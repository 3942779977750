import { Box, Checkbox } from '@chakra-ui/react'
import styled from '@emotion/styled'
import React from 'react'

export type CustomCheckboxProps = {
  onClick?: () => void
  text?: string | null
  color?: string
  backgroundColor: string
}

const StyledCheckbox = styled(Checkbox)`
  display: none;
`

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  onClick,
  text,
  color,
  backgroundColor
}) => {
  return (
    <Box
      color={color}
      backgroundColor={backgroundColor}
      px={2}
      py={1}
      mb={1}
      borderRadius={24}
      borderWidth={1}
      onClick={onClick}
    >
      <StyledCheckbox />
      {text}
    </Box>
  )
}

export default CustomCheckbox
