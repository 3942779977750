import { Flex, Image } from '@chakra-ui/react'
import * as React from 'react'
import images from '../../theme/images'

type UnderConstructionProps = {
  width?: number | string
  height?: number | string
}

const UnderConstruction: React.FC<UnderConstructionProps> = ({ width, height }) => {
  return (
    <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%">
      <Image src={images.macsLogo} width={width} height={height} />
    </Flex>
  )
}

export default UnderConstruction

UnderConstruction.defaultProps = {
  width: 350,
  height: 350
}
