import React, { FC } from 'react'
import AdvancedDataProvider from './AdvancedData.context'
import ApplicationProvider from './ApplicationProvider.context'
import AuthenticationProvider from './AuthenticationProvider.context'
import CarreirSetupProvider from './CarrierSetupProvider.context'
import DestinationProvider from './DestinationProvider.context'
import ProducSetupProvider from './ProductSetupProvider.context'
import StockOwnerProvider from './StockOwnerProvider.context'
import TableProvider from './TableProvider.context'
import UserProvider from './UserProvider.context'

export const AppProvider: FC = ({ children }) => {
  return (
    <AuthenticationProvider>
      <ApplicationProvider>
        <DestinationProvider>
          <ProducSetupProvider>
            <StockOwnerProvider>
              <CarreirSetupProvider>
                <UserProvider>
                  <AdvancedDataProvider>
                    <TableProvider>{children}</TableProvider>
                  </AdvancedDataProvider>
                </UserProvider>
              </CarreirSetupProvider>
            </StockOwnerProvider>
          </ProducSetupProvider>
        </DestinationProvider>
      </ApplicationProvider>
    </AuthenticationProvider>
  )
}
