import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  BoxProps,
  CloseButton
} from '@chakra-ui/react'
import React, { FC } from 'react'

const Notification: FC<
  {
    status: 'error' | 'info' | 'warning' | 'success'
    title: string
    description?: string
    onClose: Function
  } & BoxProps
> = ({ status, title, description, onClose, ...rest }) => {
  return (
    <Alert
      color="solid.white"
      bg={`intent.${status}`}
      status={status}
      marginBottom={-1}
      padding={2}
      rounded="md"
      {...rest}
    >
      <AlertIcon color="solid.white" padding={0} />
      <Box ml={-1} paddingRight={3}>
        <AlertTitle>{title}</AlertTitle>
        {description && <AlertDescription>{description}</AlertDescription>}
      </Box>
      <CloseButton position="absolute" right="4px" top="4px" onClick={() => onClose()} />
    </Alert>
  )
}

export default Notification
