import { Input } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { theme } from '../../../theme'

const StyledInput = styled(Input)`
  border: ${theme.colors.border.default};
`

StyledInput.defaultProps = {
  marginBottom: 2,
  paddingX: 12
}

export default StyledInput
