import { Button, Flex, useToast } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { CardFooter, Col, Container, ModalWrap, Notification, Row } from '../../components'
import { useChangePasswordMutation } from '../../generated/graphql'
import ConnectedFormGroup from '../FormElements/ConnectedPasswordInput/index'

type ChangePassword = {
  open: boolean
  onClose: () => void
}

/**
 * @render react
 * @name ChangePassword container
 * @description Allows a user to update their password
 */

const ChangePasswordModal: React.FC<ChangePassword> = ({ open, onClose }) => {
  const toast = useToast()

  const PasswordFormValidation = Yup.object().shape({
    oldPassword: Yup.string().required('Password is a required field'),
    confirmOldPassword: Yup.string()
      .oneOf([Yup.ref('oldPassword'), null], "Passwords don't match")
      .required('Confirm Password is a required field'),
    newPassword: Yup.string().required('New Password is a required field')
  })

  const INITIAL_VALUES = {
    oldPassword: '',
    confirmOldPassword: '',
    newPassword: ''
  }

  const [changePasswordMutation] = useChangePasswordMutation({
    onCompleted: (data) => {
      if (data.changePassword?.__typename === 'ValidationError') {
        data.changePassword.messages?.map((error) => {
          toast({
            duration: 8000,
            isClosable: true,
            position: 'top-right',
            render: ({ onClose }) => (
              <Notification
                status="error"
                title="Validation Error"
                description={error ? error : 'An error has occured'}
                onClose={onClose}
              />
            )
          })
        })
      }
      if (data.changePassword?.__typename === 'WMSUser') {
        toast({
          duration: 8000,
          isClosable: true,
          position: 'top-right',
          render: ({ onClose }) => (
            <Notification
              status="success"
              title="Password Updated"
              description={'You have updated your password'}
              onClose={onClose}
            />
          )
        })
        onClose()
      }
    }
  })

  return (
    <ModalWrap size="xl" title={'Change Password'} isOpen={open} onClose={onClose}>
      <Flex flexDirection="column" position="relative" padding={3} paddingBottom={1}>
        <Formik
          validationSchema={PasswordFormValidation}
          initialValues={INITIAL_VALUES}
          onSubmit={(values) => {
            changePasswordMutation({
              variables: {
                newPassword: values.newPassword,
                oldPassword: values.oldPassword
              }
            })
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form>
              <Container fluid>
                <Row>
                  <Col xs={12}>
                    <Field
                      name="oldPassword"
                      type="text"
                      label="Current Password"
                      component={ConnectedFormGroup}
                    />
                  </Col>
                  <Col xs={12}>
                    <Field
                      name="confirmOldPassword"
                      type="text"
                      label="Confirm current Password"
                      component={ConnectedFormGroup}
                    />
                  </Col>
                  <Col xs={12}>
                    <Field
                      name="newPassword"
                      type="text"
                      label="New Password"
                      component={ConnectedFormGroup}
                    />
                  </Col>
                </Row>
              </Container>
              <CardFooter flexDirection="row" flex={1}>
                <Flex justifyContent="flex-end" flex={1}>
                  <Button
                    mr={2}
                    variant="outline"
                    type="submit"
                    onClick={() => {
                      onClose()
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    bg="primary.base"
                    color="solid.white"
                    isLoading={isSubmitting}
                    onClick={() => {
                      handleSubmit()
                    }}
                  >
                    Save
                  </Button>
                </Flex>
              </CardFooter>
            </Form>
          )}
        </Formik>
      </Flex>
    </ModalWrap>
  )
}

export default ChangePasswordModal
