import { Heading, HeadingProps } from '@chakra-ui/react'
import * as React from 'react'
import { theme } from '../../theme'

const H3: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return <Heading {...rest}>{children}</Heading>
}

export default H3

H3.defaultProps = {
  as: 'h3',
  textAlign: 'center',
  lineHeight: '16px',
  fontSize: '25px',
  fontWeight: 900,
  color: theme.colors.text.default,
  mb: 8,
  mt: 4
}
