import { Log, UserManager } from 'oidc-client'
import React, { FC, useEffect, useState } from 'react'
import { CHECK_TOKEN, COGNITO_USER_STORAGE_KEY, IDENTITY_SERVER, RENEW_TOKEN } from '../constants'
import { OIDC_USER } from '../constants/index'
import { useCurrentUserLazyQuery } from '../generated/graphql'
import { useLocalStorage, useSessionStorage } from '../hooks'
import { logger } from '../utils'

type CognitoUser = {
  user: {
    userName: string
    firstname: string
    surname: string
  }
}

type ContextType = {
  authenticating: boolean
  cognitoUser: CognitoUser
  isAuthenticated: boolean
  signInRedirect: () => void
  signOut: () => void
  setAuthenticated: (_status: boolean) => void
  renewToken: () => void
  tokenExpiresIn: () => Promise<number>
}

const DEFAULT_STATE = {
  authenticating: true,
  isAuthenticated: false,
  cognitoUser: { user: { userName: '', firstname: '', surname: '' } },
  signInRedirect: () => {},
  signOut: () => {},
  setAuthenticated: (_status: boolean) => {},
  renewToken: () => null,
  tokenExpiresIn: async () => 0
}

export const AuthenticationContext = React.createContext<ContextType>(DEFAULT_STATE)

const settings = {
  authority: IDENTITY_SERVER.stsAuthority,
  client_id: IDENTITY_SERVER.clientId,
  redirect_uri: `${IDENTITY_SERVER.clientRoot}signin-callback.html`,
  silent_redirect_uri: `${IDENTITY_SERVER.clientRoot}silent-renew.html`,
  post_logout_redirect_uri: `${IDENTITY_SERVER.clientRoot}`,
  response_type: 'code',
  scope: IDENTITY_SERVER.clientScope
}

const userManager = new UserManager(settings)

const Provider: FC = ({ children }) => {
  const [oidcSession] = useSessionStorage(OIDC_USER)
  const [localUser, setLocalUser, clearLocalUser] = useLocalStorage(COGNITO_USER_STORAGE_KEY, {
    user: { userName: '', firstname: '', surname: '' }
  })
  const [authenticating, setAuthenticating] = useState(DEFAULT_STATE.authenticating)
  const [isAuthenticated, setIsAuthenticated] = useState(DEFAULT_STATE.isAuthenticated)
  const [cognitoUser, setCognitoUser] = useState(localUser)

  const [currentUserQuery] = useCurrentUserLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.currentUser?.__typename === 'WMSUser') {
        const newCognitoUser = {
          ...localUser,
          user: {
            userName: data.currentUser.userName,
            firstname: data.currentUser.forename,
            surname: data.currentUser.surname
          }
        }
        setCognitoUser(newCognitoUser)
        setLocalUser(newCognitoUser)
      }
    }
  })

  const signInRedirect = () => userManager.signinRedirect()
  const signOut = () => {
    clearLocalUser()
    userManager.signoutRedirect().then(() => {
      window.localStorage.clear()
    })
  }

  const renewToken = () => {
    userManager
      .signinSilent()
      .then((user) => {
        logger('Token has been sucessfully renewed. :-)')
        if (user) {
          logger('User has been successfully loaded from store.')
        } else {
          logger('You are not logged in.')
        }
      })
      .catch((error) => {
        logger(error)
      })
  }

  const tokenExpiresIn = () => {
    const expiresIn = userManager
      .getUser()
      .then((user) => {
        if (user) {
          return user.expires_in
        } else {
          return 0
        }
      })
      .catch((error) => {
        logger(error)
        return 0
      })

    return expiresIn
  }

  useEffect(() => {
    const interval = setInterval(() => {
      tokenExpiresIn().then((expiresIn) => {
        //600 seconds = 10 minutes
        //If the token expires in 10 minutes or less renew the token
        if (expiresIn <= RENEW_TOKEN) {
          logger('Token Renewal fires')
          renewToken()
        } else {
          logger('Valid token')
        }
      })
      ///60000 ms = 1 Minute
      //Check if the token is valid every minute
    }, CHECK_TOKEN)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    Log.logger = console
    Log.level = Log.INFO
    setCognitoUser(cognitoUser)
    setLocalUser(cognitoUser)
    userManager.getUser().then((user) => {
      if (user) {
        currentUserQuery()
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const logout = (e: StorageEvent) => {
    logger({ e, key: e.key, oldValue: e.oldValue, newValue: e.newValue })
    if (e.key === COGNITO_USER_STORAGE_KEY && e.oldValue && !e.newValue) {
      signOut()
    }
  }
  useEffect(() => {
    window.addEventListener('storage', logout)
    return () => {
      window.removeEventListener('storage', logout)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (oidcSession) {
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
    }

    setAuthenticating(false)
    // eslint-disable-next-line
  }, [cognitoUser])

  return (
    <AuthenticationContext.Provider
      value={{
        authenticating,
        cognitoUser,
        isAuthenticated,
        setAuthenticated: setIsAuthenticated,
        signInRedirect,
        signOut,
        renewToken,
        tokenExpiresIn
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}

export default Provider
