import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { isFirefox } from 'react-device-detect'
import {
  alignItems,
  color,
  flex,
  flexDirection,
  flexWrap,
  height,
  justifyContent,
  maxHeight,
  space,
  width
} from 'styled-system'

export const Wrapper = styled.div`
  ${flex};
  ${space};
  ${color};
  ${width};
  ${height};
  ${flexWrap};
  ${maxHeight};
  ${alignItems};
  ${flexDirection};
  ${justifyContent};
  display: flex;
  box-sizing: border-box;
  height: calc(100vh - 60px);
  position: relative;
  z-index: 0;
`

export const ScrollView = styled(Box)<{ borderTopWidth?: any }>`
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: ${isFirefox ? '160px' : '0'};
`
