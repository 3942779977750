import * as React from 'react'
import { DestinationBudget, DestinationRoute } from '../generated/graphql'

/**
 * @render hook
 * @name useDestinationContext hook
 * @description Contect to store all Destination modal values for use when creating, updating or deleting.
 */

type DestinationContextType = {
  tabIndex: number
  updateTabIndex: (index: number) => void

  newRoute: DestinationRoute[]
  createNewRoute: (route: DestinationRoute) => void
  deleteNewRoute: (routeNumber: string) => void
  updatedRoute: DestinationRoute[]
  createUpdatedRoute: (route: DestinationRoute) => void
  deletableRoute: DestinationRoute[]
  deleteRoute: (routes: DestinationRoute[]) => void
  clearRoutes: () => void

  newBudget: DestinationBudget[]
  createNewBudget: (budget: DestinationBudget) => void
  deleteNewBudget: (date: string) => void
  updatedBudget: DestinationBudget[]
  createUpdatedBudget: (budget: DestinationBudget) => void
  deletableBudget: DestinationBudget[]
  deleteBudget: (routes: DestinationBudget[]) => void
  clearBudgets: () => void
}

export const DestinationContext = React.createContext<DestinationContextType>({
  tabIndex: 0,
  updateTabIndex: () => null,

  newRoute: [],
  createNewRoute: () => null,
  deleteNewRoute: () => null,
  updatedRoute: [],
  createUpdatedRoute: () => null,
  deletableRoute: [],
  deleteRoute: () => null,
  clearRoutes: () => null,

  newBudget: [],
  createNewBudget: () => null,
  deleteNewBudget: () => null,
  updatedBudget: [],
  createUpdatedBudget: () => null,
  deletableBudget: [],
  deleteBudget: () => null,
  clearBudgets: () => null
})

export const useDestinationContext = () => React.useContext(DestinationContext)

const DestinationProvider: React.FC<{}> = ({ children }) => {
  const [tabIndex, setTabIndex] = React.useState<number>(0)

  const [updatedRoute, setUpdatedRoute] = React.useState<DestinationRoute[]>([])
  const [newRoute, setNewRoute] = React.useState<DestinationRoute[]>([])
  const [deletableRoute, setDeletableRoute] = React.useState<DestinationRoute[]>([])

  const [updatedBudget, setUpdatedBudget] = React.useState<DestinationBudget[]>([])
  const [newBudget, setNewBudget] = React.useState<DestinationBudget[]>([])
  const [deletableBudget, setDeletableBudget] = React.useState<DestinationBudget[]>([])

  function updateTabIndex(index: number) {
    setTabIndex(index)
  }

  function createNewRoute(route: DestinationRoute) {
    setNewRoute([...newRoute, route])
  }

  function deleteNewRoute(route: string) {
    const filteredNewRoute = newRoute.filter((newRoute) => newRoute.route !== route)
    setNewRoute(filteredNewRoute)
  }

  function createUpdatedRoute(route: DestinationRoute) {
    setUpdatedRoute([...updatedRoute, route])
  }

  function deleteRoute(routes: DestinationRoute[]) {
    setDeletableRoute(routes)
  }

  function clearRoutes() {
    setUpdatedRoute([])
    setNewRoute([])
    setDeletableRoute([])
  }

  function createNewBudget(budget: DestinationBudget) {
    setNewBudget([...newBudget, budget])
  }

  function deleteNewBudget(date: string) {
    const filteredNewBudget = newBudget.filter((newBudget) => newBudget.startDate !== date)
    setNewBudget(filteredNewBudget)
  }

  function createUpdatedBudget(budget: DestinationBudget) {
    setUpdatedBudget([...updatedBudget, budget])
  }

  function deleteBudget(budget: DestinationBudget[]) {
    setDeletableBudget(budget)
  }

  function clearBudgets() {
    setUpdatedBudget([])
    setNewBudget([])
    setDeletableBudget([])
  }

  return (
    <DestinationContext.Provider
      value={{
        tabIndex,
        updateTabIndex,

        newRoute,
        createNewRoute,
        deleteNewRoute,
        updatedRoute,
        createUpdatedRoute,
        deletableRoute,
        deleteRoute,
        clearRoutes,

        newBudget,
        createNewBudget,
        deleteNewBudget,
        updatedBudget,
        createUpdatedBudget,
        deletableBudget,
        deleteBudget,
        clearBudgets
      }}
    >
      {children}
    </DestinationContext.Provider>
  )
}

export default DestinationProvider
