import gql from 'graphql-tag'
import * as ApolloReactCommon from '@apollo/react-common'
import * as React from 'react'
import * as ApolloReactComponents from '@apollo/react-components'
import * as ApolloReactHoc from '@apollo/react-hoc'
import * as ApolloReactHooks from '@apollo/react-hooks'
export type Maybe<T> = T | null
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  DateTime: any
  Decimal: any
  Byte: any
  Uuid: any
}

export enum Accessiblity {
  Deny = 'DENY',
  Allow = 'ALLOW',
  Warn = 'WARN'
}

export type Activity = {
  __typename?: 'Activity'
  id?: Maybe<Scalars['ID']>
  code?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type Address = {
  __typename?: 'Address'
  name?: Maybe<Scalars['String']>
  street1: Scalars['String']
  street2?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  county?: Maybe<Scalars['String']>
  postCode?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
}

export type AddressFilter = {
  AND?: Maybe<Array<AddressFilter>>
  OR?: Maybe<Array<AddressFilter>>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  street1?: Maybe<Scalars['String']>
  street1_not?: Maybe<Scalars['String']>
  street1_contains?: Maybe<Scalars['String']>
  street1_not_contains?: Maybe<Scalars['String']>
  street1_starts_with?: Maybe<Scalars['String']>
  street1_not_starts_with?: Maybe<Scalars['String']>
  street1_ends_with?: Maybe<Scalars['String']>
  street1_not_ends_with?: Maybe<Scalars['String']>
  street1_in?: Maybe<Array<Scalars['String']>>
  street1_not_in?: Maybe<Array<Scalars['String']>>
  street2?: Maybe<Scalars['String']>
  street2_not?: Maybe<Scalars['String']>
  street2_contains?: Maybe<Scalars['String']>
  street2_not_contains?: Maybe<Scalars['String']>
  street2_starts_with?: Maybe<Scalars['String']>
  street2_not_starts_with?: Maybe<Scalars['String']>
  street2_ends_with?: Maybe<Scalars['String']>
  street2_not_ends_with?: Maybe<Scalars['String']>
  street2_in?: Maybe<Array<Maybe<Scalars['String']>>>
  street2_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  city?: Maybe<Scalars['String']>
  city_not?: Maybe<Scalars['String']>
  city_contains?: Maybe<Scalars['String']>
  city_not_contains?: Maybe<Scalars['String']>
  city_starts_with?: Maybe<Scalars['String']>
  city_not_starts_with?: Maybe<Scalars['String']>
  city_ends_with?: Maybe<Scalars['String']>
  city_not_ends_with?: Maybe<Scalars['String']>
  city_in?: Maybe<Array<Maybe<Scalars['String']>>>
  city_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  county?: Maybe<Scalars['String']>
  county_not?: Maybe<Scalars['String']>
  county_contains?: Maybe<Scalars['String']>
  county_not_contains?: Maybe<Scalars['String']>
  county_starts_with?: Maybe<Scalars['String']>
  county_not_starts_with?: Maybe<Scalars['String']>
  county_ends_with?: Maybe<Scalars['String']>
  county_not_ends_with?: Maybe<Scalars['String']>
  county_in?: Maybe<Array<Maybe<Scalars['String']>>>
  county_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  postCode?: Maybe<Scalars['String']>
  postCode_not?: Maybe<Scalars['String']>
  postCode_contains?: Maybe<Scalars['String']>
  postCode_not_contains?: Maybe<Scalars['String']>
  postCode_starts_with?: Maybe<Scalars['String']>
  postCode_not_starts_with?: Maybe<Scalars['String']>
  postCode_ends_with?: Maybe<Scalars['String']>
  postCode_not_ends_with?: Maybe<Scalars['String']>
  postCode_in?: Maybe<Array<Maybe<Scalars['String']>>>
  postCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  country?: Maybe<Scalars['String']>
  country_not?: Maybe<Scalars['String']>
  country_contains?: Maybe<Scalars['String']>
  country_not_contains?: Maybe<Scalars['String']>
  country_starts_with?: Maybe<Scalars['String']>
  country_not_starts_with?: Maybe<Scalars['String']>
  country_ends_with?: Maybe<Scalars['String']>
  country_not_ends_with?: Maybe<Scalars['String']>
  country_in?: Maybe<Array<Maybe<Scalars['String']>>>
  country_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type AddressFilterInput = {
  and?: Maybe<Array<AddressFilterInput>>
  or?: Maybe<Array<AddressFilterInput>>
  name?: Maybe<StringOperationFilterInput>
  street1?: Maybe<StringOperationFilterInput>
  street2?: Maybe<StringOperationFilterInput>
  city?: Maybe<StringOperationFilterInput>
  county?: Maybe<StringOperationFilterInput>
  postCode?: Maybe<StringOperationFilterInput>
  country?: Maybe<StringOperationFilterInput>
}

export type AddressInput = {
  name?: Maybe<Scalars['String']>
  street1: Scalars['String']
  street2?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  county?: Maybe<Scalars['String']>
  postCode?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
}

export type AddressSort = {
  name?: Maybe<SortOperationKind>
  street1?: Maybe<SortOperationKind>
  street2?: Maybe<SortOperationKind>
  city?: Maybe<SortOperationKind>
  county?: Maybe<SortOperationKind>
  postCode?: Maybe<SortOperationKind>
  country?: Maybe<SortOperationKind>
}

export type AddressSortInput = {
  name?: Maybe<SortEnumType>
  street1?: Maybe<SortEnumType>
  street2?: Maybe<SortEnumType>
  city?: Maybe<SortEnumType>
  county?: Maybe<SortEnumType>
  postCode?: Maybe<SortEnumType>
  country?: Maybe<SortEnumType>
}

export type AddressUpdateInput = {
  name?: Maybe<Scalars['String']>
  street1?: Maybe<Scalars['String']>
  street2?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  county?: Maybe<Scalars['String']>
  postCode?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
}

export enum ApplyPolicy {
  BeforeResolver = 'BEFORE_RESOLVER',
  AfterResolver = 'AFTER_RESOLVER'
}

export type AssemblyName = {
  __typename?: 'AssemblyName'
  name?: Maybe<Scalars['String']>
  fullName: Scalars['String']
  version?: Maybe<Scalars['String']>
  cultureName?: Maybe<Scalars['String']>
  processorArchitecture: ProcessorArchitecture
  publicKeyToken?: Maybe<Array<Scalars['Byte']>>
}

export type AuditCode = {
  __typename?: 'AuditCode'
  compareTo: Scalars['Int']
  description?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  id: Scalars['Int']
}

export type AuditCodeSortInput = {
  description?: Maybe<SortEnumType>
  message?: Maybe<SortEnumType>
  name?: Maybe<SortEnumType>
  id?: Maybe<SortEnumType>
}

export type AuditEntry = {
  __typename?: 'AuditEntry'
  attributes?: Maybe<IwmsAttributes>
  message?: Maybe<Scalars['String']>
  user?: Maybe<WmsUser>
  fromLocation?: Maybe<Location>
  toLocation?: Maybe<Location>
  id?: Maybe<Scalars['Int']>
  warehouseId?: Maybe<Scalars['Int']>
  warehouse?: Maybe<Warehouse>
  stockOwnerId?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwner>
  eventTime: Scalars['DateTime']
  auditCode?: Maybe<AuditCode>
  code?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
  toLocationId?: Maybe<Scalars['Int']>
  fromLocationId?: Maybe<Scalars['Int']>
  attributeId?: Maybe<Scalars['Int']>
  status?: Maybe<Scalars['String']>
  packType?: Maybe<Scalars['String']>
  packs?: Maybe<Scalars['Decimal']>
  singles?: Maybe<Scalars['Decimal']>
  metadata?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type AuditEntryConnection = {
  __typename?: 'AuditEntryConnection'
  pageInfo: PageInfo
  edges?: Maybe<Array<AuditEntryEdge>>
  nodes?: Maybe<Array<Maybe<AuditEntry>>>
  totalCount: Scalars['Int']
}

export type AuditEntryEdge = {
  __typename?: 'AuditEntryEdge'
  cursor: Scalars['String']
  node?: Maybe<AuditEntry>
}

export type AuditEntryFilterInput = {
  and?: Maybe<Array<AuditEntryFilterInput>>
  or?: Maybe<Array<AuditEntryFilterInput>>
  code?: Maybe<StringOperationFilterInput>
  eventTime?: Maybe<ComparableDateTimeOperationFilterInput>
  userId?: Maybe<StringOperationFilterInput>
  packType?: Maybe<StringOperationFilterInput>
  packs?: Maybe<ComparableNullableOfDecimalOperationFilterInput>
  singles?: Maybe<ComparableNullableOfDecimalOperationFilterInput>
  status?: Maybe<StringOperationFilterInput>
  toLocationId?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  fromLocationId?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  metadata?: Maybe<ListStringOperationFilterInput>
}

export type AuditEntrySortInput = {
  id?: Maybe<SortEnumType>
  warehouseId?: Maybe<SortEnumType>
  warehouse?: Maybe<WarehouseSortInput>
  stockOwnerId?: Maybe<SortEnumType>
  stockOwner?: Maybe<StockOwnerSortInput>
  eventTime?: Maybe<SortEnumType>
  auditCode?: Maybe<AuditCodeSortInput>
  code?: Maybe<SortEnumType>
  userId?: Maybe<SortEnumType>
  user?: Maybe<WmsUserSortInput>
  toLocationId?: Maybe<SortEnumType>
  toLocation?: Maybe<LocationSortInput>
  fromLocationId?: Maybe<SortEnumType>
  fromLocation?: Maybe<LocationSortInput>
  attributeId?: Maybe<SortEnumType>
  attributes?: Maybe<SortEnumType>
  status?: Maybe<SortEnumType>
  packType?: Maybe<SortEnumType>
  packs?: Maybe<SortEnumType>
  singles?: Maybe<SortEnumType>
}

export type BasicResourceItem = {
  __typename?: 'BasicResourceItem'
  resourceId?: Maybe<Scalars['String']>
  localeId?: Maybe<Scalars['String']>
  resourceSet?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type BomDefinition = {
  __typename?: 'BOMDefinition'
  id?: Maybe<Scalars['Int']>
  parent?: Maybe<Product>
  childId: Scalars['Int']
  child?: Maybe<Product>
  qty: Scalars['Int']
}

export type BomDefinitionFilter = {
  AND?: Maybe<Array<BomDefinitionFilter>>
  OR?: Maybe<Array<BomDefinitionFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  parent?: Maybe<ProductFilter>
  childId?: Maybe<Scalars['Int']>
  childId_not?: Maybe<Scalars['Int']>
  childId_in?: Maybe<Array<Scalars['Int']>>
  childId_not_in?: Maybe<Array<Scalars['Int']>>
  childId_gt?: Maybe<Scalars['Int']>
  childId_not_gt?: Maybe<Scalars['Int']>
  childId_gte?: Maybe<Scalars['Int']>
  childId_not_gte?: Maybe<Scalars['Int']>
  childId_lt?: Maybe<Scalars['Int']>
  childId_not_lt?: Maybe<Scalars['Int']>
  childId_lte?: Maybe<Scalars['Int']>
  childId_not_lte?: Maybe<Scalars['Int']>
  child?: Maybe<ProductFilter>
  qty?: Maybe<Scalars['Int']>
  qty_not?: Maybe<Scalars['Int']>
  qty_in?: Maybe<Array<Scalars['Int']>>
  qty_not_in?: Maybe<Array<Scalars['Int']>>
  qty_gt?: Maybe<Scalars['Int']>
  qty_not_gt?: Maybe<Scalars['Int']>
  qty_gte?: Maybe<Scalars['Int']>
  qty_not_gte?: Maybe<Scalars['Int']>
  qty_lt?: Maybe<Scalars['Int']>
  qty_not_lt?: Maybe<Scalars['Int']>
  qty_lte?: Maybe<Scalars['Int']>
  qty_not_lte?: Maybe<Scalars['Int']>
}

export type BomDefinitionFilterInput = {
  and?: Maybe<Array<BomDefinitionFilterInput>>
  or?: Maybe<Array<BomDefinitionFilterInput>>
  id?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  parent?: Maybe<ProductFilterInput>
  childId?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  child?: Maybe<ProductFilterInput>
  qty?: Maybe<ComparableNullableOfInt32OperationFilterInput>
}

export type BomDefinitionInput = {
  id?: Maybe<Scalars['Int']>
  parent?: Maybe<ProductInput>
  childId: Scalars['Int']
  child?: Maybe<ProductInput>
  qty: Scalars['Int']
}

export type BomDefinitionUpdateInput = {
  childId?: Maybe<Scalars['Int']>
  qty?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
}

export enum BomType {
  Sku = 'SKU',
  NonStock = 'NON_STOCK',
  Stock = 'STOCK'
}

export type BooleanOperationFilterInput = {
  eq?: Maybe<Scalars['Boolean']>
  neq?: Maybe<Scalars['Boolean']>
}

export type BudgetHolder = {
  __typename?: 'BudgetHolder'
  stockOwner?: Maybe<StockOwner>
  id?: Maybe<Scalars['Int']>
  code: Scalars['String']
  description: Scalars['String']
  status: State
  warehouse?: Maybe<Warehouse>
}

export type BudgetHolderConnection = {
  __typename?: 'BudgetHolderConnection'
  pageInfo: PageInfo
  edges?: Maybe<Array<BudgetHolderEdge>>
  nodes?: Maybe<Array<Maybe<BudgetHolder>>>
  totalCount: Scalars['Int']
}

export type BudgetHolderEdge = {
  __typename?: 'BudgetHolderEdge'
  cursor: Scalars['String']
  node?: Maybe<BudgetHolder>
}

export type BudgetHolderFilter = {
  AND?: Maybe<Array<BudgetHolderFilter>>
  OR?: Maybe<Array<BudgetHolderFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
  code_not?: Maybe<Scalars['String']>
  code_contains?: Maybe<Scalars['String']>
  code_not_contains?: Maybe<Scalars['String']>
  code_starts_with?: Maybe<Scalars['String']>
  code_not_starts_with?: Maybe<Scalars['String']>
  code_ends_with?: Maybe<Scalars['String']>
  code_not_ends_with?: Maybe<Scalars['String']>
  code_in?: Maybe<Array<Scalars['String']>>
  code_not_in?: Maybe<Array<Scalars['String']>>
  description?: Maybe<Scalars['String']>
  description_not?: Maybe<Scalars['String']>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  description_starts_with?: Maybe<Scalars['String']>
  description_not_starts_with?: Maybe<Scalars['String']>
  description_ends_with?: Maybe<Scalars['String']>
  description_not_ends_with?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Scalars['String']>>
  description_not_in?: Maybe<Array<Scalars['String']>>
  status?: Maybe<State>
  status_not?: Maybe<State>
  status_in?: Maybe<Array<State>>
  status_not_in?: Maybe<Array<State>>
  status_gt?: Maybe<State>
  status_not_gt?: Maybe<State>
  status_gte?: Maybe<State>
  status_not_gte?: Maybe<State>
  status_lt?: Maybe<State>
  status_not_lt?: Maybe<State>
  status_lte?: Maybe<State>
  status_not_lte?: Maybe<State>
  stockOwner?: Maybe<StockOwnerFilter>
  warehouse?: Maybe<WarehouseFilter>
}

export type BudgetHolderInput = {
  id?: Maybe<Scalars['Int']>
  code: Scalars['String']
  description: Scalars['String']
  status: State
  stockOwner?: Maybe<StockOwnerInput>
  warehouse?: Maybe<WarehouseInput>
}

export type BudgetHolderSort = {
  id?: Maybe<SortOperationKind>
  code?: Maybe<SortOperationKind>
  description?: Maybe<SortOperationKind>
  status?: Maybe<SortOperationKind>
  stockOwner?: Maybe<StockOwnerSort>
  warehouse?: Maybe<WarehouseSort>
}

export type BudgetHolderUpdateInput = {
  description?: Maybe<Scalars['String']>
  status?: Maybe<State>
  id?: Maybe<Scalars['Int']>
}

export enum CalendarAlgorithmType {
  Unknown = 'UNKNOWN',
  SolarCalendar = 'SOLAR_CALENDAR',
  LunarCalendar = 'LUNAR_CALENDAR',
  LunisolarCalendar = 'LUNISOLAR_CALENDAR'
}

export type CalendarAlgorithmTypeOperationFilterInput = {
  eq?: Maybe<CalendarAlgorithmType>
  neq?: Maybe<CalendarAlgorithmType>
  in?: Maybe<Array<CalendarAlgorithmType>>
  nin?: Maybe<Array<CalendarAlgorithmType>>
}

export type CalendarFilterInput = {
  and?: Maybe<Array<CalendarFilterInput>>
  or?: Maybe<Array<CalendarFilterInput>>
  minSupportedDateTime?: Maybe<ComparableDateTimeOperationFilterInput>
  maxSupportedDateTime?: Maybe<ComparableDateTimeOperationFilterInput>
  algorithmType?: Maybe<CalendarAlgorithmTypeOperationFilterInput>
  isReadOnly?: Maybe<BooleanOperationFilterInput>
  eras?: Maybe<ListComparableInt32OperationFilterInput>
  twoDigitYearMax?: Maybe<ComparableInt32OperationFilterInput>
}

export type CalendarSortInput = {
  minSupportedDateTime?: Maybe<SortEnumType>
  maxSupportedDateTime?: Maybe<SortEnumType>
  algorithmType?: Maybe<SortEnumType>
  isReadOnly?: Maybe<SortEnumType>
  twoDigitYearMax?: Maybe<SortEnumType>
}

export enum CalendarWeekRule {
  FirstDay = 'FIRST_DAY',
  FirstFullWeek = 'FIRST_FULL_WEEK',
  FirstFourDayWeek = 'FIRST_FOUR_DAY_WEEK'
}

export type CalendarWeekRuleOperationFilterInput = {
  eq?: Maybe<CalendarWeekRule>
  neq?: Maybe<CalendarWeekRule>
  in?: Maybe<Array<CalendarWeekRule>>
  nin?: Maybe<Array<CalendarWeekRule>>
}

export type Carrier = {
  __typename?: 'Carrier'
  id?: Maybe<Scalars['Int']>
  code: Scalars['String']
  name: Scalars['String']
  module?: Maybe<Scalars['String']>
  telephone?: Maybe<Scalars['String']>
  defDespatchLane?: Maybe<Scalars['String']>
  requireService?: Maybe<Scalars['Boolean']>
  captureDelNo?: Maybe<Scalars['Boolean']>
  defService?: Maybe<Scalars['String']>
  services?: Maybe<Array<Maybe<CarrierService>>>
  accounts?: Maybe<Array<Maybe<CarrierAccount>>>
}

export type CarrierAccount = {
  __typename?: 'CarrierAccount'
  id?: Maybe<Scalars['Int']>
  account: Scalars['String']
  description: Scalars['String']
  allowedServices?: Maybe<Array<Maybe<Scalars['String']>>>
  configuration?: Maybe<Array<Maybe<CarrierConfig>>>
}

export type CarrierAccountFilter = {
  AND?: Maybe<Array<CarrierAccountFilter>>
  OR?: Maybe<Array<CarrierAccountFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  account?: Maybe<Scalars['String']>
  account_not?: Maybe<Scalars['String']>
  account_contains?: Maybe<Scalars['String']>
  account_not_contains?: Maybe<Scalars['String']>
  account_starts_with?: Maybe<Scalars['String']>
  account_not_starts_with?: Maybe<Scalars['String']>
  account_ends_with?: Maybe<Scalars['String']>
  account_not_ends_with?: Maybe<Scalars['String']>
  account_in?: Maybe<Array<Scalars['String']>>
  account_not_in?: Maybe<Array<Scalars['String']>>
  description?: Maybe<Scalars['String']>
  description_not?: Maybe<Scalars['String']>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  description_starts_with?: Maybe<Scalars['String']>
  description_not_starts_with?: Maybe<Scalars['String']>
  description_ends_with?: Maybe<Scalars['String']>
  description_not_ends_with?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Scalars['String']>>
  description_not_in?: Maybe<Array<Scalars['String']>>
  allowedServices_some?: Maybe<ISingleFilterOfStringFilter>
  allowedServices_none?: Maybe<ISingleFilterOfStringFilter>
  allowedServices_all?: Maybe<ISingleFilterOfStringFilter>
  allowedServices_any?: Maybe<Scalars['Boolean']>
  configuration_some?: Maybe<CarrierConfigFilter>
  configuration_none?: Maybe<CarrierConfigFilter>
  configuration_all?: Maybe<CarrierConfigFilter>
  configuration_any?: Maybe<Scalars['Boolean']>
}

export type CarrierAccountInput = {
  id?: Maybe<Scalars['Int']>
  account: Scalars['String']
  description: Scalars['String']
  allowedServices?: Maybe<Array<Maybe<Scalars['String']>>>
  configuration?: Maybe<Array<Maybe<CarrierConfigInput>>>
}

export type CarrierAccountUpdateInput = {
  id?: Maybe<Scalars['Int']>
  account?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  allowedServices?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type CarrierConfig = {
  __typename?: 'CarrierConfig'
  id?: Maybe<Scalars['Int']>
  setting: Scalars['String']
  value: Scalars['String']
}

export type CarrierConfigFilter = {
  AND?: Maybe<Array<CarrierConfigFilter>>
  OR?: Maybe<Array<CarrierConfigFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  setting?: Maybe<Scalars['String']>
  setting_not?: Maybe<Scalars['String']>
  setting_contains?: Maybe<Scalars['String']>
  setting_not_contains?: Maybe<Scalars['String']>
  setting_starts_with?: Maybe<Scalars['String']>
  setting_not_starts_with?: Maybe<Scalars['String']>
  setting_ends_with?: Maybe<Scalars['String']>
  setting_not_ends_with?: Maybe<Scalars['String']>
  setting_in?: Maybe<Array<Scalars['String']>>
  setting_not_in?: Maybe<Array<Scalars['String']>>
  value?: Maybe<Scalars['String']>
  value_not?: Maybe<Scalars['String']>
  value_contains?: Maybe<Scalars['String']>
  value_not_contains?: Maybe<Scalars['String']>
  value_starts_with?: Maybe<Scalars['String']>
  value_not_starts_with?: Maybe<Scalars['String']>
  value_ends_with?: Maybe<Scalars['String']>
  value_not_ends_with?: Maybe<Scalars['String']>
  value_in?: Maybe<Array<Scalars['String']>>
  value_not_in?: Maybe<Array<Scalars['String']>>
}

export type CarrierConfigInput = {
  id?: Maybe<Scalars['Int']>
  setting: Scalars['String']
  value: Scalars['String']
}

export type CarrierConfigUpdateInput = {
  value?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
}

export type CarrierConnection = {
  __typename?: 'CarrierConnection'
  pageInfo: PageInfo
  edges?: Maybe<Array<CarrierEdge>>
  nodes?: Maybe<Array<Maybe<Carrier>>>
  totalCount: Scalars['Int']
}

export type CarrierEdge = {
  __typename?: 'CarrierEdge'
  cursor: Scalars['String']
  node?: Maybe<Carrier>
}

export type CarrierFilter = {
  AND?: Maybe<Array<CarrierFilter>>
  OR?: Maybe<Array<CarrierFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
  code_not?: Maybe<Scalars['String']>
  code_contains?: Maybe<Scalars['String']>
  code_not_contains?: Maybe<Scalars['String']>
  code_starts_with?: Maybe<Scalars['String']>
  code_not_starts_with?: Maybe<Scalars['String']>
  code_ends_with?: Maybe<Scalars['String']>
  code_not_ends_with?: Maybe<Scalars['String']>
  code_in?: Maybe<Array<Scalars['String']>>
  code_not_in?: Maybe<Array<Scalars['String']>>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Scalars['String']>>
  name_not_in?: Maybe<Array<Scalars['String']>>
  module?: Maybe<Scalars['String']>
  module_not?: Maybe<Scalars['String']>
  module_contains?: Maybe<Scalars['String']>
  module_not_contains?: Maybe<Scalars['String']>
  module_starts_with?: Maybe<Scalars['String']>
  module_not_starts_with?: Maybe<Scalars['String']>
  module_ends_with?: Maybe<Scalars['String']>
  module_not_ends_with?: Maybe<Scalars['String']>
  module_in?: Maybe<Array<Maybe<Scalars['String']>>>
  module_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  telephone?: Maybe<Scalars['String']>
  telephone_not?: Maybe<Scalars['String']>
  telephone_contains?: Maybe<Scalars['String']>
  telephone_not_contains?: Maybe<Scalars['String']>
  telephone_starts_with?: Maybe<Scalars['String']>
  telephone_not_starts_with?: Maybe<Scalars['String']>
  telephone_ends_with?: Maybe<Scalars['String']>
  telephone_not_ends_with?: Maybe<Scalars['String']>
  telephone_in?: Maybe<Array<Maybe<Scalars['String']>>>
  telephone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defDespatchLane?: Maybe<Scalars['String']>
  defDespatchLane_not?: Maybe<Scalars['String']>
  defDespatchLane_contains?: Maybe<Scalars['String']>
  defDespatchLane_not_contains?: Maybe<Scalars['String']>
  defDespatchLane_starts_with?: Maybe<Scalars['String']>
  defDespatchLane_not_starts_with?: Maybe<Scalars['String']>
  defDespatchLane_ends_with?: Maybe<Scalars['String']>
  defDespatchLane_not_ends_with?: Maybe<Scalars['String']>
  defDespatchLane_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defDespatchLane_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  requireService?: Maybe<Scalars['Boolean']>
  requireService_not?: Maybe<Scalars['Boolean']>
  captureDelNo?: Maybe<Scalars['Boolean']>
  captureDelNo_not?: Maybe<Scalars['Boolean']>
  defService?: Maybe<Scalars['String']>
  defService_not?: Maybe<Scalars['String']>
  defService_contains?: Maybe<Scalars['String']>
  defService_not_contains?: Maybe<Scalars['String']>
  defService_starts_with?: Maybe<Scalars['String']>
  defService_not_starts_with?: Maybe<Scalars['String']>
  defService_ends_with?: Maybe<Scalars['String']>
  defService_not_ends_with?: Maybe<Scalars['String']>
  defService_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defService_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  services_some?: Maybe<CarrierServiceFilter>
  services_none?: Maybe<CarrierServiceFilter>
  services_all?: Maybe<CarrierServiceFilter>
  services_any?: Maybe<Scalars['Boolean']>
  accounts_some?: Maybe<CarrierAccountFilter>
  accounts_none?: Maybe<CarrierAccountFilter>
  accounts_all?: Maybe<CarrierAccountFilter>
  accounts_any?: Maybe<Scalars['Boolean']>
}

export type CarrierInput = {
  id?: Maybe<Scalars['Int']>
  code: Scalars['String']
  name: Scalars['String']
  module?: Maybe<Scalars['String']>
  telephone?: Maybe<Scalars['String']>
  defDespatchLane?: Maybe<Scalars['String']>
  requireService?: Maybe<Scalars['Boolean']>
  captureDelNo?: Maybe<Scalars['Boolean']>
  defService?: Maybe<Scalars['String']>
  services?: Maybe<Array<Maybe<CarrierServiceInput>>>
  accounts?: Maybe<Array<Maybe<CarrierAccountInput>>>
}

export type CarrierLeadTime = {
  __typename?: 'CarrierLeadTime'
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

export type CarrierService = {
  __typename?: 'CarrierService'
  id?: Maybe<Scalars['Int']>
  code: Scalars['String']
  description: Scalars['String']
  references?: Maybe<Array<Maybe<Scalars['String']>>>
  maxWeight?: Maybe<Scalars['Decimal']>
  maxHeight?: Maybe<Scalars['Decimal']>
  maxWidth?: Maybe<Scalars['Decimal']>
  maxLength?: Maybe<Scalars['Decimal']>
  nextDay?: Maybe<Scalars['Boolean']>
  saturday?: Maybe<Scalars['Boolean']>
  sunday?: Maybe<Scalars['Boolean']>
  collection?: Maybe<Scalars['Boolean']>
  localCollect?: Maybe<Scalars['Boolean']>
  signReq?: Maybe<Scalars['Boolean']>
  trackingReq?: Maybe<Scalars['Boolean']>
  recordDelivery?: Maybe<Scalars['Boolean']>
  notifySMS?: Maybe<Scalars['Boolean']>
  notifyEmail?: Maybe<Scalars['Boolean']>
  extraBoxInfo?: Maybe<Scalars['Boolean']>
}

export type CarrierServiceFilter = {
  AND?: Maybe<Array<CarrierServiceFilter>>
  OR?: Maybe<Array<CarrierServiceFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
  code_not?: Maybe<Scalars['String']>
  code_contains?: Maybe<Scalars['String']>
  code_not_contains?: Maybe<Scalars['String']>
  code_starts_with?: Maybe<Scalars['String']>
  code_not_starts_with?: Maybe<Scalars['String']>
  code_ends_with?: Maybe<Scalars['String']>
  code_not_ends_with?: Maybe<Scalars['String']>
  code_in?: Maybe<Array<Scalars['String']>>
  code_not_in?: Maybe<Array<Scalars['String']>>
  description?: Maybe<Scalars['String']>
  description_not?: Maybe<Scalars['String']>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  description_starts_with?: Maybe<Scalars['String']>
  description_not_starts_with?: Maybe<Scalars['String']>
  description_ends_with?: Maybe<Scalars['String']>
  description_not_ends_with?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Scalars['String']>>
  description_not_in?: Maybe<Array<Scalars['String']>>
  references_some?: Maybe<ISingleFilterOfStringFilter>
  references_none?: Maybe<ISingleFilterOfStringFilter>
  references_all?: Maybe<ISingleFilterOfStringFilter>
  references_any?: Maybe<Scalars['Boolean']>
  maxWeight?: Maybe<Scalars['Decimal']>
  maxWeight_not?: Maybe<Scalars['Decimal']>
  maxWeight_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  maxWeight_not_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  maxWeight_gt?: Maybe<Scalars['Decimal']>
  maxWeight_not_gt?: Maybe<Scalars['Decimal']>
  maxWeight_gte?: Maybe<Scalars['Decimal']>
  maxWeight_not_gte?: Maybe<Scalars['Decimal']>
  maxWeight_lt?: Maybe<Scalars['Decimal']>
  maxWeight_not_lt?: Maybe<Scalars['Decimal']>
  maxWeight_lte?: Maybe<Scalars['Decimal']>
  maxWeight_not_lte?: Maybe<Scalars['Decimal']>
  maxHeight?: Maybe<Scalars['Decimal']>
  maxHeight_not?: Maybe<Scalars['Decimal']>
  maxHeight_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  maxHeight_not_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  maxHeight_gt?: Maybe<Scalars['Decimal']>
  maxHeight_not_gt?: Maybe<Scalars['Decimal']>
  maxHeight_gte?: Maybe<Scalars['Decimal']>
  maxHeight_not_gte?: Maybe<Scalars['Decimal']>
  maxHeight_lt?: Maybe<Scalars['Decimal']>
  maxHeight_not_lt?: Maybe<Scalars['Decimal']>
  maxHeight_lte?: Maybe<Scalars['Decimal']>
  maxHeight_not_lte?: Maybe<Scalars['Decimal']>
  maxWidth?: Maybe<Scalars['Decimal']>
  maxWidth_not?: Maybe<Scalars['Decimal']>
  maxWidth_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  maxWidth_not_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  maxWidth_gt?: Maybe<Scalars['Decimal']>
  maxWidth_not_gt?: Maybe<Scalars['Decimal']>
  maxWidth_gte?: Maybe<Scalars['Decimal']>
  maxWidth_not_gte?: Maybe<Scalars['Decimal']>
  maxWidth_lt?: Maybe<Scalars['Decimal']>
  maxWidth_not_lt?: Maybe<Scalars['Decimal']>
  maxWidth_lte?: Maybe<Scalars['Decimal']>
  maxWidth_not_lte?: Maybe<Scalars['Decimal']>
  maxLength?: Maybe<Scalars['Decimal']>
  maxLength_not?: Maybe<Scalars['Decimal']>
  maxLength_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  maxLength_not_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  maxLength_gt?: Maybe<Scalars['Decimal']>
  maxLength_not_gt?: Maybe<Scalars['Decimal']>
  maxLength_gte?: Maybe<Scalars['Decimal']>
  maxLength_not_gte?: Maybe<Scalars['Decimal']>
  maxLength_lt?: Maybe<Scalars['Decimal']>
  maxLength_not_lt?: Maybe<Scalars['Decimal']>
  maxLength_lte?: Maybe<Scalars['Decimal']>
  maxLength_not_lte?: Maybe<Scalars['Decimal']>
  nextDay?: Maybe<Scalars['Boolean']>
  nextDay_not?: Maybe<Scalars['Boolean']>
  saturday?: Maybe<Scalars['Boolean']>
  saturday_not?: Maybe<Scalars['Boolean']>
  sunday?: Maybe<Scalars['Boolean']>
  sunday_not?: Maybe<Scalars['Boolean']>
  collection?: Maybe<Scalars['Boolean']>
  collection_not?: Maybe<Scalars['Boolean']>
  localCollect?: Maybe<Scalars['Boolean']>
  localCollect_not?: Maybe<Scalars['Boolean']>
  signReq?: Maybe<Scalars['Boolean']>
  signReq_not?: Maybe<Scalars['Boolean']>
  trackingReq?: Maybe<Scalars['Boolean']>
  trackingReq_not?: Maybe<Scalars['Boolean']>
  recordDelivery?: Maybe<Scalars['Boolean']>
  recordDelivery_not?: Maybe<Scalars['Boolean']>
  notifySMS?: Maybe<Scalars['Boolean']>
  notifySMS_not?: Maybe<Scalars['Boolean']>
  notifyEmail?: Maybe<Scalars['Boolean']>
  notifyEmail_not?: Maybe<Scalars['Boolean']>
  extraBoxInfo?: Maybe<Scalars['Boolean']>
  extraBoxInfo_not?: Maybe<Scalars['Boolean']>
}

export type CarrierServiceInput = {
  id?: Maybe<Scalars['Int']>
  code: Scalars['String']
  description: Scalars['String']
  references?: Maybe<Array<Maybe<Scalars['String']>>>
  maxWeight: Scalars['Int']
  maxHeight: Scalars['Int']
  maxWidth: Scalars['Int']
  maxLength: Scalars['Int']
  nextDay?: Maybe<Scalars['Boolean']>
  saturday?: Maybe<Scalars['Boolean']>
  sunday?: Maybe<Scalars['Boolean']>
  collection?: Maybe<Scalars['Boolean']>
  localCollect?: Maybe<Scalars['Boolean']>
  signReq?: Maybe<Scalars['Boolean']>
  trackingReq?: Maybe<Scalars['Boolean']>
  recordDelivery?: Maybe<Scalars['Boolean']>
  notifySMS?: Maybe<Scalars['Boolean']>
  notifyEmail?: Maybe<Scalars['Boolean']>
  extraBoxInfo?: Maybe<Scalars['Boolean']>
}

export type CarrierServiceUpdateInput = {
  id?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  references?: Maybe<Array<Maybe<Scalars['String']>>>
  maxWeight?: Maybe<Scalars['Decimal']>
  maxHeight?: Maybe<Scalars['Decimal']>
  maxWidth?: Maybe<Scalars['Decimal']>
  maxLength?: Maybe<Scalars['Decimal']>
  nextDay?: Maybe<Scalars['Boolean']>
  saturday?: Maybe<Scalars['Boolean']>
  sunday?: Maybe<Scalars['Boolean']>
  collection?: Maybe<Scalars['Boolean']>
  localCollect?: Maybe<Scalars['Boolean']>
  signReq?: Maybe<Scalars['Boolean']>
  trackingReq?: Maybe<Scalars['Boolean']>
  recordDelivery?: Maybe<Scalars['Boolean']>
  notifySMS?: Maybe<Scalars['Boolean']>
  notifyEmail?: Maybe<Scalars['Boolean']>
  extraBoxInfo?: Maybe<Scalars['Boolean']>
}

export type CarrierSort = {
  id?: Maybe<SortOperationKind>
  code?: Maybe<SortOperationKind>
  name?: Maybe<SortOperationKind>
  module?: Maybe<SortOperationKind>
  telephone?: Maybe<SortOperationKind>
  defDespatchLane?: Maybe<SortOperationKind>
  requireService?: Maybe<SortOperationKind>
  captureDelNo?: Maybe<SortOperationKind>
  defService?: Maybe<SortOperationKind>
}

export type CarrierUpdateInput = {
  id?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  module?: Maybe<Scalars['String']>
  telephone?: Maybe<Scalars['String']>
  defDespatchLane?: Maybe<Scalars['String']>
  requireService?: Maybe<Scalars['Boolean']>
  captureDelNo?: Maybe<Scalars['Boolean']>
  defService?: Maybe<Scalars['String']>
}

export type CategoryDefinition = {
  __typename?: 'CategoryDefinition'
  id?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwner>
  code: Scalars['String']
  description?: Maybe<Scalars['String']>
}

export type CategoryDefinitionFilter = {
  AND?: Maybe<Array<CategoryDefinitionFilter>>
  OR?: Maybe<Array<CategoryDefinitionFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwnerFilter>
  code?: Maybe<Scalars['String']>
  code_not?: Maybe<Scalars['String']>
  code_contains?: Maybe<Scalars['String']>
  code_not_contains?: Maybe<Scalars['String']>
  code_starts_with?: Maybe<Scalars['String']>
  code_not_starts_with?: Maybe<Scalars['String']>
  code_ends_with?: Maybe<Scalars['String']>
  code_not_ends_with?: Maybe<Scalars['String']>
  code_in?: Maybe<Array<Scalars['String']>>
  code_not_in?: Maybe<Array<Scalars['String']>>
  description?: Maybe<Scalars['String']>
  description_not?: Maybe<Scalars['String']>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  description_starts_with?: Maybe<Scalars['String']>
  description_not_starts_with?: Maybe<Scalars['String']>
  description_ends_with?: Maybe<Scalars['String']>
  description_not_ends_with?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type CategoryDefinitionFilterInput = {
  and?: Maybe<Array<CategoryDefinitionFilterInput>>
  or?: Maybe<Array<CategoryDefinitionFilterInput>>
  id?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  stockOwner?: Maybe<StockOwnerFilterInput>
  code?: Maybe<StringOperationFilterInput>
  description?: Maybe<StringOperationFilterInput>
}

export type CategoryDefinitionInput = {
  id?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwnerInput>
  code: Scalars['String']
  description?: Maybe<Scalars['String']>
}

export type CategoryDefinitionUpdateInput = {
  id?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwnerUpdateInput>
  stockOwnerId: Scalars['Int']
  code?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
}

export type CodeDefinition = {
  __typename?: 'CodeDefinition'
  compareTo: Scalars['Int']
  description?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  id: Scalars['Int']
}

export type CodeDefinitionConnection = {
  __typename?: 'CodeDefinitionConnection'
  pageInfo: PageInfo
  edges?: Maybe<Array<CodeDefinitionEdge>>
  nodes?: Maybe<Array<Maybe<CodeDefinition>>>
  totalCount: Scalars['Int']
}

export type CodeDefinitionEdge = {
  __typename?: 'CodeDefinitionEdge'
  cursor: Scalars['String']
  node?: Maybe<CodeDefinition>
}

export type CodeDefinitionFilter = {
  AND?: Maybe<Array<CodeDefinitionFilter>>
  OR?: Maybe<Array<CodeDefinitionFilter>>
  description?: Maybe<Scalars['String']>
  description_not?: Maybe<Scalars['String']>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  description_starts_with?: Maybe<Scalars['String']>
  description_not_starts_with?: Maybe<Scalars['String']>
  description_ends_with?: Maybe<Scalars['String']>
  description_not_ends_with?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Scalars['Int']>>
  id_not_in?: Maybe<Array<Scalars['Int']>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
}

export type CodeDefinitionSort = {
  description?: Maybe<SortOperationKind>
  name?: Maybe<SortOperationKind>
  id?: Maybe<SortOperationKind>
}

export enum CollectWeight {
  None = 'NONE',
  Carton = 'CARTON',
  Pallet = 'PALLET'
}

export type ComparableDateTimeOperationFilterInput = {
  eq?: Maybe<Scalars['DateTime']>
  neq?: Maybe<Scalars['DateTime']>
  in?: Maybe<Array<Scalars['DateTime']>>
  nin?: Maybe<Array<Scalars['DateTime']>>
  gt?: Maybe<Scalars['DateTime']>
  ngt?: Maybe<Scalars['DateTime']>
  gte?: Maybe<Scalars['DateTime']>
  ngte?: Maybe<Scalars['DateTime']>
  lt?: Maybe<Scalars['DateTime']>
  nlt?: Maybe<Scalars['DateTime']>
  lte?: Maybe<Scalars['DateTime']>
  nlte?: Maybe<Scalars['DateTime']>
}

export type ComparableDecimalOperationFilterInput = {
  eq?: Maybe<Scalars['Decimal']>
  neq?: Maybe<Scalars['Decimal']>
  in?: Maybe<Array<Scalars['Decimal']>>
  nin?: Maybe<Array<Scalars['Decimal']>>
  gt?: Maybe<Scalars['Decimal']>
  ngt?: Maybe<Scalars['Decimal']>
  gte?: Maybe<Scalars['Decimal']>
  ngte?: Maybe<Scalars['Decimal']>
  lt?: Maybe<Scalars['Decimal']>
  nlt?: Maybe<Scalars['Decimal']>
  lte?: Maybe<Scalars['Decimal']>
  nlte?: Maybe<Scalars['Decimal']>
}

export type ComparableGuidOperationFilterInput = {
  eq?: Maybe<Scalars['Uuid']>
  neq?: Maybe<Scalars['Uuid']>
  in?: Maybe<Array<Scalars['Uuid']>>
  nin?: Maybe<Array<Scalars['Uuid']>>
  gt?: Maybe<Scalars['Uuid']>
  ngt?: Maybe<Scalars['Uuid']>
  gte?: Maybe<Scalars['Uuid']>
  ngte?: Maybe<Scalars['Uuid']>
  lt?: Maybe<Scalars['Uuid']>
  nlt?: Maybe<Scalars['Uuid']>
  lte?: Maybe<Scalars['Uuid']>
  nlte?: Maybe<Scalars['Uuid']>
}

export type ComparableInt32OperationFilterInput = {
  eq?: Maybe<Scalars['Int']>
  neq?: Maybe<Scalars['Int']>
  in?: Maybe<Array<Scalars['Int']>>
  nin?: Maybe<Array<Scalars['Int']>>
  gt?: Maybe<Scalars['Int']>
  ngt?: Maybe<Scalars['Int']>
  gte?: Maybe<Scalars['Int']>
  ngte?: Maybe<Scalars['Int']>
  lt?: Maybe<Scalars['Int']>
  nlt?: Maybe<Scalars['Int']>
  lte?: Maybe<Scalars['Int']>
  nlte?: Maybe<Scalars['Int']>
}

export type ComparableNullableOfDateTimeOffsetOperationFilterInput = {
  eq?: Maybe<Scalars['DateTime']>
  neq?: Maybe<Scalars['DateTime']>
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  nin?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  gt?: Maybe<Scalars['DateTime']>
  ngt?: Maybe<Scalars['DateTime']>
  gte?: Maybe<Scalars['DateTime']>
  ngte?: Maybe<Scalars['DateTime']>
  lt?: Maybe<Scalars['DateTime']>
  nlt?: Maybe<Scalars['DateTime']>
  lte?: Maybe<Scalars['DateTime']>
  nlte?: Maybe<Scalars['DateTime']>
}

export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: Maybe<Scalars['DateTime']>
  neq?: Maybe<Scalars['DateTime']>
  in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  nin?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  gt?: Maybe<Scalars['DateTime']>
  ngt?: Maybe<Scalars['DateTime']>
  gte?: Maybe<Scalars['DateTime']>
  ngte?: Maybe<Scalars['DateTime']>
  lt?: Maybe<Scalars['DateTime']>
  nlt?: Maybe<Scalars['DateTime']>
  lte?: Maybe<Scalars['DateTime']>
  nlte?: Maybe<Scalars['DateTime']>
}

export type ComparableNullableOfDecimalOperationFilterInput = {
  eq?: Maybe<Scalars['Decimal']>
  neq?: Maybe<Scalars['Decimal']>
  in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  nin?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  gt?: Maybe<Scalars['Decimal']>
  ngt?: Maybe<Scalars['Decimal']>
  gte?: Maybe<Scalars['Decimal']>
  ngte?: Maybe<Scalars['Decimal']>
  lt?: Maybe<Scalars['Decimal']>
  nlt?: Maybe<Scalars['Decimal']>
  lte?: Maybe<Scalars['Decimal']>
  nlte?: Maybe<Scalars['Decimal']>
}

export type ComparableNullableOfInt32OperationFilterInput = {
  eq?: Maybe<Scalars['Int']>
  neq?: Maybe<Scalars['Int']>
  in?: Maybe<Array<Maybe<Scalars['Int']>>>
  nin?: Maybe<Array<Maybe<Scalars['Int']>>>
  gt?: Maybe<Scalars['Int']>
  ngt?: Maybe<Scalars['Int']>
  gte?: Maybe<Scalars['Int']>
  ngte?: Maybe<Scalars['Int']>
  lt?: Maybe<Scalars['Int']>
  nlt?: Maybe<Scalars['Int']>
  lte?: Maybe<Scalars['Int']>
  nlte?: Maybe<Scalars['Int']>
}

export type CompareInfoFilterInput = {
  and?: Maybe<Array<CompareInfoFilterInput>>
  or?: Maybe<Array<CompareInfoFilterInput>>
  name?: Maybe<StringOperationFilterInput>
  version?: Maybe<SortVersionFilterInput>
  lCID?: Maybe<ComparableInt32OperationFilterInput>
}

export type CompareInfoSortInput = {
  name?: Maybe<SortEnumType>
  version?: Maybe<SortVersionSortInput>
  lCID?: Maybe<SortEnumType>
}

export type ConfigOption = {
  __typename?: 'ConfigOption'
  label?: Maybe<Scalars['String']>
  settingName?: Maybe<Scalars['String']>
  position?: Maybe<Array<Scalars['Int']>>
  type?: Maybe<Scalars['String']>
  overrideRequired: Scalars['Boolean']
}

export type Contact = {
  __typename?: 'Contact'
  name?: Maybe<Scalars['String']>
  telephone?: Maybe<Scalars['String']>
  fax?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type ContactFilter = {
  AND?: Maybe<Array<ContactFilter>>
  OR?: Maybe<Array<ContactFilter>>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  telephone?: Maybe<Scalars['String']>
  telephone_not?: Maybe<Scalars['String']>
  telephone_contains?: Maybe<Scalars['String']>
  telephone_not_contains?: Maybe<Scalars['String']>
  telephone_starts_with?: Maybe<Scalars['String']>
  telephone_not_starts_with?: Maybe<Scalars['String']>
  telephone_ends_with?: Maybe<Scalars['String']>
  telephone_not_ends_with?: Maybe<Scalars['String']>
  telephone_in?: Maybe<Array<Maybe<Scalars['String']>>>
  telephone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  fax?: Maybe<Scalars['String']>
  fax_not?: Maybe<Scalars['String']>
  fax_contains?: Maybe<Scalars['String']>
  fax_not_contains?: Maybe<Scalars['String']>
  fax_starts_with?: Maybe<Scalars['String']>
  fax_not_starts_with?: Maybe<Scalars['String']>
  fax_ends_with?: Maybe<Scalars['String']>
  fax_not_ends_with?: Maybe<Scalars['String']>
  fax_in?: Maybe<Array<Maybe<Scalars['String']>>>
  fax_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  email?: Maybe<Scalars['String']>
  email_not?: Maybe<Scalars['String']>
  email_contains?: Maybe<Scalars['String']>
  email_not_contains?: Maybe<Scalars['String']>
  email_starts_with?: Maybe<Scalars['String']>
  email_not_starts_with?: Maybe<Scalars['String']>
  email_ends_with?: Maybe<Scalars['String']>
  email_not_ends_with?: Maybe<Scalars['String']>
  email_in?: Maybe<Array<Maybe<Scalars['String']>>>
  email_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ContactFilterInput = {
  and?: Maybe<Array<ContactFilterInput>>
  or?: Maybe<Array<ContactFilterInput>>
  name?: Maybe<StringOperationFilterInput>
  telephone?: Maybe<StringOperationFilterInput>
  fax?: Maybe<StringOperationFilterInput>
  email?: Maybe<StringOperationFilterInput>
}

export type ContactInput = {
  name?: Maybe<Scalars['String']>
  telephone?: Maybe<Scalars['String']>
  fax?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
}

export type ContactSort = {
  name?: Maybe<SortOperationKind>
  telephone?: Maybe<SortOperationKind>
  fax?: Maybe<SortOperationKind>
  email?: Maybe<SortOperationKind>
}

export type ContactSortInput = {
  name?: Maybe<SortEnumType>
  telephone?: Maybe<SortEnumType>
  fax?: Maybe<SortEnumType>
  email?: Maybe<SortEnumType>
}

export type ContactUpdateInput = {
  name?: Maybe<Scalars['String']>
  telephone?: Maybe<Scalars['String']>
  fax?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
}

export type Country = {
  __typename?: 'Country'
  id: Scalars['Int']
  code?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  depotId?: Maybe<Scalars['String']>
  groupCode?: Maybe<Scalars['String']>
  typeCode?: Maybe<Scalars['String']>
  iSO3?: Maybe<Scalars['String']>
  iSO2?: Maybe<Scalars['String']>
  iSONumber?: Maybe<Scalars['Int']>
}

export type CountryConnection = {
  __typename?: 'CountryConnection'
  pageInfo: PageInfo
  edges?: Maybe<Array<CountryEdge>>
  nodes?: Maybe<Array<Maybe<Country>>>
  totalCount: Scalars['Int']
}

export type CountryEdge = {
  __typename?: 'CountryEdge'
  cursor: Scalars['String']
  node?: Maybe<Country>
}

export type CountryFilter = {
  AND?: Maybe<Array<CountryFilter>>
  OR?: Maybe<Array<CountryFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Scalars['Int']>>
  id_not_in?: Maybe<Array<Scalars['Int']>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
  code_not?: Maybe<Scalars['String']>
  code_contains?: Maybe<Scalars['String']>
  code_not_contains?: Maybe<Scalars['String']>
  code_starts_with?: Maybe<Scalars['String']>
  code_not_starts_with?: Maybe<Scalars['String']>
  code_ends_with?: Maybe<Scalars['String']>
  code_not_ends_with?: Maybe<Scalars['String']>
  code_in?: Maybe<Array<Maybe<Scalars['String']>>>
  code_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  currency?: Maybe<Scalars['String']>
  currency_not?: Maybe<Scalars['String']>
  currency_contains?: Maybe<Scalars['String']>
  currency_not_contains?: Maybe<Scalars['String']>
  currency_starts_with?: Maybe<Scalars['String']>
  currency_not_starts_with?: Maybe<Scalars['String']>
  currency_ends_with?: Maybe<Scalars['String']>
  currency_not_ends_with?: Maybe<Scalars['String']>
  currency_in?: Maybe<Array<Maybe<Scalars['String']>>>
  currency_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  depotId?: Maybe<Scalars['String']>
  depotId_not?: Maybe<Scalars['String']>
  depotId_contains?: Maybe<Scalars['String']>
  depotId_not_contains?: Maybe<Scalars['String']>
  depotId_starts_with?: Maybe<Scalars['String']>
  depotId_not_starts_with?: Maybe<Scalars['String']>
  depotId_ends_with?: Maybe<Scalars['String']>
  depotId_not_ends_with?: Maybe<Scalars['String']>
  depotId_in?: Maybe<Array<Maybe<Scalars['String']>>>
  depotId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  groupCode?: Maybe<Scalars['String']>
  groupCode_not?: Maybe<Scalars['String']>
  groupCode_contains?: Maybe<Scalars['String']>
  groupCode_not_contains?: Maybe<Scalars['String']>
  groupCode_starts_with?: Maybe<Scalars['String']>
  groupCode_not_starts_with?: Maybe<Scalars['String']>
  groupCode_ends_with?: Maybe<Scalars['String']>
  groupCode_not_ends_with?: Maybe<Scalars['String']>
  groupCode_in?: Maybe<Array<Maybe<Scalars['String']>>>
  groupCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  typeCode?: Maybe<Scalars['String']>
  typeCode_not?: Maybe<Scalars['String']>
  typeCode_contains?: Maybe<Scalars['String']>
  typeCode_not_contains?: Maybe<Scalars['String']>
  typeCode_starts_with?: Maybe<Scalars['String']>
  typeCode_not_starts_with?: Maybe<Scalars['String']>
  typeCode_ends_with?: Maybe<Scalars['String']>
  typeCode_not_ends_with?: Maybe<Scalars['String']>
  typeCode_in?: Maybe<Array<Maybe<Scalars['String']>>>
  typeCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  iSO3?: Maybe<Scalars['String']>
  iSO3_not?: Maybe<Scalars['String']>
  iSO3_contains?: Maybe<Scalars['String']>
  iSO3_not_contains?: Maybe<Scalars['String']>
  iSO3_starts_with?: Maybe<Scalars['String']>
  iSO3_not_starts_with?: Maybe<Scalars['String']>
  iSO3_ends_with?: Maybe<Scalars['String']>
  iSO3_not_ends_with?: Maybe<Scalars['String']>
  iSO3_in?: Maybe<Array<Maybe<Scalars['String']>>>
  iSO3_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  iSO2?: Maybe<Scalars['String']>
  iSO2_not?: Maybe<Scalars['String']>
  iSO2_contains?: Maybe<Scalars['String']>
  iSO2_not_contains?: Maybe<Scalars['String']>
  iSO2_starts_with?: Maybe<Scalars['String']>
  iSO2_not_starts_with?: Maybe<Scalars['String']>
  iSO2_ends_with?: Maybe<Scalars['String']>
  iSO2_not_ends_with?: Maybe<Scalars['String']>
  iSO2_in?: Maybe<Array<Maybe<Scalars['String']>>>
  iSO2_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  iSONumber?: Maybe<Scalars['Int']>
  iSONumber_not?: Maybe<Scalars['Int']>
  iSONumber_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  iSONumber_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  iSONumber_gt?: Maybe<Scalars['Int']>
  iSONumber_not_gt?: Maybe<Scalars['Int']>
  iSONumber_gte?: Maybe<Scalars['Int']>
  iSONumber_not_gte?: Maybe<Scalars['Int']>
  iSONumber_lt?: Maybe<Scalars['Int']>
  iSONumber_not_lt?: Maybe<Scalars['Int']>
  iSONumber_lte?: Maybe<Scalars['Int']>
  iSONumber_not_lte?: Maybe<Scalars['Int']>
}

export type CountrySort = {
  id?: Maybe<SortOperationKind>
  code?: Maybe<SortOperationKind>
  name?: Maybe<SortOperationKind>
  currency?: Maybe<SortOperationKind>
  depotId?: Maybe<SortOperationKind>
  groupCode?: Maybe<SortOperationKind>
  typeCode?: Maybe<SortOperationKind>
  iSO3?: Maybe<SortOperationKind>
  iSO2?: Maybe<SortOperationKind>
  iSONumber?: Maybe<SortOperationKind>
}

export type CreateOrderEntryInput = {
  orderNumber: Scalars['String']
  destinationCode: Scalars['ID']
  budgetHolder: Scalars['ID']
  priority: Scalars['Int']
  orderType: Scalars['ID']
  soCustomerRef: Scalars['String']
}

export type CultureInfo = {
  __typename?: 'CultureInfo'
  displayName: Scalars['String']
  englishName: Scalars['String']
  isNeutralCulture: Scalars['Boolean']
  LCID: Scalars['Int']
  name: Scalars['String']
  nativeName: Scalars['String']
  threeLetterISOLanguageName: Scalars['String']
  threeLetterWindowsLanguageName: Scalars['String']
  twoLetterISOLanguageName: Scalars['String']
}

export type CultureInfoConnection = {
  __typename?: 'CultureInfoConnection'
  pageInfo: PageInfo
  edges?: Maybe<Array<CultureInfoEdge>>
  nodes?: Maybe<Array<Maybe<CultureInfo>>>
  totalCount: Scalars['Int']
}

export type CultureInfoEdge = {
  __typename?: 'CultureInfoEdge'
  cursor: Scalars['String']
  node?: Maybe<CultureInfo>
}

export type CultureInfoFilterInput = {
  and?: Maybe<Array<CultureInfoFilterInput>>
  or?: Maybe<Array<CultureInfoFilterInput>>
  parent?: Maybe<CultureInfoFilterInput>
  lCID?: Maybe<ComparableInt32OperationFilterInput>
  keyboardLayoutId?: Maybe<ComparableInt32OperationFilterInput>
  name?: Maybe<StringOperationFilterInput>
  ietfLanguageTag?: Maybe<StringOperationFilterInput>
  displayName?: Maybe<StringOperationFilterInput>
  nativeName?: Maybe<StringOperationFilterInput>
  englishName?: Maybe<StringOperationFilterInput>
  twoLetterISOLanguageName?: Maybe<StringOperationFilterInput>
  threeLetterISOLanguageName?: Maybe<StringOperationFilterInput>
  threeLetterWindowsLanguageName?: Maybe<StringOperationFilterInput>
  compareInfo?: Maybe<CompareInfoFilterInput>
  textInfo?: Maybe<TextInfoFilterInput>
  isNeutralCulture?: Maybe<BooleanOperationFilterInput>
  cultureTypes?: Maybe<CultureTypesOperationFilterInput>
  numberFormat?: Maybe<NumberFormatInfoFilterInput>
  dateTimeFormat?: Maybe<DateTimeFormatInfoFilterInput>
  calendar?: Maybe<CalendarFilterInput>
  optionalCalendars?: Maybe<ListFilterInputTypeOfCalendarFilterInput>
  useUserOverride?: Maybe<BooleanOperationFilterInput>
  isReadOnly?: Maybe<BooleanOperationFilterInput>
}

export type CultureInfoSortInput = {
  parent?: Maybe<CultureInfoSortInput>
  lCID?: Maybe<SortEnumType>
  keyboardLayoutId?: Maybe<SortEnumType>
  name?: Maybe<SortEnumType>
  ietfLanguageTag?: Maybe<SortEnumType>
  displayName?: Maybe<SortEnumType>
  nativeName?: Maybe<SortEnumType>
  englishName?: Maybe<SortEnumType>
  twoLetterISOLanguageName?: Maybe<SortEnumType>
  threeLetterISOLanguageName?: Maybe<SortEnumType>
  threeLetterWindowsLanguageName?: Maybe<SortEnumType>
  compareInfo?: Maybe<CompareInfoSortInput>
  textInfo?: Maybe<TextInfoSortInput>
  isNeutralCulture?: Maybe<SortEnumType>
  cultureTypes?: Maybe<SortEnumType>
  numberFormat?: Maybe<NumberFormatInfoSortInput>
  dateTimeFormat?: Maybe<DateTimeFormatInfoSortInput>
  calendar?: Maybe<CalendarSortInput>
  useUserOverride?: Maybe<SortEnumType>
  isReadOnly?: Maybe<SortEnumType>
}

export enum CultureTypes {
  NeutralCultures = 'NEUTRAL_CULTURES',
  SpecificCultures = 'SPECIFIC_CULTURES',
  InstalledWin32Cultures = 'INSTALLED_WIN32_CULTURES',
  AllCultures = 'ALL_CULTURES',
  UserCustomCulture = 'USER_CUSTOM_CULTURE',
  ReplacementCultures = 'REPLACEMENT_CULTURES',
  WindowsOnlyCultures = 'WINDOWS_ONLY_CULTURES',
  FrameworkCultures = 'FRAMEWORK_CULTURES'
}

export type CultureTypesOperationFilterInput = {
  eq?: Maybe<CultureTypes>
  neq?: Maybe<CultureTypes>
  in?: Maybe<Array<CultureTypes>>
  nin?: Maybe<Array<CultureTypes>>
}

export enum Currency {
  Gbp = 'GBP',
  Eur = 'EUR',
  Usd = 'USD',
  Can = 'CAN',
  Jpy = 'JPY',
  Trl = 'TRL',
  Aud = 'AUD',
  Zmw = 'ZMW',
  Ugx = 'UGX',
  Rub = 'RUB',
  Afn = 'AFN',
  All = 'ALL',
  Dzd = 'DZD',
  Aoa = 'AOA',
  Xcd = 'XCD',
  Ars = 'ARS',
  Amd = 'AMD'
}

export type DatabaseError = {
  __typename?: 'DatabaseError'
  id: Scalars['Int']
  message?: Maybe<Scalars['String']>
}

export type DateTimeFormatInfoFilterInput = {
  and?: Maybe<Array<DateTimeFormatInfoFilterInput>>
  or?: Maybe<Array<DateTimeFormatInfoFilterInput>>
  aMDesignator?: Maybe<StringOperationFilterInput>
  calendar?: Maybe<CalendarFilterInput>
  dateSeparator?: Maybe<StringOperationFilterInput>
  firstDayOfWeek?: Maybe<DayOfWeekOperationFilterInput>
  calendarWeekRule?: Maybe<CalendarWeekRuleOperationFilterInput>
  fullDateTimePattern?: Maybe<StringOperationFilterInput>
  longDatePattern?: Maybe<StringOperationFilterInput>
  longTimePattern?: Maybe<StringOperationFilterInput>
  monthDayPattern?: Maybe<StringOperationFilterInput>
  pMDesignator?: Maybe<StringOperationFilterInput>
  rFC1123Pattern?: Maybe<StringOperationFilterInput>
  shortDatePattern?: Maybe<StringOperationFilterInput>
  shortTimePattern?: Maybe<StringOperationFilterInput>
  sortableDateTimePattern?: Maybe<StringOperationFilterInput>
  timeSeparator?: Maybe<StringOperationFilterInput>
  universalSortableDateTimePattern?: Maybe<StringOperationFilterInput>
  yearMonthPattern?: Maybe<StringOperationFilterInput>
  abbreviatedDayNames?: Maybe<ListStringOperationFilterInput>
  shortestDayNames?: Maybe<ListStringOperationFilterInput>
  dayNames?: Maybe<ListStringOperationFilterInput>
  abbreviatedMonthNames?: Maybe<ListStringOperationFilterInput>
  monthNames?: Maybe<ListStringOperationFilterInput>
  isReadOnly?: Maybe<BooleanOperationFilterInput>
  nativeCalendarName?: Maybe<StringOperationFilterInput>
  abbreviatedMonthGenitiveNames?: Maybe<ListStringOperationFilterInput>
  monthGenitiveNames?: Maybe<ListStringOperationFilterInput>
}

export type DateTimeFormatInfoSortInput = {
  aMDesignator?: Maybe<SortEnumType>
  calendar?: Maybe<CalendarSortInput>
  dateSeparator?: Maybe<SortEnumType>
  firstDayOfWeek?: Maybe<SortEnumType>
  calendarWeekRule?: Maybe<SortEnumType>
  fullDateTimePattern?: Maybe<SortEnumType>
  longDatePattern?: Maybe<SortEnumType>
  longTimePattern?: Maybe<SortEnumType>
  monthDayPattern?: Maybe<SortEnumType>
  pMDesignator?: Maybe<SortEnumType>
  rFC1123Pattern?: Maybe<SortEnumType>
  shortDatePattern?: Maybe<SortEnumType>
  shortTimePattern?: Maybe<SortEnumType>
  sortableDateTimePattern?: Maybe<SortEnumType>
  timeSeparator?: Maybe<SortEnumType>
  universalSortableDateTimePattern?: Maybe<SortEnumType>
  yearMonthPattern?: Maybe<SortEnumType>
  isReadOnly?: Maybe<SortEnumType>
  nativeCalendarName?: Maybe<SortEnumType>
}

export enum DayOfWeek {
  Sunday = 'SUNDAY',
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY'
}

export type DayOfWeekOperationFilterInput = {
  eq?: Maybe<DayOfWeek>
  neq?: Maybe<DayOfWeek>
  in?: Maybe<Array<DayOfWeek>>
  nin?: Maybe<Array<DayOfWeek>>
}

export type Destination = {
  __typename?: 'Destination'
  id?: Maybe<Scalars['Int']>
  code: Scalars['String']
  name: Scalars['String']
  address: Address
  status: State
  contact?: Maybe<Contact>
  salesman?: Maybe<Scalars['String']>
  VATNo?: Maybe<Scalars['String']>
  facility?: Maybe<Scalars['String']>
  category?: Maybe<Scalars['String']>
  stockOwnerId?: Maybe<Scalars['Int']>
  owner?: Maybe<StockOwner>
  masterAcc?: Maybe<Scalars['String']>
  budgetholder?: Maybe<Scalars['String']>
  area?: Maybe<Scalars['String']>
  defRoute?: Maybe<Scalars['String']>
  pickLocation?: Maybe<Scalars['String']>
  custStkCodeOnDespNotes?: Maybe<Scalars['Boolean']>
  despNoteFormat?: Maybe<Scalars['Int']>
  printNotesDelayDesp?: Maybe<Scalars['Boolean']>
  noDespNotes?: Maybe<Scalars['Boolean']>
  printBoxNotes?: Maybe<Scalars['Boolean']>
  printBoxLbls?: Maybe<Scalars['Boolean']>
  FTPAcc?: Maybe<Scalars['String']>
  depot?: Maybe<Scalars['String']>
  masterHub?: Maybe<Scalars['String']>
  subHub?: Maybe<Scalars['String']>
  GLN?: Maybe<Scalars['String']>
  customsId?: Maybe<Scalars['String']>
  defDelGroup?: Maybe<Scalars['String']>
  despNoteEmail?: Maybe<Scalars['String']>
  useDespMethMBP?: Maybe<Scalars['Boolean']>
  routing?: Maybe<Array<Maybe<DestinationRoute>>>
  budgets?: Maybe<Array<Maybe<DestinationBudget>>>
  defOrderType?: Maybe<Scalars['String']>
  defOrderPri?: Maybe<Scalars['String']>
  despMethod?: Maybe<Scalars['String']>
  transitTime?: Maybe<Scalars['Int']>
  defDespLane?: Maybe<Scalars['String']>
  orderFreq?: Maybe<Frequency>
  orderDay?: Maybe<DayOfWeek>
  defSpecIns?: Maybe<Scalars['String']>
  minPickValue?: Maybe<Scalars['Int']>
  reqManifest?: Maybe<Scalars['Boolean']>
  usePalletMethod?: Maybe<Scalars['Boolean']>
  palletMethod?: Maybe<Scalars['String']>
  ASNOnDespatch?: Maybe<Scalars['Boolean']>
  ASNMethod?: Maybe<Scalars['String']>
  captureSerialOnDesp?: Maybe<Scalars['Boolean']>
  suppressBackOrdClose?: Maybe<Scalars['Boolean']>
  noPickConsolidation?: Maybe<Scalars['Boolean']>
}

export type DestinationBudget = {
  __typename?: 'DestinationBudget'
  id?: Maybe<Scalars['Int']>
  destination?: Maybe<Destination>
  startDate: Scalars['DateTime']
  maxSpend: Scalars['Decimal']
  spent?: Maybe<Scalars['Decimal']>
}

export type DestinationBudgetFilter = {
  AND?: Maybe<Array<DestinationBudgetFilter>>
  OR?: Maybe<Array<DestinationBudgetFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  destination?: Maybe<DestinationFilter>
  startDate?: Maybe<Scalars['DateTime']>
  startDate_not?: Maybe<Scalars['DateTime']>
  startDate_in?: Maybe<Array<Scalars['DateTime']>>
  startDate_not_in?: Maybe<Array<Scalars['DateTime']>>
  startDate_gt?: Maybe<Scalars['DateTime']>
  startDate_not_gt?: Maybe<Scalars['DateTime']>
  startDate_gte?: Maybe<Scalars['DateTime']>
  startDate_not_gte?: Maybe<Scalars['DateTime']>
  startDate_lt?: Maybe<Scalars['DateTime']>
  startDate_not_lt?: Maybe<Scalars['DateTime']>
  startDate_lte?: Maybe<Scalars['DateTime']>
  startDate_not_lte?: Maybe<Scalars['DateTime']>
  maxSpend?: Maybe<Scalars['Decimal']>
  maxSpend_not?: Maybe<Scalars['Decimal']>
  maxSpend_in?: Maybe<Array<Scalars['Decimal']>>
  maxSpend_not_in?: Maybe<Array<Scalars['Decimal']>>
  maxSpend_gt?: Maybe<Scalars['Decimal']>
  maxSpend_not_gt?: Maybe<Scalars['Decimal']>
  maxSpend_gte?: Maybe<Scalars['Decimal']>
  maxSpend_not_gte?: Maybe<Scalars['Decimal']>
  maxSpend_lt?: Maybe<Scalars['Decimal']>
  maxSpend_not_lt?: Maybe<Scalars['Decimal']>
  maxSpend_lte?: Maybe<Scalars['Decimal']>
  maxSpend_not_lte?: Maybe<Scalars['Decimal']>
  spent?: Maybe<Scalars['Decimal']>
  spent_not?: Maybe<Scalars['Decimal']>
  spent_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  spent_not_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  spent_gt?: Maybe<Scalars['Decimal']>
  spent_not_gt?: Maybe<Scalars['Decimal']>
  spent_gte?: Maybe<Scalars['Decimal']>
  spent_not_gte?: Maybe<Scalars['Decimal']>
  spent_lt?: Maybe<Scalars['Decimal']>
  spent_not_lt?: Maybe<Scalars['Decimal']>
  spent_lte?: Maybe<Scalars['Decimal']>
  spent_not_lte?: Maybe<Scalars['Decimal']>
}

export type DestinationBudgetInput = {
  id?: Maybe<Scalars['Int']>
  destination?: Maybe<DestinationInput>
  startDate: Scalars['DateTime']
  maxSpend: Scalars['Decimal']
  spent: Scalars['Int']
}

export type DestinationBudgetUpdateInput = {
  startDate: Scalars['DateTime']
  maxSpend?: Maybe<Scalars['Decimal']>
  spent?: Maybe<Scalars['Decimal']>
  id?: Maybe<Scalars['Int']>
}

export type DestinationCode = {
  __typename?: 'DestinationCode'
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
}

export type DestinationConnection = {
  __typename?: 'DestinationConnection'
  pageInfo: PageInfo
  edges?: Maybe<Array<DestinationEdge>>
  nodes?: Maybe<Array<Maybe<Destination>>>
  totalCount: Scalars['Int']
}

export type DestinationEdge = {
  __typename?: 'DestinationEdge'
  cursor: Scalars['String']
  node?: Maybe<Destination>
}

export type DestinationFilter = {
  AND?: Maybe<Array<DestinationFilter>>
  OR?: Maybe<Array<DestinationFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
  code_not?: Maybe<Scalars['String']>
  code_contains?: Maybe<Scalars['String']>
  code_not_contains?: Maybe<Scalars['String']>
  code_starts_with?: Maybe<Scalars['String']>
  code_not_starts_with?: Maybe<Scalars['String']>
  code_ends_with?: Maybe<Scalars['String']>
  code_not_ends_with?: Maybe<Scalars['String']>
  code_in?: Maybe<Array<Scalars['String']>>
  code_not_in?: Maybe<Array<Scalars['String']>>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Scalars['String']>>
  name_not_in?: Maybe<Array<Scalars['String']>>
  address?: Maybe<AddressFilter>
  status?: Maybe<State>
  status_not?: Maybe<State>
  status_in?: Maybe<Array<State>>
  status_not_in?: Maybe<Array<State>>
  status_gt?: Maybe<State>
  status_not_gt?: Maybe<State>
  status_gte?: Maybe<State>
  status_not_gte?: Maybe<State>
  status_lt?: Maybe<State>
  status_not_lt?: Maybe<State>
  status_lte?: Maybe<State>
  status_not_lte?: Maybe<State>
  contact?: Maybe<ContactFilter>
  salesman?: Maybe<Scalars['String']>
  salesman_not?: Maybe<Scalars['String']>
  salesman_contains?: Maybe<Scalars['String']>
  salesman_not_contains?: Maybe<Scalars['String']>
  salesman_starts_with?: Maybe<Scalars['String']>
  salesman_not_starts_with?: Maybe<Scalars['String']>
  salesman_ends_with?: Maybe<Scalars['String']>
  salesman_not_ends_with?: Maybe<Scalars['String']>
  salesman_in?: Maybe<Array<Maybe<Scalars['String']>>>
  salesman_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  VATNo?: Maybe<Scalars['String']>
  VATNo_not?: Maybe<Scalars['String']>
  VATNo_contains?: Maybe<Scalars['String']>
  VATNo_not_contains?: Maybe<Scalars['String']>
  VATNo_starts_with?: Maybe<Scalars['String']>
  VATNo_not_starts_with?: Maybe<Scalars['String']>
  VATNo_ends_with?: Maybe<Scalars['String']>
  VATNo_not_ends_with?: Maybe<Scalars['String']>
  VATNo_in?: Maybe<Array<Maybe<Scalars['String']>>>
  VATNo_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  facility?: Maybe<Scalars['String']>
  facility_not?: Maybe<Scalars['String']>
  facility_contains?: Maybe<Scalars['String']>
  facility_not_contains?: Maybe<Scalars['String']>
  facility_starts_with?: Maybe<Scalars['String']>
  facility_not_starts_with?: Maybe<Scalars['String']>
  facility_ends_with?: Maybe<Scalars['String']>
  facility_not_ends_with?: Maybe<Scalars['String']>
  facility_in?: Maybe<Array<Maybe<Scalars['String']>>>
  facility_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  category?: Maybe<Scalars['String']>
  category_not?: Maybe<Scalars['String']>
  category_contains?: Maybe<Scalars['String']>
  category_not_contains?: Maybe<Scalars['String']>
  category_starts_with?: Maybe<Scalars['String']>
  category_not_starts_with?: Maybe<Scalars['String']>
  category_ends_with?: Maybe<Scalars['String']>
  category_not_ends_with?: Maybe<Scalars['String']>
  category_in?: Maybe<Array<Maybe<Scalars['String']>>>
  category_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  stockOwnerId?: Maybe<Scalars['Int']>
  stockOwnerId_not?: Maybe<Scalars['Int']>
  stockOwnerId_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  stockOwnerId_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  stockOwnerId_gt?: Maybe<Scalars['Int']>
  stockOwnerId_not_gt?: Maybe<Scalars['Int']>
  stockOwnerId_gte?: Maybe<Scalars['Int']>
  stockOwnerId_not_gte?: Maybe<Scalars['Int']>
  stockOwnerId_lt?: Maybe<Scalars['Int']>
  stockOwnerId_not_lt?: Maybe<Scalars['Int']>
  stockOwnerId_lte?: Maybe<Scalars['Int']>
  stockOwnerId_not_lte?: Maybe<Scalars['Int']>
  owner?: Maybe<StockOwnerFilter>
  masterAcc?: Maybe<Scalars['String']>
  masterAcc_not?: Maybe<Scalars['String']>
  masterAcc_contains?: Maybe<Scalars['String']>
  masterAcc_not_contains?: Maybe<Scalars['String']>
  masterAcc_starts_with?: Maybe<Scalars['String']>
  masterAcc_not_starts_with?: Maybe<Scalars['String']>
  masterAcc_ends_with?: Maybe<Scalars['String']>
  masterAcc_not_ends_with?: Maybe<Scalars['String']>
  masterAcc_in?: Maybe<Array<Maybe<Scalars['String']>>>
  masterAcc_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  budgetholder?: Maybe<Scalars['String']>
  budgetholder_not?: Maybe<Scalars['String']>
  budgetholder_contains?: Maybe<Scalars['String']>
  budgetholder_not_contains?: Maybe<Scalars['String']>
  budgetholder_starts_with?: Maybe<Scalars['String']>
  budgetholder_not_starts_with?: Maybe<Scalars['String']>
  budgetholder_ends_with?: Maybe<Scalars['String']>
  budgetholder_not_ends_with?: Maybe<Scalars['String']>
  budgetholder_in?: Maybe<Array<Maybe<Scalars['String']>>>
  budgetholder_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  area?: Maybe<Scalars['String']>
  area_not?: Maybe<Scalars['String']>
  area_contains?: Maybe<Scalars['String']>
  area_not_contains?: Maybe<Scalars['String']>
  area_starts_with?: Maybe<Scalars['String']>
  area_not_starts_with?: Maybe<Scalars['String']>
  area_ends_with?: Maybe<Scalars['String']>
  area_not_ends_with?: Maybe<Scalars['String']>
  area_in?: Maybe<Array<Maybe<Scalars['String']>>>
  area_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defRoute?: Maybe<Scalars['String']>
  defRoute_not?: Maybe<Scalars['String']>
  defRoute_contains?: Maybe<Scalars['String']>
  defRoute_not_contains?: Maybe<Scalars['String']>
  defRoute_starts_with?: Maybe<Scalars['String']>
  defRoute_not_starts_with?: Maybe<Scalars['String']>
  defRoute_ends_with?: Maybe<Scalars['String']>
  defRoute_not_ends_with?: Maybe<Scalars['String']>
  defRoute_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defRoute_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  pickLocation?: Maybe<Scalars['String']>
  pickLocation_not?: Maybe<Scalars['String']>
  pickLocation_contains?: Maybe<Scalars['String']>
  pickLocation_not_contains?: Maybe<Scalars['String']>
  pickLocation_starts_with?: Maybe<Scalars['String']>
  pickLocation_not_starts_with?: Maybe<Scalars['String']>
  pickLocation_ends_with?: Maybe<Scalars['String']>
  pickLocation_not_ends_with?: Maybe<Scalars['String']>
  pickLocation_in?: Maybe<Array<Maybe<Scalars['String']>>>
  pickLocation_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  custStkCodeOnDespNotes?: Maybe<Scalars['Boolean']>
  custStkCodeOnDespNotes_not?: Maybe<Scalars['Boolean']>
  despNoteFormat?: Maybe<Scalars['Int']>
  despNoteFormat_not?: Maybe<Scalars['Int']>
  despNoteFormat_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  despNoteFormat_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  despNoteFormat_gt?: Maybe<Scalars['Int']>
  despNoteFormat_not_gt?: Maybe<Scalars['Int']>
  despNoteFormat_gte?: Maybe<Scalars['Int']>
  despNoteFormat_not_gte?: Maybe<Scalars['Int']>
  despNoteFormat_lt?: Maybe<Scalars['Int']>
  despNoteFormat_not_lt?: Maybe<Scalars['Int']>
  despNoteFormat_lte?: Maybe<Scalars['Int']>
  despNoteFormat_not_lte?: Maybe<Scalars['Int']>
  printNotesDelayDesp?: Maybe<Scalars['Boolean']>
  printNotesDelayDesp_not?: Maybe<Scalars['Boolean']>
  noDespNotes?: Maybe<Scalars['Boolean']>
  noDespNotes_not?: Maybe<Scalars['Boolean']>
  printBoxNotes?: Maybe<Scalars['Boolean']>
  printBoxNotes_not?: Maybe<Scalars['Boolean']>
  printBoxLbls?: Maybe<Scalars['Boolean']>
  printBoxLbls_not?: Maybe<Scalars['Boolean']>
  FTPAcc?: Maybe<Scalars['String']>
  FTPAcc_not?: Maybe<Scalars['String']>
  FTPAcc_contains?: Maybe<Scalars['String']>
  FTPAcc_not_contains?: Maybe<Scalars['String']>
  FTPAcc_starts_with?: Maybe<Scalars['String']>
  FTPAcc_not_starts_with?: Maybe<Scalars['String']>
  FTPAcc_ends_with?: Maybe<Scalars['String']>
  FTPAcc_not_ends_with?: Maybe<Scalars['String']>
  FTPAcc_in?: Maybe<Array<Maybe<Scalars['String']>>>
  FTPAcc_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  depot?: Maybe<Scalars['String']>
  depot_not?: Maybe<Scalars['String']>
  depot_contains?: Maybe<Scalars['String']>
  depot_not_contains?: Maybe<Scalars['String']>
  depot_starts_with?: Maybe<Scalars['String']>
  depot_not_starts_with?: Maybe<Scalars['String']>
  depot_ends_with?: Maybe<Scalars['String']>
  depot_not_ends_with?: Maybe<Scalars['String']>
  depot_in?: Maybe<Array<Maybe<Scalars['String']>>>
  depot_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  masterHub?: Maybe<Scalars['String']>
  masterHub_not?: Maybe<Scalars['String']>
  masterHub_contains?: Maybe<Scalars['String']>
  masterHub_not_contains?: Maybe<Scalars['String']>
  masterHub_starts_with?: Maybe<Scalars['String']>
  masterHub_not_starts_with?: Maybe<Scalars['String']>
  masterHub_ends_with?: Maybe<Scalars['String']>
  masterHub_not_ends_with?: Maybe<Scalars['String']>
  masterHub_in?: Maybe<Array<Maybe<Scalars['String']>>>
  masterHub_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  subHub?: Maybe<Scalars['String']>
  subHub_not?: Maybe<Scalars['String']>
  subHub_contains?: Maybe<Scalars['String']>
  subHub_not_contains?: Maybe<Scalars['String']>
  subHub_starts_with?: Maybe<Scalars['String']>
  subHub_not_starts_with?: Maybe<Scalars['String']>
  subHub_ends_with?: Maybe<Scalars['String']>
  subHub_not_ends_with?: Maybe<Scalars['String']>
  subHub_in?: Maybe<Array<Maybe<Scalars['String']>>>
  subHub_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  GLN?: Maybe<Scalars['String']>
  GLN_not?: Maybe<Scalars['String']>
  GLN_contains?: Maybe<Scalars['String']>
  GLN_not_contains?: Maybe<Scalars['String']>
  GLN_starts_with?: Maybe<Scalars['String']>
  GLN_not_starts_with?: Maybe<Scalars['String']>
  GLN_ends_with?: Maybe<Scalars['String']>
  GLN_not_ends_with?: Maybe<Scalars['String']>
  GLN_in?: Maybe<Array<Maybe<Scalars['String']>>>
  GLN_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  customsId?: Maybe<Scalars['String']>
  customsId_not?: Maybe<Scalars['String']>
  customsId_contains?: Maybe<Scalars['String']>
  customsId_not_contains?: Maybe<Scalars['String']>
  customsId_starts_with?: Maybe<Scalars['String']>
  customsId_not_starts_with?: Maybe<Scalars['String']>
  customsId_ends_with?: Maybe<Scalars['String']>
  customsId_not_ends_with?: Maybe<Scalars['String']>
  customsId_in?: Maybe<Array<Maybe<Scalars['String']>>>
  customsId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defDelGroup?: Maybe<Scalars['String']>
  defDelGroup_not?: Maybe<Scalars['String']>
  defDelGroup_contains?: Maybe<Scalars['String']>
  defDelGroup_not_contains?: Maybe<Scalars['String']>
  defDelGroup_starts_with?: Maybe<Scalars['String']>
  defDelGroup_not_starts_with?: Maybe<Scalars['String']>
  defDelGroup_ends_with?: Maybe<Scalars['String']>
  defDelGroup_not_ends_with?: Maybe<Scalars['String']>
  defDelGroup_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defDelGroup_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  despNoteEmail?: Maybe<Scalars['String']>
  despNoteEmail_not?: Maybe<Scalars['String']>
  despNoteEmail_contains?: Maybe<Scalars['String']>
  despNoteEmail_not_contains?: Maybe<Scalars['String']>
  despNoteEmail_starts_with?: Maybe<Scalars['String']>
  despNoteEmail_not_starts_with?: Maybe<Scalars['String']>
  despNoteEmail_ends_with?: Maybe<Scalars['String']>
  despNoteEmail_not_ends_with?: Maybe<Scalars['String']>
  despNoteEmail_in?: Maybe<Array<Maybe<Scalars['String']>>>
  despNoteEmail_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  useDespMethMBP?: Maybe<Scalars['Boolean']>
  useDespMethMBP_not?: Maybe<Scalars['Boolean']>
  routing_some?: Maybe<DestinationRouteFilter>
  routing_none?: Maybe<DestinationRouteFilter>
  routing_all?: Maybe<DestinationRouteFilter>
  routing_any?: Maybe<Scalars['Boolean']>
  budgets_some?: Maybe<DestinationBudgetFilter>
  budgets_none?: Maybe<DestinationBudgetFilter>
  budgets_all?: Maybe<DestinationBudgetFilter>
  budgets_any?: Maybe<Scalars['Boolean']>
  defOrderType?: Maybe<Scalars['String']>
  defOrderType_not?: Maybe<Scalars['String']>
  defOrderType_contains?: Maybe<Scalars['String']>
  defOrderType_not_contains?: Maybe<Scalars['String']>
  defOrderType_starts_with?: Maybe<Scalars['String']>
  defOrderType_not_starts_with?: Maybe<Scalars['String']>
  defOrderType_ends_with?: Maybe<Scalars['String']>
  defOrderType_not_ends_with?: Maybe<Scalars['String']>
  defOrderType_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defOrderType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defOrderPri?: Maybe<Scalars['String']>
  defOrderPri_not?: Maybe<Scalars['String']>
  defOrderPri_contains?: Maybe<Scalars['String']>
  defOrderPri_not_contains?: Maybe<Scalars['String']>
  defOrderPri_starts_with?: Maybe<Scalars['String']>
  defOrderPri_not_starts_with?: Maybe<Scalars['String']>
  defOrderPri_ends_with?: Maybe<Scalars['String']>
  defOrderPri_not_ends_with?: Maybe<Scalars['String']>
  defOrderPri_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defOrderPri_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  despMethod?: Maybe<Scalars['String']>
  despMethod_not?: Maybe<Scalars['String']>
  despMethod_contains?: Maybe<Scalars['String']>
  despMethod_not_contains?: Maybe<Scalars['String']>
  despMethod_starts_with?: Maybe<Scalars['String']>
  despMethod_not_starts_with?: Maybe<Scalars['String']>
  despMethod_ends_with?: Maybe<Scalars['String']>
  despMethod_not_ends_with?: Maybe<Scalars['String']>
  despMethod_in?: Maybe<Array<Maybe<Scalars['String']>>>
  despMethod_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  transitTime?: Maybe<Scalars['Int']>
  transitTime_not?: Maybe<Scalars['Int']>
  transitTime_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  transitTime_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  transitTime_gt?: Maybe<Scalars['Int']>
  transitTime_not_gt?: Maybe<Scalars['Int']>
  transitTime_gte?: Maybe<Scalars['Int']>
  transitTime_not_gte?: Maybe<Scalars['Int']>
  transitTime_lt?: Maybe<Scalars['Int']>
  transitTime_not_lt?: Maybe<Scalars['Int']>
  transitTime_lte?: Maybe<Scalars['Int']>
  transitTime_not_lte?: Maybe<Scalars['Int']>
  defDespLane?: Maybe<Scalars['String']>
  defDespLane_not?: Maybe<Scalars['String']>
  defDespLane_contains?: Maybe<Scalars['String']>
  defDespLane_not_contains?: Maybe<Scalars['String']>
  defDespLane_starts_with?: Maybe<Scalars['String']>
  defDespLane_not_starts_with?: Maybe<Scalars['String']>
  defDespLane_ends_with?: Maybe<Scalars['String']>
  defDespLane_not_ends_with?: Maybe<Scalars['String']>
  defDespLane_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defDespLane_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  orderFreq?: Maybe<Frequency>
  orderFreq_not?: Maybe<Frequency>
  orderFreq_in?: Maybe<Array<Maybe<Frequency>>>
  orderFreq_not_in?: Maybe<Array<Maybe<Frequency>>>
  orderFreq_gt?: Maybe<Frequency>
  orderFreq_not_gt?: Maybe<Frequency>
  orderFreq_gte?: Maybe<Frequency>
  orderFreq_not_gte?: Maybe<Frequency>
  orderFreq_lt?: Maybe<Frequency>
  orderFreq_not_lt?: Maybe<Frequency>
  orderFreq_lte?: Maybe<Frequency>
  orderFreq_not_lte?: Maybe<Frequency>
  orderDay?: Maybe<DayOfWeek>
  orderDay_not?: Maybe<DayOfWeek>
  orderDay_in?: Maybe<Array<Maybe<DayOfWeek>>>
  orderDay_not_in?: Maybe<Array<Maybe<DayOfWeek>>>
  orderDay_gt?: Maybe<DayOfWeek>
  orderDay_not_gt?: Maybe<DayOfWeek>
  orderDay_gte?: Maybe<DayOfWeek>
  orderDay_not_gte?: Maybe<DayOfWeek>
  orderDay_lt?: Maybe<DayOfWeek>
  orderDay_not_lt?: Maybe<DayOfWeek>
  orderDay_lte?: Maybe<DayOfWeek>
  orderDay_not_lte?: Maybe<DayOfWeek>
  defSpecIns?: Maybe<Scalars['String']>
  defSpecIns_not?: Maybe<Scalars['String']>
  defSpecIns_contains?: Maybe<Scalars['String']>
  defSpecIns_not_contains?: Maybe<Scalars['String']>
  defSpecIns_starts_with?: Maybe<Scalars['String']>
  defSpecIns_not_starts_with?: Maybe<Scalars['String']>
  defSpecIns_ends_with?: Maybe<Scalars['String']>
  defSpecIns_not_ends_with?: Maybe<Scalars['String']>
  defSpecIns_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defSpecIns_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  minPickValue?: Maybe<Scalars['Int']>
  minPickValue_not?: Maybe<Scalars['Int']>
  minPickValue_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  minPickValue_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  minPickValue_gt?: Maybe<Scalars['Int']>
  minPickValue_not_gt?: Maybe<Scalars['Int']>
  minPickValue_gte?: Maybe<Scalars['Int']>
  minPickValue_not_gte?: Maybe<Scalars['Int']>
  minPickValue_lt?: Maybe<Scalars['Int']>
  minPickValue_not_lt?: Maybe<Scalars['Int']>
  minPickValue_lte?: Maybe<Scalars['Int']>
  minPickValue_not_lte?: Maybe<Scalars['Int']>
  reqManifest?: Maybe<Scalars['Boolean']>
  reqManifest_not?: Maybe<Scalars['Boolean']>
  usePalletMethod?: Maybe<Scalars['Boolean']>
  usePalletMethod_not?: Maybe<Scalars['Boolean']>
  palletMethod?: Maybe<Scalars['String']>
  palletMethod_not?: Maybe<Scalars['String']>
  palletMethod_contains?: Maybe<Scalars['String']>
  palletMethod_not_contains?: Maybe<Scalars['String']>
  palletMethod_starts_with?: Maybe<Scalars['String']>
  palletMethod_not_starts_with?: Maybe<Scalars['String']>
  palletMethod_ends_with?: Maybe<Scalars['String']>
  palletMethod_not_ends_with?: Maybe<Scalars['String']>
  palletMethod_in?: Maybe<Array<Maybe<Scalars['String']>>>
  palletMethod_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  ASNOnDespatch?: Maybe<Scalars['Boolean']>
  ASNOnDespatch_not?: Maybe<Scalars['Boolean']>
  ASNMethod?: Maybe<Scalars['String']>
  ASNMethod_not?: Maybe<Scalars['String']>
  ASNMethod_contains?: Maybe<Scalars['String']>
  ASNMethod_not_contains?: Maybe<Scalars['String']>
  ASNMethod_starts_with?: Maybe<Scalars['String']>
  ASNMethod_not_starts_with?: Maybe<Scalars['String']>
  ASNMethod_ends_with?: Maybe<Scalars['String']>
  ASNMethod_not_ends_with?: Maybe<Scalars['String']>
  ASNMethod_in?: Maybe<Array<Maybe<Scalars['String']>>>
  ASNMethod_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  captureSerialOnDesp?: Maybe<Scalars['Boolean']>
  captureSerialOnDesp_not?: Maybe<Scalars['Boolean']>
  suppressBackOrdClose?: Maybe<Scalars['Boolean']>
  suppressBackOrdClose_not?: Maybe<Scalars['Boolean']>
  noPickConsolidation?: Maybe<Scalars['Boolean']>
  noPickConsolidation_not?: Maybe<Scalars['Boolean']>
}

export type DestinationInput = {
  id?: Maybe<Scalars['Int']>
  code: Scalars['String']
  name: Scalars['String']
  address: AddressInput
  status: State
  contact?: Maybe<ContactInput>
  salesman?: Maybe<Scalars['String']>
  VATNo?: Maybe<Scalars['String']>
  facility?: Maybe<Scalars['String']>
  category?: Maybe<Scalars['String']>
  stockOwnerId?: Maybe<Scalars['Int']>
  owner?: Maybe<StockOwnerInput>
  masterAcc?: Maybe<Scalars['String']>
  budgetholder?: Maybe<Scalars['String']>
  area?: Maybe<Scalars['String']>
  defRoute?: Maybe<Scalars['String']>
  pickLocation?: Maybe<Scalars['String']>
  custStkCodeOnDespNotes: Scalars['Boolean']
  despNoteFormat?: Maybe<Scalars['Int']>
  printNotesDelayDesp: Scalars['Boolean']
  noDespNotes: Scalars['Boolean']
  printBoxNotes: Scalars['Boolean']
  printBoxLbls: Scalars['Boolean']
  FTPAcc?: Maybe<Scalars['String']>
  depot?: Maybe<Scalars['String']>
  masterHub?: Maybe<Scalars['String']>
  subHub?: Maybe<Scalars['String']>
  GLN?: Maybe<Scalars['String']>
  customsId?: Maybe<Scalars['String']>
  defDelGroup?: Maybe<Scalars['String']>
  despNoteEmail?: Maybe<Scalars['String']>
  useDespMethMBP: Scalars['Boolean']
  routing?: Maybe<Array<Maybe<DestinationRouteInput>>>
  budgets?: Maybe<Array<Maybe<DestinationBudgetInput>>>
  defOrderType?: Maybe<Scalars['String']>
  defOrderPri?: Maybe<Scalars['String']>
  despMethod?: Maybe<Scalars['String']>
  transitTime?: Maybe<Scalars['Int']>
  defDespLane?: Maybe<Scalars['String']>
  orderFreq?: Maybe<Frequency>
  orderDay?: Maybe<DayOfWeek>
  defSpecIns?: Maybe<Scalars['String']>
  minPickValue?: Maybe<Scalars['Int']>
  reqManifest: Scalars['Boolean']
  usePalletMethod: Scalars['Boolean']
  palletMethod?: Maybe<Scalars['String']>
  ASNOnDespatch: Scalars['Boolean']
  ASNMethod?: Maybe<Scalars['String']>
  captureSerialOnDesp: Scalars['Boolean']
  suppressBackOrdClose: Scalars['Boolean']
  noPickConsolidation: Scalars['Boolean']
}

export type DestinationRoute = {
  __typename?: 'DestinationRoute'
  id?: Maybe<Scalars['Int']>
  destination?: Maybe<Destination>
  route: Scalars['String']
  days?: Maybe<Array<DayOfWeek>>
  dropNumber?: Maybe<Scalars['Int']>
  frequency?: Maybe<Scalars['Int']>
}

export type DestinationRouteFilter = {
  AND?: Maybe<Array<DestinationRouteFilter>>
  OR?: Maybe<Array<DestinationRouteFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  destination?: Maybe<DestinationFilter>
  route?: Maybe<Scalars['String']>
  route_not?: Maybe<Scalars['String']>
  route_contains?: Maybe<Scalars['String']>
  route_not_contains?: Maybe<Scalars['String']>
  route_starts_with?: Maybe<Scalars['String']>
  route_not_starts_with?: Maybe<Scalars['String']>
  route_ends_with?: Maybe<Scalars['String']>
  route_not_ends_with?: Maybe<Scalars['String']>
  route_in?: Maybe<Array<Scalars['String']>>
  route_not_in?: Maybe<Array<Scalars['String']>>
  days_some?: Maybe<ISingleFilterOfDayOfWeekFilter>
  days_none?: Maybe<ISingleFilterOfDayOfWeekFilter>
  days_all?: Maybe<ISingleFilterOfDayOfWeekFilter>
  days_any?: Maybe<Scalars['Boolean']>
  dropNumber?: Maybe<Scalars['Int']>
  dropNumber_not?: Maybe<Scalars['Int']>
  dropNumber_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  dropNumber_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  dropNumber_gt?: Maybe<Scalars['Int']>
  dropNumber_not_gt?: Maybe<Scalars['Int']>
  dropNumber_gte?: Maybe<Scalars['Int']>
  dropNumber_not_gte?: Maybe<Scalars['Int']>
  dropNumber_lt?: Maybe<Scalars['Int']>
  dropNumber_not_lt?: Maybe<Scalars['Int']>
  dropNumber_lte?: Maybe<Scalars['Int']>
  dropNumber_not_lte?: Maybe<Scalars['Int']>
  frequency?: Maybe<Scalars['Int']>
  frequency_not?: Maybe<Scalars['Int']>
  frequency_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  frequency_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  frequency_gt?: Maybe<Scalars['Int']>
  frequency_not_gt?: Maybe<Scalars['Int']>
  frequency_gte?: Maybe<Scalars['Int']>
  frequency_not_gte?: Maybe<Scalars['Int']>
  frequency_lt?: Maybe<Scalars['Int']>
  frequency_not_lt?: Maybe<Scalars['Int']>
  frequency_lte?: Maybe<Scalars['Int']>
  frequency_not_lte?: Maybe<Scalars['Int']>
}

export type DestinationRouteInput = {
  id?: Maybe<Scalars['Int']>
  destination?: Maybe<DestinationInput>
  route: Scalars['String']
  days?: Maybe<Array<DayOfWeek>>
  dropNumber?: Maybe<Scalars['Int']>
  frequency?: Maybe<Scalars['Int']>
}

export type DestinationRouteUpdateInput = {
  route?: Maybe<Scalars['String']>
  days?: Maybe<Array<DayOfWeek>>
  dropNumber?: Maybe<Scalars['Int']>
  frequency?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
}

export type DestinationSort = {
  id?: Maybe<SortOperationKind>
  code?: Maybe<SortOperationKind>
  name?: Maybe<SortOperationKind>
  address?: Maybe<AddressSort>
  status?: Maybe<SortOperationKind>
  contact?: Maybe<ContactSort>
  salesman?: Maybe<SortOperationKind>
  VATNo?: Maybe<SortOperationKind>
  facility?: Maybe<SortOperationKind>
  category?: Maybe<SortOperationKind>
  stockOwnerId?: Maybe<SortOperationKind>
  owner?: Maybe<StockOwnerSort>
  masterAcc?: Maybe<SortOperationKind>
  budgetholder?: Maybe<SortOperationKind>
  area?: Maybe<SortOperationKind>
  defRoute?: Maybe<SortOperationKind>
  pickLocation?: Maybe<SortOperationKind>
  custStkCodeOnDespNotes?: Maybe<SortOperationKind>
  despNoteFormat?: Maybe<SortOperationKind>
  printNotesDelayDesp?: Maybe<SortOperationKind>
  noDespNotes?: Maybe<SortOperationKind>
  printBoxNotes?: Maybe<SortOperationKind>
  printBoxLbls?: Maybe<SortOperationKind>
  FTPAcc?: Maybe<SortOperationKind>
  depot?: Maybe<SortOperationKind>
  masterHub?: Maybe<SortOperationKind>
  subHub?: Maybe<SortOperationKind>
  GLN?: Maybe<SortOperationKind>
  customsId?: Maybe<SortOperationKind>
  defDelGroup?: Maybe<SortOperationKind>
  despNoteEmail?: Maybe<SortOperationKind>
  useDespMethMBP?: Maybe<SortOperationKind>
  defOrderType?: Maybe<SortOperationKind>
  defOrderPri?: Maybe<SortOperationKind>
  despMethod?: Maybe<SortOperationKind>
  transitTime?: Maybe<SortOperationKind>
  defDespLane?: Maybe<SortOperationKind>
  orderFreq?: Maybe<SortOperationKind>
  orderDay?: Maybe<SortOperationKind>
  defSpecIns?: Maybe<SortOperationKind>
  minPickValue?: Maybe<SortOperationKind>
  reqManifest?: Maybe<SortOperationKind>
  usePalletMethod?: Maybe<SortOperationKind>
  palletMethod?: Maybe<SortOperationKind>
  ASNOnDespatch?: Maybe<SortOperationKind>
  ASNMethod?: Maybe<SortOperationKind>
  captureSerialOnDesp?: Maybe<SortOperationKind>
  suppressBackOrdClose?: Maybe<SortOperationKind>
  noPickConsolidation?: Maybe<SortOperationKind>
}

export type DestinationUpdateInput = {
  code?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  address?: Maybe<AddressUpdateInput>
  status?: Maybe<State>
  contact?: Maybe<ContactUpdateInput>
  salesman?: Maybe<Scalars['String']>
  VATNo?: Maybe<Scalars['String']>
  facility?: Maybe<Scalars['String']>
  category?: Maybe<Scalars['String']>
  stockOwnerId?: Maybe<Scalars['Int']>
  owner?: Maybe<StockOwnerUpdateInput>
  masterAcc?: Maybe<Scalars['String']>
  budgetholder?: Maybe<Scalars['String']>
  area?: Maybe<Scalars['String']>
  defRoute?: Maybe<Scalars['String']>
  pickLocation?: Maybe<Scalars['String']>
  custStkCodeOnDespNotes?: Maybe<Scalars['Boolean']>
  despNoteFormat?: Maybe<Scalars['Int']>
  printNotesDelayDesp?: Maybe<Scalars['Boolean']>
  noDespNotes?: Maybe<Scalars['Boolean']>
  printBoxNotes?: Maybe<Scalars['Boolean']>
  printBoxLbls?: Maybe<Scalars['Boolean']>
  FTPAcc?: Maybe<Scalars['String']>
  depot?: Maybe<Scalars['String']>
  masterHub?: Maybe<Scalars['String']>
  subHub?: Maybe<Scalars['String']>
  GLN?: Maybe<Scalars['String']>
  customsId?: Maybe<Scalars['String']>
  defDelGroup?: Maybe<Scalars['String']>
  despNoteEmail?: Maybe<Scalars['String']>
  useDespMethMBP?: Maybe<Scalars['Boolean']>
  defOrderType?: Maybe<Scalars['String']>
  defOrderPri?: Maybe<Scalars['String']>
  despMethod?: Maybe<Scalars['String']>
  transitTime?: Maybe<Scalars['Int']>
  defDespLane?: Maybe<Scalars['String']>
  orderFreq?: Maybe<Frequency>
  orderDay?: Maybe<DayOfWeek>
  defSpecIns?: Maybe<Scalars['String']>
  minPickValue?: Maybe<Scalars['Int']>
  reqManifest?: Maybe<Scalars['Boolean']>
  usePalletMethod?: Maybe<Scalars['Boolean']>
  palletMethod?: Maybe<Scalars['String']>
  ASNOnDespatch?: Maybe<Scalars['Boolean']>
  ASNMethod?: Maybe<Scalars['String']>
  captureSerialOnDesp?: Maybe<Scalars['Boolean']>
  suppressBackOrdClose?: Maybe<Scalars['Boolean']>
  noPickConsolidation?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['Int']>
}

export enum DigitShapes {
  Context = 'CONTEXT',
  None = 'NONE',
  NativeNational = 'NATIVE_NATIONAL'
}

export type DigitShapesOperationFilterInput = {
  eq?: Maybe<DigitShapes>
  neq?: Maybe<DigitShapes>
  in?: Maybe<Array<DigitShapes>>
  nin?: Maybe<Array<DigitShapes>>
}

export type Dimension = {
  __typename?: 'Dimension'
  height: Scalars['Decimal']
  width: Scalars['Decimal']
  depth: Scalars['Decimal']
  volume: Scalars['Decimal']
}

export type DimensionFilter = {
  AND?: Maybe<Array<DimensionFilter>>
  OR?: Maybe<Array<DimensionFilter>>
  height?: Maybe<Scalars['Decimal']>
  height_not?: Maybe<Scalars['Decimal']>
  height_in?: Maybe<Array<Scalars['Decimal']>>
  height_not_in?: Maybe<Array<Scalars['Decimal']>>
  height_gt?: Maybe<Scalars['Decimal']>
  height_not_gt?: Maybe<Scalars['Decimal']>
  height_gte?: Maybe<Scalars['Decimal']>
  height_not_gte?: Maybe<Scalars['Decimal']>
  height_lt?: Maybe<Scalars['Decimal']>
  height_not_lt?: Maybe<Scalars['Decimal']>
  height_lte?: Maybe<Scalars['Decimal']>
  height_not_lte?: Maybe<Scalars['Decimal']>
  width?: Maybe<Scalars['Decimal']>
  width_not?: Maybe<Scalars['Decimal']>
  width_in?: Maybe<Array<Scalars['Decimal']>>
  width_not_in?: Maybe<Array<Scalars['Decimal']>>
  width_gt?: Maybe<Scalars['Decimal']>
  width_not_gt?: Maybe<Scalars['Decimal']>
  width_gte?: Maybe<Scalars['Decimal']>
  width_not_gte?: Maybe<Scalars['Decimal']>
  width_lt?: Maybe<Scalars['Decimal']>
  width_not_lt?: Maybe<Scalars['Decimal']>
  width_lte?: Maybe<Scalars['Decimal']>
  width_not_lte?: Maybe<Scalars['Decimal']>
  depth?: Maybe<Scalars['Decimal']>
  depth_not?: Maybe<Scalars['Decimal']>
  depth_in?: Maybe<Array<Scalars['Decimal']>>
  depth_not_in?: Maybe<Array<Scalars['Decimal']>>
  depth_gt?: Maybe<Scalars['Decimal']>
  depth_not_gt?: Maybe<Scalars['Decimal']>
  depth_gte?: Maybe<Scalars['Decimal']>
  depth_not_gte?: Maybe<Scalars['Decimal']>
  depth_lt?: Maybe<Scalars['Decimal']>
  depth_not_lt?: Maybe<Scalars['Decimal']>
  depth_lte?: Maybe<Scalars['Decimal']>
  depth_not_lte?: Maybe<Scalars['Decimal']>
  volume?: Maybe<Scalars['Decimal']>
  volume_not?: Maybe<Scalars['Decimal']>
  volume_in?: Maybe<Array<Scalars['Decimal']>>
  volume_not_in?: Maybe<Array<Scalars['Decimal']>>
  volume_gt?: Maybe<Scalars['Decimal']>
  volume_not_gt?: Maybe<Scalars['Decimal']>
  volume_gte?: Maybe<Scalars['Decimal']>
  volume_not_gte?: Maybe<Scalars['Decimal']>
  volume_lt?: Maybe<Scalars['Decimal']>
  volume_not_lt?: Maybe<Scalars['Decimal']>
  volume_lte?: Maybe<Scalars['Decimal']>
  volume_not_lte?: Maybe<Scalars['Decimal']>
}

export type DimensionFilterInput = {
  and?: Maybe<Array<DimensionFilterInput>>
  or?: Maybe<Array<DimensionFilterInput>>
  height?: Maybe<ComparableNullableOfDecimalOperationFilterInput>
  width?: Maybe<ComparableNullableOfDecimalOperationFilterInput>
  depth?: Maybe<ComparableNullableOfDecimalOperationFilterInput>
  volume?: Maybe<ComparableNullableOfDecimalOperationFilterInput>
}

export type DimensionInput = {
  height: Scalars['Decimal']
  width: Scalars['Decimal']
  depth: Scalars['Decimal']
  volume: Scalars['Decimal']
}

export type DimensionSort = {
  height?: Maybe<SortOperationKind>
  width?: Maybe<SortOperationKind>
  depth?: Maybe<SortOperationKind>
  volume?: Maybe<SortOperationKind>
}

export type DimensionSortInput = {
  height?: Maybe<SortEnumType>
  width?: Maybe<SortEnumType>
  depth?: Maybe<SortEnumType>
  volume?: Maybe<SortEnumType>
}

export type DimensionUpdateInput = {
  height?: Maybe<Scalars['Decimal']>
  width?: Maybe<Scalars['Decimal']>
  depth?: Maybe<Scalars['Decimal']>
  volume?: Maybe<Scalars['Decimal']>
  id?: Maybe<Scalars['Int']>
}

export type ExchangeRate = {
  __typename?: 'ExchangeRate'
  id?: Maybe<Scalars['Int']>
  currency: Currency
  target: Currency
  startDate: Scalars['DateTime']
  rate: Scalars['Decimal']
}

export type ExchangeRateConnection = {
  __typename?: 'ExchangeRateConnection'
  pageInfo: PageInfo
  edges?: Maybe<Array<ExchangeRateEdge>>
  nodes?: Maybe<Array<Maybe<ExchangeRate>>>
  totalCount: Scalars['Int']
}

export type ExchangeRateEdge = {
  __typename?: 'ExchangeRateEdge'
  cursor: Scalars['String']
  node?: Maybe<ExchangeRate>
}

export type ExchangeRateFilter = {
  AND?: Maybe<Array<ExchangeRateFilter>>
  OR?: Maybe<Array<ExchangeRateFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  currency?: Maybe<Currency>
  currency_not?: Maybe<Currency>
  currency_in?: Maybe<Array<Currency>>
  currency_not_in?: Maybe<Array<Currency>>
  currency_gt?: Maybe<Currency>
  currency_not_gt?: Maybe<Currency>
  currency_gte?: Maybe<Currency>
  currency_not_gte?: Maybe<Currency>
  currency_lt?: Maybe<Currency>
  currency_not_lt?: Maybe<Currency>
  currency_lte?: Maybe<Currency>
  currency_not_lte?: Maybe<Currency>
  target?: Maybe<Currency>
  target_not?: Maybe<Currency>
  target_in?: Maybe<Array<Currency>>
  target_not_in?: Maybe<Array<Currency>>
  target_gt?: Maybe<Currency>
  target_not_gt?: Maybe<Currency>
  target_gte?: Maybe<Currency>
  target_not_gte?: Maybe<Currency>
  target_lt?: Maybe<Currency>
  target_not_lt?: Maybe<Currency>
  target_lte?: Maybe<Currency>
  target_not_lte?: Maybe<Currency>
  startDate?: Maybe<Scalars['DateTime']>
  startDate_not?: Maybe<Scalars['DateTime']>
  startDate_in?: Maybe<Array<Scalars['DateTime']>>
  startDate_not_in?: Maybe<Array<Scalars['DateTime']>>
  startDate_gt?: Maybe<Scalars['DateTime']>
  startDate_not_gt?: Maybe<Scalars['DateTime']>
  startDate_gte?: Maybe<Scalars['DateTime']>
  startDate_not_gte?: Maybe<Scalars['DateTime']>
  startDate_lt?: Maybe<Scalars['DateTime']>
  startDate_not_lt?: Maybe<Scalars['DateTime']>
  startDate_lte?: Maybe<Scalars['DateTime']>
  startDate_not_lte?: Maybe<Scalars['DateTime']>
  rate?: Maybe<Scalars['Decimal']>
  rate_not?: Maybe<Scalars['Decimal']>
  rate_in?: Maybe<Array<Scalars['Decimal']>>
  rate_not_in?: Maybe<Array<Scalars['Decimal']>>
  rate_gt?: Maybe<Scalars['Decimal']>
  rate_not_gt?: Maybe<Scalars['Decimal']>
  rate_gte?: Maybe<Scalars['Decimal']>
  rate_not_gte?: Maybe<Scalars['Decimal']>
  rate_lt?: Maybe<Scalars['Decimal']>
  rate_not_lt?: Maybe<Scalars['Decimal']>
  rate_lte?: Maybe<Scalars['Decimal']>
  rate_not_lte?: Maybe<Scalars['Decimal']>
}

export type ExchangeRateInput = {
  id?: Maybe<Scalars['Int']>
  currency: Currency
  target: Currency
  startDate: Scalars['DateTime']
  rate: Scalars['Decimal']
}

export type ExchangeRateSort = {
  id?: Maybe<SortOperationKind>
  currency?: Maybe<SortOperationKind>
  target?: Maybe<SortOperationKind>
  startDate?: Maybe<SortOperationKind>
  rate?: Maybe<SortOperationKind>
}

export type ExchangeRateUpdateInput = {
  startDate?: Maybe<Scalars['DateTime']>
  rate?: Maybe<Scalars['Decimal']>
  id?: Maybe<Scalars['Int']>
}

export enum FieldVisibility {
  Undefined = 'UNDEFINED',
  NotRequired = 'NOT_REQUIRED',
  Required = 'REQUIRED',
  Optional = 'OPTIONAL',
  ReadOnly = 'READ_ONLY'
}

export enum FileFormat {
  Csv = 'CSV',
  Edi = 'EDI',
  Xml = 'XML',
  Json = 'JSON'
}

export enum FileTransmission {
  Ftp = 'FTP',
  Sftp = 'SFTP',
  Smtp = 'SMTP',
  Int = 'INT',
  None = 'NONE'
}

export enum Frequency {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  BiWeekly = 'BI_WEEKLY',
  Monthly = 'MONTHLY'
}

export type GridResult = {
  __typename?: 'GridResult'
  resourceSet?: Maybe<Scalars['String']>
  locales?: Maybe<Array<Maybe<Scalars['String']>>>
  resources?: Maybe<Array<Maybe<ResourceItem>>>
}

export type HandlingStorageType = {
  __typename?: 'HandlingStorageType'
  handlingTypeId?: Maybe<Scalars['Int']>
  handlingType?: Maybe<HandlingType>
  storageTypeId?: Maybe<Scalars['Int']>
  storageType?: Maybe<StorageType>
}

export type HandlingStorageTypeFilter = {
  AND?: Maybe<Array<HandlingStorageTypeFilter>>
  OR?: Maybe<Array<HandlingStorageTypeFilter>>
  handlingTypeId?: Maybe<Scalars['Int']>
  handlingTypeId_not?: Maybe<Scalars['Int']>
  handlingTypeId_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  handlingTypeId_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  handlingTypeId_gt?: Maybe<Scalars['Int']>
  handlingTypeId_not_gt?: Maybe<Scalars['Int']>
  handlingTypeId_gte?: Maybe<Scalars['Int']>
  handlingTypeId_not_gte?: Maybe<Scalars['Int']>
  handlingTypeId_lt?: Maybe<Scalars['Int']>
  handlingTypeId_not_lt?: Maybe<Scalars['Int']>
  handlingTypeId_lte?: Maybe<Scalars['Int']>
  handlingTypeId_not_lte?: Maybe<Scalars['Int']>
  handlingType?: Maybe<HandlingTypeFilter>
  storageTypeId?: Maybe<Scalars['Int']>
  storageTypeId_not?: Maybe<Scalars['Int']>
  storageTypeId_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  storageTypeId_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  storageTypeId_gt?: Maybe<Scalars['Int']>
  storageTypeId_not_gt?: Maybe<Scalars['Int']>
  storageTypeId_gte?: Maybe<Scalars['Int']>
  storageTypeId_not_gte?: Maybe<Scalars['Int']>
  storageTypeId_lt?: Maybe<Scalars['Int']>
  storageTypeId_not_lt?: Maybe<Scalars['Int']>
  storageTypeId_lte?: Maybe<Scalars['Int']>
  storageTypeId_not_lte?: Maybe<Scalars['Int']>
  storageType?: Maybe<StorageTypeFilter>
}

export type HandlingStorageTypeInput = {
  handlingTypeId?: Maybe<Scalars['Int']>
  handlingType?: Maybe<HandlingTypeInput>
  storageTypeId?: Maybe<Scalars['Int']>
  storageType?: Maybe<StorageTypeInput>
}

export type HandlingType = {
  __typename?: 'HandlingType'
  id?: Maybe<Scalars['Int']>
  code: Scalars['String']
  description: Scalars['String']
  storageTypes?: Maybe<Array<Maybe<HandlingStorageType>>>
}

export type HandlingTypeConnection = {
  __typename?: 'HandlingTypeConnection'
  pageInfo: PageInfo
  edges?: Maybe<Array<HandlingTypeEdge>>
  nodes?: Maybe<Array<Maybe<HandlingType>>>
  totalCount: Scalars['Int']
}

export type HandlingTypeEdge = {
  __typename?: 'HandlingTypeEdge'
  cursor: Scalars['String']
  node?: Maybe<HandlingType>
}

export type HandlingTypeFilter = {
  AND?: Maybe<Array<HandlingTypeFilter>>
  OR?: Maybe<Array<HandlingTypeFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
  code_not?: Maybe<Scalars['String']>
  code_contains?: Maybe<Scalars['String']>
  code_not_contains?: Maybe<Scalars['String']>
  code_starts_with?: Maybe<Scalars['String']>
  code_not_starts_with?: Maybe<Scalars['String']>
  code_ends_with?: Maybe<Scalars['String']>
  code_not_ends_with?: Maybe<Scalars['String']>
  code_in?: Maybe<Array<Scalars['String']>>
  code_not_in?: Maybe<Array<Scalars['String']>>
  description?: Maybe<Scalars['String']>
  description_not?: Maybe<Scalars['String']>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  description_starts_with?: Maybe<Scalars['String']>
  description_not_starts_with?: Maybe<Scalars['String']>
  description_ends_with?: Maybe<Scalars['String']>
  description_not_ends_with?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Scalars['String']>>
  description_not_in?: Maybe<Array<Scalars['String']>>
  storageTypes_some?: Maybe<HandlingStorageTypeFilter>
  storageTypes_none?: Maybe<HandlingStorageTypeFilter>
  storageTypes_all?: Maybe<HandlingStorageTypeFilter>
  storageTypes_any?: Maybe<Scalars['Boolean']>
}

export type HandlingTypeInput = {
  id?: Maybe<Scalars['Int']>
  code: Scalars['String']
  description?: Maybe<Scalars['String']>
  storageTypes?: Maybe<Array<Maybe<HandlingStorageTypeInput>>>
}

export type HandlingTypeSort = {
  id?: Maybe<SortOperationKind>
  code?: Maybe<SortOperationKind>
  description?: Maybe<SortOperationKind>
}

export type HandlingTypeUpdateInput = {
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
}

export type ISingleFilterOfDayOfWeekFilter = {
  AND?: Maybe<Array<ISingleFilterOfDayOfWeekFilter>>
  OR?: Maybe<Array<ISingleFilterOfDayOfWeekFilter>>
  element?: Maybe<DayOfWeek>
  element_not?: Maybe<DayOfWeek>
  element_in?: Maybe<Array<DayOfWeek>>
  element_not_in?: Maybe<Array<DayOfWeek>>
  element_gt?: Maybe<DayOfWeek>
  element_not_gt?: Maybe<DayOfWeek>
  element_gte?: Maybe<DayOfWeek>
  element_not_gte?: Maybe<DayOfWeek>
  element_lt?: Maybe<DayOfWeek>
  element_not_lt?: Maybe<DayOfWeek>
  element_lte?: Maybe<DayOfWeek>
  element_not_lte?: Maybe<DayOfWeek>
}

export type ISingleFilterOfPermissionFilter = {
  AND?: Maybe<Array<ISingleFilterOfPermissionFilter>>
  OR?: Maybe<Array<ISingleFilterOfPermissionFilter>>
  element?: Maybe<Permission>
  element_not?: Maybe<Permission>
  element_in?: Maybe<Array<Permission>>
  element_not_in?: Maybe<Array<Permission>>
  element_gt?: Maybe<Permission>
  element_not_gt?: Maybe<Permission>
  element_gte?: Maybe<Permission>
  element_not_gte?: Maybe<Permission>
  element_lt?: Maybe<Permission>
  element_not_lt?: Maybe<Permission>
  element_lte?: Maybe<Permission>
  element_not_lte?: Maybe<Permission>
}

export type ISingleFilterOfStringFilter = {
  AND?: Maybe<Array<ISingleFilterOfStringFilter>>
  OR?: Maybe<Array<ISingleFilterOfStringFilter>>
  element?: Maybe<Scalars['String']>
  element_not?: Maybe<Scalars['String']>
  element_contains?: Maybe<Scalars['String']>
  element_not_contains?: Maybe<Scalars['String']>
  element_starts_with?: Maybe<Scalars['String']>
  element_not_starts_with?: Maybe<Scalars['String']>
  element_ends_with?: Maybe<Scalars['String']>
  element_not_ends_with?: Maybe<Scalars['String']>
  element_in?: Maybe<Array<Scalars['String']>>
  element_not_in?: Maybe<Array<Scalars['String']>>
}

export type IwmsAttributes = {
  id?: Maybe<Scalars['Int']>
}

export type LicencePlate = {
  __typename?: 'LicencePlate'
  id?: Maybe<Scalars['Int']>
  LPN: Scalars['String']
  LPType?: Maybe<LicencePlateType>
  locationId?: Maybe<Scalars['Int']>
  location?: Maybe<Location>
  outerId?: Maybe<Scalars['Int']>
  outer?: Maybe<LicencePlate>
  inners?: Maybe<Array<Maybe<LicencePlate>>>
  owner?: Maybe<LpOwner>
}

export type LicencePlateFilterInput = {
  and?: Maybe<Array<LicencePlateFilterInput>>
  or?: Maybe<Array<LicencePlateFilterInput>>
  id?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  LPN?: Maybe<StringOperationFilterInput>
  LPType?: Maybe<LicencePlateTypeFilterInput>
  locationId?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  location?: Maybe<LocationFilterInput>
  outerId?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  outer?: Maybe<LicencePlateFilterInput>
  inners?: Maybe<ListFilterInputTypeOfLicencePlateFilterInput>
  owner?: Maybe<LpOwnerFilterInput>
}

export type LicencePlateSortInput = {
  id?: Maybe<SortEnumType>
  LPN?: Maybe<SortEnumType>
  LPType?: Maybe<LicencePlateTypeSortInput>
  locationId?: Maybe<SortEnumType>
  location?: Maybe<LocationSortInput>
  outerId?: Maybe<SortEnumType>
  outer?: Maybe<LicencePlateSortInput>
  owner?: Maybe<LpOwnerSortInput>
}

export type LicencePlateType = {
  __typename?: 'LicencePlateType'
  id?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  size?: Maybe<Dimension>
  tareWeight?: Maybe<Scalars['Decimal']>
  material?: Maybe<Scalars['String']>
  controlled?: Maybe<LpControlType>
}

export type LicencePlateTypeFilterInput = {
  and?: Maybe<Array<LicencePlateTypeFilterInput>>
  or?: Maybe<Array<LicencePlateTypeFilterInput>>
  id?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  code?: Maybe<StringOperationFilterInput>
  description?: Maybe<StringOperationFilterInput>
  size?: Maybe<DimensionFilterInput>
  tareWeight?: Maybe<ComparableNullableOfDecimalOperationFilterInput>
  material?: Maybe<StringOperationFilterInput>
  controlled?: Maybe<NullableOfLpControlTypeOperationFilterInput>
}

export type LicencePlateTypeSortInput = {
  id?: Maybe<SortEnumType>
  code?: Maybe<SortEnumType>
  description?: Maybe<SortEnumType>
  size?: Maybe<DimensionSortInput>
  tareWeight?: Maybe<SortEnumType>
  material?: Maybe<SortEnumType>
  controlled?: Maybe<SortEnumType>
}

export type ListComparableInt32OperationFilterInput = {
  all?: Maybe<ComparableInt32OperationFilterInput>
  none?: Maybe<ComparableInt32OperationFilterInput>
  some?: Maybe<ComparableInt32OperationFilterInput>
  any?: Maybe<Scalars['Boolean']>
}

export type ListDayOfWeekOperationFilterInput = {
  all?: Maybe<DayOfWeekOperationFilterInput>
  none?: Maybe<DayOfWeekOperationFilterInput>
  some?: Maybe<DayOfWeekOperationFilterInput>
  any?: Maybe<Scalars['Boolean']>
}

export type ListFilterInputTypeOfBomDefinitionFilterInput = {
  all?: Maybe<BomDefinitionFilterInput>
  none?: Maybe<BomDefinitionFilterInput>
  some?: Maybe<BomDefinitionFilterInput>
  any?: Maybe<Scalars['Boolean']>
}

export type ListFilterInputTypeOfCalendarFilterInput = {
  all?: Maybe<CalendarFilterInput>
  none?: Maybe<CalendarFilterInput>
  some?: Maybe<CalendarFilterInput>
  any?: Maybe<Scalars['Boolean']>
}

export type ListFilterInputTypeOfCategoryDefinitionFilterInput = {
  all?: Maybe<CategoryDefinitionFilterInput>
  none?: Maybe<CategoryDefinitionFilterInput>
  some?: Maybe<CategoryDefinitionFilterInput>
  any?: Maybe<Scalars['Boolean']>
}

export type ListFilterInputTypeOfLicencePlateFilterInput = {
  all?: Maybe<LicencePlateFilterInput>
  none?: Maybe<LicencePlateFilterInput>
  some?: Maybe<LicencePlateFilterInput>
  any?: Maybe<Scalars['Boolean']>
}

export type ListFilterInputTypeOfLpDefinitionFilterInput = {
  all?: Maybe<LpDefinitionFilterInput>
  none?: Maybe<LpDefinitionFilterInput>
  some?: Maybe<LpDefinitionFilterInput>
  any?: Maybe<Scalars['Boolean']>
}

export type ListFilterInputTypeOfOrderStatusFilterInput = {
  all?: Maybe<OrderStatusFilterInput>
  none?: Maybe<OrderStatusFilterInput>
  some?: Maybe<OrderStatusFilterInput>
  any?: Maybe<Scalars['Boolean']>
}

export type ListFilterInputTypeOfPickConfirmMethodFilterInput = {
  all?: Maybe<PickConfirmMethodFilterInput>
  none?: Maybe<PickConfirmMethodFilterInput>
  some?: Maybe<PickConfirmMethodFilterInput>
  any?: Maybe<Scalars['Boolean']>
}

export type ListFilterInputTypeOfPickMethodFilterInput = {
  all?: Maybe<PickMethodFilterInput>
  none?: Maybe<PickMethodFilterInput>
  some?: Maybe<PickMethodFilterInput>
  any?: Maybe<Scalars['Boolean']>
}

export type ListFilterInputTypeOfProductBarcodeFilterInput = {
  all?: Maybe<ProductBarcodeFilterInput>
  none?: Maybe<ProductBarcodeFilterInput>
  some?: Maybe<ProductBarcodeFilterInput>
  any?: Maybe<Scalars['Boolean']>
}

export type ListFilterInputTypeOfProductCategoryFilterInput = {
  all?: Maybe<ProductCategoryFilterInput>
  none?: Maybe<ProductCategoryFilterInput>
  some?: Maybe<ProductCategoryFilterInput>
  any?: Maybe<Scalars['Boolean']>
}

export type ListFilterInputTypeOfProductDocumentFilterInput = {
  all?: Maybe<ProductDocumentFilterInput>
  none?: Maybe<ProductDocumentFilterInput>
  some?: Maybe<ProductDocumentFilterInput>
  any?: Maybe<Scalars['Boolean']>
}

export type ListFilterInputTypeOfProductPickfaceFilterInput = {
  all?: Maybe<ProductPickfaceFilterInput>
  none?: Maybe<ProductPickfaceFilterInput>
  some?: Maybe<ProductPickfaceFilterInput>
  any?: Maybe<Scalars['Boolean']>
}

export type ListFilterInputTypeOfProductStatusFilterInput = {
  all?: Maybe<ProductStatusFilterInput>
  none?: Maybe<ProductStatusFilterInput>
  some?: Maybe<ProductStatusFilterInput>
  any?: Maybe<Scalars['Boolean']>
}

export type ListFilterInputTypeOfProductSupplierFilterInput = {
  all?: Maybe<ProductSupplierFilterInput>
  none?: Maybe<ProductSupplierFilterInput>
  some?: Maybe<ProductSupplierFilterInput>
  any?: Maybe<Scalars['Boolean']>
}

export type ListFilterInputTypeOfStockOwnerLinkFilterInput = {
  all?: Maybe<StockOwnerLinkFilterInput>
  none?: Maybe<StockOwnerLinkFilterInput>
  some?: Maybe<StockOwnerLinkFilterInput>
  any?: Maybe<Scalars['Boolean']>
}

export type ListFilterInputTypeOfStorageTypeMheFilterInput = {
  all?: Maybe<StorageTypeMheFilterInput>
  none?: Maybe<StorageTypeMheFilterInput>
  some?: Maybe<StorageTypeMheFilterInput>
  any?: Maybe<Scalars['Boolean']>
}

export type ListStringOperationFilterInput = {
  all?: Maybe<StringOperationFilterInput>
  none?: Maybe<StringOperationFilterInput>
  some?: Maybe<StringOperationFilterInput>
  any?: Maybe<Scalars['Boolean']>
}

export enum LoadOrder {
  Delivery = 'DELIVERY',
  None = 'NONE',
  Reverse = 'REVERSE'
}

export type Location = {
  __typename?: 'Location'
  id?: Maybe<Scalars['Int']>
  warehouse?: Maybe<Warehouse>
  code: Scalars['String']
  physicalCharacteristics: LocationPhysicalType
  processCharacteristics: LocationProcessType
  temperatureType: LocationTemperatureType
  attributes?: Maybe<Scalars['String']>
  storageTypeId: Scalars['Int']
  storageType?: Maybe<StorageType>
  aisle?: Maybe<Scalars['String']>
  status: LocationStatus
  checkCode?: Maybe<Scalars['String']>
  beam?: Maybe<Scalars['String']>
  beamSequence?: Maybe<Scalars['String']>
  zone: Scalars['String']
  countZone?: Maybe<Scalars['String']>
  tier: Scalars['Int']
  elevation: Scalars['Decimal']
  dimensions: Dimension
  maxWeight: Scalars['Decimal']
  pickZone?: Maybe<Scalars['String']>
  reservedProductId?: Maybe<Scalars['Int']>
}

export type LocationConnection = {
  __typename?: 'LocationConnection'
  pageInfo: PageInfo
  edges?: Maybe<Array<LocationEdge>>
  nodes?: Maybe<Array<Maybe<Location>>>
  totalCount: Scalars['Int']
}

export type LocationEdge = {
  __typename?: 'LocationEdge'
  cursor: Scalars['String']
  node?: Maybe<Location>
}

export type LocationFilter = {
  AND?: Maybe<Array<LocationFilter>>
  OR?: Maybe<Array<LocationFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  warehouse?: Maybe<WarehouseFilter>
  code?: Maybe<Scalars['String']>
  code_not?: Maybe<Scalars['String']>
  code_contains?: Maybe<Scalars['String']>
  code_not_contains?: Maybe<Scalars['String']>
  code_starts_with?: Maybe<Scalars['String']>
  code_not_starts_with?: Maybe<Scalars['String']>
  code_ends_with?: Maybe<Scalars['String']>
  code_not_ends_with?: Maybe<Scalars['String']>
  code_in?: Maybe<Array<Scalars['String']>>
  code_not_in?: Maybe<Array<Scalars['String']>>
  physicalCharacteristics?: Maybe<LocationPhysicalType>
  physicalCharacteristics_not?: Maybe<LocationPhysicalType>
  physicalCharacteristics_in?: Maybe<Array<LocationPhysicalType>>
  physicalCharacteristics_not_in?: Maybe<Array<LocationPhysicalType>>
  physicalCharacteristics_gt?: Maybe<LocationPhysicalType>
  physicalCharacteristics_not_gt?: Maybe<LocationPhysicalType>
  physicalCharacteristics_gte?: Maybe<LocationPhysicalType>
  physicalCharacteristics_not_gte?: Maybe<LocationPhysicalType>
  physicalCharacteristics_lt?: Maybe<LocationPhysicalType>
  physicalCharacteristics_not_lt?: Maybe<LocationPhysicalType>
  physicalCharacteristics_lte?: Maybe<LocationPhysicalType>
  physicalCharacteristics_not_lte?: Maybe<LocationPhysicalType>
  processCharacteristics?: Maybe<LocationProcessType>
  processCharacteristics_not?: Maybe<LocationProcessType>
  processCharacteristics_in?: Maybe<Array<LocationProcessType>>
  processCharacteristics_not_in?: Maybe<Array<LocationProcessType>>
  processCharacteristics_gt?: Maybe<LocationProcessType>
  processCharacteristics_not_gt?: Maybe<LocationProcessType>
  processCharacteristics_gte?: Maybe<LocationProcessType>
  processCharacteristics_not_gte?: Maybe<LocationProcessType>
  processCharacteristics_lt?: Maybe<LocationProcessType>
  processCharacteristics_not_lt?: Maybe<LocationProcessType>
  processCharacteristics_lte?: Maybe<LocationProcessType>
  processCharacteristics_not_lte?: Maybe<LocationProcessType>
  temperatureType?: Maybe<LocationTemperatureType>
  temperatureType_not?: Maybe<LocationTemperatureType>
  temperatureType_in?: Maybe<Array<LocationTemperatureType>>
  temperatureType_not_in?: Maybe<Array<LocationTemperatureType>>
  temperatureType_gt?: Maybe<LocationTemperatureType>
  temperatureType_not_gt?: Maybe<LocationTemperatureType>
  temperatureType_gte?: Maybe<LocationTemperatureType>
  temperatureType_not_gte?: Maybe<LocationTemperatureType>
  temperatureType_lt?: Maybe<LocationTemperatureType>
  temperatureType_not_lt?: Maybe<LocationTemperatureType>
  temperatureType_lte?: Maybe<LocationTemperatureType>
  temperatureType_not_lte?: Maybe<LocationTemperatureType>
  attributes?: Maybe<Scalars['String']>
  attributes_not?: Maybe<Scalars['String']>
  attributes_contains?: Maybe<Scalars['String']>
  attributes_not_contains?: Maybe<Scalars['String']>
  attributes_starts_with?: Maybe<Scalars['String']>
  attributes_not_starts_with?: Maybe<Scalars['String']>
  attributes_ends_with?: Maybe<Scalars['String']>
  attributes_not_ends_with?: Maybe<Scalars['String']>
  attributes_in?: Maybe<Array<Maybe<Scalars['String']>>>
  attributes_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  storageTypeId?: Maybe<Scalars['Int']>
  storageTypeId_not?: Maybe<Scalars['Int']>
  storageTypeId_in?: Maybe<Array<Scalars['Int']>>
  storageTypeId_not_in?: Maybe<Array<Scalars['Int']>>
  storageTypeId_gt?: Maybe<Scalars['Int']>
  storageTypeId_not_gt?: Maybe<Scalars['Int']>
  storageTypeId_gte?: Maybe<Scalars['Int']>
  storageTypeId_not_gte?: Maybe<Scalars['Int']>
  storageTypeId_lt?: Maybe<Scalars['Int']>
  storageTypeId_not_lt?: Maybe<Scalars['Int']>
  storageTypeId_lte?: Maybe<Scalars['Int']>
  storageTypeId_not_lte?: Maybe<Scalars['Int']>
  storageType?: Maybe<StorageTypeFilter>
  aisle?: Maybe<Scalars['String']>
  aisle_not?: Maybe<Scalars['String']>
  aisle_contains?: Maybe<Scalars['String']>
  aisle_not_contains?: Maybe<Scalars['String']>
  aisle_starts_with?: Maybe<Scalars['String']>
  aisle_not_starts_with?: Maybe<Scalars['String']>
  aisle_ends_with?: Maybe<Scalars['String']>
  aisle_not_ends_with?: Maybe<Scalars['String']>
  aisle_in?: Maybe<Array<Maybe<Scalars['String']>>>
  aisle_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  status?: Maybe<LocationStatus>
  status_not?: Maybe<LocationStatus>
  status_in?: Maybe<Array<LocationStatus>>
  status_not_in?: Maybe<Array<LocationStatus>>
  status_gt?: Maybe<LocationStatus>
  status_not_gt?: Maybe<LocationStatus>
  status_gte?: Maybe<LocationStatus>
  status_not_gte?: Maybe<LocationStatus>
  status_lt?: Maybe<LocationStatus>
  status_not_lt?: Maybe<LocationStatus>
  status_lte?: Maybe<LocationStatus>
  status_not_lte?: Maybe<LocationStatus>
  checkCode?: Maybe<Scalars['String']>
  checkCode_not?: Maybe<Scalars['String']>
  checkCode_contains?: Maybe<Scalars['String']>
  checkCode_not_contains?: Maybe<Scalars['String']>
  checkCode_starts_with?: Maybe<Scalars['String']>
  checkCode_not_starts_with?: Maybe<Scalars['String']>
  checkCode_ends_with?: Maybe<Scalars['String']>
  checkCode_not_ends_with?: Maybe<Scalars['String']>
  checkCode_in?: Maybe<Array<Maybe<Scalars['String']>>>
  checkCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  beam?: Maybe<Scalars['String']>
  beam_not?: Maybe<Scalars['String']>
  beam_contains?: Maybe<Scalars['String']>
  beam_not_contains?: Maybe<Scalars['String']>
  beam_starts_with?: Maybe<Scalars['String']>
  beam_not_starts_with?: Maybe<Scalars['String']>
  beam_ends_with?: Maybe<Scalars['String']>
  beam_not_ends_with?: Maybe<Scalars['String']>
  beam_in?: Maybe<Array<Maybe<Scalars['String']>>>
  beam_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  beamSequence?: Maybe<Scalars['String']>
  beamSequence_not?: Maybe<Scalars['String']>
  beamSequence_contains?: Maybe<Scalars['String']>
  beamSequence_not_contains?: Maybe<Scalars['String']>
  beamSequence_starts_with?: Maybe<Scalars['String']>
  beamSequence_not_starts_with?: Maybe<Scalars['String']>
  beamSequence_ends_with?: Maybe<Scalars['String']>
  beamSequence_not_ends_with?: Maybe<Scalars['String']>
  beamSequence_in?: Maybe<Array<Maybe<Scalars['String']>>>
  beamSequence_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  zone?: Maybe<Scalars['String']>
  zone_not?: Maybe<Scalars['String']>
  zone_contains?: Maybe<Scalars['String']>
  zone_not_contains?: Maybe<Scalars['String']>
  zone_starts_with?: Maybe<Scalars['String']>
  zone_not_starts_with?: Maybe<Scalars['String']>
  zone_ends_with?: Maybe<Scalars['String']>
  zone_not_ends_with?: Maybe<Scalars['String']>
  zone_in?: Maybe<Array<Scalars['String']>>
  zone_not_in?: Maybe<Array<Scalars['String']>>
  countZone?: Maybe<Scalars['String']>
  countZone_not?: Maybe<Scalars['String']>
  countZone_contains?: Maybe<Scalars['String']>
  countZone_not_contains?: Maybe<Scalars['String']>
  countZone_starts_with?: Maybe<Scalars['String']>
  countZone_not_starts_with?: Maybe<Scalars['String']>
  countZone_ends_with?: Maybe<Scalars['String']>
  countZone_not_ends_with?: Maybe<Scalars['String']>
  countZone_in?: Maybe<Array<Maybe<Scalars['String']>>>
  countZone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  tier?: Maybe<Scalars['Int']>
  tier_not?: Maybe<Scalars['Int']>
  tier_in?: Maybe<Array<Scalars['Int']>>
  tier_not_in?: Maybe<Array<Scalars['Int']>>
  tier_gt?: Maybe<Scalars['Int']>
  tier_not_gt?: Maybe<Scalars['Int']>
  tier_gte?: Maybe<Scalars['Int']>
  tier_not_gte?: Maybe<Scalars['Int']>
  tier_lt?: Maybe<Scalars['Int']>
  tier_not_lt?: Maybe<Scalars['Int']>
  tier_lte?: Maybe<Scalars['Int']>
  tier_not_lte?: Maybe<Scalars['Int']>
  elevation?: Maybe<Scalars['Decimal']>
  elevation_not?: Maybe<Scalars['Decimal']>
  elevation_in?: Maybe<Array<Scalars['Decimal']>>
  elevation_not_in?: Maybe<Array<Scalars['Decimal']>>
  elevation_gt?: Maybe<Scalars['Decimal']>
  elevation_not_gt?: Maybe<Scalars['Decimal']>
  elevation_gte?: Maybe<Scalars['Decimal']>
  elevation_not_gte?: Maybe<Scalars['Decimal']>
  elevation_lt?: Maybe<Scalars['Decimal']>
  elevation_not_lt?: Maybe<Scalars['Decimal']>
  elevation_lte?: Maybe<Scalars['Decimal']>
  elevation_not_lte?: Maybe<Scalars['Decimal']>
  dimensions?: Maybe<DimensionFilter>
  maxWeight?: Maybe<Scalars['Decimal']>
  maxWeight_not?: Maybe<Scalars['Decimal']>
  maxWeight_in?: Maybe<Array<Scalars['Decimal']>>
  maxWeight_not_in?: Maybe<Array<Scalars['Decimal']>>
  maxWeight_gt?: Maybe<Scalars['Decimal']>
  maxWeight_not_gt?: Maybe<Scalars['Decimal']>
  maxWeight_gte?: Maybe<Scalars['Decimal']>
  maxWeight_not_gte?: Maybe<Scalars['Decimal']>
  maxWeight_lt?: Maybe<Scalars['Decimal']>
  maxWeight_not_lt?: Maybe<Scalars['Decimal']>
  maxWeight_lte?: Maybe<Scalars['Decimal']>
  maxWeight_not_lte?: Maybe<Scalars['Decimal']>
  pickZone?: Maybe<Scalars['String']>
  pickZone_not?: Maybe<Scalars['String']>
  pickZone_contains?: Maybe<Scalars['String']>
  pickZone_not_contains?: Maybe<Scalars['String']>
  pickZone_starts_with?: Maybe<Scalars['String']>
  pickZone_not_starts_with?: Maybe<Scalars['String']>
  pickZone_ends_with?: Maybe<Scalars['String']>
  pickZone_not_ends_with?: Maybe<Scalars['String']>
  pickZone_in?: Maybe<Array<Maybe<Scalars['String']>>>
  pickZone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  reservedProductId?: Maybe<Scalars['Int']>
  reservedProductId_not?: Maybe<Scalars['Int']>
  reservedProductId_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  reservedProductId_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  reservedProductId_gt?: Maybe<Scalars['Int']>
  reservedProductId_not_gt?: Maybe<Scalars['Int']>
  reservedProductId_gte?: Maybe<Scalars['Int']>
  reservedProductId_not_gte?: Maybe<Scalars['Int']>
  reservedProductId_lt?: Maybe<Scalars['Int']>
  reservedProductId_not_lt?: Maybe<Scalars['Int']>
  reservedProductId_lte?: Maybe<Scalars['Int']>
  reservedProductId_not_lte?: Maybe<Scalars['Int']>
}

export type LocationFilterInput = {
  and?: Maybe<Array<LocationFilterInput>>
  or?: Maybe<Array<LocationFilterInput>>
  id?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  warehouse?: Maybe<WarehouseFilterInput>
  code?: Maybe<StringOperationFilterInput>
  physicalCharacteristics?: Maybe<NullableOfLocationPhysicalTypeOperationFilterInput>
  processCharacteristics?: Maybe<NullableOfLocationProcessTypeOperationFilterInput>
  temperatureType?: Maybe<NullableOfLocationTemperatureTypeOperationFilterInput>
  attributes?: Maybe<StringOperationFilterInput>
  storageTypeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  storageType?: Maybe<StorageTypeFilterInput>
  aisle?: Maybe<StringOperationFilterInput>
  status?: Maybe<NullableOfLocationStatusOperationFilterInput>
  checkCode?: Maybe<StringOperationFilterInput>
  beam?: Maybe<StringOperationFilterInput>
  beamSequence?: Maybe<StringOperationFilterInput>
  zone?: Maybe<StringOperationFilterInput>
  countZone?: Maybe<StringOperationFilterInput>
  tier?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  elevation?: Maybe<ComparableNullableOfDecimalOperationFilterInput>
  dimensions?: Maybe<DimensionFilterInput>
  maxWeight?: Maybe<ComparableNullableOfDecimalOperationFilterInput>
  pickZone?: Maybe<StringOperationFilterInput>
  reservedProductId?: Maybe<ComparableNullableOfInt32OperationFilterInput>
}

export type LocationInput = {
  id?: Maybe<Scalars['Int']>
  warehouse?: Maybe<WarehouseInput>
  code: Scalars['String']
  physicalCharacteristics: LocationPhysicalType
  processCharacteristics: LocationProcessType
  temperatureType: LocationTemperatureType
  attributes?: Maybe<Scalars['String']>
  storageTypeId: Scalars['Int']
  storageType?: Maybe<StorageTypeInput>
  aisle?: Maybe<Scalars['String']>
  status: LocationStatus
  checkCode?: Maybe<Scalars['String']>
  beam?: Maybe<Scalars['String']>
  beamSequence?: Maybe<Scalars['String']>
  zone: Scalars['String']
  countZone?: Maybe<Scalars['String']>
  tier: Scalars['Int']
  elevation: Scalars['Decimal']
  dimensions: DimensionInput
  maxWeight: Scalars['Decimal']
  pickZone?: Maybe<Scalars['String']>
  reservedProductId?: Maybe<Scalars['Int']>
}

export enum LocationPhysicalType {
  General = 'GENERAL',
  Gravity = 'GRAVITY',
  DriveIn = 'DRIVE_IN',
  Robotic = 'ROBOTIC',
  Cantilever = 'CANTILEVER',
  DoubleDeep = 'DOUBLE_DEEP',
  BlockStack = 'BLOCK_STACK',
  AFrame = 'A_FRAME',
  PandDStation = 'PAND_D_STATION',
  PushBack = 'PUSH_BACK'
}

export type LocationPhysicalTypeMetadata = {
  __typename?: 'LocationPhysicalTypeMetadata'
  id?: Maybe<Scalars['Int']>
  value?: Maybe<LocationPhysicalType>
  displayName?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  group?: Maybe<Scalars['String']>
}

export enum LocationProcessType {
  General = 'GENERAL',
  GoodsReceipt = 'GOODS_RECEIPT',
  Production = 'PRODUCTION',
  DespatchLane = 'DESPATCH_LANE',
  PickFace = 'PICK_FACE',
  PotentialPickFace = 'POTENTIAL_PICK_FACE',
  PackingStation = 'PACKING_STATION',
  ReservedForProduct = 'RESERVED_FOR_PRODUCT',
  RingFenced = 'RING_FENCED'
}

export type LocationProcessTypeMetadata = {
  __typename?: 'LocationProcessTypeMetadata'
  id?: Maybe<Scalars['Int']>
  value?: Maybe<LocationProcessType>
  displayName?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  group?: Maybe<Scalars['String']>
}

export type LocationSort = {
  id?: Maybe<SortOperationKind>
  warehouse?: Maybe<WarehouseSort>
  code?: Maybe<SortOperationKind>
  physicalCharacteristics?: Maybe<SortOperationKind>
  processCharacteristics?: Maybe<SortOperationKind>
  temperatureType?: Maybe<SortOperationKind>
  attributes?: Maybe<SortOperationKind>
  storageTypeId?: Maybe<SortOperationKind>
  storageType?: Maybe<StorageTypeSort>
  aisle?: Maybe<SortOperationKind>
  status?: Maybe<SortOperationKind>
  checkCode?: Maybe<SortOperationKind>
  beam?: Maybe<SortOperationKind>
  beamSequence?: Maybe<SortOperationKind>
  zone?: Maybe<SortOperationKind>
  countZone?: Maybe<SortOperationKind>
  tier?: Maybe<SortOperationKind>
  elevation?: Maybe<SortOperationKind>
  dimensions?: Maybe<DimensionSort>
  maxWeight?: Maybe<SortOperationKind>
  pickZone?: Maybe<SortOperationKind>
  reservedProductId?: Maybe<SortOperationKind>
}

export type LocationSortInput = {
  id?: Maybe<SortEnumType>
  warehouse?: Maybe<WarehouseSortInput>
  code?: Maybe<SortEnumType>
  physicalCharacteristics?: Maybe<SortEnumType>
  processCharacteristics?: Maybe<SortEnumType>
  temperatureType?: Maybe<SortEnumType>
  attributes?: Maybe<SortEnumType>
  storageTypeId?: Maybe<SortEnumType>
  storageType?: Maybe<StorageTypeSortInput>
  aisle?: Maybe<SortEnumType>
  status?: Maybe<SortEnumType>
  checkCode?: Maybe<SortEnumType>
  beam?: Maybe<SortEnumType>
  beamSequence?: Maybe<SortEnumType>
  zone?: Maybe<SortEnumType>
  countZone?: Maybe<SortEnumType>
  tier?: Maybe<SortEnumType>
  elevation?: Maybe<SortEnumType>
  dimensions?: Maybe<DimensionSortInput>
  maxWeight?: Maybe<SortEnumType>
  pickZone?: Maybe<SortEnumType>
  reservedProductId?: Maybe<SortEnumType>
}

export enum LocationStatus {
  Available = 'AVAILABLE',
  Damaged = 'DAMAGED',
  UnderConstruction = 'UNDER_CONSTRUCTION',
  Unavailable = 'UNAVAILABLE'
}

export enum LocationTemperatureType {
  NonControlled = 'NON_CONTROLLED',
  Ambient = 'AMBIENT',
  Chilled = 'CHILLED',
  Frozen = 'FROZEN'
}

export type LocationUpdateInput = {
  warehouseId?: Maybe<Scalars['Int']>
  warehouse?: Maybe<WarehouseUpdateInput>
  physicalCharacteristics?: Maybe<LocationPhysicalType>
  processCharacteristics?: Maybe<LocationProcessType>
  temperatureType?: Maybe<LocationTemperatureType>
  attributes?: Maybe<Scalars['String']>
  storageTypeId?: Maybe<Scalars['Int']>
  storageType?: Maybe<StorageTypeUpdateInput>
  aisle?: Maybe<Scalars['String']>
  status?: Maybe<LocationStatus>
  checkCode?: Maybe<Scalars['String']>
  beam?: Maybe<Scalars['String']>
  beamSequence?: Maybe<Scalars['String']>
  zone?: Maybe<Scalars['String']>
  countZone?: Maybe<Scalars['String']>
  tier?: Maybe<Scalars['Int']>
  elevation?: Maybe<Scalars['Decimal']>
  dimensions?: Maybe<DimensionUpdateInput>
  maxWeight?: Maybe<Scalars['Decimal']>
  pickZone?: Maybe<Scalars['String']>
  reservedProductId?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
}

export enum LpControlType {
  None = 'NONE',
  Serialized = 'SERIALIZED',
  Owned = 'OWNED'
}

export type LpDefinition = {
  __typename?: 'LPDefinition'
  id?: Maybe<Scalars['Int']>
  product?: Maybe<Product>
  handType: Scalars['String']
  packType?: Maybe<Scalars['String']>
  quantity: Scalars['Int']
  diagram?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['Decimal']>
  width?: Maybe<Scalars['Decimal']>
  depth?: Maybe<Scalars['Decimal']>
  weight?: Maybe<Scalars['Decimal']>
  layerQty?: Maybe<Scalars['Int']>
  maxLayerQty?: Maybe<Scalars['Int']>
}

export type LpDefinitionFilter = {
  AND?: Maybe<Array<LpDefinitionFilter>>
  OR?: Maybe<Array<LpDefinitionFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  product?: Maybe<ProductFilter>
  handType?: Maybe<Scalars['String']>
  handType_not?: Maybe<Scalars['String']>
  handType_contains?: Maybe<Scalars['String']>
  handType_not_contains?: Maybe<Scalars['String']>
  handType_starts_with?: Maybe<Scalars['String']>
  handType_not_starts_with?: Maybe<Scalars['String']>
  handType_ends_with?: Maybe<Scalars['String']>
  handType_not_ends_with?: Maybe<Scalars['String']>
  handType_in?: Maybe<Array<Scalars['String']>>
  handType_not_in?: Maybe<Array<Scalars['String']>>
  packType?: Maybe<Scalars['String']>
  packType_not?: Maybe<Scalars['String']>
  packType_contains?: Maybe<Scalars['String']>
  packType_not_contains?: Maybe<Scalars['String']>
  packType_starts_with?: Maybe<Scalars['String']>
  packType_not_starts_with?: Maybe<Scalars['String']>
  packType_ends_with?: Maybe<Scalars['String']>
  packType_not_ends_with?: Maybe<Scalars['String']>
  packType_in?: Maybe<Array<Maybe<Scalars['String']>>>
  packType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  quantity?: Maybe<Scalars['Int']>
  quantity_not?: Maybe<Scalars['Int']>
  quantity_in?: Maybe<Array<Scalars['Int']>>
  quantity_not_in?: Maybe<Array<Scalars['Int']>>
  quantity_gt?: Maybe<Scalars['Int']>
  quantity_not_gt?: Maybe<Scalars['Int']>
  quantity_gte?: Maybe<Scalars['Int']>
  quantity_not_gte?: Maybe<Scalars['Int']>
  quantity_lt?: Maybe<Scalars['Int']>
  quantity_not_lt?: Maybe<Scalars['Int']>
  quantity_lte?: Maybe<Scalars['Int']>
  quantity_not_lte?: Maybe<Scalars['Int']>
  diagram?: Maybe<Scalars['String']>
  diagram_not?: Maybe<Scalars['String']>
  diagram_contains?: Maybe<Scalars['String']>
  diagram_not_contains?: Maybe<Scalars['String']>
  diagram_starts_with?: Maybe<Scalars['String']>
  diagram_not_starts_with?: Maybe<Scalars['String']>
  diagram_ends_with?: Maybe<Scalars['String']>
  diagram_not_ends_with?: Maybe<Scalars['String']>
  diagram_in?: Maybe<Array<Maybe<Scalars['String']>>>
  diagram_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  height?: Maybe<Scalars['Decimal']>
  height_not?: Maybe<Scalars['Decimal']>
  height_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  height_not_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  height_gt?: Maybe<Scalars['Decimal']>
  height_not_gt?: Maybe<Scalars['Decimal']>
  height_gte?: Maybe<Scalars['Decimal']>
  height_not_gte?: Maybe<Scalars['Decimal']>
  height_lt?: Maybe<Scalars['Decimal']>
  height_not_lt?: Maybe<Scalars['Decimal']>
  height_lte?: Maybe<Scalars['Decimal']>
  height_not_lte?: Maybe<Scalars['Decimal']>
  width?: Maybe<Scalars['Decimal']>
  width_not?: Maybe<Scalars['Decimal']>
  width_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  width_not_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  width_gt?: Maybe<Scalars['Decimal']>
  width_not_gt?: Maybe<Scalars['Decimal']>
  width_gte?: Maybe<Scalars['Decimal']>
  width_not_gte?: Maybe<Scalars['Decimal']>
  width_lt?: Maybe<Scalars['Decimal']>
  width_not_lt?: Maybe<Scalars['Decimal']>
  width_lte?: Maybe<Scalars['Decimal']>
  width_not_lte?: Maybe<Scalars['Decimal']>
  depth?: Maybe<Scalars['Decimal']>
  depth_not?: Maybe<Scalars['Decimal']>
  depth_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  depth_not_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  depth_gt?: Maybe<Scalars['Decimal']>
  depth_not_gt?: Maybe<Scalars['Decimal']>
  depth_gte?: Maybe<Scalars['Decimal']>
  depth_not_gte?: Maybe<Scalars['Decimal']>
  depth_lt?: Maybe<Scalars['Decimal']>
  depth_not_lt?: Maybe<Scalars['Decimal']>
  depth_lte?: Maybe<Scalars['Decimal']>
  depth_not_lte?: Maybe<Scalars['Decimal']>
  weight?: Maybe<Scalars['Decimal']>
  weight_not?: Maybe<Scalars['Decimal']>
  weight_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  weight_not_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  weight_gt?: Maybe<Scalars['Decimal']>
  weight_not_gt?: Maybe<Scalars['Decimal']>
  weight_gte?: Maybe<Scalars['Decimal']>
  weight_not_gte?: Maybe<Scalars['Decimal']>
  weight_lt?: Maybe<Scalars['Decimal']>
  weight_not_lt?: Maybe<Scalars['Decimal']>
  weight_lte?: Maybe<Scalars['Decimal']>
  weight_not_lte?: Maybe<Scalars['Decimal']>
  layerQty?: Maybe<Scalars['Int']>
  layerQty_not?: Maybe<Scalars['Int']>
  layerQty_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  layerQty_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  layerQty_gt?: Maybe<Scalars['Int']>
  layerQty_not_gt?: Maybe<Scalars['Int']>
  layerQty_gte?: Maybe<Scalars['Int']>
  layerQty_not_gte?: Maybe<Scalars['Int']>
  layerQty_lt?: Maybe<Scalars['Int']>
  layerQty_not_lt?: Maybe<Scalars['Int']>
  layerQty_lte?: Maybe<Scalars['Int']>
  layerQty_not_lte?: Maybe<Scalars['Int']>
  maxLayerQty?: Maybe<Scalars['Int']>
  maxLayerQty_not?: Maybe<Scalars['Int']>
  maxLayerQty_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  maxLayerQty_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  maxLayerQty_gt?: Maybe<Scalars['Int']>
  maxLayerQty_not_gt?: Maybe<Scalars['Int']>
  maxLayerQty_gte?: Maybe<Scalars['Int']>
  maxLayerQty_not_gte?: Maybe<Scalars['Int']>
  maxLayerQty_lt?: Maybe<Scalars['Int']>
  maxLayerQty_not_lt?: Maybe<Scalars['Int']>
  maxLayerQty_lte?: Maybe<Scalars['Int']>
  maxLayerQty_not_lte?: Maybe<Scalars['Int']>
}

export type LpDefinitionFilterInput = {
  and?: Maybe<Array<LpDefinitionFilterInput>>
  or?: Maybe<Array<LpDefinitionFilterInput>>
  id?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  product?: Maybe<ProductFilterInput>
  handType?: Maybe<StringOperationFilterInput>
  packType?: Maybe<StringOperationFilterInput>
  quantity?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  diagram?: Maybe<StringOperationFilterInput>
  height?: Maybe<ComparableNullableOfDecimalOperationFilterInput>
  width?: Maybe<ComparableNullableOfDecimalOperationFilterInput>
  depth?: Maybe<ComparableNullableOfDecimalOperationFilterInput>
  weight?: Maybe<ComparableNullableOfDecimalOperationFilterInput>
  layerQty?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  maxLayerQty?: Maybe<ComparableNullableOfInt32OperationFilterInput>
}

export type LpDefinitionInput = {
  id?: Maybe<Scalars['Int']>
  product?: Maybe<ProductInput>
  handType: Scalars['String']
  packType?: Maybe<Scalars['String']>
  quantity: Scalars['Int']
  diagram?: Maybe<Scalars['String']>
  height: Scalars['Int']
  width: Scalars['Int']
  depth: Scalars['Int']
  weight: Scalars['Int']
  layerQty: Scalars['Int']
  maxLayerQty: Scalars['Int']
}

export type LpDefinitionUpdateInput = {
  handType?: Maybe<Scalars['String']>
  packType?: Maybe<Scalars['String']>
  quantity?: Maybe<Scalars['Int']>
  diagram?: Maybe<Scalars['String']>
  height?: Maybe<Scalars['Decimal']>
  width?: Maybe<Scalars['Decimal']>
  depth?: Maybe<Scalars['Decimal']>
  weight?: Maybe<Scalars['Decimal']>
  layerQty?: Maybe<Scalars['Int']>
  maxLayerQty?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
}

export type LpOwner = {
  __typename?: 'LPOwner'
  owner?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
}

export type LpOwnerFilterInput = {
  and?: Maybe<Array<LpOwnerFilterInput>>
  or?: Maybe<Array<LpOwnerFilterInput>>
  owner?: Maybe<StringOperationFilterInput>
  id?: Maybe<StringOperationFilterInput>
}

export type LpOwnerSortInput = {
  owner?: Maybe<SortEnumType>
  id?: Maybe<SortEnumType>
}

export type MaterialHandlingEquipment = {
  __typename?: 'MaterialHandlingEquipment'
  id?: Maybe<Scalars['Int']>
  code: Scalars['String']
  description: Scalars['String']
  maximumWeight?: Maybe<Scalars['Decimal']>
  width?: Maybe<Scalars['Decimal']>
  heightLimit?: Maybe<Scalars['Decimal']>
  class: MheClass
}

export type MaterialHandlingEquipmentConnection = {
  __typename?: 'MaterialHandlingEquipmentConnection'
  pageInfo: PageInfo
  edges?: Maybe<Array<MaterialHandlingEquipmentEdge>>
  nodes?: Maybe<Array<Maybe<MaterialHandlingEquipment>>>
  totalCount: Scalars['Int']
}

export type MaterialHandlingEquipmentEdge = {
  __typename?: 'MaterialHandlingEquipmentEdge'
  cursor: Scalars['String']
  node?: Maybe<MaterialHandlingEquipment>
}

export type MaterialHandlingEquipmentFilter = {
  AND?: Maybe<Array<MaterialHandlingEquipmentFilter>>
  OR?: Maybe<Array<MaterialHandlingEquipmentFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
  code_not?: Maybe<Scalars['String']>
  code_contains?: Maybe<Scalars['String']>
  code_not_contains?: Maybe<Scalars['String']>
  code_starts_with?: Maybe<Scalars['String']>
  code_not_starts_with?: Maybe<Scalars['String']>
  code_ends_with?: Maybe<Scalars['String']>
  code_not_ends_with?: Maybe<Scalars['String']>
  code_in?: Maybe<Array<Scalars['String']>>
  code_not_in?: Maybe<Array<Scalars['String']>>
  description?: Maybe<Scalars['String']>
  description_not?: Maybe<Scalars['String']>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  description_starts_with?: Maybe<Scalars['String']>
  description_not_starts_with?: Maybe<Scalars['String']>
  description_ends_with?: Maybe<Scalars['String']>
  description_not_ends_with?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Scalars['String']>>
  description_not_in?: Maybe<Array<Scalars['String']>>
  maximumWeight?: Maybe<Scalars['Decimal']>
  maximumWeight_not?: Maybe<Scalars['Decimal']>
  maximumWeight_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  maximumWeight_not_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  maximumWeight_gt?: Maybe<Scalars['Decimal']>
  maximumWeight_not_gt?: Maybe<Scalars['Decimal']>
  maximumWeight_gte?: Maybe<Scalars['Decimal']>
  maximumWeight_not_gte?: Maybe<Scalars['Decimal']>
  maximumWeight_lt?: Maybe<Scalars['Decimal']>
  maximumWeight_not_lt?: Maybe<Scalars['Decimal']>
  maximumWeight_lte?: Maybe<Scalars['Decimal']>
  maximumWeight_not_lte?: Maybe<Scalars['Decimal']>
  width?: Maybe<Scalars['Decimal']>
  width_not?: Maybe<Scalars['Decimal']>
  width_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  width_not_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  width_gt?: Maybe<Scalars['Decimal']>
  width_not_gt?: Maybe<Scalars['Decimal']>
  width_gte?: Maybe<Scalars['Decimal']>
  width_not_gte?: Maybe<Scalars['Decimal']>
  width_lt?: Maybe<Scalars['Decimal']>
  width_not_lt?: Maybe<Scalars['Decimal']>
  width_lte?: Maybe<Scalars['Decimal']>
  width_not_lte?: Maybe<Scalars['Decimal']>
  heightLimit?: Maybe<Scalars['Decimal']>
  heightLimit_not?: Maybe<Scalars['Decimal']>
  heightLimit_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  heightLimit_not_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  heightLimit_gt?: Maybe<Scalars['Decimal']>
  heightLimit_not_gt?: Maybe<Scalars['Decimal']>
  heightLimit_gte?: Maybe<Scalars['Decimal']>
  heightLimit_not_gte?: Maybe<Scalars['Decimal']>
  heightLimit_lt?: Maybe<Scalars['Decimal']>
  heightLimit_not_lt?: Maybe<Scalars['Decimal']>
  heightLimit_lte?: Maybe<Scalars['Decimal']>
  heightLimit_not_lte?: Maybe<Scalars['Decimal']>
  class?: Maybe<MheClass>
  class_not?: Maybe<MheClass>
  class_in?: Maybe<Array<MheClass>>
  class_not_in?: Maybe<Array<MheClass>>
  class_gt?: Maybe<MheClass>
  class_not_gt?: Maybe<MheClass>
  class_gte?: Maybe<MheClass>
  class_not_gte?: Maybe<MheClass>
  class_lt?: Maybe<MheClass>
  class_not_lt?: Maybe<MheClass>
  class_lte?: Maybe<MheClass>
  class_not_lte?: Maybe<MheClass>
}

export type MaterialHandlingEquipmentFilterInput = {
  and?: Maybe<Array<MaterialHandlingEquipmentFilterInput>>
  or?: Maybe<Array<MaterialHandlingEquipmentFilterInput>>
  id?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  code?: Maybe<StringOperationFilterInput>
  description?: Maybe<StringOperationFilterInput>
  maximumWeight?: Maybe<ComparableNullableOfDecimalOperationFilterInput>
  width?: Maybe<ComparableNullableOfDecimalOperationFilterInput>
  heightLimit?: Maybe<ComparableNullableOfDecimalOperationFilterInput>
  class?: Maybe<NullableOfMheClassOperationFilterInput>
}

export type MaterialHandlingEquipmentInput = {
  id?: Maybe<Scalars['Int']>
  code: Scalars['String']
  description: Scalars['String']
  maximumWeight?: Maybe<Scalars['Decimal']>
  width?: Maybe<Scalars['Decimal']>
  heightLimit?: Maybe<Scalars['Decimal']>
  class: MheClass
}

export type MaterialHandlingEquipmentSort = {
  id?: Maybe<SortOperationKind>
  code?: Maybe<SortOperationKind>
  description?: Maybe<SortOperationKind>
  maximumWeight?: Maybe<SortOperationKind>
  width?: Maybe<SortOperationKind>
  heightLimit?: Maybe<SortOperationKind>
  class?: Maybe<SortOperationKind>
}

export type MaterialHandlingEquipmentUpdateInput = {
  description?: Maybe<Scalars['String']>
  maximumWeight?: Maybe<Scalars['Decimal']>
  width?: Maybe<Scalars['Decimal']>
  heightLimit?: Maybe<Scalars['Decimal']>
  class?: Maybe<MheClass>
  id?: Maybe<Scalars['Int']>
}

export type MaxWeeklySpend = {
  __typename?: 'MaxWeeklySpend'
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

export type MaxWeeklySpendInput = {
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

export enum MheClass {
  None = 'NONE',
  Manual = 'MANUAL',
  VnaV = 'VNA_V',
  VnaK = 'VNA_K',
  Reach = 'REACH',
  Pallet = 'PALLET'
}

export type Mutation = {
  __typename?: 'Mutation'
  NOP?: Maybe<Scalars['String']>
  addCarrierConfig?: Maybe<CarrierConfig>
  addHandlingStorageType?: Maybe<HandlingStorageType>
  addStockOwnerLink?: Maybe<StockOwnerLink>
  addStorageTypeMHE?: Maybe<StorageTypeMhe>
  addUser?: Maybe<WmsUserResult>
  changePassword?: Maybe<WmsUserResult>
  createBOMDefinition?: Maybe<BomDefinition>
  createBudgetHolder?: Maybe<BudgetHolder>
  createCarrier?: Maybe<Carrier>
  createCarrierAccount?: Maybe<CarrierAccount>
  createCarrierService?: Maybe<CarrierService>
  createDestination?: Maybe<Destination>
  createDestinationBudget?: Maybe<DestinationBudget>
  createDestinationRoute?: Maybe<DestinationRoute>
  createExchangeRate?: Maybe<ExchangeRate>
  createHandlingType?: Maybe<HandlingType>
  createLocation?: Maybe<Location>
  createMaterialHandlingEquipment?: Maybe<MaterialHandlingEquipment>
  createOrderEntry?: Maybe<OrderEntry>
  createOrderStatus?: Maybe<OrderStatus>
  createProduct?: Maybe<Product>
  createProductBarcode?: Maybe<ProductBarcode>
  createProductCategory?: Maybe<ProductCategory>
  createProductDocument?: Maybe<ProductDocument>
  createProductLicencePlate?: Maybe<LpDefinition>
  createProductPickface?: Maybe<ProductPickface>
  createProductStatus?: Maybe<ProductStatus>
  createProductSupplier?: Maybe<ProductSupplier>
  createStockOwner?: Maybe<StockOwner>
  createStockOwnerCategoryDefinition?: Maybe<CategoryDefinition>
  createStockOwnerPickConfirmMethod?: Maybe<PickConfirmMethod>
  createStockOwnerPickMethod?: Maybe<PickMethod>
  createStorageType?: Maybe<StorageType>
  createSupplier?: Maybe<Supplier>
  createUDC?: Maybe<UdCode>
  createUserPermission?: Maybe<UserPermission>
  createWarehouse?: Maybe<Warehouse>
  createWarehouseZone?: Maybe<WarehouseZone>
  createZone?: Maybe<Zone>
  deleteBOMDefinition?: Maybe<BomDefinition>
  deleteBudgetHolder?: Maybe<BudgetHolder>
  deleteCarrier?: Maybe<Carrier>
  deleteCarrierAccount?: Maybe<CarrierAccount>
  deleteCarrierService?: Maybe<CarrierService>
  deleteDestination?: Maybe<Destination>
  deleteDestinationBudget?: Maybe<DestinationBudget>
  deleteDestinationRoute?: Maybe<DestinationRoute>
  deleteExchangeRate?: Maybe<ExchangeRate>
  deleteHandlingType?: Maybe<HandlingType>
  deleteLocation?: Maybe<Location>
  deleteMaterialHandlingEquipment?: Maybe<MaterialHandlingEquipment>
  deleteOrderEntry?: Maybe<OrderEntry>
  deleteOrderStatus?: Maybe<OrderStatus>
  deleteProduct?: Maybe<Product>
  deleteProductBarcode?: Maybe<ProductBarcode>
  deleteProductCategory?: Maybe<ProductCategory>
  deleteProductDocument?: Maybe<ProductDocument>
  deleteProductLicencePlate?: Maybe<LpDefinition>
  deleteProductPickface?: Maybe<ProductPickface>
  deleteProductStatus?: Maybe<ProductStatus>
  deleteProductSupplier?: Maybe<ProductSupplier>
  deleteStockOwner?: Maybe<StockOwner>
  deleteStockOwnerCategoryDefinition?: Maybe<CategoryDefinition>
  deleteStockOwnerPickConfirmMethod?: Maybe<PickConfirmMethod>
  deleteStockOwnerPickMethod?: Maybe<PickMethod>
  deleteStorageType?: Maybe<StorageType>
  deleteSupplier?: Maybe<Supplier>
  deleteUDC?: Maybe<UdCode>
  deleteUser?: Maybe<WmsUserResult>
  deleteUserPermission?: Maybe<UserPermission>
  deleteWarehouse?: Maybe<Warehouse>
  deleteWarehouseZone?: Maybe<WarehouseZone>
  deleteZone?: Maybe<Zone>
  removeCarrierConfig?: Maybe<CarrierConfig>
  removeHandlingStorageType?: Maybe<HandlingStorageType>
  removeStockOwnerLink?: Maybe<StockOwnerLink>
  removeStorageTypeMHE?: Maybe<StorageTypeMhe>
  resources?: Maybe<ResourcesMutation>
  setPasswordForUser?: Maybe<WmsUserResult>
  updateBOMDefinition?: Maybe<BomDefinition>
  updateBudgetHolder?: Maybe<BudgetHolder>
  updateCarrier?: Maybe<Carrier>
  updateCarrierAccount?: Maybe<CarrierAccount>
  updateCarrierConfig?: Maybe<CarrierConfig>
  updateCarrierService?: Maybe<CarrierService>
  updateDestination?: Maybe<Destination>
  updateDestinationBudget?: Maybe<DestinationBudget>
  updateDestinationRoute?: Maybe<DestinationRoute>
  updateExchangeRate?: Maybe<ExchangeRate>
  updateHandlingType?: Maybe<HandlingType>
  updateLocation?: Maybe<Location>
  updateMaterialHandlingEquipment?: Maybe<MaterialHandlingEquipment>
  updateOrderEntry?: Maybe<OrderEntry>
  updateOrderStatus?: Maybe<OrderStatus>
  updateProduct?: Maybe<Product>
  updateProductBarcode?: Maybe<ProductBarcode>
  updateProductCategory?: Maybe<ProductCategory>
  updateProductDocument?: Maybe<ProductDocument>
  updateProductLicencePlate?: Maybe<LpDefinition>
  updateProductPickface?: Maybe<ProductPickface>
  updateProductStatus?: Maybe<ProductStatus>
  updateProductSupplier?: Maybe<ProductSupplier>
  updateStockOwner?: Maybe<StockOwner>
  updateStockOwnerCategoryDefinition?: Maybe<CategoryDefinition>
  updateStockOwnerPickConfirmMethod?: Maybe<PickConfirmMethod>
  updateStockOwnerPickMethod?: Maybe<PickMethod>
  updateStorageType?: Maybe<StorageType>
  updateSupplier?: Maybe<Supplier>
  updateUDC?: Maybe<UdCode>
  updateUser?: Maybe<WmsUserResult>
  updateUserPermission?: Maybe<UserPermission>
  updateWarehouse?: Maybe<Warehouse>
  updateWarehouseZone?: Maybe<WarehouseZone>
  updateZone?: Maybe<Zone>
}

export type MutationAddCarrierConfigArgs = {
  userContext?: Maybe<UserContextInput>
  carrierId: Scalars['Int']
  accountId: Scalars['Int']
  config?: Maybe<CarrierConfigInput>
}

export type MutationAddHandlingStorageTypeArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
  storageTypeId: Scalars['Int']
}

export type MutationAddStockOwnerLinkArgs = {
  userContext?: Maybe<UserContextInput>
  stockOwnerId: Scalars['Int']
  linkedStockOwnerId: Scalars['Int']
}

export type MutationAddStorageTypeMheArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
  MHEId: Scalars['Int']
}

export type MutationAddUserArgs = {
  input?: Maybe<UserInput>
}

export type MutationChangePasswordArgs = {
  oldPassword?: Maybe<Scalars['String']>
  newPassword?: Maybe<Scalars['String']>
}

export type MutationCreateBomDefinitionArgs = {
  userContext?: Maybe<UserContextInput>
  productId: Scalars['Int']
  component?: Maybe<BomDefinitionInput>
}

export type MutationCreateBudgetHolderArgs = {
  userContext?: Maybe<UserContextInput>
  input?: Maybe<BudgetHolderInput>
}

export type MutationCreateCarrierArgs = {
  userContext?: Maybe<UserContextInput>
  input?: Maybe<CarrierInput>
}

export type MutationCreateCarrierAccountArgs = {
  userContext?: Maybe<UserContextInput>
  carrierId: Scalars['Int']
  input?: Maybe<CarrierAccountInput>
}

export type MutationCreateCarrierServiceArgs = {
  userContext?: Maybe<UserContextInput>
  carrierId: Scalars['Int']
  input?: Maybe<CarrierServiceInput>
}

export type MutationCreateDestinationArgs = {
  userContext?: Maybe<UserContextInput>
  input?: Maybe<DestinationInput>
}

export type MutationCreateDestinationBudgetArgs = {
  userContext?: Maybe<UserContextInput>
  destinationId: Scalars['Int']
  input?: Maybe<DestinationBudgetInput>
}

export type MutationCreateDestinationRouteArgs = {
  userContext?: Maybe<UserContextInput>
  destinationId: Scalars['Int']
  input?: Maybe<DestinationRouteInput>
}

export type MutationCreateExchangeRateArgs = {
  userContext?: Maybe<UserContextInput>
  input?: Maybe<ExchangeRateInput>
}

export type MutationCreateHandlingTypeArgs = {
  userContext?: Maybe<UserContextInput>
  input?: Maybe<HandlingTypeInput>
}

export type MutationCreateLocationArgs = {
  userContext?: Maybe<UserContextInput>
  input?: Maybe<LocationInput>
}

export type MutationCreateMaterialHandlingEquipmentArgs = {
  userContext?: Maybe<UserContextInput>
  input?: Maybe<MaterialHandlingEquipmentInput>
}

export type MutationCreateOrderEntryArgs = {
  input: CreateOrderEntryInput
}

export type MutationCreateOrderStatusArgs = {
  userContext?: Maybe<UserContextInput>
  stockOwnerId: Scalars['Int']
  input?: Maybe<OrderStatusInput>
}

export type MutationCreateProductArgs = {
  userContext?: Maybe<UserContextInput>
  input?: Maybe<ProductInput>
}

export type MutationCreateProductBarcodeArgs = {
  userContext?: Maybe<UserContextInput>
  productId: Scalars['Int']
  productBarcode?: Maybe<ProductBarcodeInput>
}

export type MutationCreateProductCategoryArgs = {
  userContext?: Maybe<UserContextInput>
  productId: Scalars['Int']
  category?: Maybe<ProductCategoryInput>
}

export type MutationCreateProductDocumentArgs = {
  userContext?: Maybe<UserContextInput>
  productId: Scalars['Int']
  productDocument?: Maybe<ProductDocumentInput>
}

export type MutationCreateProductLicencePlateArgs = {
  userContext?: Maybe<UserContextInput>
  productId: Scalars['Int']
  licencePlate?: Maybe<LpDefinitionInput>
}

export type MutationCreateProductPickfaceArgs = {
  userContext?: Maybe<UserContextInput>
  productId: Scalars['Int']
  pickface?: Maybe<ProductPickfaceInput>
}

export type MutationCreateProductStatusArgs = {
  userContext?: Maybe<UserContextInput>
  stockOwnerId: Scalars['Int']
  input?: Maybe<ProductStatusInput>
}

export type MutationCreateProductSupplierArgs = {
  userContext?: Maybe<UserContextInput>
  productId: Scalars['Int']
  productSupplier?: Maybe<ProductSupplierInput>
}

export type MutationCreateStockOwnerArgs = {
  userContext?: Maybe<UserContextInput>
  input?: Maybe<StockOwnerInput>
}

export type MutationCreateStockOwnerCategoryDefinitionArgs = {
  userContext?: Maybe<UserContextInput>
  stockOwnerId: Scalars['Int']
  input?: Maybe<CategoryDefinitionInput>
}

export type MutationCreateStockOwnerPickConfirmMethodArgs = {
  userContext?: Maybe<UserContextInput>
  stockOwnerId: Scalars['Int']
  input?: Maybe<PickConfirmMethodInput>
}

export type MutationCreateStockOwnerPickMethodArgs = {
  userContext?: Maybe<UserContextInput>
  stockOwnerId: Scalars['Int']
  input?: Maybe<PickMethodInput>
}

export type MutationCreateStorageTypeArgs = {
  userContext?: Maybe<UserContextInput>
  input?: Maybe<StorageTypeInput>
}

export type MutationCreateSupplierArgs = {
  userContext?: Maybe<UserContextInput>
  input?: Maybe<SupplierInput>
}

export type MutationCreateUdcArgs = {
  userContext?: Maybe<UserContextInput>
  input?: Maybe<UdCodeInput>
}

export type MutationCreateUserPermissionArgs = {
  userContext?: Maybe<UserContextInput>
  input?: Maybe<UserPermissionInput>
}

export type MutationCreateWarehouseArgs = {
  userContext?: Maybe<UserContextInput>
  input?: Maybe<WarehouseInput>
}

export type MutationCreateWarehouseZoneArgs = {
  userContext?: Maybe<UserContextInput>
  input?: Maybe<WarehouseZoneInput>
}

export type MutationCreateZoneArgs = {
  input: ZoneInput
}

export type MutationDeleteBomDefinitionArgs = {
  userContext?: Maybe<UserContextInput>
  productId: Scalars['Int']
  componentId: Scalars['Int']
}

export type MutationDeleteBudgetHolderArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type MutationDeleteCarrierArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type MutationDeleteCarrierAccountArgs = {
  userContext?: Maybe<UserContextInput>
  carrierId: Scalars['Int']
  id: Scalars['Int']
}

export type MutationDeleteCarrierServiceArgs = {
  userContext?: Maybe<UserContextInput>
  carrierId: Scalars['Int']
  id: Scalars['Int']
}

export type MutationDeleteDestinationArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type MutationDeleteDestinationBudgetArgs = {
  userContext?: Maybe<UserContextInput>
  destinationId: Scalars['Int']
  id: Scalars['Int']
}

export type MutationDeleteDestinationRouteArgs = {
  userContext?: Maybe<UserContextInput>
  destinationId: Scalars['Int']
  id: Scalars['Int']
}

export type MutationDeleteExchangeRateArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type MutationDeleteHandlingTypeArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type MutationDeleteLocationArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type MutationDeleteMaterialHandlingEquipmentArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type MutationDeleteOrderEntryArgs = {
  id: Scalars['ID']
}

export type MutationDeleteOrderStatusArgs = {
  userContext?: Maybe<UserContextInput>
  stockOwnerId: Scalars['Int']
  id: Scalars['Int']
}

export type MutationDeleteProductArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type MutationDeleteProductBarcodeArgs = {
  userContext?: Maybe<UserContextInput>
  productId: Scalars['Int']
  id: Scalars['Int']
}

export type MutationDeleteProductCategoryArgs = {
  userContext?: Maybe<UserContextInput>
  productId: Scalars['Int']
  id: Scalars['Int']
}

export type MutationDeleteProductDocumentArgs = {
  userContext?: Maybe<UserContextInput>
  productId: Scalars['Int']
  id: Scalars['Int']
}

export type MutationDeleteProductLicencePlateArgs = {
  userContext?: Maybe<UserContextInput>
  productId: Scalars['Int']
  id: Scalars['Int']
}

export type MutationDeleteProductPickfaceArgs = {
  userContext?: Maybe<UserContextInput>
  productId: Scalars['Int']
  id: Scalars['Int']
}

export type MutationDeleteProductStatusArgs = {
  userContext?: Maybe<UserContextInput>
  stockOwnerId: Scalars['Int']
  id: Scalars['Int']
}

export type MutationDeleteProductSupplierArgs = {
  userContext?: Maybe<UserContextInput>
  productId: Scalars['Int']
  id: Scalars['Int']
}

export type MutationDeleteStockOwnerArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type MutationDeleteStockOwnerCategoryDefinitionArgs = {
  userContext?: Maybe<UserContextInput>
  stockOwnerId: Scalars['Int']
  id: Scalars['Int']
}

export type MutationDeleteStockOwnerPickConfirmMethodArgs = {
  userContext?: Maybe<UserContextInput>
  stockOwnerId: Scalars['Int']
  id: Scalars['Int']
}

export type MutationDeleteStockOwnerPickMethodArgs = {
  userContext?: Maybe<UserContextInput>
  stockOwnerId: Scalars['Int']
  id: Scalars['Int']
}

export type MutationDeleteStorageTypeArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type MutationDeleteSupplierArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type MutationDeleteUdcArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type MutationDeleteUserArgs = {
  id?: Maybe<Scalars['String']>
}

export type MutationDeleteUserPermissionArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type MutationDeleteWarehouseArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type MutationDeleteWarehouseZoneArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type MutationDeleteZoneArgs = {
  id: Scalars['ID']
}

export type MutationRemoveCarrierConfigArgs = {
  userContext?: Maybe<UserContextInput>
  carrierId: Scalars['Int']
  accountId: Scalars['Int']
  id: Scalars['Int']
}

export type MutationRemoveHandlingStorageTypeArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
  storageTypeId: Scalars['Int']
}

export type MutationRemoveStockOwnerLinkArgs = {
  userContext?: Maybe<UserContextInput>
  stockOwnerId: Scalars['Int']
  linkedStockOwnerId: Scalars['Int']
}

export type MutationRemoveStorageTypeMheArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
  MHEId: Scalars['Int']
}

export type MutationSetPasswordForUserArgs = {
  id?: Maybe<Scalars['String']>
  newPassword?: Maybe<Scalars['String']>
}

export type MutationUpdateBomDefinitionArgs = {
  userContext?: Maybe<UserContextInput>
  productId: Scalars['Int']
  componentId: Scalars['Int']
  update?: Maybe<BomDefinitionUpdateInput>
}

export type MutationUpdateBudgetHolderArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
  input?: Maybe<BudgetHolderUpdateInput>
}

export type MutationUpdateCarrierArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
  update?: Maybe<CarrierUpdateInput>
}

export type MutationUpdateCarrierAccountArgs = {
  userContext?: Maybe<UserContextInput>
  carrierId: Scalars['Int']
  id: Scalars['Int']
  update?: Maybe<CarrierAccountUpdateInput>
}

export type MutationUpdateCarrierConfigArgs = {
  userContext?: Maybe<UserContextInput>
  carrierId: Scalars['Int']
  accountId: Scalars['Int']
  id: Scalars['Int']
  update?: Maybe<CarrierConfigUpdateInput>
}

export type MutationUpdateCarrierServiceArgs = {
  userContext?: Maybe<UserContextInput>
  carrierId: Scalars['Int']
  id: Scalars['Int']
  update?: Maybe<CarrierServiceUpdateInput>
}

export type MutationUpdateDestinationArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
  update?: Maybe<DestinationUpdateInput>
}

export type MutationUpdateDestinationBudgetArgs = {
  userContext?: Maybe<UserContextInput>
  destinationId: Scalars['Int']
  id: Scalars['Int']
  update?: Maybe<DestinationBudgetUpdateInput>
}

export type MutationUpdateDestinationRouteArgs = {
  userContext?: Maybe<UserContextInput>
  destinationId: Scalars['Int']
  id: Scalars['Int']
  update?: Maybe<DestinationRouteUpdateInput>
}

export type MutationUpdateExchangeRateArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
  update?: Maybe<ExchangeRateUpdateInput>
}

export type MutationUpdateHandlingTypeArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
  input?: Maybe<HandlingTypeUpdateInput>
}

export type MutationUpdateLocationArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
  input?: Maybe<LocationUpdateInput>
}

export type MutationUpdateMaterialHandlingEquipmentArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
  update?: Maybe<MaterialHandlingEquipmentUpdateInput>
}

export type MutationUpdateOrderEntryArgs = {
  id: Scalars['ID']
  input: UpdateOrderEntryInput
}

export type MutationUpdateOrderStatusArgs = {
  userContext?: Maybe<UserContextInput>
  stockOwnerId: Scalars['Int']
  id: Scalars['Int']
  input?: Maybe<OrderStatusUpdateInput>
}

export type MutationUpdateProductArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
  update?: Maybe<ProductUpdateInput>
}

export type MutationUpdateProductBarcodeArgs = {
  userContext?: Maybe<UserContextInput>
  productId: Scalars['Int']
  id: Scalars['Int']
  update?: Maybe<ProductBarcodeUpdateInput>
}

export type MutationUpdateProductCategoryArgs = {
  userContext?: Maybe<UserContextInput>
  productId: Scalars['Int']
  id: Scalars['Int']
  update?: Maybe<ProductCategoryUpdateInput>
}

export type MutationUpdateProductDocumentArgs = {
  userContext?: Maybe<UserContextInput>
  productId: Scalars['Int']
  id: Scalars['Int']
  update?: Maybe<ProductDocumentUpdateInput>
}

export type MutationUpdateProductLicencePlateArgs = {
  userContext?: Maybe<UserContextInput>
  productId: Scalars['Int']
  id: Scalars['Int']
  update?: Maybe<LpDefinitionUpdateInput>
}

export type MutationUpdateProductPickfaceArgs = {
  userContext?: Maybe<UserContextInput>
  productId: Scalars['Int']
  id: Scalars['Int']
  update?: Maybe<ProductPickfaceUpdateInput>
}

export type MutationUpdateProductStatusArgs = {
  userContext?: Maybe<UserContextInput>
  stockOwnerId: Scalars['Int']
  id: Scalars['Int']
  input?: Maybe<ProductStatusUpdateInput>
}

export type MutationUpdateProductSupplierArgs = {
  userContext?: Maybe<UserContextInput>
  productId: Scalars['Int']
  id: Scalars['Int']
  update?: Maybe<ProductSupplierUpdateInput>
}

export type MutationUpdateStockOwnerArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
  input?: Maybe<StockOwnerUpdateInput>
}

export type MutationUpdateStockOwnerCategoryDefinitionArgs = {
  userContext?: Maybe<UserContextInput>
  stockOwnerId: Scalars['Int']
  id: Scalars['Int']
  input?: Maybe<CategoryDefinitionUpdateInput>
}

export type MutationUpdateStockOwnerPickConfirmMethodArgs = {
  userContext?: Maybe<UserContextInput>
  stockOwnerId: Scalars['Int']
  id: Scalars['Int']
  input?: Maybe<PickConfirmMethodUpdateInput>
}

export type MutationUpdateStockOwnerPickMethodArgs = {
  userContext?: Maybe<UserContextInput>
  stockOwnerId: Scalars['Int']
  id: Scalars['Int']
  input?: Maybe<PickMethodUpdateInput>
}

export type MutationUpdateStorageTypeArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
  input?: Maybe<StorageTypeUpdateInput>
}

export type MutationUpdateSupplierArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
  update?: Maybe<SupplierUpdateInput>
}

export type MutationUpdateUdcArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
  input?: Maybe<UdCodeUpdateInput>
}

export type MutationUpdateUserArgs = {
  id?: Maybe<Scalars['String']>
  input?: Maybe<UserUpdateInput>
}

export type MutationUpdateUserPermissionArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
  update?: Maybe<UserPermissionUpdateInput>
}

export type MutationUpdateWarehouseArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
  input?: Maybe<WarehouseUpdateInput>
}

export type MutationUpdateWarehouseZoneArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
  update?: Maybe<WarehouseZoneUpdateInput>
}

export type MutationUpdateZoneArgs = {
  input: ZoneInput
}

export type NullableOfAccessiblityOperationFilterInput = {
  eq?: Maybe<Accessiblity>
  neq?: Maybe<Accessiblity>
  in?: Maybe<Array<Maybe<Accessiblity>>>
  nin?: Maybe<Array<Maybe<Accessiblity>>>
}

export type NullableOfBomTypeOperationFilterInput = {
  eq?: Maybe<BomType>
  neq?: Maybe<BomType>
  in?: Maybe<Array<Maybe<BomType>>>
  nin?: Maybe<Array<Maybe<BomType>>>
}

export type NullableOfCollectWeightOperationFilterInput = {
  eq?: Maybe<CollectWeight>
  neq?: Maybe<CollectWeight>
  in?: Maybe<Array<Maybe<CollectWeight>>>
  nin?: Maybe<Array<Maybe<CollectWeight>>>
}

export type NullableOfCurrencyOperationFilterInput = {
  eq?: Maybe<Currency>
  neq?: Maybe<Currency>
  in?: Maybe<Array<Maybe<Currency>>>
  nin?: Maybe<Array<Maybe<Currency>>>
}

export type NullableOfFieldVisibilityOperationFilterInput = {
  eq?: Maybe<FieldVisibility>
  neq?: Maybe<FieldVisibility>
  in?: Maybe<Array<Maybe<FieldVisibility>>>
  nin?: Maybe<Array<Maybe<FieldVisibility>>>
}

export type NullableOfFileFormatOperationFilterInput = {
  eq?: Maybe<FileFormat>
  neq?: Maybe<FileFormat>
  in?: Maybe<Array<Maybe<FileFormat>>>
  nin?: Maybe<Array<Maybe<FileFormat>>>
}

export type NullableOfFileTransmissionOperationFilterInput = {
  eq?: Maybe<FileTransmission>
  neq?: Maybe<FileTransmission>
  in?: Maybe<Array<Maybe<FileTransmission>>>
  nin?: Maybe<Array<Maybe<FileTransmission>>>
}

export type NullableOfLoadOrderOperationFilterInput = {
  eq?: Maybe<LoadOrder>
  neq?: Maybe<LoadOrder>
  in?: Maybe<Array<Maybe<LoadOrder>>>
  nin?: Maybe<Array<Maybe<LoadOrder>>>
}

export type NullableOfLocationPhysicalTypeOperationFilterInput = {
  eq?: Maybe<LocationPhysicalType>
  neq?: Maybe<LocationPhysicalType>
  in?: Maybe<Array<Maybe<LocationPhysicalType>>>
  nin?: Maybe<Array<Maybe<LocationPhysicalType>>>
}

export type NullableOfLocationProcessTypeOperationFilterInput = {
  eq?: Maybe<LocationProcessType>
  neq?: Maybe<LocationProcessType>
  in?: Maybe<Array<Maybe<LocationProcessType>>>
  nin?: Maybe<Array<Maybe<LocationProcessType>>>
}

export type NullableOfLocationStatusOperationFilterInput = {
  eq?: Maybe<LocationStatus>
  neq?: Maybe<LocationStatus>
  in?: Maybe<Array<Maybe<LocationStatus>>>
  nin?: Maybe<Array<Maybe<LocationStatus>>>
}

export type NullableOfLocationTemperatureTypeOperationFilterInput = {
  eq?: Maybe<LocationTemperatureType>
  neq?: Maybe<LocationTemperatureType>
  in?: Maybe<Array<Maybe<LocationTemperatureType>>>
  nin?: Maybe<Array<Maybe<LocationTemperatureType>>>
}

export type NullableOfLpControlTypeOperationFilterInput = {
  eq?: Maybe<LpControlType>
  neq?: Maybe<LpControlType>
  in?: Maybe<Array<Maybe<LpControlType>>>
  nin?: Maybe<Array<Maybe<LpControlType>>>
}

export type NullableOfMheClassOperationFilterInput = {
  eq?: Maybe<MheClass>
  neq?: Maybe<MheClass>
  in?: Maybe<Array<Maybe<MheClass>>>
  nin?: Maybe<Array<Maybe<MheClass>>>
}

export type NullableOfOrderStatusPurposeOperationFilterInput = {
  eq?: Maybe<OrderStatusPurpose>
  neq?: Maybe<OrderStatusPurpose>
  in?: Maybe<Array<Maybe<OrderStatusPurpose>>>
  nin?: Maybe<Array<Maybe<OrderStatusPurpose>>>
}

export type NullableOfPickAssignmentOperationFilterInput = {
  eq?: Maybe<PickAssignment>
  neq?: Maybe<PickAssignment>
  in?: Maybe<Array<Maybe<PickAssignment>>>
  nin?: Maybe<Array<Maybe<PickAssignment>>>
}

export type NullableOfProductProcessStatusOperationFilterInput = {
  eq?: Maybe<ProductProcessStatus>
  neq?: Maybe<ProductProcessStatus>
  in?: Maybe<Array<Maybe<ProductProcessStatus>>>
  nin?: Maybe<Array<Maybe<ProductProcessStatus>>>
}

export type NullableOfRotationRuleOperationFilterInput = {
  eq?: Maybe<RotationRule>
  neq?: Maybe<RotationRule>
  in?: Maybe<Array<Maybe<RotationRule>>>
  nin?: Maybe<Array<Maybe<RotationRule>>>
}

export type NullableOfStateOperationFilterInput = {
  eq?: Maybe<State>
  neq?: Maybe<State>
  in?: Maybe<Array<Maybe<State>>>
  nin?: Maybe<Array<Maybe<State>>>
}

export type NumberFormatInfoFilterInput = {
  and?: Maybe<Array<NumberFormatInfoFilterInput>>
  or?: Maybe<Array<NumberFormatInfoFilterInput>>
  currencyDecimalDigits?: Maybe<ComparableInt32OperationFilterInput>
  currencyDecimalSeparator?: Maybe<StringOperationFilterInput>
  isReadOnly?: Maybe<BooleanOperationFilterInput>
  currencyGroupSizes?: Maybe<ListComparableInt32OperationFilterInput>
  numberGroupSizes?: Maybe<ListComparableInt32OperationFilterInput>
  percentGroupSizes?: Maybe<ListComparableInt32OperationFilterInput>
  currencyGroupSeparator?: Maybe<StringOperationFilterInput>
  currencySymbol?: Maybe<StringOperationFilterInput>
  naNSymbol?: Maybe<StringOperationFilterInput>
  currencyNegativePattern?: Maybe<ComparableInt32OperationFilterInput>
  numberNegativePattern?: Maybe<ComparableInt32OperationFilterInput>
  percentPositivePattern?: Maybe<ComparableInt32OperationFilterInput>
  percentNegativePattern?: Maybe<ComparableInt32OperationFilterInput>
  negativeInfinitySymbol?: Maybe<StringOperationFilterInput>
  negativeSign?: Maybe<StringOperationFilterInput>
  numberDecimalDigits?: Maybe<ComparableInt32OperationFilterInput>
  numberDecimalSeparator?: Maybe<StringOperationFilterInput>
  numberGroupSeparator?: Maybe<StringOperationFilterInput>
  currencyPositivePattern?: Maybe<ComparableInt32OperationFilterInput>
  positiveInfinitySymbol?: Maybe<StringOperationFilterInput>
  positiveSign?: Maybe<StringOperationFilterInput>
  percentDecimalDigits?: Maybe<ComparableInt32OperationFilterInput>
  percentDecimalSeparator?: Maybe<StringOperationFilterInput>
  percentGroupSeparator?: Maybe<StringOperationFilterInput>
  percentSymbol?: Maybe<StringOperationFilterInput>
  perMilleSymbol?: Maybe<StringOperationFilterInput>
  nativeDigits?: Maybe<ListStringOperationFilterInput>
  digitSubstitution?: Maybe<DigitShapesOperationFilterInput>
}

export type NumberFormatInfoSortInput = {
  currencyDecimalDigits?: Maybe<SortEnumType>
  currencyDecimalSeparator?: Maybe<SortEnumType>
  isReadOnly?: Maybe<SortEnumType>
  currencyGroupSeparator?: Maybe<SortEnumType>
  currencySymbol?: Maybe<SortEnumType>
  naNSymbol?: Maybe<SortEnumType>
  currencyNegativePattern?: Maybe<SortEnumType>
  numberNegativePattern?: Maybe<SortEnumType>
  percentPositivePattern?: Maybe<SortEnumType>
  percentNegativePattern?: Maybe<SortEnumType>
  negativeInfinitySymbol?: Maybe<SortEnumType>
  negativeSign?: Maybe<SortEnumType>
  numberDecimalDigits?: Maybe<SortEnumType>
  numberDecimalSeparator?: Maybe<SortEnumType>
  numberGroupSeparator?: Maybe<SortEnumType>
  currencyPositivePattern?: Maybe<SortEnumType>
  positiveInfinitySymbol?: Maybe<SortEnumType>
  positiveSign?: Maybe<SortEnumType>
  percentDecimalDigits?: Maybe<SortEnumType>
  percentDecimalSeparator?: Maybe<SortEnumType>
  percentGroupSeparator?: Maybe<SortEnumType>
  percentSymbol?: Maybe<SortEnumType>
  perMilleSymbol?: Maybe<SortEnumType>
  digitSubstitution?: Maybe<SortEnumType>
}

export type OrderEntry = {
  __typename?: 'OrderEntry'
  id: Scalars['ID']
  orderNumber: Scalars['String']
  destinationCode: DestinationCode
  budgetHolder: BudgetHolder
  priority: Scalars['Int']
  orderType: OrderType
  soCustomerRef: Scalars['String']
}

export type OrderFilter = {
  orderNumber_starts_with?: Maybe<Scalars['String']>
  destinationCodeId?: Maybe<Scalars['ID']>
  budgetHolderId?: Maybe<Scalars['ID']>
}

export type OrderStatus = {
  __typename?: 'OrderStatus'
  id?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwner>
  code: Scalars['String']
  description: Scalars['String']
  purpose: OrderStatusPurpose
}

export type OrderStatusFilter = {
  AND?: Maybe<Array<OrderStatusFilter>>
  OR?: Maybe<Array<OrderStatusFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwnerFilter>
  code?: Maybe<Scalars['String']>
  code_not?: Maybe<Scalars['String']>
  code_contains?: Maybe<Scalars['String']>
  code_not_contains?: Maybe<Scalars['String']>
  code_starts_with?: Maybe<Scalars['String']>
  code_not_starts_with?: Maybe<Scalars['String']>
  code_ends_with?: Maybe<Scalars['String']>
  code_not_ends_with?: Maybe<Scalars['String']>
  code_in?: Maybe<Array<Scalars['String']>>
  code_not_in?: Maybe<Array<Scalars['String']>>
  description?: Maybe<Scalars['String']>
  description_not?: Maybe<Scalars['String']>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  description_starts_with?: Maybe<Scalars['String']>
  description_not_starts_with?: Maybe<Scalars['String']>
  description_ends_with?: Maybe<Scalars['String']>
  description_not_ends_with?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Scalars['String']>>
  description_not_in?: Maybe<Array<Scalars['String']>>
  purpose?: Maybe<OrderStatusPurpose>
  purpose_not?: Maybe<OrderStatusPurpose>
  purpose_in?: Maybe<Array<OrderStatusPurpose>>
  purpose_not_in?: Maybe<Array<OrderStatusPurpose>>
  purpose_gt?: Maybe<OrderStatusPurpose>
  purpose_not_gt?: Maybe<OrderStatusPurpose>
  purpose_gte?: Maybe<OrderStatusPurpose>
  purpose_not_gte?: Maybe<OrderStatusPurpose>
  purpose_lt?: Maybe<OrderStatusPurpose>
  purpose_not_lt?: Maybe<OrderStatusPurpose>
  purpose_lte?: Maybe<OrderStatusPurpose>
  purpose_not_lte?: Maybe<OrderStatusPurpose>
}

export type OrderStatusFilterInput = {
  and?: Maybe<Array<OrderStatusFilterInput>>
  or?: Maybe<Array<OrderStatusFilterInput>>
  id?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  stockOwner?: Maybe<StockOwnerFilterInput>
  code?: Maybe<StringOperationFilterInput>
  description?: Maybe<StringOperationFilterInput>
  purpose?: Maybe<NullableOfOrderStatusPurposeOperationFilterInput>
}

export type OrderStatusInput = {
  id?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwnerInput>
  code: Scalars['String']
  description: Scalars['String']
  purpose: OrderStatusPurpose
}

export enum OrderStatusPurpose {
  Unknown = 'UNKNOWN',
  Interfacing = 'INTERFACING',
  Editing = 'EDITING',
  Locked = 'LOCKED',
  AwaitingApproval = 'AWAITING_APPROVAL',
  Open = 'OPEN',
  OnPick = 'ON_PICK',
  PickStarted = 'PICK_STARTED',
  Picked = 'PICKED',
  PackInProgress = 'PACK_IN_PROGRESS',
  PackComplete = 'PACK_COMPLETE',
  AwaitingDespatch = 'AWAITING_DESPATCH',
  AwaitingInspection = 'AWAITING_INSPECTION',
  AwaitingDocumentation = 'AWAITING_DOCUMENTATION',
  LoadStarted = 'LOAD_STARTED',
  Loaded = 'LOADED',
  Despatched = 'DESPATCHED',
  Exported = 'EXPORTED'
}

export type OrderStatusUpdateInput = {
  stockOwnerId: Scalars['Int']
  stockOwner?: Maybe<StockOwnerUpdateInput>
  code?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  purpose?: Maybe<OrderStatusPurpose>
  id?: Maybe<Scalars['Int']>
}

export type OrderType = {
  __typename?: 'OrderType'
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
}

export type PageInfo = {
  __typename?: 'PageInfo'
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
  startCursor?: Maybe<Scalars['String']>
  endCursor?: Maybe<Scalars['String']>
}

export enum Permission {
  CreateOrder = 'CREATE_ORDER',
  DeleteOrder = 'DELETE_ORDER',
  IsSupervisor = 'IS_SUPERVISOR'
}

export enum PickAssignment {
  AnyAvailable = 'ANY_AVAILABLE',
  NotAvailable = 'NOT_AVAILABLE',
  AssignedOnly = 'ASSIGNED_ONLY'
}

export type PickConfirmMethod = {
  __typename?: 'PickConfirmMethod'
  id?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwner>
  name: Scalars['String']
  default?: Maybe<Scalars['Boolean']>
  pickConfMethod?: Maybe<Scalars['String']>
  postConfMethod?: Maybe<Scalars['String']>
  packingMethod?: Maybe<Scalars['String']>
  despatchMethod?: Maybe<Scalars['String']>
  loadCloseMethod?: Maybe<Scalars['String']>
}

export type PickConfirmMethodFilter = {
  AND?: Maybe<Array<PickConfirmMethodFilter>>
  OR?: Maybe<Array<PickConfirmMethodFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwnerFilter>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Scalars['String']>>
  name_not_in?: Maybe<Array<Scalars['String']>>
  default?: Maybe<Scalars['Boolean']>
  default_not?: Maybe<Scalars['Boolean']>
  pickConfMethod?: Maybe<Scalars['String']>
  pickConfMethod_not?: Maybe<Scalars['String']>
  pickConfMethod_contains?: Maybe<Scalars['String']>
  pickConfMethod_not_contains?: Maybe<Scalars['String']>
  pickConfMethod_starts_with?: Maybe<Scalars['String']>
  pickConfMethod_not_starts_with?: Maybe<Scalars['String']>
  pickConfMethod_ends_with?: Maybe<Scalars['String']>
  pickConfMethod_not_ends_with?: Maybe<Scalars['String']>
  pickConfMethod_in?: Maybe<Array<Maybe<Scalars['String']>>>
  pickConfMethod_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  postConfMethod?: Maybe<Scalars['String']>
  postConfMethod_not?: Maybe<Scalars['String']>
  postConfMethod_contains?: Maybe<Scalars['String']>
  postConfMethod_not_contains?: Maybe<Scalars['String']>
  postConfMethod_starts_with?: Maybe<Scalars['String']>
  postConfMethod_not_starts_with?: Maybe<Scalars['String']>
  postConfMethod_ends_with?: Maybe<Scalars['String']>
  postConfMethod_not_ends_with?: Maybe<Scalars['String']>
  postConfMethod_in?: Maybe<Array<Maybe<Scalars['String']>>>
  postConfMethod_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  packingMethod?: Maybe<Scalars['String']>
  packingMethod_not?: Maybe<Scalars['String']>
  packingMethod_contains?: Maybe<Scalars['String']>
  packingMethod_not_contains?: Maybe<Scalars['String']>
  packingMethod_starts_with?: Maybe<Scalars['String']>
  packingMethod_not_starts_with?: Maybe<Scalars['String']>
  packingMethod_ends_with?: Maybe<Scalars['String']>
  packingMethod_not_ends_with?: Maybe<Scalars['String']>
  packingMethod_in?: Maybe<Array<Maybe<Scalars['String']>>>
  packingMethod_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  despatchMethod?: Maybe<Scalars['String']>
  despatchMethod_not?: Maybe<Scalars['String']>
  despatchMethod_contains?: Maybe<Scalars['String']>
  despatchMethod_not_contains?: Maybe<Scalars['String']>
  despatchMethod_starts_with?: Maybe<Scalars['String']>
  despatchMethod_not_starts_with?: Maybe<Scalars['String']>
  despatchMethod_ends_with?: Maybe<Scalars['String']>
  despatchMethod_not_ends_with?: Maybe<Scalars['String']>
  despatchMethod_in?: Maybe<Array<Maybe<Scalars['String']>>>
  despatchMethod_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  loadCloseMethod?: Maybe<Scalars['String']>
  loadCloseMethod_not?: Maybe<Scalars['String']>
  loadCloseMethod_contains?: Maybe<Scalars['String']>
  loadCloseMethod_not_contains?: Maybe<Scalars['String']>
  loadCloseMethod_starts_with?: Maybe<Scalars['String']>
  loadCloseMethod_not_starts_with?: Maybe<Scalars['String']>
  loadCloseMethod_ends_with?: Maybe<Scalars['String']>
  loadCloseMethod_not_ends_with?: Maybe<Scalars['String']>
  loadCloseMethod_in?: Maybe<Array<Maybe<Scalars['String']>>>
  loadCloseMethod_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type PickConfirmMethodFilterInput = {
  and?: Maybe<Array<PickConfirmMethodFilterInput>>
  or?: Maybe<Array<PickConfirmMethodFilterInput>>
  id?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  stockOwner?: Maybe<StockOwnerFilterInput>
  name?: Maybe<StringOperationFilterInput>
  default?: Maybe<BooleanOperationFilterInput>
  pickConfMethod?: Maybe<StringOperationFilterInput>
  postConfMethod?: Maybe<StringOperationFilterInput>
  packingMethod?: Maybe<StringOperationFilterInput>
  despatchMethod?: Maybe<StringOperationFilterInput>
  loadCloseMethod?: Maybe<StringOperationFilterInput>
}

export type PickConfirmMethodInput = {
  id?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwnerInput>
  name: Scalars['String']
  default: Scalars['Boolean']
  pickConfMethod?: Maybe<Scalars['String']>
  postConfMethod?: Maybe<Scalars['String']>
  packingMethod?: Maybe<Scalars['String']>
  despatchMethod?: Maybe<Scalars['String']>
  loadCloseMethod?: Maybe<Scalars['String']>
}

export type PickConfirmMethodUpdateInput = {
  stockOwnerId: Scalars['Int']
  stockOwner?: Maybe<StockOwnerUpdateInput>
  name?: Maybe<Scalars['String']>
  default?: Maybe<Scalars['Boolean']>
  pickConfMethod?: Maybe<Scalars['String']>
  postConfMethod?: Maybe<Scalars['String']>
  packingMethod?: Maybe<Scalars['String']>
  despatchMethod?: Maybe<Scalars['String']>
  loadCloseMethod?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
}

export type PickListType = {
  __typename?: 'PickListType'
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

export type PickMethod = {
  __typename?: 'PickMethod'
  id?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwner>
  name: Scalars['String']
  default?: Maybe<Scalars['Boolean']>
  prePickMethod?: Maybe<Scalars['String']>
  pickingMethod?: Maybe<Scalars['String']>
  postPickMethod?: Maybe<Scalars['String']>
  outputMethod?: Maybe<Scalars['String']>
}

export type PickMethodFilter = {
  AND?: Maybe<Array<PickMethodFilter>>
  OR?: Maybe<Array<PickMethodFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwnerFilter>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Scalars['String']>>
  name_not_in?: Maybe<Array<Scalars['String']>>
  default?: Maybe<Scalars['Boolean']>
  default_not?: Maybe<Scalars['Boolean']>
  prePickMethod?: Maybe<Scalars['String']>
  prePickMethod_not?: Maybe<Scalars['String']>
  prePickMethod_contains?: Maybe<Scalars['String']>
  prePickMethod_not_contains?: Maybe<Scalars['String']>
  prePickMethod_starts_with?: Maybe<Scalars['String']>
  prePickMethod_not_starts_with?: Maybe<Scalars['String']>
  prePickMethod_ends_with?: Maybe<Scalars['String']>
  prePickMethod_not_ends_with?: Maybe<Scalars['String']>
  prePickMethod_in?: Maybe<Array<Maybe<Scalars['String']>>>
  prePickMethod_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  pickingMethod?: Maybe<Scalars['String']>
  pickingMethod_not?: Maybe<Scalars['String']>
  pickingMethod_contains?: Maybe<Scalars['String']>
  pickingMethod_not_contains?: Maybe<Scalars['String']>
  pickingMethod_starts_with?: Maybe<Scalars['String']>
  pickingMethod_not_starts_with?: Maybe<Scalars['String']>
  pickingMethod_ends_with?: Maybe<Scalars['String']>
  pickingMethod_not_ends_with?: Maybe<Scalars['String']>
  pickingMethod_in?: Maybe<Array<Maybe<Scalars['String']>>>
  pickingMethod_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  postPickMethod?: Maybe<Scalars['String']>
  postPickMethod_not?: Maybe<Scalars['String']>
  postPickMethod_contains?: Maybe<Scalars['String']>
  postPickMethod_not_contains?: Maybe<Scalars['String']>
  postPickMethod_starts_with?: Maybe<Scalars['String']>
  postPickMethod_not_starts_with?: Maybe<Scalars['String']>
  postPickMethod_ends_with?: Maybe<Scalars['String']>
  postPickMethod_not_ends_with?: Maybe<Scalars['String']>
  postPickMethod_in?: Maybe<Array<Maybe<Scalars['String']>>>
  postPickMethod_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  outputMethod?: Maybe<Scalars['String']>
  outputMethod_not?: Maybe<Scalars['String']>
  outputMethod_contains?: Maybe<Scalars['String']>
  outputMethod_not_contains?: Maybe<Scalars['String']>
  outputMethod_starts_with?: Maybe<Scalars['String']>
  outputMethod_not_starts_with?: Maybe<Scalars['String']>
  outputMethod_ends_with?: Maybe<Scalars['String']>
  outputMethod_not_ends_with?: Maybe<Scalars['String']>
  outputMethod_in?: Maybe<Array<Maybe<Scalars['String']>>>
  outputMethod_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type PickMethodFilterInput = {
  and?: Maybe<Array<PickMethodFilterInput>>
  or?: Maybe<Array<PickMethodFilterInput>>
  id?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  stockOwner?: Maybe<StockOwnerFilterInput>
  name?: Maybe<StringOperationFilterInput>
  default?: Maybe<BooleanOperationFilterInput>
  prePickMethod?: Maybe<StringOperationFilterInput>
  pickingMethod?: Maybe<StringOperationFilterInput>
  postPickMethod?: Maybe<StringOperationFilterInput>
  outputMethod?: Maybe<StringOperationFilterInput>
}

export type PickMethodInput = {
  id?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwnerInput>
  name: Scalars['String']
  default: Scalars['Boolean']
  prePickMethod?: Maybe<Scalars['String']>
  pickingMethod?: Maybe<Scalars['String']>
  postPickMethod?: Maybe<Scalars['String']>
  outputMethod?: Maybe<Scalars['String']>
}

export type PickMethodSort = {
  id?: Maybe<SortOperationKind>
  stockOwner?: Maybe<StockOwnerSort>
  name?: Maybe<SortOperationKind>
  default?: Maybe<SortOperationKind>
  prePickMethod?: Maybe<SortOperationKind>
  pickingMethod?: Maybe<SortOperationKind>
  postPickMethod?: Maybe<SortOperationKind>
  outputMethod?: Maybe<SortOperationKind>
}

export type PickMethodSortInput = {
  id?: Maybe<SortEnumType>
  stockOwner?: Maybe<StockOwnerSortInput>
  name?: Maybe<SortEnumType>
  default?: Maybe<SortEnumType>
  prePickMethod?: Maybe<SortEnumType>
  pickingMethod?: Maybe<SortEnumType>
  postPickMethod?: Maybe<SortEnumType>
  outputMethod?: Maybe<SortEnumType>
}

export type PickMethodUpdateInput = {
  stockOwnerId: Scalars['Int']
  stockOwner?: Maybe<StockOwnerUpdateInput>
  name?: Maybe<Scalars['String']>
  default?: Maybe<Scalars['Boolean']>
  prePickMethod?: Maybe<Scalars['String']>
  pickingMethod?: Maybe<Scalars['String']>
  postPickMethod?: Maybe<Scalars['String']>
  outputMethod?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
}

export type PoFormat = {
  __typename?: 'POFormat'
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

export type PoFormatInput = {
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

export type PoTransmissionMethod = {
  __typename?: 'POTransmissionMethod'
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

export type PoTransmissionMethodInput = {
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

export enum ProcessorArchitecture {
  None = 'NONE',
  Msil = 'MSIL',
  X86 = 'X86',
  Ia64 = 'IA64',
  Amd64 = 'AMD64',
  Arm = 'ARM'
}

export type Product = {
  __typename?: 'Product'
  stockOwner?: Maybe<StockOwner>
  manufacturer?: Maybe<UdCode>
  id?: Maybe<Scalars['Int']>
  code: Scalars['String']
  altCode?: Maybe<Scalars['String']>
  gTIN?: Maybe<Scalars['String']>
  substitute?: Maybe<Scalars['String']>
  description: Scalars['String']
  longDesc?: Maybe<Scalars['String']>
  linkCode?: Maybe<Scalars['String']>
  categories?: Maybe<Array<Maybe<ProductCategory>>>
  components?: Maybe<Array<Maybe<BomDefinition>>>
  suppliers?: Maybe<Array<Maybe<ProductSupplier>>>
  licencePlates?: Maybe<Array<Maybe<LpDefinition>>>
  barcodes?: Maybe<Array<Maybe<ProductBarcode>>>
  documents?: Maybe<Array<Maybe<ProductDocument>>>
  require?: Maybe<ProductRequiredData>
  SKUType?: Maybe<BomType>
  INCICode?: Maybe<Scalars['String']>
  rotation?: Maybe<RotationRule>
  type?: Maybe<Scalars['String']>
  StorageType?: Maybe<Scalars['String']>
  facilityCat?: Maybe<Scalars['String']>
  defBudhol?: Maybe<Scalars['String']>
  defWH?: Maybe<Scalars['String']>
  defHandType?: Maybe<Scalars['String']>
  defPackType?: Maybe<Scalars['String']>
  depCode?: Maybe<Scalars['String']>
  rank?: Maybe<Scalars['String']>
  uNCode?: Maybe<Scalars['String']>
  bandQty?: Maybe<Scalars['Int']>
  manufacturerCode?: Maybe<Scalars['String']>
  lifeCycleStatus?: Maybe<Scalars['String']>
  packValidateBatch?: Maybe<Scalars['Boolean']>
  capturePackType?: Maybe<Scalars['Boolean']>
  pickPartPalletsAsFull?: Maybe<Scalars['Boolean']>
  pickSingles?: Maybe<Scalars['Boolean']>
  pickDefId?: Maybe<Scalars['Int']>
  defPickDefinition?: Maybe<PickMethod>
  pickfaces?: Maybe<Array<Maybe<ProductPickface>>>
  defGRStatus: Scalars['String']
  minRShlfLife?: Maybe<Scalars['Int']>
  minPShlfLife?: Maybe<Scalars['Int']>
  totShlfLife?: Maybe<Scalars['Int']>
}

export type ProductBarcode = {
  __typename?: 'ProductBarcode'
  id?: Maybe<Scalars['Int']>
  product?: Maybe<Product>
  barcode: Scalars['String']
  symbology?: Maybe<Scalars['String']>
  factor: Scalars['Int']
  unitType?: Maybe<Scalars['String']>
}

export type ProductBarcodeFilter = {
  AND?: Maybe<Array<ProductBarcodeFilter>>
  OR?: Maybe<Array<ProductBarcodeFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  product?: Maybe<ProductFilter>
  barcode?: Maybe<Scalars['String']>
  barcode_not?: Maybe<Scalars['String']>
  barcode_contains?: Maybe<Scalars['String']>
  barcode_not_contains?: Maybe<Scalars['String']>
  barcode_starts_with?: Maybe<Scalars['String']>
  barcode_not_starts_with?: Maybe<Scalars['String']>
  barcode_ends_with?: Maybe<Scalars['String']>
  barcode_not_ends_with?: Maybe<Scalars['String']>
  barcode_in?: Maybe<Array<Scalars['String']>>
  barcode_not_in?: Maybe<Array<Scalars['String']>>
  symbology?: Maybe<Scalars['String']>
  symbology_not?: Maybe<Scalars['String']>
  symbology_contains?: Maybe<Scalars['String']>
  symbology_not_contains?: Maybe<Scalars['String']>
  symbology_starts_with?: Maybe<Scalars['String']>
  symbology_not_starts_with?: Maybe<Scalars['String']>
  symbology_ends_with?: Maybe<Scalars['String']>
  symbology_not_ends_with?: Maybe<Scalars['String']>
  symbology_in?: Maybe<Array<Maybe<Scalars['String']>>>
  symbology_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  factor?: Maybe<Scalars['Int']>
  factor_not?: Maybe<Scalars['Int']>
  factor_in?: Maybe<Array<Scalars['Int']>>
  factor_not_in?: Maybe<Array<Scalars['Int']>>
  factor_gt?: Maybe<Scalars['Int']>
  factor_not_gt?: Maybe<Scalars['Int']>
  factor_gte?: Maybe<Scalars['Int']>
  factor_not_gte?: Maybe<Scalars['Int']>
  factor_lt?: Maybe<Scalars['Int']>
  factor_not_lt?: Maybe<Scalars['Int']>
  factor_lte?: Maybe<Scalars['Int']>
  factor_not_lte?: Maybe<Scalars['Int']>
  unitType?: Maybe<Scalars['String']>
  unitType_not?: Maybe<Scalars['String']>
  unitType_contains?: Maybe<Scalars['String']>
  unitType_not_contains?: Maybe<Scalars['String']>
  unitType_starts_with?: Maybe<Scalars['String']>
  unitType_not_starts_with?: Maybe<Scalars['String']>
  unitType_ends_with?: Maybe<Scalars['String']>
  unitType_not_ends_with?: Maybe<Scalars['String']>
  unitType_in?: Maybe<Array<Maybe<Scalars['String']>>>
  unitType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProductBarcodeFilterInput = {
  and?: Maybe<Array<ProductBarcodeFilterInput>>
  or?: Maybe<Array<ProductBarcodeFilterInput>>
  id?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  product?: Maybe<ProductFilterInput>
  barcode?: Maybe<StringOperationFilterInput>
  symbology?: Maybe<StringOperationFilterInput>
  factor?: Maybe<ComparableInt32OperationFilterInput>
  unitType?: Maybe<StringOperationFilterInput>
}

export type ProductBarcodeInput = {
  id?: Maybe<Scalars['Int']>
  product?: Maybe<ProductInput>
  barcode: Scalars['String']
  symbology?: Maybe<Scalars['String']>
  factor: Scalars['Int']
  unitType?: Maybe<Scalars['String']>
}

export type ProductBarcodeUpdateInput = {
  barcode?: Maybe<Scalars['String']>
  symbology?: Maybe<Scalars['String']>
  factor: Scalars['Int']
  unitType?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
}

export type ProductCategory = {
  __typename?: 'ProductCategory'
  id?: Maybe<Scalars['Int']>
  product?: Maybe<Product>
  category: Scalars['String']
  attribute: Scalars['String']
}

export type ProductCategoryFilter = {
  AND?: Maybe<Array<ProductCategoryFilter>>
  OR?: Maybe<Array<ProductCategoryFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  product?: Maybe<ProductFilter>
  category?: Maybe<Scalars['String']>
  category_not?: Maybe<Scalars['String']>
  category_contains?: Maybe<Scalars['String']>
  category_not_contains?: Maybe<Scalars['String']>
  category_starts_with?: Maybe<Scalars['String']>
  category_not_starts_with?: Maybe<Scalars['String']>
  category_ends_with?: Maybe<Scalars['String']>
  category_not_ends_with?: Maybe<Scalars['String']>
  category_in?: Maybe<Array<Scalars['String']>>
  category_not_in?: Maybe<Array<Scalars['String']>>
  attribute?: Maybe<Scalars['String']>
  attribute_not?: Maybe<Scalars['String']>
  attribute_contains?: Maybe<Scalars['String']>
  attribute_not_contains?: Maybe<Scalars['String']>
  attribute_starts_with?: Maybe<Scalars['String']>
  attribute_not_starts_with?: Maybe<Scalars['String']>
  attribute_ends_with?: Maybe<Scalars['String']>
  attribute_not_ends_with?: Maybe<Scalars['String']>
  attribute_in?: Maybe<Array<Scalars['String']>>
  attribute_not_in?: Maybe<Array<Scalars['String']>>
}

export type ProductCategoryFilterInput = {
  and?: Maybe<Array<ProductCategoryFilterInput>>
  or?: Maybe<Array<ProductCategoryFilterInput>>
  id?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  product?: Maybe<ProductFilterInput>
  category?: Maybe<StringOperationFilterInput>
  attribute?: Maybe<StringOperationFilterInput>
}

export type ProductCategoryInput = {
  id?: Maybe<Scalars['Int']>
  product?: Maybe<ProductInput>
  category: Scalars['String']
  attribute: Scalars['String']
}

export type ProductCategoryUpdateInput = {
  productId: Scalars['Int']
  product?: Maybe<ProductUpdateInput>
  category?: Maybe<Scalars['String']>
  attribute?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
}

export type ProductConnection = {
  __typename?: 'ProductConnection'
  pageInfo: PageInfo
  edges?: Maybe<Array<ProductEdge>>
  nodes?: Maybe<Array<Maybe<Product>>>
  totalCount: Scalars['Int']
}

export type ProductDocument = {
  __typename?: 'ProductDocument'
  id?: Maybe<Scalars['Int']>
  product?: Maybe<Product>
  name: Scalars['String']
  type?: Maybe<Scalars['String']>
  usage?: Maybe<Scalars['String']>
  uploaded?: Maybe<Scalars['DateTime']>
  uploadBy?: Maybe<Scalars['String']>
}

export type ProductDocumentFilter = {
  AND?: Maybe<Array<ProductDocumentFilter>>
  OR?: Maybe<Array<ProductDocumentFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  product?: Maybe<ProductFilter>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Scalars['String']>>
  name_not_in?: Maybe<Array<Scalars['String']>>
  type?: Maybe<Scalars['String']>
  type_not?: Maybe<Scalars['String']>
  type_contains?: Maybe<Scalars['String']>
  type_not_contains?: Maybe<Scalars['String']>
  type_starts_with?: Maybe<Scalars['String']>
  type_not_starts_with?: Maybe<Scalars['String']>
  type_ends_with?: Maybe<Scalars['String']>
  type_not_ends_with?: Maybe<Scalars['String']>
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  usage?: Maybe<Scalars['String']>
  usage_not?: Maybe<Scalars['String']>
  usage_contains?: Maybe<Scalars['String']>
  usage_not_contains?: Maybe<Scalars['String']>
  usage_starts_with?: Maybe<Scalars['String']>
  usage_not_starts_with?: Maybe<Scalars['String']>
  usage_ends_with?: Maybe<Scalars['String']>
  usage_not_ends_with?: Maybe<Scalars['String']>
  usage_in?: Maybe<Array<Maybe<Scalars['String']>>>
  usage_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  uploaded?: Maybe<Scalars['DateTime']>
  uploaded_not?: Maybe<Scalars['DateTime']>
  uploaded_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  uploaded_not_in?: Maybe<Array<Maybe<Scalars['DateTime']>>>
  uploaded_gt?: Maybe<Scalars['DateTime']>
  uploaded_not_gt?: Maybe<Scalars['DateTime']>
  uploaded_gte?: Maybe<Scalars['DateTime']>
  uploaded_not_gte?: Maybe<Scalars['DateTime']>
  uploaded_lt?: Maybe<Scalars['DateTime']>
  uploaded_not_lt?: Maybe<Scalars['DateTime']>
  uploaded_lte?: Maybe<Scalars['DateTime']>
  uploaded_not_lte?: Maybe<Scalars['DateTime']>
  uploadBy?: Maybe<Scalars['String']>
  uploadBy_not?: Maybe<Scalars['String']>
  uploadBy_contains?: Maybe<Scalars['String']>
  uploadBy_not_contains?: Maybe<Scalars['String']>
  uploadBy_starts_with?: Maybe<Scalars['String']>
  uploadBy_not_starts_with?: Maybe<Scalars['String']>
  uploadBy_ends_with?: Maybe<Scalars['String']>
  uploadBy_not_ends_with?: Maybe<Scalars['String']>
  uploadBy_in?: Maybe<Array<Maybe<Scalars['String']>>>
  uploadBy_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProductDocumentFilterInput = {
  and?: Maybe<Array<ProductDocumentFilterInput>>
  or?: Maybe<Array<ProductDocumentFilterInput>>
  id?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  product?: Maybe<ProductFilterInput>
  name?: Maybe<StringOperationFilterInput>
  type?: Maybe<StringOperationFilterInput>
  usage?: Maybe<StringOperationFilterInput>
  uploaded?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>
  uploadBy?: Maybe<StringOperationFilterInput>
}

export type ProductDocumentInput = {
  id?: Maybe<Scalars['Int']>
  product?: Maybe<ProductInput>
  name: Scalars['String']
  type?: Maybe<Scalars['String']>
  usage?: Maybe<Scalars['String']>
  uploaded?: Maybe<Scalars['DateTime']>
  uploadBy?: Maybe<Scalars['String']>
}

export type ProductDocumentUpdateInput = {
  product?: Maybe<ProductUpdateInput>
  name?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  usage?: Maybe<Scalars['String']>
  uploaded?: Maybe<Scalars['DateTime']>
  uploadBy?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
}

export type ProductEdge = {
  __typename?: 'ProductEdge'
  cursor: Scalars['String']
  node?: Maybe<Product>
}

export type ProductFilter = {
  AND?: Maybe<Array<ProductFilter>>
  OR?: Maybe<Array<ProductFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwnerFilter>
  code?: Maybe<Scalars['String']>
  code_not?: Maybe<Scalars['String']>
  code_contains?: Maybe<Scalars['String']>
  code_not_contains?: Maybe<Scalars['String']>
  code_starts_with?: Maybe<Scalars['String']>
  code_not_starts_with?: Maybe<Scalars['String']>
  code_ends_with?: Maybe<Scalars['String']>
  code_not_ends_with?: Maybe<Scalars['String']>
  code_in?: Maybe<Array<Scalars['String']>>
  code_not_in?: Maybe<Array<Scalars['String']>>
  altCode?: Maybe<Scalars['String']>
  altCode_not?: Maybe<Scalars['String']>
  altCode_contains?: Maybe<Scalars['String']>
  altCode_not_contains?: Maybe<Scalars['String']>
  altCode_starts_with?: Maybe<Scalars['String']>
  altCode_not_starts_with?: Maybe<Scalars['String']>
  altCode_ends_with?: Maybe<Scalars['String']>
  altCode_not_ends_with?: Maybe<Scalars['String']>
  altCode_in?: Maybe<Array<Maybe<Scalars['String']>>>
  altCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  gTIN?: Maybe<Scalars['String']>
  gTIN_not?: Maybe<Scalars['String']>
  gTIN_contains?: Maybe<Scalars['String']>
  gTIN_not_contains?: Maybe<Scalars['String']>
  gTIN_starts_with?: Maybe<Scalars['String']>
  gTIN_not_starts_with?: Maybe<Scalars['String']>
  gTIN_ends_with?: Maybe<Scalars['String']>
  gTIN_not_ends_with?: Maybe<Scalars['String']>
  gTIN_in?: Maybe<Array<Maybe<Scalars['String']>>>
  gTIN_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  substitute?: Maybe<Scalars['String']>
  substitute_not?: Maybe<Scalars['String']>
  substitute_contains?: Maybe<Scalars['String']>
  substitute_not_contains?: Maybe<Scalars['String']>
  substitute_starts_with?: Maybe<Scalars['String']>
  substitute_not_starts_with?: Maybe<Scalars['String']>
  substitute_ends_with?: Maybe<Scalars['String']>
  substitute_not_ends_with?: Maybe<Scalars['String']>
  substitute_in?: Maybe<Array<Maybe<Scalars['String']>>>
  substitute_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  description?: Maybe<Scalars['String']>
  description_not?: Maybe<Scalars['String']>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  description_starts_with?: Maybe<Scalars['String']>
  description_not_starts_with?: Maybe<Scalars['String']>
  description_ends_with?: Maybe<Scalars['String']>
  description_not_ends_with?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Scalars['String']>>
  description_not_in?: Maybe<Array<Scalars['String']>>
  longDesc?: Maybe<Scalars['String']>
  longDesc_not?: Maybe<Scalars['String']>
  longDesc_contains?: Maybe<Scalars['String']>
  longDesc_not_contains?: Maybe<Scalars['String']>
  longDesc_starts_with?: Maybe<Scalars['String']>
  longDesc_not_starts_with?: Maybe<Scalars['String']>
  longDesc_ends_with?: Maybe<Scalars['String']>
  longDesc_not_ends_with?: Maybe<Scalars['String']>
  longDesc_in?: Maybe<Array<Maybe<Scalars['String']>>>
  longDesc_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  linkCode?: Maybe<Scalars['String']>
  linkCode_not?: Maybe<Scalars['String']>
  linkCode_contains?: Maybe<Scalars['String']>
  linkCode_not_contains?: Maybe<Scalars['String']>
  linkCode_starts_with?: Maybe<Scalars['String']>
  linkCode_not_starts_with?: Maybe<Scalars['String']>
  linkCode_ends_with?: Maybe<Scalars['String']>
  linkCode_not_ends_with?: Maybe<Scalars['String']>
  linkCode_in?: Maybe<Array<Maybe<Scalars['String']>>>
  linkCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  categories_some?: Maybe<ProductCategoryFilter>
  categories_none?: Maybe<ProductCategoryFilter>
  categories_all?: Maybe<ProductCategoryFilter>
  categories_any?: Maybe<Scalars['Boolean']>
  components_some?: Maybe<BomDefinitionFilter>
  components_none?: Maybe<BomDefinitionFilter>
  components_all?: Maybe<BomDefinitionFilter>
  components_any?: Maybe<Scalars['Boolean']>
  suppliers_some?: Maybe<ProductSupplierFilter>
  suppliers_none?: Maybe<ProductSupplierFilter>
  suppliers_all?: Maybe<ProductSupplierFilter>
  suppliers_any?: Maybe<Scalars['Boolean']>
  licencePlates_some?: Maybe<LpDefinitionFilter>
  licencePlates_none?: Maybe<LpDefinitionFilter>
  licencePlates_all?: Maybe<LpDefinitionFilter>
  licencePlates_any?: Maybe<Scalars['Boolean']>
  barcodes_some?: Maybe<ProductBarcodeFilter>
  barcodes_none?: Maybe<ProductBarcodeFilter>
  barcodes_all?: Maybe<ProductBarcodeFilter>
  barcodes_any?: Maybe<Scalars['Boolean']>
  documents_some?: Maybe<ProductDocumentFilter>
  documents_none?: Maybe<ProductDocumentFilter>
  documents_all?: Maybe<ProductDocumentFilter>
  documents_any?: Maybe<Scalars['Boolean']>
  require?: Maybe<ProductRequiredDataFilter>
  SKUType?: Maybe<BomType>
  SKUType_not?: Maybe<BomType>
  SKUType_in?: Maybe<Array<Maybe<BomType>>>
  SKUType_not_in?: Maybe<Array<Maybe<BomType>>>
  SKUType_gt?: Maybe<BomType>
  SKUType_not_gt?: Maybe<BomType>
  SKUType_gte?: Maybe<BomType>
  SKUType_not_gte?: Maybe<BomType>
  SKUType_lt?: Maybe<BomType>
  SKUType_not_lt?: Maybe<BomType>
  SKUType_lte?: Maybe<BomType>
  SKUType_not_lte?: Maybe<BomType>
  INCICode?: Maybe<Scalars['String']>
  INCICode_not?: Maybe<Scalars['String']>
  INCICode_contains?: Maybe<Scalars['String']>
  INCICode_not_contains?: Maybe<Scalars['String']>
  INCICode_starts_with?: Maybe<Scalars['String']>
  INCICode_not_starts_with?: Maybe<Scalars['String']>
  INCICode_ends_with?: Maybe<Scalars['String']>
  INCICode_not_ends_with?: Maybe<Scalars['String']>
  INCICode_in?: Maybe<Array<Maybe<Scalars['String']>>>
  INCICode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  rotation?: Maybe<RotationRule>
  rotation_not?: Maybe<RotationRule>
  rotation_in?: Maybe<Array<Maybe<RotationRule>>>
  rotation_not_in?: Maybe<Array<Maybe<RotationRule>>>
  rotation_gt?: Maybe<RotationRule>
  rotation_not_gt?: Maybe<RotationRule>
  rotation_gte?: Maybe<RotationRule>
  rotation_not_gte?: Maybe<RotationRule>
  rotation_lt?: Maybe<RotationRule>
  rotation_not_lt?: Maybe<RotationRule>
  rotation_lte?: Maybe<RotationRule>
  rotation_not_lte?: Maybe<RotationRule>
  type?: Maybe<Scalars['String']>
  type_not?: Maybe<Scalars['String']>
  type_contains?: Maybe<Scalars['String']>
  type_not_contains?: Maybe<Scalars['String']>
  type_starts_with?: Maybe<Scalars['String']>
  type_not_starts_with?: Maybe<Scalars['String']>
  type_ends_with?: Maybe<Scalars['String']>
  type_not_ends_with?: Maybe<Scalars['String']>
  type_in?: Maybe<Array<Maybe<Scalars['String']>>>
  type_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  StorageType?: Maybe<Scalars['String']>
  StorageType_not?: Maybe<Scalars['String']>
  StorageType_contains?: Maybe<Scalars['String']>
  StorageType_not_contains?: Maybe<Scalars['String']>
  StorageType_starts_with?: Maybe<Scalars['String']>
  StorageType_not_starts_with?: Maybe<Scalars['String']>
  StorageType_ends_with?: Maybe<Scalars['String']>
  StorageType_not_ends_with?: Maybe<Scalars['String']>
  StorageType_in?: Maybe<Array<Maybe<Scalars['String']>>>
  StorageType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  facilityCat?: Maybe<Scalars['String']>
  facilityCat_not?: Maybe<Scalars['String']>
  facilityCat_contains?: Maybe<Scalars['String']>
  facilityCat_not_contains?: Maybe<Scalars['String']>
  facilityCat_starts_with?: Maybe<Scalars['String']>
  facilityCat_not_starts_with?: Maybe<Scalars['String']>
  facilityCat_ends_with?: Maybe<Scalars['String']>
  facilityCat_not_ends_with?: Maybe<Scalars['String']>
  facilityCat_in?: Maybe<Array<Maybe<Scalars['String']>>>
  facilityCat_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defBudhol?: Maybe<Scalars['String']>
  defBudhol_not?: Maybe<Scalars['String']>
  defBudhol_contains?: Maybe<Scalars['String']>
  defBudhol_not_contains?: Maybe<Scalars['String']>
  defBudhol_starts_with?: Maybe<Scalars['String']>
  defBudhol_not_starts_with?: Maybe<Scalars['String']>
  defBudhol_ends_with?: Maybe<Scalars['String']>
  defBudhol_not_ends_with?: Maybe<Scalars['String']>
  defBudhol_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defBudhol_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defWH?: Maybe<Scalars['String']>
  defWH_not?: Maybe<Scalars['String']>
  defWH_contains?: Maybe<Scalars['String']>
  defWH_not_contains?: Maybe<Scalars['String']>
  defWH_starts_with?: Maybe<Scalars['String']>
  defWH_not_starts_with?: Maybe<Scalars['String']>
  defWH_ends_with?: Maybe<Scalars['String']>
  defWH_not_ends_with?: Maybe<Scalars['String']>
  defWH_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defWH_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defHandType?: Maybe<Scalars['String']>
  defHandType_not?: Maybe<Scalars['String']>
  defHandType_contains?: Maybe<Scalars['String']>
  defHandType_not_contains?: Maybe<Scalars['String']>
  defHandType_starts_with?: Maybe<Scalars['String']>
  defHandType_not_starts_with?: Maybe<Scalars['String']>
  defHandType_ends_with?: Maybe<Scalars['String']>
  defHandType_not_ends_with?: Maybe<Scalars['String']>
  defHandType_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defHandType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defPackType?: Maybe<Scalars['String']>
  defPackType_not?: Maybe<Scalars['String']>
  defPackType_contains?: Maybe<Scalars['String']>
  defPackType_not_contains?: Maybe<Scalars['String']>
  defPackType_starts_with?: Maybe<Scalars['String']>
  defPackType_not_starts_with?: Maybe<Scalars['String']>
  defPackType_ends_with?: Maybe<Scalars['String']>
  defPackType_not_ends_with?: Maybe<Scalars['String']>
  defPackType_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defPackType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  depCode?: Maybe<Scalars['String']>
  depCode_not?: Maybe<Scalars['String']>
  depCode_contains?: Maybe<Scalars['String']>
  depCode_not_contains?: Maybe<Scalars['String']>
  depCode_starts_with?: Maybe<Scalars['String']>
  depCode_not_starts_with?: Maybe<Scalars['String']>
  depCode_ends_with?: Maybe<Scalars['String']>
  depCode_not_ends_with?: Maybe<Scalars['String']>
  depCode_in?: Maybe<Array<Maybe<Scalars['String']>>>
  depCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  rank?: Maybe<Scalars['String']>
  rank_not?: Maybe<Scalars['String']>
  rank_contains?: Maybe<Scalars['String']>
  rank_not_contains?: Maybe<Scalars['String']>
  rank_starts_with?: Maybe<Scalars['String']>
  rank_not_starts_with?: Maybe<Scalars['String']>
  rank_ends_with?: Maybe<Scalars['String']>
  rank_not_ends_with?: Maybe<Scalars['String']>
  rank_in?: Maybe<Array<Maybe<Scalars['String']>>>
  rank_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  uNCode?: Maybe<Scalars['String']>
  uNCode_not?: Maybe<Scalars['String']>
  uNCode_contains?: Maybe<Scalars['String']>
  uNCode_not_contains?: Maybe<Scalars['String']>
  uNCode_starts_with?: Maybe<Scalars['String']>
  uNCode_not_starts_with?: Maybe<Scalars['String']>
  uNCode_ends_with?: Maybe<Scalars['String']>
  uNCode_not_ends_with?: Maybe<Scalars['String']>
  uNCode_in?: Maybe<Array<Maybe<Scalars['String']>>>
  uNCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  bandQty?: Maybe<Scalars['Int']>
  bandQty_not?: Maybe<Scalars['Int']>
  bandQty_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  bandQty_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  bandQty_gt?: Maybe<Scalars['Int']>
  bandQty_not_gt?: Maybe<Scalars['Int']>
  bandQty_gte?: Maybe<Scalars['Int']>
  bandQty_not_gte?: Maybe<Scalars['Int']>
  bandQty_lt?: Maybe<Scalars['Int']>
  bandQty_not_lt?: Maybe<Scalars['Int']>
  bandQty_lte?: Maybe<Scalars['Int']>
  bandQty_not_lte?: Maybe<Scalars['Int']>
  manufacturerCode?: Maybe<Scalars['String']>
  manufacturerCode_not?: Maybe<Scalars['String']>
  manufacturerCode_contains?: Maybe<Scalars['String']>
  manufacturerCode_not_contains?: Maybe<Scalars['String']>
  manufacturerCode_starts_with?: Maybe<Scalars['String']>
  manufacturerCode_not_starts_with?: Maybe<Scalars['String']>
  manufacturerCode_ends_with?: Maybe<Scalars['String']>
  manufacturerCode_not_ends_with?: Maybe<Scalars['String']>
  manufacturerCode_in?: Maybe<Array<Maybe<Scalars['String']>>>
  manufacturerCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  lifeCycleStatus?: Maybe<Scalars['String']>
  lifeCycleStatus_not?: Maybe<Scalars['String']>
  lifeCycleStatus_contains?: Maybe<Scalars['String']>
  lifeCycleStatus_not_contains?: Maybe<Scalars['String']>
  lifeCycleStatus_starts_with?: Maybe<Scalars['String']>
  lifeCycleStatus_not_starts_with?: Maybe<Scalars['String']>
  lifeCycleStatus_ends_with?: Maybe<Scalars['String']>
  lifeCycleStatus_not_ends_with?: Maybe<Scalars['String']>
  lifeCycleStatus_in?: Maybe<Array<Maybe<Scalars['String']>>>
  lifeCycleStatus_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  packValidateBatch?: Maybe<Scalars['Boolean']>
  packValidateBatch_not?: Maybe<Scalars['Boolean']>
  capturePackType?: Maybe<Scalars['Boolean']>
  capturePackType_not?: Maybe<Scalars['Boolean']>
  pickPartPalletsAsFull?: Maybe<Scalars['Boolean']>
  pickPartPalletsAsFull_not?: Maybe<Scalars['Boolean']>
  pickSingles?: Maybe<Scalars['Boolean']>
  pickSingles_not?: Maybe<Scalars['Boolean']>
  pickDefId?: Maybe<Scalars['Int']>
  pickDefId_not?: Maybe<Scalars['Int']>
  pickDefId_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  pickDefId_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  pickDefId_gt?: Maybe<Scalars['Int']>
  pickDefId_not_gt?: Maybe<Scalars['Int']>
  pickDefId_gte?: Maybe<Scalars['Int']>
  pickDefId_not_gte?: Maybe<Scalars['Int']>
  pickDefId_lt?: Maybe<Scalars['Int']>
  pickDefId_not_lt?: Maybe<Scalars['Int']>
  pickDefId_lte?: Maybe<Scalars['Int']>
  pickDefId_not_lte?: Maybe<Scalars['Int']>
  defPickDefinition?: Maybe<PickMethodFilter>
  pickfaces_some?: Maybe<ProductPickfaceFilter>
  pickfaces_none?: Maybe<ProductPickfaceFilter>
  pickfaces_all?: Maybe<ProductPickfaceFilter>
  pickfaces_any?: Maybe<Scalars['Boolean']>
  defGRStatus?: Maybe<Scalars['String']>
  defGRStatus_not?: Maybe<Scalars['String']>
  defGRStatus_contains?: Maybe<Scalars['String']>
  defGRStatus_not_contains?: Maybe<Scalars['String']>
  defGRStatus_starts_with?: Maybe<Scalars['String']>
  defGRStatus_not_starts_with?: Maybe<Scalars['String']>
  defGRStatus_ends_with?: Maybe<Scalars['String']>
  defGRStatus_not_ends_with?: Maybe<Scalars['String']>
  defGRStatus_in?: Maybe<Array<Scalars['String']>>
  defGRStatus_not_in?: Maybe<Array<Scalars['String']>>
  minRShlfLife?: Maybe<Scalars['Int']>
  minRShlfLife_not?: Maybe<Scalars['Int']>
  minRShlfLife_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  minRShlfLife_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  minRShlfLife_gt?: Maybe<Scalars['Int']>
  minRShlfLife_not_gt?: Maybe<Scalars['Int']>
  minRShlfLife_gte?: Maybe<Scalars['Int']>
  minRShlfLife_not_gte?: Maybe<Scalars['Int']>
  minRShlfLife_lt?: Maybe<Scalars['Int']>
  minRShlfLife_not_lt?: Maybe<Scalars['Int']>
  minRShlfLife_lte?: Maybe<Scalars['Int']>
  minRShlfLife_not_lte?: Maybe<Scalars['Int']>
  minPShlfLife?: Maybe<Scalars['Int']>
  minPShlfLife_not?: Maybe<Scalars['Int']>
  minPShlfLife_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  minPShlfLife_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  minPShlfLife_gt?: Maybe<Scalars['Int']>
  minPShlfLife_not_gt?: Maybe<Scalars['Int']>
  minPShlfLife_gte?: Maybe<Scalars['Int']>
  minPShlfLife_not_gte?: Maybe<Scalars['Int']>
  minPShlfLife_lt?: Maybe<Scalars['Int']>
  minPShlfLife_not_lt?: Maybe<Scalars['Int']>
  minPShlfLife_lte?: Maybe<Scalars['Int']>
  minPShlfLife_not_lte?: Maybe<Scalars['Int']>
  totShlfLife?: Maybe<Scalars['Int']>
  totShlfLife_not?: Maybe<Scalars['Int']>
  totShlfLife_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  totShlfLife_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  totShlfLife_gt?: Maybe<Scalars['Int']>
  totShlfLife_not_gt?: Maybe<Scalars['Int']>
  totShlfLife_gte?: Maybe<Scalars['Int']>
  totShlfLife_not_gte?: Maybe<Scalars['Int']>
  totShlfLife_lt?: Maybe<Scalars['Int']>
  totShlfLife_not_lt?: Maybe<Scalars['Int']>
  totShlfLife_lte?: Maybe<Scalars['Int']>
  totShlfLife_not_lte?: Maybe<Scalars['Int']>
}

export type ProductFilterInput = {
  and?: Maybe<Array<ProductFilterInput>>
  or?: Maybe<Array<ProductFilterInput>>
  id?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  stockOwner?: Maybe<StockOwnerFilterInput>
  code?: Maybe<StringOperationFilterInput>
  altCode?: Maybe<StringOperationFilterInput>
  gTIN?: Maybe<StringOperationFilterInput>
  substitute?: Maybe<StringOperationFilterInput>
  description?: Maybe<StringOperationFilterInput>
  longDesc?: Maybe<StringOperationFilterInput>
  linkCode?: Maybe<StringOperationFilterInput>
  categories?: Maybe<ListFilterInputTypeOfProductCategoryFilterInput>
  components?: Maybe<ListFilterInputTypeOfBomDefinitionFilterInput>
  suppliers?: Maybe<ListFilterInputTypeOfProductSupplierFilterInput>
  licencePlates?: Maybe<ListFilterInputTypeOfLpDefinitionFilterInput>
  barcodes?: Maybe<ListFilterInputTypeOfProductBarcodeFilterInput>
  documents?: Maybe<ListFilterInputTypeOfProductDocumentFilterInput>
  require?: Maybe<ProductRequiredDataFilterInput>
  SKUType?: Maybe<NullableOfBomTypeOperationFilterInput>
  INCICode?: Maybe<StringOperationFilterInput>
  rotation?: Maybe<NullableOfRotationRuleOperationFilterInput>
  type?: Maybe<StringOperationFilterInput>
  StorageType?: Maybe<StringOperationFilterInput>
  facilityCat?: Maybe<StringOperationFilterInput>
  defBudhol?: Maybe<StringOperationFilterInput>
  defWH?: Maybe<StringOperationFilterInput>
  defHandType?: Maybe<StringOperationFilterInput>
  defPackType?: Maybe<StringOperationFilterInput>
  depCode?: Maybe<StringOperationFilterInput>
  rank?: Maybe<StringOperationFilterInput>
  uNCode?: Maybe<StringOperationFilterInput>
  bandQty?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  manufacturerCode?: Maybe<StringOperationFilterInput>
  lifeCycleStatus?: Maybe<StringOperationFilterInput>
  packValidateBatch?: Maybe<BooleanOperationFilterInput>
  capturePackType?: Maybe<BooleanOperationFilterInput>
  pickPartPalletsAsFull?: Maybe<BooleanOperationFilterInput>
  pickSingles?: Maybe<BooleanOperationFilterInput>
  pickDefId?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  defPickDefinition?: Maybe<PickMethodFilterInput>
  pickfaces?: Maybe<ListFilterInputTypeOfProductPickfaceFilterInput>
  defGRStatus?: Maybe<StringOperationFilterInput>
  minRShlfLife?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  minPShlfLife?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  totShlfLife?: Maybe<ComparableNullableOfInt32OperationFilterInput>
}

export type ProductInput = {
  id?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwnerInput>
  code: Scalars['String']
  altCode?: Maybe<Scalars['String']>
  gTIN?: Maybe<Scalars['String']>
  substitute?: Maybe<Scalars['String']>
  description: Scalars['String']
  longDesc?: Maybe<Scalars['String']>
  linkCode?: Maybe<Scalars['String']>
  categories?: Maybe<Array<Maybe<ProductCategoryInput>>>
  components?: Maybe<Array<Maybe<BomDefinitionInput>>>
  suppliers?: Maybe<Array<Maybe<ProductSupplierInput>>>
  licencePlates?: Maybe<Array<Maybe<LpDefinitionInput>>>
  barcodes?: Maybe<Array<Maybe<ProductBarcodeInput>>>
  documents?: Maybe<Array<Maybe<ProductDocumentInput>>>
  require?: Maybe<ProductRequiredDataInput>
  SKUType?: Maybe<BomType>
  INCICode?: Maybe<Scalars['String']>
  rotation?: Maybe<RotationRule>
  type?: Maybe<Scalars['String']>
  StorageType?: Maybe<Scalars['String']>
  facilityCat?: Maybe<Scalars['String']>
  defBudhol?: Maybe<Scalars['String']>
  defWH?: Maybe<Scalars['String']>
  defHandType?: Maybe<Scalars['String']>
  defPackType?: Maybe<Scalars['String']>
  depCode?: Maybe<Scalars['String']>
  rank?: Maybe<Scalars['String']>
  uNCode?: Maybe<Scalars['String']>
  bandQty: Scalars['Int']
  manufacturerCode?: Maybe<Scalars['String']>
  lifeCycleStatus?: Maybe<Scalars['String']>
  packValidateBatch: Scalars['Boolean']
  capturePackType: Scalars['Boolean']
  pickPartPalletsAsFull: Scalars['Boolean']
  pickSingles: Scalars['Boolean']
  pickDefId?: Maybe<Scalars['Int']>
  defPickDefinition?: Maybe<PickMethodInput>
  pickfaces?: Maybe<Array<Maybe<ProductPickfaceInput>>>
  defGRStatus: Scalars['String']
  minRShlfLife?: Maybe<Scalars['Int']>
  minPShlfLife?: Maybe<Scalars['Int']>
  totShlfLife?: Maybe<Scalars['Int']>
}

export type ProductPickface = {
  __typename?: 'ProductPickface'
  id?: Maybe<Scalars['Int']>
  product?: Maybe<Product>
  warehouse: Scalars['String']
  budhol?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  pickZone?: Maybe<Scalars['String']>
  maxFaceQty?: Maybe<Scalars['Int']>
  replenTriggr?: Maybe<Scalars['Int']>
  replenMulti?: Maybe<Scalars['Int']>
}

export type ProductPickfaceFilter = {
  AND?: Maybe<Array<ProductPickfaceFilter>>
  OR?: Maybe<Array<ProductPickfaceFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  product?: Maybe<ProductFilter>
  warehouse?: Maybe<Scalars['String']>
  warehouse_not?: Maybe<Scalars['String']>
  warehouse_contains?: Maybe<Scalars['String']>
  warehouse_not_contains?: Maybe<Scalars['String']>
  warehouse_starts_with?: Maybe<Scalars['String']>
  warehouse_not_starts_with?: Maybe<Scalars['String']>
  warehouse_ends_with?: Maybe<Scalars['String']>
  warehouse_not_ends_with?: Maybe<Scalars['String']>
  warehouse_in?: Maybe<Array<Scalars['String']>>
  warehouse_not_in?: Maybe<Array<Scalars['String']>>
  budhol?: Maybe<Scalars['String']>
  budhol_not?: Maybe<Scalars['String']>
  budhol_contains?: Maybe<Scalars['String']>
  budhol_not_contains?: Maybe<Scalars['String']>
  budhol_starts_with?: Maybe<Scalars['String']>
  budhol_not_starts_with?: Maybe<Scalars['String']>
  budhol_ends_with?: Maybe<Scalars['String']>
  budhol_not_ends_with?: Maybe<Scalars['String']>
  budhol_in?: Maybe<Array<Maybe<Scalars['String']>>>
  budhol_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  location?: Maybe<Scalars['String']>
  location_not?: Maybe<Scalars['String']>
  location_contains?: Maybe<Scalars['String']>
  location_not_contains?: Maybe<Scalars['String']>
  location_starts_with?: Maybe<Scalars['String']>
  location_not_starts_with?: Maybe<Scalars['String']>
  location_ends_with?: Maybe<Scalars['String']>
  location_not_ends_with?: Maybe<Scalars['String']>
  location_in?: Maybe<Array<Maybe<Scalars['String']>>>
  location_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  pickZone?: Maybe<Scalars['String']>
  pickZone_not?: Maybe<Scalars['String']>
  pickZone_contains?: Maybe<Scalars['String']>
  pickZone_not_contains?: Maybe<Scalars['String']>
  pickZone_starts_with?: Maybe<Scalars['String']>
  pickZone_not_starts_with?: Maybe<Scalars['String']>
  pickZone_ends_with?: Maybe<Scalars['String']>
  pickZone_not_ends_with?: Maybe<Scalars['String']>
  pickZone_in?: Maybe<Array<Maybe<Scalars['String']>>>
  pickZone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  maxFaceQty?: Maybe<Scalars['Int']>
  maxFaceQty_not?: Maybe<Scalars['Int']>
  maxFaceQty_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  maxFaceQty_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  maxFaceQty_gt?: Maybe<Scalars['Int']>
  maxFaceQty_not_gt?: Maybe<Scalars['Int']>
  maxFaceQty_gte?: Maybe<Scalars['Int']>
  maxFaceQty_not_gte?: Maybe<Scalars['Int']>
  maxFaceQty_lt?: Maybe<Scalars['Int']>
  maxFaceQty_not_lt?: Maybe<Scalars['Int']>
  maxFaceQty_lte?: Maybe<Scalars['Int']>
  maxFaceQty_not_lte?: Maybe<Scalars['Int']>
  replenTriggr?: Maybe<Scalars['Int']>
  replenTriggr_not?: Maybe<Scalars['Int']>
  replenTriggr_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  replenTriggr_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  replenTriggr_gt?: Maybe<Scalars['Int']>
  replenTriggr_not_gt?: Maybe<Scalars['Int']>
  replenTriggr_gte?: Maybe<Scalars['Int']>
  replenTriggr_not_gte?: Maybe<Scalars['Int']>
  replenTriggr_lt?: Maybe<Scalars['Int']>
  replenTriggr_not_lt?: Maybe<Scalars['Int']>
  replenTriggr_lte?: Maybe<Scalars['Int']>
  replenTriggr_not_lte?: Maybe<Scalars['Int']>
  replenMulti?: Maybe<Scalars['Int']>
  replenMulti_not?: Maybe<Scalars['Int']>
  replenMulti_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  replenMulti_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  replenMulti_gt?: Maybe<Scalars['Int']>
  replenMulti_not_gt?: Maybe<Scalars['Int']>
  replenMulti_gte?: Maybe<Scalars['Int']>
  replenMulti_not_gte?: Maybe<Scalars['Int']>
  replenMulti_lt?: Maybe<Scalars['Int']>
  replenMulti_not_lt?: Maybe<Scalars['Int']>
  replenMulti_lte?: Maybe<Scalars['Int']>
  replenMulti_not_lte?: Maybe<Scalars['Int']>
}

export type ProductPickfaceFilterInput = {
  and?: Maybe<Array<ProductPickfaceFilterInput>>
  or?: Maybe<Array<ProductPickfaceFilterInput>>
  id?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  product?: Maybe<ProductFilterInput>
  warehouse?: Maybe<StringOperationFilterInput>
  budhol?: Maybe<StringOperationFilterInput>
  location?: Maybe<StringOperationFilterInput>
  pickZone?: Maybe<StringOperationFilterInput>
  maxFaceQty?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  replenTriggr?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  replenMulti?: Maybe<ComparableNullableOfInt32OperationFilterInput>
}

export type ProductPickfaceInput = {
  id?: Maybe<Scalars['Int']>
  product?: Maybe<ProductInput>
  warehouse: Scalars['String']
  budhol?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  pickZone?: Maybe<Scalars['String']>
  maxFaceQty: Scalars['Int']
  replenTriggr: Scalars['Int']
  replenMulti?: Maybe<Scalars['Int']>
}

export type ProductPickfaceUpdateInput = {
  product?: Maybe<ProductUpdateInput>
  warehouse?: Maybe<Scalars['String']>
  budhol?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  pickZone?: Maybe<Scalars['String']>
  maxFaceQty?: Maybe<Scalars['Int']>
  replenTriggr?: Maybe<Scalars['Int']>
  replenMulti?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
}

export enum ProductProcessStatus {
  GoodStock = 'GOOD_STOCK',
  Qc = 'QC',
  Quarantined = 'QUARANTINED',
  PickFailed = 'PICK_FAILED',
  Damaged = 'DAMAGED',
  Expired = 'EXPIRED',
  UserDefined = 'USER_DEFINED'
}

export type ProductRequiredData = {
  __typename?: 'ProductRequiredData'
  expiry?: Maybe<FieldVisibility>
  bestBefore?: Maybe<FieldVisibility>
  batch?: Maybe<FieldVisibility>
  status?: Maybe<FieldVisibility>
  packType?: Maybe<FieldVisibility>
  handlingType?: Maybe<FieldVisibility>
  serial?: Maybe<FieldVisibility>
  cartonLP?: Maybe<FieldVisibility>
  palletLP?: Maybe<FieldVisibility>
  arrivalTemp?: Maybe<FieldVisibility>
  originCntry?: Maybe<FieldVisibility>
  batchInfo?: Maybe<FieldVisibility>
  productionDte?: Maybe<FieldVisibility>
  killDte?: Maybe<FieldVisibility>
  slaughterCode?: Maybe<FieldVisibility>
  budgetHolder?: Maybe<FieldVisibility>
  serials?: Maybe<FieldVisibility>
  weight?: Maybe<CollectWeight>
}

export type ProductRequiredDataFilter = {
  AND?: Maybe<Array<ProductRequiredDataFilter>>
  OR?: Maybe<Array<ProductRequiredDataFilter>>
  expiry?: Maybe<FieldVisibility>
  expiry_not?: Maybe<FieldVisibility>
  expiry_in?: Maybe<Array<Maybe<FieldVisibility>>>
  expiry_not_in?: Maybe<Array<Maybe<FieldVisibility>>>
  expiry_gt?: Maybe<FieldVisibility>
  expiry_not_gt?: Maybe<FieldVisibility>
  expiry_gte?: Maybe<FieldVisibility>
  expiry_not_gte?: Maybe<FieldVisibility>
  expiry_lt?: Maybe<FieldVisibility>
  expiry_not_lt?: Maybe<FieldVisibility>
  expiry_lte?: Maybe<FieldVisibility>
  expiry_not_lte?: Maybe<FieldVisibility>
  bestBefore?: Maybe<FieldVisibility>
  bestBefore_not?: Maybe<FieldVisibility>
  bestBefore_in?: Maybe<Array<Maybe<FieldVisibility>>>
  bestBefore_not_in?: Maybe<Array<Maybe<FieldVisibility>>>
  bestBefore_gt?: Maybe<FieldVisibility>
  bestBefore_not_gt?: Maybe<FieldVisibility>
  bestBefore_gte?: Maybe<FieldVisibility>
  bestBefore_not_gte?: Maybe<FieldVisibility>
  bestBefore_lt?: Maybe<FieldVisibility>
  bestBefore_not_lt?: Maybe<FieldVisibility>
  bestBefore_lte?: Maybe<FieldVisibility>
  bestBefore_not_lte?: Maybe<FieldVisibility>
  batch?: Maybe<FieldVisibility>
  batch_not?: Maybe<FieldVisibility>
  batch_in?: Maybe<Array<Maybe<FieldVisibility>>>
  batch_not_in?: Maybe<Array<Maybe<FieldVisibility>>>
  batch_gt?: Maybe<FieldVisibility>
  batch_not_gt?: Maybe<FieldVisibility>
  batch_gte?: Maybe<FieldVisibility>
  batch_not_gte?: Maybe<FieldVisibility>
  batch_lt?: Maybe<FieldVisibility>
  batch_not_lt?: Maybe<FieldVisibility>
  batch_lte?: Maybe<FieldVisibility>
  batch_not_lte?: Maybe<FieldVisibility>
  status?: Maybe<FieldVisibility>
  status_not?: Maybe<FieldVisibility>
  status_in?: Maybe<Array<Maybe<FieldVisibility>>>
  status_not_in?: Maybe<Array<Maybe<FieldVisibility>>>
  status_gt?: Maybe<FieldVisibility>
  status_not_gt?: Maybe<FieldVisibility>
  status_gte?: Maybe<FieldVisibility>
  status_not_gte?: Maybe<FieldVisibility>
  status_lt?: Maybe<FieldVisibility>
  status_not_lt?: Maybe<FieldVisibility>
  status_lte?: Maybe<FieldVisibility>
  status_not_lte?: Maybe<FieldVisibility>
  packType?: Maybe<FieldVisibility>
  packType_not?: Maybe<FieldVisibility>
  packType_in?: Maybe<Array<Maybe<FieldVisibility>>>
  packType_not_in?: Maybe<Array<Maybe<FieldVisibility>>>
  packType_gt?: Maybe<FieldVisibility>
  packType_not_gt?: Maybe<FieldVisibility>
  packType_gte?: Maybe<FieldVisibility>
  packType_not_gte?: Maybe<FieldVisibility>
  packType_lt?: Maybe<FieldVisibility>
  packType_not_lt?: Maybe<FieldVisibility>
  packType_lte?: Maybe<FieldVisibility>
  packType_not_lte?: Maybe<FieldVisibility>
  handlingType?: Maybe<FieldVisibility>
  handlingType_not?: Maybe<FieldVisibility>
  handlingType_in?: Maybe<Array<Maybe<FieldVisibility>>>
  handlingType_not_in?: Maybe<Array<Maybe<FieldVisibility>>>
  handlingType_gt?: Maybe<FieldVisibility>
  handlingType_not_gt?: Maybe<FieldVisibility>
  handlingType_gte?: Maybe<FieldVisibility>
  handlingType_not_gte?: Maybe<FieldVisibility>
  handlingType_lt?: Maybe<FieldVisibility>
  handlingType_not_lt?: Maybe<FieldVisibility>
  handlingType_lte?: Maybe<FieldVisibility>
  handlingType_not_lte?: Maybe<FieldVisibility>
  serial?: Maybe<FieldVisibility>
  serial_not?: Maybe<FieldVisibility>
  serial_in?: Maybe<Array<Maybe<FieldVisibility>>>
  serial_not_in?: Maybe<Array<Maybe<FieldVisibility>>>
  serial_gt?: Maybe<FieldVisibility>
  serial_not_gt?: Maybe<FieldVisibility>
  serial_gte?: Maybe<FieldVisibility>
  serial_not_gte?: Maybe<FieldVisibility>
  serial_lt?: Maybe<FieldVisibility>
  serial_not_lt?: Maybe<FieldVisibility>
  serial_lte?: Maybe<FieldVisibility>
  serial_not_lte?: Maybe<FieldVisibility>
  cartonLP?: Maybe<FieldVisibility>
  cartonLP_not?: Maybe<FieldVisibility>
  cartonLP_in?: Maybe<Array<Maybe<FieldVisibility>>>
  cartonLP_not_in?: Maybe<Array<Maybe<FieldVisibility>>>
  cartonLP_gt?: Maybe<FieldVisibility>
  cartonLP_not_gt?: Maybe<FieldVisibility>
  cartonLP_gte?: Maybe<FieldVisibility>
  cartonLP_not_gte?: Maybe<FieldVisibility>
  cartonLP_lt?: Maybe<FieldVisibility>
  cartonLP_not_lt?: Maybe<FieldVisibility>
  cartonLP_lte?: Maybe<FieldVisibility>
  cartonLP_not_lte?: Maybe<FieldVisibility>
  palletLP?: Maybe<FieldVisibility>
  palletLP_not?: Maybe<FieldVisibility>
  palletLP_in?: Maybe<Array<Maybe<FieldVisibility>>>
  palletLP_not_in?: Maybe<Array<Maybe<FieldVisibility>>>
  palletLP_gt?: Maybe<FieldVisibility>
  palletLP_not_gt?: Maybe<FieldVisibility>
  palletLP_gte?: Maybe<FieldVisibility>
  palletLP_not_gte?: Maybe<FieldVisibility>
  palletLP_lt?: Maybe<FieldVisibility>
  palletLP_not_lt?: Maybe<FieldVisibility>
  palletLP_lte?: Maybe<FieldVisibility>
  palletLP_not_lte?: Maybe<FieldVisibility>
  arrivalTemp?: Maybe<FieldVisibility>
  arrivalTemp_not?: Maybe<FieldVisibility>
  arrivalTemp_in?: Maybe<Array<Maybe<FieldVisibility>>>
  arrivalTemp_not_in?: Maybe<Array<Maybe<FieldVisibility>>>
  arrivalTemp_gt?: Maybe<FieldVisibility>
  arrivalTemp_not_gt?: Maybe<FieldVisibility>
  arrivalTemp_gte?: Maybe<FieldVisibility>
  arrivalTemp_not_gte?: Maybe<FieldVisibility>
  arrivalTemp_lt?: Maybe<FieldVisibility>
  arrivalTemp_not_lt?: Maybe<FieldVisibility>
  arrivalTemp_lte?: Maybe<FieldVisibility>
  arrivalTemp_not_lte?: Maybe<FieldVisibility>
  originCntry?: Maybe<FieldVisibility>
  originCntry_not?: Maybe<FieldVisibility>
  originCntry_in?: Maybe<Array<Maybe<FieldVisibility>>>
  originCntry_not_in?: Maybe<Array<Maybe<FieldVisibility>>>
  originCntry_gt?: Maybe<FieldVisibility>
  originCntry_not_gt?: Maybe<FieldVisibility>
  originCntry_gte?: Maybe<FieldVisibility>
  originCntry_not_gte?: Maybe<FieldVisibility>
  originCntry_lt?: Maybe<FieldVisibility>
  originCntry_not_lt?: Maybe<FieldVisibility>
  originCntry_lte?: Maybe<FieldVisibility>
  originCntry_not_lte?: Maybe<FieldVisibility>
  batchInfo?: Maybe<FieldVisibility>
  batchInfo_not?: Maybe<FieldVisibility>
  batchInfo_in?: Maybe<Array<Maybe<FieldVisibility>>>
  batchInfo_not_in?: Maybe<Array<Maybe<FieldVisibility>>>
  batchInfo_gt?: Maybe<FieldVisibility>
  batchInfo_not_gt?: Maybe<FieldVisibility>
  batchInfo_gte?: Maybe<FieldVisibility>
  batchInfo_not_gte?: Maybe<FieldVisibility>
  batchInfo_lt?: Maybe<FieldVisibility>
  batchInfo_not_lt?: Maybe<FieldVisibility>
  batchInfo_lte?: Maybe<FieldVisibility>
  batchInfo_not_lte?: Maybe<FieldVisibility>
  productionDte?: Maybe<FieldVisibility>
  productionDte_not?: Maybe<FieldVisibility>
  productionDte_in?: Maybe<Array<Maybe<FieldVisibility>>>
  productionDte_not_in?: Maybe<Array<Maybe<FieldVisibility>>>
  productionDte_gt?: Maybe<FieldVisibility>
  productionDte_not_gt?: Maybe<FieldVisibility>
  productionDte_gte?: Maybe<FieldVisibility>
  productionDte_not_gte?: Maybe<FieldVisibility>
  productionDte_lt?: Maybe<FieldVisibility>
  productionDte_not_lt?: Maybe<FieldVisibility>
  productionDte_lte?: Maybe<FieldVisibility>
  productionDte_not_lte?: Maybe<FieldVisibility>
  killDte?: Maybe<FieldVisibility>
  killDte_not?: Maybe<FieldVisibility>
  killDte_in?: Maybe<Array<Maybe<FieldVisibility>>>
  killDte_not_in?: Maybe<Array<Maybe<FieldVisibility>>>
  killDte_gt?: Maybe<FieldVisibility>
  killDte_not_gt?: Maybe<FieldVisibility>
  killDte_gte?: Maybe<FieldVisibility>
  killDte_not_gte?: Maybe<FieldVisibility>
  killDte_lt?: Maybe<FieldVisibility>
  killDte_not_lt?: Maybe<FieldVisibility>
  killDte_lte?: Maybe<FieldVisibility>
  killDte_not_lte?: Maybe<FieldVisibility>
  slaughterCode?: Maybe<FieldVisibility>
  slaughterCode_not?: Maybe<FieldVisibility>
  slaughterCode_in?: Maybe<Array<Maybe<FieldVisibility>>>
  slaughterCode_not_in?: Maybe<Array<Maybe<FieldVisibility>>>
  slaughterCode_gt?: Maybe<FieldVisibility>
  slaughterCode_not_gt?: Maybe<FieldVisibility>
  slaughterCode_gte?: Maybe<FieldVisibility>
  slaughterCode_not_gte?: Maybe<FieldVisibility>
  slaughterCode_lt?: Maybe<FieldVisibility>
  slaughterCode_not_lt?: Maybe<FieldVisibility>
  slaughterCode_lte?: Maybe<FieldVisibility>
  slaughterCode_not_lte?: Maybe<FieldVisibility>
  budgetHolder?: Maybe<FieldVisibility>
  budgetHolder_not?: Maybe<FieldVisibility>
  budgetHolder_in?: Maybe<Array<Maybe<FieldVisibility>>>
  budgetHolder_not_in?: Maybe<Array<Maybe<FieldVisibility>>>
  budgetHolder_gt?: Maybe<FieldVisibility>
  budgetHolder_not_gt?: Maybe<FieldVisibility>
  budgetHolder_gte?: Maybe<FieldVisibility>
  budgetHolder_not_gte?: Maybe<FieldVisibility>
  budgetHolder_lt?: Maybe<FieldVisibility>
  budgetHolder_not_lt?: Maybe<FieldVisibility>
  budgetHolder_lte?: Maybe<FieldVisibility>
  budgetHolder_not_lte?: Maybe<FieldVisibility>
  serials?: Maybe<FieldVisibility>
  serials_not?: Maybe<FieldVisibility>
  serials_in?: Maybe<Array<Maybe<FieldVisibility>>>
  serials_not_in?: Maybe<Array<Maybe<FieldVisibility>>>
  serials_gt?: Maybe<FieldVisibility>
  serials_not_gt?: Maybe<FieldVisibility>
  serials_gte?: Maybe<FieldVisibility>
  serials_not_gte?: Maybe<FieldVisibility>
  serials_lt?: Maybe<FieldVisibility>
  serials_not_lt?: Maybe<FieldVisibility>
  serials_lte?: Maybe<FieldVisibility>
  serials_not_lte?: Maybe<FieldVisibility>
  weight?: Maybe<CollectWeight>
  weight_not?: Maybe<CollectWeight>
  weight_in?: Maybe<Array<Maybe<CollectWeight>>>
  weight_not_in?: Maybe<Array<Maybe<CollectWeight>>>
  weight_gt?: Maybe<CollectWeight>
  weight_not_gt?: Maybe<CollectWeight>
  weight_gte?: Maybe<CollectWeight>
  weight_not_gte?: Maybe<CollectWeight>
  weight_lt?: Maybe<CollectWeight>
  weight_not_lt?: Maybe<CollectWeight>
  weight_lte?: Maybe<CollectWeight>
  weight_not_lte?: Maybe<CollectWeight>
}

export type ProductRequiredDataFilterInput = {
  and?: Maybe<Array<ProductRequiredDataFilterInput>>
  or?: Maybe<Array<ProductRequiredDataFilterInput>>
  expiry?: Maybe<NullableOfFieldVisibilityOperationFilterInput>
  bestBefore?: Maybe<NullableOfFieldVisibilityOperationFilterInput>
  batch?: Maybe<NullableOfFieldVisibilityOperationFilterInput>
  status?: Maybe<NullableOfFieldVisibilityOperationFilterInput>
  packType?: Maybe<NullableOfFieldVisibilityOperationFilterInput>
  handlingType?: Maybe<NullableOfFieldVisibilityOperationFilterInput>
  serial?: Maybe<NullableOfFieldVisibilityOperationFilterInput>
  cartonLP?: Maybe<NullableOfFieldVisibilityOperationFilterInput>
  palletLP?: Maybe<NullableOfFieldVisibilityOperationFilterInput>
  arrivalTemp?: Maybe<NullableOfFieldVisibilityOperationFilterInput>
  originCntry?: Maybe<NullableOfFieldVisibilityOperationFilterInput>
  batchInfo?: Maybe<NullableOfFieldVisibilityOperationFilterInput>
  productionDte?: Maybe<NullableOfFieldVisibilityOperationFilterInput>
  killDte?: Maybe<NullableOfFieldVisibilityOperationFilterInput>
  slaughterCode?: Maybe<NullableOfFieldVisibilityOperationFilterInput>
  budgetHolder?: Maybe<NullableOfFieldVisibilityOperationFilterInput>
  serials?: Maybe<NullableOfFieldVisibilityOperationFilterInput>
  weight?: Maybe<NullableOfCollectWeightOperationFilterInput>
}

export type ProductRequiredDataInput = {
  expiry?: Maybe<FieldVisibility>
  bestBefore?: Maybe<FieldVisibility>
  batch?: Maybe<FieldVisibility>
  status?: Maybe<FieldVisibility>
  packType?: Maybe<FieldVisibility>
  handlingType?: Maybe<FieldVisibility>
  serial?: Maybe<FieldVisibility>
  cartonLP?: Maybe<FieldVisibility>
  palletLP?: Maybe<FieldVisibility>
  arrivalTemp?: Maybe<FieldVisibility>
  originCntry?: Maybe<FieldVisibility>
  batchInfo?: Maybe<FieldVisibility>
  productionDte?: Maybe<FieldVisibility>
  killDte?: Maybe<FieldVisibility>
  slaughterCode?: Maybe<FieldVisibility>
  budgetHolder?: Maybe<FieldVisibility>
  serials?: Maybe<FieldVisibility>
  weight?: Maybe<CollectWeight>
}

export type ProductRequiredDataSort = {
  expiry?: Maybe<SortOperationKind>
  bestBefore?: Maybe<SortOperationKind>
  batch?: Maybe<SortOperationKind>
  status?: Maybe<SortOperationKind>
  packType?: Maybe<SortOperationKind>
  handlingType?: Maybe<SortOperationKind>
  serial?: Maybe<SortOperationKind>
  cartonLP?: Maybe<SortOperationKind>
  palletLP?: Maybe<SortOperationKind>
  arrivalTemp?: Maybe<SortOperationKind>
  originCntry?: Maybe<SortOperationKind>
  batchInfo?: Maybe<SortOperationKind>
  productionDte?: Maybe<SortOperationKind>
  killDte?: Maybe<SortOperationKind>
  slaughterCode?: Maybe<SortOperationKind>
  budgetHolder?: Maybe<SortOperationKind>
  serials?: Maybe<SortOperationKind>
  weight?: Maybe<SortOperationKind>
}

export type ProductRequiredDataSortInput = {
  expiry?: Maybe<SortEnumType>
  bestBefore?: Maybe<SortEnumType>
  batch?: Maybe<SortEnumType>
  status?: Maybe<SortEnumType>
  packType?: Maybe<SortEnumType>
  handlingType?: Maybe<SortEnumType>
  serial?: Maybe<SortEnumType>
  cartonLP?: Maybe<SortEnumType>
  palletLP?: Maybe<SortEnumType>
  arrivalTemp?: Maybe<SortEnumType>
  originCntry?: Maybe<SortEnumType>
  batchInfo?: Maybe<SortEnumType>
  productionDte?: Maybe<SortEnumType>
  killDte?: Maybe<SortEnumType>
  slaughterCode?: Maybe<SortEnumType>
  budgetHolder?: Maybe<SortEnumType>
  serials?: Maybe<SortEnumType>
  weight?: Maybe<SortEnumType>
}

export type ProductRequiredDataUpdateInput = {
  expiry?: Maybe<FieldVisibility>
  bestBefore?: Maybe<FieldVisibility>
  batch?: Maybe<FieldVisibility>
  status?: Maybe<FieldVisibility>
  packType?: Maybe<FieldVisibility>
  handlingType?: Maybe<FieldVisibility>
  serial?: Maybe<FieldVisibility>
  cartonLP?: Maybe<FieldVisibility>
  palletLP?: Maybe<FieldVisibility>
  arrivalTemp?: Maybe<FieldVisibility>
  originCntry?: Maybe<FieldVisibility>
  batchInfo?: Maybe<FieldVisibility>
  productionDte?: Maybe<FieldVisibility>
  killDte?: Maybe<FieldVisibility>
  slaughterCode?: Maybe<FieldVisibility>
  budgetHolder?: Maybe<FieldVisibility>
  serials?: Maybe<FieldVisibility>
  weight?: Maybe<CollectWeight>
  id?: Maybe<Scalars['Int']>
}

export type ProductSort = {
  id?: Maybe<SortOperationKind>
  stockOwner?: Maybe<StockOwnerSort>
  code?: Maybe<SortOperationKind>
  altCode?: Maybe<SortOperationKind>
  gTIN?: Maybe<SortOperationKind>
  substitute?: Maybe<SortOperationKind>
  description?: Maybe<SortOperationKind>
  longDesc?: Maybe<SortOperationKind>
  linkCode?: Maybe<SortOperationKind>
  require?: Maybe<ProductRequiredDataSort>
  SKUType?: Maybe<SortOperationKind>
  INCICode?: Maybe<SortOperationKind>
  rotation?: Maybe<SortOperationKind>
  type?: Maybe<SortOperationKind>
  StorageType?: Maybe<SortOperationKind>
  facilityCat?: Maybe<SortOperationKind>
  defBudhol?: Maybe<SortOperationKind>
  defWH?: Maybe<SortOperationKind>
  defHandType?: Maybe<SortOperationKind>
  defPackType?: Maybe<SortOperationKind>
  depCode?: Maybe<SortOperationKind>
  rank?: Maybe<SortOperationKind>
  uNCode?: Maybe<SortOperationKind>
  bandQty?: Maybe<SortOperationKind>
  manufacturerCode?: Maybe<SortOperationKind>
  lifeCycleStatus?: Maybe<SortOperationKind>
  packValidateBatch?: Maybe<SortOperationKind>
  capturePackType?: Maybe<SortOperationKind>
  pickPartPalletsAsFull?: Maybe<SortOperationKind>
  pickSingles?: Maybe<SortOperationKind>
  pickDefId?: Maybe<SortOperationKind>
  defPickDefinition?: Maybe<PickMethodSort>
  defGRStatus?: Maybe<SortOperationKind>
  minRShlfLife?: Maybe<SortOperationKind>
  minPShlfLife?: Maybe<SortOperationKind>
  totShlfLife?: Maybe<SortOperationKind>
}

export type ProductSortInput = {
  id?: Maybe<SortEnumType>
  stockOwner?: Maybe<StockOwnerSortInput>
  code?: Maybe<SortEnumType>
  altCode?: Maybe<SortEnumType>
  gTIN?: Maybe<SortEnumType>
  substitute?: Maybe<SortEnumType>
  description?: Maybe<SortEnumType>
  longDesc?: Maybe<SortEnumType>
  linkCode?: Maybe<SortEnumType>
  require?: Maybe<ProductRequiredDataSortInput>
  SKUType?: Maybe<SortEnumType>
  INCICode?: Maybe<SortEnumType>
  rotation?: Maybe<SortEnumType>
  type?: Maybe<SortEnumType>
  StorageType?: Maybe<SortEnumType>
  facilityCat?: Maybe<SortEnumType>
  defBudhol?: Maybe<SortEnumType>
  defWH?: Maybe<SortEnumType>
  defHandType?: Maybe<SortEnumType>
  defPackType?: Maybe<SortEnumType>
  depCode?: Maybe<SortEnumType>
  rank?: Maybe<SortEnumType>
  uNCode?: Maybe<SortEnumType>
  bandQty?: Maybe<SortEnumType>
  manufacturerCode?: Maybe<SortEnumType>
  lifeCycleStatus?: Maybe<SortEnumType>
  packValidateBatch?: Maybe<SortEnumType>
  capturePackType?: Maybe<SortEnumType>
  pickPartPalletsAsFull?: Maybe<SortEnumType>
  pickSingles?: Maybe<SortEnumType>
  pickDefId?: Maybe<SortEnumType>
  defPickDefinition?: Maybe<PickMethodSortInput>
  defGRStatus?: Maybe<SortEnumType>
  minRShlfLife?: Maybe<SortEnumType>
  minPShlfLife?: Maybe<SortEnumType>
  totShlfLife?: Maybe<SortEnumType>
}

export type ProductStatus = {
  __typename?: 'ProductStatus'
  id?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwner>
  code: Scalars['String']
  description: Scalars['String']
  adjustStock: Scalars['Boolean']
  processStatus: ProductProcessStatus
  targetStatuses?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProductStatusFilter = {
  AND?: Maybe<Array<ProductStatusFilter>>
  OR?: Maybe<Array<ProductStatusFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwnerFilter>
  code?: Maybe<Scalars['String']>
  code_not?: Maybe<Scalars['String']>
  code_contains?: Maybe<Scalars['String']>
  code_not_contains?: Maybe<Scalars['String']>
  code_starts_with?: Maybe<Scalars['String']>
  code_not_starts_with?: Maybe<Scalars['String']>
  code_ends_with?: Maybe<Scalars['String']>
  code_not_ends_with?: Maybe<Scalars['String']>
  code_in?: Maybe<Array<Scalars['String']>>
  code_not_in?: Maybe<Array<Scalars['String']>>
  description?: Maybe<Scalars['String']>
  description_not?: Maybe<Scalars['String']>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  description_starts_with?: Maybe<Scalars['String']>
  description_not_starts_with?: Maybe<Scalars['String']>
  description_ends_with?: Maybe<Scalars['String']>
  description_not_ends_with?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Scalars['String']>>
  description_not_in?: Maybe<Array<Scalars['String']>>
  adjustStock?: Maybe<Scalars['Boolean']>
  adjustStock_not?: Maybe<Scalars['Boolean']>
  processStatus?: Maybe<ProductProcessStatus>
  processStatus_not?: Maybe<ProductProcessStatus>
  processStatus_in?: Maybe<Array<ProductProcessStatus>>
  processStatus_not_in?: Maybe<Array<ProductProcessStatus>>
  processStatus_gt?: Maybe<ProductProcessStatus>
  processStatus_not_gt?: Maybe<ProductProcessStatus>
  processStatus_gte?: Maybe<ProductProcessStatus>
  processStatus_not_gte?: Maybe<ProductProcessStatus>
  processStatus_lt?: Maybe<ProductProcessStatus>
  processStatus_not_lt?: Maybe<ProductProcessStatus>
  processStatus_lte?: Maybe<ProductProcessStatus>
  processStatus_not_lte?: Maybe<ProductProcessStatus>
  targetStatuses_some?: Maybe<ISingleFilterOfStringFilter>
  targetStatuses_none?: Maybe<ISingleFilterOfStringFilter>
  targetStatuses_all?: Maybe<ISingleFilterOfStringFilter>
  targetStatuses_any?: Maybe<Scalars['Boolean']>
}

export type ProductStatusFilterInput = {
  and?: Maybe<Array<ProductStatusFilterInput>>
  or?: Maybe<Array<ProductStatusFilterInput>>
  id?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  stockOwner?: Maybe<StockOwnerFilterInput>
  code?: Maybe<StringOperationFilterInput>
  description?: Maybe<StringOperationFilterInput>
  adjustStock?: Maybe<BooleanOperationFilterInput>
  processStatus?: Maybe<NullableOfProductProcessStatusOperationFilterInput>
  targetStatuses?: Maybe<ListStringOperationFilterInput>
}

export type ProductStatusInput = {
  id?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwnerInput>
  code: Scalars['String']
  description: Scalars['String']
  adjustStock: Scalars['Boolean']
  processStatus: ProductProcessStatus
  targetStatuses?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type ProductStatusUpdateInput = {
  stockOwnerId: Scalars['Int']
  stockOwner?: Maybe<StockOwnerUpdateInput>
  code?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  adjustStock?: Maybe<Scalars['Boolean']>
  processStatus?: Maybe<ProductProcessStatus>
  targetStatuses?: Maybe<Array<Maybe<Scalars['String']>>>
  id?: Maybe<Scalars['Int']>
}

export type ProductSupplier = {
  __typename?: 'ProductSupplier'
  id?: Maybe<Scalars['Int']>
  product?: Maybe<Product>
  supplierId: Scalars['Int']
  supplier?: Maybe<Supplier>
  warehouseId?: Maybe<Scalars['Int']>
  warehouse?: Maybe<Warehouse>
  active?: Maybe<Scalars['Boolean']>
}

export type ProductSupplierFilter = {
  AND?: Maybe<Array<ProductSupplierFilter>>
  OR?: Maybe<Array<ProductSupplierFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  product?: Maybe<ProductFilter>
  supplierId?: Maybe<Scalars['Int']>
  supplierId_not?: Maybe<Scalars['Int']>
  supplierId_in?: Maybe<Array<Scalars['Int']>>
  supplierId_not_in?: Maybe<Array<Scalars['Int']>>
  supplierId_gt?: Maybe<Scalars['Int']>
  supplierId_not_gt?: Maybe<Scalars['Int']>
  supplierId_gte?: Maybe<Scalars['Int']>
  supplierId_not_gte?: Maybe<Scalars['Int']>
  supplierId_lt?: Maybe<Scalars['Int']>
  supplierId_not_lt?: Maybe<Scalars['Int']>
  supplierId_lte?: Maybe<Scalars['Int']>
  supplierId_not_lte?: Maybe<Scalars['Int']>
  supplier?: Maybe<SupplierFilter>
  warehouseId?: Maybe<Scalars['Int']>
  warehouseId_not?: Maybe<Scalars['Int']>
  warehouseId_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  warehouseId_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  warehouseId_gt?: Maybe<Scalars['Int']>
  warehouseId_not_gt?: Maybe<Scalars['Int']>
  warehouseId_gte?: Maybe<Scalars['Int']>
  warehouseId_not_gte?: Maybe<Scalars['Int']>
  warehouseId_lt?: Maybe<Scalars['Int']>
  warehouseId_not_lt?: Maybe<Scalars['Int']>
  warehouseId_lte?: Maybe<Scalars['Int']>
  warehouseId_not_lte?: Maybe<Scalars['Int']>
  warehouse?: Maybe<WarehouseFilter>
  active?: Maybe<Scalars['Boolean']>
  active_not?: Maybe<Scalars['Boolean']>
}

export type ProductSupplierFilterInput = {
  and?: Maybe<Array<ProductSupplierFilterInput>>
  or?: Maybe<Array<ProductSupplierFilterInput>>
  id?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  product?: Maybe<ProductFilterInput>
  supplierId?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  supplier?: Maybe<SupplierFilterInput>
  warehouseId?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  warehouse?: Maybe<WarehouseFilterInput>
  active?: Maybe<BooleanOperationFilterInput>
}

export type ProductSupplierInput = {
  id?: Maybe<Scalars['Int']>
  product?: Maybe<ProductInput>
  supplierId: Scalars['Int']
  supplier?: Maybe<SupplierInput>
  warehouseId?: Maybe<Scalars['Int']>
  warehouse?: Maybe<WarehouseInput>
  active: Scalars['Boolean']
}

export type ProductSupplierUpdateInput = {
  product?: Maybe<ProductUpdateInput>
  supplierId?: Maybe<Scalars['Int']>
  warehouseId?: Maybe<Scalars['Int']>
  warehouse?: Maybe<WarehouseUpdateInput>
  active?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['Int']>
}

export type ProductUpdateInput = {
  stockOwnerId?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
  altCode?: Maybe<Scalars['String']>
  gTIN?: Maybe<Scalars['String']>
  substitute?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  longDesc?: Maybe<Scalars['String']>
  linkCode?: Maybe<Scalars['String']>
  require?: Maybe<ProductRequiredDataUpdateInput>
  SKUType?: Maybe<BomType>
  INCICode?: Maybe<Scalars['String']>
  rotation?: Maybe<RotationRule>
  type?: Maybe<Scalars['String']>
  StorageType?: Maybe<Scalars['String']>
  facilityCat?: Maybe<Scalars['String']>
  defBudhol?: Maybe<Scalars['String']>
  defWH?: Maybe<Scalars['String']>
  defHandType?: Maybe<Scalars['String']>
  defPackType?: Maybe<Scalars['String']>
  depCode?: Maybe<Scalars['String']>
  rank?: Maybe<Scalars['String']>
  uNCode?: Maybe<Scalars['String']>
  bandQty?: Maybe<Scalars['Int']>
  manufacturerCode?: Maybe<Scalars['String']>
  lifeCycleStatus?: Maybe<Scalars['String']>
  packValidateBatch?: Maybe<Scalars['Boolean']>
  capturePackType?: Maybe<Scalars['Boolean']>
  pickPartPalletsAsFull?: Maybe<Scalars['Boolean']>
  pickSingles?: Maybe<Scalars['Boolean']>
  pickDefId?: Maybe<Scalars['Int']>
  defGRStatus?: Maybe<Scalars['String']>
  minRShlfLife?: Maybe<Scalars['Int']>
  minPShlfLife?: Maybe<Scalars['Int']>
  totShlfLife?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
}

export type Query = {
  __typename?: 'Query'
  activities?: Maybe<Array<Maybe<Activity>>>
  audits?: Maybe<AuditEntryConnection>
  availablePermissions?: Maybe<Array<Permission>>
  budgetHolderByCode?: Maybe<BudgetHolder>
  budgetHolderById?: Maybe<BudgetHolder>
  budgetHolders?: Maybe<BudgetHolderConnection>
  carrierByCode?: Maybe<Carrier>
  carrierById?: Maybe<Carrier>
  carrierLeadTimes?: Maybe<Array<Maybe<CarrierLeadTime>>>
  carrierModules?: Maybe<Array<Maybe<Scalars['String']>>>
  carriers?: Maybe<CarrierConnection>
  closeLoadMethods?: Maybe<Array<Maybe<Scalars['String']>>>
  code?: Maybe<UdCode>
  codeDefinitions?: Maybe<CodeDefinitionConnection>
  codes?: Maybe<UdCodeConnection>
  codesForType?: Maybe<UdCodeConnection>
  configurationForModule?: Maybe<Array<Maybe<ConfigOption>>>
  coreVersion?: Maybe<Scalars['String']>
  countries?: Maybe<CountryConnection>
  countryByCode?: Maybe<Country>
  currencies?: Maybe<Array<Maybe<Scalars['String']>>>
  currentRateForCurrency?: Maybe<ExchangeRate>
  currentUser?: Maybe<WmsUserResult>
  despatchMethods?: Maybe<Array<Maybe<Scalars['String']>>>
  destinationByCode?: Maybe<Destination>
  destinationById?: Maybe<Destination>
  destinationCodes?: Maybe<Array<Maybe<DestinationCode>>>
  destinations?: Maybe<DestinationConnection>
  exchangeRateById?: Maybe<ExchangeRate>
  exchangeRates?: Maybe<ExchangeRateConnection>
  exchangeRatesForCurrency?: Maybe<ExchangeRateConnection>
  handlingTypeByCode?: Maybe<HandlingType>
  handlingTypeById?: Maybe<HandlingType>
  handlingTypes?: Maybe<HandlingTypeConnection>
  loadedModules?: Maybe<Array<Maybe<AssemblyName>>>
  locationByCode?: Maybe<Location>
  locationById?: Maybe<Location>
  locationPhysicalTypes?: Maybe<Array<Maybe<LocationPhysicalTypeMetadata>>>
  locationProcessTypes?: Maybe<Array<Maybe<LocationProcessTypeMetadata>>>
  locations?: Maybe<LocationConnection>
  materialHandlingEquipment?: Maybe<MaterialHandlingEquipmentConnection>
  materialHandlingEquipmentByCode?: Maybe<MaterialHandlingEquipment>
  materialHandlingEquipmentById?: Maybe<MaterialHandlingEquipment>
  maxWeeklySpends?: Maybe<Array<Maybe<MaxWeeklySpend>>>
  orderEntries?: Maybe<Array<Maybe<OrderEntry>>>
  orderEntry?: Maybe<OrderEntry>
  orderTypes?: Maybe<Array<Maybe<OrderType>>>
  packingMethods?: Maybe<Array<Maybe<Scalars['String']>>>
  pickConfirmMethods?: Maybe<Array<Maybe<Scalars['String']>>>
  pickCreationMethods?: Maybe<Array<Maybe<Scalars['String']>>>
  pickListTypes?: Maybe<Array<Maybe<PickListType>>>
  pickOutputMethods?: Maybe<Array<Maybe<Scalars['String']>>>
  poFormats?: Maybe<Array<Maybe<PoFormat>>>
  poTransmissionMethods?: Maybe<Array<Maybe<PoTransmissionMethod>>>
  postPickConfirmMethods?: Maybe<Array<Maybe<Scalars['String']>>>
  postPickCreationMethods?: Maybe<Array<Maybe<Scalars['String']>>>
  prePickCreationMethods?: Maybe<Array<Maybe<Scalars['String']>>>
  product?: Maybe<ProductConnection>
  productByCode?: Maybe<Product>
  productById?: Maybe<Product>
  resources?: Maybe<ResourcesQuery>
  routs?: Maybe<Array<Maybe<Route>>>
  serverTime?: Maybe<Scalars['DateTime']>
  services?: Maybe<Array<Maybe<Service>>>
  stock?: Maybe<StockQueryType>
  stockOwnerByCode?: Maybe<StockOwner>
  stockOwnerById?: Maybe<StockOwner>
  stockOwnerById2?: Maybe<Array<Maybe<StockOwner>>>
  stockOwners?: Maybe<StockOwnerConnection>
  storageTypeByCode?: Maybe<StorageType>
  storageTypeById?: Maybe<StorageType>
  storageTypes?: Maybe<StorageTypeConnection>
  supplierByCode?: Maybe<Supplier>
  supplierById?: Maybe<Supplier>
  suppliers?: Maybe<SupplierConnection>
  userById?: Maybe<WmsUserResult>
  userByUsername?: Maybe<WmsUserResult>
  userPermission?: Maybe<UserPermission>
  userPermissions?: Maybe<UserPermissionConnection>
  users?: Maybe<WmsUserResultConnection>
  warehouseByCode?: Maybe<Warehouse>
  warehouseById?: Maybe<Warehouse>
  warehouseZone?: Maybe<WarehouseZone>
  warehouseZones?: Maybe<WarehouseZoneConnection>
  warehouses?: Maybe<WarehouseConnection>
  zoneTypes?: Maybe<Array<Maybe<ZoneType>>>
  zones?: Maybe<Array<Maybe<Zone>>>
}

export type QueryAuditsArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  userContext?: Maybe<UserContextInput>
  where?: Maybe<AuditEntryFilterInput>
  order?: Maybe<Array<AuditEntrySortInput>>
}

export type QueryAvailablePermissionsArgs = {
  userContext?: Maybe<UserContextInput>
}

export type QueryBudgetHolderByCodeArgs = {
  userContext?: Maybe<UserContextInput>
  code?: Maybe<Scalars['String']>
}

export type QueryBudgetHolderByIdArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type QueryBudgetHoldersArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  userContext?: Maybe<UserContextInput>
  where?: Maybe<BudgetHolderFilter>
  order_by?: Maybe<BudgetHolderSort>
}

export type QueryCarrierByCodeArgs = {
  userContext?: Maybe<UserContextInput>
  code?: Maybe<Scalars['String']>
}

export type QueryCarrierByIdArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type QueryCarrierModulesArgs = {
  userContext?: Maybe<UserContextInput>
}

export type QueryCarriersArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  userContext?: Maybe<UserContextInput>
  where?: Maybe<CarrierFilter>
  order_by?: Maybe<CarrierSort>
}

export type QueryCodeArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type QueryCodeDefinitionsArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  where?: Maybe<CodeDefinitionFilter>
  order_by?: Maybe<CodeDefinitionSort>
}

export type QueryCodesArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  userContext?: Maybe<UserContextInput>
  where?: Maybe<UdCodeFilter>
  order_by?: Maybe<UdCodeSort>
}

export type QueryCodesForTypeArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  userContext?: Maybe<UserContextInput>
  codeType?: Maybe<Scalars['String']>
  where?: Maybe<UdCodeFilter>
  order_by?: Maybe<UdCodeSort>
}

export type QueryConfigurationForModuleArgs = {
  userContext?: Maybe<UserContextInput>
  module?: Maybe<Scalars['String']>
}

export type QueryCountriesArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  userContext?: Maybe<UserContextInput>
  where?: Maybe<CountryFilter>
  order_by?: Maybe<CountrySort>
}

export type QueryCountryByCodeArgs = {
  userContext?: Maybe<UserContextInput>
  code?: Maybe<Scalars['String']>
}

export type QueryCurrenciesArgs = {
  userContext?: Maybe<UserContextInput>
}

export type QueryCurrentRateForCurrencyArgs = {
  userContext?: Maybe<UserContextInput>
  code: Currency
  target: Currency
}

export type QueryDestinationByCodeArgs = {
  userContext?: Maybe<UserContextInput>
  code?: Maybe<Scalars['String']>
}

export type QueryDestinationByIdArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type QueryDestinationsArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  userContext?: Maybe<UserContextInput>
  where?: Maybe<DestinationFilter>
  order_by?: Maybe<DestinationSort>
}

export type QueryExchangeRateByIdArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type QueryExchangeRatesArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  userContext?: Maybe<UserContextInput>
  where?: Maybe<ExchangeRateFilter>
  order_by?: Maybe<ExchangeRateSort>
}

export type QueryExchangeRatesForCurrencyArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  userContext?: Maybe<UserContextInput>
  code: Currency
  where?: Maybe<ExchangeRateFilter>
  order_by?: Maybe<ExchangeRateSort>
}

export type QueryHandlingTypeByCodeArgs = {
  userContext?: Maybe<UserContextInput>
  code?: Maybe<Scalars['String']>
}

export type QueryHandlingTypeByIdArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type QueryHandlingTypesArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  userContext?: Maybe<UserContextInput>
  where?: Maybe<HandlingTypeFilter>
  order_by?: Maybe<HandlingTypeSort>
}

export type QueryLocationByCodeArgs = {
  userContext?: Maybe<UserContextInput>
  code?: Maybe<Scalars['String']>
}

export type QueryLocationByIdArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type QueryLocationsArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  userContext?: Maybe<UserContextInput>
  where?: Maybe<LocationFilter>
  order_by?: Maybe<LocationSort>
}

export type QueryMaterialHandlingEquipmentArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  userContext?: Maybe<UserContextInput>
  where?: Maybe<MaterialHandlingEquipmentFilter>
  order_by?: Maybe<MaterialHandlingEquipmentSort>
}

export type QueryMaterialHandlingEquipmentByCodeArgs = {
  userContext?: Maybe<UserContextInput>
  code?: Maybe<Scalars['String']>
}

export type QueryMaterialHandlingEquipmentByIdArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type QueryOrderEntriesArgs = {
  where?: Maybe<OrderFilter>
}

export type QueryOrderEntryArgs = {
  code: Scalars['String']
}

export type QueryProductArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  context?: Maybe<UserContextInput>
  includeLinked: Scalars['Boolean']
  where?: Maybe<ProductFilter>
  order_by?: Maybe<ProductSort>
}

export type QueryProductByCodeArgs = {
  context?: Maybe<UserContextInput>
  code?: Maybe<Scalars['String']>
}

export type QueryProductByIdArgs = {
  context?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type QueryStockOwnerByCodeArgs = {
  userContext?: Maybe<UserContextInput>
  code?: Maybe<Scalars['String']>
}

export type QueryStockOwnerByIdArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type QueryStockOwnerById2Args = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type QueryStockOwnersArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  userContext?: Maybe<UserContextInput>
  where?: Maybe<StockOwnerFilter>
  order_by?: Maybe<StockOwnerSort>
}

export type QueryStorageTypeByCodeArgs = {
  userContext?: Maybe<UserContextInput>
  code?: Maybe<Scalars['String']>
}

export type QueryStorageTypeByIdArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type QueryStorageTypesArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  userContext?: Maybe<UserContextInput>
  where?: Maybe<StorageTypeFilter>
  order_by?: Maybe<StorageTypeSort>
}

export type QuerySupplierByCodeArgs = {
  userContext?: Maybe<UserContextInput>
  code?: Maybe<Scalars['String']>
}

export type QuerySupplierByIdArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type QuerySuppliersArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  userContext?: Maybe<UserContextInput>
  where?: Maybe<SupplierFilter>
  order_by?: Maybe<SupplierSort>
}

export type QueryUserByIdArgs = {
  id?: Maybe<Scalars['String']>
}

export type QueryUserByUsernameArgs = {
  username?: Maybe<Scalars['String']>
}

export type QueryUserPermissionArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type QueryUserPermissionsArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  userContext?: Maybe<UserContextInput>
  where?: Maybe<UserPermissionFilter>
  order_by?: Maybe<UserPermissionSort>
}

export type QueryUsersArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  where?: Maybe<WmsUserFilterInput>
  order?: Maybe<Array<WmsUserSortInput>>
}

export type QueryWarehouseByCodeArgs = {
  userContext?: Maybe<UserContextInput>
  code?: Maybe<Scalars['String']>
}

export type QueryWarehouseByIdArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type QueryWarehouseZoneArgs = {
  userContext?: Maybe<UserContextInput>
  id: Scalars['Int']
}

export type QueryWarehouseZonesArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  userContext?: Maybe<UserContextInput>
  where?: Maybe<WarehouseZoneFilter>
  order_by?: Maybe<WarehouseZoneSort>
}

export type QueryWarehousesArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  userContext?: Maybe<UserContextInput>
  where?: Maybe<WarehouseFilter>
  order_by?: Maybe<WarehouseSort>
}

export type QueryZonesArgs = {
  where?: Maybe<ZoneFilter>
}

export type ResourceIdItem = {
  __typename?: 'ResourceIdItem'
  value?: Maybe<Scalars['String']>
  resourceId?: Maybe<Scalars['String']>
  hasValue: Scalars['Boolean']
}

export type ResourceIdListItem = {
  __typename?: 'ResourceIdListItem'
  value?: Maybe<Scalars['String']>
  text?: Maybe<Scalars['String']>
  selected: Scalars['Boolean']
  style?: Maybe<Scalars['String']>
  resourceId?: Maybe<Scalars['String']>
  hasValue: Scalars['Boolean']
}

export type ResourceItem = {
  __typename?: 'ResourceItem'
  resourceId?: Maybe<Scalars['String']>
  resources?: Maybe<Array<Maybe<BasicResourceItem>>>
}

export type ResourceItemEx = {
  __typename?: 'ResourceItemEx'
  isRtl: Scalars['Boolean']
  resourceList?: Maybe<Array<Maybe<ResourceString>>>
  resourceId?: Maybe<Scalars['String']>
  comment?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  localeId?: Maybe<Scalars['String']>
  valueType: Scalars['Int']
  updated: Scalars['DateTime']
  resourceSet?: Maybe<Scalars['String']>
  textFile?: Maybe<Scalars['String']>
  binFile?: Maybe<Array<Scalars['Byte']>>
  fileName?: Maybe<Scalars['String']>
}

export type ResourceItemInput = {
  resourceId?: Maybe<Scalars['String']>
  comment?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  localeId?: Maybe<Scalars['String']>
  valueType: Scalars['Int']
  updated: Scalars['DateTime']
  resourceSet?: Maybe<Scalars['String']>
  textFile?: Maybe<Scalars['String']>
  binFile?: Maybe<Array<Scalars['Byte']>>
  fileName?: Maybe<Scalars['String']>
}

export type ResourcesMutation = {
  __typename?: 'ResourcesMutation'
  updateResourceString: Scalars['Boolean']
  updateComment: Scalars['Boolean']
  updateResource: Scalars['Boolean']
  deleteResourceSet: Scalars['Boolean']
  deleteResource: Scalars['Boolean']
  renameResource: Scalars['Boolean']
  renameResourceProperty: Scalars['Boolean']
  renameResourceSet: Scalars['Boolean']
  createTable: Scalars['Boolean']
  backup: Scalars['Boolean']
  reloadResources: Scalars['Boolean']
  translate?: Maybe<Scalars['String']>
  fromCamelCase?: Maybe<Scalars['String']>
}

export type ResourcesMutationUpdateResourceStringArgs = {
  resourceSet: Scalars['String']
  resourceId: Scalars['String']
  localeId?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
  comment?: Maybe<Scalars['String']>
}

export type ResourcesMutationUpdateCommentArgs = {
  resourceSet: Scalars['String']
  resourceId: Scalars['String']
  localeId?: Maybe<Scalars['String']>
  comment?: Maybe<Scalars['String']>
}

export type ResourcesMutationUpdateResourceArgs = {
  resource: ResourceItemInput
}

export type ResourcesMutationDeleteResourceSetArgs = {
  resourceSet: Scalars['String']
}

export type ResourcesMutationDeleteResourceArgs = {
  resourceId: Scalars['String']
  resourceSet: Scalars['String']
  localeId?: Maybe<Scalars['String']>
}

export type ResourcesMutationRenameResourceArgs = {
  resourceId: Scalars['String']
  newResourceId: Scalars['String']
  resourceSet: Scalars['String']
}

export type ResourcesMutationRenameResourcePropertyArgs = {
  property: Scalars['String']
  newProperty: Scalars['String']
  resourceSet: Scalars['String']
}

export type ResourcesMutationRenameResourceSetArgs = {
  oldResourceSet: Scalars['String']
  newResourceSet: Scalars['String']
}

export type ResourcesMutationTranslateArgs = {
  text: Scalars['String']
  from: Scalars['String']
  to: Scalars['String']
  service: Scalars['String']
}

export type ResourcesMutationFromCamelCaseArgs = {
  camelCaseString?: Maybe<Scalars['String']>
}

export type ResourcesQuery = {
  __typename?: 'ResourcesQuery'
  resourceList?: Maybe<Array<Maybe<ResourceIdItem>>>
  allResourcesForResourceGrid?: Maybe<GridResult>
  resourceListHtml?: Maybe<Array<Maybe<ResourceIdListItem>>>
  resourceSets?: Maybe<Array<Maybe<Scalars['String']>>>
  allLocaleIds?: Maybe<Array<Maybe<CultureInfo>>>
  resourceString?: Maybe<Scalars['String']>
  resourceItems?: Maybe<Array<Maybe<ResourceItemEx>>>
  resourceItem?: Maybe<ResourceItemEx>
  cultures?: Maybe<CultureInfoConnection>
  isLocalizationTable: Scalars['Boolean']
  isRtl: Scalars['Boolean']
}

export type ResourcesQueryResourceListArgs = {
  resourceSet: Scalars['String']
}

export type ResourcesQueryAllResourcesForResourceGridArgs = {
  resourceSet: Scalars['String']
}

export type ResourcesQueryResourceListHtmlArgs = {
  resourceSet: Scalars['String']
}

export type ResourcesQueryAllLocaleIdsArgs = {
  resourceSet: Scalars['String']
}

export type ResourcesQueryResourceStringArgs = {
  resourceId: Scalars['String']
  resourceSet: Scalars['String']
  cultureName?: Maybe<Scalars['String']>
}

export type ResourcesQueryResourceItemsArgs = {
  resourceId: Scalars['String']
  resourceSet: Scalars['String']
}

export type ResourcesQueryResourceItemArgs = {
  resourceId: Scalars['String']
  resourceSet: Scalars['String']
}

export type ResourcesQueryCulturesArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  where?: Maybe<CultureInfoFilterInput>
  order?: Maybe<Array<CultureInfoSortInput>>
}

export type ResourcesQueryIsRtlArgs = {
  localeId?: Maybe<Scalars['String']>
}

export type ResourceString = {
  __typename?: 'ResourceString'
  localeId?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export enum RotationRule {
  Fifo = 'FIFO',
  Fefo = 'FEFO',
  Lifo = 'LIFO',
  None = 'NONE'
}

export type Route = {
  __typename?: 'Route'
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

export enum ServerPermission {
  User = 'USER',
  Manager = 'MANAGER',
  Administrator = 'ADMINISTRATOR',
  SuperUser = 'SUPER_USER'
}

export type Service = {
  __typename?: 'Service'
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortOperationKind {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SortVersionFilterInput = {
  and?: Maybe<Array<SortVersionFilterInput>>
  or?: Maybe<Array<SortVersionFilterInput>>
  fullVersion?: Maybe<ComparableInt32OperationFilterInput>
  sortId?: Maybe<ComparableGuidOperationFilterInput>
}

export type SortVersionSortInput = {
  fullVersion?: Maybe<SortEnumType>
  sortId?: Maybe<SortEnumType>
}

export enum State {
  Open = 'OPEN',
  Closed = 'CLOSED',
  OpeningSoon = 'OPENING_SOON',
  ClosingSoon = 'CLOSING_SOON'
}

export type Stock = {
  __typename?: 'Stock'
  stockOwner?: Maybe<StockOwner>
  product?: Maybe<Product>
  location?: Maybe<Location>
  id?: Maybe<Scalars['Int']>
  warehouseId?: Maybe<Scalars['Int']>
  warehouse?: Maybe<Warehouse>
  stockOwnerId?: Maybe<Scalars['Int']>
  productId?: Maybe<Scalars['Int']>
  attributeId?: Maybe<Scalars['Int']>
  attributes?: Maybe<StockAttributes>
  status?: Maybe<Scalars['String']>
  packType?: Maybe<Scalars['String']>
  packs: Scalars['Decimal']
  singles: Scalars['Decimal']
  allocated: Scalars['Decimal']
  locationId?: Maybe<Scalars['Int']>
  licencePlateId?: Maybe<Scalars['Int']>
  licencePlate?: Maybe<LicencePlate>
}

export type StockAttributes = IwmsAttributes & {
  __typename?: 'StockAttributes'
  product?: Maybe<Product>
  id?: Maybe<Scalars['Int']>
  productId?: Maybe<Scalars['Int']>
  batch?: Maybe<Scalars['String']>
  sellByDate?: Maybe<Scalars['DateTime']>
  GRN?: Maybe<Scalars['String']>
  pONumber?: Maybe<Scalars['String']>
  serialNumber?: Maybe<Scalars['String']>
  budgetHolder?: Maybe<Scalars['String']>
  catchWeight?: Maybe<Scalars['Decimal']>
  bestBeforeDate?: Maybe<Scalars['DateTime']>
  killDate?: Maybe<Scalars['DateTime']>
  productionDate?: Maybe<Scalars['DateTime']>
  slaughterCode?: Maybe<Scalars['String']>
  batchInfo?: Maybe<Scalars['String']>
}

export type StockAttributesFilterInput = {
  and?: Maybe<Array<StockAttributesFilterInput>>
  or?: Maybe<Array<StockAttributesFilterInput>>
  id?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  productId?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  product?: Maybe<ProductFilterInput>
  batch?: Maybe<StringOperationFilterInput>
  sellByDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>
  GRN?: Maybe<StringOperationFilterInput>
  pONumber?: Maybe<StringOperationFilterInput>
  serialNumber?: Maybe<StringOperationFilterInput>
  budgetHolder?: Maybe<StringOperationFilterInput>
  catchWeight?: Maybe<ComparableNullableOfDecimalOperationFilterInput>
  bestBeforeDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>
  killDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>
  productionDate?: Maybe<ComparableNullableOfDateTimeOperationFilterInput>
  slaughterCode?: Maybe<StringOperationFilterInput>
  batchInfo?: Maybe<StringOperationFilterInput>
}

export type StockAttributesSortInput = {
  id?: Maybe<SortEnumType>
  productId?: Maybe<SortEnumType>
  product?: Maybe<ProductSortInput>
  batch?: Maybe<SortEnumType>
  sellByDate?: Maybe<SortEnumType>
  GRN?: Maybe<SortEnumType>
  pONumber?: Maybe<SortEnumType>
  serialNumber?: Maybe<SortEnumType>
  budgetHolder?: Maybe<SortEnumType>
  catchWeight?: Maybe<SortEnumType>
  bestBeforeDate?: Maybe<SortEnumType>
  killDate?: Maybe<SortEnumType>
  productionDate?: Maybe<SortEnumType>
  slaughterCode?: Maybe<SortEnumType>
  batchInfo?: Maybe<SortEnumType>
}

export type StockConnection = {
  __typename?: 'StockConnection'
  pageInfo: PageInfo
  edges?: Maybe<Array<StockEdge>>
  nodes?: Maybe<Array<Maybe<Stock>>>
  totalCount: Scalars['Int']
}

export type StockEdge = {
  __typename?: 'StockEdge'
  cursor: Scalars['String']
  node?: Maybe<Stock>
}

export type StockFilterInput = {
  and?: Maybe<Array<StockFilterInput>>
  or?: Maybe<Array<StockFilterInput>>
  id?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  warehouseId?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  warehouse?: Maybe<WarehouseFilterInput>
  stockOwnerId?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  stockOwner?: Maybe<StockOwnerFilterInput>
  productId?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  product?: Maybe<ProductFilterInput>
  attributeId?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  attributes?: Maybe<StockAttributesFilterInput>
  status?: Maybe<StringOperationFilterInput>
  packType?: Maybe<StringOperationFilterInput>
  packs?: Maybe<ComparableDecimalOperationFilterInput>
  singles?: Maybe<ComparableDecimalOperationFilterInput>
  allocated?: Maybe<ComparableDecimalOperationFilterInput>
  locationId?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  location?: Maybe<LocationFilterInput>
  licencePlateId?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  licencePlate?: Maybe<LicencePlateFilterInput>
}

export type StockOwner = {
  __typename?: 'StockOwner'
  orderStatuses?: Maybe<Array<Maybe<OrderStatus>>>
  productStatuses?: Maybe<Array<Maybe<ProductStatus>>>
  categories?: Maybe<Array<Maybe<CategoryDefinition>>>
  pickMethods?: Maybe<Array<Maybe<PickMethod>>>
  confirmMethods?: Maybe<Array<Maybe<PickConfirmMethod>>>
  id?: Maybe<Scalars['Int']>
  code: Scalars['String']
  name: Scalars['String']
  status: State
  linkedStockOwners?: Maybe<Array<Maybe<StockOwnerLink>>>
  contact?: Maybe<Contact>
  address?: Maybe<Address>
  coldStoreID?: Maybe<Scalars['String']>
  GLN?: Maybe<Scalars['String']>
  VATNumber?: Maybe<Scalars['String']>
  warnMoveDifStatus?: Maybe<Scalars['Boolean']>
  requireBudhol?: Maybe<Scalars['Boolean']>
  requireProdCategories?: Maybe<Scalars['Boolean']>
  scanBarcodeMask?: Maybe<Scalars['String']>
  keyedBarcodeMask?: Maybe<Scalars['String']>
  captureSerialsOnRcpt?: Maybe<Scalars['Boolean']>
  requirePalletID?: Maybe<Scalars['Boolean']>
  printPalLblsOnRcpt?: Maybe<Scalars['Boolean']>
  capturePONumOnRcpt?: Maybe<Scalars['Boolean']>
  allocateLocOnRcpt?: Maybe<Scalars['Boolean']>
  closeComPoOnRcpt?: Maybe<Scalars['Boolean']>
  validateBatchPO?: Maybe<Scalars['Boolean']>
  capturePkgInfoOnRcpt?: Maybe<Scalars['Boolean']>
  putwayLPOnRcpt?: Maybe<Scalars['Boolean']>
  confirmPutawaysOnRcpt?: Maybe<Scalars['Boolean']>
  reqAuthOnGRClose?: Maybe<Scalars['Boolean']>
  defaultUnbookedRcpt?: Maybe<Scalars['Boolean']>
  identicalPallets?: Maybe<Scalars['Boolean']>
  strictPODateVal?: Maybe<Scalars['Boolean']>
  splitSuppTolerance?: Maybe<Scalars['Boolean']>
  RVPutToDefLoc?: Maybe<Scalars['Boolean']>
  RFPutImmediately?: Maybe<Scalars['Boolean']>
  disablePutForSpecificLocs?: Maybe<Scalars['Boolean']>
  mandExtraInfoOnRcpt?: Maybe<Scalars['Boolean']>
  captureContainerType?: Maybe<Scalars['Boolean']>
  F2ShowBookedProdsOnly?: Maybe<Scalars['Boolean']>
  autoGeneratePONumber?: Maybe<Scalars['Boolean']>
  allowEditInRV?: Maybe<Scalars['Boolean']>
  GRPrintCrtnLbls?: Maybe<Scalars['Boolean']>
  noBckFlushGRProduction?: Maybe<Scalars['Boolean']>
  printLblsGRProduction?: Maybe<Scalars['Boolean']>
  matchGRWithBookNo?: Maybe<Scalars['Boolean']>
  RVSuppressMergePF?: Maybe<Scalars['Boolean']>
  spltPOLinesOnPrint?: Maybe<Scalars['Boolean']>
  makeGRNBudhol?: Maybe<Scalars['Boolean']>
  allowShortShelfLife?: Maybe<Scalars['Boolean']>
  allowAddProdsToPO?: Maybe<Scalars['Boolean']>
  captureLocOnRcpt?: Maybe<Scalars['Boolean']>
  captureTempOnRcpt?: Maybe<Scalars['Boolean']>
  autoGenOrderNum?: Maybe<Scalars['Boolean']>
  dispOrderEntryErrs?: Maybe<Scalars['Boolean']>
  requirePOD?: Maybe<Scalars['Boolean']>
  printOrdConfRpt?: Maybe<Scalars['Boolean']>
  createPickOnOrdEntry?: Maybe<Scalars['Boolean']>
  dontClearLineCustRef?: Maybe<Scalars['Boolean']>
  mandLineCustref?: Maybe<Scalars['Boolean']>
  warnOnShortage?: Maybe<Scalars['Boolean']>
  printOrdRptWithPick?: Maybe<Scalars['Boolean']>
  printBoxPackLbls?: Maybe<Scalars['Boolean']>
  GDPRControlled?: Maybe<Scalars['Boolean']>
  forceBudholOnEntry?: Maybe<Scalars['Boolean']>
  preventBatchOnEntry?: Maybe<Scalars['Boolean']>
  swapGenericsOnEntry?: Maybe<Scalars['Boolean']>
  forceDepartmentOnEntry?: Maybe<Scalars['Boolean']>
  cleanOrdersOnly?: Maybe<Scalars['Boolean']>
  createBackOrders?: Maybe<Scalars['Boolean']>
  allowShortageSwap?: Maybe<Scalars['Boolean']>
  allowBudholSwap?: Maybe<Scalars['Boolean']>
  chngeTraysOnPri?: Maybe<Scalars['Boolean']>
  noReplenPicksOnly?: Maybe<Scalars['Boolean']>
  allowStatusSwap?: Maybe<Scalars['Boolean']>
  pickCartons?: Maybe<Scalars['Boolean']>
  MPNoSplitLists?: Maybe<Scalars['Boolean']>
  showUnpickableRpt?: Maybe<Scalars['Boolean']>
  allowLPSwap?: Maybe<Scalars['Boolean']>
  allowSBDSwap?: Maybe<Scalars['Boolean']>
  findCartonByWgt?: Maybe<Scalars['Boolean']>
  captureCarrier?: Maybe<Scalars['Boolean']>
  allowLocSwap?: Maybe<Scalars['Boolean']>
  useBOMPicking?: Maybe<Scalars['Boolean']>
  requireDespNote?: Maybe<Scalars['Boolean']>
  RFPickAssignment?: Maybe<PickAssignment>
  voicePickAssignment?: Maybe<PickAssignment>
  allowBatchSwap?: Maybe<Scalars['Boolean']>
  requireConfProduct?: Maybe<Scalars['Boolean']>
  requireUPICheck?: Maybe<Scalars['Boolean']>
  cageValidation?: Maybe<Scalars['Boolean']>
  despNoteValidation?: Maybe<Scalars['Boolean']>
  suppressReplenOpt?: Maybe<Scalars['Boolean']>
  useLoading?: Maybe<Scalars['Boolean']>
  produceRFManifest?: Maybe<Scalars['Boolean']>
  requireSealNo?: Maybe<Scalars['Boolean']>
  requireTruck?: Maybe<Scalars['Boolean']>
  requireDriver?: Maybe<Scalars['Boolean']>
  useLoadOrder?: Maybe<Scalars['Boolean']>
  loadOrder?: Maybe<LoadOrder>
  despLane?: Maybe<Scalars['String']>
  maxLoaders?: Maybe<Scalars['Int']>
  adhocLoading?: Maybe<Accessiblity>
  loadTempCaptureStart?: Maybe<Scalars['Boolean']>
  loadTempCaptureEnd?: Maybe<Scalars['Boolean']>
  loadTempLPInterval?: Maybe<Scalars['Boolean']>
  captureTimeOnConfirm?: Maybe<FieldVisibility>
  captureCheckerOnConfirm?: Maybe<FieldVisibility>
  capturePickerOnConfirm?: Maybe<FieldVisibility>
  defPickFailCode?: Maybe<Scalars['String']>
  requireDespatchLane?: Maybe<Scalars['Boolean']>
  useEmailFromDestination?: Maybe<Scalars['Boolean']>
  reprntUnfinishedLists?: Maybe<Scalars['Boolean']>
  destockShortOnSHort?: Maybe<Scalars['Boolean']>
  proportionalBoxesOnConfirm?: Maybe<Scalars['Boolean']>
  allowLoadAnySeq?: Maybe<Scalars['Boolean']>
  MBPLPControl?: Maybe<Scalars['Boolean']>
  MBPUseSODespMeth?: Maybe<Scalars['Boolean']>
  noDespNotes?: Maybe<Scalars['Boolean']>
  previewDespNote?: Maybe<Scalars['Boolean']>
  BOMSToStock?: Maybe<Scalars['Boolean']>
  delayDespatch?: Maybe<Scalars['Boolean']>
  RFWarnInPckConf?: Maybe<Scalars['Boolean']>
  noReplenPckConf?: Maybe<Scalars['Boolean']>
  despNotePerList?: Maybe<Scalars['Boolean']>
  printBoxLbls?: Maybe<Scalars['Boolean']>
  disablImmDesp?: Maybe<Scalars['Boolean']>
  allowFutureDespDates?: Maybe<Scalars['Boolean']>
  MBPCaptureConsNo?: Maybe<Scalars['Boolean']>
  optConsNo?: Maybe<Scalars['Boolean']>
  boxLblsPickConf?: Maybe<Scalars['Boolean']>
  useLPIdsPickConfirm?: Maybe<Scalars['Boolean']>
  authMBP?: Maybe<Scalars['Boolean']>
  mandContainerType?: Maybe<Scalars['Boolean']>
  PDFDespNotes?: Maybe<Scalars['Boolean']>
  useDespPrefix?: Maybe<Scalars['Boolean']>
  noOfDespNotes?: Maybe<Scalars['Boolean']>
  confWholePick?: Maybe<Scalars['Boolean']>
  capReasonDelayDesp?: Maybe<Scalars['Boolean']>
  MBPDespNoteBreakdwn?: Maybe<Scalars['Boolean']>
  dontCloseOrderOnLblFail?: Maybe<Scalars['Boolean']>
  capBoxesPerOrder?: Maybe<Scalars['Boolean']>
  carrierNotReq?: Maybe<Scalars['Boolean']>
  noCarrLblsPckConf?: Maybe<Scalars['Boolean']>
  copyCarrToBckOrd?: Maybe<Scalars['Boolean']>
}

export type StockOwnerConnection = {
  __typename?: 'StockOwnerConnection'
  pageInfo: PageInfo
  edges?: Maybe<Array<StockOwnerEdge>>
  nodes?: Maybe<Array<Maybe<StockOwner>>>
  totalCount: Scalars['Int']
}

export type StockOwnerEdge = {
  __typename?: 'StockOwnerEdge'
  cursor: Scalars['String']
  node?: Maybe<StockOwner>
}

export type StockOwnerFilter = {
  AND?: Maybe<Array<StockOwnerFilter>>
  OR?: Maybe<Array<StockOwnerFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
  code_not?: Maybe<Scalars['String']>
  code_contains?: Maybe<Scalars['String']>
  code_not_contains?: Maybe<Scalars['String']>
  code_starts_with?: Maybe<Scalars['String']>
  code_not_starts_with?: Maybe<Scalars['String']>
  code_ends_with?: Maybe<Scalars['String']>
  code_not_ends_with?: Maybe<Scalars['String']>
  code_in?: Maybe<Array<Scalars['String']>>
  code_not_in?: Maybe<Array<Scalars['String']>>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Scalars['String']>>
  name_not_in?: Maybe<Array<Scalars['String']>>
  status?: Maybe<State>
  status_not?: Maybe<State>
  status_in?: Maybe<Array<State>>
  status_not_in?: Maybe<Array<State>>
  status_gt?: Maybe<State>
  status_not_gt?: Maybe<State>
  status_gte?: Maybe<State>
  status_not_gte?: Maybe<State>
  status_lt?: Maybe<State>
  status_not_lt?: Maybe<State>
  status_lte?: Maybe<State>
  status_not_lte?: Maybe<State>
  linkedStockOwners_some?: Maybe<StockOwnerLinkFilter>
  linkedStockOwners_none?: Maybe<StockOwnerLinkFilter>
  linkedStockOwners_all?: Maybe<StockOwnerLinkFilter>
  linkedStockOwners_any?: Maybe<Scalars['Boolean']>
  contact?: Maybe<ContactFilter>
  address?: Maybe<AddressFilter>
  coldStoreID?: Maybe<Scalars['String']>
  coldStoreID_not?: Maybe<Scalars['String']>
  coldStoreID_contains?: Maybe<Scalars['String']>
  coldStoreID_not_contains?: Maybe<Scalars['String']>
  coldStoreID_starts_with?: Maybe<Scalars['String']>
  coldStoreID_not_starts_with?: Maybe<Scalars['String']>
  coldStoreID_ends_with?: Maybe<Scalars['String']>
  coldStoreID_not_ends_with?: Maybe<Scalars['String']>
  coldStoreID_in?: Maybe<Array<Maybe<Scalars['String']>>>
  coldStoreID_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  GLN?: Maybe<Scalars['String']>
  GLN_not?: Maybe<Scalars['String']>
  GLN_contains?: Maybe<Scalars['String']>
  GLN_not_contains?: Maybe<Scalars['String']>
  GLN_starts_with?: Maybe<Scalars['String']>
  GLN_not_starts_with?: Maybe<Scalars['String']>
  GLN_ends_with?: Maybe<Scalars['String']>
  GLN_not_ends_with?: Maybe<Scalars['String']>
  GLN_in?: Maybe<Array<Maybe<Scalars['String']>>>
  GLN_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  VATNumber?: Maybe<Scalars['String']>
  VATNumber_not?: Maybe<Scalars['String']>
  VATNumber_contains?: Maybe<Scalars['String']>
  VATNumber_not_contains?: Maybe<Scalars['String']>
  VATNumber_starts_with?: Maybe<Scalars['String']>
  VATNumber_not_starts_with?: Maybe<Scalars['String']>
  VATNumber_ends_with?: Maybe<Scalars['String']>
  VATNumber_not_ends_with?: Maybe<Scalars['String']>
  VATNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>
  VATNumber_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  productStatuses_some?: Maybe<ProductStatusFilter>
  productStatuses_none?: Maybe<ProductStatusFilter>
  productStatuses_all?: Maybe<ProductStatusFilter>
  productStatuses_any?: Maybe<Scalars['Boolean']>
  categories_some?: Maybe<CategoryDefinitionFilter>
  categories_none?: Maybe<CategoryDefinitionFilter>
  categories_all?: Maybe<CategoryDefinitionFilter>
  categories_any?: Maybe<Scalars['Boolean']>
  warnMoveDifStatus?: Maybe<Scalars['Boolean']>
  warnMoveDifStatus_not?: Maybe<Scalars['Boolean']>
  requireBudhol?: Maybe<Scalars['Boolean']>
  requireBudhol_not?: Maybe<Scalars['Boolean']>
  requireProdCategories?: Maybe<Scalars['Boolean']>
  requireProdCategories_not?: Maybe<Scalars['Boolean']>
  scanBarcodeMask?: Maybe<Scalars['String']>
  scanBarcodeMask_not?: Maybe<Scalars['String']>
  scanBarcodeMask_contains?: Maybe<Scalars['String']>
  scanBarcodeMask_not_contains?: Maybe<Scalars['String']>
  scanBarcodeMask_starts_with?: Maybe<Scalars['String']>
  scanBarcodeMask_not_starts_with?: Maybe<Scalars['String']>
  scanBarcodeMask_ends_with?: Maybe<Scalars['String']>
  scanBarcodeMask_not_ends_with?: Maybe<Scalars['String']>
  scanBarcodeMask_in?: Maybe<Array<Maybe<Scalars['String']>>>
  scanBarcodeMask_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  keyedBarcodeMask?: Maybe<Scalars['String']>
  keyedBarcodeMask_not?: Maybe<Scalars['String']>
  keyedBarcodeMask_contains?: Maybe<Scalars['String']>
  keyedBarcodeMask_not_contains?: Maybe<Scalars['String']>
  keyedBarcodeMask_starts_with?: Maybe<Scalars['String']>
  keyedBarcodeMask_not_starts_with?: Maybe<Scalars['String']>
  keyedBarcodeMask_ends_with?: Maybe<Scalars['String']>
  keyedBarcodeMask_not_ends_with?: Maybe<Scalars['String']>
  keyedBarcodeMask_in?: Maybe<Array<Maybe<Scalars['String']>>>
  keyedBarcodeMask_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  captureSerialsOnRcpt?: Maybe<Scalars['Boolean']>
  captureSerialsOnRcpt_not?: Maybe<Scalars['Boolean']>
  requirePalletID?: Maybe<Scalars['Boolean']>
  requirePalletID_not?: Maybe<Scalars['Boolean']>
  printPalLblsOnRcpt?: Maybe<Scalars['Boolean']>
  printPalLblsOnRcpt_not?: Maybe<Scalars['Boolean']>
  capturePONumOnRcpt?: Maybe<Scalars['Boolean']>
  capturePONumOnRcpt_not?: Maybe<Scalars['Boolean']>
  allocateLocOnRcpt?: Maybe<Scalars['Boolean']>
  allocateLocOnRcpt_not?: Maybe<Scalars['Boolean']>
  closeComPoOnRcpt?: Maybe<Scalars['Boolean']>
  closeComPoOnRcpt_not?: Maybe<Scalars['Boolean']>
  validateBatchPO?: Maybe<Scalars['Boolean']>
  validateBatchPO_not?: Maybe<Scalars['Boolean']>
  capturePkgInfoOnRcpt?: Maybe<Scalars['Boolean']>
  capturePkgInfoOnRcpt_not?: Maybe<Scalars['Boolean']>
  putwayLPOnRcpt?: Maybe<Scalars['Boolean']>
  putwayLPOnRcpt_not?: Maybe<Scalars['Boolean']>
  confirmPutawaysOnRcpt?: Maybe<Scalars['Boolean']>
  confirmPutawaysOnRcpt_not?: Maybe<Scalars['Boolean']>
  reqAuthOnGRClose?: Maybe<Scalars['Boolean']>
  reqAuthOnGRClose_not?: Maybe<Scalars['Boolean']>
  defaultUnbookedRcpt?: Maybe<Scalars['Boolean']>
  defaultUnbookedRcpt_not?: Maybe<Scalars['Boolean']>
  identicalPallets?: Maybe<Scalars['Boolean']>
  identicalPallets_not?: Maybe<Scalars['Boolean']>
  strictPODateVal?: Maybe<Scalars['Boolean']>
  strictPODateVal_not?: Maybe<Scalars['Boolean']>
  splitSuppTolerance?: Maybe<Scalars['Boolean']>
  splitSuppTolerance_not?: Maybe<Scalars['Boolean']>
  RVPutToDefLoc?: Maybe<Scalars['Boolean']>
  RVPutToDefLoc_not?: Maybe<Scalars['Boolean']>
  RFPutImmediately?: Maybe<Scalars['Boolean']>
  RFPutImmediately_not?: Maybe<Scalars['Boolean']>
  disablePutForSpecificLocs?: Maybe<Scalars['Boolean']>
  disablePutForSpecificLocs_not?: Maybe<Scalars['Boolean']>
  mandExtraInfoOnRcpt?: Maybe<Scalars['Boolean']>
  mandExtraInfoOnRcpt_not?: Maybe<Scalars['Boolean']>
  captureContainerType?: Maybe<Scalars['Boolean']>
  captureContainerType_not?: Maybe<Scalars['Boolean']>
  F2ShowBookedProdsOnly?: Maybe<Scalars['Boolean']>
  F2ShowBookedProdsOnly_not?: Maybe<Scalars['Boolean']>
  autoGeneratePONumber?: Maybe<Scalars['Boolean']>
  autoGeneratePONumber_not?: Maybe<Scalars['Boolean']>
  allowEditInRV?: Maybe<Scalars['Boolean']>
  allowEditInRV_not?: Maybe<Scalars['Boolean']>
  GRPrintCrtnLbls?: Maybe<Scalars['Boolean']>
  GRPrintCrtnLbls_not?: Maybe<Scalars['Boolean']>
  noBckFlushGRProduction?: Maybe<Scalars['Boolean']>
  noBckFlushGRProduction_not?: Maybe<Scalars['Boolean']>
  printLblsGRProduction?: Maybe<Scalars['Boolean']>
  printLblsGRProduction_not?: Maybe<Scalars['Boolean']>
  matchGRWithBookNo?: Maybe<Scalars['Boolean']>
  matchGRWithBookNo_not?: Maybe<Scalars['Boolean']>
  RVSuppressMergePF?: Maybe<Scalars['Boolean']>
  RVSuppressMergePF_not?: Maybe<Scalars['Boolean']>
  spltPOLinesOnPrint?: Maybe<Scalars['Boolean']>
  spltPOLinesOnPrint_not?: Maybe<Scalars['Boolean']>
  makeGRNBudhol?: Maybe<Scalars['Boolean']>
  makeGRNBudhol_not?: Maybe<Scalars['Boolean']>
  allowShortShelfLife?: Maybe<Scalars['Boolean']>
  allowShortShelfLife_not?: Maybe<Scalars['Boolean']>
  allowAddProdsToPO?: Maybe<Scalars['Boolean']>
  allowAddProdsToPO_not?: Maybe<Scalars['Boolean']>
  captureLocOnRcpt?: Maybe<Scalars['Boolean']>
  captureLocOnRcpt_not?: Maybe<Scalars['Boolean']>
  captureTempOnRcpt?: Maybe<Scalars['Boolean']>
  captureTempOnRcpt_not?: Maybe<Scalars['Boolean']>
  orderStatuses_some?: Maybe<OrderStatusFilter>
  orderStatuses_none?: Maybe<OrderStatusFilter>
  orderStatuses_all?: Maybe<OrderStatusFilter>
  orderStatuses_any?: Maybe<Scalars['Boolean']>
  autoGenOrderNum?: Maybe<Scalars['Boolean']>
  autoGenOrderNum_not?: Maybe<Scalars['Boolean']>
  dispOrderEntryErrs?: Maybe<Scalars['Boolean']>
  dispOrderEntryErrs_not?: Maybe<Scalars['Boolean']>
  requirePOD?: Maybe<Scalars['Boolean']>
  requirePOD_not?: Maybe<Scalars['Boolean']>
  printOrdConfRpt?: Maybe<Scalars['Boolean']>
  printOrdConfRpt_not?: Maybe<Scalars['Boolean']>
  createPickOnOrdEntry?: Maybe<Scalars['Boolean']>
  createPickOnOrdEntry_not?: Maybe<Scalars['Boolean']>
  dontClearLineCustRef?: Maybe<Scalars['Boolean']>
  dontClearLineCustRef_not?: Maybe<Scalars['Boolean']>
  mandLineCustref?: Maybe<Scalars['Boolean']>
  mandLineCustref_not?: Maybe<Scalars['Boolean']>
  warnOnShortage?: Maybe<Scalars['Boolean']>
  warnOnShortage_not?: Maybe<Scalars['Boolean']>
  printOrdRptWithPick?: Maybe<Scalars['Boolean']>
  printOrdRptWithPick_not?: Maybe<Scalars['Boolean']>
  printBoxPackLbls?: Maybe<Scalars['Boolean']>
  printBoxPackLbls_not?: Maybe<Scalars['Boolean']>
  GDPRControlled?: Maybe<Scalars['Boolean']>
  GDPRControlled_not?: Maybe<Scalars['Boolean']>
  forceBudholOnEntry?: Maybe<Scalars['Boolean']>
  forceBudholOnEntry_not?: Maybe<Scalars['Boolean']>
  preventBatchOnEntry?: Maybe<Scalars['Boolean']>
  preventBatchOnEntry_not?: Maybe<Scalars['Boolean']>
  swapGenericsOnEntry?: Maybe<Scalars['Boolean']>
  swapGenericsOnEntry_not?: Maybe<Scalars['Boolean']>
  forceDepartmentOnEntry?: Maybe<Scalars['Boolean']>
  forceDepartmentOnEntry_not?: Maybe<Scalars['Boolean']>
  pickMethods_some?: Maybe<PickMethodFilter>
  pickMethods_none?: Maybe<PickMethodFilter>
  pickMethods_all?: Maybe<PickMethodFilter>
  pickMethods_any?: Maybe<Scalars['Boolean']>
  cleanOrdersOnly?: Maybe<Scalars['Boolean']>
  cleanOrdersOnly_not?: Maybe<Scalars['Boolean']>
  createBackOrders?: Maybe<Scalars['Boolean']>
  createBackOrders_not?: Maybe<Scalars['Boolean']>
  allowShortageSwap?: Maybe<Scalars['Boolean']>
  allowShortageSwap_not?: Maybe<Scalars['Boolean']>
  allowBudholSwap?: Maybe<Scalars['Boolean']>
  allowBudholSwap_not?: Maybe<Scalars['Boolean']>
  chngeTraysOnPri?: Maybe<Scalars['Boolean']>
  chngeTraysOnPri_not?: Maybe<Scalars['Boolean']>
  noReplenPicksOnly?: Maybe<Scalars['Boolean']>
  noReplenPicksOnly_not?: Maybe<Scalars['Boolean']>
  allowStatusSwap?: Maybe<Scalars['Boolean']>
  allowStatusSwap_not?: Maybe<Scalars['Boolean']>
  pickCartons?: Maybe<Scalars['Boolean']>
  pickCartons_not?: Maybe<Scalars['Boolean']>
  MPNoSplitLists?: Maybe<Scalars['Boolean']>
  MPNoSplitLists_not?: Maybe<Scalars['Boolean']>
  showUnpickableRpt?: Maybe<Scalars['Boolean']>
  showUnpickableRpt_not?: Maybe<Scalars['Boolean']>
  allowLPSwap?: Maybe<Scalars['Boolean']>
  allowLPSwap_not?: Maybe<Scalars['Boolean']>
  allowSBDSwap?: Maybe<Scalars['Boolean']>
  allowSBDSwap_not?: Maybe<Scalars['Boolean']>
  findCartonByWgt?: Maybe<Scalars['Boolean']>
  findCartonByWgt_not?: Maybe<Scalars['Boolean']>
  captureCarrier?: Maybe<Scalars['Boolean']>
  captureCarrier_not?: Maybe<Scalars['Boolean']>
  allowLocSwap?: Maybe<Scalars['Boolean']>
  allowLocSwap_not?: Maybe<Scalars['Boolean']>
  useBOMPicking?: Maybe<Scalars['Boolean']>
  useBOMPicking_not?: Maybe<Scalars['Boolean']>
  requireDespNote?: Maybe<Scalars['Boolean']>
  requireDespNote_not?: Maybe<Scalars['Boolean']>
  RFPickAssignment?: Maybe<PickAssignment>
  RFPickAssignment_not?: Maybe<PickAssignment>
  RFPickAssignment_in?: Maybe<Array<Maybe<PickAssignment>>>
  RFPickAssignment_not_in?: Maybe<Array<Maybe<PickAssignment>>>
  RFPickAssignment_gt?: Maybe<PickAssignment>
  RFPickAssignment_not_gt?: Maybe<PickAssignment>
  RFPickAssignment_gte?: Maybe<PickAssignment>
  RFPickAssignment_not_gte?: Maybe<PickAssignment>
  RFPickAssignment_lt?: Maybe<PickAssignment>
  RFPickAssignment_not_lt?: Maybe<PickAssignment>
  RFPickAssignment_lte?: Maybe<PickAssignment>
  RFPickAssignment_not_lte?: Maybe<PickAssignment>
  voicePickAssignment?: Maybe<PickAssignment>
  voicePickAssignment_not?: Maybe<PickAssignment>
  voicePickAssignment_in?: Maybe<Array<Maybe<PickAssignment>>>
  voicePickAssignment_not_in?: Maybe<Array<Maybe<PickAssignment>>>
  voicePickAssignment_gt?: Maybe<PickAssignment>
  voicePickAssignment_not_gt?: Maybe<PickAssignment>
  voicePickAssignment_gte?: Maybe<PickAssignment>
  voicePickAssignment_not_gte?: Maybe<PickAssignment>
  voicePickAssignment_lt?: Maybe<PickAssignment>
  voicePickAssignment_not_lt?: Maybe<PickAssignment>
  voicePickAssignment_lte?: Maybe<PickAssignment>
  voicePickAssignment_not_lte?: Maybe<PickAssignment>
  allowBatchSwap?: Maybe<Scalars['Boolean']>
  allowBatchSwap_not?: Maybe<Scalars['Boolean']>
  requireConfProduct?: Maybe<Scalars['Boolean']>
  requireConfProduct_not?: Maybe<Scalars['Boolean']>
  requireUPICheck?: Maybe<Scalars['Boolean']>
  requireUPICheck_not?: Maybe<Scalars['Boolean']>
  cageValidation?: Maybe<Scalars['Boolean']>
  cageValidation_not?: Maybe<Scalars['Boolean']>
  despNoteValidation?: Maybe<Scalars['Boolean']>
  despNoteValidation_not?: Maybe<Scalars['Boolean']>
  suppressReplenOpt?: Maybe<Scalars['Boolean']>
  suppressReplenOpt_not?: Maybe<Scalars['Boolean']>
  useLoading?: Maybe<Scalars['Boolean']>
  useLoading_not?: Maybe<Scalars['Boolean']>
  produceRFManifest?: Maybe<Scalars['Boolean']>
  produceRFManifest_not?: Maybe<Scalars['Boolean']>
  requireSealNo?: Maybe<Scalars['Boolean']>
  requireSealNo_not?: Maybe<Scalars['Boolean']>
  requireTruck?: Maybe<Scalars['Boolean']>
  requireTruck_not?: Maybe<Scalars['Boolean']>
  requireDriver?: Maybe<Scalars['Boolean']>
  requireDriver_not?: Maybe<Scalars['Boolean']>
  useLoadOrder?: Maybe<Scalars['Boolean']>
  useLoadOrder_not?: Maybe<Scalars['Boolean']>
  loadOrder?: Maybe<LoadOrder>
  loadOrder_not?: Maybe<LoadOrder>
  loadOrder_in?: Maybe<Array<Maybe<LoadOrder>>>
  loadOrder_not_in?: Maybe<Array<Maybe<LoadOrder>>>
  loadOrder_gt?: Maybe<LoadOrder>
  loadOrder_not_gt?: Maybe<LoadOrder>
  loadOrder_gte?: Maybe<LoadOrder>
  loadOrder_not_gte?: Maybe<LoadOrder>
  loadOrder_lt?: Maybe<LoadOrder>
  loadOrder_not_lt?: Maybe<LoadOrder>
  loadOrder_lte?: Maybe<LoadOrder>
  loadOrder_not_lte?: Maybe<LoadOrder>
  despLane?: Maybe<Scalars['String']>
  despLane_not?: Maybe<Scalars['String']>
  despLane_contains?: Maybe<Scalars['String']>
  despLane_not_contains?: Maybe<Scalars['String']>
  despLane_starts_with?: Maybe<Scalars['String']>
  despLane_not_starts_with?: Maybe<Scalars['String']>
  despLane_ends_with?: Maybe<Scalars['String']>
  despLane_not_ends_with?: Maybe<Scalars['String']>
  despLane_in?: Maybe<Array<Maybe<Scalars['String']>>>
  despLane_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  maxLoaders?: Maybe<Scalars['Int']>
  maxLoaders_not?: Maybe<Scalars['Int']>
  maxLoaders_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  maxLoaders_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  maxLoaders_gt?: Maybe<Scalars['Int']>
  maxLoaders_not_gt?: Maybe<Scalars['Int']>
  maxLoaders_gte?: Maybe<Scalars['Int']>
  maxLoaders_not_gte?: Maybe<Scalars['Int']>
  maxLoaders_lt?: Maybe<Scalars['Int']>
  maxLoaders_not_lt?: Maybe<Scalars['Int']>
  maxLoaders_lte?: Maybe<Scalars['Int']>
  maxLoaders_not_lte?: Maybe<Scalars['Int']>
  adhocLoading?: Maybe<Accessiblity>
  adhocLoading_not?: Maybe<Accessiblity>
  adhocLoading_in?: Maybe<Array<Maybe<Accessiblity>>>
  adhocLoading_not_in?: Maybe<Array<Maybe<Accessiblity>>>
  adhocLoading_gt?: Maybe<Accessiblity>
  adhocLoading_not_gt?: Maybe<Accessiblity>
  adhocLoading_gte?: Maybe<Accessiblity>
  adhocLoading_not_gte?: Maybe<Accessiblity>
  adhocLoading_lt?: Maybe<Accessiblity>
  adhocLoading_not_lt?: Maybe<Accessiblity>
  adhocLoading_lte?: Maybe<Accessiblity>
  adhocLoading_not_lte?: Maybe<Accessiblity>
  loadTempCaptureStart?: Maybe<Scalars['Boolean']>
  loadTempCaptureStart_not?: Maybe<Scalars['Boolean']>
  loadTempCaptureEnd?: Maybe<Scalars['Boolean']>
  loadTempCaptureEnd_not?: Maybe<Scalars['Boolean']>
  loadTempLPInterval?: Maybe<Scalars['Boolean']>
  loadTempLPInterval_not?: Maybe<Scalars['Boolean']>
  confirmMethods_some?: Maybe<PickConfirmMethodFilter>
  confirmMethods_none?: Maybe<PickConfirmMethodFilter>
  confirmMethods_all?: Maybe<PickConfirmMethodFilter>
  confirmMethods_any?: Maybe<Scalars['Boolean']>
  captureTimeOnConfirm?: Maybe<FieldVisibility>
  captureTimeOnConfirm_not?: Maybe<FieldVisibility>
  captureTimeOnConfirm_in?: Maybe<Array<Maybe<FieldVisibility>>>
  captureTimeOnConfirm_not_in?: Maybe<Array<Maybe<FieldVisibility>>>
  captureTimeOnConfirm_gt?: Maybe<FieldVisibility>
  captureTimeOnConfirm_not_gt?: Maybe<FieldVisibility>
  captureTimeOnConfirm_gte?: Maybe<FieldVisibility>
  captureTimeOnConfirm_not_gte?: Maybe<FieldVisibility>
  captureTimeOnConfirm_lt?: Maybe<FieldVisibility>
  captureTimeOnConfirm_not_lt?: Maybe<FieldVisibility>
  captureTimeOnConfirm_lte?: Maybe<FieldVisibility>
  captureTimeOnConfirm_not_lte?: Maybe<FieldVisibility>
  captureCheckerOnConfirm?: Maybe<FieldVisibility>
  captureCheckerOnConfirm_not?: Maybe<FieldVisibility>
  captureCheckerOnConfirm_in?: Maybe<Array<Maybe<FieldVisibility>>>
  captureCheckerOnConfirm_not_in?: Maybe<Array<Maybe<FieldVisibility>>>
  captureCheckerOnConfirm_gt?: Maybe<FieldVisibility>
  captureCheckerOnConfirm_not_gt?: Maybe<FieldVisibility>
  captureCheckerOnConfirm_gte?: Maybe<FieldVisibility>
  captureCheckerOnConfirm_not_gte?: Maybe<FieldVisibility>
  captureCheckerOnConfirm_lt?: Maybe<FieldVisibility>
  captureCheckerOnConfirm_not_lt?: Maybe<FieldVisibility>
  captureCheckerOnConfirm_lte?: Maybe<FieldVisibility>
  captureCheckerOnConfirm_not_lte?: Maybe<FieldVisibility>
  capturePickerOnConfirm?: Maybe<FieldVisibility>
  capturePickerOnConfirm_not?: Maybe<FieldVisibility>
  capturePickerOnConfirm_in?: Maybe<Array<Maybe<FieldVisibility>>>
  capturePickerOnConfirm_not_in?: Maybe<Array<Maybe<FieldVisibility>>>
  capturePickerOnConfirm_gt?: Maybe<FieldVisibility>
  capturePickerOnConfirm_not_gt?: Maybe<FieldVisibility>
  capturePickerOnConfirm_gte?: Maybe<FieldVisibility>
  capturePickerOnConfirm_not_gte?: Maybe<FieldVisibility>
  capturePickerOnConfirm_lt?: Maybe<FieldVisibility>
  capturePickerOnConfirm_not_lt?: Maybe<FieldVisibility>
  capturePickerOnConfirm_lte?: Maybe<FieldVisibility>
  capturePickerOnConfirm_not_lte?: Maybe<FieldVisibility>
  defPickFailCode?: Maybe<Scalars['String']>
  defPickFailCode_not?: Maybe<Scalars['String']>
  defPickFailCode_contains?: Maybe<Scalars['String']>
  defPickFailCode_not_contains?: Maybe<Scalars['String']>
  defPickFailCode_starts_with?: Maybe<Scalars['String']>
  defPickFailCode_not_starts_with?: Maybe<Scalars['String']>
  defPickFailCode_ends_with?: Maybe<Scalars['String']>
  defPickFailCode_not_ends_with?: Maybe<Scalars['String']>
  defPickFailCode_in?: Maybe<Array<Maybe<Scalars['String']>>>
  defPickFailCode_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  requireDespatchLane?: Maybe<Scalars['Boolean']>
  requireDespatchLane_not?: Maybe<Scalars['Boolean']>
  useEmailFromDestination?: Maybe<Scalars['Boolean']>
  useEmailFromDestination_not?: Maybe<Scalars['Boolean']>
  reprntUnfinishedLists?: Maybe<Scalars['Boolean']>
  reprntUnfinishedLists_not?: Maybe<Scalars['Boolean']>
  destockShortOnSHort?: Maybe<Scalars['Boolean']>
  destockShortOnSHort_not?: Maybe<Scalars['Boolean']>
  proportionalBoxesOnConfirm?: Maybe<Scalars['Boolean']>
  proportionalBoxesOnConfirm_not?: Maybe<Scalars['Boolean']>
  allowLoadAnySeq?: Maybe<Scalars['Boolean']>
  allowLoadAnySeq_not?: Maybe<Scalars['Boolean']>
  MBPLPControl?: Maybe<Scalars['Boolean']>
  MBPLPControl_not?: Maybe<Scalars['Boolean']>
  MBPUseSODespMeth?: Maybe<Scalars['Boolean']>
  MBPUseSODespMeth_not?: Maybe<Scalars['Boolean']>
  noDespNotes?: Maybe<Scalars['Boolean']>
  noDespNotes_not?: Maybe<Scalars['Boolean']>
  previewDespNote?: Maybe<Scalars['Boolean']>
  previewDespNote_not?: Maybe<Scalars['Boolean']>
  BOMSToStock?: Maybe<Scalars['Boolean']>
  BOMSToStock_not?: Maybe<Scalars['Boolean']>
  delayDespatch?: Maybe<Scalars['Boolean']>
  delayDespatch_not?: Maybe<Scalars['Boolean']>
  RFWarnInPckConf?: Maybe<Scalars['Boolean']>
  RFWarnInPckConf_not?: Maybe<Scalars['Boolean']>
  noReplenPckConf?: Maybe<Scalars['Boolean']>
  noReplenPckConf_not?: Maybe<Scalars['Boolean']>
  despNotePerList?: Maybe<Scalars['Boolean']>
  despNotePerList_not?: Maybe<Scalars['Boolean']>
  printBoxLbls?: Maybe<Scalars['Boolean']>
  printBoxLbls_not?: Maybe<Scalars['Boolean']>
  disablImmDesp?: Maybe<Scalars['Boolean']>
  disablImmDesp_not?: Maybe<Scalars['Boolean']>
  allowFutureDespDates?: Maybe<Scalars['Boolean']>
  allowFutureDespDates_not?: Maybe<Scalars['Boolean']>
  MBPCaptureConsNo?: Maybe<Scalars['Boolean']>
  MBPCaptureConsNo_not?: Maybe<Scalars['Boolean']>
  optConsNo?: Maybe<Scalars['Boolean']>
  optConsNo_not?: Maybe<Scalars['Boolean']>
  boxLblsPickConf?: Maybe<Scalars['Boolean']>
  boxLblsPickConf_not?: Maybe<Scalars['Boolean']>
  useLPIdsPickConfirm?: Maybe<Scalars['Boolean']>
  useLPIdsPickConfirm_not?: Maybe<Scalars['Boolean']>
  authMBP?: Maybe<Scalars['Boolean']>
  authMBP_not?: Maybe<Scalars['Boolean']>
  mandContainerType?: Maybe<Scalars['Boolean']>
  mandContainerType_not?: Maybe<Scalars['Boolean']>
  PDFDespNotes?: Maybe<Scalars['Boolean']>
  PDFDespNotes_not?: Maybe<Scalars['Boolean']>
  useDespPrefix?: Maybe<Scalars['Boolean']>
  useDespPrefix_not?: Maybe<Scalars['Boolean']>
  noOfDespNotes?: Maybe<Scalars['Boolean']>
  noOfDespNotes_not?: Maybe<Scalars['Boolean']>
  confWholePick?: Maybe<Scalars['Boolean']>
  confWholePick_not?: Maybe<Scalars['Boolean']>
  capReasonDelayDesp?: Maybe<Scalars['Boolean']>
  capReasonDelayDesp_not?: Maybe<Scalars['Boolean']>
  MBPDespNoteBreakdwn?: Maybe<Scalars['Boolean']>
  MBPDespNoteBreakdwn_not?: Maybe<Scalars['Boolean']>
  dontCloseOrderOnLblFail?: Maybe<Scalars['Boolean']>
  dontCloseOrderOnLblFail_not?: Maybe<Scalars['Boolean']>
  capBoxesPerOrder?: Maybe<Scalars['Boolean']>
  capBoxesPerOrder_not?: Maybe<Scalars['Boolean']>
  carrierNotReq?: Maybe<Scalars['Boolean']>
  carrierNotReq_not?: Maybe<Scalars['Boolean']>
  noCarrLblsPckConf?: Maybe<Scalars['Boolean']>
  noCarrLblsPckConf_not?: Maybe<Scalars['Boolean']>
  copyCarrToBckOrd?: Maybe<Scalars['Boolean']>
  copyCarrToBckOrd_not?: Maybe<Scalars['Boolean']>
}

export type StockOwnerFilterInput = {
  and?: Maybe<Array<StockOwnerFilterInput>>
  or?: Maybe<Array<StockOwnerFilterInput>>
  id?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  code?: Maybe<StringOperationFilterInput>
  name?: Maybe<StringOperationFilterInput>
  status?: Maybe<NullableOfStateOperationFilterInput>
  linkedStockOwners?: Maybe<ListFilterInputTypeOfStockOwnerLinkFilterInput>
  contact?: Maybe<ContactFilterInput>
  address?: Maybe<AddressFilterInput>
  coldStoreID?: Maybe<StringOperationFilterInput>
  GLN?: Maybe<StringOperationFilterInput>
  VATNumber?: Maybe<StringOperationFilterInput>
  productStatuses?: Maybe<ListFilterInputTypeOfProductStatusFilterInput>
  categories?: Maybe<ListFilterInputTypeOfCategoryDefinitionFilterInput>
  warnMoveDifStatus?: Maybe<BooleanOperationFilterInput>
  requireBudhol?: Maybe<BooleanOperationFilterInput>
  requireProdCategories?: Maybe<BooleanOperationFilterInput>
  scanBarcodeMask?: Maybe<StringOperationFilterInput>
  keyedBarcodeMask?: Maybe<StringOperationFilterInput>
  captureSerialsOnRcpt?: Maybe<BooleanOperationFilterInput>
  requirePalletID?: Maybe<BooleanOperationFilterInput>
  printPalLblsOnRcpt?: Maybe<BooleanOperationFilterInput>
  capturePONumOnRcpt?: Maybe<BooleanOperationFilterInput>
  allocateLocOnRcpt?: Maybe<BooleanOperationFilterInput>
  closeComPoOnRcpt?: Maybe<BooleanOperationFilterInput>
  validateBatchPO?: Maybe<BooleanOperationFilterInput>
  capturePkgInfoOnRcpt?: Maybe<BooleanOperationFilterInput>
  putwayLPOnRcpt?: Maybe<BooleanOperationFilterInput>
  confirmPutawaysOnRcpt?: Maybe<BooleanOperationFilterInput>
  reqAuthOnGRClose?: Maybe<BooleanOperationFilterInput>
  defaultUnbookedRcpt?: Maybe<BooleanOperationFilterInput>
  identicalPallets?: Maybe<BooleanOperationFilterInput>
  strictPODateVal?: Maybe<BooleanOperationFilterInput>
  splitSuppTolerance?: Maybe<BooleanOperationFilterInput>
  RVPutToDefLoc?: Maybe<BooleanOperationFilterInput>
  RFPutImmediately?: Maybe<BooleanOperationFilterInput>
  disablePutForSpecificLocs?: Maybe<BooleanOperationFilterInput>
  mandExtraInfoOnRcpt?: Maybe<BooleanOperationFilterInput>
  captureContainerType?: Maybe<BooleanOperationFilterInput>
  F2ShowBookedProdsOnly?: Maybe<BooleanOperationFilterInput>
  autoGeneratePONumber?: Maybe<BooleanOperationFilterInput>
  allowEditInRV?: Maybe<BooleanOperationFilterInput>
  GRPrintCrtnLbls?: Maybe<BooleanOperationFilterInput>
  noBckFlushGRProduction?: Maybe<BooleanOperationFilterInput>
  printLblsGRProduction?: Maybe<BooleanOperationFilterInput>
  matchGRWithBookNo?: Maybe<BooleanOperationFilterInput>
  RVSuppressMergePF?: Maybe<BooleanOperationFilterInput>
  spltPOLinesOnPrint?: Maybe<BooleanOperationFilterInput>
  makeGRNBudhol?: Maybe<BooleanOperationFilterInput>
  allowShortShelfLife?: Maybe<BooleanOperationFilterInput>
  allowAddProdsToPO?: Maybe<BooleanOperationFilterInput>
  captureLocOnRcpt?: Maybe<BooleanOperationFilterInput>
  captureTempOnRcpt?: Maybe<BooleanOperationFilterInput>
  orderStatuses?: Maybe<ListFilterInputTypeOfOrderStatusFilterInput>
  autoGenOrderNum?: Maybe<BooleanOperationFilterInput>
  dispOrderEntryErrs?: Maybe<BooleanOperationFilterInput>
  requirePOD?: Maybe<BooleanOperationFilterInput>
  printOrdConfRpt?: Maybe<BooleanOperationFilterInput>
  createPickOnOrdEntry?: Maybe<BooleanOperationFilterInput>
  dontClearLineCustRef?: Maybe<BooleanOperationFilterInput>
  mandLineCustref?: Maybe<BooleanOperationFilterInput>
  warnOnShortage?: Maybe<BooleanOperationFilterInput>
  printOrdRptWithPick?: Maybe<BooleanOperationFilterInput>
  printBoxPackLbls?: Maybe<BooleanOperationFilterInput>
  GDPRControlled?: Maybe<BooleanOperationFilterInput>
  forceBudholOnEntry?: Maybe<BooleanOperationFilterInput>
  preventBatchOnEntry?: Maybe<BooleanOperationFilterInput>
  swapGenericsOnEntry?: Maybe<BooleanOperationFilterInput>
  forceDepartmentOnEntry?: Maybe<BooleanOperationFilterInput>
  pickMethods?: Maybe<ListFilterInputTypeOfPickMethodFilterInput>
  cleanOrdersOnly?: Maybe<BooleanOperationFilterInput>
  createBackOrders?: Maybe<BooleanOperationFilterInput>
  allowShortageSwap?: Maybe<BooleanOperationFilterInput>
  allowBudholSwap?: Maybe<BooleanOperationFilterInput>
  chngeTraysOnPri?: Maybe<BooleanOperationFilterInput>
  noReplenPicksOnly?: Maybe<BooleanOperationFilterInput>
  allowStatusSwap?: Maybe<BooleanOperationFilterInput>
  pickCartons?: Maybe<BooleanOperationFilterInput>
  MPNoSplitLists?: Maybe<BooleanOperationFilterInput>
  showUnpickableRpt?: Maybe<BooleanOperationFilterInput>
  allowLPSwap?: Maybe<BooleanOperationFilterInput>
  allowSBDSwap?: Maybe<BooleanOperationFilterInput>
  findCartonByWgt?: Maybe<BooleanOperationFilterInput>
  captureCarrier?: Maybe<BooleanOperationFilterInput>
  allowLocSwap?: Maybe<BooleanOperationFilterInput>
  useBOMPicking?: Maybe<BooleanOperationFilterInput>
  requireDespNote?: Maybe<BooleanOperationFilterInput>
  RFPickAssignment?: Maybe<NullableOfPickAssignmentOperationFilterInput>
  voicePickAssignment?: Maybe<NullableOfPickAssignmentOperationFilterInput>
  allowBatchSwap?: Maybe<BooleanOperationFilterInput>
  requireConfProduct?: Maybe<BooleanOperationFilterInput>
  requireUPICheck?: Maybe<BooleanOperationFilterInput>
  cageValidation?: Maybe<BooleanOperationFilterInput>
  despNoteValidation?: Maybe<BooleanOperationFilterInput>
  suppressReplenOpt?: Maybe<BooleanOperationFilterInput>
  useLoading?: Maybe<BooleanOperationFilterInput>
  produceRFManifest?: Maybe<BooleanOperationFilterInput>
  requireSealNo?: Maybe<BooleanOperationFilterInput>
  requireTruck?: Maybe<BooleanOperationFilterInput>
  requireDriver?: Maybe<BooleanOperationFilterInput>
  useLoadOrder?: Maybe<BooleanOperationFilterInput>
  loadOrder?: Maybe<NullableOfLoadOrderOperationFilterInput>
  despLane?: Maybe<StringOperationFilterInput>
  maxLoaders?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  adhocLoading?: Maybe<NullableOfAccessiblityOperationFilterInput>
  loadTempCaptureStart?: Maybe<BooleanOperationFilterInput>
  loadTempCaptureEnd?: Maybe<BooleanOperationFilterInput>
  loadTempLPInterval?: Maybe<BooleanOperationFilterInput>
  confirmMethods?: Maybe<ListFilterInputTypeOfPickConfirmMethodFilterInput>
  captureTimeOnConfirm?: Maybe<NullableOfFieldVisibilityOperationFilterInput>
  captureCheckerOnConfirm?: Maybe<NullableOfFieldVisibilityOperationFilterInput>
  capturePickerOnConfirm?: Maybe<NullableOfFieldVisibilityOperationFilterInput>
  defPickFailCode?: Maybe<StringOperationFilterInput>
  requireDespatchLane?: Maybe<BooleanOperationFilterInput>
  useEmailFromDestination?: Maybe<BooleanOperationFilterInput>
  reprntUnfinishedLists?: Maybe<BooleanOperationFilterInput>
  destockShortOnSHort?: Maybe<BooleanOperationFilterInput>
  proportionalBoxesOnConfirm?: Maybe<BooleanOperationFilterInput>
  allowLoadAnySeq?: Maybe<BooleanOperationFilterInput>
  MBPLPControl?: Maybe<BooleanOperationFilterInput>
  MBPUseSODespMeth?: Maybe<BooleanOperationFilterInput>
  noDespNotes?: Maybe<BooleanOperationFilterInput>
  previewDespNote?: Maybe<BooleanOperationFilterInput>
  BOMSToStock?: Maybe<BooleanOperationFilterInput>
  delayDespatch?: Maybe<BooleanOperationFilterInput>
  RFWarnInPckConf?: Maybe<BooleanOperationFilterInput>
  noReplenPckConf?: Maybe<BooleanOperationFilterInput>
  despNotePerList?: Maybe<BooleanOperationFilterInput>
  printBoxLbls?: Maybe<BooleanOperationFilterInput>
  disablImmDesp?: Maybe<BooleanOperationFilterInput>
  allowFutureDespDates?: Maybe<BooleanOperationFilterInput>
  MBPCaptureConsNo?: Maybe<BooleanOperationFilterInput>
  optConsNo?: Maybe<BooleanOperationFilterInput>
  boxLblsPickConf?: Maybe<BooleanOperationFilterInput>
  useLPIdsPickConfirm?: Maybe<BooleanOperationFilterInput>
  authMBP?: Maybe<BooleanOperationFilterInput>
  mandContainerType?: Maybe<BooleanOperationFilterInput>
  PDFDespNotes?: Maybe<BooleanOperationFilterInput>
  useDespPrefix?: Maybe<BooleanOperationFilterInput>
  noOfDespNotes?: Maybe<BooleanOperationFilterInput>
  confWholePick?: Maybe<BooleanOperationFilterInput>
  capReasonDelayDesp?: Maybe<BooleanOperationFilterInput>
  MBPDespNoteBreakdwn?: Maybe<BooleanOperationFilterInput>
  dontCloseOrderOnLblFail?: Maybe<BooleanOperationFilterInput>
  capBoxesPerOrder?: Maybe<BooleanOperationFilterInput>
  carrierNotReq?: Maybe<BooleanOperationFilterInput>
  noCarrLblsPckConf?: Maybe<BooleanOperationFilterInput>
  copyCarrToBckOrd?: Maybe<BooleanOperationFilterInput>
}

export type StockOwnerInput = {
  id?: Maybe<Scalars['Int']>
  code: Scalars['String']
  name: Scalars['String']
  status: State
  linkedStockOwners?: Maybe<Array<Maybe<StockOwnerLinkInput>>>
  contact?: Maybe<ContactInput>
  address?: Maybe<AddressInput>
  coldStoreID?: Maybe<Scalars['String']>
  GLN?: Maybe<Scalars['String']>
  VATNumber?: Maybe<Scalars['String']>
  productStatuses?: Maybe<Array<Maybe<ProductStatusInput>>>
  categories?: Maybe<Array<Maybe<CategoryDefinitionInput>>>
  warnMoveDifStatus: Scalars['Boolean']
  requireBudhol: Scalars['Boolean']
  requireProdCategories: Scalars['Boolean']
  scanBarcodeMask?: Maybe<Scalars['String']>
  keyedBarcodeMask?: Maybe<Scalars['String']>
  captureSerialsOnRcpt: Scalars['Boolean']
  requirePalletID: Scalars['Boolean']
  printPalLblsOnRcpt: Scalars['Boolean']
  capturePONumOnRcpt: Scalars['Boolean']
  allocateLocOnRcpt: Scalars['Boolean']
  closeComPoOnRcpt: Scalars['Boolean']
  validateBatchPO: Scalars['Boolean']
  capturePkgInfoOnRcpt: Scalars['Boolean']
  putwayLPOnRcpt: Scalars['Boolean']
  confirmPutawaysOnRcpt: Scalars['Boolean']
  reqAuthOnGRClose: Scalars['Boolean']
  defaultUnbookedRcpt: Scalars['Boolean']
  identicalPallets: Scalars['Boolean']
  strictPODateVal: Scalars['Boolean']
  splitSuppTolerance: Scalars['Boolean']
  RVPutToDefLoc: Scalars['Boolean']
  RFPutImmediately: Scalars['Boolean']
  disablePutForSpecificLocs: Scalars['Boolean']
  mandExtraInfoOnRcpt: Scalars['Boolean']
  captureContainerType: Scalars['Boolean']
  F2ShowBookedProdsOnly: Scalars['Boolean']
  autoGeneratePONumber: Scalars['Boolean']
  allowEditInRV: Scalars['Boolean']
  GRPrintCrtnLbls: Scalars['Boolean']
  noBckFlushGRProduction: Scalars['Boolean']
  printLblsGRProduction: Scalars['Boolean']
  matchGRWithBookNo: Scalars['Boolean']
  RVSuppressMergePF: Scalars['Boolean']
  spltPOLinesOnPrint: Scalars['Boolean']
  makeGRNBudhol: Scalars['Boolean']
  allowShortShelfLife: Scalars['Boolean']
  allowAddProdsToPO: Scalars['Boolean']
  captureLocOnRcpt: Scalars['Boolean']
  captureTempOnRcpt: Scalars['Boolean']
  orderStatuses?: Maybe<Array<Maybe<OrderStatusInput>>>
  autoGenOrderNum: Scalars['Boolean']
  dispOrderEntryErrs: Scalars['Boolean']
  requirePOD: Scalars['Boolean']
  printOrdConfRpt: Scalars['Boolean']
  createPickOnOrdEntry: Scalars['Boolean']
  dontClearLineCustRef: Scalars['Boolean']
  mandLineCustref: Scalars['Boolean']
  warnOnShortage: Scalars['Boolean']
  printOrdRptWithPick: Scalars['Boolean']
  printBoxPackLbls: Scalars['Boolean']
  GDPRControlled: Scalars['Boolean']
  forceBudholOnEntry: Scalars['Boolean']
  preventBatchOnEntry: Scalars['Boolean']
  swapGenericsOnEntry: Scalars['Boolean']
  forceDepartmentOnEntry: Scalars['Boolean']
  pickMethods?: Maybe<Array<Maybe<PickMethodInput>>>
  cleanOrdersOnly: Scalars['Boolean']
  createBackOrders: Scalars['Boolean']
  allowShortageSwap: Scalars['Boolean']
  allowBudholSwap: Scalars['Boolean']
  chngeTraysOnPri: Scalars['Boolean']
  noReplenPicksOnly: Scalars['Boolean']
  allowStatusSwap: Scalars['Boolean']
  pickCartons: Scalars['Boolean']
  MPNoSplitLists: Scalars['Boolean']
  showUnpickableRpt: Scalars['Boolean']
  allowLPSwap: Scalars['Boolean']
  allowSBDSwap: Scalars['Boolean']
  findCartonByWgt: Scalars['Boolean']
  captureCarrier: Scalars['Boolean']
  allowLocSwap: Scalars['Boolean']
  useBOMPicking: Scalars['Boolean']
  requireDespNote: Scalars['Boolean']
  RFPickAssignment?: Maybe<PickAssignment>
  voicePickAssignment?: Maybe<PickAssignment>
  allowBatchSwap: Scalars['Boolean']
  requireConfProduct: Scalars['Boolean']
  requireUPICheck: Scalars['Boolean']
  cageValidation: Scalars['Boolean']
  despNoteValidation: Scalars['Boolean']
  suppressReplenOpt: Scalars['Boolean']
  useLoading: Scalars['Boolean']
  produceRFManifest: Scalars['Boolean']
  requireSealNo: Scalars['Boolean']
  requireTruck: Scalars['Boolean']
  requireDriver: Scalars['Boolean']
  useLoadOrder: Scalars['Boolean']
  loadOrder?: Maybe<LoadOrder>
  despLane?: Maybe<Scalars['String']>
  maxLoaders?: Maybe<Scalars['Int']>
  adhocLoading?: Maybe<Accessiblity>
  loadTempCaptureStart: Scalars['Boolean']
  loadTempCaptureEnd: Scalars['Boolean']
  loadTempLPInterval: Scalars['Boolean']
  confirmMethods?: Maybe<Array<Maybe<PickConfirmMethodInput>>>
  captureTimeOnConfirm?: Maybe<FieldVisibility>
  captureCheckerOnConfirm?: Maybe<FieldVisibility>
  capturePickerOnConfirm?: Maybe<FieldVisibility>
  defPickFailCode?: Maybe<Scalars['String']>
  requireDespatchLane: Scalars['Boolean']
  useEmailFromDestination: Scalars['Boolean']
  reprntUnfinishedLists: Scalars['Boolean']
  destockShortOnSHort: Scalars['Boolean']
  proportionalBoxesOnConfirm: Scalars['Boolean']
  allowLoadAnySeq: Scalars['Boolean']
  MBPLPControl: Scalars['Boolean']
  MBPUseSODespMeth: Scalars['Boolean']
  noDespNotes: Scalars['Boolean']
  previewDespNote: Scalars['Boolean']
  BOMSToStock: Scalars['Boolean']
  delayDespatch: Scalars['Boolean']
  RFWarnInPckConf: Scalars['Boolean']
  noReplenPckConf: Scalars['Boolean']
  despNotePerList: Scalars['Boolean']
  printBoxLbls: Scalars['Boolean']
  disablImmDesp: Scalars['Boolean']
  allowFutureDespDates: Scalars['Boolean']
  MBPCaptureConsNo: Scalars['Boolean']
  optConsNo: Scalars['Boolean']
  boxLblsPickConf: Scalars['Boolean']
  useLPIdsPickConfirm: Scalars['Boolean']
  authMBP: Scalars['Boolean']
  mandContainerType: Scalars['Boolean']
  PDFDespNotes: Scalars['Boolean']
  useDespPrefix: Scalars['Boolean']
  noOfDespNotes: Scalars['Boolean']
  confWholePick: Scalars['Boolean']
  capReasonDelayDesp: Scalars['Boolean']
  MBPDespNoteBreakdwn: Scalars['Boolean']
  dontCloseOrderOnLblFail: Scalars['Boolean']
  capBoxesPerOrder: Scalars['Boolean']
  carrierNotReq: Scalars['Boolean']
  noCarrLblsPckConf: Scalars['Boolean']
  copyCarrToBckOrd: Scalars['Boolean']
}

export type StockOwnerLink = {
  __typename?: 'StockOwnerLink'
  parent?: Maybe<StockOwner>
  child?: Maybe<StockOwner>
}

export type StockOwnerLinkFilter = {
  AND?: Maybe<Array<StockOwnerLinkFilter>>
  OR?: Maybe<Array<StockOwnerLinkFilter>>
  parent?: Maybe<StockOwnerFilter>
  child?: Maybe<StockOwnerFilter>
}

export type StockOwnerLinkFilterInput = {
  and?: Maybe<Array<StockOwnerLinkFilterInput>>
  or?: Maybe<Array<StockOwnerLinkFilterInput>>
  parent?: Maybe<StockOwnerFilterInput>
  child?: Maybe<StockOwnerFilterInput>
}

export type StockOwnerLinkInput = {
  parent?: Maybe<StockOwnerInput>
  child?: Maybe<StockOwnerInput>
}

export type StockOwnerSort = {
  id?: Maybe<SortOperationKind>
  code?: Maybe<SortOperationKind>
  name?: Maybe<SortOperationKind>
  status?: Maybe<SortOperationKind>
  contact?: Maybe<ContactSort>
  address?: Maybe<AddressSort>
  coldStoreID?: Maybe<SortOperationKind>
  GLN?: Maybe<SortOperationKind>
  VATNumber?: Maybe<SortOperationKind>
  warnMoveDifStatus?: Maybe<SortOperationKind>
  requireBudhol?: Maybe<SortOperationKind>
  requireProdCategories?: Maybe<SortOperationKind>
  scanBarcodeMask?: Maybe<SortOperationKind>
  keyedBarcodeMask?: Maybe<SortOperationKind>
  captureSerialsOnRcpt?: Maybe<SortOperationKind>
  requirePalletID?: Maybe<SortOperationKind>
  printPalLblsOnRcpt?: Maybe<SortOperationKind>
  capturePONumOnRcpt?: Maybe<SortOperationKind>
  allocateLocOnRcpt?: Maybe<SortOperationKind>
  closeComPoOnRcpt?: Maybe<SortOperationKind>
  validateBatchPO?: Maybe<SortOperationKind>
  capturePkgInfoOnRcpt?: Maybe<SortOperationKind>
  putwayLPOnRcpt?: Maybe<SortOperationKind>
  confirmPutawaysOnRcpt?: Maybe<SortOperationKind>
  reqAuthOnGRClose?: Maybe<SortOperationKind>
  defaultUnbookedRcpt?: Maybe<SortOperationKind>
  identicalPallets?: Maybe<SortOperationKind>
  strictPODateVal?: Maybe<SortOperationKind>
  splitSuppTolerance?: Maybe<SortOperationKind>
  RVPutToDefLoc?: Maybe<SortOperationKind>
  RFPutImmediately?: Maybe<SortOperationKind>
  disablePutForSpecificLocs?: Maybe<SortOperationKind>
  mandExtraInfoOnRcpt?: Maybe<SortOperationKind>
  captureContainerType?: Maybe<SortOperationKind>
  F2ShowBookedProdsOnly?: Maybe<SortOperationKind>
  autoGeneratePONumber?: Maybe<SortOperationKind>
  allowEditInRV?: Maybe<SortOperationKind>
  GRPrintCrtnLbls?: Maybe<SortOperationKind>
  noBckFlushGRProduction?: Maybe<SortOperationKind>
  printLblsGRProduction?: Maybe<SortOperationKind>
  matchGRWithBookNo?: Maybe<SortOperationKind>
  RVSuppressMergePF?: Maybe<SortOperationKind>
  spltPOLinesOnPrint?: Maybe<SortOperationKind>
  makeGRNBudhol?: Maybe<SortOperationKind>
  allowShortShelfLife?: Maybe<SortOperationKind>
  allowAddProdsToPO?: Maybe<SortOperationKind>
  captureLocOnRcpt?: Maybe<SortOperationKind>
  captureTempOnRcpt?: Maybe<SortOperationKind>
  autoGenOrderNum?: Maybe<SortOperationKind>
  dispOrderEntryErrs?: Maybe<SortOperationKind>
  requirePOD?: Maybe<SortOperationKind>
  printOrdConfRpt?: Maybe<SortOperationKind>
  createPickOnOrdEntry?: Maybe<SortOperationKind>
  dontClearLineCustRef?: Maybe<SortOperationKind>
  mandLineCustref?: Maybe<SortOperationKind>
  warnOnShortage?: Maybe<SortOperationKind>
  printOrdRptWithPick?: Maybe<SortOperationKind>
  printBoxPackLbls?: Maybe<SortOperationKind>
  GDPRControlled?: Maybe<SortOperationKind>
  forceBudholOnEntry?: Maybe<SortOperationKind>
  preventBatchOnEntry?: Maybe<SortOperationKind>
  swapGenericsOnEntry?: Maybe<SortOperationKind>
  forceDepartmentOnEntry?: Maybe<SortOperationKind>
  cleanOrdersOnly?: Maybe<SortOperationKind>
  createBackOrders?: Maybe<SortOperationKind>
  allowShortageSwap?: Maybe<SortOperationKind>
  allowBudholSwap?: Maybe<SortOperationKind>
  chngeTraysOnPri?: Maybe<SortOperationKind>
  noReplenPicksOnly?: Maybe<SortOperationKind>
  allowStatusSwap?: Maybe<SortOperationKind>
  pickCartons?: Maybe<SortOperationKind>
  MPNoSplitLists?: Maybe<SortOperationKind>
  showUnpickableRpt?: Maybe<SortOperationKind>
  allowLPSwap?: Maybe<SortOperationKind>
  allowSBDSwap?: Maybe<SortOperationKind>
  findCartonByWgt?: Maybe<SortOperationKind>
  captureCarrier?: Maybe<SortOperationKind>
  allowLocSwap?: Maybe<SortOperationKind>
  useBOMPicking?: Maybe<SortOperationKind>
  requireDespNote?: Maybe<SortOperationKind>
  RFPickAssignment?: Maybe<SortOperationKind>
  voicePickAssignment?: Maybe<SortOperationKind>
  allowBatchSwap?: Maybe<SortOperationKind>
  requireConfProduct?: Maybe<SortOperationKind>
  requireUPICheck?: Maybe<SortOperationKind>
  cageValidation?: Maybe<SortOperationKind>
  despNoteValidation?: Maybe<SortOperationKind>
  suppressReplenOpt?: Maybe<SortOperationKind>
  useLoading?: Maybe<SortOperationKind>
  produceRFManifest?: Maybe<SortOperationKind>
  requireSealNo?: Maybe<SortOperationKind>
  requireTruck?: Maybe<SortOperationKind>
  requireDriver?: Maybe<SortOperationKind>
  useLoadOrder?: Maybe<SortOperationKind>
  loadOrder?: Maybe<SortOperationKind>
  despLane?: Maybe<SortOperationKind>
  maxLoaders?: Maybe<SortOperationKind>
  adhocLoading?: Maybe<SortOperationKind>
  loadTempCaptureStart?: Maybe<SortOperationKind>
  loadTempCaptureEnd?: Maybe<SortOperationKind>
  loadTempLPInterval?: Maybe<SortOperationKind>
  captureTimeOnConfirm?: Maybe<SortOperationKind>
  captureCheckerOnConfirm?: Maybe<SortOperationKind>
  capturePickerOnConfirm?: Maybe<SortOperationKind>
  defPickFailCode?: Maybe<SortOperationKind>
  requireDespatchLane?: Maybe<SortOperationKind>
  useEmailFromDestination?: Maybe<SortOperationKind>
  reprntUnfinishedLists?: Maybe<SortOperationKind>
  destockShortOnSHort?: Maybe<SortOperationKind>
  proportionalBoxesOnConfirm?: Maybe<SortOperationKind>
  allowLoadAnySeq?: Maybe<SortOperationKind>
  MBPLPControl?: Maybe<SortOperationKind>
  MBPUseSODespMeth?: Maybe<SortOperationKind>
  noDespNotes?: Maybe<SortOperationKind>
  previewDespNote?: Maybe<SortOperationKind>
  BOMSToStock?: Maybe<SortOperationKind>
  delayDespatch?: Maybe<SortOperationKind>
  RFWarnInPckConf?: Maybe<SortOperationKind>
  noReplenPckConf?: Maybe<SortOperationKind>
  despNotePerList?: Maybe<SortOperationKind>
  printBoxLbls?: Maybe<SortOperationKind>
  disablImmDesp?: Maybe<SortOperationKind>
  allowFutureDespDates?: Maybe<SortOperationKind>
  MBPCaptureConsNo?: Maybe<SortOperationKind>
  optConsNo?: Maybe<SortOperationKind>
  boxLblsPickConf?: Maybe<SortOperationKind>
  useLPIdsPickConfirm?: Maybe<SortOperationKind>
  authMBP?: Maybe<SortOperationKind>
  mandContainerType?: Maybe<SortOperationKind>
  PDFDespNotes?: Maybe<SortOperationKind>
  useDespPrefix?: Maybe<SortOperationKind>
  noOfDespNotes?: Maybe<SortOperationKind>
  confWholePick?: Maybe<SortOperationKind>
  capReasonDelayDesp?: Maybe<SortOperationKind>
  MBPDespNoteBreakdwn?: Maybe<SortOperationKind>
  dontCloseOrderOnLblFail?: Maybe<SortOperationKind>
  capBoxesPerOrder?: Maybe<SortOperationKind>
  carrierNotReq?: Maybe<SortOperationKind>
  noCarrLblsPckConf?: Maybe<SortOperationKind>
  copyCarrToBckOrd?: Maybe<SortOperationKind>
}

export type StockOwnerSortInput = {
  id?: Maybe<SortEnumType>
  code?: Maybe<SortEnumType>
  name?: Maybe<SortEnumType>
  status?: Maybe<SortEnumType>
  contact?: Maybe<ContactSortInput>
  address?: Maybe<AddressSortInput>
  coldStoreID?: Maybe<SortEnumType>
  GLN?: Maybe<SortEnumType>
  VATNumber?: Maybe<SortEnumType>
  warnMoveDifStatus?: Maybe<SortEnumType>
  requireBudhol?: Maybe<SortEnumType>
  requireProdCategories?: Maybe<SortEnumType>
  scanBarcodeMask?: Maybe<SortEnumType>
  keyedBarcodeMask?: Maybe<SortEnumType>
  captureSerialsOnRcpt?: Maybe<SortEnumType>
  requirePalletID?: Maybe<SortEnumType>
  printPalLblsOnRcpt?: Maybe<SortEnumType>
  capturePONumOnRcpt?: Maybe<SortEnumType>
  allocateLocOnRcpt?: Maybe<SortEnumType>
  closeComPoOnRcpt?: Maybe<SortEnumType>
  validateBatchPO?: Maybe<SortEnumType>
  capturePkgInfoOnRcpt?: Maybe<SortEnumType>
  putwayLPOnRcpt?: Maybe<SortEnumType>
  confirmPutawaysOnRcpt?: Maybe<SortEnumType>
  reqAuthOnGRClose?: Maybe<SortEnumType>
  defaultUnbookedRcpt?: Maybe<SortEnumType>
  identicalPallets?: Maybe<SortEnumType>
  strictPODateVal?: Maybe<SortEnumType>
  splitSuppTolerance?: Maybe<SortEnumType>
  RVPutToDefLoc?: Maybe<SortEnumType>
  RFPutImmediately?: Maybe<SortEnumType>
  disablePutForSpecificLocs?: Maybe<SortEnumType>
  mandExtraInfoOnRcpt?: Maybe<SortEnumType>
  captureContainerType?: Maybe<SortEnumType>
  F2ShowBookedProdsOnly?: Maybe<SortEnumType>
  autoGeneratePONumber?: Maybe<SortEnumType>
  allowEditInRV?: Maybe<SortEnumType>
  GRPrintCrtnLbls?: Maybe<SortEnumType>
  noBckFlushGRProduction?: Maybe<SortEnumType>
  printLblsGRProduction?: Maybe<SortEnumType>
  matchGRWithBookNo?: Maybe<SortEnumType>
  RVSuppressMergePF?: Maybe<SortEnumType>
  spltPOLinesOnPrint?: Maybe<SortEnumType>
  makeGRNBudhol?: Maybe<SortEnumType>
  allowShortShelfLife?: Maybe<SortEnumType>
  allowAddProdsToPO?: Maybe<SortEnumType>
  captureLocOnRcpt?: Maybe<SortEnumType>
  captureTempOnRcpt?: Maybe<SortEnumType>
  autoGenOrderNum?: Maybe<SortEnumType>
  dispOrderEntryErrs?: Maybe<SortEnumType>
  requirePOD?: Maybe<SortEnumType>
  printOrdConfRpt?: Maybe<SortEnumType>
  createPickOnOrdEntry?: Maybe<SortEnumType>
  dontClearLineCustRef?: Maybe<SortEnumType>
  mandLineCustref?: Maybe<SortEnumType>
  warnOnShortage?: Maybe<SortEnumType>
  printOrdRptWithPick?: Maybe<SortEnumType>
  printBoxPackLbls?: Maybe<SortEnumType>
  GDPRControlled?: Maybe<SortEnumType>
  forceBudholOnEntry?: Maybe<SortEnumType>
  preventBatchOnEntry?: Maybe<SortEnumType>
  swapGenericsOnEntry?: Maybe<SortEnumType>
  forceDepartmentOnEntry?: Maybe<SortEnumType>
  cleanOrdersOnly?: Maybe<SortEnumType>
  createBackOrders?: Maybe<SortEnumType>
  allowShortageSwap?: Maybe<SortEnumType>
  allowBudholSwap?: Maybe<SortEnumType>
  chngeTraysOnPri?: Maybe<SortEnumType>
  noReplenPicksOnly?: Maybe<SortEnumType>
  allowStatusSwap?: Maybe<SortEnumType>
  pickCartons?: Maybe<SortEnumType>
  MPNoSplitLists?: Maybe<SortEnumType>
  showUnpickableRpt?: Maybe<SortEnumType>
  allowLPSwap?: Maybe<SortEnumType>
  allowSBDSwap?: Maybe<SortEnumType>
  findCartonByWgt?: Maybe<SortEnumType>
  captureCarrier?: Maybe<SortEnumType>
  allowLocSwap?: Maybe<SortEnumType>
  useBOMPicking?: Maybe<SortEnumType>
  requireDespNote?: Maybe<SortEnumType>
  RFPickAssignment?: Maybe<SortEnumType>
  voicePickAssignment?: Maybe<SortEnumType>
  allowBatchSwap?: Maybe<SortEnumType>
  requireConfProduct?: Maybe<SortEnumType>
  requireUPICheck?: Maybe<SortEnumType>
  cageValidation?: Maybe<SortEnumType>
  despNoteValidation?: Maybe<SortEnumType>
  suppressReplenOpt?: Maybe<SortEnumType>
  useLoading?: Maybe<SortEnumType>
  produceRFManifest?: Maybe<SortEnumType>
  requireSealNo?: Maybe<SortEnumType>
  requireTruck?: Maybe<SortEnumType>
  requireDriver?: Maybe<SortEnumType>
  useLoadOrder?: Maybe<SortEnumType>
  loadOrder?: Maybe<SortEnumType>
  despLane?: Maybe<SortEnumType>
  maxLoaders?: Maybe<SortEnumType>
  adhocLoading?: Maybe<SortEnumType>
  loadTempCaptureStart?: Maybe<SortEnumType>
  loadTempCaptureEnd?: Maybe<SortEnumType>
  loadTempLPInterval?: Maybe<SortEnumType>
  captureTimeOnConfirm?: Maybe<SortEnumType>
  captureCheckerOnConfirm?: Maybe<SortEnumType>
  capturePickerOnConfirm?: Maybe<SortEnumType>
  defPickFailCode?: Maybe<SortEnumType>
  requireDespatchLane?: Maybe<SortEnumType>
  useEmailFromDestination?: Maybe<SortEnumType>
  reprntUnfinishedLists?: Maybe<SortEnumType>
  destockShortOnSHort?: Maybe<SortEnumType>
  proportionalBoxesOnConfirm?: Maybe<SortEnumType>
  allowLoadAnySeq?: Maybe<SortEnumType>
  MBPLPControl?: Maybe<SortEnumType>
  MBPUseSODespMeth?: Maybe<SortEnumType>
  noDespNotes?: Maybe<SortEnumType>
  previewDespNote?: Maybe<SortEnumType>
  BOMSToStock?: Maybe<SortEnumType>
  delayDespatch?: Maybe<SortEnumType>
  RFWarnInPckConf?: Maybe<SortEnumType>
  noReplenPckConf?: Maybe<SortEnumType>
  despNotePerList?: Maybe<SortEnumType>
  printBoxLbls?: Maybe<SortEnumType>
  disablImmDesp?: Maybe<SortEnumType>
  allowFutureDespDates?: Maybe<SortEnumType>
  MBPCaptureConsNo?: Maybe<SortEnumType>
  optConsNo?: Maybe<SortEnumType>
  boxLblsPickConf?: Maybe<SortEnumType>
  useLPIdsPickConfirm?: Maybe<SortEnumType>
  authMBP?: Maybe<SortEnumType>
  mandContainerType?: Maybe<SortEnumType>
  PDFDespNotes?: Maybe<SortEnumType>
  useDespPrefix?: Maybe<SortEnumType>
  noOfDespNotes?: Maybe<SortEnumType>
  confWholePick?: Maybe<SortEnumType>
  capReasonDelayDesp?: Maybe<SortEnumType>
  MBPDespNoteBreakdwn?: Maybe<SortEnumType>
  dontCloseOrderOnLblFail?: Maybe<SortEnumType>
  capBoxesPerOrder?: Maybe<SortEnumType>
  carrierNotReq?: Maybe<SortEnumType>
  noCarrLblsPckConf?: Maybe<SortEnumType>
  copyCarrToBckOrd?: Maybe<SortEnumType>
}

export type StockOwnerUpdateInput = {
  name?: Maybe<Scalars['String']>
  status?: Maybe<State>
  contact?: Maybe<ContactUpdateInput>
  address?: Maybe<AddressUpdateInput>
  coldStoreID?: Maybe<Scalars['String']>
  GLN?: Maybe<Scalars['String']>
  VATNumber?: Maybe<Scalars['String']>
  warnMoveDifStatus?: Maybe<Scalars['Boolean']>
  requireBudhol?: Maybe<Scalars['Boolean']>
  requireProdCategories?: Maybe<Scalars['Boolean']>
  scanBarcodeMask?: Maybe<Scalars['String']>
  keyedBarcodeMask?: Maybe<Scalars['String']>
  captureSerialsOnRcpt?: Maybe<Scalars['Boolean']>
  requirePalletID?: Maybe<Scalars['Boolean']>
  printPalLblsOnRcpt?: Maybe<Scalars['Boolean']>
  capturePONumOnRcpt?: Maybe<Scalars['Boolean']>
  allocateLocOnRcpt?: Maybe<Scalars['Boolean']>
  closeComPoOnRcpt?: Maybe<Scalars['Boolean']>
  validateBatchPO?: Maybe<Scalars['Boolean']>
  capturePkgInfoOnRcpt?: Maybe<Scalars['Boolean']>
  putwayLPOnRcpt?: Maybe<Scalars['Boolean']>
  confirmPutawaysOnRcpt?: Maybe<Scalars['Boolean']>
  reqAuthOnGRClose?: Maybe<Scalars['Boolean']>
  defaultUnbookedRcpt?: Maybe<Scalars['Boolean']>
  identicalPallets?: Maybe<Scalars['Boolean']>
  strictPODateVal?: Maybe<Scalars['Boolean']>
  splitSuppTolerance?: Maybe<Scalars['Boolean']>
  RVPutToDefLoc?: Maybe<Scalars['Boolean']>
  RFPutImmediately?: Maybe<Scalars['Boolean']>
  disablePutForSpecificLocs?: Maybe<Scalars['Boolean']>
  mandExtraInfoOnRcpt?: Maybe<Scalars['Boolean']>
  captureContainerType?: Maybe<Scalars['Boolean']>
  F2ShowBookedProdsOnly?: Maybe<Scalars['Boolean']>
  autoGeneratePONumber?: Maybe<Scalars['Boolean']>
  allowEditInRV?: Maybe<Scalars['Boolean']>
  GRPrintCrtnLbls?: Maybe<Scalars['Boolean']>
  noBckFlushGRProduction?: Maybe<Scalars['Boolean']>
  printLblsGRProduction?: Maybe<Scalars['Boolean']>
  matchGRWithBookNo?: Maybe<Scalars['Boolean']>
  RVSuppressMergePF?: Maybe<Scalars['Boolean']>
  spltPOLinesOnPrint?: Maybe<Scalars['Boolean']>
  makeGRNBudhol?: Maybe<Scalars['Boolean']>
  allowShortShelfLife?: Maybe<Scalars['Boolean']>
  allowAddProdsToPO?: Maybe<Scalars['Boolean']>
  captureLocOnRcpt?: Maybe<Scalars['Boolean']>
  captureTempOnRcpt?: Maybe<Scalars['Boolean']>
  autoGenOrderNum?: Maybe<Scalars['Boolean']>
  dispOrderEntryErrs?: Maybe<Scalars['Boolean']>
  requirePOD?: Maybe<Scalars['Boolean']>
  printOrdConfRpt?: Maybe<Scalars['Boolean']>
  createPickOnOrdEntry?: Maybe<Scalars['Boolean']>
  dontClearLineCustRef?: Maybe<Scalars['Boolean']>
  mandLineCustref?: Maybe<Scalars['Boolean']>
  warnOnShortage?: Maybe<Scalars['Boolean']>
  printOrdRptWithPick?: Maybe<Scalars['Boolean']>
  printBoxPackLbls?: Maybe<Scalars['Boolean']>
  GDPRControlled?: Maybe<Scalars['Boolean']>
  forceBudholOnEntry?: Maybe<Scalars['Boolean']>
  preventBatchOnEntry?: Maybe<Scalars['Boolean']>
  swapGenericsOnEntry?: Maybe<Scalars['Boolean']>
  forceDepartmentOnEntry?: Maybe<Scalars['Boolean']>
  cleanOrdersOnly?: Maybe<Scalars['Boolean']>
  createBackOrders?: Maybe<Scalars['Boolean']>
  allowShortageSwap?: Maybe<Scalars['Boolean']>
  allowBudholSwap?: Maybe<Scalars['Boolean']>
  chngeTraysOnPri?: Maybe<Scalars['Boolean']>
  noReplenPicksOnly?: Maybe<Scalars['Boolean']>
  allowStatusSwap?: Maybe<Scalars['Boolean']>
  pickCartons?: Maybe<Scalars['Boolean']>
  MPNoSplitLists?: Maybe<Scalars['Boolean']>
  showUnpickableRpt?: Maybe<Scalars['Boolean']>
  allowLPSwap?: Maybe<Scalars['Boolean']>
  allowSBDSwap?: Maybe<Scalars['Boolean']>
  findCartonByWgt?: Maybe<Scalars['Boolean']>
  captureCarrier?: Maybe<Scalars['Boolean']>
  allowLocSwap?: Maybe<Scalars['Boolean']>
  useBOMPicking?: Maybe<Scalars['Boolean']>
  requireDespNote?: Maybe<Scalars['Boolean']>
  RFPickAssignment?: Maybe<PickAssignment>
  voicePickAssignment?: Maybe<PickAssignment>
  allowBatchSwap?: Maybe<Scalars['Boolean']>
  requireConfProduct?: Maybe<Scalars['Boolean']>
  requireUPICheck?: Maybe<Scalars['Boolean']>
  cageValidation?: Maybe<Scalars['Boolean']>
  despNoteValidation?: Maybe<Scalars['Boolean']>
  suppressReplenOpt?: Maybe<Scalars['Boolean']>
  useLoading?: Maybe<Scalars['Boolean']>
  produceRFManifest?: Maybe<Scalars['Boolean']>
  requireSealNo?: Maybe<Scalars['Boolean']>
  requireTruck?: Maybe<Scalars['Boolean']>
  requireDriver?: Maybe<Scalars['Boolean']>
  useLoadOrder?: Maybe<Scalars['Boolean']>
  loadOrder?: Maybe<LoadOrder>
  despLane?: Maybe<Scalars['String']>
  maxLoaders?: Maybe<Scalars['Int']>
  adhocLoading?: Maybe<Accessiblity>
  loadTempCaptureStart?: Maybe<Scalars['Boolean']>
  loadTempCaptureEnd?: Maybe<Scalars['Boolean']>
  loadTempLPInterval?: Maybe<Scalars['Boolean']>
  captureTimeOnConfirm?: Maybe<FieldVisibility>
  captureCheckerOnConfirm?: Maybe<FieldVisibility>
  capturePickerOnConfirm?: Maybe<FieldVisibility>
  defPickFailCode?: Maybe<Scalars['String']>
  requireDespatchLane?: Maybe<Scalars['Boolean']>
  useEmailFromDestination?: Maybe<Scalars['Boolean']>
  reprntUnfinishedLists?: Maybe<Scalars['Boolean']>
  destockShortOnSHort?: Maybe<Scalars['Boolean']>
  proportionalBoxesOnConfirm?: Maybe<Scalars['Boolean']>
  allowLoadAnySeq?: Maybe<Scalars['Boolean']>
  MBPLPControl?: Maybe<Scalars['Boolean']>
  MBPUseSODespMeth?: Maybe<Scalars['Boolean']>
  noDespNotes?: Maybe<Scalars['Boolean']>
  previewDespNote?: Maybe<Scalars['Boolean']>
  BOMSToStock?: Maybe<Scalars['Boolean']>
  delayDespatch?: Maybe<Scalars['Boolean']>
  RFWarnInPckConf?: Maybe<Scalars['Boolean']>
  noReplenPckConf?: Maybe<Scalars['Boolean']>
  despNotePerList?: Maybe<Scalars['Boolean']>
  printBoxLbls?: Maybe<Scalars['Boolean']>
  disablImmDesp?: Maybe<Scalars['Boolean']>
  allowFutureDespDates?: Maybe<Scalars['Boolean']>
  MBPCaptureConsNo?: Maybe<Scalars['Boolean']>
  optConsNo?: Maybe<Scalars['Boolean']>
  boxLblsPickConf?: Maybe<Scalars['Boolean']>
  useLPIdsPickConfirm?: Maybe<Scalars['Boolean']>
  authMBP?: Maybe<Scalars['Boolean']>
  mandContainerType?: Maybe<Scalars['Boolean']>
  PDFDespNotes?: Maybe<Scalars['Boolean']>
  useDespPrefix?: Maybe<Scalars['Boolean']>
  noOfDespNotes?: Maybe<Scalars['Boolean']>
  confWholePick?: Maybe<Scalars['Boolean']>
  capReasonDelayDesp?: Maybe<Scalars['Boolean']>
  MBPDespNoteBreakdwn?: Maybe<Scalars['Boolean']>
  dontCloseOrderOnLblFail?: Maybe<Scalars['Boolean']>
  capBoxesPerOrder?: Maybe<Scalars['Boolean']>
  carrierNotReq?: Maybe<Scalars['Boolean']>
  noCarrLblsPckConf?: Maybe<Scalars['Boolean']>
  copyCarrToBckOrd?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['Int']>
}

export type StockQueryType = {
  __typename?: 'StockQueryType'
  stock?: Maybe<StockConnection>
}

export type StockQueryTypeStockArgs = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  last?: Maybe<Scalars['Int']>
  before?: Maybe<Scalars['String']>
  userContext?: Maybe<UserContextInput>
  where?: Maybe<StockFilterInput>
  order?: Maybe<Array<StockSortInput>>
}

export type StockSortInput = {
  id?: Maybe<SortEnumType>
  warehouseId?: Maybe<SortEnumType>
  warehouse?: Maybe<WarehouseSortInput>
  stockOwnerId?: Maybe<SortEnumType>
  stockOwner?: Maybe<StockOwnerSortInput>
  productId?: Maybe<SortEnumType>
  product?: Maybe<ProductSortInput>
  attributeId?: Maybe<SortEnumType>
  attributes?: Maybe<StockAttributesSortInput>
  status?: Maybe<SortEnumType>
  packType?: Maybe<SortEnumType>
  packs?: Maybe<SortEnumType>
  singles?: Maybe<SortEnumType>
  allocated?: Maybe<SortEnumType>
  locationId?: Maybe<SortEnumType>
  location?: Maybe<LocationSortInput>
  licencePlateId?: Maybe<SortEnumType>
  licencePlate?: Maybe<LicencePlateSortInput>
}

export type StorageType = {
  __typename?: 'StorageType'
  MHEs?: Maybe<Array<Maybe<StorageTypeMhe>>>
  code: Scalars['String']
  description: Scalars['String']
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type StorageTypeConnection = {
  __typename?: 'StorageTypeConnection'
  pageInfo: PageInfo
  edges?: Maybe<Array<StorageTypeEdge>>
  nodes?: Maybe<Array<Maybe<StorageType>>>
  totalCount: Scalars['Int']
}

export type StorageTypeEdge = {
  __typename?: 'StorageTypeEdge'
  cursor: Scalars['String']
  node?: Maybe<StorageType>
}

export type StorageTypeFilter = {
  AND?: Maybe<Array<StorageTypeFilter>>
  OR?: Maybe<Array<StorageTypeFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
  code_not?: Maybe<Scalars['String']>
  code_contains?: Maybe<Scalars['String']>
  code_not_contains?: Maybe<Scalars['String']>
  code_starts_with?: Maybe<Scalars['String']>
  code_not_starts_with?: Maybe<Scalars['String']>
  code_ends_with?: Maybe<Scalars['String']>
  code_not_ends_with?: Maybe<Scalars['String']>
  code_in?: Maybe<Array<Scalars['String']>>
  code_not_in?: Maybe<Array<Scalars['String']>>
  description?: Maybe<Scalars['String']>
  description_not?: Maybe<Scalars['String']>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  description_starts_with?: Maybe<Scalars['String']>
  description_not_starts_with?: Maybe<Scalars['String']>
  description_ends_with?: Maybe<Scalars['String']>
  description_not_ends_with?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Scalars['String']>>
  description_not_in?: Maybe<Array<Scalars['String']>>
  MHEs_some?: Maybe<StorageTypeMheFilter>
  MHEs_none?: Maybe<StorageTypeMheFilter>
  MHEs_all?: Maybe<StorageTypeMheFilter>
  MHEs_any?: Maybe<Scalars['Boolean']>
}

export type StorageTypeFilterInput = {
  and?: Maybe<Array<StorageTypeFilterInput>>
  or?: Maybe<Array<StorageTypeFilterInput>>
  id?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  code?: Maybe<StringOperationFilterInput>
  description?: Maybe<StringOperationFilterInput>
  MHEs?: Maybe<ListFilterInputTypeOfStorageTypeMheFilterInput>
}

export type StorageTypeInput = {
  id?: Maybe<Scalars['Int']>
  code: Scalars['String']
  description: Scalars['String']
  MHEs?: Maybe<Array<Maybe<StorageTypeMheInput>>>
}

export type StorageTypeMhe = {
  __typename?: 'StorageTypeMHE'
  storageType?: Maybe<StorageType>
  MHEId?: Maybe<Scalars['Int']>
  materialHandlingEquipment?: Maybe<MaterialHandlingEquipment>
}

export type StorageTypeMheFilter = {
  AND?: Maybe<Array<StorageTypeMheFilter>>
  OR?: Maybe<Array<StorageTypeMheFilter>>
  storageType?: Maybe<StorageTypeFilter>
  MHEId?: Maybe<Scalars['Int']>
  MHEId_not?: Maybe<Scalars['Int']>
  MHEId_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  MHEId_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  MHEId_gt?: Maybe<Scalars['Int']>
  MHEId_not_gt?: Maybe<Scalars['Int']>
  MHEId_gte?: Maybe<Scalars['Int']>
  MHEId_not_gte?: Maybe<Scalars['Int']>
  MHEId_lt?: Maybe<Scalars['Int']>
  MHEId_not_lt?: Maybe<Scalars['Int']>
  MHEId_lte?: Maybe<Scalars['Int']>
  MHEId_not_lte?: Maybe<Scalars['Int']>
  materialHandlingEquipment?: Maybe<MaterialHandlingEquipmentFilter>
}

export type StorageTypeMheFilterInput = {
  and?: Maybe<Array<StorageTypeMheFilterInput>>
  or?: Maybe<Array<StorageTypeMheFilterInput>>
  storageType?: Maybe<StorageTypeFilterInput>
  MHEId?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  materialHandlingEquipment?: Maybe<MaterialHandlingEquipmentFilterInput>
}

export type StorageTypeMheInput = {
  storageType?: Maybe<StorageTypeInput>
  MHEId?: Maybe<Scalars['Int']>
  materialHandlingEquipment?: Maybe<MaterialHandlingEquipmentInput>
}

export type StorageTypeSort = {
  id?: Maybe<SortOperationKind>
  code?: Maybe<SortOperationKind>
  description?: Maybe<SortOperationKind>
}

export type StorageTypeSortInput = {
  id?: Maybe<SortEnumType>
  code?: Maybe<SortEnumType>
  description?: Maybe<SortEnumType>
}

export type StorageTypeUpdateInput = {
  description?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
}

export type StringOperationFilterInput = {
  and?: Maybe<Array<StringOperationFilterInput>>
  or?: Maybe<Array<StringOperationFilterInput>>
  eq?: Maybe<Scalars['String']>
  neq?: Maybe<Scalars['String']>
  contains?: Maybe<Scalars['String']>
  ncontains?: Maybe<Scalars['String']>
  in?: Maybe<Array<Maybe<Scalars['String']>>>
  nin?: Maybe<Array<Maybe<Scalars['String']>>>
  startsWith?: Maybe<Scalars['String']>
  nstartsWith?: Maybe<Scalars['String']>
  endsWith?: Maybe<Scalars['String']>
  nendsWith?: Maybe<Scalars['String']>
}

export type Subscription = {
  __typename?: 'Subscription'
  serverTime: Scalars['DateTime']
  serverTimeAuth: Scalars['DateTime']
  onServerTimeEvent: Scalars['DateTime']
}

export type Supplier = {
  __typename?: 'Supplier'
  id?: Maybe<Scalars['Int']>
  code: Scalars['String']
  master?: Maybe<Scalars['String']>
  name: Scalars['String']
  address: Address
  currency?: Maybe<Currency>
  fileTransMeth?: Maybe<FileTransmission>
  fileFormat?: Maybe<FileFormat>
  mustOrderLayerQty?: Maybe<Scalars['Boolean']>
  maxWeekSpend?: Maybe<Scalars['Decimal']>
  leadTime?: Maybe<Scalars['Int']>
  orderDays?: Maybe<Array<DayOfWeek>>
}

export type SupplierConnection = {
  __typename?: 'SupplierConnection'
  pageInfo: PageInfo
  edges?: Maybe<Array<SupplierEdge>>
  nodes?: Maybe<Array<Maybe<Supplier>>>
  totalCount: Scalars['Int']
}

export type SupplierEdge = {
  __typename?: 'SupplierEdge'
  cursor: Scalars['String']
  node?: Maybe<Supplier>
}

export type SupplierFilter = {
  AND?: Maybe<Array<SupplierFilter>>
  OR?: Maybe<Array<SupplierFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
  code_not?: Maybe<Scalars['String']>
  code_contains?: Maybe<Scalars['String']>
  code_not_contains?: Maybe<Scalars['String']>
  code_starts_with?: Maybe<Scalars['String']>
  code_not_starts_with?: Maybe<Scalars['String']>
  code_ends_with?: Maybe<Scalars['String']>
  code_not_ends_with?: Maybe<Scalars['String']>
  code_in?: Maybe<Array<Scalars['String']>>
  code_not_in?: Maybe<Array<Scalars['String']>>
  master?: Maybe<Scalars['String']>
  master_not?: Maybe<Scalars['String']>
  master_contains?: Maybe<Scalars['String']>
  master_not_contains?: Maybe<Scalars['String']>
  master_starts_with?: Maybe<Scalars['String']>
  master_not_starts_with?: Maybe<Scalars['String']>
  master_ends_with?: Maybe<Scalars['String']>
  master_not_ends_with?: Maybe<Scalars['String']>
  master_in?: Maybe<Array<Maybe<Scalars['String']>>>
  master_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  name?: Maybe<Scalars['String']>
  name_not?: Maybe<Scalars['String']>
  name_contains?: Maybe<Scalars['String']>
  name_not_contains?: Maybe<Scalars['String']>
  name_starts_with?: Maybe<Scalars['String']>
  name_not_starts_with?: Maybe<Scalars['String']>
  name_ends_with?: Maybe<Scalars['String']>
  name_not_ends_with?: Maybe<Scalars['String']>
  name_in?: Maybe<Array<Scalars['String']>>
  name_not_in?: Maybe<Array<Scalars['String']>>
  address?: Maybe<AddressFilter>
  currency?: Maybe<Currency>
  currency_not?: Maybe<Currency>
  currency_in?: Maybe<Array<Maybe<Currency>>>
  currency_not_in?: Maybe<Array<Maybe<Currency>>>
  currency_gt?: Maybe<Currency>
  currency_not_gt?: Maybe<Currency>
  currency_gte?: Maybe<Currency>
  currency_not_gte?: Maybe<Currency>
  currency_lt?: Maybe<Currency>
  currency_not_lt?: Maybe<Currency>
  currency_lte?: Maybe<Currency>
  currency_not_lte?: Maybe<Currency>
  fileTransMeth?: Maybe<FileTransmission>
  fileTransMeth_not?: Maybe<FileTransmission>
  fileTransMeth_in?: Maybe<Array<Maybe<FileTransmission>>>
  fileTransMeth_not_in?: Maybe<Array<Maybe<FileTransmission>>>
  fileTransMeth_gt?: Maybe<FileTransmission>
  fileTransMeth_not_gt?: Maybe<FileTransmission>
  fileTransMeth_gte?: Maybe<FileTransmission>
  fileTransMeth_not_gte?: Maybe<FileTransmission>
  fileTransMeth_lt?: Maybe<FileTransmission>
  fileTransMeth_not_lt?: Maybe<FileTransmission>
  fileTransMeth_lte?: Maybe<FileTransmission>
  fileTransMeth_not_lte?: Maybe<FileTransmission>
  fileFormat?: Maybe<FileFormat>
  fileFormat_not?: Maybe<FileFormat>
  fileFormat_in?: Maybe<Array<Maybe<FileFormat>>>
  fileFormat_not_in?: Maybe<Array<Maybe<FileFormat>>>
  fileFormat_gt?: Maybe<FileFormat>
  fileFormat_not_gt?: Maybe<FileFormat>
  fileFormat_gte?: Maybe<FileFormat>
  fileFormat_not_gte?: Maybe<FileFormat>
  fileFormat_lt?: Maybe<FileFormat>
  fileFormat_not_lt?: Maybe<FileFormat>
  fileFormat_lte?: Maybe<FileFormat>
  fileFormat_not_lte?: Maybe<FileFormat>
  mustOrderLayerQty?: Maybe<Scalars['Boolean']>
  mustOrderLayerQty_not?: Maybe<Scalars['Boolean']>
  maxWeekSpend?: Maybe<Scalars['Decimal']>
  maxWeekSpend_not?: Maybe<Scalars['Decimal']>
  maxWeekSpend_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  maxWeekSpend_not_in?: Maybe<Array<Maybe<Scalars['Decimal']>>>
  maxWeekSpend_gt?: Maybe<Scalars['Decimal']>
  maxWeekSpend_not_gt?: Maybe<Scalars['Decimal']>
  maxWeekSpend_gte?: Maybe<Scalars['Decimal']>
  maxWeekSpend_not_gte?: Maybe<Scalars['Decimal']>
  maxWeekSpend_lt?: Maybe<Scalars['Decimal']>
  maxWeekSpend_not_lt?: Maybe<Scalars['Decimal']>
  maxWeekSpend_lte?: Maybe<Scalars['Decimal']>
  maxWeekSpend_not_lte?: Maybe<Scalars['Decimal']>
  leadTime?: Maybe<Scalars['Int']>
  leadTime_not?: Maybe<Scalars['Int']>
  leadTime_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  leadTime_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  leadTime_gt?: Maybe<Scalars['Int']>
  leadTime_not_gt?: Maybe<Scalars['Int']>
  leadTime_gte?: Maybe<Scalars['Int']>
  leadTime_not_gte?: Maybe<Scalars['Int']>
  leadTime_lt?: Maybe<Scalars['Int']>
  leadTime_not_lt?: Maybe<Scalars['Int']>
  leadTime_lte?: Maybe<Scalars['Int']>
  leadTime_not_lte?: Maybe<Scalars['Int']>
  orderDays_some?: Maybe<ISingleFilterOfDayOfWeekFilter>
  orderDays_none?: Maybe<ISingleFilterOfDayOfWeekFilter>
  orderDays_all?: Maybe<ISingleFilterOfDayOfWeekFilter>
  orderDays_any?: Maybe<Scalars['Boolean']>
}

export type SupplierFilterInput = {
  and?: Maybe<Array<SupplierFilterInput>>
  or?: Maybe<Array<SupplierFilterInput>>
  id?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  code?: Maybe<StringOperationFilterInput>
  master?: Maybe<StringOperationFilterInput>
  name?: Maybe<StringOperationFilterInput>
  address?: Maybe<AddressFilterInput>
  currency?: Maybe<NullableOfCurrencyOperationFilterInput>
  fileTransMeth?: Maybe<NullableOfFileTransmissionOperationFilterInput>
  fileFormat?: Maybe<NullableOfFileFormatOperationFilterInput>
  mustOrderLayerQty?: Maybe<BooleanOperationFilterInput>
  maxWeekSpend?: Maybe<ComparableNullableOfDecimalOperationFilterInput>
  leadTime?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  orderDays?: Maybe<ListDayOfWeekOperationFilterInput>
}

export type SupplierInput = {
  id?: Maybe<Scalars['Int']>
  code: Scalars['String']
  master?: Maybe<Scalars['String']>
  name: Scalars['String']
  address: AddressInput
  currency?: Maybe<Currency>
  fileTransMeth?: Maybe<FileTransmission>
  fileFormat?: Maybe<FileFormat>
  mustOrderLayerQty: Scalars['Boolean']
  maxWeekSpend: Scalars['Int']
  leadTime: Scalars['Int']
  orderDays?: Maybe<Array<DayOfWeek>>
}

export type SupplierSort = {
  id?: Maybe<SortOperationKind>
  code?: Maybe<SortOperationKind>
  master?: Maybe<SortOperationKind>
  name?: Maybe<SortOperationKind>
  address?: Maybe<AddressSort>
  currency?: Maybe<SortOperationKind>
  fileTransMeth?: Maybe<SortOperationKind>
  fileFormat?: Maybe<SortOperationKind>
  mustOrderLayerQty?: Maybe<SortOperationKind>
  maxWeekSpend?: Maybe<SortOperationKind>
  leadTime?: Maybe<SortOperationKind>
}

export type SupplierUpdateInput = {
  master?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  address?: Maybe<AddressUpdateInput>
  currency?: Maybe<Currency>
  fileTransMeth?: Maybe<FileTransmission>
  fileFormat?: Maybe<FileFormat>
  mustOrderLayerQty?: Maybe<Scalars['Boolean']>
  maxWeekSpend?: Maybe<Scalars['Decimal']>
  leadTime?: Maybe<Scalars['Int']>
  orderDays?: Maybe<Array<DayOfWeek>>
  id?: Maybe<Scalars['Int']>
}

export type TextInfoFilterInput = {
  and?: Maybe<Array<TextInfoFilterInput>>
  or?: Maybe<Array<TextInfoFilterInput>>
  aNSICodePage?: Maybe<ComparableInt32OperationFilterInput>
  oEMCodePage?: Maybe<ComparableInt32OperationFilterInput>
  macCodePage?: Maybe<ComparableInt32OperationFilterInput>
  eBCDICCodePage?: Maybe<ComparableInt32OperationFilterInput>
  lCID?: Maybe<ComparableInt32OperationFilterInput>
  cultureName?: Maybe<StringOperationFilterInput>
  isReadOnly?: Maybe<BooleanOperationFilterInput>
  listSeparator?: Maybe<StringOperationFilterInput>
  isRightToLeft?: Maybe<BooleanOperationFilterInput>
}

export type TextInfoSortInput = {
  aNSICodePage?: Maybe<SortEnumType>
  oEMCodePage?: Maybe<SortEnumType>
  macCodePage?: Maybe<SortEnumType>
  eBCDICCodePage?: Maybe<SortEnumType>
  lCID?: Maybe<SortEnumType>
  cultureName?: Maybe<SortEnumType>
  isReadOnly?: Maybe<SortEnumType>
  listSeparator?: Maybe<SortEnumType>
  isRightToLeft?: Maybe<SortEnumType>
}

export type UdCode = {
  __typename?: 'UDCode'
  id?: Maybe<Scalars['Int']>
  codeType?: Maybe<Scalars['String']>
  code: Scalars['String']
  description: Scalars['String']
  longDescription?: Maybe<Scalars['String']>
  stockOwnerId?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwner>
}

export type UdCodeConnection = {
  __typename?: 'UDCodeConnection'
  pageInfo: PageInfo
  edges?: Maybe<Array<UdCodeEdge>>
  nodes?: Maybe<Array<Maybe<UdCode>>>
  totalCount: Scalars['Int']
}

export type UdCodeEdge = {
  __typename?: 'UDCodeEdge'
  cursor: Scalars['String']
  node?: Maybe<UdCode>
}

export type UdCodeFilter = {
  AND?: Maybe<Array<UdCodeFilter>>
  OR?: Maybe<Array<UdCodeFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  codeType?: Maybe<Scalars['String']>
  codeType_not?: Maybe<Scalars['String']>
  codeType_contains?: Maybe<Scalars['String']>
  codeType_not_contains?: Maybe<Scalars['String']>
  codeType_starts_with?: Maybe<Scalars['String']>
  codeType_not_starts_with?: Maybe<Scalars['String']>
  codeType_ends_with?: Maybe<Scalars['String']>
  codeType_not_ends_with?: Maybe<Scalars['String']>
  codeType_in?: Maybe<Array<Maybe<Scalars['String']>>>
  codeType_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  code?: Maybe<Scalars['String']>
  code_not?: Maybe<Scalars['String']>
  code_contains?: Maybe<Scalars['String']>
  code_not_contains?: Maybe<Scalars['String']>
  code_starts_with?: Maybe<Scalars['String']>
  code_not_starts_with?: Maybe<Scalars['String']>
  code_ends_with?: Maybe<Scalars['String']>
  code_not_ends_with?: Maybe<Scalars['String']>
  code_in?: Maybe<Array<Scalars['String']>>
  code_not_in?: Maybe<Array<Scalars['String']>>
  description?: Maybe<Scalars['String']>
  description_not?: Maybe<Scalars['String']>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  description_starts_with?: Maybe<Scalars['String']>
  description_not_starts_with?: Maybe<Scalars['String']>
  description_ends_with?: Maybe<Scalars['String']>
  description_not_ends_with?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Scalars['String']>>
  description_not_in?: Maybe<Array<Scalars['String']>>
  longDescription?: Maybe<Scalars['String']>
  longDescription_not?: Maybe<Scalars['String']>
  longDescription_contains?: Maybe<Scalars['String']>
  longDescription_not_contains?: Maybe<Scalars['String']>
  longDescription_starts_with?: Maybe<Scalars['String']>
  longDescription_not_starts_with?: Maybe<Scalars['String']>
  longDescription_ends_with?: Maybe<Scalars['String']>
  longDescription_not_ends_with?: Maybe<Scalars['String']>
  longDescription_in?: Maybe<Array<Maybe<Scalars['String']>>>
  longDescription_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  stockOwnerId?: Maybe<Scalars['Int']>
  stockOwnerId_not?: Maybe<Scalars['Int']>
  stockOwnerId_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  stockOwnerId_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  stockOwnerId_gt?: Maybe<Scalars['Int']>
  stockOwnerId_not_gt?: Maybe<Scalars['Int']>
  stockOwnerId_gte?: Maybe<Scalars['Int']>
  stockOwnerId_not_gte?: Maybe<Scalars['Int']>
  stockOwnerId_lt?: Maybe<Scalars['Int']>
  stockOwnerId_not_lt?: Maybe<Scalars['Int']>
  stockOwnerId_lte?: Maybe<Scalars['Int']>
  stockOwnerId_not_lte?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwnerFilter>
}

export type UdCodeInput = {
  id?: Maybe<Scalars['Int']>
  codeType?: Maybe<Scalars['String']>
  code: Scalars['String']
  description: Scalars['String']
  longDescription?: Maybe<Scalars['String']>
  stockOwnerId?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwnerInput>
}

export type UdCodeSort = {
  id?: Maybe<SortOperationKind>
  codeType?: Maybe<SortOperationKind>
  code?: Maybe<SortOperationKind>
  description?: Maybe<SortOperationKind>
  longDescription?: Maybe<SortOperationKind>
  stockOwnerId?: Maybe<SortOperationKind>
  stockOwner?: Maybe<StockOwnerSort>
}

export type UdCodeUpdateInput = {
  codeType?: Maybe<Scalars['String']>
  code?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  longDescription?: Maybe<Scalars['String']>
  stockOwnerId?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwnerUpdateInput>
  id?: Maybe<Scalars['Int']>
}

export type UpdateCollectionOfUserPermissionInput = {
  create?: Maybe<Array<Maybe<UserPermissionInput>>>
  update?: Maybe<Array<Maybe<UserPermissionUpdateInput>>>
  delete?: Maybe<Array<Maybe<UserPermissionInput>>>
}

export type UpdateOrderEntryInput = {
  destinationCode: Scalars['ID']
  budgetHolder: Scalars['ID']
  priority: Scalars['Int']
  orderType: Scalars['ID']
  soCustomerRef: Scalars['String']
}

export type UserContextInput = {
  warehouseId?: Maybe<Scalars['Int']>
  stockOwnerId?: Maybe<Scalars['Int']>
}

export type UserInput = {
  forename?: Maybe<Scalars['String']>
  surname?: Maybe<Scalars['String']>
  culture?: Maybe<Scalars['String']>
  twoFactorEnabled: Scalars['Boolean']
  phoneNumber?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  userName?: Maybe<Scalars['String']>
  lockoutEnabled: Scalars['Boolean']
  serverRole: ServerPermission
  userPermissions?: Maybe<UpdateCollectionOfUserPermissionInput>
}

export type UserPermission = {
  __typename?: 'UserPermission'
  id?: Maybe<Scalars['Int']>
  userId?: Maybe<Scalars['String']>
  warehouseId?: Maybe<Scalars['Int']>
  warehouse?: Maybe<Warehouse>
  stockOwnerId?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwner>
  permissions?: Maybe<Array<Permission>>
}

export type UserPermissionConnection = {
  __typename?: 'UserPermissionConnection'
  pageInfo: PageInfo
  edges?: Maybe<Array<UserPermissionEdge>>
  nodes?: Maybe<Array<Maybe<UserPermission>>>
  totalCount: Scalars['Int']
}

export type UserPermissionEdge = {
  __typename?: 'UserPermissionEdge'
  cursor: Scalars['String']
  node?: Maybe<UserPermission>
}

export type UserPermissionFilter = {
  AND?: Maybe<Array<UserPermissionFilter>>
  OR?: Maybe<Array<UserPermissionFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  userId?: Maybe<Scalars['String']>
  userId_not?: Maybe<Scalars['String']>
  userId_contains?: Maybe<Scalars['String']>
  userId_not_contains?: Maybe<Scalars['String']>
  userId_starts_with?: Maybe<Scalars['String']>
  userId_not_starts_with?: Maybe<Scalars['String']>
  userId_ends_with?: Maybe<Scalars['String']>
  userId_not_ends_with?: Maybe<Scalars['String']>
  userId_in?: Maybe<Array<Maybe<Scalars['String']>>>
  userId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  warehouseId?: Maybe<Scalars['Int']>
  warehouseId_not?: Maybe<Scalars['Int']>
  warehouseId_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  warehouseId_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  warehouseId_gt?: Maybe<Scalars['Int']>
  warehouseId_not_gt?: Maybe<Scalars['Int']>
  warehouseId_gte?: Maybe<Scalars['Int']>
  warehouseId_not_gte?: Maybe<Scalars['Int']>
  warehouseId_lt?: Maybe<Scalars['Int']>
  warehouseId_not_lt?: Maybe<Scalars['Int']>
  warehouseId_lte?: Maybe<Scalars['Int']>
  warehouseId_not_lte?: Maybe<Scalars['Int']>
  warehouse?: Maybe<WarehouseFilter>
  stockOwnerId?: Maybe<Scalars['Int']>
  stockOwnerId_not?: Maybe<Scalars['Int']>
  stockOwnerId_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  stockOwnerId_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  stockOwnerId_gt?: Maybe<Scalars['Int']>
  stockOwnerId_not_gt?: Maybe<Scalars['Int']>
  stockOwnerId_gte?: Maybe<Scalars['Int']>
  stockOwnerId_not_gte?: Maybe<Scalars['Int']>
  stockOwnerId_lt?: Maybe<Scalars['Int']>
  stockOwnerId_not_lt?: Maybe<Scalars['Int']>
  stockOwnerId_lte?: Maybe<Scalars['Int']>
  stockOwnerId_not_lte?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwnerFilter>
  permissions_some?: Maybe<ISingleFilterOfPermissionFilter>
  permissions_none?: Maybe<ISingleFilterOfPermissionFilter>
  permissions_all?: Maybe<ISingleFilterOfPermissionFilter>
  permissions_any?: Maybe<Scalars['Boolean']>
}

export type UserPermissionInput = {
  id?: Maybe<Scalars['Int']>
  userId?: Maybe<Scalars['String']>
  warehouseId?: Maybe<Scalars['Int']>
  warehouse?: Maybe<WarehouseInput>
  stockOwnerId?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwnerInput>
  permissions?: Maybe<Array<Permission>>
}

export type UserPermissionSort = {
  id?: Maybe<SortOperationKind>
  userId?: Maybe<SortOperationKind>
  warehouseId?: Maybe<SortOperationKind>
  warehouse?: Maybe<WarehouseSort>
  stockOwnerId?: Maybe<SortOperationKind>
  stockOwner?: Maybe<StockOwnerSort>
}

export type UserPermissionUpdateInput = {
  userId?: Maybe<Scalars['String']>
  warehouseId?: Maybe<Scalars['Int']>
  warehouse?: Maybe<WarehouseUpdateInput>
  stockOwnerId?: Maybe<Scalars['Int']>
  stockOwner?: Maybe<StockOwnerUpdateInput>
  permissions?: Maybe<Array<Permission>>
  id?: Maybe<Scalars['Int']>
}

export type UserUpdateInput = {
  forename?: Maybe<Scalars['String']>
  surname?: Maybe<Scalars['String']>
  culture?: Maybe<Scalars['String']>
  twoFactorEnabled?: Maybe<Scalars['Boolean']>
  phoneNumber?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  userName?: Maybe<Scalars['String']>
  lockoutEnabled?: Maybe<Scalars['Boolean']>
  serverRole?: Maybe<ServerPermission>
  userPermissions?: Maybe<UpdateCollectionOfUserPermissionInput>
}

export type ValidationError = {
  __typename?: 'ValidationError'
  id: Scalars['Int']
  field?: Maybe<Scalars['String']>
  messages?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type Warehouse = {
  __typename?: 'Warehouse'
  id?: Maybe<Scalars['Int']>
  code: Scalars['String']
  companyName?: Maybe<Scalars['String']>
  warehouseName: Scalars['String']
  address: Address
  telephone?: Maybe<Scalars['String']>
  VATNumber?: Maybe<Scalars['String']>
  customsId?: Maybe<Scalars['String']>
}

export type WarehouseConnection = {
  __typename?: 'WarehouseConnection'
  pageInfo: PageInfo
  edges?: Maybe<Array<WarehouseEdge>>
  nodes?: Maybe<Array<Maybe<Warehouse>>>
  totalCount: Scalars['Int']
}

export type WarehouseEdge = {
  __typename?: 'WarehouseEdge'
  cursor: Scalars['String']
  node?: Maybe<Warehouse>
}

export type WarehouseFilter = {
  AND?: Maybe<Array<WarehouseFilter>>
  OR?: Maybe<Array<WarehouseFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
  code_not?: Maybe<Scalars['String']>
  code_contains?: Maybe<Scalars['String']>
  code_not_contains?: Maybe<Scalars['String']>
  code_starts_with?: Maybe<Scalars['String']>
  code_not_starts_with?: Maybe<Scalars['String']>
  code_ends_with?: Maybe<Scalars['String']>
  code_not_ends_with?: Maybe<Scalars['String']>
  code_in?: Maybe<Array<Scalars['String']>>
  code_not_in?: Maybe<Array<Scalars['String']>>
  companyName?: Maybe<Scalars['String']>
  companyName_not?: Maybe<Scalars['String']>
  companyName_contains?: Maybe<Scalars['String']>
  companyName_not_contains?: Maybe<Scalars['String']>
  companyName_starts_with?: Maybe<Scalars['String']>
  companyName_not_starts_with?: Maybe<Scalars['String']>
  companyName_ends_with?: Maybe<Scalars['String']>
  companyName_not_ends_with?: Maybe<Scalars['String']>
  companyName_in?: Maybe<Array<Maybe<Scalars['String']>>>
  companyName_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  warehouseName?: Maybe<Scalars['String']>
  warehouseName_not?: Maybe<Scalars['String']>
  warehouseName_contains?: Maybe<Scalars['String']>
  warehouseName_not_contains?: Maybe<Scalars['String']>
  warehouseName_starts_with?: Maybe<Scalars['String']>
  warehouseName_not_starts_with?: Maybe<Scalars['String']>
  warehouseName_ends_with?: Maybe<Scalars['String']>
  warehouseName_not_ends_with?: Maybe<Scalars['String']>
  warehouseName_in?: Maybe<Array<Scalars['String']>>
  warehouseName_not_in?: Maybe<Array<Scalars['String']>>
  address?: Maybe<AddressFilter>
  telephone?: Maybe<Scalars['String']>
  telephone_not?: Maybe<Scalars['String']>
  telephone_contains?: Maybe<Scalars['String']>
  telephone_not_contains?: Maybe<Scalars['String']>
  telephone_starts_with?: Maybe<Scalars['String']>
  telephone_not_starts_with?: Maybe<Scalars['String']>
  telephone_ends_with?: Maybe<Scalars['String']>
  telephone_not_ends_with?: Maybe<Scalars['String']>
  telephone_in?: Maybe<Array<Maybe<Scalars['String']>>>
  telephone_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  VATNumber?: Maybe<Scalars['String']>
  VATNumber_not?: Maybe<Scalars['String']>
  VATNumber_contains?: Maybe<Scalars['String']>
  VATNumber_not_contains?: Maybe<Scalars['String']>
  VATNumber_starts_with?: Maybe<Scalars['String']>
  VATNumber_not_starts_with?: Maybe<Scalars['String']>
  VATNumber_ends_with?: Maybe<Scalars['String']>
  VATNumber_not_ends_with?: Maybe<Scalars['String']>
  VATNumber_in?: Maybe<Array<Maybe<Scalars['String']>>>
  VATNumber_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
  customsId?: Maybe<Scalars['String']>
  customsId_not?: Maybe<Scalars['String']>
  customsId_contains?: Maybe<Scalars['String']>
  customsId_not_contains?: Maybe<Scalars['String']>
  customsId_starts_with?: Maybe<Scalars['String']>
  customsId_not_starts_with?: Maybe<Scalars['String']>
  customsId_ends_with?: Maybe<Scalars['String']>
  customsId_not_ends_with?: Maybe<Scalars['String']>
  customsId_in?: Maybe<Array<Maybe<Scalars['String']>>>
  customsId_not_in?: Maybe<Array<Maybe<Scalars['String']>>>
}

export type WarehouseFilterInput = {
  and?: Maybe<Array<WarehouseFilterInput>>
  or?: Maybe<Array<WarehouseFilterInput>>
  id?: Maybe<ComparableNullableOfInt32OperationFilterInput>
  code?: Maybe<StringOperationFilterInput>
  companyName?: Maybe<StringOperationFilterInput>
  warehouseName?: Maybe<StringOperationFilterInput>
  address?: Maybe<AddressFilterInput>
  telephone?: Maybe<StringOperationFilterInput>
  VATNumber?: Maybe<StringOperationFilterInput>
  customsId?: Maybe<StringOperationFilterInput>
}

export type WarehouseInput = {
  id?: Maybe<Scalars['Int']>
  code: Scalars['String']
  companyName?: Maybe<Scalars['String']>
  warehouseName: Scalars['String']
  address: AddressInput
  telephone?: Maybe<Scalars['String']>
  VATNumber?: Maybe<Scalars['String']>
  customsId?: Maybe<Scalars['String']>
}

export type WarehouseSort = {
  id?: Maybe<SortOperationKind>
  code?: Maybe<SortOperationKind>
  companyName?: Maybe<SortOperationKind>
  warehouseName?: Maybe<SortOperationKind>
  address?: Maybe<AddressSort>
  telephone?: Maybe<SortOperationKind>
  VATNumber?: Maybe<SortOperationKind>
  customsId?: Maybe<SortOperationKind>
}

export type WarehouseSortInput = {
  id?: Maybe<SortEnumType>
  code?: Maybe<SortEnumType>
  companyName?: Maybe<SortEnumType>
  warehouseName?: Maybe<SortEnumType>
  address?: Maybe<AddressSortInput>
  telephone?: Maybe<SortEnumType>
  VATNumber?: Maybe<SortEnumType>
  customsId?: Maybe<SortEnumType>
}

export type WarehouseUpdateInput = {
  companyName?: Maybe<Scalars['String']>
  warehouseName?: Maybe<Scalars['String']>
  address?: Maybe<AddressUpdateInput>
  telephone?: Maybe<Scalars['String']>
  VATNumber?: Maybe<Scalars['String']>
  customsId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
}

export type WarehouseZone = {
  __typename?: 'WarehouseZone'
  id?: Maybe<Scalars['Int']>
  warehouse?: Maybe<Warehouse>
  code: Scalars['String']
  description: Scalars['String']
  zoneType: Scalars['String']
}

export type WarehouseZoneConnection = {
  __typename?: 'WarehouseZoneConnection'
  pageInfo: PageInfo
  edges?: Maybe<Array<WarehouseZoneEdge>>
  nodes?: Maybe<Array<Maybe<WarehouseZone>>>
  totalCount: Scalars['Int']
}

export type WarehouseZoneEdge = {
  __typename?: 'WarehouseZoneEdge'
  cursor: Scalars['String']
  node?: Maybe<WarehouseZone>
}

export type WarehouseZoneFilter = {
  AND?: Maybe<Array<WarehouseZoneFilter>>
  OR?: Maybe<Array<WarehouseZoneFilter>>
  id?: Maybe<Scalars['Int']>
  id_not?: Maybe<Scalars['Int']>
  id_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_not_in?: Maybe<Array<Maybe<Scalars['Int']>>>
  id_gt?: Maybe<Scalars['Int']>
  id_not_gt?: Maybe<Scalars['Int']>
  id_gte?: Maybe<Scalars['Int']>
  id_not_gte?: Maybe<Scalars['Int']>
  id_lt?: Maybe<Scalars['Int']>
  id_not_lt?: Maybe<Scalars['Int']>
  id_lte?: Maybe<Scalars['Int']>
  id_not_lte?: Maybe<Scalars['Int']>
  warehouse?: Maybe<WarehouseFilter>
  code?: Maybe<Scalars['String']>
  code_not?: Maybe<Scalars['String']>
  code_contains?: Maybe<Scalars['String']>
  code_not_contains?: Maybe<Scalars['String']>
  code_starts_with?: Maybe<Scalars['String']>
  code_not_starts_with?: Maybe<Scalars['String']>
  code_ends_with?: Maybe<Scalars['String']>
  code_not_ends_with?: Maybe<Scalars['String']>
  code_in?: Maybe<Array<Scalars['String']>>
  code_not_in?: Maybe<Array<Scalars['String']>>
  description?: Maybe<Scalars['String']>
  description_not?: Maybe<Scalars['String']>
  description_contains?: Maybe<Scalars['String']>
  description_not_contains?: Maybe<Scalars['String']>
  description_starts_with?: Maybe<Scalars['String']>
  description_not_starts_with?: Maybe<Scalars['String']>
  description_ends_with?: Maybe<Scalars['String']>
  description_not_ends_with?: Maybe<Scalars['String']>
  description_in?: Maybe<Array<Scalars['String']>>
  description_not_in?: Maybe<Array<Scalars['String']>>
  zoneType?: Maybe<Scalars['String']>
  zoneType_not?: Maybe<Scalars['String']>
  zoneType_contains?: Maybe<Scalars['String']>
  zoneType_not_contains?: Maybe<Scalars['String']>
  zoneType_starts_with?: Maybe<Scalars['String']>
  zoneType_not_starts_with?: Maybe<Scalars['String']>
  zoneType_ends_with?: Maybe<Scalars['String']>
  zoneType_not_ends_with?: Maybe<Scalars['String']>
  zoneType_in?: Maybe<Array<Scalars['String']>>
  zoneType_not_in?: Maybe<Array<Scalars['String']>>
}

export type WarehouseZoneInput = {
  id?: Maybe<Scalars['Int']>
  warehouse?: Maybe<WarehouseInput>
  code: Scalars['String']
  description: Scalars['String']
  zoneType: Scalars['String']
}

export type WarehouseZoneSort = {
  id?: Maybe<SortOperationKind>
  warehouse?: Maybe<WarehouseSort>
  code?: Maybe<SortOperationKind>
  description?: Maybe<SortOperationKind>
  zoneType?: Maybe<SortOperationKind>
}

export type WarehouseZoneUpdateInput = {
  warehouseId?: Maybe<Scalars['Int']>
  code?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  zoneType?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['Int']>
}

export type WmsUser = {
  __typename?: 'WMSUser'
  serverRole?: Maybe<ServerPermission>
  userPermissions?: Maybe<Array<Maybe<UserPermission>>>
  forename?: Maybe<Scalars['String']>
  surname?: Maybe<Scalars['String']>
  culture?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  userName?: Maybe<Scalars['String']>
  normalizedUserName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  phoneNumberConfirmed: Scalars['Boolean']
  twoFactorEnabled: Scalars['Boolean']
  lockoutEnd?: Maybe<Scalars['DateTime']>
  lockoutEnabled: Scalars['Boolean']
  accessFailedCount: Scalars['Int']
}

export type WmsUserFilterInput = {
  and?: Maybe<Array<WmsUserFilterInput>>
  or?: Maybe<Array<WmsUserFilterInput>>
  concurrencyStamp?: Maybe<StringOperationFilterInput>
  emailConfirmed?: Maybe<BooleanOperationFilterInput>
  normalizedEmail?: Maybe<StringOperationFilterInput>
  passwordHash?: Maybe<StringOperationFilterInput>
  securityStamp?: Maybe<StringOperationFilterInput>
  forename?: Maybe<StringOperationFilterInput>
  surname?: Maybe<StringOperationFilterInput>
  culture?: Maybe<StringOperationFilterInput>
  id?: Maybe<StringOperationFilterInput>
  userName?: Maybe<StringOperationFilterInput>
  normalizedUserName?: Maybe<StringOperationFilterInput>
  email?: Maybe<StringOperationFilterInput>
  phoneNumber?: Maybe<StringOperationFilterInput>
  phoneNumberConfirmed?: Maybe<BooleanOperationFilterInput>
  twoFactorEnabled?: Maybe<BooleanOperationFilterInput>
  lockoutEnd?: Maybe<ComparableNullableOfDateTimeOffsetOperationFilterInput>
  lockoutEnabled?: Maybe<BooleanOperationFilterInput>
  accessFailedCount?: Maybe<ComparableInt32OperationFilterInput>
}

export type WmsUserResult = WmsUser | DatabaseError | ValidationError

export type WmsUserResultConnection = {
  __typename?: 'WMSUserResultConnection'
  pageInfo: PageInfo
  edges?: Maybe<Array<WmsUserResultEdge>>
  nodes?: Maybe<Array<Maybe<WmsUserResult>>>
  totalCount: Scalars['Int']
}

export type WmsUserResultEdge = {
  __typename?: 'WMSUserResultEdge'
  cursor: Scalars['String']
  node?: Maybe<WmsUserResult>
}

export type WmsUserSortInput = {
  concurrencyStamp?: Maybe<SortEnumType>
  emailConfirmed?: Maybe<SortEnumType>
  normalizedEmail?: Maybe<SortEnumType>
  passwordHash?: Maybe<SortEnumType>
  securityStamp?: Maybe<SortEnumType>
  forename?: Maybe<SortEnumType>
  surname?: Maybe<SortEnumType>
  culture?: Maybe<SortEnumType>
  id?: Maybe<SortEnumType>
  userName?: Maybe<SortEnumType>
  normalizedUserName?: Maybe<SortEnumType>
  email?: Maybe<SortEnumType>
  phoneNumber?: Maybe<SortEnumType>
  phoneNumberConfirmed?: Maybe<SortEnumType>
  twoFactorEnabled?: Maybe<SortEnumType>
  lockoutEnd?: Maybe<SortEnumType>
  lockoutEnabled?: Maybe<SortEnumType>
  accessFailedCount?: Maybe<SortEnumType>
}

export type Zone = {
  __typename?: 'Zone'
  id?: Maybe<Scalars['ID']>
  code?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  zoneType?: Maybe<ZoneType>
}

export type ZoneFilter = {
  code_starts_with?: Maybe<Scalars['String']>
  zoneTypeId?: Maybe<Scalars['ID']>
}

export type ZoneInput = {
  code: Scalars['String']
  description: Scalars['String']
  zoneType?: Maybe<Scalars['ID']>
}

export type ZoneType = {
  __typename?: 'ZoneType'
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
}

/**
 * One possible value for a given Enum. Enum values are unique values, not a
 * placeholder for a string or numeric value. However an Enum value is returned in
 * a JSON response as a string.
 */
export type __EnumValue = {
  __typename?: '__EnumValue'
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  isDeprecated: Scalars['Boolean']
  deprecationReason?: Maybe<Scalars['String']>
}

/**
 * Object and Interface types are described by a list of Fields, each of which has
 * a name, potentially a list of arguments, and a return type.
 */
export type __Field = {
  __typename?: '__Field'
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  args: Array<__InputValue>
  type: __Type
  isDeprecated: Scalars['Boolean']
  deprecationReason?: Maybe<Scalars['String']>
}

/**
 * Arguments provided to Fields or Directives and the input fields of an
 * InputObject are represented as Input Values which describe their type and
 * optionally a default value.
 */
export type __InputValue = {
  __typename?: '__InputValue'
  name: Scalars['String']
  description?: Maybe<Scalars['String']>
  type: __Type
  /** A GraphQL-formatted string representing the default value for this input value. */
  defaultValue?: Maybe<Scalars['String']>
}

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of
 * types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that
 * type. Scalar types provide no information beyond a name and description, while
 * Enum types provide their values. Object and Interface types provide the fields
 * they describe. Abstract types, Union and Interface, provide the Object types
 * possible at runtime. List and NonNull types compose other types.
 */
export type __Type = {
  __typename?: '__Type'
  kind: __TypeKind
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  fields?: Maybe<Array<__Field>>
  interfaces?: Maybe<Array<__Type>>
  possibleTypes?: Maybe<Array<__Type>>
  enumValues?: Maybe<Array<__EnumValue>>
  inputFields?: Maybe<Array<__InputValue>>
  ofType?: Maybe<__Type>
}

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of
 * types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that
 * type. Scalar types provide no information beyond a name and description, while
 * Enum types provide their values. Object and Interface types provide the fields
 * they describe. Abstract types, Union and Interface, provide the Object types
 * possible at runtime. List and NonNull types compose other types.
 */
export type __TypeFieldsArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>
}

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of
 * types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that
 * type. Scalar types provide no information beyond a name and description, while
 * Enum types provide their values. Object and Interface types provide the fields
 * they describe. Abstract types, Union and Interface, provide the Object types
 * possible at runtime. List and NonNull types compose other types.
 */
export type __TypeEnumValuesArgs = {
  includeDeprecated?: Maybe<Scalars['Boolean']>
}

/** An enum describing what kind of type a given `__Type` is. */
export enum __TypeKind {
  /** Indicates this type is a scalar. */
  Scalar = 'SCALAR',
  /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
  Object = 'OBJECT',
  /** Indicates this type is an interface. `fields` and `possibleTypes` are valid fields. */
  Interface = 'INTERFACE',
  /** Indicates this type is a union. `possibleTypes` is a valid field. */
  Union = 'UNION',
  /** Indicates this type is an enum. `enumValues` is a valid field. */
  Enum = 'ENUM',
  /** Indicates this type is an input object. `inputFields` is a valid field. */
  InputObject = 'INPUT_OBJECT',
  /** Indicates this type is a list. `ofType` is a valid field. */
  List = 'LIST',
  /** Indicates this type is a non-null. `ofType` is a valid field. */
  NonNull = 'NON_NULL'
}

export type ActivityFragment = { __typename?: 'Activity' } & Pick<Activity, 'id' | 'code' | 'name'>

export type AddressFragment = { __typename?: 'Address' } & Pick<
  Address,
  'city' | 'country' | 'county' | 'postCode' | 'street1' | 'street2'
>

export type AuditLogFragment = { __typename?: 'AuditEntry' } & Pick<
  AuditEntry,
  | 'id'
  | 'attributeId'
  | 'singles'
  | 'packs'
  | 'status'
  | 'packType'
  | 'code'
  | 'eventTime'
  | 'message'
  | 'metadata'
  | 'stockOwnerId'
  | 'userId'
  | 'warehouseId'
> & {
    attributes?: Maybe<
      { __typename?: 'StockAttributes' } & Pick<
        StockAttributes,
        | 'id'
        | 'batch'
        | 'batchInfo'
        | 'bestBeforeDate'
        | 'budgetHolder'
        | 'catchWeight'
        | 'GRN'
        | 'killDate'
        | 'pONumber'
        | 'productId'
        | 'productionDate'
        | 'sellByDate'
        | 'serialNumber'
        | 'slaughterCode'
      > & { product?: Maybe<{ __typename?: 'Product' } & Pick<Product, 'code' | 'description'>> }
    >
    auditCode?: Maybe<
      { __typename?: 'AuditCode' } & Pick<AuditCode, 'compareTo' | 'description' | 'id' | 'message'>
    >
    stockOwner?: Maybe<{ __typename?: 'StockOwner' } & Pick<StockOwner, 'code'>>
    user?: Maybe<{ __typename?: 'WMSUser' } & Pick<WmsUser, 'userName'>>
    warehouse?: Maybe<{ __typename?: 'Warehouse' } & Pick<Warehouse, 'code'>>
  }

export type BudgetHolderFragment = { __typename?: 'BudgetHolder' } & Pick<
  BudgetHolder,
  'id' | 'code' | 'description' | 'status'
>

export type BudgetFragment = { __typename?: 'DestinationBudget' } & Pick<
  DestinationBudget,
  'id' | 'maxSpend' | 'spent' | 'startDate'
>

export type CarrierFragment = { __typename?: 'Carrier' } & Pick<
  Carrier,
  | 'id'
  | 'code'
  | 'captureDelNo'
  | 'defDespatchLane'
  | 'defService'
  | 'module'
  | 'name'
  | 'requireService'
  | 'telephone'
>

export type CarrierAccountFragment = { __typename?: 'CarrierAccount' } & Pick<
  CarrierAccount,
  'id' | 'account' | 'allowedServices' | 'description'
> & {
    configuration?: Maybe<
      Array<
        Maybe<{ __typename?: 'CarrierConfig' } & Pick<CarrierConfig, 'id' | 'setting' | 'value'>>
      >
    >
  }

export type CarrierServiceFragment = { __typename?: 'CarrierService' } & Pick<
  CarrierService,
  | 'code'
  | 'collection'
  | 'description'
  | 'extraBoxInfo'
  | 'id'
  | 'localCollect'
  | 'maxHeight'
  | 'maxLength'
  | 'maxWeight'
  | 'maxWidth'
  | 'nextDay'
  | 'notifyEmail'
  | 'notifySMS'
  | 'recordDelivery'
  | 'references'
  | 'saturday'
  | 'signReq'
  | 'sunday'
  | 'trackingReq'
>

export type CategoryFragment = { __typename?: 'CategoryDefinition' } & Pick<
  CategoryDefinition,
  'id' | 'code' | 'description'
>

export type CodeFragment = { __typename?: 'UDCode' } & Pick<
  UdCode,
  'id' | 'code' | 'codeType' | 'description' | 'longDescription'
>

export type CodeDefinitionFragment = { __typename?: 'CodeDefinition' } & Pick<
  CodeDefinition,
  'id' | 'name' | 'description'
>

export type ContactFragment = { __typename?: 'Contact' } & Pick<
  Contact,
  'email' | 'fax' | 'name' | 'telephone'
>

export type DestinationFragment = { __typename?: 'Destination' } & Pick<
  Destination,
  | 'area'
  | 'ASNMethod'
  | 'ASNOnDespatch'
  | 'budgetholder'
  | 'captureSerialOnDesp'
  | 'category'
  | 'code'
  | 'custStkCodeOnDespNotes'
  | 'defDespLane'
  | 'defOrderPri'
  | 'defOrderType'
  | 'defRoute'
  | 'defSpecIns'
  | 'despMethod'
  | 'despNoteFormat'
  | 'facility'
  | 'FTPAcc'
  | 'id'
  | 'masterAcc'
  | 'name'
  | 'noDespNotes'
  | 'orderDay'
  | 'orderFreq'
  | 'palletMethod'
  | 'pickLocation'
  | 'printBoxLbls'
  | 'printBoxNotes'
  | 'printNotesDelayDesp'
  | 'reqManifest'
  | 'salesman'
  | 'suppressBackOrdClose'
  | 'transitTime'
  | 'usePalletMethod'
  | 'VATNo'
  | 'depot'
  | 'masterHub'
  | 'subHub'
  | 'status'
  | 'GLN'
  | 'customsId'
  | 'defDelGroup'
  | 'minPickValue'
  | 'noPickConsolidation'
> & { owner?: Maybe<{ __typename?: 'StockOwner' } & Pick<StockOwner, 'id' | 'code'>> }

export type DestinationCodeFragment = { __typename?: 'DestinationCode' } & Pick<
  DestinationCode,
  'id' | 'name' | 'description'
>

export type HandlingTypeFragment = { __typename?: 'HandlingType' } & Pick<
  HandlingType,
  'id' | 'code' | 'description'
>

export type LocationFragment = { __typename?: 'Location' } & Pick<
  Location,
  | 'id'
  | 'aisle'
  | 'attributes'
  | 'beam'
  | 'beamSequence'
  | 'checkCode'
  | 'code'
  | 'countZone'
  | 'elevation'
  | 'maxWeight'
  | 'pickZone'
  | 'physicalCharacteristics'
  | 'processCharacteristics'
  | 'status'
  | 'temperatureType'
  | 'tier'
  | 'zone'
> & {
    dimensions: { __typename?: 'Dimension' } & Pick<
      Dimension,
      'depth' | 'height' | 'width' | 'volume'
    >
    storageType?: Maybe<{ __typename?: 'StorageType' } & StorageTypeFragment>
  }

export type MaxWeeklySpendFragment = { __typename?: 'MaxWeeklySpend' } & Pick<
  MaxWeeklySpend,
  'id' | 'name'
>

export type MheFragment = { __typename?: 'MaterialHandlingEquipment' } & Pick<
  MaterialHandlingEquipment,
  'id' | 'code' | 'class' | 'description' | 'heightLimit' | 'maximumWeight' | 'width'
>

export type OrderEntryFragment = { __typename?: 'OrderEntry' } & Pick<
  OrderEntry,
  'id' | 'orderNumber' | 'priority' | 'soCustomerRef'
>

export type OrderStatusFragment = { __typename?: 'OrderStatus' } & Pick<
  OrderStatus,
  'id' | 'code' | 'description' | 'purpose'
>

export type OrderTypeFragment = { __typename?: 'OrderType' } & Pick<OrderType, 'id' | 'name'>

export type PoFormatFragment = { __typename?: 'POFormat' } & Pick<PoFormat, 'id' | 'name'>

export type PoTransmissionMethodFragment = { __typename?: 'POTransmissionMethod' } & Pick<
  PoTransmissionMethod,
  'id' | 'name'
>

export type PickfaceFragment = { __typename?: 'ProductPickface' } & Pick<
  ProductPickface,
  | 'id'
  | 'budhol'
  | 'location'
  | 'maxFaceQty'
  | 'pickZone'
  | 'replenMulti'
  | 'replenTriggr'
  | 'warehouse'
>

export type ProductFragment = { __typename?: 'Product' } & Pick<
  Product,
  | 'id'
  | 'code'
  | 'altCode'
  | 'gTIN'
  | 'substitute'
  | 'linkCode'
  | 'description'
  | 'longDesc'
  | 'defGRStatus'
  | 'SKUType'
  | 'type'
  | 'INCICode'
  | 'manufacturerCode'
  | 'StorageType'
  | 'rotation'
  | 'facilityCat'
  | 'defBudhol'
  | 'defWH'
  | 'defHandType'
  | 'defPackType'
  | 'depCode'
  | 'lifeCycleStatus'
  | 'rank'
  | 'uNCode'
  | 'bandQty'
  | 'minPShlfLife'
  | 'minRShlfLife'
  | 'totShlfLife'
  | 'packValidateBatch'
  | 'capturePackType'
  | 'pickPartPalletsAsFull'
  | 'pickSingles'
  | 'pickDefId'
> & {
    require?: Maybe<
      { __typename?: 'ProductRequiredData' } & Pick<
        ProductRequiredData,
        | 'arrivalTemp'
        | 'batch'
        | 'batchInfo'
        | 'bestBefore'
        | 'budgetHolder'
        | 'cartonLP'
        | 'expiry'
        | 'handlingType'
        | 'killDte'
        | 'originCntry'
        | 'packType'
        | 'palletLP'
        | 'productionDte'
        | 'serial'
        | 'serials'
        | 'slaughterCode'
        | 'status'
        | 'weight'
      >
    >
    suppliers?: Maybe<
      Array<
        Maybe<
          { __typename?: 'ProductSupplier' } & Pick<
            ProductSupplier,
            'id' | 'active' | 'supplierId' | 'warehouseId'
          > & {
              warehouse?: Maybe<
                { __typename?: 'Warehouse' } & Pick<Warehouse, 'code' | 'warehouseName'>
              >
              supplier?: Maybe<{ __typename?: 'Supplier' } & Pick<Supplier, 'code' | 'name'>>
            }
        >
      >
    >
    components?: Maybe<
      Array<
        Maybe<
          { __typename?: 'BOMDefinition' } & Pick<BomDefinition, 'id' | 'childId' | 'qty'> & {
              parent?: Maybe<{ __typename?: 'Product' } & Pick<Product, 'code'>>
              child?: Maybe<{ __typename?: 'Product' } & Pick<Product, 'code' | 'description'>>
            }
        >
      >
    >
  }

export type DocumentFragment = { __typename?: 'ProductDocument' } & Pick<
  ProductDocument,
  'id' | 'name' | 'type' | 'uploadBy' | 'uploaded' | 'usage'
>

export type LicencePlateFragment = { __typename?: 'LPDefinition' } & Pick<
  LpDefinition,
  | 'id'
  | 'depth'
  | 'diagram'
  | 'handType'
  | 'height'
  | 'layerQty'
  | 'maxLayerQty'
  | 'packType'
  | 'quantity'
  | 'weight'
  | 'width'
>

export type ProductStatusesFragment = { __typename?: 'ProductStatus' } & Pick<
  ProductStatus,
  'id' | 'code' | 'description' | 'adjustStock' | 'processStatus' | 'targetStatuses'
>

export type ProductSupplierFragment = { __typename?: 'ProductSupplier' } & Pick<
  ProductSupplier,
  'id' | 'active' | 'supplierId' | 'warehouseId'
>

export type RoutingFragment = { __typename?: 'DestinationRoute' } & Pick<
  DestinationRoute,
  'days' | 'dropNumber' | 'frequency' | 'id' | 'route'
> & { destination?: Maybe<{ __typename?: 'Destination' } & Pick<Destination, 'id' | 'code'>> }

export type StockFragment = { __typename?: 'Stock' } & Pick<
  Stock,
  | 'locationId'
  | 'productId'
  | 'status'
  | 'licencePlateId'
  | 'packType'
  | 'packs'
  | 'singles'
  | 'allocated'
> & {
    location?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'id' | 'code'>>
    product?: Maybe<{ __typename?: 'Product' } & Pick<Product, 'id' | 'code'>>
    licencePlate?: Maybe<{ __typename?: 'LicencePlate' } & Pick<LicencePlate, 'id' | 'LPN'>>
    attributes?: Maybe<
      { __typename?: 'StockAttributes' } & Pick<
        StockAttributes,
        | 'id'
        | 'batch'
        | 'batchInfo'
        | 'bestBeforeDate'
        | 'budgetHolder'
        | 'catchWeight'
        | 'GRN'
        | 'killDate'
        | 'pONumber'
        | 'productId'
        | 'productionDate'
        | 'sellByDate'
        | 'serialNumber'
        | 'slaughterCode'
      >
    >
  }

export type StockOwnerFragment = { __typename?: 'StockOwner' } & Pick<
  StockOwner,
  | 'id'
  | 'code'
  | 'name'
  | 'status'
  | 'GLN'
  | 'coldStoreID'
  | 'VATNumber'
  | 'warnMoveDifStatus'
  | 'requireBudhol'
  | 'requireProdCategories'
  | 'scanBarcodeMask'
  | 'keyedBarcodeMask'
  | 'captureSerialsOnRcpt'
  | 'requirePalletID'
  | 'printPalLblsOnRcpt'
  | 'capturePONumOnRcpt'
  | 'allocateLocOnRcpt'
  | 'closeComPoOnRcpt'
  | 'validateBatchPO'
  | 'capturePkgInfoOnRcpt'
  | 'putwayLPOnRcpt'
  | 'confirmPutawaysOnRcpt'
  | 'reqAuthOnGRClose'
  | 'defaultUnbookedRcpt'
  | 'strictPODateVal'
  | 'splitSuppTolerance'
  | 'RVPutToDefLoc'
  | 'disablePutForSpecificLocs'
  | 'mandExtraInfoOnRcpt'
  | 'captureContainerType'
  | 'F2ShowBookedProdsOnly'
  | 'autoGeneratePONumber'
  | 'allowEditInRV'
  | 'GRPrintCrtnLbls'
  | 'identicalPallets'
  | 'noBckFlushGRProduction'
  | 'matchGRWithBookNo'
  | 'RVSuppressMergePF'
  | 'spltPOLinesOnPrint'
  | 'makeGRNBudhol'
  | 'allowShortShelfLife'
  | 'allowAddProdsToPO'
  | 'captureLocOnRcpt'
  | 'captureTempOnRcpt'
  | 'printLblsGRProduction'
  | 'autoGenOrderNum'
  | 'dispOrderEntryErrs'
  | 'requirePOD'
  | 'createPickOnOrdEntry'
  | 'dontClearLineCustRef'
  | 'mandLineCustref'
  | 'warnOnShortage'
  | 'printOrdRptWithPick'
  | 'forceBudholOnEntry'
  | 'preventBatchOnEntry'
  | 'swapGenericsOnEntry'
  | 'GDPRControlled'
  | 'printOrdConfRpt'
  | 'printBoxPackLbls'
  | 'cleanOrdersOnly'
  | 'createBackOrders'
  | 'allowShortageSwap'
  | 'allowBudholSwap'
  | 'chngeTraysOnPri'
  | 'noReplenPicksOnly'
  | 'allowStatusSwap'
  | 'pickCartons'
  | 'MPNoSplitLists'
  | 'showUnpickableRpt'
  | 'forceDepartmentOnEntry'
  | 'allowLPSwap'
  | 'allowSBDSwap'
  | 'findCartonByWgt'
  | 'captureCarrier'
  | 'allowLocSwap'
  | 'useBOMPicking'
  | 'requireDespNote'
  | 'voicePickAssignment'
  | 'allowBatchSwap'
  | 'requireConfProduct'
  | 'requireUPICheck'
  | 'cageValidation'
  | 'suppressReplenOpt'
  | 'despNoteValidation'
  | 'RFPickAssignment'
  | 'useLoading'
  | 'produceRFManifest'
  | 'requireSealNo'
  | 'useLoadOrder'
  | 'requireTruck'
  | 'requireDriver'
  | 'loadOrder'
  | 'despLane'
  | 'maxLoaders'
  | 'adhocLoading'
  | 'loadTempCaptureStart'
  | 'loadTempCaptureEnd'
  | 'loadTempLPInterval'
  | 'captureTimeOnConfirm'
  | 'captureCheckerOnConfirm'
  | 'capturePickerOnConfirm'
  | 'defPickFailCode'
  | 'requireDespatchLane'
  | 'useEmailFromDestination'
  | 'reprntUnfinishedLists'
  | 'destockShortOnSHort'
  | 'proportionalBoxesOnConfirm'
  | 'allowLoadAnySeq'
  | 'MBPLPControl'
  | 'MBPUseSODespMeth'
  | 'noDespNotes'
  | 'previewDespNote'
  | 'BOMSToStock'
  | 'delayDespatch'
  | 'RFWarnInPckConf'
  | 'noReplenPckConf'
  | 'despNotePerList'
  | 'disablImmDesp'
  | 'allowFutureDespDates'
  | 'MBPCaptureConsNo'
  | 'optConsNo'
  | 'boxLblsPickConf'
  | 'useLPIdsPickConfirm'
  | 'authMBP'
  | 'mandContainerType'
  | 'PDFDespNotes'
  | 'useDespPrefix'
  | 'noOfDespNotes'
  | 'confWholePick'
  | 'capReasonDelayDesp'
  | 'MBPDespNoteBreakdwn'
  | 'dontCloseOrderOnLblFail'
  | 'capBoxesPerOrder'
  | 'carrierNotReq'
  | 'copyCarrToBckOrd'
  | 'printBoxLbls'
  | 'noCarrLblsPckConf'
>

export type CategoryDefinitionFragment = { __typename?: 'CategoryDefinition' } & Pick<
  CategoryDefinition,
  'id' | 'code' | 'description'
>

export type PickConfirmMethodFragment = { __typename?: 'PickConfirmMethod' } & Pick<
  PickConfirmMethod,
  | 'id'
  | 'name'
  | 'default'
  | 'pickConfMethod'
  | 'postConfMethod'
  | 'packingMethod'
  | 'despatchMethod'
  | 'loadCloseMethod'
>

export type PickMethodFragment = { __typename?: 'PickMethod' } & Pick<
  PickMethod,
  'id' | 'default' | 'name' | 'outputMethod' | 'pickingMethod' | 'postPickMethod' | 'prePickMethod'
>

export type StorageTypeFragment = { __typename?: 'StorageType' } & Pick<
  StorageType,
  'id' | 'code' | 'description'
>

export type SupplierFragment = { __typename?: 'Supplier' } & Pick<
  Supplier,
  | 'id'
  | 'code'
  | 'name'
  | 'currency'
  | 'fileFormat'
  | 'fileTransMeth'
  | 'leadTime'
  | 'master'
  | 'maxWeekSpend'
  | 'mustOrderLayerQty'
  | 'orderDays'
>

export type UserFragment = { __typename?: 'WMSUser' } & Pick<
  WmsUser,
  | 'id'
  | 'userName'
  | 'accessFailedCount'
  | 'culture'
  | 'email'
  | 'forename'
  | 'lockoutEnd'
  | 'normalizedUserName'
  | 'phoneNumber'
  | 'serverRole'
  | 'surname'
  | 'twoFactorEnabled'
>

export type UserPermissionFragment = { __typename?: 'UserPermission' } & Pick<
  UserPermission,
  'id' | 'permissions' | 'stockOwnerId' | 'userId' | 'warehouseId'
> & {
    warehouse?: Maybe<{ __typename?: 'Warehouse' } & Pick<Warehouse, 'code' | 'warehouseName'>>
    stockOwner?: Maybe<{ __typename?: 'StockOwner' } & Pick<StockOwner, 'code' | 'name'>>
  }

export type WarehouseFragment = { __typename?: 'Warehouse' } & Pick<
  Warehouse,
  'id' | 'code' | 'warehouseName' | 'companyName' | 'telephone' | 'VATNumber' | 'customsId'
>

export type ZoneFragment = { __typename?: 'Zone' } & Pick<Zone, 'id' | 'code' | 'description'>

export type WarehouseZoneFragment = { __typename?: 'WarehouseZone' } & Pick<
  WarehouseZone,
  'id' | 'code' | 'description'
>

export type ZoneTypeFragment = { __typename?: 'ZoneType' } & Pick<ZoneType, 'id' | 'name'>

export type CreateBudgetHolderMutationVariables = {
  input?: Maybe<BudgetHolderInput>
  userContext?: Maybe<UserContextInput>
}

export type CreateBudgetHolderMutation = { __typename?: 'Mutation' } & {
  createBudgetHolder?: Maybe<{ __typename?: 'BudgetHolder' } & BudgetHolderFragment>
}

export type UpdateBudgetHolderMutationVariables = {
  id: Scalars['Int']
  input?: Maybe<BudgetHolderUpdateInput>
  userContext?: Maybe<UserContextInput>
}

export type UpdateBudgetHolderMutation = { __typename?: 'Mutation' } & {
  updateBudgetHolder?: Maybe<{ __typename?: 'BudgetHolder' } & BudgetHolderFragment>
}

export type DeleteBudgetHolderMutationVariables = {
  id: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type DeleteBudgetHolderMutation = { __typename?: 'Mutation' } & {
  deleteBudgetHolder?: Maybe<{ __typename?: 'BudgetHolder' } & Pick<BudgetHolder, 'id'>>
}

export type CreateCarrierMutationVariables = {
  input?: Maybe<CarrierInput>
  userContext?: Maybe<UserContextInput>
}

export type CreateCarrierMutation = { __typename?: 'Mutation' } & {
  createCarrier?: Maybe<
    { __typename?: 'Carrier' } & {
      accounts?: Maybe<Array<Maybe<{ __typename?: 'CarrierAccount' } & CarrierAccountFragment>>>
      services?: Maybe<Array<Maybe<{ __typename?: 'CarrierService' } & CarrierServiceFragment>>>
    } & CarrierFragment
  >
}

export type UpdateCarrierMutationVariables = {
  id: Scalars['Int']
  input?: Maybe<CarrierUpdateInput>
  userContext?: Maybe<UserContextInput>
}

export type UpdateCarrierMutation = { __typename?: 'Mutation' } & {
  updateCarrier?: Maybe<
    { __typename?: 'Carrier' } & {
      accounts?: Maybe<Array<Maybe<{ __typename?: 'CarrierAccount' } & CarrierAccountFragment>>>
      services?: Maybe<Array<Maybe<{ __typename?: 'CarrierService' } & CarrierServiceFragment>>>
    } & CarrierFragment
  >
}

export type DeleteCarrierMutationVariables = {
  id: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type DeleteCarrierMutation = { __typename?: 'Mutation' } & {
  deleteCarrier?: Maybe<{ __typename?: 'Carrier' } & Pick<Carrier, 'id'>>
}

export type CreateCarrierAccountMutationVariables = {
  carrierId: Scalars['Int']
  input?: Maybe<CarrierAccountInput>
  userContext?: Maybe<UserContextInput>
}

export type CreateCarrierAccountMutation = { __typename?: 'Mutation' } & {
  createCarrierAccount?: Maybe<
    { __typename?: 'CarrierAccount' } & Pick<CarrierAccount, 'id' | 'account'>
  >
}

export type UpdateCarrierAccountMutationVariables = {
  id: Scalars['Int']
  carrierId: Scalars['Int']
  input?: Maybe<CarrierAccountUpdateInput>
  userContext?: Maybe<UserContextInput>
}

export type UpdateCarrierAccountMutation = { __typename?: 'Mutation' } & {
  updateCarrierAccount?: Maybe<
    { __typename?: 'CarrierAccount' } & Pick<CarrierAccount, 'id' | 'account'>
  >
}

export type DeleteCarrierAccountMutationVariables = {
  id: Scalars['Int']
  carrierId: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type DeleteCarrierAccountMutation = { __typename?: 'Mutation' } & {
  deleteCarrierAccount?: Maybe<
    { __typename?: 'CarrierAccount' } & Pick<CarrierAccount, 'id' | 'account'>
  >
}

export type AddCarrierConfigMutationVariables = {
  accountId: Scalars['Int']
  carrierId: Scalars['Int']
  config?: Maybe<CarrierConfigInput>
  userContext?: Maybe<UserContextInput>
}

export type AddCarrierConfigMutation = { __typename?: 'Mutation' } & {
  addCarrierConfig?: Maybe<
    { __typename?: 'CarrierConfig' } & Pick<CarrierConfig, 'id' | 'setting' | 'value'>
  >
}

export type UpdateCarrierConfigMutationVariables = {
  accountId: Scalars['Int']
  carrierId: Scalars['Int']
  id: Scalars['Int']
  config?: Maybe<CarrierConfigUpdateInput>
  userContext?: Maybe<UserContextInput>
}

export type UpdateCarrierConfigMutation = { __typename?: 'Mutation' } & {
  updateCarrierConfig?: Maybe<
    { __typename?: 'CarrierConfig' } & Pick<CarrierConfig, 'id' | 'setting' | 'value'>
  >
}

export type RemoveCarrierConfigMutationVariables = {
  accountId: Scalars['Int']
  carrierId: Scalars['Int']
  id: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type RemoveCarrierConfigMutation = { __typename?: 'Mutation' } & {
  removeCarrierConfig?: Maybe<
    { __typename?: 'CarrierConfig' } & Pick<CarrierConfig, 'id' | 'setting' | 'value'>
  >
}

export type CreatCarrierServiceMutationVariables = {
  carrierId: Scalars['Int']
  input?: Maybe<CarrierServiceInput>
  userContext?: Maybe<UserContextInput>
}

export type CreatCarrierServiceMutation = { __typename?: 'Mutation' } & {
  createCarrierService?: Maybe<
    { __typename?: 'CarrierService' } & Pick<CarrierService, 'id' | 'code'>
  >
}

export type UpdateCarrierServiceMutationVariables = {
  id: Scalars['Int']
  carrierId: Scalars['Int']
  input?: Maybe<CarrierServiceUpdateInput>
  userContext?: Maybe<UserContextInput>
}

export type UpdateCarrierServiceMutation = { __typename?: 'Mutation' } & {
  updateCarrierService?: Maybe<
    { __typename?: 'CarrierService' } & Pick<CarrierService, 'id' | 'code'>
  >
}

export type DeleteCarrierServiceMutationVariables = {
  id: Scalars['Int']
  carrierId: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type DeleteCarrierServiceMutation = { __typename?: 'Mutation' } & {
  deleteCarrierService?: Maybe<
    { __typename?: 'CarrierService' } & Pick<CarrierService, 'id' | 'code'>
  >
}

export type CreateUdcMutationVariables = {
  input?: Maybe<UdCodeInput>
  userContext?: Maybe<UserContextInput>
}

export type CreateUdcMutation = { __typename?: 'Mutation' } & {
  createUDC?: Maybe<{ __typename?: 'UDCode' } & CodeFragment>
}

export type UpdateUdcMutationVariables = {
  id: Scalars['Int']
  input?: Maybe<UdCodeUpdateInput>
  userContext?: Maybe<UserContextInput>
}

export type UpdateUdcMutation = { __typename?: 'Mutation' } & {
  updateUDC?: Maybe<{ __typename?: 'UDCode' } & CodeFragment>
}

export type DeleteUdcMutationVariables = {
  id: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type DeleteUdcMutation = { __typename?: 'Mutation' } & {
  deleteUDC?: Maybe<{ __typename?: 'UDCode' } & CodeFragment>
}

export type CreateDestinationMutationVariables = {
  input?: Maybe<DestinationInput>
  userContext?: Maybe<UserContextInput>
}

export type CreateDestinationMutation = { __typename?: 'Mutation' } & {
  createDestination?: Maybe<
    { __typename?: 'Destination' } & {
      address: { __typename?: 'Address' } & AddressFragment
      budgets?: Maybe<Array<Maybe<{ __typename?: 'DestinationBudget' } & BudgetFragment>>>
      contact?: Maybe<{ __typename?: 'Contact' } & ContactFragment>
      routing?: Maybe<Array<Maybe<{ __typename?: 'DestinationRoute' } & RoutingFragment>>>
    } & DestinationFragment
  >
}

export type UpdateDestinationMutationVariables = {
  id: Scalars['Int']
  input?: Maybe<DestinationUpdateInput>
  userContext?: Maybe<UserContextInput>
}

export type UpdateDestinationMutation = { __typename?: 'Mutation' } & {
  updateDestination?: Maybe<
    { __typename?: 'Destination' } & {
      address: { __typename?: 'Address' } & AddressFragment
      budgets?: Maybe<Array<Maybe<{ __typename?: 'DestinationBudget' } & BudgetFragment>>>
      contact?: Maybe<{ __typename?: 'Contact' } & ContactFragment>
      routing?: Maybe<Array<Maybe<{ __typename?: 'DestinationRoute' } & RoutingFragment>>>
    } & DestinationFragment
  >
}

export type DeleteDestinationMutationVariables = {
  id: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type DeleteDestinationMutation = { __typename?: 'Mutation' } & {
  deleteDestination?: Maybe<{ __typename?: 'Destination' } & Pick<Destination, 'id'>>
}

export type CreateDestiantnionBudgetMutationVariables = {
  destinationId: Scalars['Int']
  input?: Maybe<DestinationBudgetInput>
  userContext?: Maybe<UserContextInput>
}

export type CreateDestiantnionBudgetMutation = { __typename?: 'Mutation' } & {
  createDestinationBudget?: Maybe<
    { __typename?: 'DestinationBudget' } & Pick<DestinationBudget, 'id'>
  >
}

export type UpdateDestiantnionBudgetMutationVariables = {
  destinationId: Scalars['Int']
  id: Scalars['Int']
  input?: Maybe<DestinationBudgetUpdateInput>
  userContext?: Maybe<UserContextInput>
}

export type UpdateDestiantnionBudgetMutation = { __typename?: 'Mutation' } & {
  updateDestinationBudget?: Maybe<
    { __typename?: 'DestinationBudget' } & Pick<DestinationBudget, 'id'>
  >
}

export type DeleteDestinationBudgetMutationVariables = {
  destinationId: Scalars['Int']
  id: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type DeleteDestinationBudgetMutation = { __typename?: 'Mutation' } & {
  deleteDestinationBudget?: Maybe<
    { __typename?: 'DestinationBudget' } & Pick<DestinationBudget, 'id'>
  >
}

export type CreateDestinationRouteMutationVariables = {
  destinationId: Scalars['Int']
  input?: Maybe<DestinationRouteInput>
  userContext?: Maybe<UserContextInput>
}

export type CreateDestinationRouteMutation = { __typename?: 'Mutation' } & {
  createDestinationRoute?: Maybe<{ __typename?: 'DestinationRoute' } & RoutingFragment>
}

export type UpdateDestinationRouteMutationVariables = {
  id: Scalars['Int']
  destinationId: Scalars['Int']
  input?: Maybe<DestinationRouteUpdateInput>
  userContext?: Maybe<UserContextInput>
}

export type UpdateDestinationRouteMutation = { __typename?: 'Mutation' } & {
  updateDestinationRoute?: Maybe<{ __typename?: 'DestinationRoute' } & RoutingFragment>
}

export type DeleteDestinationRouteMutationVariables = {
  id: Scalars['Int']
  destinationId: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type DeleteDestinationRouteMutation = { __typename?: 'Mutation' } & {
  deleteDestinationRoute?: Maybe<{ __typename?: 'DestinationRoute' } & Pick<DestinationRoute, 'id'>>
}

export type CreateHandlingTypeMutationVariables = {
  input?: Maybe<HandlingTypeInput>
  userContext?: Maybe<UserContextInput>
}

export type CreateHandlingTypeMutation = { __typename?: 'Mutation' } & {
  createHandlingType?: Maybe<
    { __typename?: 'HandlingType' } & {
      storageTypes?: Maybe<
        Array<
          Maybe<
            { __typename?: 'HandlingStorageType' } & {
              storageType?: Maybe<{ __typename?: 'StorageType' } & StorageTypeFragment>
            }
          >
        >
      >
    } & HandlingTypeFragment
  >
}

export type DeleteHandlingTypeMutationVariables = {
  id: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type DeleteHandlingTypeMutation = { __typename?: 'Mutation' } & {
  deleteHandlingType?: Maybe<{ __typename?: 'HandlingType' } & HandlingTypeFragment>
}

export type UpdateHandlingTypeMutationVariables = {
  id: Scalars['Int']
  input?: Maybe<HandlingTypeUpdateInput>
  userContext?: Maybe<UserContextInput>
}

export type UpdateHandlingTypeMutation = { __typename?: 'Mutation' } & {
  updateHandlingType?: Maybe<
    { __typename?: 'HandlingType' } & {
      storageTypes?: Maybe<
        Array<
          Maybe<
            { __typename?: 'HandlingStorageType' } & {
              storageType?: Maybe<{ __typename?: 'StorageType' } & StorageTypeFragment>
            }
          >
        >
      >
    } & HandlingTypeFragment
  >
}

export type AddHandlingStorageTypeMutationVariables = {
  id: Scalars['Int']
  storageTypeId: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type AddHandlingStorageTypeMutation = { __typename?: 'Mutation' } & {
  addHandlingStorageType?: Maybe<
    { __typename?: 'HandlingStorageType' } & Pick<
      HandlingStorageType,
      'handlingTypeId' | 'storageTypeId'
    >
  >
}

export type RemoveHandlingStorageTypeMutationVariables = {
  id: Scalars['Int']
  storageTypeId: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type RemoveHandlingStorageTypeMutation = { __typename?: 'Mutation' } & {
  removeHandlingStorageType?: Maybe<
    { __typename?: 'HandlingStorageType' } & Pick<
      HandlingStorageType,
      'handlingTypeId' | 'storageTypeId'
    >
  >
}

export type AddStockOwnerLinkMutationVariables = {
  linkedStockOwnerId: Scalars['Int']
  stockOwnerId: Scalars['Int']
}

export type AddStockOwnerLinkMutation = { __typename?: 'Mutation' } & {
  addStockOwnerLink?: Maybe<
    { __typename?: 'StockOwnerLink' } & {
      child?: Maybe<{ __typename?: 'StockOwner' } & Pick<StockOwner, 'code'>>
    }
  >
}

export type RemoveStockOwnerLinkMutationVariables = {
  linkedStockOwnerId: Scalars['Int']
  stockOwnerId: Scalars['Int']
}

export type RemoveStockOwnerLinkMutation = { __typename?: 'Mutation' } & {
  removeStockOwnerLink?: Maybe<
    { __typename?: 'StockOwnerLink' } & {
      child?: Maybe<{ __typename?: 'StockOwner' } & Pick<StockOwner, 'code'>>
    }
  >
}

export type CreateLocationMutationVariables = {
  input?: Maybe<LocationInput>
  userContext?: Maybe<UserContextInput>
}

export type CreateLocationMutation = { __typename?: 'Mutation' } & {
  createLocation?: Maybe<{ __typename?: 'Location' } & LocationFragment>
}

export type UpdateLocationMutationVariables = {
  id: Scalars['Int']
  input?: Maybe<LocationUpdateInput>
  userContext?: Maybe<UserContextInput>
}

export type UpdateLocationMutation = { __typename?: 'Mutation' } & {
  updateLocation?: Maybe<{ __typename?: 'Location' } & LocationFragment>
}

export type DeleteLocationMutationVariables = {
  id: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type DeleteLocationMutation = { __typename?: 'Mutation' } & {
  deleteLocation?: Maybe<{ __typename?: 'Location' } & LocationFragment>
}

export type CreateMheMutationVariables = {
  input?: Maybe<MaterialHandlingEquipmentInput>
  userContext?: Maybe<UserContextInput>
}

export type CreateMheMutation = { __typename?: 'Mutation' } & {
  createMaterialHandlingEquipment?: Maybe<
    { __typename?: 'MaterialHandlingEquipment' } & MheFragment
  >
}

export type UpdateMheMutationVariables = {
  id: Scalars['Int']
  input?: Maybe<MaterialHandlingEquipmentUpdateInput>
  userContext?: Maybe<UserContextInput>
}

export type UpdateMheMutation = { __typename?: 'Mutation' } & {
  updateMaterialHandlingEquipment?: Maybe<
    { __typename?: 'MaterialHandlingEquipment' } & MheFragment
  >
}

export type DeleteMheMutationVariables = {
  id: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type DeleteMheMutation = { __typename?: 'Mutation' } & {
  deleteMaterialHandlingEquipment?: Maybe<
    { __typename?: 'MaterialHandlingEquipment' } & Pick<MaterialHandlingEquipment, 'id'>
  >
}

export type CreateOrderEntryMutationVariables = {
  input: CreateOrderEntryInput
}

export type CreateOrderEntryMutation = { __typename?: 'Mutation' } & {
  createOrderEntry?: Maybe<
    { __typename?: 'OrderEntry' } & {
      destinationCode: { __typename?: 'DestinationCode' } & DestinationCodeFragment
      budgetHolder: { __typename?: 'BudgetHolder' } & BudgetHolderFragment
      orderType: { __typename?: 'OrderType' } & OrderTypeFragment
    } & OrderEntryFragment
  >
}

export type DeleteOrderEntryMutationVariables = {
  id: Scalars['ID']
}

export type DeleteOrderEntryMutation = { __typename?: 'Mutation' } & {
  deleteOrderEntry?: Maybe<{ __typename?: 'OrderEntry' } & OrderEntryFragment>
}

export type UpdateOrderEntryMutationVariables = {
  id: Scalars['ID']
  input: UpdateOrderEntryInput
}

export type UpdateOrderEntryMutation = { __typename?: 'Mutation' } & {
  updateOrderEntry?: Maybe<
    { __typename?: 'OrderEntry' } & {
      destinationCode: { __typename?: 'DestinationCode' } & DestinationCodeFragment
      budgetHolder: { __typename?: 'BudgetHolder' } & BudgetHolderFragment
      orderType: { __typename?: 'OrderType' } & OrderTypeFragment
    } & OrderEntryFragment
  >
}

export type CreateProductMutationVariables = {
  input?: Maybe<ProductInput>
  userContext?: Maybe<UserContextInput>
}

export type CreateProductMutation = { __typename?: 'Mutation' } & {
  createProduct?: Maybe<{ __typename?: 'Product' } & ProductFragment>
}

export type UpdateProductMutationVariables = {
  id: Scalars['Int']
  input?: Maybe<ProductUpdateInput>
  userContext?: Maybe<UserContextInput>
}

export type UpdateProductMutation = { __typename?: 'Mutation' } & {
  updateProduct?: Maybe<{ __typename?: 'Product' } & ProductFragment>
}

export type DeleteProductMutationVariables = {
  id: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type DeleteProductMutation = { __typename?: 'Mutation' } & {
  deleteProduct?: Maybe<{ __typename?: 'Product' } & ProductFragment>
}

export type CreateProductBomMutationVariables = {
  component?: Maybe<BomDefinitionInput>
  productId: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type CreateProductBomMutation = { __typename?: 'Mutation' } & {
  createBOMDefinition?: Maybe<
    { __typename?: 'BOMDefinition' } & Pick<BomDefinition, 'id' | 'childId' | 'qty'>
  >
}

export type UpdateProductBomMutationVariables = {
  componentId: Scalars['Int']
  productId: Scalars['Int']
  update?: Maybe<BomDefinitionUpdateInput>
  userContext?: Maybe<UserContextInput>
}

export type UpdateProductBomMutation = { __typename?: 'Mutation' } & {
  updateBOMDefinition?: Maybe<
    { __typename?: 'BOMDefinition' } & Pick<BomDefinition, 'id' | 'childId' | 'qty'>
  >
}

export type DeleteProductBomMutationVariables = {
  componentId: Scalars['Int']
  productId: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type DeleteProductBomMutation = { __typename?: 'Mutation' } & {
  deleteBOMDefinition?: Maybe<{ __typename?: 'BOMDefinition' } & Pick<BomDefinition, 'id'>>
}

export type CreateProductDocumentMutationVariables = {
  productId: Scalars['Int']
  productDocument?: Maybe<ProductDocumentInput>
  userContext?: Maybe<UserContextInput>
}

export type CreateProductDocumentMutation = { __typename?: 'Mutation' } & {
  createProductDocument?: Maybe<{ __typename?: 'ProductDocument' } & DocumentFragment>
}

export type UpdateProductDocumentMutationVariables = {
  id: Scalars['Int']
  productId: Scalars['Int']
  update?: Maybe<ProductDocumentUpdateInput>
  userContext?: Maybe<UserContextInput>
}

export type UpdateProductDocumentMutation = { __typename?: 'Mutation' } & {
  updateProductDocument?: Maybe<{ __typename?: 'ProductDocument' } & DocumentFragment>
}

export type DeleteProductDocumentMutationVariables = {
  id: Scalars['Int']
  productId: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type DeleteProductDocumentMutation = { __typename?: 'Mutation' } & {
  deleteProductDocument?: Maybe<{ __typename?: 'ProductDocument' } & Pick<ProductDocument, 'id'>>
}

export type CreateProductLicencePlateMutationVariables = {
  licencePlate?: Maybe<LpDefinitionInput>
  productId: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type CreateProductLicencePlateMutation = { __typename?: 'Mutation' } & {
  createProductLicencePlate?: Maybe<{ __typename?: 'LPDefinition' } & LicencePlateFragment>
}

export type UpdateProductLicencePlateMutationVariables = {
  id: Scalars['Int']
  productId: Scalars['Int']
  update?: Maybe<LpDefinitionUpdateInput>
  userContext?: Maybe<UserContextInput>
}

export type UpdateProductLicencePlateMutation = { __typename?: 'Mutation' } & {
  updateProductLicencePlate?: Maybe<{ __typename?: 'LPDefinition' } & LicencePlateFragment>
}

export type DeleteProductLicencePlateMutationVariables = {
  id: Scalars['Int']
  productId: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type DeleteProductLicencePlateMutation = { __typename?: 'Mutation' } & {
  deleteProductLicencePlate?: Maybe<{ __typename?: 'LPDefinition' } & Pick<LpDefinition, 'id'>>
}

export type CreateProductPickfaceMutationVariables = {
  input?: Maybe<ProductPickfaceInput>
  productId: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type CreateProductPickfaceMutation = { __typename?: 'Mutation' } & {
  createProductPickface?: Maybe<{ __typename?: 'ProductPickface' } & PickfaceFragment>
}

export type UpdateProductPickfaceMutationVariables = {
  update?: Maybe<ProductPickfaceUpdateInput>
  id: Scalars['Int']
  productId: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type UpdateProductPickfaceMutation = { __typename?: 'Mutation' } & {
  updateProductPickface?: Maybe<{ __typename?: 'ProductPickface' } & PickfaceFragment>
}

export type DeleteProductPickfaceMutationVariables = {
  id: Scalars['Int']
  productId: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type DeleteProductPickfaceMutation = { __typename?: 'Mutation' } & {
  deleteProductPickface?: Maybe<{ __typename?: 'ProductPickface' } & Pick<ProductPickface, 'id'>>
}

export type UpdateProductSupplierMutationVariables = {
  id: Scalars['Int']
  productId: Scalars['Int']
  input?: Maybe<ProductSupplierUpdateInput>
  userContext?: Maybe<UserContextInput>
}

export type UpdateProductSupplierMutation = { __typename?: 'Mutation' } & {
  updateProductSupplier?: Maybe<{ __typename?: 'ProductSupplier' } & ProductSupplierFragment>
}

export type CreateProductSupplierMutationVariables = {
  productId: Scalars['Int']
  input?: Maybe<ProductSupplierInput>
  userContext?: Maybe<UserContextInput>
}

export type CreateProductSupplierMutation = { __typename?: 'Mutation' } & {
  createProductSupplier?: Maybe<{ __typename?: 'ProductSupplier' } & ProductSupplierFragment>
}

export type DeleteProductSupplierMutationVariables = {
  id: Scalars['Int']
  productId: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type DeleteProductSupplierMutation = { __typename?: 'Mutation' } & {
  deleteProductSupplier?: Maybe<{ __typename?: 'ProductSupplier' } & Pick<ProductSupplier, 'id'>>
}

export type CreateStockOwnerMutationVariables = {
  input?: Maybe<StockOwnerInput>
}

export type CreateStockOwnerMutation = { __typename?: 'Mutation' } & {
  createStockOwner?: Maybe<{ __typename?: 'StockOwner' } & StockOwnerFragment>
}

export type UpdateStockOwnerMutationVariables = {
  id: Scalars['Int']
  input?: Maybe<StockOwnerUpdateInput>
  userContext?: Maybe<UserContextInput>
}

export type UpdateStockOwnerMutation = { __typename?: 'Mutation' } & {
  updateStockOwner?: Maybe<{ __typename?: 'StockOwner' } & StockOwnerFragment>
}

export type DeleteStockOwnerMutationVariables = {
  id: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type DeleteStockOwnerMutation = { __typename?: 'Mutation' } & {
  deleteStockOwner?: Maybe<{ __typename?: 'StockOwner' } & StockOwnerFragment>
}

export type UpdateStockOwnerCategoryMutationVariables = {
  id: Scalars['Int']
  stockOwnerId: Scalars['Int']
  input?: Maybe<CategoryDefinitionUpdateInput>
}

export type UpdateStockOwnerCategoryMutation = { __typename?: 'Mutation' } & {
  updateStockOwnerCategoryDefinition?: Maybe<
    { __typename?: 'CategoryDefinition' } & CategoryDefinitionFragment
  >
}

export type CreateStockOwnerCategoyMutationVariables = {
  stockOwnerId: Scalars['Int']
  input?: Maybe<CategoryDefinitionInput>
}

export type CreateStockOwnerCategoyMutation = { __typename?: 'Mutation' } & {
  createStockOwnerCategoryDefinition?: Maybe<
    { __typename?: 'CategoryDefinition' } & CategoryDefinitionFragment
  >
}

export type DeleteStockOwnerCategoryMutationVariables = {
  stockOwnerId: Scalars['Int']
  id: Scalars['Int']
}

export type DeleteStockOwnerCategoryMutation = { __typename?: 'Mutation' } & {
  deleteStockOwnerCategoryDefinition?: Maybe<
    { __typename?: 'CategoryDefinition' } & Pick<CategoryDefinition, 'id'>
  >
}

export type UpdateStockOwnerPickConfirmMethodMutationVariables = {
  id: Scalars['Int']
  stockOwnerId: Scalars['Int']
  input?: Maybe<PickConfirmMethodUpdateInput>
}

export type UpdateStockOwnerPickConfirmMethodMutation = { __typename?: 'Mutation' } & {
  updateStockOwnerPickConfirmMethod?: Maybe<
    { __typename?: 'PickConfirmMethod' } & PickConfirmMethodFragment
  >
}

export type CreateStockOwnerPickConfirmMethodMutationVariables = {
  stockOwnerId: Scalars['Int']
  input?: Maybe<PickConfirmMethodInput>
}

export type CreateStockOwnerPickConfirmMethodMutation = { __typename?: 'Mutation' } & {
  createStockOwnerPickConfirmMethod?: Maybe<
    { __typename?: 'PickConfirmMethod' } & PickConfirmMethodFragment
  >
}

export type DeleteStockOwnerPickConfirmMethodMutationVariables = {
  stockOwnerId: Scalars['Int']
  id: Scalars['Int']
}

export type DeleteStockOwnerPickConfirmMethodMutation = { __typename?: 'Mutation' } & {
  deleteStockOwnerPickConfirmMethod?: Maybe<
    { __typename?: 'PickConfirmMethod' } & Pick<PickConfirmMethod, 'id'>
  >
}

export type UpdateOrderStatusMutationVariables = {
  id: Scalars['Int']
  stockOwnerId: Scalars['Int']
  input?: Maybe<OrderStatusUpdateInput>
}

export type UpdateOrderStatusMutation = { __typename?: 'Mutation' } & {
  updateOrderStatus?: Maybe<{ __typename?: 'OrderStatus' } & OrderStatusFragment>
}

export type CreateOrderStatusMutationVariables = {
  stockOwnerId: Scalars['Int']
  input?: Maybe<OrderStatusInput>
}

export type CreateOrderStatusMutation = { __typename?: 'Mutation' } & {
  createOrderStatus?: Maybe<{ __typename?: 'OrderStatus' } & OrderStatusFragment>
}

export type DeleteOrderStatusMutationVariables = {
  stockOwnerId: Scalars['Int']
  id: Scalars['Int']
}

export type DeleteOrderStatusMutation = { __typename?: 'Mutation' } & {
  deleteOrderStatus?: Maybe<{ __typename?: 'OrderStatus' } & OrderStatusFragment>
}

export type UpdateStockOwnerPickMethodMutationVariables = {
  id: Scalars['Int']
  stockOwnerId: Scalars['Int']
  input?: Maybe<PickMethodUpdateInput>
}

export type UpdateStockOwnerPickMethodMutation = { __typename?: 'Mutation' } & {
  updateStockOwnerPickMethod?: Maybe<{ __typename?: 'PickMethod' } & PickMethodFragment>
}

export type CreateStockOwnerPickMethodMutationVariables = {
  stockOwnerId: Scalars['Int']
  input?: Maybe<PickMethodInput>
}

export type CreateStockOwnerPickMethodMutation = { __typename?: 'Mutation' } & {
  createStockOwnerPickMethod?: Maybe<{ __typename?: 'PickMethod' } & PickMethodFragment>
}

export type DeleteStockOwnerPickMethodMutationVariables = {
  stockOwnerId: Scalars['Int']
  id: Scalars['Int']
}

export type DeleteStockOwnerPickMethodMutation = { __typename?: 'Mutation' } & {
  deleteStockOwnerPickMethod?: Maybe<{ __typename?: 'PickMethod' } & Pick<PickMethod, 'id'>>
}

export type UpdateProductStatusMutationVariables = {
  id: Scalars['Int']
  stockOwnerId: Scalars['Int']
  input?: Maybe<ProductStatusUpdateInput>
}

export type UpdateProductStatusMutation = { __typename?: 'Mutation' } & {
  updateProductStatus?: Maybe<{ __typename?: 'ProductStatus' } & ProductStatusesFragment>
}

export type CreateProductStatusMutationVariables = {
  stockOwnerId: Scalars['Int']
  input?: Maybe<ProductStatusInput>
}

export type CreateProductStatusMutation = { __typename?: 'Mutation' } & {
  createProductStatus?: Maybe<{ __typename?: 'ProductStatus' } & ProductStatusesFragment>
}

export type DeleteProductStatusMutationVariables = {
  stockOwnerId: Scalars['Int']
  id: Scalars['Int']
}

export type DeleteProductStatusMutation = { __typename?: 'Mutation' } & {
  deleteProductStatus?: Maybe<{ __typename?: 'ProductStatus' } & ProductStatusesFragment>
}

export type CreateStorageTypeMutationVariables = {
  input?: Maybe<StorageTypeInput>
  userContext?: Maybe<UserContextInput>
}

export type CreateStorageTypeMutation = { __typename?: 'Mutation' } & {
  createStorageType?: Maybe<{ __typename?: 'StorageType' } & StorageTypeFragment>
}

export type UpdateStorageTypeMutationVariables = {
  id: Scalars['Int']
  input?: Maybe<StorageTypeUpdateInput>
  userContext?: Maybe<UserContextInput>
}

export type UpdateStorageTypeMutation = { __typename?: 'Mutation' } & {
  updateStorageType?: Maybe<{ __typename?: 'StorageType' } & StorageTypeFragment>
}

export type DeleteStorageTypeMutationVariables = {
  id: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type DeleteStorageTypeMutation = { __typename?: 'Mutation' } & {
  deleteStorageType?: Maybe<{ __typename?: 'StorageType' } & StorageTypeFragment>
}

export type AddStorageTypeMheMutationVariables = {
  id: Scalars['Int']
  MHEId: Scalars['Int']
}

export type AddStorageTypeMheMutation = { __typename?: 'Mutation' } & {
  addStorageTypeMHE?: Maybe<
    { __typename?: 'StorageTypeMHE' } & {
      storageType?: Maybe<{ __typename?: 'StorageType' } & StorageTypeFragment>
    }
  >
}

export type RemoveStorageTypeMheMutationVariables = {
  id: Scalars['Int']
  MHEId: Scalars['Int']
}

export type RemoveStorageTypeMheMutation = { __typename?: 'Mutation' } & {
  removeStorageTypeMHE?: Maybe<
    { __typename?: 'StorageTypeMHE' } & {
      storageType?: Maybe<{ __typename?: 'StorageType' } & StorageTypeFragment>
    }
  >
}

export type CreateSupplierMutationVariables = {
  input?: Maybe<SupplierInput>
}

export type CreateSupplierMutation = { __typename?: 'Mutation' } & {
  createSupplier?: Maybe<{ __typename?: 'Supplier' } & SupplierFragment>
}

export type UpdateSupplierMutationVariables = {
  id: Scalars['Int']
  input?: Maybe<SupplierUpdateInput>
}

export type UpdateSupplierMutation = { __typename?: 'Mutation' } & {
  updateSupplier?: Maybe<{ __typename?: 'Supplier' } & SupplierFragment>
}

export type DeleteSupplierMutationVariables = {
  id: Scalars['Int']
}

export type DeleteSupplierMutation = { __typename?: 'Mutation' } & {
  deleteSupplier?: Maybe<{ __typename?: 'Supplier' } & SupplierFragment>
}

export type AddUserMutationVariables = {
  input?: Maybe<UserInput>
}

export type AddUserMutation = { __typename?: 'Mutation' } & {
  addUser?: Maybe<
    | ({ __typename: 'WMSUser' } & Pick<WmsUser, 'id' | 'userName'>)
    | { __typename: 'DatabaseError' }
    | { __typename: 'ValidationError' }
  >
}

export type UpdateUserMutationVariables = {
  id?: Maybe<Scalars['String']>
  input?: Maybe<UserUpdateInput>
}

export type UpdateUserMutation = { __typename?: 'Mutation' } & {
  updateUser?: Maybe<
    | ({ __typename: 'WMSUser' } & Pick<WmsUser, 'id' | 'userName'>)
    | ({ __typename: 'DatabaseError' } & Pick<DatabaseError, 'message'>)
    | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'messages'>)
  >
}

export type DeleteUserMutationVariables = {
  id?: Maybe<Scalars['String']>
}

export type DeleteUserMutation = { __typename?: 'Mutation' } & {
  deleteUser?: Maybe<
    | ({ __typename: 'WMSUser' } & Pick<WmsUser, 'id' | 'userName'>)
    | { __typename: 'DatabaseError' }
    | { __typename: 'ValidationError' }
  >
}

export type ChangePasswordMutationVariables = {
  newPassword?: Maybe<Scalars['String']>
  oldPassword?: Maybe<Scalars['String']>
}

export type ChangePasswordMutation = { __typename?: 'Mutation' } & {
  changePassword?: Maybe<
    | ({ __typename: 'WMSUser' } & Pick<WmsUser, 'userName'>)
    | ({ __typename: 'DatabaseError' } & Pick<DatabaseError, 'message'>)
    | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'field' | 'messages'>)
  >
}

export type CreateUserPermissionMutationVariables = {
  input?: Maybe<UserPermissionInput>
}

export type CreateUserPermissionMutation = { __typename?: 'Mutation' } & {
  createUserPermission?: Maybe<{ __typename?: 'UserPermission' } & Pick<UserPermission, 'id'>>
}

export type UpdateUserPermissionMutationVariables = {
  id: Scalars['Int']
  input?: Maybe<UserPermissionUpdateInput>
  userContext?: Maybe<UserContextInput>
}

export type UpdateUserPermissionMutation = { __typename?: 'Mutation' } & {
  updateUserPermission?: Maybe<{ __typename?: 'UserPermission' } & Pick<UserPermission, 'id'>>
}

export type DeleteUserPermissionMutationVariables = {
  id: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type DeleteUserPermissionMutation = { __typename?: 'Mutation' } & {
  deleteUserPermission?: Maybe<{ __typename?: 'UserPermission' } & Pick<UserPermission, 'id'>>
}

export type CreateWarehouseMutationVariables = {
  input?: Maybe<WarehouseInput>
}

export type CreateWarehouseMutation = { __typename?: 'Mutation' } & {
  createWarehouse?: Maybe<
    { __typename?: 'Warehouse' } & {
      address: { __typename?: 'Address' } & AddressFragment
    } & WarehouseFragment
  >
}

export type UpdateWarehouseMutationVariables = {
  id: Scalars['Int']
  input?: Maybe<WarehouseUpdateInput>
}

export type UpdateWarehouseMutation = { __typename?: 'Mutation' } & {
  updateWarehouse?: Maybe<
    { __typename?: 'Warehouse' } & {
      address: { __typename?: 'Address' } & AddressFragment
    } & WarehouseFragment
  >
}

export type DeleteWarehouseMutationVariables = {
  id: Scalars['Int']
}

export type DeleteWarehouseMutation = { __typename?: 'Mutation' } & {
  deleteWarehouse?: Maybe<{ __typename?: 'Warehouse' } & WarehouseFragment>
}

export type CreateWarehouseZoneMutationVariables = {
  input?: Maybe<WarehouseZoneInput>
  userContext?: Maybe<UserContextInput>
}

export type CreateWarehouseZoneMutation = { __typename?: 'Mutation' } & {
  createWarehouseZone?: Maybe<{ __typename?: 'WarehouseZone' } & WarehouseZoneFragment>
}

export type UpdateWarehouseZoneMutationVariables = {
  id: Scalars['Int']
  input?: Maybe<WarehouseZoneUpdateInput>
  userContext?: Maybe<UserContextInput>
}

export type UpdateWarehouseZoneMutation = { __typename?: 'Mutation' } & {
  updateWarehouseZone?: Maybe<{ __typename?: 'WarehouseZone' } & WarehouseZoneFragment>
}

export type DeleteWarehouseZoneMutationVariables = {
  id: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type DeleteWarehouseZoneMutation = { __typename?: 'Mutation' } & {
  deleteWarehouseZone?: Maybe<{ __typename?: 'WarehouseZone' } & Pick<WarehouseZone, 'id'>>
}

export type EnumQueryVariables = {
  name: Scalars['String']
}

export type EnumQuery = { __typename?: 'Query' } & {
  enum?: Maybe<
    { __typename?: '__Type' } & {
      values?: Maybe<Array<{ __typename?: '__EnumValue' } & Pick<__EnumValue, 'name'>>>
    }
  >
}

export type ActivitiesQueryVariables = {}

export type ActivitiesQuery = { __typename?: 'Query' } & {
  activities?: Maybe<Array<Maybe<{ __typename?: 'Activity' } & ActivityFragment>>>
}

export type AuditsQueryVariables = {
  filterInput?: Maybe<AuditEntryFilterInput>
  userContext?: Maybe<UserContextInput>
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
}

export type AuditsQuery = { __typename?: 'Query' } & {
  audits?: Maybe<
    { __typename?: 'AuditEntryConnection' } & Pick<AuditEntryConnection, 'totalCount'> & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'hasNextPage' | 'startCursor' | 'endCursor'
        >
        nodes?: Maybe<Array<Maybe<{ __typename?: 'AuditEntry' } & AuditLogFragment>>>
      }
  >
}

export type BudgetHoldersQueryVariables = {
  filterInput?: Maybe<BudgetHolderFilter>
  userContext?: Maybe<UserContextInput>
}

export type BudgetHoldersQuery = { __typename?: 'Query' } & {
  budgetHolders?: Maybe<
    { __typename?: 'BudgetHolderConnection' } & {
      nodes?: Maybe<Array<Maybe<{ __typename?: 'BudgetHolder' } & BudgetHolderFragment>>>
    }
  >
}

export type BudgetHoldersPaginatedQueryVariables = {
  filterInput?: Maybe<BudgetHolderFilter>
  userContext?: Maybe<UserContextInput>
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type BudgetHoldersPaginatedQuery = { __typename?: 'Query' } & {
  budgetHolders?: Maybe<
    { __typename?: 'BudgetHolderConnection' } & {
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        PageInfo,
        'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'
      >
      nodes?: Maybe<Array<Maybe<{ __typename?: 'BudgetHolder' } & BudgetHolderFragment>>>
    }
  >
}

export type BudgetHolderByIdQueryVariables = {
  id: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type BudgetHolderByIdQuery = { __typename?: 'Query' } & {
  budgetHolderById?: Maybe<{ __typename?: 'BudgetHolder' } & BudgetHolderFragment>
}

export type CarriersQueryVariables = {
  filterInput?: Maybe<CarrierFilter>
  userContext?: Maybe<UserContextInput>
}

export type CarriersQuery = { __typename?: 'Query' } & {
  carriers?: Maybe<
    { __typename?: 'CarrierConnection' } & {
      nodes?: Maybe<
        Array<
          Maybe<
            { __typename?: 'Carrier' } & {
              accounts?: Maybe<
                Array<Maybe<{ __typename?: 'CarrierAccount' } & CarrierAccountFragment>>
              >
              services?: Maybe<
                Array<Maybe<{ __typename?: 'CarrierService' } & CarrierServiceFragment>>
              >
            } & CarrierFragment
          >
        >
      >
    }
  >
}

export type CarrierByIdQueryVariables = {
  id: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type CarrierByIdQuery = { __typename?: 'Query' } & {
  carrierById?: Maybe<
    { __typename?: 'Carrier' } & {
      accounts?: Maybe<Array<Maybe<{ __typename?: 'CarrierAccount' } & CarrierAccountFragment>>>
      services?: Maybe<Array<Maybe<{ __typename?: 'CarrierService' } & CarrierServiceFragment>>>
    } & CarrierFragment
  >
}

export type CarriersPaginaterQueryVariables = {
  filterInput?: Maybe<CarrierFilter>
  userContext?: Maybe<UserContextInput>
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type CarriersPaginaterQuery = { __typename?: 'Query' } & {
  carriers?: Maybe<
    { __typename?: 'CarrierConnection' } & Pick<CarrierConnection, 'totalCount'> & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
        nodes?: Maybe<
          Array<Maybe<{ __typename?: 'Carrier' } & Pick<Carrier, 'id' | 'code' | 'name'>>>
        >
      }
  >
}

export type CarrierModulesQueryVariables = {}

export type CarrierModulesQuery = { __typename?: 'Query' } & Pick<Query, 'carrierModules'>

export type ConfigurationForModuleQueryVariables = {
  module?: Maybe<Scalars['String']>
  userContext?: Maybe<UserContextInput>
}

export type ConfigurationForModuleQuery = { __typename?: 'Query' } & {
  configurationForModule?: Maybe<
    Array<
      Maybe<
        { __typename?: 'ConfigOption' } & Pick<
          ConfigOption,
          'label' | 'overrideRequired' | 'position' | 'settingName' | 'type'
        >
      >
    >
  >
}

export type CodesQueryVariables = {
  filterInput?: Maybe<UdCodeFilter>
  userContext?: Maybe<UserContextInput>
}

export type CodesQuery = { __typename?: 'Query' } & {
  codes?: Maybe<
    { __typename?: 'UDCodeConnection' } & {
      nodes?: Maybe<Array<Maybe<{ __typename?: 'UDCode' } & CodeFragment>>>
    }
  >
}

export type CodeForTypeQueryVariables = {
  codeType?: Maybe<Scalars['String']>
}

export type CodeForTypeQuery = { __typename?: 'Query' } & {
  codesForType?: Maybe<
    { __typename?: 'UDCodeConnection' } & {
      nodes?: Maybe<Array<Maybe<{ __typename?: 'UDCode' } & CodeFragment>>>
    }
  >
}

export type CodesPaginatedQueryVariables = {
  filterInput?: Maybe<UdCodeFilter>
  userContext?: Maybe<UserContextInput>
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
}

export type CodesPaginatedQuery = { __typename?: 'Query' } & {
  codes?: Maybe<
    { __typename?: 'UDCodeConnection' } & Pick<UdCodeConnection, 'totalCount'> & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'hasNextPage' | 'startCursor' | 'endCursor'
        >
        nodes?: Maybe<Array<Maybe<{ __typename?: 'UDCode' } & CodeFragment>>>
      }
  >
}

export type CodeForTypePaginatedQueryVariables = {
  codeType?: Maybe<Scalars['String']>
  userContext?: Maybe<UserContextInput>
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
}

export type CodeForTypePaginatedQuery = { __typename?: 'Query' } & {
  codesForType?: Maybe<
    { __typename?: 'UDCodeConnection' } & Pick<UdCodeConnection, 'totalCount'> & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'hasNextPage' | 'startCursor' | 'endCursor'
        >
        nodes?: Maybe<Array<Maybe<{ __typename?: 'UDCode' } & CodeFragment>>>
      }
  >
}

export type CodeDefinitionsQueryVariables = {}

export type CodeDefinitionsQuery = { __typename?: 'Query' } & {
  codeDefinitions?: Maybe<
    { __typename?: 'CodeDefinitionConnection' } & {
      nodes?: Maybe<Array<Maybe<{ __typename?: 'CodeDefinition' } & CodeDefinitionFragment>>>
    }
  >
}

export type DestinationsQueryVariables = {
  filterInput?: Maybe<DestinationFilter>
  userContext?: Maybe<UserContextInput>
}

export type DestinationsQuery = { __typename?: 'Query' } & {
  destinations?: Maybe<
    { __typename?: 'DestinationConnection' } & {
      nodes?: Maybe<
        Array<
          Maybe<
            { __typename?: 'Destination' } & {
              address: { __typename?: 'Address' } & AddressFragment
              budgets?: Maybe<Array<Maybe<{ __typename?: 'DestinationBudget' } & BudgetFragment>>>
              contact?: Maybe<{ __typename?: 'Contact' } & ContactFragment>
              routing?: Maybe<Array<Maybe<{ __typename?: 'DestinationRoute' } & RoutingFragment>>>
            } & DestinationFragment
          >
        >
      >
    }
  >
}

export type DestinationsPaginatedQueryVariables = {
  filterInput?: Maybe<DestinationFilter>
  userContext?: Maybe<UserContextInput>
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type DestinationsPaginatedQuery = { __typename?: 'Query' } & {
  destinations?: Maybe<
    { __typename?: 'DestinationConnection' } & Pick<DestinationConnection, 'totalCount'> & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'
        >
        nodes?: Maybe<
          Array<Maybe<{ __typename?: 'Destination' } & Pick<Destination, 'id' | 'code' | 'name'>>>
        >
      }
  >
}

export type DestinationByIdQueryVariables = {
  id: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type DestinationByIdQuery = { __typename?: 'Query' } & {
  destinationById?: Maybe<
    { __typename?: 'Destination' } & {
      address: { __typename?: 'Address' } & AddressFragment
      budgets?: Maybe<Array<Maybe<{ __typename?: 'DestinationBudget' } & BudgetFragment>>>
      contact?: Maybe<{ __typename?: 'Contact' } & ContactFragment>
      routing?: Maybe<Array<Maybe<{ __typename?: 'DestinationRoute' } & RoutingFragment>>>
    } & DestinationFragment
  >
}

export type DestinationCodesQueryVariables = {}

export type DestinationCodesQuery = { __typename?: 'Query' } & {
  destinationCodes?: Maybe<
    Array<Maybe<{ __typename?: 'DestinationCode' } & DestinationCodeFragment>>
  >
}

export type HandlingTypesQueryVariables = {
  filterInput?: Maybe<HandlingTypeFilter>
  userContext?: Maybe<UserContextInput>
}

export type HandlingTypesQuery = { __typename?: 'Query' } & {
  handlingTypes?: Maybe<
    { __typename?: 'HandlingTypeConnection' } & {
      nodes?: Maybe<
        Array<
          Maybe<
            { __typename?: 'HandlingType' } & {
              storageTypes?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'HandlingStorageType' } & {
                      storageType?: Maybe<{ __typename?: 'StorageType' } & StorageTypeFragment>
                    }
                  >
                >
              >
            } & HandlingTypeFragment
          >
        >
      >
    }
  >
}

export type SelectHandlingTypesQueryVariables = {
  userContext?: Maybe<UserContextInput>
}

export type SelectHandlingTypesQuery = { __typename?: 'Query' } & {
  handlingTypes?: Maybe<
    { __typename?: 'HandlingTypeConnection' } & {
      nodes?: Maybe<Array<Maybe<{ __typename?: 'HandlingType' } & HandlingTypeFragment>>>
    }
  >
}

export type HandlingTypesPaginatedQueryVariables = {
  filterInput?: Maybe<HandlingTypeFilter>
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
}

export type HandlingTypesPaginatedQuery = { __typename?: 'Query' } & {
  handlingTypes?: Maybe<
    { __typename?: 'HandlingTypeConnection' } & Pick<HandlingTypeConnection, 'totalCount'> & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'
        >
        nodes?: Maybe<
          Array<
            Maybe<
              { __typename?: 'HandlingType' } & {
                storageTypes?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'HandlingStorageType' } & {
                        storageType?: Maybe<{ __typename?: 'StorageType' } & StorageTypeFragment>
                      }
                    >
                  >
                >
              } & HandlingTypeFragment
            >
          >
        >
      }
  >
}

export type HandlingTypeByIdQueryVariables = {
  id: Scalars['Int']
}

export type HandlingTypeByIdQuery = { __typename?: 'Query' } & {
  handlingTypeById?: Maybe<
    { __typename?: 'HandlingType' } & {
      storageTypes?: Maybe<
        Array<
          Maybe<
            { __typename?: 'HandlingStorageType' } & {
              storageType?: Maybe<{ __typename?: 'StorageType' } & StorageTypeFragment>
            }
          >
        >
      >
    } & HandlingTypeFragment
  >
}

export type LocationsQueryVariables = {
  filterInput?: Maybe<LocationFilter>
  userContext?: Maybe<UserContextInput>
}

export type LocationsQuery = { __typename?: 'Query' } & {
  locations?: Maybe<
    { __typename?: 'LocationConnection' } & {
      nodes?: Maybe<Array<Maybe<{ __typename?: 'Location' } & LocationFragment>>>
    }
  >
}

export type LocationByIdQueryVariables = {
  id: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type LocationByIdQuery = { __typename?: 'Query' } & {
  locationById?: Maybe<
    { __typename?: 'Location' } & {
      storageType?: Maybe<{ __typename?: 'StorageType' } & StorageTypeFragment>
    } & LocationFragment
  >
}

export type LocationProcessTypesQueryVariables = {}

export type LocationProcessTypesQuery = { __typename?: 'Query' } & {
  locationProcessTypes?: Maybe<
    Array<
      Maybe<
        { __typename?: 'LocationProcessTypeMetadata' } & Pick<
          LocationProcessTypeMetadata,
          'displayName' | 'description' | 'value'
        >
      >
    >
  >
}

export type LocationPhysicalTypesQueryVariables = {}

export type LocationPhysicalTypesQuery = { __typename?: 'Query' } & {
  locationPhysicalTypes?: Maybe<
    Array<
      Maybe<
        { __typename?: 'LocationPhysicalTypeMetadata' } & Pick<
          LocationPhysicalTypeMetadata,
          'displayName' | 'description' | 'value'
        >
      >
    >
  >
}

export type SelectLocationsQueryVariables = {
  filterInput?: Maybe<LocationFilter>
  userContext?: Maybe<UserContextInput>
}

export type SelectLocationsQuery = { __typename?: 'Query' } & {
  locations?: Maybe<
    { __typename?: 'LocationConnection' } & {
      nodes?: Maybe<Array<Maybe<{ __typename?: 'Location' } & Pick<Location, 'id' | 'code'>>>>
    }
  >
}

export type LocationsPaginatedQueryVariables = {
  filterInput?: Maybe<LocationFilter>
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  userContext?: Maybe<UserContextInput>
}

export type LocationsPaginatedQuery = { __typename?: 'Query' } & {
  locations?: Maybe<
    { __typename?: 'LocationConnection' } & Pick<LocationConnection, 'totalCount'> & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'
        >
        nodes?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Location' } & Pick<Location, 'id' | 'aisle' | 'code' | 'zone'> & {
                  storageType?: Maybe<{ __typename?: 'StorageType' } & StorageTypeFragment>
                }
            >
          >
        >
      }
  >
}

export type MaxWeeklySpendsQueryVariables = {}

export type MaxWeeklySpendsQuery = { __typename?: 'Query' } & {
  maxWeeklySpends?: Maybe<Array<Maybe<{ __typename?: 'MaxWeeklySpend' } & MaxWeeklySpendFragment>>>
}

export type ConfirmMethodsQueryVariables = {}

export type ConfirmMethodsQuery = { __typename?: 'Query' } & Pick<
  Query,
  | 'pickConfirmMethods'
  | 'postPickConfirmMethods'
  | 'packingMethods'
  | 'despatchMethods'
  | 'closeLoadMethods'
>

export type PickCreationmethodsQueryVariables = {}

export type PickCreationmethodsQuery = { __typename?: 'Query' } & Pick<
  Query,
  'prePickCreationMethods' | 'pickCreationMethods' | 'postPickCreationMethods' | 'pickOutputMethods'
>

export type MaterialHandlingEquipmentsQueryVariables = {
  filterInput?: Maybe<MaterialHandlingEquipmentFilter>
  userContext?: Maybe<UserContextInput>
}

export type MaterialHandlingEquipmentsQuery = { __typename?: 'Query' } & {
  materialHandlingEquipment?: Maybe<
    { __typename?: 'MaterialHandlingEquipmentConnection' } & {
      nodes?: Maybe<Array<Maybe<{ __typename?: 'MaterialHandlingEquipment' } & MheFragment>>>
    }
  >
}

export type MaterialHandlingEquipmentsPaginatedQueryVariables = {
  filterInput?: Maybe<MaterialHandlingEquipmentFilter>
  userContext?: Maybe<UserContextInput>
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type MaterialHandlingEquipmentsPaginatedQuery = { __typename?: 'Query' } & {
  materialHandlingEquipment?: Maybe<
    { __typename?: 'MaterialHandlingEquipmentConnection' } & Pick<
      MaterialHandlingEquipmentConnection,
      'totalCount'
    > & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'
        >
        nodes?: Maybe<
          Array<
            Maybe<
              { __typename?: 'MaterialHandlingEquipment' } & Pick<
                MaterialHandlingEquipment,
                'id' | 'code' | 'description' | 'class'
              >
            >
          >
        >
      }
  >
}

export type MaterialHandlingEquipmentByIdQueryVariables = {
  id: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type MaterialHandlingEquipmentByIdQuery = { __typename?: 'Query' } & {
  materialHandlingEquipmentById?: Maybe<{ __typename?: 'MaterialHandlingEquipment' } & MheFragment>
}

export type OrderEntryQueryVariables = {
  code: Scalars['String']
}

export type OrderEntryQuery = { __typename?: 'Query' } & {
  orderEntry?: Maybe<
    { __typename?: 'OrderEntry' } & {
      destinationCode: { __typename?: 'DestinationCode' } & DestinationCodeFragment
      budgetHolder: { __typename?: 'BudgetHolder' } & BudgetHolderFragment
      orderType: { __typename?: 'OrderType' } & OrderTypeFragment
    } & OrderEntryFragment
  >
}

export type OrderEntriesQueryVariables = {
  filterInput?: Maybe<OrderFilter>
}

export type OrderEntriesQuery = { __typename?: 'Query' } & {
  orderEntries?: Maybe<
    Array<
      Maybe<
        { __typename?: 'OrderEntry' } & {
          destinationCode: { __typename?: 'DestinationCode' } & DestinationCodeFragment
          budgetHolder: { __typename?: 'BudgetHolder' } & BudgetHolderFragment
          orderType: { __typename?: 'OrderType' } & OrderTypeFragment
        } & OrderEntryFragment
      >
    >
  >
}

export type OrderTypesQueryVariables = {}

export type OrderTypesQuery = { __typename?: 'Query' } & {
  orderTypes?: Maybe<Array<Maybe<{ __typename?: 'OrderType' } & OrderTypeFragment>>>
}

export type PoFormatsQueryVariables = {}

export type PoFormatsQuery = { __typename?: 'Query' } & {
  poFormats?: Maybe<Array<Maybe<{ __typename?: 'POFormat' } & PoFormatFragment>>>
}

export type PoTransmissionMethodsQueryVariables = {}

export type PoTransmissionMethodsQuery = { __typename?: 'Query' } & {
  poTransmissionMethods?: Maybe<
    Array<Maybe<{ __typename?: 'POTransmissionMethod' } & PoTransmissionMethodFragment>>
  >
}

export type ProductsQueryVariables = {
  filterInput?: Maybe<ProductFilter>
  userContext?: Maybe<UserContextInput>
  includeLinked: Scalars['Boolean']
}

export type ProductsQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename?: 'ProductConnection' } & {
      nodes?: Maybe<
        Array<
          Maybe<
            { __typename?: 'Product' } & {
              pickfaces?: Maybe<Array<Maybe<{ __typename?: 'ProductPickface' } & PickfaceFragment>>>
              licencePlates?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'LPDefinition' } & LicencePlateFragment & LicencePlateFragment
                  >
                >
              >
              documents?: Maybe<Array<Maybe<{ __typename?: 'ProductDocument' } & DocumentFragment>>>
            } & ProductFragment
          >
        >
      >
    }
  >
}

export type SelectProductsQueryVariables = {
  filterInput?: Maybe<ProductFilter>
  userContext?: Maybe<UserContextInput>
  includeLinked: Scalars['Boolean']
}

export type SelectProductsQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename?: 'ProductConnection' } & {
      nodes?: Maybe<
        Array<Maybe<{ __typename?: 'Product' } & Pick<Product, 'id' | 'code' | 'description'>>>
      >
    }
  >
}

export type ProductsPaginatedQueryVariables = {
  filterInput?: Maybe<ProductFilter>
  userContext?: Maybe<UserContextInput>
  includeLinked: Scalars['Boolean']
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type ProductsPaginatedQuery = { __typename?: 'Query' } & {
  product?: Maybe<
    { __typename?: 'ProductConnection' } & {
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        PageInfo,
        'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'
      >
      nodes?: Maybe<
        Array<Maybe<{ __typename?: 'Product' } & Pick<Product, 'id' | 'code' | 'description'>>>
      >
    }
  >
}

export type ProductByIdQueryVariables = {
  id: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type ProductByIdQuery = { __typename?: 'Query' } & {
  productById?: Maybe<
    { __typename?: 'Product' } & {
      pickfaces?: Maybe<Array<Maybe<{ __typename?: 'ProductPickface' } & PickfaceFragment>>>
      licencePlates?: Maybe<
        Array<Maybe<{ __typename?: 'LPDefinition' } & LicencePlateFragment & LicencePlateFragment>>
      >
      documents?: Maybe<Array<Maybe<{ __typename?: 'ProductDocument' } & DocumentFragment>>>
    } & ProductFragment
  >
}

export type CultureQueryVariables = {
  resourceSet: Scalars['String']
}

export type CultureQuery = { __typename?: 'Query' } & {
  resources?: Maybe<
    { __typename?: 'ResourcesQuery' } & {
      allLocaleIds?: Maybe<
        Array<
          Maybe<
            { __typename?: 'CultureInfo' } & Pick<
              CultureInfo,
              'name' | 'displayName' | 'nativeName' | 'englishName'
            >
          >
        >
      >
    }
  >
}

export type CultureByNameQueryVariables = {
  filterInput?: Maybe<CultureInfoFilterInput>
}

export type CultureByNameQuery = { __typename?: 'Query' } & {
  resources?: Maybe<
    { __typename?: 'ResourcesQuery' } & {
      cultures?: Maybe<
        { __typename?: 'CultureInfoConnection' } & {
          nodes?: Maybe<
            Array<
              Maybe<
                { __typename?: 'CultureInfo' } & Pick<
                  CultureInfo,
                  'LCID' | 'name' | 'displayName' | 'nativeName' | 'englishName'
                >
              >
            >
          >
        }
      >
    }
  >
}

export type StockEnquiryQueryVariables = {
  filterInput?: Maybe<StockFilterInput>
  userContext?: Maybe<UserContextInput>
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
}

export type StockEnquiryQuery = { __typename?: 'Query' } & {
  stock?: Maybe<
    { __typename?: 'StockQueryType' } & {
      stock?: Maybe<
        { __typename?: 'StockConnection' } & Pick<StockConnection, 'totalCount'> & {
            pageInfo: { __typename?: 'PageInfo' } & Pick<
              PageInfo,
              'hasNextPage' | 'startCursor' | 'endCursor'
            >
            nodes?: Maybe<Array<Maybe<{ __typename?: 'Stock' } & StockFragment>>>
          }
      >
    }
  >
}

export type StockOwnersQueryVariables = {
  filterInput?: Maybe<StockOwnerFilter>
}

export type StockOwnersQuery = { __typename?: 'Query' } & {
  stockOwners?: Maybe<
    { __typename?: 'StockOwnerConnection' } & {
      nodes?: Maybe<
        Array<
          Maybe<
            { __typename?: 'StockOwner' } & {
              linkedStockOwners?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'StockOwnerLink' } & {
                      child?: Maybe<{ __typename?: 'StockOwner' } & Pick<StockOwner, 'id' | 'code'>>
                    }
                  >
                >
              >
              address?: Maybe<{ __typename?: 'Address' } & AddressFragment>
              categories?: Maybe<
                Array<Maybe<{ __typename?: 'CategoryDefinition' } & CategoryFragment>>
              >
              contact?: Maybe<{ __typename?: 'Contact' } & ContactFragment>
              productStatuses?: Maybe<
                Array<Maybe<{ __typename?: 'ProductStatus' } & ProductStatusesFragment>>
              >
              orderStatuses?: Maybe<
                Array<Maybe<{ __typename?: 'OrderStatus' } & OrderStatusFragment>>
              >
              pickMethods?: Maybe<Array<Maybe<{ __typename?: 'PickMethod' } & PickMethodFragment>>>
              confirmMethods?: Maybe<
                Array<Maybe<{ __typename?: 'PickConfirmMethod' } & PickConfirmMethodFragment>>
              >
            } & StockOwnerFragment
          >
        >
      >
    }
  >
}

export type StockOwnerCategoriesQueryVariables = {
  id: Scalars['Int']
}

export type StockOwnerCategoriesQuery = { __typename?: 'Query' } & {
  stockOwnerById?: Maybe<
    { __typename?: 'StockOwner' } & {
      categories?: Maybe<
        Array<
          Maybe<
            { __typename?: 'CategoryDefinition' } & Pick<CategoryDefinition, 'code' | 'description'>
          >
        >
      >
    }
  >
}

export type StockOwnerProductStatusesQueryVariables = {
  id: Scalars['Int']
}

export type StockOwnerProductStatusesQuery = { __typename?: 'Query' } & {
  stockOwnerById?: Maybe<
    { __typename?: 'StockOwner' } & {
      productStatuses?: Maybe<
        Array<Maybe<{ __typename?: 'ProductStatus' } & ProductStatusesFragment>>
      >
    }
  >
}

export type StockOwnerPickMethodsQueryVariables = {
  id: Scalars['Int']
}

export type StockOwnerPickMethodsQuery = { __typename?: 'Query' } & {
  stockOwnerById?: Maybe<
    { __typename?: 'StockOwner' } & {
      pickMethods?: Maybe<
        Array<Maybe<{ __typename?: 'PickMethod' } & Pick<PickMethod, 'id' | 'name'>>>
      >
    }
  >
}

export type LinkedStockOwnersQueryVariables = {}

export type LinkedStockOwnersQuery = { __typename?: 'Query' } & {
  stockOwners?: Maybe<
    { __typename?: 'StockOwnerConnection' } & {
      nodes?: Maybe<Array<Maybe<{ __typename?: 'StockOwner' } & Pick<StockOwner, 'id' | 'code'>>>>
    }
  >
}

export type SelectStockOwnersQueryVariables = {}

export type SelectStockOwnersQuery = { __typename?: 'Query' } & {
  stockOwners?: Maybe<
    { __typename?: 'StockOwnerConnection' } & {
      nodes?: Maybe<
        Array<Maybe<{ __typename?: 'StockOwner' } & Pick<StockOwner, 'id' | 'code' | 'name'>>>
      >
    }
  >
}

export type StockOwnerPaginatedQueryVariables = {
  filterInput?: Maybe<StockOwnerFilter>
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type StockOwnerPaginatedQuery = { __typename?: 'Query' } & {
  stockOwners?: Maybe<
    { __typename?: 'StockOwnerConnection' } & Pick<StockOwnerConnection, 'totalCount'> & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'
        >
        nodes?: Maybe<
          Array<
            Maybe<
              { __typename?: 'StockOwner' } & Pick<StockOwner, 'id' | 'code' | 'name' | 'status'>
            >
          >
        >
      }
  >
}

export type StockOwnerByIdQueryVariables = {
  id: Scalars['Int']
}

export type StockOwnerByIdQuery = { __typename?: 'Query' } & {
  stockOwnerById?: Maybe<
    { __typename?: 'StockOwner' } & {
      linkedStockOwners?: Maybe<
        Array<
          Maybe<
            { __typename?: 'StockOwnerLink' } & {
              child?: Maybe<{ __typename?: 'StockOwner' } & Pick<StockOwner, 'id' | 'code'>>
            }
          >
        >
      >
      address?: Maybe<{ __typename?: 'Address' } & AddressFragment>
      categories?: Maybe<Array<Maybe<{ __typename?: 'CategoryDefinition' } & CategoryFragment>>>
      contact?: Maybe<{ __typename?: 'Contact' } & ContactFragment>
      productStatuses?: Maybe<
        Array<Maybe<{ __typename?: 'ProductStatus' } & ProductStatusesFragment>>
      >
      orderStatuses?: Maybe<Array<Maybe<{ __typename?: 'OrderStatus' } & OrderStatusFragment>>>
      pickMethods?: Maybe<Array<Maybe<{ __typename?: 'PickMethod' } & PickMethodFragment>>>
      confirmMethods?: Maybe<
        Array<Maybe<{ __typename?: 'PickConfirmMethod' } & PickConfirmMethodFragment>>
      >
    } & StockOwnerFragment
  >
}

export type StorageTypesQueryVariables = {}

export type StorageTypesQuery = { __typename?: 'Query' } & {
  storageTypes?: Maybe<
    { __typename?: 'StorageTypeConnection' } & {
      nodes?: Maybe<
        Array<
          Maybe<
            { __typename?: 'StorageType' } & {
              MHEs?: Maybe<
                Array<
                  Maybe<
                    { __typename?: 'StorageTypeMHE' } & {
                      materialHandlingEquipment?: Maybe<
                        { __typename?: 'MaterialHandlingEquipment' } & Pick<
                          MaterialHandlingEquipment,
                          'id' | 'code'
                        >
                      >
                    }
                  >
                >
              >
            } & StorageTypeFragment
          >
        >
      >
    }
  >
}

export type StorageTypesPaginatedQueryVariables = {
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
}

export type StorageTypesPaginatedQuery = { __typename?: 'Query' } & {
  storageTypes?: Maybe<
    { __typename?: 'StorageTypeConnection' } & Pick<StorageTypeConnection, 'totalCount'> & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'hasNextPage' | 'startCursor' | 'endCursor'
        >
        nodes?: Maybe<
          Array<
            Maybe<
              { __typename?: 'StorageType' } & {
                MHEs?: Maybe<
                  Array<
                    Maybe<
                      { __typename?: 'StorageTypeMHE' } & {
                        materialHandlingEquipment?: Maybe<
                          { __typename?: 'MaterialHandlingEquipment' } & Pick<
                            MaterialHandlingEquipment,
                            'id' | 'code'
                          >
                        >
                      }
                    >
                  >
                >
              } & StorageTypeFragment
            >
          >
        >
      }
  >
}

export type SuppliersQueryVariables = {
  filterInput?: Maybe<SupplierFilter>
}

export type SuppliersQuery = { __typename?: 'Query' } & {
  suppliers?: Maybe<
    { __typename?: 'SupplierConnection' } & {
      nodes?: Maybe<
        Array<
          Maybe<
            { __typename?: 'Supplier' } & {
              address: { __typename?: 'Address' } & AddressFragment
            } & SupplierFragment
          >
        >
      >
    }
  >
}

export type SelectSuppliersQueryVariables = {
  filterInput?: Maybe<SupplierFilter>
}

export type SelectSuppliersQuery = { __typename?: 'Query' } & {
  suppliers?: Maybe<
    { __typename?: 'SupplierConnection' } & {
      nodes?: Maybe<
        Array<Maybe<{ __typename?: 'Supplier' } & Pick<Supplier, 'id' | 'code' | 'name'>>>
      >
    }
  >
}

export type SupplierByIdQueryVariables = {
  id: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type SupplierByIdQuery = { __typename?: 'Query' } & {
  supplierById?: Maybe<
    { __typename?: 'Supplier' } & {
      address: { __typename?: 'Address' } & AddressFragment
    } & SupplierFragment
  >
}

export type SuppliersPaginatedQueryVariables = {
  filterInput?: Maybe<SupplierFilter>
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
  userContext?: Maybe<UserContextInput>
}

export type SuppliersPaginatedQuery = { __typename?: 'Query' } & {
  suppliers?: Maybe<
    { __typename?: 'SupplierConnection' } & {
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        PageInfo,
        'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'
      >
      nodes?: Maybe<
        Array<Maybe<{ __typename?: 'Supplier' } & Pick<Supplier, 'id' | 'code' | 'name'>>>
      >
    }
  >
}

export type CurrentUserQueryVariables = {}

export type CurrentUserQuery = { __typename?: 'Query' } & {
  currentUser?: Maybe<
    | ({ __typename: 'WMSUser' } & Pick<WmsUser, 'userName' | 'forename' | 'surname'>)
    | ({ __typename: 'DatabaseError' } & Pick<DatabaseError, 'message'> & {
          DBErrorId: DatabaseError['id']
        })
    | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'field' | 'messages'> & {
          ValidationErrorId: ValidationError['id']
        })
  >
}

export type UsersQueryVariables = {
  filterInput?: Maybe<WmsUserFilterInput>
}

export type UsersQuery = { __typename?: 'Query' } & {
  users?: Maybe<
    { __typename?: 'WMSUserResultConnection' } & {
      nodes?: Maybe<
        Array<
          Maybe<
            | ({ __typename: 'WMSUser' } & UserFragment)
            | { __typename: 'DatabaseError' }
            | { __typename: 'ValidationError' }
          >
        >
      >
    }
  >
}

export type UserByIdQueryVariables = {
  id?: Maybe<Scalars['String']>
}

export type UserByIdQuery = { __typename?: 'Query' } & {
  userById?: Maybe<
    | ({ __typename: 'WMSUser' } & {
        userPermissions?: Maybe<
          Array<Maybe<{ __typename?: 'UserPermission' } & UserPermissionFragment>>
        >
      } & UserFragment)
    | ({ __typename: 'DatabaseError' } & Pick<DatabaseError, 'message'> & {
          DBErrorId: DatabaseError['id']
        })
    | ({ __typename: 'ValidationError' } & Pick<ValidationError, 'field' | 'messages'> & {
          ValidationErrorId: ValidationError['id']
        })
  >
}

export type UserByUsernameQueryVariables = {
  username?: Maybe<Scalars['String']>
}

export type UserByUsernameQuery = { __typename?: 'Query' } & {
  userByUsername?: Maybe<
    | ({ __typename: 'WMSUser' } & UserFragment)
    | { __typename: 'DatabaseError' }
    | { __typename: 'ValidationError' }
  >
}

export type UsersPaginatedQueryVariables = {
  filterInput?: Maybe<WmsUserFilterInput>
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
}

export type UsersPaginatedQuery = { __typename?: 'Query' } & {
  users?: Maybe<
    { __typename?: 'WMSUserResultConnection' } & {
      pageInfo: { __typename?: 'PageInfo' } & Pick<
        PageInfo,
        'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'
      >
      nodes?: Maybe<
        Array<
          Maybe<
            | ({ __typename?: 'WMSUser' } & Pick<
                WmsUser,
                'id' | 'forename' | 'surname' | 'userName'
              >)
            | { __typename?: 'DatabaseError' }
            | { __typename?: 'ValidationError' }
          >
        >
      >
    }
  >
}

export type SetPasswordForUserMutationVariables = {
  id?: Maybe<Scalars['String']>
  newPassword?: Maybe<Scalars['String']>
}

export type SetPasswordForUserMutation = { __typename?: 'Mutation' } & {
  setPasswordForUser?: Maybe<
    | ({ __typename?: 'WMSUser' } & Pick<WmsUser, 'userName'>)
    | ({ __typename?: 'DatabaseError' } & Pick<DatabaseError, 'id' | 'message'>)
    | ({ __typename?: 'ValidationError' } & Pick<ValidationError, 'id' | 'messages'>)
  >
}

export type UserPermissionsQueryVariables = {
  filterInput?: Maybe<UserPermissionFilter>
}

export type UserPermissionsQuery = { __typename?: 'Query' } & {
  userPermissions?: Maybe<
    { __typename?: 'UserPermissionConnection' } & {
      nodes?: Maybe<Array<Maybe<{ __typename?: 'UserPermission' } & UserPermissionFragment>>>
    }
  >
}

export type UserPermissionQueryVariables = {
  id: Scalars['Int']
}

export type UserPermissionQuery = { __typename?: 'Query' } & {
  userPermission?: Maybe<{ __typename?: 'UserPermission' } & UserPermissionFragment>
}

export type WarehouseByIdQueryVariables = {
  id: Scalars['Int']
}

export type WarehouseByIdQuery = { __typename?: 'Query' } & {
  warehouseById?: Maybe<
    { __typename?: 'Warehouse' } & {
      address: { __typename?: 'Address' } & AddressFragment
    } & WarehouseFragment
  >
}

export type WarehouseByCodeQueryVariables = {
  code: Scalars['String']
}

export type WarehouseByCodeQuery = { __typename?: 'Query' } & {
  warehouseByCode?: Maybe<
    { __typename?: 'Warehouse' } & {
      address: { __typename?: 'Address' } & AddressFragment
    } & WarehouseFragment
  >
}

export type WarehousesQueryVariables = {
  filterInput?: Maybe<WarehouseFilter>
}

export type WarehousesQuery = { __typename?: 'Query' } & {
  warehouses?: Maybe<
    { __typename?: 'WarehouseConnection' } & Pick<WarehouseConnection, 'totalCount'> & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'
        >
        nodes?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Warehouse' } & {
                address: { __typename?: 'Address' } & AddressFragment
              } & WarehouseFragment
            >
          >
        >
      }
  >
}

export type WarehousesPaginatedQueryVariables = {
  filterInput?: Maybe<WarehouseFilter>
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
}

export type WarehousesPaginatedQuery = { __typename?: 'Query' } & {
  warehouses?: Maybe<
    { __typename?: 'WarehouseConnection' } & Pick<WarehouseConnection, 'totalCount'> & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'endCursor' | 'startCursor' | 'hasNextPage' | 'hasPreviousPage'
        >
        nodes?: Maybe<
          Array<
            Maybe<
              { __typename?: 'Warehouse' } & Pick<
                Warehouse,
                'id' | 'code' | 'warehouseName' | 'companyName'
              >
            >
          >
        >
      }
  >
}

export type SelectWarehousesQueryVariables = {}

export type SelectWarehousesQuery = { __typename?: 'Query' } & {
  warehouses?: Maybe<
    { __typename?: 'WarehouseConnection' } & {
      nodes?: Maybe<
        Array<
          Maybe<{ __typename?: 'Warehouse' } & Pick<Warehouse, 'id' | 'code' | 'warehouseName'>>
        >
      >
    }
  >
}

export type WarehouseZonesQueryVariables = {
  filterInput?: Maybe<WarehouseZoneFilter>
  userContext?: Maybe<UserContextInput>
}

export type WarehouseZonesQuery = { __typename?: 'Query' } & {
  warehouseZones?: Maybe<
    { __typename?: 'WarehouseZoneConnection' } & {
      nodes?: Maybe<
        Array<
          Maybe<
            { __typename?: 'WarehouseZone' } & Pick<WarehouseZone, 'zoneType'> &
              WarehouseZoneFragment
          >
        >
      >
    }
  >
}

export type WarehouseZonesPaginatedQueryVariables = {
  filterInput?: Maybe<WarehouseZoneFilter>
  userContext?: Maybe<UserContextInput>
  first?: Maybe<Scalars['Int']>
  after?: Maybe<Scalars['String']>
  before?: Maybe<Scalars['String']>
}

export type WarehouseZonesPaginatedQuery = { __typename?: 'Query' } & {
  warehouseZones?: Maybe<
    { __typename?: 'WarehouseZoneConnection' } & Pick<WarehouseZoneConnection, 'totalCount'> & {
        pageInfo: { __typename?: 'PageInfo' } & Pick<
          PageInfo,
          'hasNextPage' | 'startCursor' | 'endCursor'
        >
        nodes?: Maybe<
          Array<
            Maybe<
              { __typename?: 'WarehouseZone' } & Pick<WarehouseZone, 'zoneType'> &
                WarehouseZoneFragment
            >
          >
        >
      }
  >
}

export type WarehouseZoneByIdQueryVariables = {
  id: Scalars['Int']
  userContext?: Maybe<UserContextInput>
}

export type WarehouseZoneByIdQuery = { __typename?: 'Query' } & {
  warehouseZone?: Maybe<
    { __typename?: 'WarehouseZone' } & Pick<WarehouseZone, 'zoneType'> & WarehouseZoneFragment
  >
}

export type ZoneTypesQueryVariables = {}

export type ZoneTypesQuery = { __typename?: 'Query' } & {
  zoneTypes?: Maybe<Array<Maybe<{ __typename?: 'ZoneType' } & ZoneTypeFragment>>>
}

export const ActivityFragmentDoc = gql`
  fragment activity on Activity {
    id
    code
    name
  }
`
export const AddressFragmentDoc = gql`
  fragment address on Address {
    city
    country
    county
    postCode
    street1
    street2
  }
`
export const AuditLogFragmentDoc = gql`
  fragment auditLog on AuditEntry {
    id
    attributeId
    singles
    packs
    status
    packType
    attributes {
      id
      ... on StockAttributes {
        id
        batch
        batchInfo
        bestBeforeDate
        budgetHolder
        catchWeight
        GRN
        killDate
        pONumber
        product {
          code
          description
        }
        productId
        productionDate
        sellByDate
        serialNumber
        slaughterCode
      }
      ... on IWMSAttributes {
        id
      }
    }
    auditCode {
      compareTo
      description
      id
      message
    }
    code
    eventTime
    message
    metadata
    stockOwner {
      code
    }
    stockOwnerId
    user {
      userName
    }
    userId
    warehouse {
      code
    }
    warehouseId
  }
`
export const BudgetHolderFragmentDoc = gql`
  fragment budgetHolder on BudgetHolder {
    id
    code
    description
    status
  }
`
export const BudgetFragmentDoc = gql`
  fragment budget on DestinationBudget {
    id
    maxSpend
    spent
    startDate
  }
`
export const CarrierFragmentDoc = gql`
  fragment carrier on Carrier {
    id
    code
    captureDelNo
    defDespatchLane
    defService
    module
    name
    requireService
    telephone
  }
`
export const CarrierAccountFragmentDoc = gql`
  fragment carrierAccount on CarrierAccount {
    id
    account
    allowedServices
    description
    configuration {
      id
      setting
      value
    }
  }
`
export const CarrierServiceFragmentDoc = gql`
  fragment carrierService on CarrierService {
    code
    collection
    description
    extraBoxInfo
    id
    localCollect
    maxHeight
    maxLength
    maxWeight
    maxWidth
    nextDay
    notifyEmail
    notifySMS
    recordDelivery
    references
    saturday
    signReq
    sunday
    trackingReq
  }
`
export const CategoryFragmentDoc = gql`
  fragment category on CategoryDefinition {
    id
    code
    description
  }
`
export const CodeFragmentDoc = gql`
  fragment code on UDCode {
    id
    code
    codeType
    description
    longDescription
  }
`
export const CodeDefinitionFragmentDoc = gql`
  fragment codeDefinition on CodeDefinition {
    id
    name
    description
  }
`
export const ContactFragmentDoc = gql`
  fragment contact on Contact {
    email
    fax
    name
    telephone
  }
`
export const DestinationFragmentDoc = gql`
  fragment destination on Destination {
    area
    ASNMethod
    ASNOnDespatch
    budgetholder
    captureSerialOnDesp
    category
    code
    custStkCodeOnDespNotes
    defDespLane
    defOrderPri
    defOrderType
    defRoute
    defSpecIns
    despMethod
    despNoteFormat
    facility
    FTPAcc
    id
    masterAcc
    name
    noDespNotes
    orderDay
    orderFreq
    owner {
      id
      code
    }
    palletMethod
    pickLocation
    printBoxLbls
    printBoxNotes
    printNotesDelayDesp
    reqManifest
    salesman
    suppressBackOrdClose
    transitTime
    usePalletMethod
    VATNo
    depot
    masterHub
    subHub
    status
    GLN
    customsId
    defDelGroup
    minPickValue
    suppressBackOrdClose
    noPickConsolidation
  }
`
export const DestinationCodeFragmentDoc = gql`
  fragment destinationCode on DestinationCode {
    id
    name
    description
  }
`
export const HandlingTypeFragmentDoc = gql`
  fragment handlingType on HandlingType {
    id
    code
    description
  }
`
export const StorageTypeFragmentDoc = gql`
  fragment storageType on StorageType {
    id
    code
    description
  }
`
export const LocationFragmentDoc = gql`
  fragment location on Location {
    id
    aisle
    attributes
    beam
    beamSequence
    checkCode
    code
    countZone
    dimensions {
      depth
      height
      width
      volume
    }
    elevation
    maxWeight
    pickZone
    physicalCharacteristics
    processCharacteristics
    status
    storageType {
      ...storageType
    }
    temperatureType
    tier
    zone
  }
  ${StorageTypeFragmentDoc}
`
export const MaxWeeklySpendFragmentDoc = gql`
  fragment maxWeeklySpend on MaxWeeklySpend {
    id
    name
  }
`
export const MheFragmentDoc = gql`
  fragment mhe on MaterialHandlingEquipment {
    id
    code
    class
    description
    heightLimit
    maximumWeight
    width
  }
`
export const OrderEntryFragmentDoc = gql`
  fragment orderEntry on OrderEntry {
    id
    orderNumber
    priority
    soCustomerRef
  }
`
export const OrderStatusFragmentDoc = gql`
  fragment orderStatus on OrderStatus {
    id
    code
    description
    purpose
  }
`
export const OrderTypeFragmentDoc = gql`
  fragment orderType on OrderType {
    id
    name
  }
`
export const PoFormatFragmentDoc = gql`
  fragment poFormat on POFormat {
    id
    name
  }
`
export const PoTransmissionMethodFragmentDoc = gql`
  fragment poTransmissionMethod on POTransmissionMethod {
    id
    name
  }
`
export const PickfaceFragmentDoc = gql`
  fragment pickface on ProductPickface {
    id
    budhol
    location
    maxFaceQty
    pickZone
    replenMulti
    replenTriggr
    warehouse
  }
`
export const ProductFragmentDoc = gql`
  fragment product on Product {
    id
    code
    altCode
    gTIN
    substitute
    linkCode
    description
    longDesc
    defGRStatus
    SKUType
    type
    INCICode
    manufacturerCode
    StorageType
    rotation
    facilityCat
    defBudhol
    defWH
    defHandType
    defPackType
    depCode
    lifeCycleStatus
    rank
    uNCode
    bandQty
    minPShlfLife
    minRShlfLife
    totShlfLife
    packValidateBatch
    capturePackType
    pickPartPalletsAsFull
    pickSingles
    pickDefId
    require {
      arrivalTemp
      batch
      batchInfo
      bestBefore
      budgetHolder
      cartonLP
      expiry
      handlingType
      killDte
      originCntry
      packType
      palletLP
      productionDte
      serial
      serials
      slaughterCode
      status
      weight
    }
    suppliers {
      id
      active
      supplierId
      warehouseId
      warehouse {
        code
        warehouseName
      }
      supplier {
        code
        name
      }
    }
    components {
      id
      childId
      qty
      parent {
        code
      }
      child {
        code
        description
      }
    }
  }
`
export const DocumentFragmentDoc = gql`
  fragment document on ProductDocument {
    id
    name
    type
    uploadBy
    uploaded
    usage
  }
`
export const LicencePlateFragmentDoc = gql`
  fragment licencePlate on LPDefinition {
    id
    depth
    diagram
    handType
    height
    layerQty
    maxLayerQty
    packType
    quantity
    weight
    width
  }
`
export const ProductStatusesFragmentDoc = gql`
  fragment productStatuses on ProductStatus {
    id
    code
    description
    adjustStock
    processStatus
    targetStatuses
  }
`
export const ProductSupplierFragmentDoc = gql`
  fragment productSupplier on ProductSupplier {
    id
    active
    supplierId
    warehouseId
  }
`
export const RoutingFragmentDoc = gql`
  fragment routing on DestinationRoute {
    days
    destination {
      id
      code
    }
    dropNumber
    frequency
    id
    route
  }
`
export const StockFragmentDoc = gql`
  fragment stock on Stock {
    locationId
    location {
      id
      code
    }
    productId
    product {
      id
      code
    }
    status
    licencePlateId
    licencePlate {
      id
      LPN
    }
    packType
    packs
    singles
    allocated
    attributes {
      ... on StockAttributes {
        id
        batch
        batchInfo
        bestBeforeDate
        budgetHolder
        catchWeight
        GRN
        killDate
        pONumber
        productId
        productionDate
        sellByDate
        serialNumber
        slaughterCode
      }
    }
  }
`
export const StockOwnerFragmentDoc = gql`
  fragment stockOwner on StockOwner {
    id
    code
    name
    status
    GLN
    coldStoreID
    VATNumber
    warnMoveDifStatus
    requireBudhol
    requireProdCategories
    scanBarcodeMask
    keyedBarcodeMask
    captureSerialsOnRcpt
    requirePalletID
    printPalLblsOnRcpt
    capturePONumOnRcpt
    allocateLocOnRcpt
    closeComPoOnRcpt
    validateBatchPO
    capturePkgInfoOnRcpt
    putwayLPOnRcpt
    confirmPutawaysOnRcpt
    reqAuthOnGRClose
    defaultUnbookedRcpt
    strictPODateVal
    splitSuppTolerance
    RVPutToDefLoc
    disablePutForSpecificLocs
    mandExtraInfoOnRcpt
    captureContainerType
    F2ShowBookedProdsOnly
    autoGeneratePONumber
    allowEditInRV
    GRPrintCrtnLbls
    identicalPallets
    noBckFlushGRProduction
    matchGRWithBookNo
    RVSuppressMergePF
    spltPOLinesOnPrint
    makeGRNBudhol
    allowShortShelfLife
    allowAddProdsToPO
    captureLocOnRcpt
    captureTempOnRcpt
    printLblsGRProduction
    autoGenOrderNum
    dispOrderEntryErrs
    requirePOD
    createPickOnOrdEntry
    dontClearLineCustRef
    mandLineCustref
    warnOnShortage
    printOrdRptWithPick
    forceBudholOnEntry
    preventBatchOnEntry
    swapGenericsOnEntry
    GDPRControlled
    printOrdConfRpt
    printBoxPackLbls
    cleanOrdersOnly
    createBackOrders
    allowShortageSwap
    allowBudholSwap
    chngeTraysOnPri
    noReplenPicksOnly
    allowStatusSwap
    pickCartons
    MPNoSplitLists
    showUnpickableRpt
    createPickOnOrdEntry
    forceBudholOnEntry
    forceDepartmentOnEntry
    preventBatchOnEntry
    swapGenericsOnEntry
    allowLPSwap
    allowSBDSwap
    findCartonByWgt
    captureCarrier
    allowLocSwap
    useBOMPicking
    requireDespNote
    voicePickAssignment
    allowBatchSwap
    requireConfProduct
    requireUPICheck
    cageValidation
    suppressReplenOpt
    despNoteValidation
    RFPickAssignment
    useLoading
    produceRFManifest
    requireSealNo
    useLoadOrder
    requireTruck
    requireDriver
    loadOrder
    despLane
    maxLoaders
    adhocLoading
    loadTempCaptureStart
    loadTempCaptureEnd
    loadTempLPInterval
    captureTimeOnConfirm
    captureCheckerOnConfirm
    capturePickerOnConfirm
    defPickFailCode
    requireDespatchLane
    useEmailFromDestination
    reprntUnfinishedLists
    destockShortOnSHort
    proportionalBoxesOnConfirm
    allowLoadAnySeq
    MBPLPControl
    MBPUseSODespMeth
    noDespNotes
    previewDespNote
    BOMSToStock
    delayDespatch
    RFWarnInPckConf
    noReplenPckConf
    despNotePerList
    disablImmDesp
    allowFutureDespDates
    MBPCaptureConsNo
    optConsNo
    boxLblsPickConf
    useLPIdsPickConfirm
    authMBP
    mandContainerType
    PDFDespNotes
    useDespPrefix
    noOfDespNotes
    confWholePick
    capReasonDelayDesp
    MBPDespNoteBreakdwn
    dontCloseOrderOnLblFail
    capBoxesPerOrder
    carrierNotReq
    copyCarrToBckOrd
    printBoxLbls
    noCarrLblsPckConf
  }
`
export const CategoryDefinitionFragmentDoc = gql`
  fragment categoryDefinition on CategoryDefinition {
    id
    code
    description
  }
`
export const PickConfirmMethodFragmentDoc = gql`
  fragment pickConfirmMethod on PickConfirmMethod {
    id
    name
    default
    pickConfMethod
    postConfMethod
    packingMethod
    despatchMethod
    loadCloseMethod
  }
`
export const PickMethodFragmentDoc = gql`
  fragment pickMethod on PickMethod {
    id
    default
    name
    outputMethod
    pickingMethod
    postPickMethod
    prePickMethod
  }
`
export const SupplierFragmentDoc = gql`
  fragment supplier on Supplier {
    id
    code
    name
    currency
    fileFormat
    fileTransMeth
    leadTime
    master
    maxWeekSpend
    mustOrderLayerQty
    orderDays
  }
`
export const UserFragmentDoc = gql`
  fragment user on WMSUser {
    id
    userName
    accessFailedCount
    culture
    email
    forename
    lockoutEnd
    normalizedUserName
    phoneNumber
    serverRole
    surname
    twoFactorEnabled
  }
`
export const UserPermissionFragmentDoc = gql`
  fragment userPermission on UserPermission {
    id
    permissions
    stockOwnerId
    userId
    warehouseId
    warehouse {
      code
      warehouseName
    }
    stockOwner {
      code
      name
    }
  }
`
export const WarehouseFragmentDoc = gql`
  fragment warehouse on Warehouse {
    id
    code
    warehouseName
    companyName
    telephone
    VATNumber
    customsId
  }
`
export const ZoneFragmentDoc = gql`
  fragment zone on Zone {
    id
    code
    description
  }
`
export const WarehouseZoneFragmentDoc = gql`
  fragment warehouseZone on WarehouseZone {
    id
    code
    description
  }
`
export const ZoneTypeFragmentDoc = gql`
  fragment zoneType on ZoneType {
    id
    name
  }
`
export const CreateBudgetHolderDocument = gql`
  mutation createBudgetHolder($input: BudgetHolderInput, $userContext: UserContextInput) {
    createBudgetHolder(input: $input, userContext: $userContext) {
      ...budgetHolder
    }
  }
  ${BudgetHolderFragmentDoc}
`
export type CreateBudgetHolderMutationFn = ApolloReactCommon.MutationFunction<
  CreateBudgetHolderMutation,
  CreateBudgetHolderMutationVariables
>
export type CreateBudgetHolderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateBudgetHolderMutation,
    CreateBudgetHolderMutationVariables
  >,
  'mutation'
>

export const CreateBudgetHolderComponent = (props: CreateBudgetHolderComponentProps) => (
  <ApolloReactComponents.Mutation<CreateBudgetHolderMutation, CreateBudgetHolderMutationVariables>
    mutation={CreateBudgetHolderDocument}
    {...props}
  />
)

export type CreateBudgetHolderProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateBudgetHolderMutation,
  CreateBudgetHolderMutationVariables
> &
  TChildProps
export function withCreateBudgetHolder<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateBudgetHolderMutation,
    CreateBudgetHolderMutationVariables,
    CreateBudgetHolderProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateBudgetHolderMutation,
    CreateBudgetHolderMutationVariables,
    CreateBudgetHolderProps<TChildProps>
  >(CreateBudgetHolderDocument, {
    alias: 'createBudgetHolder',
    ...operationOptions
  })
}

/**
 * __useCreateBudgetHolderMutation__
 *
 * To run a mutation, you first call `useCreateBudgetHolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBudgetHolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBudgetHolderMutation, { data, loading, error }] = useCreateBudgetHolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useCreateBudgetHolderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateBudgetHolderMutation,
    CreateBudgetHolderMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateBudgetHolderMutation,
    CreateBudgetHolderMutationVariables
  >(CreateBudgetHolderDocument, baseOptions)
}
export type CreateBudgetHolderMutationHookResult = ReturnType<typeof useCreateBudgetHolderMutation>
export type CreateBudgetHolderMutationResult = ApolloReactCommon.MutationResult<
  CreateBudgetHolderMutation
>
export type CreateBudgetHolderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateBudgetHolderMutation,
  CreateBudgetHolderMutationVariables
>
export const UpdateBudgetHolderDocument = gql`
  mutation updateBudgetHolder(
    $id: Int!
    $input: BudgetHolderUpdateInput
    $userContext: UserContextInput
  ) {
    updateBudgetHolder(id: $id, input: $input, userContext: $userContext) {
      ...budgetHolder
    }
  }
  ${BudgetHolderFragmentDoc}
`
export type UpdateBudgetHolderMutationFn = ApolloReactCommon.MutationFunction<
  UpdateBudgetHolderMutation,
  UpdateBudgetHolderMutationVariables
>
export type UpdateBudgetHolderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateBudgetHolderMutation,
    UpdateBudgetHolderMutationVariables
  >,
  'mutation'
>

export const UpdateBudgetHolderComponent = (props: UpdateBudgetHolderComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateBudgetHolderMutation, UpdateBudgetHolderMutationVariables>
    mutation={UpdateBudgetHolderDocument}
    {...props}
  />
)

export type UpdateBudgetHolderProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateBudgetHolderMutation,
  UpdateBudgetHolderMutationVariables
> &
  TChildProps
export function withUpdateBudgetHolder<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateBudgetHolderMutation,
    UpdateBudgetHolderMutationVariables,
    UpdateBudgetHolderProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateBudgetHolderMutation,
    UpdateBudgetHolderMutationVariables,
    UpdateBudgetHolderProps<TChildProps>
  >(UpdateBudgetHolderDocument, {
    alias: 'updateBudgetHolder',
    ...operationOptions
  })
}

/**
 * __useUpdateBudgetHolderMutation__
 *
 * To run a mutation, you first call `useUpdateBudgetHolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBudgetHolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBudgetHolderMutation, { data, loading, error }] = useUpdateBudgetHolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useUpdateBudgetHolderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateBudgetHolderMutation,
    UpdateBudgetHolderMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateBudgetHolderMutation,
    UpdateBudgetHolderMutationVariables
  >(UpdateBudgetHolderDocument, baseOptions)
}
export type UpdateBudgetHolderMutationHookResult = ReturnType<typeof useUpdateBudgetHolderMutation>
export type UpdateBudgetHolderMutationResult = ApolloReactCommon.MutationResult<
  UpdateBudgetHolderMutation
>
export type UpdateBudgetHolderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateBudgetHolderMutation,
  UpdateBudgetHolderMutationVariables
>
export const DeleteBudgetHolderDocument = gql`
  mutation deleteBudgetHolder($id: Int!, $userContext: UserContextInput) {
    deleteBudgetHolder(id: $id, userContext: $userContext) {
      id
    }
  }
`
export type DeleteBudgetHolderMutationFn = ApolloReactCommon.MutationFunction<
  DeleteBudgetHolderMutation,
  DeleteBudgetHolderMutationVariables
>
export type DeleteBudgetHolderComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteBudgetHolderMutation,
    DeleteBudgetHolderMutationVariables
  >,
  'mutation'
>

export const DeleteBudgetHolderComponent = (props: DeleteBudgetHolderComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteBudgetHolderMutation, DeleteBudgetHolderMutationVariables>
    mutation={DeleteBudgetHolderDocument}
    {...props}
  />
)

export type DeleteBudgetHolderProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteBudgetHolderMutation,
  DeleteBudgetHolderMutationVariables
> &
  TChildProps
export function withDeleteBudgetHolder<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteBudgetHolderMutation,
    DeleteBudgetHolderMutationVariables,
    DeleteBudgetHolderProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteBudgetHolderMutation,
    DeleteBudgetHolderMutationVariables,
    DeleteBudgetHolderProps<TChildProps>
  >(DeleteBudgetHolderDocument, {
    alias: 'deleteBudgetHolder',
    ...operationOptions
  })
}

/**
 * __useDeleteBudgetHolderMutation__
 *
 * To run a mutation, you first call `useDeleteBudgetHolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBudgetHolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBudgetHolderMutation, { data, loading, error }] = useDeleteBudgetHolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useDeleteBudgetHolderMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteBudgetHolderMutation,
    DeleteBudgetHolderMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteBudgetHolderMutation,
    DeleteBudgetHolderMutationVariables
  >(DeleteBudgetHolderDocument, baseOptions)
}
export type DeleteBudgetHolderMutationHookResult = ReturnType<typeof useDeleteBudgetHolderMutation>
export type DeleteBudgetHolderMutationResult = ApolloReactCommon.MutationResult<
  DeleteBudgetHolderMutation
>
export type DeleteBudgetHolderMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteBudgetHolderMutation,
  DeleteBudgetHolderMutationVariables
>
export const CreateCarrierDocument = gql`
  mutation createCarrier($input: CarrierInput, $userContext: UserContextInput) {
    createCarrier(input: $input, userContext: $userContext) {
      ...carrier
      accounts {
        ...carrierAccount
      }
      services {
        ...carrierService
      }
    }
  }
  ${CarrierFragmentDoc}
  ${CarrierAccountFragmentDoc}
  ${CarrierServiceFragmentDoc}
`
export type CreateCarrierMutationFn = ApolloReactCommon.MutationFunction<
  CreateCarrierMutation,
  CreateCarrierMutationVariables
>
export type CreateCarrierComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateCarrierMutation,
    CreateCarrierMutationVariables
  >,
  'mutation'
>

export const CreateCarrierComponent = (props: CreateCarrierComponentProps) => (
  <ApolloReactComponents.Mutation<CreateCarrierMutation, CreateCarrierMutationVariables>
    mutation={CreateCarrierDocument}
    {...props}
  />
)

export type CreateCarrierProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateCarrierMutation,
  CreateCarrierMutationVariables
> &
  TChildProps
export function withCreateCarrier<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCarrierMutation,
    CreateCarrierMutationVariables,
    CreateCarrierProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCarrierMutation,
    CreateCarrierMutationVariables,
    CreateCarrierProps<TChildProps>
  >(CreateCarrierDocument, {
    alias: 'createCarrier',
    ...operationOptions
  })
}

/**
 * __useCreateCarrierMutation__
 *
 * To run a mutation, you first call `useCreateCarrierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCarrierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCarrierMutation, { data, loading, error }] = useCreateCarrierMutation({
 *   variables: {
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useCreateCarrierMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCarrierMutation,
    CreateCarrierMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateCarrierMutation, CreateCarrierMutationVariables>(
    CreateCarrierDocument,
    baseOptions
  )
}
export type CreateCarrierMutationHookResult = ReturnType<typeof useCreateCarrierMutation>
export type CreateCarrierMutationResult = ApolloReactCommon.MutationResult<CreateCarrierMutation>
export type CreateCarrierMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCarrierMutation,
  CreateCarrierMutationVariables
>
export const UpdateCarrierDocument = gql`
  mutation updateCarrier($id: Int!, $input: CarrierUpdateInput, $userContext: UserContextInput) {
    updateCarrier(id: $id, update: $input, userContext: $userContext) {
      ...carrier
      accounts {
        ...carrierAccount
      }
      services {
        ...carrierService
      }
    }
  }
  ${CarrierFragmentDoc}
  ${CarrierAccountFragmentDoc}
  ${CarrierServiceFragmentDoc}
`
export type UpdateCarrierMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCarrierMutation,
  UpdateCarrierMutationVariables
>
export type UpdateCarrierComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCarrierMutation,
    UpdateCarrierMutationVariables
  >,
  'mutation'
>

export const UpdateCarrierComponent = (props: UpdateCarrierComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateCarrierMutation, UpdateCarrierMutationVariables>
    mutation={UpdateCarrierDocument}
    {...props}
  />
)

export type UpdateCarrierProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateCarrierMutation,
  UpdateCarrierMutationVariables
> &
  TChildProps
export function withUpdateCarrier<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCarrierMutation,
    UpdateCarrierMutationVariables,
    UpdateCarrierProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCarrierMutation,
    UpdateCarrierMutationVariables,
    UpdateCarrierProps<TChildProps>
  >(UpdateCarrierDocument, {
    alias: 'updateCarrier',
    ...operationOptions
  })
}

/**
 * __useUpdateCarrierMutation__
 *
 * To run a mutation, you first call `useUpdateCarrierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCarrierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCarrierMutation, { data, loading, error }] = useUpdateCarrierMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useUpdateCarrierMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCarrierMutation,
    UpdateCarrierMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateCarrierMutation, UpdateCarrierMutationVariables>(
    UpdateCarrierDocument,
    baseOptions
  )
}
export type UpdateCarrierMutationHookResult = ReturnType<typeof useUpdateCarrierMutation>
export type UpdateCarrierMutationResult = ApolloReactCommon.MutationResult<UpdateCarrierMutation>
export type UpdateCarrierMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCarrierMutation,
  UpdateCarrierMutationVariables
>
export const DeleteCarrierDocument = gql`
  mutation deleteCarrier($id: Int!, $userContext: UserContextInput) {
    deleteCarrier(id: $id, userContext: $userContext) {
      id
    }
  }
`
export type DeleteCarrierMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCarrierMutation,
  DeleteCarrierMutationVariables
>
export type DeleteCarrierComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteCarrierMutation,
    DeleteCarrierMutationVariables
  >,
  'mutation'
>

export const DeleteCarrierComponent = (props: DeleteCarrierComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteCarrierMutation, DeleteCarrierMutationVariables>
    mutation={DeleteCarrierDocument}
    {...props}
  />
)

export type DeleteCarrierProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteCarrierMutation,
  DeleteCarrierMutationVariables
> &
  TChildProps
export function withDeleteCarrier<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCarrierMutation,
    DeleteCarrierMutationVariables,
    DeleteCarrierProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCarrierMutation,
    DeleteCarrierMutationVariables,
    DeleteCarrierProps<TChildProps>
  >(DeleteCarrierDocument, {
    alias: 'deleteCarrier',
    ...operationOptions
  })
}

/**
 * __useDeleteCarrierMutation__
 *
 * To run a mutation, you first call `useDeleteCarrierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCarrierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCarrierMutation, { data, loading, error }] = useDeleteCarrierMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useDeleteCarrierMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCarrierMutation,
    DeleteCarrierMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteCarrierMutation, DeleteCarrierMutationVariables>(
    DeleteCarrierDocument,
    baseOptions
  )
}
export type DeleteCarrierMutationHookResult = ReturnType<typeof useDeleteCarrierMutation>
export type DeleteCarrierMutationResult = ApolloReactCommon.MutationResult<DeleteCarrierMutation>
export type DeleteCarrierMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCarrierMutation,
  DeleteCarrierMutationVariables
>
export const CreateCarrierAccountDocument = gql`
  mutation createCarrierAccount(
    $carrierId: Int!
    $input: CarrierAccountInput
    $userContext: UserContextInput
  ) {
    createCarrierAccount(carrierId: $carrierId, input: $input, userContext: $userContext) {
      id
      account
    }
  }
`
export type CreateCarrierAccountMutationFn = ApolloReactCommon.MutationFunction<
  CreateCarrierAccountMutation,
  CreateCarrierAccountMutationVariables
>
export type CreateCarrierAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateCarrierAccountMutation,
    CreateCarrierAccountMutationVariables
  >,
  'mutation'
>

export const CreateCarrierAccountComponent = (props: CreateCarrierAccountComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateCarrierAccountMutation,
    CreateCarrierAccountMutationVariables
  >
    mutation={CreateCarrierAccountDocument}
    {...props}
  />
)

export type CreateCarrierAccountProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateCarrierAccountMutation,
  CreateCarrierAccountMutationVariables
> &
  TChildProps
export function withCreateCarrierAccount<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateCarrierAccountMutation,
    CreateCarrierAccountMutationVariables,
    CreateCarrierAccountProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateCarrierAccountMutation,
    CreateCarrierAccountMutationVariables,
    CreateCarrierAccountProps<TChildProps>
  >(CreateCarrierAccountDocument, {
    alias: 'createCarrierAccount',
    ...operationOptions
  })
}

/**
 * __useCreateCarrierAccountMutation__
 *
 * To run a mutation, you first call `useCreateCarrierAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCarrierAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCarrierAccountMutation, { data, loading, error }] = useCreateCarrierAccountMutation({
 *   variables: {
 *      carrierId: // value for 'carrierId'
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useCreateCarrierAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCarrierAccountMutation,
    CreateCarrierAccountMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateCarrierAccountMutation,
    CreateCarrierAccountMutationVariables
  >(CreateCarrierAccountDocument, baseOptions)
}
export type CreateCarrierAccountMutationHookResult = ReturnType<
  typeof useCreateCarrierAccountMutation
>
export type CreateCarrierAccountMutationResult = ApolloReactCommon.MutationResult<
  CreateCarrierAccountMutation
>
export type CreateCarrierAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateCarrierAccountMutation,
  CreateCarrierAccountMutationVariables
>
export const UpdateCarrierAccountDocument = gql`
  mutation updateCarrierAccount(
    $id: Int!
    $carrierId: Int!
    $input: CarrierAccountUpdateInput
    $userContext: UserContextInput
  ) {
    updateCarrierAccount(
      id: $id
      carrierId: $carrierId
      update: $input
      userContext: $userContext
    ) {
      id
      account
    }
  }
`
export type UpdateCarrierAccountMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCarrierAccountMutation,
  UpdateCarrierAccountMutationVariables
>
export type UpdateCarrierAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCarrierAccountMutation,
    UpdateCarrierAccountMutationVariables
  >,
  'mutation'
>

export const UpdateCarrierAccountComponent = (props: UpdateCarrierAccountComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateCarrierAccountMutation,
    UpdateCarrierAccountMutationVariables
  >
    mutation={UpdateCarrierAccountDocument}
    {...props}
  />
)

export type UpdateCarrierAccountProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateCarrierAccountMutation,
  UpdateCarrierAccountMutationVariables
> &
  TChildProps
export function withUpdateCarrierAccount<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCarrierAccountMutation,
    UpdateCarrierAccountMutationVariables,
    UpdateCarrierAccountProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCarrierAccountMutation,
    UpdateCarrierAccountMutationVariables,
    UpdateCarrierAccountProps<TChildProps>
  >(UpdateCarrierAccountDocument, {
    alias: 'updateCarrierAccount',
    ...operationOptions
  })
}

/**
 * __useUpdateCarrierAccountMutation__
 *
 * To run a mutation, you first call `useUpdateCarrierAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCarrierAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCarrierAccountMutation, { data, loading, error }] = useUpdateCarrierAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      carrierId: // value for 'carrierId'
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useUpdateCarrierAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCarrierAccountMutation,
    UpdateCarrierAccountMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCarrierAccountMutation,
    UpdateCarrierAccountMutationVariables
  >(UpdateCarrierAccountDocument, baseOptions)
}
export type UpdateCarrierAccountMutationHookResult = ReturnType<
  typeof useUpdateCarrierAccountMutation
>
export type UpdateCarrierAccountMutationResult = ApolloReactCommon.MutationResult<
  UpdateCarrierAccountMutation
>
export type UpdateCarrierAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCarrierAccountMutation,
  UpdateCarrierAccountMutationVariables
>
export const DeleteCarrierAccountDocument = gql`
  mutation deleteCarrierAccount($id: Int!, $carrierId: Int!, $userContext: UserContextInput) {
    deleteCarrierAccount(id: $id, carrierId: $carrierId, userContext: $userContext) {
      id
      account
    }
  }
`
export type DeleteCarrierAccountMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCarrierAccountMutation,
  DeleteCarrierAccountMutationVariables
>
export type DeleteCarrierAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteCarrierAccountMutation,
    DeleteCarrierAccountMutationVariables
  >,
  'mutation'
>

export const DeleteCarrierAccountComponent = (props: DeleteCarrierAccountComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteCarrierAccountMutation,
    DeleteCarrierAccountMutationVariables
  >
    mutation={DeleteCarrierAccountDocument}
    {...props}
  />
)

export type DeleteCarrierAccountProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteCarrierAccountMutation,
  DeleteCarrierAccountMutationVariables
> &
  TChildProps
export function withDeleteCarrierAccount<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCarrierAccountMutation,
    DeleteCarrierAccountMutationVariables,
    DeleteCarrierAccountProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCarrierAccountMutation,
    DeleteCarrierAccountMutationVariables,
    DeleteCarrierAccountProps<TChildProps>
  >(DeleteCarrierAccountDocument, {
    alias: 'deleteCarrierAccount',
    ...operationOptions
  })
}

/**
 * __useDeleteCarrierAccountMutation__
 *
 * To run a mutation, you first call `useDeleteCarrierAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCarrierAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCarrierAccountMutation, { data, loading, error }] = useDeleteCarrierAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      carrierId: // value for 'carrierId'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useDeleteCarrierAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCarrierAccountMutation,
    DeleteCarrierAccountMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteCarrierAccountMutation,
    DeleteCarrierAccountMutationVariables
  >(DeleteCarrierAccountDocument, baseOptions)
}
export type DeleteCarrierAccountMutationHookResult = ReturnType<
  typeof useDeleteCarrierAccountMutation
>
export type DeleteCarrierAccountMutationResult = ApolloReactCommon.MutationResult<
  DeleteCarrierAccountMutation
>
export type DeleteCarrierAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCarrierAccountMutation,
  DeleteCarrierAccountMutationVariables
>
export const AddCarrierConfigDocument = gql`
  mutation addCarrierConfig(
    $accountId: Int!
    $carrierId: Int!
    $config: CarrierConfigInput
    $userContext: UserContextInput
  ) {
    addCarrierConfig(
      accountId: $accountId
      carrierId: $carrierId
      config: $config
      userContext: $userContext
    ) {
      id
      setting
      value
    }
  }
`
export type AddCarrierConfigMutationFn = ApolloReactCommon.MutationFunction<
  AddCarrierConfigMutation,
  AddCarrierConfigMutationVariables
>
export type AddCarrierConfigComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddCarrierConfigMutation,
    AddCarrierConfigMutationVariables
  >,
  'mutation'
>

export const AddCarrierConfigComponent = (props: AddCarrierConfigComponentProps) => (
  <ApolloReactComponents.Mutation<AddCarrierConfigMutation, AddCarrierConfigMutationVariables>
    mutation={AddCarrierConfigDocument}
    {...props}
  />
)

export type AddCarrierConfigProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  AddCarrierConfigMutation,
  AddCarrierConfigMutationVariables
> &
  TChildProps
export function withAddCarrierConfig<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddCarrierConfigMutation,
    AddCarrierConfigMutationVariables,
    AddCarrierConfigProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AddCarrierConfigMutation,
    AddCarrierConfigMutationVariables,
    AddCarrierConfigProps<TChildProps>
  >(AddCarrierConfigDocument, {
    alias: 'addCarrierConfig',
    ...operationOptions
  })
}

/**
 * __useAddCarrierConfigMutation__
 *
 * To run a mutation, you first call `useAddCarrierConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCarrierConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCarrierConfigMutation, { data, loading, error }] = useAddCarrierConfigMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      carrierId: // value for 'carrierId'
 *      config: // value for 'config'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useAddCarrierConfigMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddCarrierConfigMutation,
    AddCarrierConfigMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<AddCarrierConfigMutation, AddCarrierConfigMutationVariables>(
    AddCarrierConfigDocument,
    baseOptions
  )
}
export type AddCarrierConfigMutationHookResult = ReturnType<typeof useAddCarrierConfigMutation>
export type AddCarrierConfigMutationResult = ApolloReactCommon.MutationResult<
  AddCarrierConfigMutation
>
export type AddCarrierConfigMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddCarrierConfigMutation,
  AddCarrierConfigMutationVariables
>
export const UpdateCarrierConfigDocument = gql`
  mutation updateCarrierConfig(
    $accountId: Int!
    $carrierId: Int!
    $id: Int!
    $config: CarrierConfigUpdateInput
    $userContext: UserContextInput
  ) {
    updateCarrierConfig(
      accountId: $accountId
      carrierId: $carrierId
      id: $id
      update: $config
      userContext: $userContext
    ) {
      id
      setting
      value
    }
  }
`
export type UpdateCarrierConfigMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCarrierConfigMutation,
  UpdateCarrierConfigMutationVariables
>
export type UpdateCarrierConfigComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCarrierConfigMutation,
    UpdateCarrierConfigMutationVariables
  >,
  'mutation'
>

export const UpdateCarrierConfigComponent = (props: UpdateCarrierConfigComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateCarrierConfigMutation, UpdateCarrierConfigMutationVariables>
    mutation={UpdateCarrierConfigDocument}
    {...props}
  />
)

export type UpdateCarrierConfigProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateCarrierConfigMutation,
  UpdateCarrierConfigMutationVariables
> &
  TChildProps
export function withUpdateCarrierConfig<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCarrierConfigMutation,
    UpdateCarrierConfigMutationVariables,
    UpdateCarrierConfigProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCarrierConfigMutation,
    UpdateCarrierConfigMutationVariables,
    UpdateCarrierConfigProps<TChildProps>
  >(UpdateCarrierConfigDocument, {
    alias: 'updateCarrierConfig',
    ...operationOptions
  })
}

/**
 * __useUpdateCarrierConfigMutation__
 *
 * To run a mutation, you first call `useUpdateCarrierConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCarrierConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCarrierConfigMutation, { data, loading, error }] = useUpdateCarrierConfigMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      carrierId: // value for 'carrierId'
 *      id: // value for 'id'
 *      config: // value for 'config'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useUpdateCarrierConfigMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCarrierConfigMutation,
    UpdateCarrierConfigMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCarrierConfigMutation,
    UpdateCarrierConfigMutationVariables
  >(UpdateCarrierConfigDocument, baseOptions)
}
export type UpdateCarrierConfigMutationHookResult = ReturnType<
  typeof useUpdateCarrierConfigMutation
>
export type UpdateCarrierConfigMutationResult = ApolloReactCommon.MutationResult<
  UpdateCarrierConfigMutation
>
export type UpdateCarrierConfigMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCarrierConfigMutation,
  UpdateCarrierConfigMutationVariables
>
export const RemoveCarrierConfigDocument = gql`
  mutation removeCarrierConfig(
    $accountId: Int!
    $carrierId: Int!
    $id: Int!
    $userContext: UserContextInput
  ) {
    removeCarrierConfig(
      accountId: $accountId
      carrierId: $carrierId
      id: $id
      userContext: $userContext
    ) {
      id
      setting
      value
    }
  }
`
export type RemoveCarrierConfigMutationFn = ApolloReactCommon.MutationFunction<
  RemoveCarrierConfigMutation,
  RemoveCarrierConfigMutationVariables
>
export type RemoveCarrierConfigComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RemoveCarrierConfigMutation,
    RemoveCarrierConfigMutationVariables
  >,
  'mutation'
>

export const RemoveCarrierConfigComponent = (props: RemoveCarrierConfigComponentProps) => (
  <ApolloReactComponents.Mutation<RemoveCarrierConfigMutation, RemoveCarrierConfigMutationVariables>
    mutation={RemoveCarrierConfigDocument}
    {...props}
  />
)

export type RemoveCarrierConfigProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  RemoveCarrierConfigMutation,
  RemoveCarrierConfigMutationVariables
> &
  TChildProps
export function withRemoveCarrierConfig<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RemoveCarrierConfigMutation,
    RemoveCarrierConfigMutationVariables,
    RemoveCarrierConfigProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RemoveCarrierConfigMutation,
    RemoveCarrierConfigMutationVariables,
    RemoveCarrierConfigProps<TChildProps>
  >(RemoveCarrierConfigDocument, {
    alias: 'removeCarrierConfig',
    ...operationOptions
  })
}

/**
 * __useRemoveCarrierConfigMutation__
 *
 * To run a mutation, you first call `useRemoveCarrierConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCarrierConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCarrierConfigMutation, { data, loading, error }] = useRemoveCarrierConfigMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      carrierId: // value for 'carrierId'
 *      id: // value for 'id'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useRemoveCarrierConfigMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveCarrierConfigMutation,
    RemoveCarrierConfigMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RemoveCarrierConfigMutation,
    RemoveCarrierConfigMutationVariables
  >(RemoveCarrierConfigDocument, baseOptions)
}
export type RemoveCarrierConfigMutationHookResult = ReturnType<
  typeof useRemoveCarrierConfigMutation
>
export type RemoveCarrierConfigMutationResult = ApolloReactCommon.MutationResult<
  RemoveCarrierConfigMutation
>
export type RemoveCarrierConfigMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveCarrierConfigMutation,
  RemoveCarrierConfigMutationVariables
>
export const CreatCarrierServiceDocument = gql`
  mutation creatCarrierService(
    $carrierId: Int!
    $input: CarrierServiceInput
    $userContext: UserContextInput
  ) {
    createCarrierService(carrierId: $carrierId, input: $input, userContext: $userContext) {
      id
      code
    }
  }
`
export type CreatCarrierServiceMutationFn = ApolloReactCommon.MutationFunction<
  CreatCarrierServiceMutation,
  CreatCarrierServiceMutationVariables
>
export type CreatCarrierServiceComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreatCarrierServiceMutation,
    CreatCarrierServiceMutationVariables
  >,
  'mutation'
>

export const CreatCarrierServiceComponent = (props: CreatCarrierServiceComponentProps) => (
  <ApolloReactComponents.Mutation<CreatCarrierServiceMutation, CreatCarrierServiceMutationVariables>
    mutation={CreatCarrierServiceDocument}
    {...props}
  />
)

export type CreatCarrierServiceProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreatCarrierServiceMutation,
  CreatCarrierServiceMutationVariables
> &
  TChildProps
export function withCreatCarrierService<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreatCarrierServiceMutation,
    CreatCarrierServiceMutationVariables,
    CreatCarrierServiceProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreatCarrierServiceMutation,
    CreatCarrierServiceMutationVariables,
    CreatCarrierServiceProps<TChildProps>
  >(CreatCarrierServiceDocument, {
    alias: 'creatCarrierService',
    ...operationOptions
  })
}

/**
 * __useCreatCarrierServiceMutation__
 *
 * To run a mutation, you first call `useCreatCarrierServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatCarrierServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [creatCarrierServiceMutation, { data, loading, error }] = useCreatCarrierServiceMutation({
 *   variables: {
 *      carrierId: // value for 'carrierId'
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useCreatCarrierServiceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatCarrierServiceMutation,
    CreatCarrierServiceMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreatCarrierServiceMutation,
    CreatCarrierServiceMutationVariables
  >(CreatCarrierServiceDocument, baseOptions)
}
export type CreatCarrierServiceMutationHookResult = ReturnType<
  typeof useCreatCarrierServiceMutation
>
export type CreatCarrierServiceMutationResult = ApolloReactCommon.MutationResult<
  CreatCarrierServiceMutation
>
export type CreatCarrierServiceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreatCarrierServiceMutation,
  CreatCarrierServiceMutationVariables
>
export const UpdateCarrierServiceDocument = gql`
  mutation updateCarrierService(
    $id: Int!
    $carrierId: Int!
    $input: CarrierServiceUpdateInput
    $userContext: UserContextInput
  ) {
    updateCarrierService(
      carrierId: $carrierId
      id: $id
      update: $input
      userContext: $userContext
    ) {
      id
      code
    }
  }
`
export type UpdateCarrierServiceMutationFn = ApolloReactCommon.MutationFunction<
  UpdateCarrierServiceMutation,
  UpdateCarrierServiceMutationVariables
>
export type UpdateCarrierServiceComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCarrierServiceMutation,
    UpdateCarrierServiceMutationVariables
  >,
  'mutation'
>

export const UpdateCarrierServiceComponent = (props: UpdateCarrierServiceComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateCarrierServiceMutation,
    UpdateCarrierServiceMutationVariables
  >
    mutation={UpdateCarrierServiceDocument}
    {...props}
  />
)

export type UpdateCarrierServiceProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateCarrierServiceMutation,
  UpdateCarrierServiceMutationVariables
> &
  TChildProps
export function withUpdateCarrierService<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateCarrierServiceMutation,
    UpdateCarrierServiceMutationVariables,
    UpdateCarrierServiceProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateCarrierServiceMutation,
    UpdateCarrierServiceMutationVariables,
    UpdateCarrierServiceProps<TChildProps>
  >(UpdateCarrierServiceDocument, {
    alias: 'updateCarrierService',
    ...operationOptions
  })
}

/**
 * __useUpdateCarrierServiceMutation__
 *
 * To run a mutation, you first call `useUpdateCarrierServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCarrierServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCarrierServiceMutation, { data, loading, error }] = useUpdateCarrierServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      carrierId: // value for 'carrierId'
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useUpdateCarrierServiceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCarrierServiceMutation,
    UpdateCarrierServiceMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateCarrierServiceMutation,
    UpdateCarrierServiceMutationVariables
  >(UpdateCarrierServiceDocument, baseOptions)
}
export type UpdateCarrierServiceMutationHookResult = ReturnType<
  typeof useUpdateCarrierServiceMutation
>
export type UpdateCarrierServiceMutationResult = ApolloReactCommon.MutationResult<
  UpdateCarrierServiceMutation
>
export type UpdateCarrierServiceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateCarrierServiceMutation,
  UpdateCarrierServiceMutationVariables
>
export const DeleteCarrierServiceDocument = gql`
  mutation deleteCarrierService($id: Int!, $carrierId: Int!, $userContext: UserContextInput) {
    deleteCarrierService(carrierId: $carrierId, id: $id, userContext: $userContext) {
      id
      code
    }
  }
`
export type DeleteCarrierServiceMutationFn = ApolloReactCommon.MutationFunction<
  DeleteCarrierServiceMutation,
  DeleteCarrierServiceMutationVariables
>
export type DeleteCarrierServiceComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteCarrierServiceMutation,
    DeleteCarrierServiceMutationVariables
  >,
  'mutation'
>

export const DeleteCarrierServiceComponent = (props: DeleteCarrierServiceComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteCarrierServiceMutation,
    DeleteCarrierServiceMutationVariables
  >
    mutation={DeleteCarrierServiceDocument}
    {...props}
  />
)

export type DeleteCarrierServiceProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteCarrierServiceMutation,
  DeleteCarrierServiceMutationVariables
> &
  TChildProps
export function withDeleteCarrierService<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteCarrierServiceMutation,
    DeleteCarrierServiceMutationVariables,
    DeleteCarrierServiceProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteCarrierServiceMutation,
    DeleteCarrierServiceMutationVariables,
    DeleteCarrierServiceProps<TChildProps>
  >(DeleteCarrierServiceDocument, {
    alias: 'deleteCarrierService',
    ...operationOptions
  })
}

/**
 * __useDeleteCarrierServiceMutation__
 *
 * To run a mutation, you first call `useDeleteCarrierServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCarrierServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCarrierServiceMutation, { data, loading, error }] = useDeleteCarrierServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      carrierId: // value for 'carrierId'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useDeleteCarrierServiceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteCarrierServiceMutation,
    DeleteCarrierServiceMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteCarrierServiceMutation,
    DeleteCarrierServiceMutationVariables
  >(DeleteCarrierServiceDocument, baseOptions)
}
export type DeleteCarrierServiceMutationHookResult = ReturnType<
  typeof useDeleteCarrierServiceMutation
>
export type DeleteCarrierServiceMutationResult = ApolloReactCommon.MutationResult<
  DeleteCarrierServiceMutation
>
export type DeleteCarrierServiceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteCarrierServiceMutation,
  DeleteCarrierServiceMutationVariables
>
export const CreateUdcDocument = gql`
  mutation createUDC($input: UDCodeInput, $userContext: UserContextInput) {
    createUDC(input: $input, userContext: $userContext) {
      ...code
    }
  }
  ${CodeFragmentDoc}
`
export type CreateUdcMutationFn = ApolloReactCommon.MutationFunction<
  CreateUdcMutation,
  CreateUdcMutationVariables
>
export type CreateUdcComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateUdcMutation, CreateUdcMutationVariables>,
  'mutation'
>

export const CreateUdcComponent = (props: CreateUdcComponentProps) => (
  <ApolloReactComponents.Mutation<CreateUdcMutation, CreateUdcMutationVariables>
    mutation={CreateUdcDocument}
    {...props}
  />
)

export type CreateUdcProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateUdcMutation,
  CreateUdcMutationVariables
> &
  TChildProps
export function withCreateUdc<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateUdcMutation,
    CreateUdcMutationVariables,
    CreateUdcProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateUdcMutation,
    CreateUdcMutationVariables,
    CreateUdcProps<TChildProps>
  >(CreateUdcDocument, {
    alias: 'createUdc',
    ...operationOptions
  })
}

/**
 * __useCreateUdcMutation__
 *
 * To run a mutation, you first call `useCreateUdcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUdcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUdcMutation, { data, loading, error }] = useCreateUdcMutation({
 *   variables: {
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useCreateUdcMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUdcMutation, CreateUdcMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateUdcMutation, CreateUdcMutationVariables>(
    CreateUdcDocument,
    baseOptions
  )
}
export type CreateUdcMutationHookResult = ReturnType<typeof useCreateUdcMutation>
export type CreateUdcMutationResult = ApolloReactCommon.MutationResult<CreateUdcMutation>
export type CreateUdcMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateUdcMutation,
  CreateUdcMutationVariables
>
export const UpdateUdcDocument = gql`
  mutation updateUDC($id: Int!, $input: UDCodeUpdateInput, $userContext: UserContextInput) {
    updateUDC(id: $id, input: $input, userContext: $userContext) {
      ...code
    }
  }
  ${CodeFragmentDoc}
`
export type UpdateUdcMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUdcMutation,
  UpdateUdcMutationVariables
>
export type UpdateUdcComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateUdcMutation, UpdateUdcMutationVariables>,
  'mutation'
>

export const UpdateUdcComponent = (props: UpdateUdcComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateUdcMutation, UpdateUdcMutationVariables>
    mutation={UpdateUdcDocument}
    {...props}
  />
)

export type UpdateUdcProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateUdcMutation,
  UpdateUdcMutationVariables
> &
  TChildProps
export function withUpdateUdc<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUdcMutation,
    UpdateUdcMutationVariables,
    UpdateUdcProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUdcMutation,
    UpdateUdcMutationVariables,
    UpdateUdcProps<TChildProps>
  >(UpdateUdcDocument, {
    alias: 'updateUdc',
    ...operationOptions
  })
}

/**
 * __useUpdateUdcMutation__
 *
 * To run a mutation, you first call `useUpdateUdcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUdcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUdcMutation, { data, loading, error }] = useUpdateUdcMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useUpdateUdcMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUdcMutation, UpdateUdcMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateUdcMutation, UpdateUdcMutationVariables>(
    UpdateUdcDocument,
    baseOptions
  )
}
export type UpdateUdcMutationHookResult = ReturnType<typeof useUpdateUdcMutation>
export type UpdateUdcMutationResult = ApolloReactCommon.MutationResult<UpdateUdcMutation>
export type UpdateUdcMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUdcMutation,
  UpdateUdcMutationVariables
>
export const DeleteUdcDocument = gql`
  mutation deleteUDC($id: Int!, $userContext: UserContextInput) {
    deleteUDC(id: $id, userContext: $userContext) {
      ...code
    }
  }
  ${CodeFragmentDoc}
`
export type DeleteUdcMutationFn = ApolloReactCommon.MutationFunction<
  DeleteUdcMutation,
  DeleteUdcMutationVariables
>
export type DeleteUdcComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteUdcMutation, DeleteUdcMutationVariables>,
  'mutation'
>

export const DeleteUdcComponent = (props: DeleteUdcComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteUdcMutation, DeleteUdcMutationVariables>
    mutation={DeleteUdcDocument}
    {...props}
  />
)

export type DeleteUdcProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteUdcMutation,
  DeleteUdcMutationVariables
> &
  TChildProps
export function withDeleteUdc<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteUdcMutation,
    DeleteUdcMutationVariables,
    DeleteUdcProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteUdcMutation,
    DeleteUdcMutationVariables,
    DeleteUdcProps<TChildProps>
  >(DeleteUdcDocument, {
    alias: 'deleteUdc',
    ...operationOptions
  })
}

/**
 * __useDeleteUdcMutation__
 *
 * To run a mutation, you first call `useDeleteUdcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUdcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUdcMutation, { data, loading, error }] = useDeleteUdcMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useDeleteUdcMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUdcMutation, DeleteUdcMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteUdcMutation, DeleteUdcMutationVariables>(
    DeleteUdcDocument,
    baseOptions
  )
}
export type DeleteUdcMutationHookResult = ReturnType<typeof useDeleteUdcMutation>
export type DeleteUdcMutationResult = ApolloReactCommon.MutationResult<DeleteUdcMutation>
export type DeleteUdcMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteUdcMutation,
  DeleteUdcMutationVariables
>
export const CreateDestinationDocument = gql`
  mutation createDestination($input: DestinationInput, $userContext: UserContextInput) {
    createDestination(input: $input, userContext: $userContext) {
      ...destination
      address {
        ...address
      }
      budgets {
        ...budget
      }
      contact {
        ...contact
      }
      routing {
        ...routing
      }
    }
  }
  ${DestinationFragmentDoc}
  ${AddressFragmentDoc}
  ${BudgetFragmentDoc}
  ${ContactFragmentDoc}
  ${RoutingFragmentDoc}
`
export type CreateDestinationMutationFn = ApolloReactCommon.MutationFunction<
  CreateDestinationMutation,
  CreateDestinationMutationVariables
>
export type CreateDestinationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateDestinationMutation,
    CreateDestinationMutationVariables
  >,
  'mutation'
>

export const CreateDestinationComponent = (props: CreateDestinationComponentProps) => (
  <ApolloReactComponents.Mutation<CreateDestinationMutation, CreateDestinationMutationVariables>
    mutation={CreateDestinationDocument}
    {...props}
  />
)

export type CreateDestinationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateDestinationMutation,
  CreateDestinationMutationVariables
> &
  TChildProps
export function withCreateDestination<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateDestinationMutation,
    CreateDestinationMutationVariables,
    CreateDestinationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateDestinationMutation,
    CreateDestinationMutationVariables,
    CreateDestinationProps<TChildProps>
  >(CreateDestinationDocument, {
    alias: 'createDestination',
    ...operationOptions
  })
}

/**
 * __useCreateDestinationMutation__
 *
 * To run a mutation, you first call `useCreateDestinationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDestinationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDestinationMutation, { data, loading, error }] = useCreateDestinationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useCreateDestinationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateDestinationMutation,
    CreateDestinationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateDestinationMutation,
    CreateDestinationMutationVariables
  >(CreateDestinationDocument, baseOptions)
}
export type CreateDestinationMutationHookResult = ReturnType<typeof useCreateDestinationMutation>
export type CreateDestinationMutationResult = ApolloReactCommon.MutationResult<
  CreateDestinationMutation
>
export type CreateDestinationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateDestinationMutation,
  CreateDestinationMutationVariables
>
export const UpdateDestinationDocument = gql`
  mutation updateDestination(
    $id: Int!
    $input: DestinationUpdateInput
    $userContext: UserContextInput
  ) {
    updateDestination(id: $id, update: $input, userContext: $userContext) {
      ...destination
      address {
        ...address
      }
      budgets {
        ...budget
      }
      contact {
        ...contact
      }
      routing {
        ...routing
      }
    }
  }
  ${DestinationFragmentDoc}
  ${AddressFragmentDoc}
  ${BudgetFragmentDoc}
  ${ContactFragmentDoc}
  ${RoutingFragmentDoc}
`
export type UpdateDestinationMutationFn = ApolloReactCommon.MutationFunction<
  UpdateDestinationMutation,
  UpdateDestinationMutationVariables
>
export type UpdateDestinationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateDestinationMutation,
    UpdateDestinationMutationVariables
  >,
  'mutation'
>

export const UpdateDestinationComponent = (props: UpdateDestinationComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateDestinationMutation, UpdateDestinationMutationVariables>
    mutation={UpdateDestinationDocument}
    {...props}
  />
)

export type UpdateDestinationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateDestinationMutation,
  UpdateDestinationMutationVariables
> &
  TChildProps
export function withUpdateDestination<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateDestinationMutation,
    UpdateDestinationMutationVariables,
    UpdateDestinationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateDestinationMutation,
    UpdateDestinationMutationVariables,
    UpdateDestinationProps<TChildProps>
  >(UpdateDestinationDocument, {
    alias: 'updateDestination',
    ...operationOptions
  })
}

/**
 * __useUpdateDestinationMutation__
 *
 * To run a mutation, you first call `useUpdateDestinationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDestinationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDestinationMutation, { data, loading, error }] = useUpdateDestinationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useUpdateDestinationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateDestinationMutation,
    UpdateDestinationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateDestinationMutation,
    UpdateDestinationMutationVariables
  >(UpdateDestinationDocument, baseOptions)
}
export type UpdateDestinationMutationHookResult = ReturnType<typeof useUpdateDestinationMutation>
export type UpdateDestinationMutationResult = ApolloReactCommon.MutationResult<
  UpdateDestinationMutation
>
export type UpdateDestinationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateDestinationMutation,
  UpdateDestinationMutationVariables
>
export const DeleteDestinationDocument = gql`
  mutation deleteDestination($id: Int!, $userContext: UserContextInput) {
    deleteDestination(id: $id, userContext: $userContext) {
      id
    }
  }
`
export type DeleteDestinationMutationFn = ApolloReactCommon.MutationFunction<
  DeleteDestinationMutation,
  DeleteDestinationMutationVariables
>
export type DeleteDestinationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteDestinationMutation,
    DeleteDestinationMutationVariables
  >,
  'mutation'
>

export const DeleteDestinationComponent = (props: DeleteDestinationComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteDestinationMutation, DeleteDestinationMutationVariables>
    mutation={DeleteDestinationDocument}
    {...props}
  />
)

export type DeleteDestinationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteDestinationMutation,
  DeleteDestinationMutationVariables
> &
  TChildProps
export function withDeleteDestination<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteDestinationMutation,
    DeleteDestinationMutationVariables,
    DeleteDestinationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteDestinationMutation,
    DeleteDestinationMutationVariables,
    DeleteDestinationProps<TChildProps>
  >(DeleteDestinationDocument, {
    alias: 'deleteDestination',
    ...operationOptions
  })
}

/**
 * __useDeleteDestinationMutation__
 *
 * To run a mutation, you first call `useDeleteDestinationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDestinationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDestinationMutation, { data, loading, error }] = useDeleteDestinationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useDeleteDestinationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteDestinationMutation,
    DeleteDestinationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteDestinationMutation,
    DeleteDestinationMutationVariables
  >(DeleteDestinationDocument, baseOptions)
}
export type DeleteDestinationMutationHookResult = ReturnType<typeof useDeleteDestinationMutation>
export type DeleteDestinationMutationResult = ApolloReactCommon.MutationResult<
  DeleteDestinationMutation
>
export type DeleteDestinationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteDestinationMutation,
  DeleteDestinationMutationVariables
>
export const CreateDestiantnionBudgetDocument = gql`
  mutation createDestiantnionBudget(
    $destinationId: Int!
    $input: DestinationBudgetInput
    $userContext: UserContextInput
  ) {
    createDestinationBudget(
      destinationId: $destinationId
      input: $input
      userContext: $userContext
    ) {
      id
    }
  }
`
export type CreateDestiantnionBudgetMutationFn = ApolloReactCommon.MutationFunction<
  CreateDestiantnionBudgetMutation,
  CreateDestiantnionBudgetMutationVariables
>
export type CreateDestiantnionBudgetComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateDestiantnionBudgetMutation,
    CreateDestiantnionBudgetMutationVariables
  >,
  'mutation'
>

export const CreateDestiantnionBudgetComponent = (
  props: CreateDestiantnionBudgetComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateDestiantnionBudgetMutation,
    CreateDestiantnionBudgetMutationVariables
  >
    mutation={CreateDestiantnionBudgetDocument}
    {...props}
  />
)

export type CreateDestiantnionBudgetProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateDestiantnionBudgetMutation,
  CreateDestiantnionBudgetMutationVariables
> &
  TChildProps
export function withCreateDestiantnionBudget<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateDestiantnionBudgetMutation,
    CreateDestiantnionBudgetMutationVariables,
    CreateDestiantnionBudgetProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateDestiantnionBudgetMutation,
    CreateDestiantnionBudgetMutationVariables,
    CreateDestiantnionBudgetProps<TChildProps>
  >(CreateDestiantnionBudgetDocument, {
    alias: 'createDestiantnionBudget',
    ...operationOptions
  })
}

/**
 * __useCreateDestiantnionBudgetMutation__
 *
 * To run a mutation, you first call `useCreateDestiantnionBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDestiantnionBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDestiantnionBudgetMutation, { data, loading, error }] = useCreateDestiantnionBudgetMutation({
 *   variables: {
 *      destinationId: // value for 'destinationId'
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useCreateDestiantnionBudgetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateDestiantnionBudgetMutation,
    CreateDestiantnionBudgetMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateDestiantnionBudgetMutation,
    CreateDestiantnionBudgetMutationVariables
  >(CreateDestiantnionBudgetDocument, baseOptions)
}
export type CreateDestiantnionBudgetMutationHookResult = ReturnType<
  typeof useCreateDestiantnionBudgetMutation
>
export type CreateDestiantnionBudgetMutationResult = ApolloReactCommon.MutationResult<
  CreateDestiantnionBudgetMutation
>
export type CreateDestiantnionBudgetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateDestiantnionBudgetMutation,
  CreateDestiantnionBudgetMutationVariables
>
export const UpdateDestiantnionBudgetDocument = gql`
  mutation updateDestiantnionBudget(
    $destinationId: Int!
    $id: Int!
    $input: DestinationBudgetUpdateInput
    $userContext: UserContextInput
  ) {
    updateDestinationBudget(
      destinationId: $destinationId
      id: $id
      update: $input
      userContext: $userContext
    ) {
      id
    }
  }
`
export type UpdateDestiantnionBudgetMutationFn = ApolloReactCommon.MutationFunction<
  UpdateDestiantnionBudgetMutation,
  UpdateDestiantnionBudgetMutationVariables
>
export type UpdateDestiantnionBudgetComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateDestiantnionBudgetMutation,
    UpdateDestiantnionBudgetMutationVariables
  >,
  'mutation'
>

export const UpdateDestiantnionBudgetComponent = (
  props: UpdateDestiantnionBudgetComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateDestiantnionBudgetMutation,
    UpdateDestiantnionBudgetMutationVariables
  >
    mutation={UpdateDestiantnionBudgetDocument}
    {...props}
  />
)

export type UpdateDestiantnionBudgetProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateDestiantnionBudgetMutation,
  UpdateDestiantnionBudgetMutationVariables
> &
  TChildProps
export function withUpdateDestiantnionBudget<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateDestiantnionBudgetMutation,
    UpdateDestiantnionBudgetMutationVariables,
    UpdateDestiantnionBudgetProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateDestiantnionBudgetMutation,
    UpdateDestiantnionBudgetMutationVariables,
    UpdateDestiantnionBudgetProps<TChildProps>
  >(UpdateDestiantnionBudgetDocument, {
    alias: 'updateDestiantnionBudget',
    ...operationOptions
  })
}

/**
 * __useUpdateDestiantnionBudgetMutation__
 *
 * To run a mutation, you first call `useUpdateDestiantnionBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDestiantnionBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDestiantnionBudgetMutation, { data, loading, error }] = useUpdateDestiantnionBudgetMutation({
 *   variables: {
 *      destinationId: // value for 'destinationId'
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useUpdateDestiantnionBudgetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateDestiantnionBudgetMutation,
    UpdateDestiantnionBudgetMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateDestiantnionBudgetMutation,
    UpdateDestiantnionBudgetMutationVariables
  >(UpdateDestiantnionBudgetDocument, baseOptions)
}
export type UpdateDestiantnionBudgetMutationHookResult = ReturnType<
  typeof useUpdateDestiantnionBudgetMutation
>
export type UpdateDestiantnionBudgetMutationResult = ApolloReactCommon.MutationResult<
  UpdateDestiantnionBudgetMutation
>
export type UpdateDestiantnionBudgetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateDestiantnionBudgetMutation,
  UpdateDestiantnionBudgetMutationVariables
>
export const DeleteDestinationBudgetDocument = gql`
  mutation deleteDestinationBudget(
    $destinationId: Int!
    $id: Int!
    $userContext: UserContextInput
  ) {
    deleteDestinationBudget(destinationId: $destinationId, id: $id, userContext: $userContext) {
      id
    }
  }
`
export type DeleteDestinationBudgetMutationFn = ApolloReactCommon.MutationFunction<
  DeleteDestinationBudgetMutation,
  DeleteDestinationBudgetMutationVariables
>
export type DeleteDestinationBudgetComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteDestinationBudgetMutation,
    DeleteDestinationBudgetMutationVariables
  >,
  'mutation'
>

export const DeleteDestinationBudgetComponent = (props: DeleteDestinationBudgetComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteDestinationBudgetMutation,
    DeleteDestinationBudgetMutationVariables
  >
    mutation={DeleteDestinationBudgetDocument}
    {...props}
  />
)

export type DeleteDestinationBudgetProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteDestinationBudgetMutation,
  DeleteDestinationBudgetMutationVariables
> &
  TChildProps
export function withDeleteDestinationBudget<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteDestinationBudgetMutation,
    DeleteDestinationBudgetMutationVariables,
    DeleteDestinationBudgetProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteDestinationBudgetMutation,
    DeleteDestinationBudgetMutationVariables,
    DeleteDestinationBudgetProps<TChildProps>
  >(DeleteDestinationBudgetDocument, {
    alias: 'deleteDestinationBudget',
    ...operationOptions
  })
}

/**
 * __useDeleteDestinationBudgetMutation__
 *
 * To run a mutation, you first call `useDeleteDestinationBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDestinationBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDestinationBudgetMutation, { data, loading, error }] = useDeleteDestinationBudgetMutation({
 *   variables: {
 *      destinationId: // value for 'destinationId'
 *      id: // value for 'id'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useDeleteDestinationBudgetMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteDestinationBudgetMutation,
    DeleteDestinationBudgetMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteDestinationBudgetMutation,
    DeleteDestinationBudgetMutationVariables
  >(DeleteDestinationBudgetDocument, baseOptions)
}
export type DeleteDestinationBudgetMutationHookResult = ReturnType<
  typeof useDeleteDestinationBudgetMutation
>
export type DeleteDestinationBudgetMutationResult = ApolloReactCommon.MutationResult<
  DeleteDestinationBudgetMutation
>
export type DeleteDestinationBudgetMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteDestinationBudgetMutation,
  DeleteDestinationBudgetMutationVariables
>
export const CreateDestinationRouteDocument = gql`
  mutation createDestinationRoute(
    $destinationId: Int!
    $input: DestinationRouteInput
    $userContext: UserContextInput
  ) {
    createDestinationRoute(
      destinationId: $destinationId
      input: $input
      userContext: $userContext
    ) {
      ...routing
    }
  }
  ${RoutingFragmentDoc}
`
export type CreateDestinationRouteMutationFn = ApolloReactCommon.MutationFunction<
  CreateDestinationRouteMutation,
  CreateDestinationRouteMutationVariables
>
export type CreateDestinationRouteComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateDestinationRouteMutation,
    CreateDestinationRouteMutationVariables
  >,
  'mutation'
>

export const CreateDestinationRouteComponent = (props: CreateDestinationRouteComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateDestinationRouteMutation,
    CreateDestinationRouteMutationVariables
  >
    mutation={CreateDestinationRouteDocument}
    {...props}
  />
)

export type CreateDestinationRouteProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateDestinationRouteMutation,
  CreateDestinationRouteMutationVariables
> &
  TChildProps
export function withCreateDestinationRoute<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateDestinationRouteMutation,
    CreateDestinationRouteMutationVariables,
    CreateDestinationRouteProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateDestinationRouteMutation,
    CreateDestinationRouteMutationVariables,
    CreateDestinationRouteProps<TChildProps>
  >(CreateDestinationRouteDocument, {
    alias: 'createDestinationRoute',
    ...operationOptions
  })
}

/**
 * __useCreateDestinationRouteMutation__
 *
 * To run a mutation, you first call `useCreateDestinationRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDestinationRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDestinationRouteMutation, { data, loading, error }] = useCreateDestinationRouteMutation({
 *   variables: {
 *      destinationId: // value for 'destinationId'
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useCreateDestinationRouteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateDestinationRouteMutation,
    CreateDestinationRouteMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateDestinationRouteMutation,
    CreateDestinationRouteMutationVariables
  >(CreateDestinationRouteDocument, baseOptions)
}
export type CreateDestinationRouteMutationHookResult = ReturnType<
  typeof useCreateDestinationRouteMutation
>
export type CreateDestinationRouteMutationResult = ApolloReactCommon.MutationResult<
  CreateDestinationRouteMutation
>
export type CreateDestinationRouteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateDestinationRouteMutation,
  CreateDestinationRouteMutationVariables
>
export const UpdateDestinationRouteDocument = gql`
  mutation updateDestinationRoute(
    $id: Int!
    $destinationId: Int!
    $input: DestinationRouteUpdateInput
    $userContext: UserContextInput
  ) {
    updateDestinationRoute(
      id: $id
      destinationId: $destinationId
      update: $input
      userContext: $userContext
    ) {
      ...routing
    }
  }
  ${RoutingFragmentDoc}
`
export type UpdateDestinationRouteMutationFn = ApolloReactCommon.MutationFunction<
  UpdateDestinationRouteMutation,
  UpdateDestinationRouteMutationVariables
>
export type UpdateDestinationRouteComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateDestinationRouteMutation,
    UpdateDestinationRouteMutationVariables
  >,
  'mutation'
>

export const UpdateDestinationRouteComponent = (props: UpdateDestinationRouteComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateDestinationRouteMutation,
    UpdateDestinationRouteMutationVariables
  >
    mutation={UpdateDestinationRouteDocument}
    {...props}
  />
)

export type UpdateDestinationRouteProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateDestinationRouteMutation,
  UpdateDestinationRouteMutationVariables
> &
  TChildProps
export function withUpdateDestinationRoute<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateDestinationRouteMutation,
    UpdateDestinationRouteMutationVariables,
    UpdateDestinationRouteProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateDestinationRouteMutation,
    UpdateDestinationRouteMutationVariables,
    UpdateDestinationRouteProps<TChildProps>
  >(UpdateDestinationRouteDocument, {
    alias: 'updateDestinationRoute',
    ...operationOptions
  })
}

/**
 * __useUpdateDestinationRouteMutation__
 *
 * To run a mutation, you first call `useUpdateDestinationRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDestinationRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDestinationRouteMutation, { data, loading, error }] = useUpdateDestinationRouteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      destinationId: // value for 'destinationId'
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useUpdateDestinationRouteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateDestinationRouteMutation,
    UpdateDestinationRouteMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateDestinationRouteMutation,
    UpdateDestinationRouteMutationVariables
  >(UpdateDestinationRouteDocument, baseOptions)
}
export type UpdateDestinationRouteMutationHookResult = ReturnType<
  typeof useUpdateDestinationRouteMutation
>
export type UpdateDestinationRouteMutationResult = ApolloReactCommon.MutationResult<
  UpdateDestinationRouteMutation
>
export type UpdateDestinationRouteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateDestinationRouteMutation,
  UpdateDestinationRouteMutationVariables
>
export const DeleteDestinationRouteDocument = gql`
  mutation deleteDestinationRoute($id: Int!, $destinationId: Int!, $userContext: UserContextInput) {
    deleteDestinationRoute(id: $id, destinationId: $destinationId, userContext: $userContext) {
      id
    }
  }
`
export type DeleteDestinationRouteMutationFn = ApolloReactCommon.MutationFunction<
  DeleteDestinationRouteMutation,
  DeleteDestinationRouteMutationVariables
>
export type DeleteDestinationRouteComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteDestinationRouteMutation,
    DeleteDestinationRouteMutationVariables
  >,
  'mutation'
>

export const DeleteDestinationRouteComponent = (props: DeleteDestinationRouteComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteDestinationRouteMutation,
    DeleteDestinationRouteMutationVariables
  >
    mutation={DeleteDestinationRouteDocument}
    {...props}
  />
)

export type DeleteDestinationRouteProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteDestinationRouteMutation,
  DeleteDestinationRouteMutationVariables
> &
  TChildProps
export function withDeleteDestinationRoute<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteDestinationRouteMutation,
    DeleteDestinationRouteMutationVariables,
    DeleteDestinationRouteProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteDestinationRouteMutation,
    DeleteDestinationRouteMutationVariables,
    DeleteDestinationRouteProps<TChildProps>
  >(DeleteDestinationRouteDocument, {
    alias: 'deleteDestinationRoute',
    ...operationOptions
  })
}

/**
 * __useDeleteDestinationRouteMutation__
 *
 * To run a mutation, you first call `useDeleteDestinationRouteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDestinationRouteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDestinationRouteMutation, { data, loading, error }] = useDeleteDestinationRouteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      destinationId: // value for 'destinationId'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useDeleteDestinationRouteMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteDestinationRouteMutation,
    DeleteDestinationRouteMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteDestinationRouteMutation,
    DeleteDestinationRouteMutationVariables
  >(DeleteDestinationRouteDocument, baseOptions)
}
export type DeleteDestinationRouteMutationHookResult = ReturnType<
  typeof useDeleteDestinationRouteMutation
>
export type DeleteDestinationRouteMutationResult = ApolloReactCommon.MutationResult<
  DeleteDestinationRouteMutation
>
export type DeleteDestinationRouteMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteDestinationRouteMutation,
  DeleteDestinationRouteMutationVariables
>
export const CreateHandlingTypeDocument = gql`
  mutation createHandlingType($input: HandlingTypeInput, $userContext: UserContextInput) {
    createHandlingType(input: $input, userContext: $userContext) {
      ...handlingType
      storageTypes {
        storageType {
          ...storageType
        }
      }
    }
  }
  ${HandlingTypeFragmentDoc}
  ${StorageTypeFragmentDoc}
`
export type CreateHandlingTypeMutationFn = ApolloReactCommon.MutationFunction<
  CreateHandlingTypeMutation,
  CreateHandlingTypeMutationVariables
>
export type CreateHandlingTypeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateHandlingTypeMutation,
    CreateHandlingTypeMutationVariables
  >,
  'mutation'
>

export const CreateHandlingTypeComponent = (props: CreateHandlingTypeComponentProps) => (
  <ApolloReactComponents.Mutation<CreateHandlingTypeMutation, CreateHandlingTypeMutationVariables>
    mutation={CreateHandlingTypeDocument}
    {...props}
  />
)

export type CreateHandlingTypeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateHandlingTypeMutation,
  CreateHandlingTypeMutationVariables
> &
  TChildProps
export function withCreateHandlingType<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateHandlingTypeMutation,
    CreateHandlingTypeMutationVariables,
    CreateHandlingTypeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateHandlingTypeMutation,
    CreateHandlingTypeMutationVariables,
    CreateHandlingTypeProps<TChildProps>
  >(CreateHandlingTypeDocument, {
    alias: 'createHandlingType',
    ...operationOptions
  })
}

/**
 * __useCreateHandlingTypeMutation__
 *
 * To run a mutation, you first call `useCreateHandlingTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHandlingTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHandlingTypeMutation, { data, loading, error }] = useCreateHandlingTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useCreateHandlingTypeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateHandlingTypeMutation,
    CreateHandlingTypeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateHandlingTypeMutation,
    CreateHandlingTypeMutationVariables
  >(CreateHandlingTypeDocument, baseOptions)
}
export type CreateHandlingTypeMutationHookResult = ReturnType<typeof useCreateHandlingTypeMutation>
export type CreateHandlingTypeMutationResult = ApolloReactCommon.MutationResult<
  CreateHandlingTypeMutation
>
export type CreateHandlingTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateHandlingTypeMutation,
  CreateHandlingTypeMutationVariables
>
export const DeleteHandlingTypeDocument = gql`
  mutation deleteHandlingType($id: Int!, $userContext: UserContextInput) {
    deleteHandlingType(id: $id, userContext: $userContext) {
      ...handlingType
    }
  }
  ${HandlingTypeFragmentDoc}
`
export type DeleteHandlingTypeMutationFn = ApolloReactCommon.MutationFunction<
  DeleteHandlingTypeMutation,
  DeleteHandlingTypeMutationVariables
>
export type DeleteHandlingTypeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteHandlingTypeMutation,
    DeleteHandlingTypeMutationVariables
  >,
  'mutation'
>

export const DeleteHandlingTypeComponent = (props: DeleteHandlingTypeComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteHandlingTypeMutation, DeleteHandlingTypeMutationVariables>
    mutation={DeleteHandlingTypeDocument}
    {...props}
  />
)

export type DeleteHandlingTypeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteHandlingTypeMutation,
  DeleteHandlingTypeMutationVariables
> &
  TChildProps
export function withDeleteHandlingType<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteHandlingTypeMutation,
    DeleteHandlingTypeMutationVariables,
    DeleteHandlingTypeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteHandlingTypeMutation,
    DeleteHandlingTypeMutationVariables,
    DeleteHandlingTypeProps<TChildProps>
  >(DeleteHandlingTypeDocument, {
    alias: 'deleteHandlingType',
    ...operationOptions
  })
}

/**
 * __useDeleteHandlingTypeMutation__
 *
 * To run a mutation, you first call `useDeleteHandlingTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHandlingTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHandlingTypeMutation, { data, loading, error }] = useDeleteHandlingTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useDeleteHandlingTypeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteHandlingTypeMutation,
    DeleteHandlingTypeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteHandlingTypeMutation,
    DeleteHandlingTypeMutationVariables
  >(DeleteHandlingTypeDocument, baseOptions)
}
export type DeleteHandlingTypeMutationHookResult = ReturnType<typeof useDeleteHandlingTypeMutation>
export type DeleteHandlingTypeMutationResult = ApolloReactCommon.MutationResult<
  DeleteHandlingTypeMutation
>
export type DeleteHandlingTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteHandlingTypeMutation,
  DeleteHandlingTypeMutationVariables
>
export const UpdateHandlingTypeDocument = gql`
  mutation updateHandlingType(
    $id: Int!
    $input: HandlingTypeUpdateInput
    $userContext: UserContextInput
  ) {
    updateHandlingType(id: $id, input: $input, userContext: $userContext) {
      ...handlingType
      storageTypes {
        storageType {
          ...storageType
        }
      }
    }
  }
  ${HandlingTypeFragmentDoc}
  ${StorageTypeFragmentDoc}
`
export type UpdateHandlingTypeMutationFn = ApolloReactCommon.MutationFunction<
  UpdateHandlingTypeMutation,
  UpdateHandlingTypeMutationVariables
>
export type UpdateHandlingTypeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateHandlingTypeMutation,
    UpdateHandlingTypeMutationVariables
  >,
  'mutation'
>

export const UpdateHandlingTypeComponent = (props: UpdateHandlingTypeComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateHandlingTypeMutation, UpdateHandlingTypeMutationVariables>
    mutation={UpdateHandlingTypeDocument}
    {...props}
  />
)

export type UpdateHandlingTypeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateHandlingTypeMutation,
  UpdateHandlingTypeMutationVariables
> &
  TChildProps
export function withUpdateHandlingType<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateHandlingTypeMutation,
    UpdateHandlingTypeMutationVariables,
    UpdateHandlingTypeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateHandlingTypeMutation,
    UpdateHandlingTypeMutationVariables,
    UpdateHandlingTypeProps<TChildProps>
  >(UpdateHandlingTypeDocument, {
    alias: 'updateHandlingType',
    ...operationOptions
  })
}

/**
 * __useUpdateHandlingTypeMutation__
 *
 * To run a mutation, you first call `useUpdateHandlingTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHandlingTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHandlingTypeMutation, { data, loading, error }] = useUpdateHandlingTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useUpdateHandlingTypeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateHandlingTypeMutation,
    UpdateHandlingTypeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateHandlingTypeMutation,
    UpdateHandlingTypeMutationVariables
  >(UpdateHandlingTypeDocument, baseOptions)
}
export type UpdateHandlingTypeMutationHookResult = ReturnType<typeof useUpdateHandlingTypeMutation>
export type UpdateHandlingTypeMutationResult = ApolloReactCommon.MutationResult<
  UpdateHandlingTypeMutation
>
export type UpdateHandlingTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateHandlingTypeMutation,
  UpdateHandlingTypeMutationVariables
>
export const AddHandlingStorageTypeDocument = gql`
  mutation addHandlingStorageType($id: Int!, $storageTypeId: Int!, $userContext: UserContextInput) {
    addHandlingStorageType(id: $id, storageTypeId: $storageTypeId, userContext: $userContext) {
      handlingTypeId
      storageTypeId
    }
  }
`
export type AddHandlingStorageTypeMutationFn = ApolloReactCommon.MutationFunction<
  AddHandlingStorageTypeMutation,
  AddHandlingStorageTypeMutationVariables
>
export type AddHandlingStorageTypeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddHandlingStorageTypeMutation,
    AddHandlingStorageTypeMutationVariables
  >,
  'mutation'
>

export const AddHandlingStorageTypeComponent = (props: AddHandlingStorageTypeComponentProps) => (
  <ApolloReactComponents.Mutation<
    AddHandlingStorageTypeMutation,
    AddHandlingStorageTypeMutationVariables
  >
    mutation={AddHandlingStorageTypeDocument}
    {...props}
  />
)

export type AddHandlingStorageTypeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  AddHandlingStorageTypeMutation,
  AddHandlingStorageTypeMutationVariables
> &
  TChildProps
export function withAddHandlingStorageType<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddHandlingStorageTypeMutation,
    AddHandlingStorageTypeMutationVariables,
    AddHandlingStorageTypeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AddHandlingStorageTypeMutation,
    AddHandlingStorageTypeMutationVariables,
    AddHandlingStorageTypeProps<TChildProps>
  >(AddHandlingStorageTypeDocument, {
    alias: 'addHandlingStorageType',
    ...operationOptions
  })
}

/**
 * __useAddHandlingStorageTypeMutation__
 *
 * To run a mutation, you first call `useAddHandlingStorageTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHandlingStorageTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHandlingStorageTypeMutation, { data, loading, error }] = useAddHandlingStorageTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      storageTypeId: // value for 'storageTypeId'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useAddHandlingStorageTypeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddHandlingStorageTypeMutation,
    AddHandlingStorageTypeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddHandlingStorageTypeMutation,
    AddHandlingStorageTypeMutationVariables
  >(AddHandlingStorageTypeDocument, baseOptions)
}
export type AddHandlingStorageTypeMutationHookResult = ReturnType<
  typeof useAddHandlingStorageTypeMutation
>
export type AddHandlingStorageTypeMutationResult = ApolloReactCommon.MutationResult<
  AddHandlingStorageTypeMutation
>
export type AddHandlingStorageTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddHandlingStorageTypeMutation,
  AddHandlingStorageTypeMutationVariables
>
export const RemoveHandlingStorageTypeDocument = gql`
  mutation removeHandlingStorageType(
    $id: Int!
    $storageTypeId: Int!
    $userContext: UserContextInput
  ) {
    removeHandlingStorageType(id: $id, storageTypeId: $storageTypeId, userContext: $userContext) {
      handlingTypeId
      storageTypeId
    }
  }
`
export type RemoveHandlingStorageTypeMutationFn = ApolloReactCommon.MutationFunction<
  RemoveHandlingStorageTypeMutation,
  RemoveHandlingStorageTypeMutationVariables
>
export type RemoveHandlingStorageTypeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RemoveHandlingStorageTypeMutation,
    RemoveHandlingStorageTypeMutationVariables
  >,
  'mutation'
>

export const RemoveHandlingStorageTypeComponent = (
  props: RemoveHandlingStorageTypeComponentProps
) => (
  <ApolloReactComponents.Mutation<
    RemoveHandlingStorageTypeMutation,
    RemoveHandlingStorageTypeMutationVariables
  >
    mutation={RemoveHandlingStorageTypeDocument}
    {...props}
  />
)

export type RemoveHandlingStorageTypeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  RemoveHandlingStorageTypeMutation,
  RemoveHandlingStorageTypeMutationVariables
> &
  TChildProps
export function withRemoveHandlingStorageType<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RemoveHandlingStorageTypeMutation,
    RemoveHandlingStorageTypeMutationVariables,
    RemoveHandlingStorageTypeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RemoveHandlingStorageTypeMutation,
    RemoveHandlingStorageTypeMutationVariables,
    RemoveHandlingStorageTypeProps<TChildProps>
  >(RemoveHandlingStorageTypeDocument, {
    alias: 'removeHandlingStorageType',
    ...operationOptions
  })
}

/**
 * __useRemoveHandlingStorageTypeMutation__
 *
 * To run a mutation, you first call `useRemoveHandlingStorageTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHandlingStorageTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeHandlingStorageTypeMutation, { data, loading, error }] = useRemoveHandlingStorageTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      storageTypeId: // value for 'storageTypeId'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useRemoveHandlingStorageTypeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveHandlingStorageTypeMutation,
    RemoveHandlingStorageTypeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RemoveHandlingStorageTypeMutation,
    RemoveHandlingStorageTypeMutationVariables
  >(RemoveHandlingStorageTypeDocument, baseOptions)
}
export type RemoveHandlingStorageTypeMutationHookResult = ReturnType<
  typeof useRemoveHandlingStorageTypeMutation
>
export type RemoveHandlingStorageTypeMutationResult = ApolloReactCommon.MutationResult<
  RemoveHandlingStorageTypeMutation
>
export type RemoveHandlingStorageTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveHandlingStorageTypeMutation,
  RemoveHandlingStorageTypeMutationVariables
>
export const AddStockOwnerLinkDocument = gql`
  mutation addStockOwnerLink($linkedStockOwnerId: Int!, $stockOwnerId: Int!) {
    addStockOwnerLink(linkedStockOwnerId: $linkedStockOwnerId, stockOwnerId: $stockOwnerId) {
      child {
        code
      }
    }
  }
`
export type AddStockOwnerLinkMutationFn = ApolloReactCommon.MutationFunction<
  AddStockOwnerLinkMutation,
  AddStockOwnerLinkMutationVariables
>
export type AddStockOwnerLinkComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddStockOwnerLinkMutation,
    AddStockOwnerLinkMutationVariables
  >,
  'mutation'
>

export const AddStockOwnerLinkComponent = (props: AddStockOwnerLinkComponentProps) => (
  <ApolloReactComponents.Mutation<AddStockOwnerLinkMutation, AddStockOwnerLinkMutationVariables>
    mutation={AddStockOwnerLinkDocument}
    {...props}
  />
)

export type AddStockOwnerLinkProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  AddStockOwnerLinkMutation,
  AddStockOwnerLinkMutationVariables
> &
  TChildProps
export function withAddStockOwnerLink<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddStockOwnerLinkMutation,
    AddStockOwnerLinkMutationVariables,
    AddStockOwnerLinkProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AddStockOwnerLinkMutation,
    AddStockOwnerLinkMutationVariables,
    AddStockOwnerLinkProps<TChildProps>
  >(AddStockOwnerLinkDocument, {
    alias: 'addStockOwnerLink',
    ...operationOptions
  })
}

/**
 * __useAddStockOwnerLinkMutation__
 *
 * To run a mutation, you first call `useAddStockOwnerLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStockOwnerLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStockOwnerLinkMutation, { data, loading, error }] = useAddStockOwnerLinkMutation({
 *   variables: {
 *      linkedStockOwnerId: // value for 'linkedStockOwnerId'
 *      stockOwnerId: // value for 'stockOwnerId'
 *   },
 * });
 */
export function useAddStockOwnerLinkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddStockOwnerLinkMutation,
    AddStockOwnerLinkMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddStockOwnerLinkMutation,
    AddStockOwnerLinkMutationVariables
  >(AddStockOwnerLinkDocument, baseOptions)
}
export type AddStockOwnerLinkMutationHookResult = ReturnType<typeof useAddStockOwnerLinkMutation>
export type AddStockOwnerLinkMutationResult = ApolloReactCommon.MutationResult<
  AddStockOwnerLinkMutation
>
export type AddStockOwnerLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddStockOwnerLinkMutation,
  AddStockOwnerLinkMutationVariables
>
export const RemoveStockOwnerLinkDocument = gql`
  mutation removeStockOwnerLink($linkedStockOwnerId: Int!, $stockOwnerId: Int!) {
    removeStockOwnerLink(linkedStockOwnerId: $linkedStockOwnerId, stockOwnerId: $stockOwnerId) {
      child {
        code
      }
    }
  }
`
export type RemoveStockOwnerLinkMutationFn = ApolloReactCommon.MutationFunction<
  RemoveStockOwnerLinkMutation,
  RemoveStockOwnerLinkMutationVariables
>
export type RemoveStockOwnerLinkComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RemoveStockOwnerLinkMutation,
    RemoveStockOwnerLinkMutationVariables
  >,
  'mutation'
>

export const RemoveStockOwnerLinkComponent = (props: RemoveStockOwnerLinkComponentProps) => (
  <ApolloReactComponents.Mutation<
    RemoveStockOwnerLinkMutation,
    RemoveStockOwnerLinkMutationVariables
  >
    mutation={RemoveStockOwnerLinkDocument}
    {...props}
  />
)

export type RemoveStockOwnerLinkProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  RemoveStockOwnerLinkMutation,
  RemoveStockOwnerLinkMutationVariables
> &
  TChildProps
export function withRemoveStockOwnerLink<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RemoveStockOwnerLinkMutation,
    RemoveStockOwnerLinkMutationVariables,
    RemoveStockOwnerLinkProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RemoveStockOwnerLinkMutation,
    RemoveStockOwnerLinkMutationVariables,
    RemoveStockOwnerLinkProps<TChildProps>
  >(RemoveStockOwnerLinkDocument, {
    alias: 'removeStockOwnerLink',
    ...operationOptions
  })
}

/**
 * __useRemoveStockOwnerLinkMutation__
 *
 * To run a mutation, you first call `useRemoveStockOwnerLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStockOwnerLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStockOwnerLinkMutation, { data, loading, error }] = useRemoveStockOwnerLinkMutation({
 *   variables: {
 *      linkedStockOwnerId: // value for 'linkedStockOwnerId'
 *      stockOwnerId: // value for 'stockOwnerId'
 *   },
 * });
 */
export function useRemoveStockOwnerLinkMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveStockOwnerLinkMutation,
    RemoveStockOwnerLinkMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RemoveStockOwnerLinkMutation,
    RemoveStockOwnerLinkMutationVariables
  >(RemoveStockOwnerLinkDocument, baseOptions)
}
export type RemoveStockOwnerLinkMutationHookResult = ReturnType<
  typeof useRemoveStockOwnerLinkMutation
>
export type RemoveStockOwnerLinkMutationResult = ApolloReactCommon.MutationResult<
  RemoveStockOwnerLinkMutation
>
export type RemoveStockOwnerLinkMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveStockOwnerLinkMutation,
  RemoveStockOwnerLinkMutationVariables
>
export const CreateLocationDocument = gql`
  mutation createLocation($input: LocationInput, $userContext: UserContextInput) {
    createLocation(input: $input, userContext: $userContext) {
      ...location
    }
  }
  ${LocationFragmentDoc}
`
export type CreateLocationMutationFn = ApolloReactCommon.MutationFunction<
  CreateLocationMutation,
  CreateLocationMutationVariables
>
export type CreateLocationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateLocationMutation,
    CreateLocationMutationVariables
  >,
  'mutation'
>

export const CreateLocationComponent = (props: CreateLocationComponentProps) => (
  <ApolloReactComponents.Mutation<CreateLocationMutation, CreateLocationMutationVariables>
    mutation={CreateLocationDocument}
    {...props}
  />
)

export type CreateLocationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateLocationMutation,
  CreateLocationMutationVariables
> &
  TChildProps
export function withCreateLocation<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateLocationMutation,
    CreateLocationMutationVariables,
    CreateLocationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateLocationMutation,
    CreateLocationMutationVariables,
    CreateLocationProps<TChildProps>
  >(CreateLocationDocument, {
    alias: 'createLocation',
    ...operationOptions
  })
}

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useCreateLocationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateLocationMutation,
    CreateLocationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(
    CreateLocationDocument,
    baseOptions
  )
}
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>
export type CreateLocationMutationResult = ApolloReactCommon.MutationResult<CreateLocationMutation>
export type CreateLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateLocationMutation,
  CreateLocationMutationVariables
>
export const UpdateLocationDocument = gql`
  mutation updateLocation($id: Int!, $input: LocationUpdateInput, $userContext: UserContextInput) {
    updateLocation(id: $id, input: $input, userContext: $userContext) {
      ...location
    }
  }
  ${LocationFragmentDoc}
`
export type UpdateLocationMutationFn = ApolloReactCommon.MutationFunction<
  UpdateLocationMutation,
  UpdateLocationMutationVariables
>
export type UpdateLocationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateLocationMutation,
    UpdateLocationMutationVariables
  >,
  'mutation'
>

export const UpdateLocationComponent = (props: UpdateLocationComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateLocationMutation, UpdateLocationMutationVariables>
    mutation={UpdateLocationDocument}
    {...props}
  />
)

export type UpdateLocationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateLocationMutation,
  UpdateLocationMutationVariables
> &
  TChildProps
export function withUpdateLocation<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateLocationMutation,
    UpdateLocationMutationVariables,
    UpdateLocationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateLocationMutation,
    UpdateLocationMutationVariables,
    UpdateLocationProps<TChildProps>
  >(UpdateLocationDocument, {
    alias: 'updateLocation',
    ...operationOptions
  })
}

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useUpdateLocationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateLocationMutation,
    UpdateLocationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(
    UpdateLocationDocument,
    baseOptions
  )
}
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>
export type UpdateLocationMutationResult = ApolloReactCommon.MutationResult<UpdateLocationMutation>
export type UpdateLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateLocationMutation,
  UpdateLocationMutationVariables
>
export const DeleteLocationDocument = gql`
  mutation deleteLocation($id: Int!, $userContext: UserContextInput) {
    deleteLocation(id: $id, userContext: $userContext) {
      ...location
    }
  }
  ${LocationFragmentDoc}
`
export type DeleteLocationMutationFn = ApolloReactCommon.MutationFunction<
  DeleteLocationMutation,
  DeleteLocationMutationVariables
>
export type DeleteLocationComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteLocationMutation,
    DeleteLocationMutationVariables
  >,
  'mutation'
>

export const DeleteLocationComponent = (props: DeleteLocationComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteLocationMutation, DeleteLocationMutationVariables>
    mutation={DeleteLocationDocument}
    {...props}
  />
)

export type DeleteLocationProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteLocationMutation,
  DeleteLocationMutationVariables
> &
  TChildProps
export function withDeleteLocation<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteLocationMutation,
    DeleteLocationMutationVariables,
    DeleteLocationProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteLocationMutation,
    DeleteLocationMutationVariables,
    DeleteLocationProps<TChildProps>
  >(DeleteLocationDocument, {
    alias: 'deleteLocation',
    ...operationOptions
  })
}

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationMutation, { data, loading, error }] = useDeleteLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useDeleteLocationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteLocationMutation,
    DeleteLocationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteLocationMutation, DeleteLocationMutationVariables>(
    DeleteLocationDocument,
    baseOptions
  )
}
export type DeleteLocationMutationHookResult = ReturnType<typeof useDeleteLocationMutation>
export type DeleteLocationMutationResult = ApolloReactCommon.MutationResult<DeleteLocationMutation>
export type DeleteLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteLocationMutation,
  DeleteLocationMutationVariables
>
export const CreateMheDocument = gql`
  mutation createMHE($input: MaterialHandlingEquipmentInput, $userContext: UserContextInput) {
    createMaterialHandlingEquipment(input: $input, userContext: $userContext) {
      ...mhe
    }
  }
  ${MheFragmentDoc}
`
export type CreateMheMutationFn = ApolloReactCommon.MutationFunction<
  CreateMheMutation,
  CreateMheMutationVariables
>
export type CreateMheComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<CreateMheMutation, CreateMheMutationVariables>,
  'mutation'
>

export const CreateMheComponent = (props: CreateMheComponentProps) => (
  <ApolloReactComponents.Mutation<CreateMheMutation, CreateMheMutationVariables>
    mutation={CreateMheDocument}
    {...props}
  />
)

export type CreateMheProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateMheMutation,
  CreateMheMutationVariables
> &
  TChildProps
export function withCreateMhe<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateMheMutation,
    CreateMheMutationVariables,
    CreateMheProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateMheMutation,
    CreateMheMutationVariables,
    CreateMheProps<TChildProps>
  >(CreateMheDocument, {
    alias: 'createMhe',
    ...operationOptions
  })
}

/**
 * __useCreateMheMutation__
 *
 * To run a mutation, you first call `useCreateMheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMheMutation, { data, loading, error }] = useCreateMheMutation({
 *   variables: {
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useCreateMheMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMheMutation, CreateMheMutationVariables>
) {
  return ApolloReactHooks.useMutation<CreateMheMutation, CreateMheMutationVariables>(
    CreateMheDocument,
    baseOptions
  )
}
export type CreateMheMutationHookResult = ReturnType<typeof useCreateMheMutation>
export type CreateMheMutationResult = ApolloReactCommon.MutationResult<CreateMheMutation>
export type CreateMheMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateMheMutation,
  CreateMheMutationVariables
>
export const UpdateMheDocument = gql`
  mutation updateMHE(
    $id: Int!
    $input: MaterialHandlingEquipmentUpdateInput
    $userContext: UserContextInput
  ) {
    updateMaterialHandlingEquipment(id: $id, update: $input, userContext: $userContext) {
      ...mhe
    }
  }
  ${MheFragmentDoc}
`
export type UpdateMheMutationFn = ApolloReactCommon.MutationFunction<
  UpdateMheMutation,
  UpdateMheMutationVariables
>
export type UpdateMheComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateMheMutation, UpdateMheMutationVariables>,
  'mutation'
>

export const UpdateMheComponent = (props: UpdateMheComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateMheMutation, UpdateMheMutationVariables>
    mutation={UpdateMheDocument}
    {...props}
  />
)

export type UpdateMheProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateMheMutation,
  UpdateMheMutationVariables
> &
  TChildProps
export function withUpdateMhe<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateMheMutation,
    UpdateMheMutationVariables,
    UpdateMheProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateMheMutation,
    UpdateMheMutationVariables,
    UpdateMheProps<TChildProps>
  >(UpdateMheDocument, {
    alias: 'updateMhe',
    ...operationOptions
  })
}

/**
 * __useUpdateMheMutation__
 *
 * To run a mutation, you first call `useUpdateMheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMheMutation, { data, loading, error }] = useUpdateMheMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useUpdateMheMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMheMutation, UpdateMheMutationVariables>
) {
  return ApolloReactHooks.useMutation<UpdateMheMutation, UpdateMheMutationVariables>(
    UpdateMheDocument,
    baseOptions
  )
}
export type UpdateMheMutationHookResult = ReturnType<typeof useUpdateMheMutation>
export type UpdateMheMutationResult = ApolloReactCommon.MutationResult<UpdateMheMutation>
export type UpdateMheMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateMheMutation,
  UpdateMheMutationVariables
>
export const DeleteMheDocument = gql`
  mutation deleteMHE($id: Int!, $userContext: UserContextInput) {
    deleteMaterialHandlingEquipment(id: $id, userContext: $userContext) {
      id
    }
  }
`
export type DeleteMheMutationFn = ApolloReactCommon.MutationFunction<
  DeleteMheMutation,
  DeleteMheMutationVariables
>
export type DeleteMheComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteMheMutation, DeleteMheMutationVariables>,
  'mutation'
>

export const DeleteMheComponent = (props: DeleteMheComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteMheMutation, DeleteMheMutationVariables>
    mutation={DeleteMheDocument}
    {...props}
  />
)

export type DeleteMheProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteMheMutation,
  DeleteMheMutationVariables
> &
  TChildProps
export function withDeleteMhe<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteMheMutation,
    DeleteMheMutationVariables,
    DeleteMheProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteMheMutation,
    DeleteMheMutationVariables,
    DeleteMheProps<TChildProps>
  >(DeleteMheDocument, {
    alias: 'deleteMhe',
    ...operationOptions
  })
}

/**
 * __useDeleteMheMutation__
 *
 * To run a mutation, you first call `useDeleteMheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMheMutation, { data, loading, error }] = useDeleteMheMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useDeleteMheMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMheMutation, DeleteMheMutationVariables>
) {
  return ApolloReactHooks.useMutation<DeleteMheMutation, DeleteMheMutationVariables>(
    DeleteMheDocument,
    baseOptions
  )
}
export type DeleteMheMutationHookResult = ReturnType<typeof useDeleteMheMutation>
export type DeleteMheMutationResult = ApolloReactCommon.MutationResult<DeleteMheMutation>
export type DeleteMheMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteMheMutation,
  DeleteMheMutationVariables
>
export const CreateOrderEntryDocument = gql`
  mutation createOrderEntry($input: CreateOrderEntryInput!) {
    createOrderEntry(input: $input) @client {
      ...orderEntry
      destinationCode {
        ...destinationCode
      }
      budgetHolder {
        ...budgetHolder
      }
      orderType {
        ...orderType
      }
    }
  }
  ${OrderEntryFragmentDoc}
  ${DestinationCodeFragmentDoc}
  ${BudgetHolderFragmentDoc}
  ${OrderTypeFragmentDoc}
`
export type CreateOrderEntryMutationFn = ApolloReactCommon.MutationFunction<
  CreateOrderEntryMutation,
  CreateOrderEntryMutationVariables
>
export type CreateOrderEntryComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateOrderEntryMutation,
    CreateOrderEntryMutationVariables
  >,
  'mutation'
>

export const CreateOrderEntryComponent = (props: CreateOrderEntryComponentProps) => (
  <ApolloReactComponents.Mutation<CreateOrderEntryMutation, CreateOrderEntryMutationVariables>
    mutation={CreateOrderEntryDocument}
    {...props}
  />
)

export type CreateOrderEntryProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateOrderEntryMutation,
  CreateOrderEntryMutationVariables
> &
  TChildProps
export function withCreateOrderEntry<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateOrderEntryMutation,
    CreateOrderEntryMutationVariables,
    CreateOrderEntryProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateOrderEntryMutation,
    CreateOrderEntryMutationVariables,
    CreateOrderEntryProps<TChildProps>
  >(CreateOrderEntryDocument, {
    alias: 'createOrderEntry',
    ...operationOptions
  })
}

/**
 * __useCreateOrderEntryMutation__
 *
 * To run a mutation, you first call `useCreateOrderEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderEntryMutation, { data, loading, error }] = useCreateOrderEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderEntryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOrderEntryMutation,
    CreateOrderEntryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateOrderEntryMutation, CreateOrderEntryMutationVariables>(
    CreateOrderEntryDocument,
    baseOptions
  )
}
export type CreateOrderEntryMutationHookResult = ReturnType<typeof useCreateOrderEntryMutation>
export type CreateOrderEntryMutationResult = ApolloReactCommon.MutationResult<
  CreateOrderEntryMutation
>
export type CreateOrderEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateOrderEntryMutation,
  CreateOrderEntryMutationVariables
>
export const DeleteOrderEntryDocument = gql`
  mutation deleteOrderEntry($id: ID!) {
    deleteOrderEntry(id: $id) @client {
      ...orderEntry
    }
  }
  ${OrderEntryFragmentDoc}
`
export type DeleteOrderEntryMutationFn = ApolloReactCommon.MutationFunction<
  DeleteOrderEntryMutation,
  DeleteOrderEntryMutationVariables
>
export type DeleteOrderEntryComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteOrderEntryMutation,
    DeleteOrderEntryMutationVariables
  >,
  'mutation'
>

export const DeleteOrderEntryComponent = (props: DeleteOrderEntryComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteOrderEntryMutation, DeleteOrderEntryMutationVariables>
    mutation={DeleteOrderEntryDocument}
    {...props}
  />
)

export type DeleteOrderEntryProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteOrderEntryMutation,
  DeleteOrderEntryMutationVariables
> &
  TChildProps
export function withDeleteOrderEntry<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteOrderEntryMutation,
    DeleteOrderEntryMutationVariables,
    DeleteOrderEntryProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteOrderEntryMutation,
    DeleteOrderEntryMutationVariables,
    DeleteOrderEntryProps<TChildProps>
  >(DeleteOrderEntryDocument, {
    alias: 'deleteOrderEntry',
    ...operationOptions
  })
}

/**
 * __useDeleteOrderEntryMutation__
 *
 * To run a mutation, you first call `useDeleteOrderEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderEntryMutation, { data, loading, error }] = useDeleteOrderEntryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrderEntryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteOrderEntryMutation,
    DeleteOrderEntryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteOrderEntryMutation, DeleteOrderEntryMutationVariables>(
    DeleteOrderEntryDocument,
    baseOptions
  )
}
export type DeleteOrderEntryMutationHookResult = ReturnType<typeof useDeleteOrderEntryMutation>
export type DeleteOrderEntryMutationResult = ApolloReactCommon.MutationResult<
  DeleteOrderEntryMutation
>
export type DeleteOrderEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteOrderEntryMutation,
  DeleteOrderEntryMutationVariables
>
export const UpdateOrderEntryDocument = gql`
  mutation updateOrderEntry($id: ID!, $input: UpdateOrderEntryInput!) {
    updateOrderEntry(id: $id, input: $input) @client {
      ...orderEntry
      destinationCode {
        ...destinationCode
      }
      budgetHolder {
        ...budgetHolder
      }
      orderType {
        ...orderType
      }
    }
  }
  ${OrderEntryFragmentDoc}
  ${DestinationCodeFragmentDoc}
  ${BudgetHolderFragmentDoc}
  ${OrderTypeFragmentDoc}
`
export type UpdateOrderEntryMutationFn = ApolloReactCommon.MutationFunction<
  UpdateOrderEntryMutation,
  UpdateOrderEntryMutationVariables
>
export type UpdateOrderEntryComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateOrderEntryMutation,
    UpdateOrderEntryMutationVariables
  >,
  'mutation'
>

export const UpdateOrderEntryComponent = (props: UpdateOrderEntryComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateOrderEntryMutation, UpdateOrderEntryMutationVariables>
    mutation={UpdateOrderEntryDocument}
    {...props}
  />
)

export type UpdateOrderEntryProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateOrderEntryMutation,
  UpdateOrderEntryMutationVariables
> &
  TChildProps
export function withUpdateOrderEntry<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateOrderEntryMutation,
    UpdateOrderEntryMutationVariables,
    UpdateOrderEntryProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateOrderEntryMutation,
    UpdateOrderEntryMutationVariables,
    UpdateOrderEntryProps<TChildProps>
  >(UpdateOrderEntryDocument, {
    alias: 'updateOrderEntry',
    ...operationOptions
  })
}

/**
 * __useUpdateOrderEntryMutation__
 *
 * To run a mutation, you first call `useUpdateOrderEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderEntryMutation, { data, loading, error }] = useUpdateOrderEntryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderEntryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOrderEntryMutation,
    UpdateOrderEntryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateOrderEntryMutation, UpdateOrderEntryMutationVariables>(
    UpdateOrderEntryDocument,
    baseOptions
  )
}
export type UpdateOrderEntryMutationHookResult = ReturnType<typeof useUpdateOrderEntryMutation>
export type UpdateOrderEntryMutationResult = ApolloReactCommon.MutationResult<
  UpdateOrderEntryMutation
>
export type UpdateOrderEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateOrderEntryMutation,
  UpdateOrderEntryMutationVariables
>
export const CreateProductDocument = gql`
  mutation createProduct($input: ProductInput, $userContext: UserContextInput) {
    createProduct(input: $input, userContext: $userContext) {
      ...product
    }
  }
  ${ProductFragmentDoc}
`
export type CreateProductMutationFn = ApolloReactCommon.MutationFunction<
  CreateProductMutation,
  CreateProductMutationVariables
>
export type CreateProductComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateProductMutation,
    CreateProductMutationVariables
  >,
  'mutation'
>

export const CreateProductComponent = (props: CreateProductComponentProps) => (
  <ApolloReactComponents.Mutation<CreateProductMutation, CreateProductMutationVariables>
    mutation={CreateProductDocument}
    {...props}
  />
)

export type CreateProductProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateProductMutation,
  CreateProductMutationVariables
> &
  TChildProps
export function withCreateProduct<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateProductMutation,
    CreateProductMutationVariables,
    CreateProductProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateProductMutation,
    CreateProductMutationVariables,
    CreateProductProps<TChildProps>
  >(CreateProductDocument, {
    alias: 'createProduct',
    ...operationOptions
  })
}

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useCreateProductMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateProductMutation,
    CreateProductMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateProductMutation, CreateProductMutationVariables>(
    CreateProductDocument,
    baseOptions
  )
}
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>
export type CreateProductMutationResult = ApolloReactCommon.MutationResult<CreateProductMutation>
export type CreateProductMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateProductMutation,
  CreateProductMutationVariables
>
export const UpdateProductDocument = gql`
  mutation updateProduct($id: Int!, $input: ProductUpdateInput, $userContext: UserContextInput) {
    updateProduct(id: $id, update: $input, userContext: $userContext) {
      ...product
    }
  }
  ${ProductFragmentDoc}
`
export type UpdateProductMutationFn = ApolloReactCommon.MutationFunction<
  UpdateProductMutation,
  UpdateProductMutationVariables
>
export type UpdateProductComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateProductMutation,
    UpdateProductMutationVariables
  >,
  'mutation'
>

export const UpdateProductComponent = (props: UpdateProductComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateProductMutation, UpdateProductMutationVariables>
    mutation={UpdateProductDocument}
    {...props}
  />
)

export type UpdateProductProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateProductMutation,
  UpdateProductMutationVariables
> &
  TChildProps
export function withUpdateProduct<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateProductMutation,
    UpdateProductMutationVariables,
    UpdateProductProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateProductMutation,
    UpdateProductMutationVariables,
    UpdateProductProps<TChildProps>
  >(UpdateProductDocument, {
    alias: 'updateProduct',
    ...operationOptions
  })
}

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useUpdateProductMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateProductMutation,
    UpdateProductMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(
    UpdateProductDocument,
    baseOptions
  )
}
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>
export type UpdateProductMutationResult = ApolloReactCommon.MutationResult<UpdateProductMutation>
export type UpdateProductMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateProductMutation,
  UpdateProductMutationVariables
>
export const DeleteProductDocument = gql`
  mutation deleteProduct($id: Int!, $userContext: UserContextInput) {
    deleteProduct(id: $id, userContext: $userContext) {
      ...product
    }
  }
  ${ProductFragmentDoc}
`
export type DeleteProductMutationFn = ApolloReactCommon.MutationFunction<
  DeleteProductMutation,
  DeleteProductMutationVariables
>
export type DeleteProductComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteProductMutation,
    DeleteProductMutationVariables
  >,
  'mutation'
>

export const DeleteProductComponent = (props: DeleteProductComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteProductMutation, DeleteProductMutationVariables>
    mutation={DeleteProductDocument}
    {...props}
  />
)

export type DeleteProductProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteProductMutation,
  DeleteProductMutationVariables
> &
  TChildProps
export function withDeleteProduct<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteProductMutation,
    DeleteProductMutationVariables,
    DeleteProductProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteProductMutation,
    DeleteProductMutationVariables,
    DeleteProductProps<TChildProps>
  >(DeleteProductDocument, {
    alias: 'deleteProduct',
    ...operationOptions
  })
}

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useDeleteProductMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteProductMutation,
    DeleteProductMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(
    DeleteProductDocument,
    baseOptions
  )
}
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>
export type DeleteProductMutationResult = ApolloReactCommon.MutationResult<DeleteProductMutation>
export type DeleteProductMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteProductMutation,
  DeleteProductMutationVariables
>
export const CreateProductBomDocument = gql`
  mutation createProductBOM(
    $component: BOMDefinitionInput
    $productId: Int!
    $userContext: UserContextInput
  ) {
    createBOMDefinition(component: $component, productId: $productId, userContext: $userContext) {
      id
      childId
      qty
    }
  }
`
export type CreateProductBomMutationFn = ApolloReactCommon.MutationFunction<
  CreateProductBomMutation,
  CreateProductBomMutationVariables
>
export type CreateProductBomComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateProductBomMutation,
    CreateProductBomMutationVariables
  >,
  'mutation'
>

export const CreateProductBomComponent = (props: CreateProductBomComponentProps) => (
  <ApolloReactComponents.Mutation<CreateProductBomMutation, CreateProductBomMutationVariables>
    mutation={CreateProductBomDocument}
    {...props}
  />
)

export type CreateProductBomProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateProductBomMutation,
  CreateProductBomMutationVariables
> &
  TChildProps
export function withCreateProductBom<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateProductBomMutation,
    CreateProductBomMutationVariables,
    CreateProductBomProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateProductBomMutation,
    CreateProductBomMutationVariables,
    CreateProductBomProps<TChildProps>
  >(CreateProductBomDocument, {
    alias: 'createProductBom',
    ...operationOptions
  })
}

/**
 * __useCreateProductBomMutation__
 *
 * To run a mutation, you first call `useCreateProductBomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductBomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductBomMutation, { data, loading, error }] = useCreateProductBomMutation({
 *   variables: {
 *      component: // value for 'component'
 *      productId: // value for 'productId'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useCreateProductBomMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateProductBomMutation,
    CreateProductBomMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateProductBomMutation, CreateProductBomMutationVariables>(
    CreateProductBomDocument,
    baseOptions
  )
}
export type CreateProductBomMutationHookResult = ReturnType<typeof useCreateProductBomMutation>
export type CreateProductBomMutationResult = ApolloReactCommon.MutationResult<
  CreateProductBomMutation
>
export type CreateProductBomMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateProductBomMutation,
  CreateProductBomMutationVariables
>
export const UpdateProductBomDocument = gql`
  mutation updateProductBOM(
    $componentId: Int!
    $productId: Int!
    $update: BOMDefinitionUpdateInput
    $userContext: UserContextInput
  ) {
    updateBOMDefinition(
      componentId: $componentId
      productId: $productId
      update: $update
      userContext: $userContext
    ) {
      id
      childId
      qty
    }
  }
`
export type UpdateProductBomMutationFn = ApolloReactCommon.MutationFunction<
  UpdateProductBomMutation,
  UpdateProductBomMutationVariables
>
export type UpdateProductBomComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateProductBomMutation,
    UpdateProductBomMutationVariables
  >,
  'mutation'
>

export const UpdateProductBomComponent = (props: UpdateProductBomComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateProductBomMutation, UpdateProductBomMutationVariables>
    mutation={UpdateProductBomDocument}
    {...props}
  />
)

export type UpdateProductBomProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateProductBomMutation,
  UpdateProductBomMutationVariables
> &
  TChildProps
export function withUpdateProductBom<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateProductBomMutation,
    UpdateProductBomMutationVariables,
    UpdateProductBomProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateProductBomMutation,
    UpdateProductBomMutationVariables,
    UpdateProductBomProps<TChildProps>
  >(UpdateProductBomDocument, {
    alias: 'updateProductBom',
    ...operationOptions
  })
}

/**
 * __useUpdateProductBomMutation__
 *
 * To run a mutation, you first call `useUpdateProductBomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductBomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductBomMutation, { data, loading, error }] = useUpdateProductBomMutation({
 *   variables: {
 *      componentId: // value for 'componentId'
 *      productId: // value for 'productId'
 *      update: // value for 'update'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useUpdateProductBomMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateProductBomMutation,
    UpdateProductBomMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateProductBomMutation, UpdateProductBomMutationVariables>(
    UpdateProductBomDocument,
    baseOptions
  )
}
export type UpdateProductBomMutationHookResult = ReturnType<typeof useUpdateProductBomMutation>
export type UpdateProductBomMutationResult = ApolloReactCommon.MutationResult<
  UpdateProductBomMutation
>
export type UpdateProductBomMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateProductBomMutation,
  UpdateProductBomMutationVariables
>
export const DeleteProductBomDocument = gql`
  mutation deleteProductBOM($componentId: Int!, $productId: Int!, $userContext: UserContextInput) {
    deleteBOMDefinition(
      componentId: $componentId
      productId: $productId
      userContext: $userContext
    ) {
      id
    }
  }
`
export type DeleteProductBomMutationFn = ApolloReactCommon.MutationFunction<
  DeleteProductBomMutation,
  DeleteProductBomMutationVariables
>
export type DeleteProductBomComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteProductBomMutation,
    DeleteProductBomMutationVariables
  >,
  'mutation'
>

export const DeleteProductBomComponent = (props: DeleteProductBomComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteProductBomMutation, DeleteProductBomMutationVariables>
    mutation={DeleteProductBomDocument}
    {...props}
  />
)

export type DeleteProductBomProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteProductBomMutation,
  DeleteProductBomMutationVariables
> &
  TChildProps
export function withDeleteProductBom<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteProductBomMutation,
    DeleteProductBomMutationVariables,
    DeleteProductBomProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteProductBomMutation,
    DeleteProductBomMutationVariables,
    DeleteProductBomProps<TChildProps>
  >(DeleteProductBomDocument, {
    alias: 'deleteProductBom',
    ...operationOptions
  })
}

/**
 * __useDeleteProductBomMutation__
 *
 * To run a mutation, you first call `useDeleteProductBomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductBomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductBomMutation, { data, loading, error }] = useDeleteProductBomMutation({
 *   variables: {
 *      componentId: // value for 'componentId'
 *      productId: // value for 'productId'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useDeleteProductBomMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteProductBomMutation,
    DeleteProductBomMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteProductBomMutation, DeleteProductBomMutationVariables>(
    DeleteProductBomDocument,
    baseOptions
  )
}
export type DeleteProductBomMutationHookResult = ReturnType<typeof useDeleteProductBomMutation>
export type DeleteProductBomMutationResult = ApolloReactCommon.MutationResult<
  DeleteProductBomMutation
>
export type DeleteProductBomMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteProductBomMutation,
  DeleteProductBomMutationVariables
>
export const CreateProductDocumentDocument = gql`
  mutation createProductDocument(
    $productId: Int!
    $productDocument: ProductDocumentInput
    $userContext: UserContextInput
  ) {
    createProductDocument(
      productId: $productId
      productDocument: $productDocument
      userContext: $userContext
    ) {
      ...document
    }
  }
  ${DocumentFragmentDoc}
`
export type CreateProductDocumentMutationFn = ApolloReactCommon.MutationFunction<
  CreateProductDocumentMutation,
  CreateProductDocumentMutationVariables
>
export type CreateProductDocumentComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateProductDocumentMutation,
    CreateProductDocumentMutationVariables
  >,
  'mutation'
>

export const CreateProductDocumentComponent = (props: CreateProductDocumentComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateProductDocumentMutation,
    CreateProductDocumentMutationVariables
  >
    mutation={CreateProductDocumentDocument}
    {...props}
  />
)

export type CreateProductDocumentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateProductDocumentMutation,
  CreateProductDocumentMutationVariables
> &
  TChildProps
export function withCreateProductDocument<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateProductDocumentMutation,
    CreateProductDocumentMutationVariables,
    CreateProductDocumentProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateProductDocumentMutation,
    CreateProductDocumentMutationVariables,
    CreateProductDocumentProps<TChildProps>
  >(CreateProductDocumentDocument, {
    alias: 'createProductDocument',
    ...operationOptions
  })
}

/**
 * __useCreateProductDocumentMutation__
 *
 * To run a mutation, you first call `useCreateProductDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductDocumentMutation, { data, loading, error }] = useCreateProductDocumentMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      productDocument: // value for 'productDocument'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useCreateProductDocumentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateProductDocumentMutation,
    CreateProductDocumentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateProductDocumentMutation,
    CreateProductDocumentMutationVariables
  >(CreateProductDocumentDocument, baseOptions)
}
export type CreateProductDocumentMutationHookResult = ReturnType<
  typeof useCreateProductDocumentMutation
>
export type CreateProductDocumentMutationResult = ApolloReactCommon.MutationResult<
  CreateProductDocumentMutation
>
export type CreateProductDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateProductDocumentMutation,
  CreateProductDocumentMutationVariables
>
export const UpdateProductDocumentDocument = gql`
  mutation updateProductDocument(
    $id: Int!
    $productId: Int!
    $update: ProductDocumentUpdateInput
    $userContext: UserContextInput
  ) {
    updateProductDocument(
      id: $id
      productId: $productId
      update: $update
      userContext: $userContext
    ) {
      ...document
    }
  }
  ${DocumentFragmentDoc}
`
export type UpdateProductDocumentMutationFn = ApolloReactCommon.MutationFunction<
  UpdateProductDocumentMutation,
  UpdateProductDocumentMutationVariables
>
export type UpdateProductDocumentComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateProductDocumentMutation,
    UpdateProductDocumentMutationVariables
  >,
  'mutation'
>

export const UpdateProductDocumentComponent = (props: UpdateProductDocumentComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateProductDocumentMutation,
    UpdateProductDocumentMutationVariables
  >
    mutation={UpdateProductDocumentDocument}
    {...props}
  />
)

export type UpdateProductDocumentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateProductDocumentMutation,
  UpdateProductDocumentMutationVariables
> &
  TChildProps
export function withUpdateProductDocument<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateProductDocumentMutation,
    UpdateProductDocumentMutationVariables,
    UpdateProductDocumentProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateProductDocumentMutation,
    UpdateProductDocumentMutationVariables,
    UpdateProductDocumentProps<TChildProps>
  >(UpdateProductDocumentDocument, {
    alias: 'updateProductDocument',
    ...operationOptions
  })
}

/**
 * __useUpdateProductDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateProductDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductDocumentMutation, { data, loading, error }] = useUpdateProductDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      productId: // value for 'productId'
 *      update: // value for 'update'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useUpdateProductDocumentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateProductDocumentMutation,
    UpdateProductDocumentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateProductDocumentMutation,
    UpdateProductDocumentMutationVariables
  >(UpdateProductDocumentDocument, baseOptions)
}
export type UpdateProductDocumentMutationHookResult = ReturnType<
  typeof useUpdateProductDocumentMutation
>
export type UpdateProductDocumentMutationResult = ApolloReactCommon.MutationResult<
  UpdateProductDocumentMutation
>
export type UpdateProductDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateProductDocumentMutation,
  UpdateProductDocumentMutationVariables
>
export const DeleteProductDocumentDocument = gql`
  mutation deleteProductDocument($id: Int!, $productId: Int!, $userContext: UserContextInput) {
    deleteProductDocument(id: $id, productId: $productId, userContext: $userContext) {
      id
    }
  }
`
export type DeleteProductDocumentMutationFn = ApolloReactCommon.MutationFunction<
  DeleteProductDocumentMutation,
  DeleteProductDocumentMutationVariables
>
export type DeleteProductDocumentComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteProductDocumentMutation,
    DeleteProductDocumentMutationVariables
  >,
  'mutation'
>

export const DeleteProductDocumentComponent = (props: DeleteProductDocumentComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteProductDocumentMutation,
    DeleteProductDocumentMutationVariables
  >
    mutation={DeleteProductDocumentDocument}
    {...props}
  />
)

export type DeleteProductDocumentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteProductDocumentMutation,
  DeleteProductDocumentMutationVariables
> &
  TChildProps
export function withDeleteProductDocument<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteProductDocumentMutation,
    DeleteProductDocumentMutationVariables,
    DeleteProductDocumentProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteProductDocumentMutation,
    DeleteProductDocumentMutationVariables,
    DeleteProductDocumentProps<TChildProps>
  >(DeleteProductDocumentDocument, {
    alias: 'deleteProductDocument',
    ...operationOptions
  })
}

/**
 * __useDeleteProductDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteProductDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductDocumentMutation, { data, loading, error }] = useDeleteProductDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      productId: // value for 'productId'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useDeleteProductDocumentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteProductDocumentMutation,
    DeleteProductDocumentMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteProductDocumentMutation,
    DeleteProductDocumentMutationVariables
  >(DeleteProductDocumentDocument, baseOptions)
}
export type DeleteProductDocumentMutationHookResult = ReturnType<
  typeof useDeleteProductDocumentMutation
>
export type DeleteProductDocumentMutationResult = ApolloReactCommon.MutationResult<
  DeleteProductDocumentMutation
>
export type DeleteProductDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteProductDocumentMutation,
  DeleteProductDocumentMutationVariables
>
export const CreateProductLicencePlateDocument = gql`
  mutation createProductLicencePlate(
    $licencePlate: LPDefinitionInput
    $productId: Int!
    $userContext: UserContextInput
  ) {
    createProductLicencePlate(
      licencePlate: $licencePlate
      productId: $productId
      userContext: $userContext
    ) {
      ...licencePlate
    }
  }
  ${LicencePlateFragmentDoc}
`
export type CreateProductLicencePlateMutationFn = ApolloReactCommon.MutationFunction<
  CreateProductLicencePlateMutation,
  CreateProductLicencePlateMutationVariables
>
export type CreateProductLicencePlateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateProductLicencePlateMutation,
    CreateProductLicencePlateMutationVariables
  >,
  'mutation'
>

export const CreateProductLicencePlateComponent = (
  props: CreateProductLicencePlateComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateProductLicencePlateMutation,
    CreateProductLicencePlateMutationVariables
  >
    mutation={CreateProductLicencePlateDocument}
    {...props}
  />
)

export type CreateProductLicencePlateProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateProductLicencePlateMutation,
  CreateProductLicencePlateMutationVariables
> &
  TChildProps
export function withCreateProductLicencePlate<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateProductLicencePlateMutation,
    CreateProductLicencePlateMutationVariables,
    CreateProductLicencePlateProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateProductLicencePlateMutation,
    CreateProductLicencePlateMutationVariables,
    CreateProductLicencePlateProps<TChildProps>
  >(CreateProductLicencePlateDocument, {
    alias: 'createProductLicencePlate',
    ...operationOptions
  })
}

/**
 * __useCreateProductLicencePlateMutation__
 *
 * To run a mutation, you first call `useCreateProductLicencePlateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductLicencePlateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductLicencePlateMutation, { data, loading, error }] = useCreateProductLicencePlateMutation({
 *   variables: {
 *      licencePlate: // value for 'licencePlate'
 *      productId: // value for 'productId'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useCreateProductLicencePlateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateProductLicencePlateMutation,
    CreateProductLicencePlateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateProductLicencePlateMutation,
    CreateProductLicencePlateMutationVariables
  >(CreateProductLicencePlateDocument, baseOptions)
}
export type CreateProductLicencePlateMutationHookResult = ReturnType<
  typeof useCreateProductLicencePlateMutation
>
export type CreateProductLicencePlateMutationResult = ApolloReactCommon.MutationResult<
  CreateProductLicencePlateMutation
>
export type CreateProductLicencePlateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateProductLicencePlateMutation,
  CreateProductLicencePlateMutationVariables
>
export const UpdateProductLicencePlateDocument = gql`
  mutation updateProductLicencePlate(
    $id: Int!
    $productId: Int!
    $update: LPDefinitionUpdateInput
    $userContext: UserContextInput
  ) {
    updateProductLicencePlate(
      id: $id
      productId: $productId
      update: $update
      userContext: $userContext
    ) {
      ...licencePlate
    }
  }
  ${LicencePlateFragmentDoc}
`
export type UpdateProductLicencePlateMutationFn = ApolloReactCommon.MutationFunction<
  UpdateProductLicencePlateMutation,
  UpdateProductLicencePlateMutationVariables
>
export type UpdateProductLicencePlateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateProductLicencePlateMutation,
    UpdateProductLicencePlateMutationVariables
  >,
  'mutation'
>

export const UpdateProductLicencePlateComponent = (
  props: UpdateProductLicencePlateComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateProductLicencePlateMutation,
    UpdateProductLicencePlateMutationVariables
  >
    mutation={UpdateProductLicencePlateDocument}
    {...props}
  />
)

export type UpdateProductLicencePlateProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateProductLicencePlateMutation,
  UpdateProductLicencePlateMutationVariables
> &
  TChildProps
export function withUpdateProductLicencePlate<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateProductLicencePlateMutation,
    UpdateProductLicencePlateMutationVariables,
    UpdateProductLicencePlateProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateProductLicencePlateMutation,
    UpdateProductLicencePlateMutationVariables,
    UpdateProductLicencePlateProps<TChildProps>
  >(UpdateProductLicencePlateDocument, {
    alias: 'updateProductLicencePlate',
    ...operationOptions
  })
}

/**
 * __useUpdateProductLicencePlateMutation__
 *
 * To run a mutation, you first call `useUpdateProductLicencePlateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductLicencePlateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductLicencePlateMutation, { data, loading, error }] = useUpdateProductLicencePlateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      productId: // value for 'productId'
 *      update: // value for 'update'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useUpdateProductLicencePlateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateProductLicencePlateMutation,
    UpdateProductLicencePlateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateProductLicencePlateMutation,
    UpdateProductLicencePlateMutationVariables
  >(UpdateProductLicencePlateDocument, baseOptions)
}
export type UpdateProductLicencePlateMutationHookResult = ReturnType<
  typeof useUpdateProductLicencePlateMutation
>
export type UpdateProductLicencePlateMutationResult = ApolloReactCommon.MutationResult<
  UpdateProductLicencePlateMutation
>
export type UpdateProductLicencePlateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateProductLicencePlateMutation,
  UpdateProductLicencePlateMutationVariables
>
export const DeleteProductLicencePlateDocument = gql`
  mutation deleteProductLicencePlate($id: Int!, $productId: Int!, $userContext: UserContextInput) {
    deleteProductLicencePlate(id: $id, productId: $productId, userContext: $userContext) {
      id
    }
  }
`
export type DeleteProductLicencePlateMutationFn = ApolloReactCommon.MutationFunction<
  DeleteProductLicencePlateMutation,
  DeleteProductLicencePlateMutationVariables
>
export type DeleteProductLicencePlateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteProductLicencePlateMutation,
    DeleteProductLicencePlateMutationVariables
  >,
  'mutation'
>

export const DeleteProductLicencePlateComponent = (
  props: DeleteProductLicencePlateComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteProductLicencePlateMutation,
    DeleteProductLicencePlateMutationVariables
  >
    mutation={DeleteProductLicencePlateDocument}
    {...props}
  />
)

export type DeleteProductLicencePlateProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteProductLicencePlateMutation,
  DeleteProductLicencePlateMutationVariables
> &
  TChildProps
export function withDeleteProductLicencePlate<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteProductLicencePlateMutation,
    DeleteProductLicencePlateMutationVariables,
    DeleteProductLicencePlateProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteProductLicencePlateMutation,
    DeleteProductLicencePlateMutationVariables,
    DeleteProductLicencePlateProps<TChildProps>
  >(DeleteProductLicencePlateDocument, {
    alias: 'deleteProductLicencePlate',
    ...operationOptions
  })
}

/**
 * __useDeleteProductLicencePlateMutation__
 *
 * To run a mutation, you first call `useDeleteProductLicencePlateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductLicencePlateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductLicencePlateMutation, { data, loading, error }] = useDeleteProductLicencePlateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      productId: // value for 'productId'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useDeleteProductLicencePlateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteProductLicencePlateMutation,
    DeleteProductLicencePlateMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteProductLicencePlateMutation,
    DeleteProductLicencePlateMutationVariables
  >(DeleteProductLicencePlateDocument, baseOptions)
}
export type DeleteProductLicencePlateMutationHookResult = ReturnType<
  typeof useDeleteProductLicencePlateMutation
>
export type DeleteProductLicencePlateMutationResult = ApolloReactCommon.MutationResult<
  DeleteProductLicencePlateMutation
>
export type DeleteProductLicencePlateMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteProductLicencePlateMutation,
  DeleteProductLicencePlateMutationVariables
>
export const CreateProductPickfaceDocument = gql`
  mutation createProductPickface(
    $input: ProductPickfaceInput
    $productId: Int!
    $userContext: UserContextInput
  ) {
    createProductPickface(pickface: $input, productId: $productId, userContext: $userContext) {
      ...pickface
    }
  }
  ${PickfaceFragmentDoc}
`
export type CreateProductPickfaceMutationFn = ApolloReactCommon.MutationFunction<
  CreateProductPickfaceMutation,
  CreateProductPickfaceMutationVariables
>
export type CreateProductPickfaceComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateProductPickfaceMutation,
    CreateProductPickfaceMutationVariables
  >,
  'mutation'
>

export const CreateProductPickfaceComponent = (props: CreateProductPickfaceComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateProductPickfaceMutation,
    CreateProductPickfaceMutationVariables
  >
    mutation={CreateProductPickfaceDocument}
    {...props}
  />
)

export type CreateProductPickfaceProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateProductPickfaceMutation,
  CreateProductPickfaceMutationVariables
> &
  TChildProps
export function withCreateProductPickface<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateProductPickfaceMutation,
    CreateProductPickfaceMutationVariables,
    CreateProductPickfaceProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateProductPickfaceMutation,
    CreateProductPickfaceMutationVariables,
    CreateProductPickfaceProps<TChildProps>
  >(CreateProductPickfaceDocument, {
    alias: 'createProductPickface',
    ...operationOptions
  })
}

/**
 * __useCreateProductPickfaceMutation__
 *
 * To run a mutation, you first call `useCreateProductPickfaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductPickfaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductPickfaceMutation, { data, loading, error }] = useCreateProductPickfaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      productId: // value for 'productId'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useCreateProductPickfaceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateProductPickfaceMutation,
    CreateProductPickfaceMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateProductPickfaceMutation,
    CreateProductPickfaceMutationVariables
  >(CreateProductPickfaceDocument, baseOptions)
}
export type CreateProductPickfaceMutationHookResult = ReturnType<
  typeof useCreateProductPickfaceMutation
>
export type CreateProductPickfaceMutationResult = ApolloReactCommon.MutationResult<
  CreateProductPickfaceMutation
>
export type CreateProductPickfaceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateProductPickfaceMutation,
  CreateProductPickfaceMutationVariables
>
export const UpdateProductPickfaceDocument = gql`
  mutation updateProductPickface(
    $update: ProductPickfaceUpdateInput
    $id: Int!
    $productId: Int!
    $userContext: UserContextInput
  ) {
    updateProductPickface(
      update: $update
      id: $id
      productId: $productId
      userContext: $userContext
    ) {
      ...pickface
    }
  }
  ${PickfaceFragmentDoc}
`
export type UpdateProductPickfaceMutationFn = ApolloReactCommon.MutationFunction<
  UpdateProductPickfaceMutation,
  UpdateProductPickfaceMutationVariables
>
export type UpdateProductPickfaceComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateProductPickfaceMutation,
    UpdateProductPickfaceMutationVariables
  >,
  'mutation'
>

export const UpdateProductPickfaceComponent = (props: UpdateProductPickfaceComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateProductPickfaceMutation,
    UpdateProductPickfaceMutationVariables
  >
    mutation={UpdateProductPickfaceDocument}
    {...props}
  />
)

export type UpdateProductPickfaceProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateProductPickfaceMutation,
  UpdateProductPickfaceMutationVariables
> &
  TChildProps
export function withUpdateProductPickface<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateProductPickfaceMutation,
    UpdateProductPickfaceMutationVariables,
    UpdateProductPickfaceProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateProductPickfaceMutation,
    UpdateProductPickfaceMutationVariables,
    UpdateProductPickfaceProps<TChildProps>
  >(UpdateProductPickfaceDocument, {
    alias: 'updateProductPickface',
    ...operationOptions
  })
}

/**
 * __useUpdateProductPickfaceMutation__
 *
 * To run a mutation, you first call `useUpdateProductPickfaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductPickfaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductPickfaceMutation, { data, loading, error }] = useUpdateProductPickfaceMutation({
 *   variables: {
 *      update: // value for 'update'
 *      id: // value for 'id'
 *      productId: // value for 'productId'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useUpdateProductPickfaceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateProductPickfaceMutation,
    UpdateProductPickfaceMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateProductPickfaceMutation,
    UpdateProductPickfaceMutationVariables
  >(UpdateProductPickfaceDocument, baseOptions)
}
export type UpdateProductPickfaceMutationHookResult = ReturnType<
  typeof useUpdateProductPickfaceMutation
>
export type UpdateProductPickfaceMutationResult = ApolloReactCommon.MutationResult<
  UpdateProductPickfaceMutation
>
export type UpdateProductPickfaceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateProductPickfaceMutation,
  UpdateProductPickfaceMutationVariables
>
export const DeleteProductPickfaceDocument = gql`
  mutation deleteProductPickface($id: Int!, $productId: Int!, $userContext: UserContextInput) {
    deleteProductPickface(id: $id, productId: $productId, userContext: $userContext) {
      id
    }
  }
`
export type DeleteProductPickfaceMutationFn = ApolloReactCommon.MutationFunction<
  DeleteProductPickfaceMutation,
  DeleteProductPickfaceMutationVariables
>
export type DeleteProductPickfaceComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteProductPickfaceMutation,
    DeleteProductPickfaceMutationVariables
  >,
  'mutation'
>

export const DeleteProductPickfaceComponent = (props: DeleteProductPickfaceComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteProductPickfaceMutation,
    DeleteProductPickfaceMutationVariables
  >
    mutation={DeleteProductPickfaceDocument}
    {...props}
  />
)

export type DeleteProductPickfaceProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteProductPickfaceMutation,
  DeleteProductPickfaceMutationVariables
> &
  TChildProps
export function withDeleteProductPickface<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteProductPickfaceMutation,
    DeleteProductPickfaceMutationVariables,
    DeleteProductPickfaceProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteProductPickfaceMutation,
    DeleteProductPickfaceMutationVariables,
    DeleteProductPickfaceProps<TChildProps>
  >(DeleteProductPickfaceDocument, {
    alias: 'deleteProductPickface',
    ...operationOptions
  })
}

/**
 * __useDeleteProductPickfaceMutation__
 *
 * To run a mutation, you first call `useDeleteProductPickfaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductPickfaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductPickfaceMutation, { data, loading, error }] = useDeleteProductPickfaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      productId: // value for 'productId'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useDeleteProductPickfaceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteProductPickfaceMutation,
    DeleteProductPickfaceMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteProductPickfaceMutation,
    DeleteProductPickfaceMutationVariables
  >(DeleteProductPickfaceDocument, baseOptions)
}
export type DeleteProductPickfaceMutationHookResult = ReturnType<
  typeof useDeleteProductPickfaceMutation
>
export type DeleteProductPickfaceMutationResult = ApolloReactCommon.MutationResult<
  DeleteProductPickfaceMutation
>
export type DeleteProductPickfaceMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteProductPickfaceMutation,
  DeleteProductPickfaceMutationVariables
>
export const UpdateProductSupplierDocument = gql`
  mutation updateProductSupplier(
    $id: Int!
    $productId: Int!
    $input: ProductSupplierUpdateInput
    $userContext: UserContextInput
  ) {
    updateProductSupplier(
      id: $id
      productId: $productId
      update: $input
      userContext: $userContext
    ) {
      ...productSupplier
    }
  }
  ${ProductSupplierFragmentDoc}
`
export type UpdateProductSupplierMutationFn = ApolloReactCommon.MutationFunction<
  UpdateProductSupplierMutation,
  UpdateProductSupplierMutationVariables
>
export type UpdateProductSupplierComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateProductSupplierMutation,
    UpdateProductSupplierMutationVariables
  >,
  'mutation'
>

export const UpdateProductSupplierComponent = (props: UpdateProductSupplierComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateProductSupplierMutation,
    UpdateProductSupplierMutationVariables
  >
    mutation={UpdateProductSupplierDocument}
    {...props}
  />
)

export type UpdateProductSupplierProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateProductSupplierMutation,
  UpdateProductSupplierMutationVariables
> &
  TChildProps
export function withUpdateProductSupplier<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateProductSupplierMutation,
    UpdateProductSupplierMutationVariables,
    UpdateProductSupplierProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateProductSupplierMutation,
    UpdateProductSupplierMutationVariables,
    UpdateProductSupplierProps<TChildProps>
  >(UpdateProductSupplierDocument, {
    alias: 'updateProductSupplier',
    ...operationOptions
  })
}

/**
 * __useUpdateProductSupplierMutation__
 *
 * To run a mutation, you first call `useUpdateProductSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductSupplierMutation, { data, loading, error }] = useUpdateProductSupplierMutation({
 *   variables: {
 *      id: // value for 'id'
 *      productId: // value for 'productId'
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useUpdateProductSupplierMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateProductSupplierMutation,
    UpdateProductSupplierMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateProductSupplierMutation,
    UpdateProductSupplierMutationVariables
  >(UpdateProductSupplierDocument, baseOptions)
}
export type UpdateProductSupplierMutationHookResult = ReturnType<
  typeof useUpdateProductSupplierMutation
>
export type UpdateProductSupplierMutationResult = ApolloReactCommon.MutationResult<
  UpdateProductSupplierMutation
>
export type UpdateProductSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateProductSupplierMutation,
  UpdateProductSupplierMutationVariables
>
export const CreateProductSupplierDocument = gql`
  mutation createProductSupplier(
    $productId: Int!
    $input: ProductSupplierInput
    $userContext: UserContextInput
  ) {
    createProductSupplier(
      productId: $productId
      productSupplier: $input
      userContext: $userContext
    ) {
      ...productSupplier
    }
  }
  ${ProductSupplierFragmentDoc}
`
export type CreateProductSupplierMutationFn = ApolloReactCommon.MutationFunction<
  CreateProductSupplierMutation,
  CreateProductSupplierMutationVariables
>
export type CreateProductSupplierComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateProductSupplierMutation,
    CreateProductSupplierMutationVariables
  >,
  'mutation'
>

export const CreateProductSupplierComponent = (props: CreateProductSupplierComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateProductSupplierMutation,
    CreateProductSupplierMutationVariables
  >
    mutation={CreateProductSupplierDocument}
    {...props}
  />
)

export type CreateProductSupplierProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateProductSupplierMutation,
  CreateProductSupplierMutationVariables
> &
  TChildProps
export function withCreateProductSupplier<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateProductSupplierMutation,
    CreateProductSupplierMutationVariables,
    CreateProductSupplierProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateProductSupplierMutation,
    CreateProductSupplierMutationVariables,
    CreateProductSupplierProps<TChildProps>
  >(CreateProductSupplierDocument, {
    alias: 'createProductSupplier',
    ...operationOptions
  })
}

/**
 * __useCreateProductSupplierMutation__
 *
 * To run a mutation, you first call `useCreateProductSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductSupplierMutation, { data, loading, error }] = useCreateProductSupplierMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useCreateProductSupplierMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateProductSupplierMutation,
    CreateProductSupplierMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateProductSupplierMutation,
    CreateProductSupplierMutationVariables
  >(CreateProductSupplierDocument, baseOptions)
}
export type CreateProductSupplierMutationHookResult = ReturnType<
  typeof useCreateProductSupplierMutation
>
export type CreateProductSupplierMutationResult = ApolloReactCommon.MutationResult<
  CreateProductSupplierMutation
>
export type CreateProductSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateProductSupplierMutation,
  CreateProductSupplierMutationVariables
>
export const DeleteProductSupplierDocument = gql`
  mutation deleteProductSupplier($id: Int!, $productId: Int!, $userContext: UserContextInput) {
    deleteProductSupplier(productId: $productId, id: $id, userContext: $userContext) {
      id
    }
  }
`
export type DeleteProductSupplierMutationFn = ApolloReactCommon.MutationFunction<
  DeleteProductSupplierMutation,
  DeleteProductSupplierMutationVariables
>
export type DeleteProductSupplierComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteProductSupplierMutation,
    DeleteProductSupplierMutationVariables
  >,
  'mutation'
>

export const DeleteProductSupplierComponent = (props: DeleteProductSupplierComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteProductSupplierMutation,
    DeleteProductSupplierMutationVariables
  >
    mutation={DeleteProductSupplierDocument}
    {...props}
  />
)

export type DeleteProductSupplierProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteProductSupplierMutation,
  DeleteProductSupplierMutationVariables
> &
  TChildProps
export function withDeleteProductSupplier<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteProductSupplierMutation,
    DeleteProductSupplierMutationVariables,
    DeleteProductSupplierProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteProductSupplierMutation,
    DeleteProductSupplierMutationVariables,
    DeleteProductSupplierProps<TChildProps>
  >(DeleteProductSupplierDocument, {
    alias: 'deleteProductSupplier',
    ...operationOptions
  })
}

/**
 * __useDeleteProductSupplierMutation__
 *
 * To run a mutation, you first call `useDeleteProductSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductSupplierMutation, { data, loading, error }] = useDeleteProductSupplierMutation({
 *   variables: {
 *      id: // value for 'id'
 *      productId: // value for 'productId'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useDeleteProductSupplierMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteProductSupplierMutation,
    DeleteProductSupplierMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteProductSupplierMutation,
    DeleteProductSupplierMutationVariables
  >(DeleteProductSupplierDocument, baseOptions)
}
export type DeleteProductSupplierMutationHookResult = ReturnType<
  typeof useDeleteProductSupplierMutation
>
export type DeleteProductSupplierMutationResult = ApolloReactCommon.MutationResult<
  DeleteProductSupplierMutation
>
export type DeleteProductSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteProductSupplierMutation,
  DeleteProductSupplierMutationVariables
>
export const CreateStockOwnerDocument = gql`
  mutation createStockOwner($input: StockOwnerInput) {
    createStockOwner(input: $input) {
      ...stockOwner
    }
  }
  ${StockOwnerFragmentDoc}
`
export type CreateStockOwnerMutationFn = ApolloReactCommon.MutationFunction<
  CreateStockOwnerMutation,
  CreateStockOwnerMutationVariables
>
export type CreateStockOwnerComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateStockOwnerMutation,
    CreateStockOwnerMutationVariables
  >,
  'mutation'
>

export const CreateStockOwnerComponent = (props: CreateStockOwnerComponentProps) => (
  <ApolloReactComponents.Mutation<CreateStockOwnerMutation, CreateStockOwnerMutationVariables>
    mutation={CreateStockOwnerDocument}
    {...props}
  />
)

export type CreateStockOwnerProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateStockOwnerMutation,
  CreateStockOwnerMutationVariables
> &
  TChildProps
export function withCreateStockOwner<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateStockOwnerMutation,
    CreateStockOwnerMutationVariables,
    CreateStockOwnerProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateStockOwnerMutation,
    CreateStockOwnerMutationVariables,
    CreateStockOwnerProps<TChildProps>
  >(CreateStockOwnerDocument, {
    alias: 'createStockOwner',
    ...operationOptions
  })
}

/**
 * __useCreateStockOwnerMutation__
 *
 * To run a mutation, you first call `useCreateStockOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockOwnerMutation, { data, loading, error }] = useCreateStockOwnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStockOwnerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateStockOwnerMutation,
    CreateStockOwnerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateStockOwnerMutation, CreateStockOwnerMutationVariables>(
    CreateStockOwnerDocument,
    baseOptions
  )
}
export type CreateStockOwnerMutationHookResult = ReturnType<typeof useCreateStockOwnerMutation>
export type CreateStockOwnerMutationResult = ApolloReactCommon.MutationResult<
  CreateStockOwnerMutation
>
export type CreateStockOwnerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateStockOwnerMutation,
  CreateStockOwnerMutationVariables
>
export const UpdateStockOwnerDocument = gql`
  mutation updateStockOwner(
    $id: Int!
    $input: StockOwnerUpdateInput
    $userContext: UserContextInput
  ) {
    updateStockOwner(id: $id, input: $input, userContext: $userContext) {
      ...stockOwner
    }
  }
  ${StockOwnerFragmentDoc}
`
export type UpdateStockOwnerMutationFn = ApolloReactCommon.MutationFunction<
  UpdateStockOwnerMutation,
  UpdateStockOwnerMutationVariables
>
export type UpdateStockOwnerComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateStockOwnerMutation,
    UpdateStockOwnerMutationVariables
  >,
  'mutation'
>

export const UpdateStockOwnerComponent = (props: UpdateStockOwnerComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateStockOwnerMutation, UpdateStockOwnerMutationVariables>
    mutation={UpdateStockOwnerDocument}
    {...props}
  />
)

export type UpdateStockOwnerProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateStockOwnerMutation,
  UpdateStockOwnerMutationVariables
> &
  TChildProps
export function withUpdateStockOwner<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateStockOwnerMutation,
    UpdateStockOwnerMutationVariables,
    UpdateStockOwnerProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateStockOwnerMutation,
    UpdateStockOwnerMutationVariables,
    UpdateStockOwnerProps<TChildProps>
  >(UpdateStockOwnerDocument, {
    alias: 'updateStockOwner',
    ...operationOptions
  })
}

/**
 * __useUpdateStockOwnerMutation__
 *
 * To run a mutation, you first call `useUpdateStockOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockOwnerMutation, { data, loading, error }] = useUpdateStockOwnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useUpdateStockOwnerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateStockOwnerMutation,
    UpdateStockOwnerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateStockOwnerMutation, UpdateStockOwnerMutationVariables>(
    UpdateStockOwnerDocument,
    baseOptions
  )
}
export type UpdateStockOwnerMutationHookResult = ReturnType<typeof useUpdateStockOwnerMutation>
export type UpdateStockOwnerMutationResult = ApolloReactCommon.MutationResult<
  UpdateStockOwnerMutation
>
export type UpdateStockOwnerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateStockOwnerMutation,
  UpdateStockOwnerMutationVariables
>
export const DeleteStockOwnerDocument = gql`
  mutation deleteStockOwner($id: Int!, $userContext: UserContextInput) {
    deleteStockOwner(id: $id, userContext: $userContext) {
      ...stockOwner
    }
  }
  ${StockOwnerFragmentDoc}
`
export type DeleteStockOwnerMutationFn = ApolloReactCommon.MutationFunction<
  DeleteStockOwnerMutation,
  DeleteStockOwnerMutationVariables
>
export type DeleteStockOwnerComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteStockOwnerMutation,
    DeleteStockOwnerMutationVariables
  >,
  'mutation'
>

export const DeleteStockOwnerComponent = (props: DeleteStockOwnerComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteStockOwnerMutation, DeleteStockOwnerMutationVariables>
    mutation={DeleteStockOwnerDocument}
    {...props}
  />
)

export type DeleteStockOwnerProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteStockOwnerMutation,
  DeleteStockOwnerMutationVariables
> &
  TChildProps
export function withDeleteStockOwner<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteStockOwnerMutation,
    DeleteStockOwnerMutationVariables,
    DeleteStockOwnerProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteStockOwnerMutation,
    DeleteStockOwnerMutationVariables,
    DeleteStockOwnerProps<TChildProps>
  >(DeleteStockOwnerDocument, {
    alias: 'deleteStockOwner',
    ...operationOptions
  })
}

/**
 * __useDeleteStockOwnerMutation__
 *
 * To run a mutation, you first call `useDeleteStockOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStockOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStockOwnerMutation, { data, loading, error }] = useDeleteStockOwnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useDeleteStockOwnerMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteStockOwnerMutation,
    DeleteStockOwnerMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteStockOwnerMutation, DeleteStockOwnerMutationVariables>(
    DeleteStockOwnerDocument,
    baseOptions
  )
}
export type DeleteStockOwnerMutationHookResult = ReturnType<typeof useDeleteStockOwnerMutation>
export type DeleteStockOwnerMutationResult = ApolloReactCommon.MutationResult<
  DeleteStockOwnerMutation
>
export type DeleteStockOwnerMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteStockOwnerMutation,
  DeleteStockOwnerMutationVariables
>
export const UpdateStockOwnerCategoryDocument = gql`
  mutation updateStockOwnerCategory(
    $id: Int!
    $stockOwnerId: Int!
    $input: CategoryDefinitionUpdateInput
  ) {
    updateStockOwnerCategoryDefinition(id: $id, stockOwnerId: $stockOwnerId, input: $input) {
      ...categoryDefinition
    }
  }
  ${CategoryDefinitionFragmentDoc}
`
export type UpdateStockOwnerCategoryMutationFn = ApolloReactCommon.MutationFunction<
  UpdateStockOwnerCategoryMutation,
  UpdateStockOwnerCategoryMutationVariables
>
export type UpdateStockOwnerCategoryComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateStockOwnerCategoryMutation,
    UpdateStockOwnerCategoryMutationVariables
  >,
  'mutation'
>

export const UpdateStockOwnerCategoryComponent = (
  props: UpdateStockOwnerCategoryComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateStockOwnerCategoryMutation,
    UpdateStockOwnerCategoryMutationVariables
  >
    mutation={UpdateStockOwnerCategoryDocument}
    {...props}
  />
)

export type UpdateStockOwnerCategoryProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateStockOwnerCategoryMutation,
  UpdateStockOwnerCategoryMutationVariables
> &
  TChildProps
export function withUpdateStockOwnerCategory<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateStockOwnerCategoryMutation,
    UpdateStockOwnerCategoryMutationVariables,
    UpdateStockOwnerCategoryProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateStockOwnerCategoryMutation,
    UpdateStockOwnerCategoryMutationVariables,
    UpdateStockOwnerCategoryProps<TChildProps>
  >(UpdateStockOwnerCategoryDocument, {
    alias: 'updateStockOwnerCategory',
    ...operationOptions
  })
}

/**
 * __useUpdateStockOwnerCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateStockOwnerCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockOwnerCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockOwnerCategoryMutation, { data, loading, error }] = useUpdateStockOwnerCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      stockOwnerId: // value for 'stockOwnerId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStockOwnerCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateStockOwnerCategoryMutation,
    UpdateStockOwnerCategoryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateStockOwnerCategoryMutation,
    UpdateStockOwnerCategoryMutationVariables
  >(UpdateStockOwnerCategoryDocument, baseOptions)
}
export type UpdateStockOwnerCategoryMutationHookResult = ReturnType<
  typeof useUpdateStockOwnerCategoryMutation
>
export type UpdateStockOwnerCategoryMutationResult = ApolloReactCommon.MutationResult<
  UpdateStockOwnerCategoryMutation
>
export type UpdateStockOwnerCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateStockOwnerCategoryMutation,
  UpdateStockOwnerCategoryMutationVariables
>
export const CreateStockOwnerCategoyDocument = gql`
  mutation createStockOwnerCategoy($stockOwnerId: Int!, $input: CategoryDefinitionInput) {
    createStockOwnerCategoryDefinition(stockOwnerId: $stockOwnerId, input: $input) {
      ...categoryDefinition
    }
  }
  ${CategoryDefinitionFragmentDoc}
`
export type CreateStockOwnerCategoyMutationFn = ApolloReactCommon.MutationFunction<
  CreateStockOwnerCategoyMutation,
  CreateStockOwnerCategoyMutationVariables
>
export type CreateStockOwnerCategoyComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateStockOwnerCategoyMutation,
    CreateStockOwnerCategoyMutationVariables
  >,
  'mutation'
>

export const CreateStockOwnerCategoyComponent = (props: CreateStockOwnerCategoyComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateStockOwnerCategoyMutation,
    CreateStockOwnerCategoyMutationVariables
  >
    mutation={CreateStockOwnerCategoyDocument}
    {...props}
  />
)

export type CreateStockOwnerCategoyProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateStockOwnerCategoyMutation,
  CreateStockOwnerCategoyMutationVariables
> &
  TChildProps
export function withCreateStockOwnerCategoy<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateStockOwnerCategoyMutation,
    CreateStockOwnerCategoyMutationVariables,
    CreateStockOwnerCategoyProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateStockOwnerCategoyMutation,
    CreateStockOwnerCategoyMutationVariables,
    CreateStockOwnerCategoyProps<TChildProps>
  >(CreateStockOwnerCategoyDocument, {
    alias: 'createStockOwnerCategoy',
    ...operationOptions
  })
}

/**
 * __useCreateStockOwnerCategoyMutation__
 *
 * To run a mutation, you first call `useCreateStockOwnerCategoyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockOwnerCategoyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockOwnerCategoyMutation, { data, loading, error }] = useCreateStockOwnerCategoyMutation({
 *   variables: {
 *      stockOwnerId: // value for 'stockOwnerId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStockOwnerCategoyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateStockOwnerCategoyMutation,
    CreateStockOwnerCategoyMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateStockOwnerCategoyMutation,
    CreateStockOwnerCategoyMutationVariables
  >(CreateStockOwnerCategoyDocument, baseOptions)
}
export type CreateStockOwnerCategoyMutationHookResult = ReturnType<
  typeof useCreateStockOwnerCategoyMutation
>
export type CreateStockOwnerCategoyMutationResult = ApolloReactCommon.MutationResult<
  CreateStockOwnerCategoyMutation
>
export type CreateStockOwnerCategoyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateStockOwnerCategoyMutation,
  CreateStockOwnerCategoyMutationVariables
>
export const DeleteStockOwnerCategoryDocument = gql`
  mutation deleteStockOwnerCategory($stockOwnerId: Int!, $id: Int!) {
    deleteStockOwnerCategoryDefinition(stockOwnerId: $stockOwnerId, id: $id) {
      id
    }
  }
`
export type DeleteStockOwnerCategoryMutationFn = ApolloReactCommon.MutationFunction<
  DeleteStockOwnerCategoryMutation,
  DeleteStockOwnerCategoryMutationVariables
>
export type DeleteStockOwnerCategoryComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteStockOwnerCategoryMutation,
    DeleteStockOwnerCategoryMutationVariables
  >,
  'mutation'
>

export const DeleteStockOwnerCategoryComponent = (
  props: DeleteStockOwnerCategoryComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteStockOwnerCategoryMutation,
    DeleteStockOwnerCategoryMutationVariables
  >
    mutation={DeleteStockOwnerCategoryDocument}
    {...props}
  />
)

export type DeleteStockOwnerCategoryProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteStockOwnerCategoryMutation,
  DeleteStockOwnerCategoryMutationVariables
> &
  TChildProps
export function withDeleteStockOwnerCategory<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteStockOwnerCategoryMutation,
    DeleteStockOwnerCategoryMutationVariables,
    DeleteStockOwnerCategoryProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteStockOwnerCategoryMutation,
    DeleteStockOwnerCategoryMutationVariables,
    DeleteStockOwnerCategoryProps<TChildProps>
  >(DeleteStockOwnerCategoryDocument, {
    alias: 'deleteStockOwnerCategory',
    ...operationOptions
  })
}

/**
 * __useDeleteStockOwnerCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteStockOwnerCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStockOwnerCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStockOwnerCategoryMutation, { data, loading, error }] = useDeleteStockOwnerCategoryMutation({
 *   variables: {
 *      stockOwnerId: // value for 'stockOwnerId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStockOwnerCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteStockOwnerCategoryMutation,
    DeleteStockOwnerCategoryMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteStockOwnerCategoryMutation,
    DeleteStockOwnerCategoryMutationVariables
  >(DeleteStockOwnerCategoryDocument, baseOptions)
}
export type DeleteStockOwnerCategoryMutationHookResult = ReturnType<
  typeof useDeleteStockOwnerCategoryMutation
>
export type DeleteStockOwnerCategoryMutationResult = ApolloReactCommon.MutationResult<
  DeleteStockOwnerCategoryMutation
>
export type DeleteStockOwnerCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteStockOwnerCategoryMutation,
  DeleteStockOwnerCategoryMutationVariables
>
export const UpdateStockOwnerPickConfirmMethodDocument = gql`
  mutation updateStockOwnerPickConfirmMethod(
    $id: Int!
    $stockOwnerId: Int!
    $input: PickConfirmMethodUpdateInput
  ) {
    updateStockOwnerPickConfirmMethod(id: $id, stockOwnerId: $stockOwnerId, input: $input) {
      ...pickConfirmMethod
    }
  }
  ${PickConfirmMethodFragmentDoc}
`
export type UpdateStockOwnerPickConfirmMethodMutationFn = ApolloReactCommon.MutationFunction<
  UpdateStockOwnerPickConfirmMethodMutation,
  UpdateStockOwnerPickConfirmMethodMutationVariables
>
export type UpdateStockOwnerPickConfirmMethodComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateStockOwnerPickConfirmMethodMutation,
    UpdateStockOwnerPickConfirmMethodMutationVariables
  >,
  'mutation'
>

export const UpdateStockOwnerPickConfirmMethodComponent = (
  props: UpdateStockOwnerPickConfirmMethodComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateStockOwnerPickConfirmMethodMutation,
    UpdateStockOwnerPickConfirmMethodMutationVariables
  >
    mutation={UpdateStockOwnerPickConfirmMethodDocument}
    {...props}
  />
)

export type UpdateStockOwnerPickConfirmMethodProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateStockOwnerPickConfirmMethodMutation,
  UpdateStockOwnerPickConfirmMethodMutationVariables
> &
  TChildProps
export function withUpdateStockOwnerPickConfirmMethod<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateStockOwnerPickConfirmMethodMutation,
    UpdateStockOwnerPickConfirmMethodMutationVariables,
    UpdateStockOwnerPickConfirmMethodProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateStockOwnerPickConfirmMethodMutation,
    UpdateStockOwnerPickConfirmMethodMutationVariables,
    UpdateStockOwnerPickConfirmMethodProps<TChildProps>
  >(UpdateStockOwnerPickConfirmMethodDocument, {
    alias: 'updateStockOwnerPickConfirmMethod',
    ...operationOptions
  })
}

/**
 * __useUpdateStockOwnerPickConfirmMethodMutation__
 *
 * To run a mutation, you first call `useUpdateStockOwnerPickConfirmMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockOwnerPickConfirmMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockOwnerPickConfirmMethodMutation, { data, loading, error }] = useUpdateStockOwnerPickConfirmMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      stockOwnerId: // value for 'stockOwnerId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStockOwnerPickConfirmMethodMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateStockOwnerPickConfirmMethodMutation,
    UpdateStockOwnerPickConfirmMethodMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateStockOwnerPickConfirmMethodMutation,
    UpdateStockOwnerPickConfirmMethodMutationVariables
  >(UpdateStockOwnerPickConfirmMethodDocument, baseOptions)
}
export type UpdateStockOwnerPickConfirmMethodMutationHookResult = ReturnType<
  typeof useUpdateStockOwnerPickConfirmMethodMutation
>
export type UpdateStockOwnerPickConfirmMethodMutationResult = ApolloReactCommon.MutationResult<
  UpdateStockOwnerPickConfirmMethodMutation
>
export type UpdateStockOwnerPickConfirmMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateStockOwnerPickConfirmMethodMutation,
  UpdateStockOwnerPickConfirmMethodMutationVariables
>
export const CreateStockOwnerPickConfirmMethodDocument = gql`
  mutation createStockOwnerPickConfirmMethod($stockOwnerId: Int!, $input: PickConfirmMethodInput) {
    createStockOwnerPickConfirmMethod(stockOwnerId: $stockOwnerId, input: $input) {
      ...pickConfirmMethod
    }
  }
  ${PickConfirmMethodFragmentDoc}
`
export type CreateStockOwnerPickConfirmMethodMutationFn = ApolloReactCommon.MutationFunction<
  CreateStockOwnerPickConfirmMethodMutation,
  CreateStockOwnerPickConfirmMethodMutationVariables
>
export type CreateStockOwnerPickConfirmMethodComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateStockOwnerPickConfirmMethodMutation,
    CreateStockOwnerPickConfirmMethodMutationVariables
  >,
  'mutation'
>

export const CreateStockOwnerPickConfirmMethodComponent = (
  props: CreateStockOwnerPickConfirmMethodComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateStockOwnerPickConfirmMethodMutation,
    CreateStockOwnerPickConfirmMethodMutationVariables
  >
    mutation={CreateStockOwnerPickConfirmMethodDocument}
    {...props}
  />
)

export type CreateStockOwnerPickConfirmMethodProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateStockOwnerPickConfirmMethodMutation,
  CreateStockOwnerPickConfirmMethodMutationVariables
> &
  TChildProps
export function withCreateStockOwnerPickConfirmMethod<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateStockOwnerPickConfirmMethodMutation,
    CreateStockOwnerPickConfirmMethodMutationVariables,
    CreateStockOwnerPickConfirmMethodProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateStockOwnerPickConfirmMethodMutation,
    CreateStockOwnerPickConfirmMethodMutationVariables,
    CreateStockOwnerPickConfirmMethodProps<TChildProps>
  >(CreateStockOwnerPickConfirmMethodDocument, {
    alias: 'createStockOwnerPickConfirmMethod',
    ...operationOptions
  })
}

/**
 * __useCreateStockOwnerPickConfirmMethodMutation__
 *
 * To run a mutation, you first call `useCreateStockOwnerPickConfirmMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockOwnerPickConfirmMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockOwnerPickConfirmMethodMutation, { data, loading, error }] = useCreateStockOwnerPickConfirmMethodMutation({
 *   variables: {
 *      stockOwnerId: // value for 'stockOwnerId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStockOwnerPickConfirmMethodMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateStockOwnerPickConfirmMethodMutation,
    CreateStockOwnerPickConfirmMethodMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateStockOwnerPickConfirmMethodMutation,
    CreateStockOwnerPickConfirmMethodMutationVariables
  >(CreateStockOwnerPickConfirmMethodDocument, baseOptions)
}
export type CreateStockOwnerPickConfirmMethodMutationHookResult = ReturnType<
  typeof useCreateStockOwnerPickConfirmMethodMutation
>
export type CreateStockOwnerPickConfirmMethodMutationResult = ApolloReactCommon.MutationResult<
  CreateStockOwnerPickConfirmMethodMutation
>
export type CreateStockOwnerPickConfirmMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateStockOwnerPickConfirmMethodMutation,
  CreateStockOwnerPickConfirmMethodMutationVariables
>
export const DeleteStockOwnerPickConfirmMethodDocument = gql`
  mutation deleteStockOwnerPickConfirmMethod($stockOwnerId: Int!, $id: Int!) {
    deleteStockOwnerPickConfirmMethod(stockOwnerId: $stockOwnerId, id: $id) {
      id
    }
  }
`
export type DeleteStockOwnerPickConfirmMethodMutationFn = ApolloReactCommon.MutationFunction<
  DeleteStockOwnerPickConfirmMethodMutation,
  DeleteStockOwnerPickConfirmMethodMutationVariables
>
export type DeleteStockOwnerPickConfirmMethodComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteStockOwnerPickConfirmMethodMutation,
    DeleteStockOwnerPickConfirmMethodMutationVariables
  >,
  'mutation'
>

export const DeleteStockOwnerPickConfirmMethodComponent = (
  props: DeleteStockOwnerPickConfirmMethodComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteStockOwnerPickConfirmMethodMutation,
    DeleteStockOwnerPickConfirmMethodMutationVariables
  >
    mutation={DeleteStockOwnerPickConfirmMethodDocument}
    {...props}
  />
)

export type DeleteStockOwnerPickConfirmMethodProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteStockOwnerPickConfirmMethodMutation,
  DeleteStockOwnerPickConfirmMethodMutationVariables
> &
  TChildProps
export function withDeleteStockOwnerPickConfirmMethod<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteStockOwnerPickConfirmMethodMutation,
    DeleteStockOwnerPickConfirmMethodMutationVariables,
    DeleteStockOwnerPickConfirmMethodProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteStockOwnerPickConfirmMethodMutation,
    DeleteStockOwnerPickConfirmMethodMutationVariables,
    DeleteStockOwnerPickConfirmMethodProps<TChildProps>
  >(DeleteStockOwnerPickConfirmMethodDocument, {
    alias: 'deleteStockOwnerPickConfirmMethod',
    ...operationOptions
  })
}

/**
 * __useDeleteStockOwnerPickConfirmMethodMutation__
 *
 * To run a mutation, you first call `useDeleteStockOwnerPickConfirmMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStockOwnerPickConfirmMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStockOwnerPickConfirmMethodMutation, { data, loading, error }] = useDeleteStockOwnerPickConfirmMethodMutation({
 *   variables: {
 *      stockOwnerId: // value for 'stockOwnerId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStockOwnerPickConfirmMethodMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteStockOwnerPickConfirmMethodMutation,
    DeleteStockOwnerPickConfirmMethodMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteStockOwnerPickConfirmMethodMutation,
    DeleteStockOwnerPickConfirmMethodMutationVariables
  >(DeleteStockOwnerPickConfirmMethodDocument, baseOptions)
}
export type DeleteStockOwnerPickConfirmMethodMutationHookResult = ReturnType<
  typeof useDeleteStockOwnerPickConfirmMethodMutation
>
export type DeleteStockOwnerPickConfirmMethodMutationResult = ApolloReactCommon.MutationResult<
  DeleteStockOwnerPickConfirmMethodMutation
>
export type DeleteStockOwnerPickConfirmMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteStockOwnerPickConfirmMethodMutation,
  DeleteStockOwnerPickConfirmMethodMutationVariables
>
export const UpdateOrderStatusDocument = gql`
  mutation updateOrderStatus($id: Int!, $stockOwnerId: Int!, $input: OrderStatusUpdateInput) {
    updateOrderStatus(id: $id, stockOwnerId: $stockOwnerId, input: $input) {
      ...orderStatus
    }
  }
  ${OrderStatusFragmentDoc}
`
export type UpdateOrderStatusMutationFn = ApolloReactCommon.MutationFunction<
  UpdateOrderStatusMutation,
  UpdateOrderStatusMutationVariables
>
export type UpdateOrderStatusComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateOrderStatusMutation,
    UpdateOrderStatusMutationVariables
  >,
  'mutation'
>

export const UpdateOrderStatusComponent = (props: UpdateOrderStatusComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateOrderStatusMutation, UpdateOrderStatusMutationVariables>
    mutation={UpdateOrderStatusDocument}
    {...props}
  />
)

export type UpdateOrderStatusProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateOrderStatusMutation,
  UpdateOrderStatusMutationVariables
> &
  TChildProps
export function withUpdateOrderStatus<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateOrderStatusMutation,
    UpdateOrderStatusMutationVariables,
    UpdateOrderStatusProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateOrderStatusMutation,
    UpdateOrderStatusMutationVariables,
    UpdateOrderStatusProps<TChildProps>
  >(UpdateOrderStatusDocument, {
    alias: 'updateOrderStatus',
    ...operationOptions
  })
}

/**
 * __useUpdateOrderStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderStatusMutation, { data, loading, error }] = useUpdateOrderStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      stockOwnerId: // value for 'stockOwnerId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrderStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOrderStatusMutation,
    UpdateOrderStatusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateOrderStatusMutation,
    UpdateOrderStatusMutationVariables
  >(UpdateOrderStatusDocument, baseOptions)
}
export type UpdateOrderStatusMutationHookResult = ReturnType<typeof useUpdateOrderStatusMutation>
export type UpdateOrderStatusMutationResult = ApolloReactCommon.MutationResult<
  UpdateOrderStatusMutation
>
export type UpdateOrderStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateOrderStatusMutation,
  UpdateOrderStatusMutationVariables
>
export const CreateOrderStatusDocument = gql`
  mutation createOrderStatus($stockOwnerId: Int!, $input: OrderStatusInput) {
    createOrderStatus(stockOwnerId: $stockOwnerId, input: $input) {
      ...orderStatus
    }
  }
  ${OrderStatusFragmentDoc}
`
export type CreateOrderStatusMutationFn = ApolloReactCommon.MutationFunction<
  CreateOrderStatusMutation,
  CreateOrderStatusMutationVariables
>
export type CreateOrderStatusComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateOrderStatusMutation,
    CreateOrderStatusMutationVariables
  >,
  'mutation'
>

export const CreateOrderStatusComponent = (props: CreateOrderStatusComponentProps) => (
  <ApolloReactComponents.Mutation<CreateOrderStatusMutation, CreateOrderStatusMutationVariables>
    mutation={CreateOrderStatusDocument}
    {...props}
  />
)

export type CreateOrderStatusProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateOrderStatusMutation,
  CreateOrderStatusMutationVariables
> &
  TChildProps
export function withCreateOrderStatus<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateOrderStatusMutation,
    CreateOrderStatusMutationVariables,
    CreateOrderStatusProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateOrderStatusMutation,
    CreateOrderStatusMutationVariables,
    CreateOrderStatusProps<TChildProps>
  >(CreateOrderStatusDocument, {
    alias: 'createOrderStatus',
    ...operationOptions
  })
}

/**
 * __useCreateOrderStatusMutation__
 *
 * To run a mutation, you first call `useCreateOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderStatusMutation, { data, loading, error }] = useCreateOrderStatusMutation({
 *   variables: {
 *      stockOwnerId: // value for 'stockOwnerId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrderStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOrderStatusMutation,
    CreateOrderStatusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateOrderStatusMutation,
    CreateOrderStatusMutationVariables
  >(CreateOrderStatusDocument, baseOptions)
}
export type CreateOrderStatusMutationHookResult = ReturnType<typeof useCreateOrderStatusMutation>
export type CreateOrderStatusMutationResult = ApolloReactCommon.MutationResult<
  CreateOrderStatusMutation
>
export type CreateOrderStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateOrderStatusMutation,
  CreateOrderStatusMutationVariables
>
export const DeleteOrderStatusDocument = gql`
  mutation deleteOrderStatus($stockOwnerId: Int!, $id: Int!) {
    deleteOrderStatus(stockOwnerId: $stockOwnerId, id: $id) {
      ...orderStatus
    }
  }
  ${OrderStatusFragmentDoc}
`
export type DeleteOrderStatusMutationFn = ApolloReactCommon.MutationFunction<
  DeleteOrderStatusMutation,
  DeleteOrderStatusMutationVariables
>
export type DeleteOrderStatusComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteOrderStatusMutation,
    DeleteOrderStatusMutationVariables
  >,
  'mutation'
>

export const DeleteOrderStatusComponent = (props: DeleteOrderStatusComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteOrderStatusMutation, DeleteOrderStatusMutationVariables>
    mutation={DeleteOrderStatusDocument}
    {...props}
  />
)

export type DeleteOrderStatusProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteOrderStatusMutation,
  DeleteOrderStatusMutationVariables
> &
  TChildProps
export function withDeleteOrderStatus<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteOrderStatusMutation,
    DeleteOrderStatusMutationVariables,
    DeleteOrderStatusProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteOrderStatusMutation,
    DeleteOrderStatusMutationVariables,
    DeleteOrderStatusProps<TChildProps>
  >(DeleteOrderStatusDocument, {
    alias: 'deleteOrderStatus',
    ...operationOptions
  })
}

/**
 * __useDeleteOrderStatusMutation__
 *
 * To run a mutation, you first call `useDeleteOrderStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrderStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrderStatusMutation, { data, loading, error }] = useDeleteOrderStatusMutation({
 *   variables: {
 *      stockOwnerId: // value for 'stockOwnerId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrderStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteOrderStatusMutation,
    DeleteOrderStatusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteOrderStatusMutation,
    DeleteOrderStatusMutationVariables
  >(DeleteOrderStatusDocument, baseOptions)
}
export type DeleteOrderStatusMutationHookResult = ReturnType<typeof useDeleteOrderStatusMutation>
export type DeleteOrderStatusMutationResult = ApolloReactCommon.MutationResult<
  DeleteOrderStatusMutation
>
export type DeleteOrderStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteOrderStatusMutation,
  DeleteOrderStatusMutationVariables
>
export const UpdateStockOwnerPickMethodDocument = gql`
  mutation updateStockOwnerPickMethod(
    $id: Int!
    $stockOwnerId: Int!
    $input: PickMethodUpdateInput
  ) {
    updateStockOwnerPickMethod(id: $id, stockOwnerId: $stockOwnerId, input: $input) {
      ...pickMethod
    }
  }
  ${PickMethodFragmentDoc}
`
export type UpdateStockOwnerPickMethodMutationFn = ApolloReactCommon.MutationFunction<
  UpdateStockOwnerPickMethodMutation,
  UpdateStockOwnerPickMethodMutationVariables
>
export type UpdateStockOwnerPickMethodComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateStockOwnerPickMethodMutation,
    UpdateStockOwnerPickMethodMutationVariables
  >,
  'mutation'
>

export const UpdateStockOwnerPickMethodComponent = (
  props: UpdateStockOwnerPickMethodComponentProps
) => (
  <ApolloReactComponents.Mutation<
    UpdateStockOwnerPickMethodMutation,
    UpdateStockOwnerPickMethodMutationVariables
  >
    mutation={UpdateStockOwnerPickMethodDocument}
    {...props}
  />
)

export type UpdateStockOwnerPickMethodProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateStockOwnerPickMethodMutation,
  UpdateStockOwnerPickMethodMutationVariables
> &
  TChildProps
export function withUpdateStockOwnerPickMethod<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateStockOwnerPickMethodMutation,
    UpdateStockOwnerPickMethodMutationVariables,
    UpdateStockOwnerPickMethodProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateStockOwnerPickMethodMutation,
    UpdateStockOwnerPickMethodMutationVariables,
    UpdateStockOwnerPickMethodProps<TChildProps>
  >(UpdateStockOwnerPickMethodDocument, {
    alias: 'updateStockOwnerPickMethod',
    ...operationOptions
  })
}

/**
 * __useUpdateStockOwnerPickMethodMutation__
 *
 * To run a mutation, you first call `useUpdateStockOwnerPickMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockOwnerPickMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockOwnerPickMethodMutation, { data, loading, error }] = useUpdateStockOwnerPickMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *      stockOwnerId: // value for 'stockOwnerId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStockOwnerPickMethodMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateStockOwnerPickMethodMutation,
    UpdateStockOwnerPickMethodMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateStockOwnerPickMethodMutation,
    UpdateStockOwnerPickMethodMutationVariables
  >(UpdateStockOwnerPickMethodDocument, baseOptions)
}
export type UpdateStockOwnerPickMethodMutationHookResult = ReturnType<
  typeof useUpdateStockOwnerPickMethodMutation
>
export type UpdateStockOwnerPickMethodMutationResult = ApolloReactCommon.MutationResult<
  UpdateStockOwnerPickMethodMutation
>
export type UpdateStockOwnerPickMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateStockOwnerPickMethodMutation,
  UpdateStockOwnerPickMethodMutationVariables
>
export const CreateStockOwnerPickMethodDocument = gql`
  mutation createStockOwnerPickMethod($stockOwnerId: Int!, $input: PickMethodInput) {
    createStockOwnerPickMethod(stockOwnerId: $stockOwnerId, input: $input) {
      ...pickMethod
    }
  }
  ${PickMethodFragmentDoc}
`
export type CreateStockOwnerPickMethodMutationFn = ApolloReactCommon.MutationFunction<
  CreateStockOwnerPickMethodMutation,
  CreateStockOwnerPickMethodMutationVariables
>
export type CreateStockOwnerPickMethodComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateStockOwnerPickMethodMutation,
    CreateStockOwnerPickMethodMutationVariables
  >,
  'mutation'
>

export const CreateStockOwnerPickMethodComponent = (
  props: CreateStockOwnerPickMethodComponentProps
) => (
  <ApolloReactComponents.Mutation<
    CreateStockOwnerPickMethodMutation,
    CreateStockOwnerPickMethodMutationVariables
  >
    mutation={CreateStockOwnerPickMethodDocument}
    {...props}
  />
)

export type CreateStockOwnerPickMethodProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateStockOwnerPickMethodMutation,
  CreateStockOwnerPickMethodMutationVariables
> &
  TChildProps
export function withCreateStockOwnerPickMethod<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateStockOwnerPickMethodMutation,
    CreateStockOwnerPickMethodMutationVariables,
    CreateStockOwnerPickMethodProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateStockOwnerPickMethodMutation,
    CreateStockOwnerPickMethodMutationVariables,
    CreateStockOwnerPickMethodProps<TChildProps>
  >(CreateStockOwnerPickMethodDocument, {
    alias: 'createStockOwnerPickMethod',
    ...operationOptions
  })
}

/**
 * __useCreateStockOwnerPickMethodMutation__
 *
 * To run a mutation, you first call `useCreateStockOwnerPickMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockOwnerPickMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockOwnerPickMethodMutation, { data, loading, error }] = useCreateStockOwnerPickMethodMutation({
 *   variables: {
 *      stockOwnerId: // value for 'stockOwnerId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStockOwnerPickMethodMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateStockOwnerPickMethodMutation,
    CreateStockOwnerPickMethodMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateStockOwnerPickMethodMutation,
    CreateStockOwnerPickMethodMutationVariables
  >(CreateStockOwnerPickMethodDocument, baseOptions)
}
export type CreateStockOwnerPickMethodMutationHookResult = ReturnType<
  typeof useCreateStockOwnerPickMethodMutation
>
export type CreateStockOwnerPickMethodMutationResult = ApolloReactCommon.MutationResult<
  CreateStockOwnerPickMethodMutation
>
export type CreateStockOwnerPickMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateStockOwnerPickMethodMutation,
  CreateStockOwnerPickMethodMutationVariables
>
export const DeleteStockOwnerPickMethodDocument = gql`
  mutation deleteStockOwnerPickMethod($stockOwnerId: Int!, $id: Int!) {
    deleteStockOwnerPickMethod(stockOwnerId: $stockOwnerId, id: $id) {
      id
    }
  }
`
export type DeleteStockOwnerPickMethodMutationFn = ApolloReactCommon.MutationFunction<
  DeleteStockOwnerPickMethodMutation,
  DeleteStockOwnerPickMethodMutationVariables
>
export type DeleteStockOwnerPickMethodComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteStockOwnerPickMethodMutation,
    DeleteStockOwnerPickMethodMutationVariables
  >,
  'mutation'
>

export const DeleteStockOwnerPickMethodComponent = (
  props: DeleteStockOwnerPickMethodComponentProps
) => (
  <ApolloReactComponents.Mutation<
    DeleteStockOwnerPickMethodMutation,
    DeleteStockOwnerPickMethodMutationVariables
  >
    mutation={DeleteStockOwnerPickMethodDocument}
    {...props}
  />
)

export type DeleteStockOwnerPickMethodProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteStockOwnerPickMethodMutation,
  DeleteStockOwnerPickMethodMutationVariables
> &
  TChildProps
export function withDeleteStockOwnerPickMethod<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteStockOwnerPickMethodMutation,
    DeleteStockOwnerPickMethodMutationVariables,
    DeleteStockOwnerPickMethodProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteStockOwnerPickMethodMutation,
    DeleteStockOwnerPickMethodMutationVariables,
    DeleteStockOwnerPickMethodProps<TChildProps>
  >(DeleteStockOwnerPickMethodDocument, {
    alias: 'deleteStockOwnerPickMethod',
    ...operationOptions
  })
}

/**
 * __useDeleteStockOwnerPickMethodMutation__
 *
 * To run a mutation, you first call `useDeleteStockOwnerPickMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStockOwnerPickMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStockOwnerPickMethodMutation, { data, loading, error }] = useDeleteStockOwnerPickMethodMutation({
 *   variables: {
 *      stockOwnerId: // value for 'stockOwnerId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStockOwnerPickMethodMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteStockOwnerPickMethodMutation,
    DeleteStockOwnerPickMethodMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteStockOwnerPickMethodMutation,
    DeleteStockOwnerPickMethodMutationVariables
  >(DeleteStockOwnerPickMethodDocument, baseOptions)
}
export type DeleteStockOwnerPickMethodMutationHookResult = ReturnType<
  typeof useDeleteStockOwnerPickMethodMutation
>
export type DeleteStockOwnerPickMethodMutationResult = ApolloReactCommon.MutationResult<
  DeleteStockOwnerPickMethodMutation
>
export type DeleteStockOwnerPickMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteStockOwnerPickMethodMutation,
  DeleteStockOwnerPickMethodMutationVariables
>
export const UpdateProductStatusDocument = gql`
  mutation updateProductStatus($id: Int!, $stockOwnerId: Int!, $input: ProductStatusUpdateInput) {
    updateProductStatus(id: $id, stockOwnerId: $stockOwnerId, input: $input) {
      ...productStatuses
    }
  }
  ${ProductStatusesFragmentDoc}
`
export type UpdateProductStatusMutationFn = ApolloReactCommon.MutationFunction<
  UpdateProductStatusMutation,
  UpdateProductStatusMutationVariables
>
export type UpdateProductStatusComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateProductStatusMutation,
    UpdateProductStatusMutationVariables
  >,
  'mutation'
>

export const UpdateProductStatusComponent = (props: UpdateProductStatusComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateProductStatusMutation, UpdateProductStatusMutationVariables>
    mutation={UpdateProductStatusDocument}
    {...props}
  />
)

export type UpdateProductStatusProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateProductStatusMutation,
  UpdateProductStatusMutationVariables
> &
  TChildProps
export function withUpdateProductStatus<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateProductStatusMutation,
    UpdateProductStatusMutationVariables,
    UpdateProductStatusProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateProductStatusMutation,
    UpdateProductStatusMutationVariables,
    UpdateProductStatusProps<TChildProps>
  >(UpdateProductStatusDocument, {
    alias: 'updateProductStatus',
    ...operationOptions
  })
}

/**
 * __useUpdateProductStatusMutation__
 *
 * To run a mutation, you first call `useUpdateProductStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductStatusMutation, { data, loading, error }] = useUpdateProductStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      stockOwnerId: // value for 'stockOwnerId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateProductStatusMutation,
    UpdateProductStatusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateProductStatusMutation,
    UpdateProductStatusMutationVariables
  >(UpdateProductStatusDocument, baseOptions)
}
export type UpdateProductStatusMutationHookResult = ReturnType<
  typeof useUpdateProductStatusMutation
>
export type UpdateProductStatusMutationResult = ApolloReactCommon.MutationResult<
  UpdateProductStatusMutation
>
export type UpdateProductStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateProductStatusMutation,
  UpdateProductStatusMutationVariables
>
export const CreateProductStatusDocument = gql`
  mutation createProductStatus($stockOwnerId: Int!, $input: ProductStatusInput) {
    createProductStatus(stockOwnerId: $stockOwnerId, input: $input) {
      ...productStatuses
    }
  }
  ${ProductStatusesFragmentDoc}
`
export type CreateProductStatusMutationFn = ApolloReactCommon.MutationFunction<
  CreateProductStatusMutation,
  CreateProductStatusMutationVariables
>
export type CreateProductStatusComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateProductStatusMutation,
    CreateProductStatusMutationVariables
  >,
  'mutation'
>

export const CreateProductStatusComponent = (props: CreateProductStatusComponentProps) => (
  <ApolloReactComponents.Mutation<CreateProductStatusMutation, CreateProductStatusMutationVariables>
    mutation={CreateProductStatusDocument}
    {...props}
  />
)

export type CreateProductStatusProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateProductStatusMutation,
  CreateProductStatusMutationVariables
> &
  TChildProps
export function withCreateProductStatus<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateProductStatusMutation,
    CreateProductStatusMutationVariables,
    CreateProductStatusProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateProductStatusMutation,
    CreateProductStatusMutationVariables,
    CreateProductStatusProps<TChildProps>
  >(CreateProductStatusDocument, {
    alias: 'createProductStatus',
    ...operationOptions
  })
}

/**
 * __useCreateProductStatusMutation__
 *
 * To run a mutation, you first call `useCreateProductStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductStatusMutation, { data, loading, error }] = useCreateProductStatusMutation({
 *   variables: {
 *      stockOwnerId: // value for 'stockOwnerId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateProductStatusMutation,
    CreateProductStatusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateProductStatusMutation,
    CreateProductStatusMutationVariables
  >(CreateProductStatusDocument, baseOptions)
}
export type CreateProductStatusMutationHookResult = ReturnType<
  typeof useCreateProductStatusMutation
>
export type CreateProductStatusMutationResult = ApolloReactCommon.MutationResult<
  CreateProductStatusMutation
>
export type CreateProductStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateProductStatusMutation,
  CreateProductStatusMutationVariables
>
export const DeleteProductStatusDocument = gql`
  mutation deleteProductStatus($stockOwnerId: Int!, $id: Int!) {
    deleteProductStatus(stockOwnerId: $stockOwnerId, id: $id) {
      ...productStatuses
    }
  }
  ${ProductStatusesFragmentDoc}
`
export type DeleteProductStatusMutationFn = ApolloReactCommon.MutationFunction<
  DeleteProductStatusMutation,
  DeleteProductStatusMutationVariables
>
export type DeleteProductStatusComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteProductStatusMutation,
    DeleteProductStatusMutationVariables
  >,
  'mutation'
>

export const DeleteProductStatusComponent = (props: DeleteProductStatusComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteProductStatusMutation, DeleteProductStatusMutationVariables>
    mutation={DeleteProductStatusDocument}
    {...props}
  />
)

export type DeleteProductStatusProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteProductStatusMutation,
  DeleteProductStatusMutationVariables
> &
  TChildProps
export function withDeleteProductStatus<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteProductStatusMutation,
    DeleteProductStatusMutationVariables,
    DeleteProductStatusProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteProductStatusMutation,
    DeleteProductStatusMutationVariables,
    DeleteProductStatusProps<TChildProps>
  >(DeleteProductStatusDocument, {
    alias: 'deleteProductStatus',
    ...operationOptions
  })
}

/**
 * __useDeleteProductStatusMutation__
 *
 * To run a mutation, you first call `useDeleteProductStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductStatusMutation, { data, loading, error }] = useDeleteProductStatusMutation({
 *   variables: {
 *      stockOwnerId: // value for 'stockOwnerId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteProductStatusMutation,
    DeleteProductStatusMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteProductStatusMutation,
    DeleteProductStatusMutationVariables
  >(DeleteProductStatusDocument, baseOptions)
}
export type DeleteProductStatusMutationHookResult = ReturnType<
  typeof useDeleteProductStatusMutation
>
export type DeleteProductStatusMutationResult = ApolloReactCommon.MutationResult<
  DeleteProductStatusMutation
>
export type DeleteProductStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteProductStatusMutation,
  DeleteProductStatusMutationVariables
>
export const CreateStorageTypeDocument = gql`
  mutation createStorageType($input: StorageTypeInput, $userContext: UserContextInput) {
    createStorageType(input: $input, userContext: $userContext) {
      ...storageType
    }
  }
  ${StorageTypeFragmentDoc}
`
export type CreateStorageTypeMutationFn = ApolloReactCommon.MutationFunction<
  CreateStorageTypeMutation,
  CreateStorageTypeMutationVariables
>
export type CreateStorageTypeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateStorageTypeMutation,
    CreateStorageTypeMutationVariables
  >,
  'mutation'
>

export const CreateStorageTypeComponent = (props: CreateStorageTypeComponentProps) => (
  <ApolloReactComponents.Mutation<CreateStorageTypeMutation, CreateStorageTypeMutationVariables>
    mutation={CreateStorageTypeDocument}
    {...props}
  />
)

export type CreateStorageTypeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateStorageTypeMutation,
  CreateStorageTypeMutationVariables
> &
  TChildProps
export function withCreateStorageType<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateStorageTypeMutation,
    CreateStorageTypeMutationVariables,
    CreateStorageTypeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateStorageTypeMutation,
    CreateStorageTypeMutationVariables,
    CreateStorageTypeProps<TChildProps>
  >(CreateStorageTypeDocument, {
    alias: 'createStorageType',
    ...operationOptions
  })
}

/**
 * __useCreateStorageTypeMutation__
 *
 * To run a mutation, you first call `useCreateStorageTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStorageTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStorageTypeMutation, { data, loading, error }] = useCreateStorageTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useCreateStorageTypeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateStorageTypeMutation,
    CreateStorageTypeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateStorageTypeMutation,
    CreateStorageTypeMutationVariables
  >(CreateStorageTypeDocument, baseOptions)
}
export type CreateStorageTypeMutationHookResult = ReturnType<typeof useCreateStorageTypeMutation>
export type CreateStorageTypeMutationResult = ApolloReactCommon.MutationResult<
  CreateStorageTypeMutation
>
export type CreateStorageTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateStorageTypeMutation,
  CreateStorageTypeMutationVariables
>
export const UpdateStorageTypeDocument = gql`
  mutation updateStorageType(
    $id: Int!
    $input: StorageTypeUpdateInput
    $userContext: UserContextInput
  ) {
    updateStorageType(id: $id, input: $input, userContext: $userContext) {
      ...storageType
    }
  }
  ${StorageTypeFragmentDoc}
`
export type UpdateStorageTypeMutationFn = ApolloReactCommon.MutationFunction<
  UpdateStorageTypeMutation,
  UpdateStorageTypeMutationVariables
>
export type UpdateStorageTypeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateStorageTypeMutation,
    UpdateStorageTypeMutationVariables
  >,
  'mutation'
>

export const UpdateStorageTypeComponent = (props: UpdateStorageTypeComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateStorageTypeMutation, UpdateStorageTypeMutationVariables>
    mutation={UpdateStorageTypeDocument}
    {...props}
  />
)

export type UpdateStorageTypeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateStorageTypeMutation,
  UpdateStorageTypeMutationVariables
> &
  TChildProps
export function withUpdateStorageType<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateStorageTypeMutation,
    UpdateStorageTypeMutationVariables,
    UpdateStorageTypeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateStorageTypeMutation,
    UpdateStorageTypeMutationVariables,
    UpdateStorageTypeProps<TChildProps>
  >(UpdateStorageTypeDocument, {
    alias: 'updateStorageType',
    ...operationOptions
  })
}

/**
 * __useUpdateStorageTypeMutation__
 *
 * To run a mutation, you first call `useUpdateStorageTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStorageTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStorageTypeMutation, { data, loading, error }] = useUpdateStorageTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useUpdateStorageTypeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateStorageTypeMutation,
    UpdateStorageTypeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateStorageTypeMutation,
    UpdateStorageTypeMutationVariables
  >(UpdateStorageTypeDocument, baseOptions)
}
export type UpdateStorageTypeMutationHookResult = ReturnType<typeof useUpdateStorageTypeMutation>
export type UpdateStorageTypeMutationResult = ApolloReactCommon.MutationResult<
  UpdateStorageTypeMutation
>
export type UpdateStorageTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateStorageTypeMutation,
  UpdateStorageTypeMutationVariables
>
export const DeleteStorageTypeDocument = gql`
  mutation deleteStorageType($id: Int!, $userContext: UserContextInput) {
    deleteStorageType(id: $id, userContext: $userContext) {
      ...storageType
    }
  }
  ${StorageTypeFragmentDoc}
`
export type DeleteStorageTypeMutationFn = ApolloReactCommon.MutationFunction<
  DeleteStorageTypeMutation,
  DeleteStorageTypeMutationVariables
>
export type DeleteStorageTypeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteStorageTypeMutation,
    DeleteStorageTypeMutationVariables
  >,
  'mutation'
>

export const DeleteStorageTypeComponent = (props: DeleteStorageTypeComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteStorageTypeMutation, DeleteStorageTypeMutationVariables>
    mutation={DeleteStorageTypeDocument}
    {...props}
  />
)

export type DeleteStorageTypeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteStorageTypeMutation,
  DeleteStorageTypeMutationVariables
> &
  TChildProps
export function withDeleteStorageType<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteStorageTypeMutation,
    DeleteStorageTypeMutationVariables,
    DeleteStorageTypeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteStorageTypeMutation,
    DeleteStorageTypeMutationVariables,
    DeleteStorageTypeProps<TChildProps>
  >(DeleteStorageTypeDocument, {
    alias: 'deleteStorageType',
    ...operationOptions
  })
}

/**
 * __useDeleteStorageTypeMutation__
 *
 * To run a mutation, you first call `useDeleteStorageTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStorageTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStorageTypeMutation, { data, loading, error }] = useDeleteStorageTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useDeleteStorageTypeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteStorageTypeMutation,
    DeleteStorageTypeMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteStorageTypeMutation,
    DeleteStorageTypeMutationVariables
  >(DeleteStorageTypeDocument, baseOptions)
}
export type DeleteStorageTypeMutationHookResult = ReturnType<typeof useDeleteStorageTypeMutation>
export type DeleteStorageTypeMutationResult = ApolloReactCommon.MutationResult<
  DeleteStorageTypeMutation
>
export type DeleteStorageTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteStorageTypeMutation,
  DeleteStorageTypeMutationVariables
>
export const AddStorageTypeMheDocument = gql`
  mutation addStorageTypeMHE($id: Int!, $MHEId: Int!) {
    addStorageTypeMHE(id: $id, MHEId: $MHEId) {
      storageType {
        ...storageType
      }
    }
  }
  ${StorageTypeFragmentDoc}
`
export type AddStorageTypeMheMutationFn = ApolloReactCommon.MutationFunction<
  AddStorageTypeMheMutation,
  AddStorageTypeMheMutationVariables
>
export type AddStorageTypeMheComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddStorageTypeMheMutation,
    AddStorageTypeMheMutationVariables
  >,
  'mutation'
>

export const AddStorageTypeMheComponent = (props: AddStorageTypeMheComponentProps) => (
  <ApolloReactComponents.Mutation<AddStorageTypeMheMutation, AddStorageTypeMheMutationVariables>
    mutation={AddStorageTypeMheDocument}
    {...props}
  />
)

export type AddStorageTypeMheProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  AddStorageTypeMheMutation,
  AddStorageTypeMheMutationVariables
> &
  TChildProps
export function withAddStorageTypeMhe<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddStorageTypeMheMutation,
    AddStorageTypeMheMutationVariables,
    AddStorageTypeMheProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AddStorageTypeMheMutation,
    AddStorageTypeMheMutationVariables,
    AddStorageTypeMheProps<TChildProps>
  >(AddStorageTypeMheDocument, {
    alias: 'addStorageTypeMhe',
    ...operationOptions
  })
}

/**
 * __useAddStorageTypeMheMutation__
 *
 * To run a mutation, you first call `useAddStorageTypeMheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStorageTypeMheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStorageTypeMheMutation, { data, loading, error }] = useAddStorageTypeMheMutation({
 *   variables: {
 *      id: // value for 'id'
 *      MHEId: // value for 'MHEId'
 *   },
 * });
 */
export function useAddStorageTypeMheMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddStorageTypeMheMutation,
    AddStorageTypeMheMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    AddStorageTypeMheMutation,
    AddStorageTypeMheMutationVariables
  >(AddStorageTypeMheDocument, baseOptions)
}
export type AddStorageTypeMheMutationHookResult = ReturnType<typeof useAddStorageTypeMheMutation>
export type AddStorageTypeMheMutationResult = ApolloReactCommon.MutationResult<
  AddStorageTypeMheMutation
>
export type AddStorageTypeMheMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddStorageTypeMheMutation,
  AddStorageTypeMheMutationVariables
>
export const RemoveStorageTypeMheDocument = gql`
  mutation removeStorageTypeMHE($id: Int!, $MHEId: Int!) {
    removeStorageTypeMHE(id: $id, MHEId: $MHEId) {
      storageType {
        ...storageType
      }
    }
  }
  ${StorageTypeFragmentDoc}
`
export type RemoveStorageTypeMheMutationFn = ApolloReactCommon.MutationFunction<
  RemoveStorageTypeMheMutation,
  RemoveStorageTypeMheMutationVariables
>
export type RemoveStorageTypeMheComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RemoveStorageTypeMheMutation,
    RemoveStorageTypeMheMutationVariables
  >,
  'mutation'
>

export const RemoveStorageTypeMheComponent = (props: RemoveStorageTypeMheComponentProps) => (
  <ApolloReactComponents.Mutation<
    RemoveStorageTypeMheMutation,
    RemoveStorageTypeMheMutationVariables
  >
    mutation={RemoveStorageTypeMheDocument}
    {...props}
  />
)

export type RemoveStorageTypeMheProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  RemoveStorageTypeMheMutation,
  RemoveStorageTypeMheMutationVariables
> &
  TChildProps
export function withRemoveStorageTypeMhe<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RemoveStorageTypeMheMutation,
    RemoveStorageTypeMheMutationVariables,
    RemoveStorageTypeMheProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RemoveStorageTypeMheMutation,
    RemoveStorageTypeMheMutationVariables,
    RemoveStorageTypeMheProps<TChildProps>
  >(RemoveStorageTypeMheDocument, {
    alias: 'removeStorageTypeMhe',
    ...operationOptions
  })
}

/**
 * __useRemoveStorageTypeMheMutation__
 *
 * To run a mutation, you first call `useRemoveStorageTypeMheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStorageTypeMheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStorageTypeMheMutation, { data, loading, error }] = useRemoveStorageTypeMheMutation({
 *   variables: {
 *      id: // value for 'id'
 *      MHEId: // value for 'MHEId'
 *   },
 * });
 */
export function useRemoveStorageTypeMheMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveStorageTypeMheMutation,
    RemoveStorageTypeMheMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RemoveStorageTypeMheMutation,
    RemoveStorageTypeMheMutationVariables
  >(RemoveStorageTypeMheDocument, baseOptions)
}
export type RemoveStorageTypeMheMutationHookResult = ReturnType<
  typeof useRemoveStorageTypeMheMutation
>
export type RemoveStorageTypeMheMutationResult = ApolloReactCommon.MutationResult<
  RemoveStorageTypeMheMutation
>
export type RemoveStorageTypeMheMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveStorageTypeMheMutation,
  RemoveStorageTypeMheMutationVariables
>
export const CreateSupplierDocument = gql`
  mutation createSupplier($input: SupplierInput) {
    createSupplier(input: $input) {
      ...supplier
    }
  }
  ${SupplierFragmentDoc}
`
export type CreateSupplierMutationFn = ApolloReactCommon.MutationFunction<
  CreateSupplierMutation,
  CreateSupplierMutationVariables
>
export type CreateSupplierComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateSupplierMutation,
    CreateSupplierMutationVariables
  >,
  'mutation'
>

export const CreateSupplierComponent = (props: CreateSupplierComponentProps) => (
  <ApolloReactComponents.Mutation<CreateSupplierMutation, CreateSupplierMutationVariables>
    mutation={CreateSupplierDocument}
    {...props}
  />
)

export type CreateSupplierProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateSupplierMutation,
  CreateSupplierMutationVariables
> &
  TChildProps
export function withCreateSupplier<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateSupplierMutation,
    CreateSupplierMutationVariables,
    CreateSupplierProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateSupplierMutation,
    CreateSupplierMutationVariables,
    CreateSupplierProps<TChildProps>
  >(CreateSupplierDocument, {
    alias: 'createSupplier',
    ...operationOptions
  })
}

/**
 * __useCreateSupplierMutation__
 *
 * To run a mutation, you first call `useCreateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierMutation, { data, loading, error }] = useCreateSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSupplierMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateSupplierMutation,
    CreateSupplierMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateSupplierMutation, CreateSupplierMutationVariables>(
    CreateSupplierDocument,
    baseOptions
  )
}
export type CreateSupplierMutationHookResult = ReturnType<typeof useCreateSupplierMutation>
export type CreateSupplierMutationResult = ApolloReactCommon.MutationResult<CreateSupplierMutation>
export type CreateSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateSupplierMutation,
  CreateSupplierMutationVariables
>
export const UpdateSupplierDocument = gql`
  mutation updateSupplier($id: Int!, $input: SupplierUpdateInput) {
    updateSupplier(id: $id, update: $input) {
      ...supplier
    }
  }
  ${SupplierFragmentDoc}
`
export type UpdateSupplierMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSupplierMutation,
  UpdateSupplierMutationVariables
>
export type UpdateSupplierComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateSupplierMutation,
    UpdateSupplierMutationVariables
  >,
  'mutation'
>

export const UpdateSupplierComponent = (props: UpdateSupplierComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateSupplierMutation, UpdateSupplierMutationVariables>
    mutation={UpdateSupplierDocument}
    {...props}
  />
)

export type UpdateSupplierProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateSupplierMutation,
  UpdateSupplierMutationVariables
> &
  TChildProps
export function withUpdateSupplier<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateSupplierMutation,
    UpdateSupplierMutationVariables,
    UpdateSupplierProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateSupplierMutation,
    UpdateSupplierMutationVariables,
    UpdateSupplierProps<TChildProps>
  >(UpdateSupplierDocument, {
    alias: 'updateSupplier',
    ...operationOptions
  })
}

/**
 * __useUpdateSupplierMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierMutation, { data, loading, error }] = useUpdateSupplierMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSupplierMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSupplierMutation,
    UpdateSupplierMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateSupplierMutation, UpdateSupplierMutationVariables>(
    UpdateSupplierDocument,
    baseOptions
  )
}
export type UpdateSupplierMutationHookResult = ReturnType<typeof useUpdateSupplierMutation>
export type UpdateSupplierMutationResult = ApolloReactCommon.MutationResult<UpdateSupplierMutation>
export type UpdateSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSupplierMutation,
  UpdateSupplierMutationVariables
>
export const DeleteSupplierDocument = gql`
  mutation deleteSupplier($id: Int!) {
    deleteSupplier(id: $id) {
      ...supplier
    }
  }
  ${SupplierFragmentDoc}
`
export type DeleteSupplierMutationFn = ApolloReactCommon.MutationFunction<
  DeleteSupplierMutation,
  DeleteSupplierMutationVariables
>
export type DeleteSupplierComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteSupplierMutation,
    DeleteSupplierMutationVariables
  >,
  'mutation'
>

export const DeleteSupplierComponent = (props: DeleteSupplierComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteSupplierMutation, DeleteSupplierMutationVariables>
    mutation={DeleteSupplierDocument}
    {...props}
  />
)

export type DeleteSupplierProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteSupplierMutation,
  DeleteSupplierMutationVariables
> &
  TChildProps
export function withDeleteSupplier<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteSupplierMutation,
    DeleteSupplierMutationVariables,
    DeleteSupplierProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteSupplierMutation,
    DeleteSupplierMutationVariables,
    DeleteSupplierProps<TChildProps>
  >(DeleteSupplierDocument, {
    alias: 'deleteSupplier',
    ...operationOptions
  })
}

/**
 * __useDeleteSupplierMutation__
 *
 * To run a mutation, you first call `useDeleteSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSupplierMutation, { data, loading, error }] = useDeleteSupplierMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSupplierMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteSupplierMutation,
    DeleteSupplierMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteSupplierMutation, DeleteSupplierMutationVariables>(
    DeleteSupplierDocument,
    baseOptions
  )
}
export type DeleteSupplierMutationHookResult = ReturnType<typeof useDeleteSupplierMutation>
export type DeleteSupplierMutationResult = ApolloReactCommon.MutationResult<DeleteSupplierMutation>
export type DeleteSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteSupplierMutation,
  DeleteSupplierMutationVariables
>
export const AddUserDocument = gql`
  mutation addUser($input: UserInput) {
    addUser(input: $input) {
      __typename
      ... on WMSUser {
        id
        userName
      }
    }
  }
`
export type AddUserMutationFn = ApolloReactCommon.MutationFunction<
  AddUserMutation,
  AddUserMutationVariables
>
export type AddUserComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<AddUserMutation, AddUserMutationVariables>,
  'mutation'
>

export const AddUserComponent = (props: AddUserComponentProps) => (
  <ApolloReactComponents.Mutation<AddUserMutation, AddUserMutationVariables>
    mutation={AddUserDocument}
    {...props}
  />
)

export type AddUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  AddUserMutation,
  AddUserMutationVariables
> &
  TChildProps
export function withAddUser<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AddUserMutation,
    AddUserMutationVariables,
    AddUserProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    AddUserMutation,
    AddUserMutationVariables,
    AddUserProps<TChildProps>
  >(AddUserDocument, {
    alias: 'addUser',
    ...operationOptions
  })
}

/**
 * __useAddUserMutation__
 *
 * To run a mutation, you first call `useAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutation, { data, loading, error }] = useAddUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<AddUserMutation, AddUserMutationVariables>
) {
  return ApolloReactHooks.useMutation<AddUserMutation, AddUserMutationVariables>(
    AddUserDocument,
    baseOptions
  )
}
export type AddUserMutationHookResult = ReturnType<typeof useAddUserMutation>
export type AddUserMutationResult = ApolloReactCommon.MutationResult<AddUserMutation>
export type AddUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  AddUserMutation,
  AddUserMutationVariables
>
export const UpdateUserDocument = gql`
  mutation updateUser($id: String, $input: UserUpdateInput) {
    updateUser(id: $id, input: $input) {
      __typename
      ... on WMSUser {
        id
        userName
      }
      ... on ValidationError {
        messages
      }
      ... on DatabaseError {
        message
      }
    }
  }
`
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>
export type UpdateUserComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<UpdateUserMutation, UpdateUserMutationVariables>,
  'mutation'
>

export const UpdateUserComponent = (props: UpdateUserComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateUserMutation, UpdateUserMutationVariables>
    mutation={UpdateUserDocument}
    {...props}
  />
)

export type UpdateUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateUserMutation,
  UpdateUserMutationVariables
> &
  TChildProps
export function withUpdateUser<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUserMutation,
    UpdateUserMutationVariables,
    UpdateUserProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUserMutation,
    UpdateUserMutationVariables,
    UpdateUserProps<TChildProps>
  >(UpdateUserDocument, {
    alias: 'updateUser',
    ...operationOptions
  })
}

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    baseOptions
  )
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>
export const DeleteUserDocument = gql`
  mutation deleteUser($id: String) {
    deleteUser(id: $id) {
      __typename
      ... on WMSUser {
        id
        userName
      }
    }
  }
`
export type DeleteUserMutationFn = ApolloReactCommon.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>
export type DeleteUserComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<DeleteUserMutation, DeleteUserMutationVariables>,
  'mutation'
>

export const DeleteUserComponent = (props: DeleteUserComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteUserMutation, DeleteUserMutationVariables>
    mutation={DeleteUserDocument}
    {...props}
  />
)

export type DeleteUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteUserMutation,
  DeleteUserMutationVariables
> &
  TChildProps
export function withDeleteUser<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteUserMutation,
    DeleteUserMutationVariables,
    DeleteUserProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteUserMutation,
    DeleteUserMutationVariables,
    DeleteUserProps<TChildProps>
  >(DeleteUserDocument, {
    alias: 'deleteUser',
    ...operationOptions
  })
}

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    baseOptions
  )
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>
export type DeleteUserMutationResult = ApolloReactCommon.MutationResult<DeleteUserMutation>
export type DeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>
export const ChangePasswordDocument = gql`
  mutation changePassword($newPassword: String, $oldPassword: String) {
    changePassword(newPassword: $newPassword, oldPassword: $oldPassword) {
      __typename
      ... on WMSUser {
        userName
      }
      ... on ValidationError {
        field
        messages
      }
      ... on DatabaseError {
        message
      }
    }
  }
`
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>
export type ChangePasswordComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >,
  'mutation'
>

export const ChangePasswordComponent = (props: ChangePasswordComponentProps) => (
  <ApolloReactComponents.Mutation<ChangePasswordMutation, ChangePasswordMutationVariables>
    mutation={ChangePasswordDocument}
    {...props}
  />
)

export type ChangePasswordProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
> &
  TChildProps
export function withChangePassword<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ChangePasswordMutation,
    ChangePasswordMutationVariables,
    ChangePasswordProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    ChangePasswordMutation,
    ChangePasswordMutationVariables,
    ChangePasswordProps<TChildProps>
  >(ChangePasswordDocument, {
    alias: 'changePassword',
    ...operationOptions
  })
}

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      oldPassword: // value for 'oldPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangePasswordMutation,
    ChangePasswordMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
    ChangePasswordDocument,
    baseOptions
  )
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>
export const CreateUserPermissionDocument = gql`
  mutation createUserPermission($input: UserPermissionInput) {
    createUserPermission(input: $input) {
      id
    }
  }
`
export type CreateUserPermissionMutationFn = ApolloReactCommon.MutationFunction<
  CreateUserPermissionMutation,
  CreateUserPermissionMutationVariables
>
export type CreateUserPermissionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateUserPermissionMutation,
    CreateUserPermissionMutationVariables
  >,
  'mutation'
>

export const CreateUserPermissionComponent = (props: CreateUserPermissionComponentProps) => (
  <ApolloReactComponents.Mutation<
    CreateUserPermissionMutation,
    CreateUserPermissionMutationVariables
  >
    mutation={CreateUserPermissionDocument}
    {...props}
  />
)

export type CreateUserPermissionProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateUserPermissionMutation,
  CreateUserPermissionMutationVariables
> &
  TChildProps
export function withCreateUserPermission<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateUserPermissionMutation,
    CreateUserPermissionMutationVariables,
    CreateUserPermissionProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateUserPermissionMutation,
    CreateUserPermissionMutationVariables,
    CreateUserPermissionProps<TChildProps>
  >(CreateUserPermissionDocument, {
    alias: 'createUserPermission',
    ...operationOptions
  })
}

/**
 * __useCreateUserPermissionMutation__
 *
 * To run a mutation, you first call `useCreateUserPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserPermissionMutation, { data, loading, error }] = useCreateUserPermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserPermissionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateUserPermissionMutation,
    CreateUserPermissionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateUserPermissionMutation,
    CreateUserPermissionMutationVariables
  >(CreateUserPermissionDocument, baseOptions)
}
export type CreateUserPermissionMutationHookResult = ReturnType<
  typeof useCreateUserPermissionMutation
>
export type CreateUserPermissionMutationResult = ApolloReactCommon.MutationResult<
  CreateUserPermissionMutation
>
export type CreateUserPermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateUserPermissionMutation,
  CreateUserPermissionMutationVariables
>
export const UpdateUserPermissionDocument = gql`
  mutation updateUserPermission(
    $id: Int!
    $input: UserPermissionUpdateInput
    $userContext: UserContextInput
  ) {
    updateUserPermission(id: $id, update: $input, userContext: $userContext) {
      id
    }
  }
`
export type UpdateUserPermissionMutationFn = ApolloReactCommon.MutationFunction<
  UpdateUserPermissionMutation,
  UpdateUserPermissionMutationVariables
>
export type UpdateUserPermissionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateUserPermissionMutation,
    UpdateUserPermissionMutationVariables
  >,
  'mutation'
>

export const UpdateUserPermissionComponent = (props: UpdateUserPermissionComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateUserPermissionMutation,
    UpdateUserPermissionMutationVariables
  >
    mutation={UpdateUserPermissionDocument}
    {...props}
  />
)

export type UpdateUserPermissionProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateUserPermissionMutation,
  UpdateUserPermissionMutationVariables
> &
  TChildProps
export function withUpdateUserPermission<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateUserPermissionMutation,
    UpdateUserPermissionMutationVariables,
    UpdateUserPermissionProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateUserPermissionMutation,
    UpdateUserPermissionMutationVariables,
    UpdateUserPermissionProps<TChildProps>
  >(UpdateUserPermissionDocument, {
    alias: 'updateUserPermission',
    ...operationOptions
  })
}

/**
 * __useUpdateUserPermissionMutation__
 *
 * To run a mutation, you first call `useUpdateUserPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPermissionMutation, { data, loading, error }] = useUpdateUserPermissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useUpdateUserPermissionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateUserPermissionMutation,
    UpdateUserPermissionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateUserPermissionMutation,
    UpdateUserPermissionMutationVariables
  >(UpdateUserPermissionDocument, baseOptions)
}
export type UpdateUserPermissionMutationHookResult = ReturnType<
  typeof useUpdateUserPermissionMutation
>
export type UpdateUserPermissionMutationResult = ApolloReactCommon.MutationResult<
  UpdateUserPermissionMutation
>
export type UpdateUserPermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateUserPermissionMutation,
  UpdateUserPermissionMutationVariables
>
export const DeleteUserPermissionDocument = gql`
  mutation deleteUserPermission($id: Int!, $userContext: UserContextInput) {
    deleteUserPermission(id: $id, userContext: $userContext) {
      id
    }
  }
`
export type DeleteUserPermissionMutationFn = ApolloReactCommon.MutationFunction<
  DeleteUserPermissionMutation,
  DeleteUserPermissionMutationVariables
>
export type DeleteUserPermissionComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteUserPermissionMutation,
    DeleteUserPermissionMutationVariables
  >,
  'mutation'
>

export const DeleteUserPermissionComponent = (props: DeleteUserPermissionComponentProps) => (
  <ApolloReactComponents.Mutation<
    DeleteUserPermissionMutation,
    DeleteUserPermissionMutationVariables
  >
    mutation={DeleteUserPermissionDocument}
    {...props}
  />
)

export type DeleteUserPermissionProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteUserPermissionMutation,
  DeleteUserPermissionMutationVariables
> &
  TChildProps
export function withDeleteUserPermission<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteUserPermissionMutation,
    DeleteUserPermissionMutationVariables,
    DeleteUserPermissionProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteUserPermissionMutation,
    DeleteUserPermissionMutationVariables,
    DeleteUserPermissionProps<TChildProps>
  >(DeleteUserPermissionDocument, {
    alias: 'deleteUserPermission',
    ...operationOptions
  })
}

/**
 * __useDeleteUserPermissionMutation__
 *
 * To run a mutation, you first call `useDeleteUserPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserPermissionMutation, { data, loading, error }] = useDeleteUserPermissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useDeleteUserPermissionMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteUserPermissionMutation,
    DeleteUserPermissionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteUserPermissionMutation,
    DeleteUserPermissionMutationVariables
  >(DeleteUserPermissionDocument, baseOptions)
}
export type DeleteUserPermissionMutationHookResult = ReturnType<
  typeof useDeleteUserPermissionMutation
>
export type DeleteUserPermissionMutationResult = ApolloReactCommon.MutationResult<
  DeleteUserPermissionMutation
>
export type DeleteUserPermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteUserPermissionMutation,
  DeleteUserPermissionMutationVariables
>
export const CreateWarehouseDocument = gql`
  mutation createWarehouse($input: WarehouseInput) {
    createWarehouse(input: $input) {
      ...warehouse
      address {
        ...address
      }
    }
  }
  ${WarehouseFragmentDoc}
  ${AddressFragmentDoc}
`
export type CreateWarehouseMutationFn = ApolloReactCommon.MutationFunction<
  CreateWarehouseMutation,
  CreateWarehouseMutationVariables
>
export type CreateWarehouseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateWarehouseMutation,
    CreateWarehouseMutationVariables
  >,
  'mutation'
>

export const CreateWarehouseComponent = (props: CreateWarehouseComponentProps) => (
  <ApolloReactComponents.Mutation<CreateWarehouseMutation, CreateWarehouseMutationVariables>
    mutation={CreateWarehouseDocument}
    {...props}
  />
)

export type CreateWarehouseProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateWarehouseMutation,
  CreateWarehouseMutationVariables
> &
  TChildProps
export function withCreateWarehouse<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateWarehouseMutation,
    CreateWarehouseMutationVariables,
    CreateWarehouseProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateWarehouseMutation,
    CreateWarehouseMutationVariables,
    CreateWarehouseProps<TChildProps>
  >(CreateWarehouseDocument, {
    alias: 'createWarehouse',
    ...operationOptions
  })
}

/**
 * __useCreateWarehouseMutation__
 *
 * To run a mutation, you first call `useCreateWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWarehouseMutation, { data, loading, error }] = useCreateWarehouseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWarehouseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateWarehouseMutation,
    CreateWarehouseMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<CreateWarehouseMutation, CreateWarehouseMutationVariables>(
    CreateWarehouseDocument,
    baseOptions
  )
}
export type CreateWarehouseMutationHookResult = ReturnType<typeof useCreateWarehouseMutation>
export type CreateWarehouseMutationResult = ApolloReactCommon.MutationResult<
  CreateWarehouseMutation
>
export type CreateWarehouseMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateWarehouseMutation,
  CreateWarehouseMutationVariables
>
export const UpdateWarehouseDocument = gql`
  mutation updateWarehouse($id: Int!, $input: WarehouseUpdateInput) {
    updateWarehouse(id: $id, input: $input) {
      ...warehouse
      address {
        ...address
      }
    }
  }
  ${WarehouseFragmentDoc}
  ${AddressFragmentDoc}
`
export type UpdateWarehouseMutationFn = ApolloReactCommon.MutationFunction<
  UpdateWarehouseMutation,
  UpdateWarehouseMutationVariables
>
export type UpdateWarehouseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateWarehouseMutation,
    UpdateWarehouseMutationVariables
  >,
  'mutation'
>

export const UpdateWarehouseComponent = (props: UpdateWarehouseComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateWarehouseMutation, UpdateWarehouseMutationVariables>
    mutation={UpdateWarehouseDocument}
    {...props}
  />
)

export type UpdateWarehouseProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateWarehouseMutation,
  UpdateWarehouseMutationVariables
> &
  TChildProps
export function withUpdateWarehouse<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateWarehouseMutation,
    UpdateWarehouseMutationVariables,
    UpdateWarehouseProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateWarehouseMutation,
    UpdateWarehouseMutationVariables,
    UpdateWarehouseProps<TChildProps>
  >(UpdateWarehouseDocument, {
    alias: 'updateWarehouse',
    ...operationOptions
  })
}

/**
 * __useUpdateWarehouseMutation__
 *
 * To run a mutation, you first call `useUpdateWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWarehouseMutation, { data, loading, error }] = useUpdateWarehouseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWarehouseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateWarehouseMutation,
    UpdateWarehouseMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<UpdateWarehouseMutation, UpdateWarehouseMutationVariables>(
    UpdateWarehouseDocument,
    baseOptions
  )
}
export type UpdateWarehouseMutationHookResult = ReturnType<typeof useUpdateWarehouseMutation>
export type UpdateWarehouseMutationResult = ApolloReactCommon.MutationResult<
  UpdateWarehouseMutation
>
export type UpdateWarehouseMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateWarehouseMutation,
  UpdateWarehouseMutationVariables
>
export const DeleteWarehouseDocument = gql`
  mutation deleteWarehouse($id: Int!) {
    deleteWarehouse(id: $id) {
      ...warehouse
    }
  }
  ${WarehouseFragmentDoc}
`
export type DeleteWarehouseMutationFn = ApolloReactCommon.MutationFunction<
  DeleteWarehouseMutation,
  DeleteWarehouseMutationVariables
>
export type DeleteWarehouseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteWarehouseMutation,
    DeleteWarehouseMutationVariables
  >,
  'mutation'
>

export const DeleteWarehouseComponent = (props: DeleteWarehouseComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteWarehouseMutation, DeleteWarehouseMutationVariables>
    mutation={DeleteWarehouseDocument}
    {...props}
  />
)

export type DeleteWarehouseProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteWarehouseMutation,
  DeleteWarehouseMutationVariables
> &
  TChildProps
export function withDeleteWarehouse<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteWarehouseMutation,
    DeleteWarehouseMutationVariables,
    DeleteWarehouseProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteWarehouseMutation,
    DeleteWarehouseMutationVariables,
    DeleteWarehouseProps<TChildProps>
  >(DeleteWarehouseDocument, {
    alias: 'deleteWarehouse',
    ...operationOptions
  })
}

/**
 * __useDeleteWarehouseMutation__
 *
 * To run a mutation, you first call `useDeleteWarehouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWarehouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWarehouseMutation, { data, loading, error }] = useDeleteWarehouseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWarehouseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteWarehouseMutation,
    DeleteWarehouseMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<DeleteWarehouseMutation, DeleteWarehouseMutationVariables>(
    DeleteWarehouseDocument,
    baseOptions
  )
}
export type DeleteWarehouseMutationHookResult = ReturnType<typeof useDeleteWarehouseMutation>
export type DeleteWarehouseMutationResult = ApolloReactCommon.MutationResult<
  DeleteWarehouseMutation
>
export type DeleteWarehouseMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteWarehouseMutation,
  DeleteWarehouseMutationVariables
>
export const CreateWarehouseZoneDocument = gql`
  mutation createWarehouseZone($input: WarehouseZoneInput, $userContext: UserContextInput) {
    createWarehouseZone(input: $input, userContext: $userContext) {
      ...warehouseZone
    }
  }
  ${WarehouseZoneFragmentDoc}
`
export type CreateWarehouseZoneMutationFn = ApolloReactCommon.MutationFunction<
  CreateWarehouseZoneMutation,
  CreateWarehouseZoneMutationVariables
>
export type CreateWarehouseZoneComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateWarehouseZoneMutation,
    CreateWarehouseZoneMutationVariables
  >,
  'mutation'
>

export const CreateWarehouseZoneComponent = (props: CreateWarehouseZoneComponentProps) => (
  <ApolloReactComponents.Mutation<CreateWarehouseZoneMutation, CreateWarehouseZoneMutationVariables>
    mutation={CreateWarehouseZoneDocument}
    {...props}
  />
)

export type CreateWarehouseZoneProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  CreateWarehouseZoneMutation,
  CreateWarehouseZoneMutationVariables
> &
  TChildProps
export function withCreateWarehouseZone<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CreateWarehouseZoneMutation,
    CreateWarehouseZoneMutationVariables,
    CreateWarehouseZoneProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    CreateWarehouseZoneMutation,
    CreateWarehouseZoneMutationVariables,
    CreateWarehouseZoneProps<TChildProps>
  >(CreateWarehouseZoneDocument, {
    alias: 'createWarehouseZone',
    ...operationOptions
  })
}

/**
 * __useCreateWarehouseZoneMutation__
 *
 * To run a mutation, you first call `useCreateWarehouseZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWarehouseZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWarehouseZoneMutation, { data, loading, error }] = useCreateWarehouseZoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useCreateWarehouseZoneMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateWarehouseZoneMutation,
    CreateWarehouseZoneMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    CreateWarehouseZoneMutation,
    CreateWarehouseZoneMutationVariables
  >(CreateWarehouseZoneDocument, baseOptions)
}
export type CreateWarehouseZoneMutationHookResult = ReturnType<
  typeof useCreateWarehouseZoneMutation
>
export type CreateWarehouseZoneMutationResult = ApolloReactCommon.MutationResult<
  CreateWarehouseZoneMutation
>
export type CreateWarehouseZoneMutationOptions = ApolloReactCommon.BaseMutationOptions<
  CreateWarehouseZoneMutation,
  CreateWarehouseZoneMutationVariables
>
export const UpdateWarehouseZoneDocument = gql`
  mutation updateWarehouseZone(
    $id: Int!
    $input: WarehouseZoneUpdateInput
    $userContext: UserContextInput
  ) {
    updateWarehouseZone(id: $id, update: $input, userContext: $userContext) {
      ...warehouseZone
    }
  }
  ${WarehouseZoneFragmentDoc}
`
export type UpdateWarehouseZoneMutationFn = ApolloReactCommon.MutationFunction<
  UpdateWarehouseZoneMutation,
  UpdateWarehouseZoneMutationVariables
>
export type UpdateWarehouseZoneComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateWarehouseZoneMutation,
    UpdateWarehouseZoneMutationVariables
  >,
  'mutation'
>

export const UpdateWarehouseZoneComponent = (props: UpdateWarehouseZoneComponentProps) => (
  <ApolloReactComponents.Mutation<UpdateWarehouseZoneMutation, UpdateWarehouseZoneMutationVariables>
    mutation={UpdateWarehouseZoneDocument}
    {...props}
  />
)

export type UpdateWarehouseZoneProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  UpdateWarehouseZoneMutation,
  UpdateWarehouseZoneMutationVariables
> &
  TChildProps
export function withUpdateWarehouseZone<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UpdateWarehouseZoneMutation,
    UpdateWarehouseZoneMutationVariables,
    UpdateWarehouseZoneProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    UpdateWarehouseZoneMutation,
    UpdateWarehouseZoneMutationVariables,
    UpdateWarehouseZoneProps<TChildProps>
  >(UpdateWarehouseZoneDocument, {
    alias: 'updateWarehouseZone',
    ...operationOptions
  })
}

/**
 * __useUpdateWarehouseZoneMutation__
 *
 * To run a mutation, you first call `useUpdateWarehouseZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWarehouseZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWarehouseZoneMutation, { data, loading, error }] = useUpdateWarehouseZoneMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useUpdateWarehouseZoneMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateWarehouseZoneMutation,
    UpdateWarehouseZoneMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    UpdateWarehouseZoneMutation,
    UpdateWarehouseZoneMutationVariables
  >(UpdateWarehouseZoneDocument, baseOptions)
}
export type UpdateWarehouseZoneMutationHookResult = ReturnType<
  typeof useUpdateWarehouseZoneMutation
>
export type UpdateWarehouseZoneMutationResult = ApolloReactCommon.MutationResult<
  UpdateWarehouseZoneMutation
>
export type UpdateWarehouseZoneMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateWarehouseZoneMutation,
  UpdateWarehouseZoneMutationVariables
>
export const DeleteWarehouseZoneDocument = gql`
  mutation deleteWarehouseZone($id: Int!, $userContext: UserContextInput) {
    deleteWarehouseZone(id: $id, userContext: $userContext) {
      id
    }
  }
`
export type DeleteWarehouseZoneMutationFn = ApolloReactCommon.MutationFunction<
  DeleteWarehouseZoneMutation,
  DeleteWarehouseZoneMutationVariables
>
export type DeleteWarehouseZoneComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    DeleteWarehouseZoneMutation,
    DeleteWarehouseZoneMutationVariables
  >,
  'mutation'
>

export const DeleteWarehouseZoneComponent = (props: DeleteWarehouseZoneComponentProps) => (
  <ApolloReactComponents.Mutation<DeleteWarehouseZoneMutation, DeleteWarehouseZoneMutationVariables>
    mutation={DeleteWarehouseZoneDocument}
    {...props}
  />
)

export type DeleteWarehouseZoneProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  DeleteWarehouseZoneMutation,
  DeleteWarehouseZoneMutationVariables
> &
  TChildProps
export function withDeleteWarehouseZone<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DeleteWarehouseZoneMutation,
    DeleteWarehouseZoneMutationVariables,
    DeleteWarehouseZoneProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    DeleteWarehouseZoneMutation,
    DeleteWarehouseZoneMutationVariables,
    DeleteWarehouseZoneProps<TChildProps>
  >(DeleteWarehouseZoneDocument, {
    alias: 'deleteWarehouseZone',
    ...operationOptions
  })
}

/**
 * __useDeleteWarehouseZoneMutation__
 *
 * To run a mutation, you first call `useDeleteWarehouseZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWarehouseZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWarehouseZoneMutation, { data, loading, error }] = useDeleteWarehouseZoneMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useDeleteWarehouseZoneMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteWarehouseZoneMutation,
    DeleteWarehouseZoneMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    DeleteWarehouseZoneMutation,
    DeleteWarehouseZoneMutationVariables
  >(DeleteWarehouseZoneDocument, baseOptions)
}
export type DeleteWarehouseZoneMutationHookResult = ReturnType<
  typeof useDeleteWarehouseZoneMutation
>
export type DeleteWarehouseZoneMutationResult = ApolloReactCommon.MutationResult<
  DeleteWarehouseZoneMutation
>
export type DeleteWarehouseZoneMutationOptions = ApolloReactCommon.BaseMutationOptions<
  DeleteWarehouseZoneMutation,
  DeleteWarehouseZoneMutationVariables
>
export const EnumDocument = gql`
  query Enum($name: String!) {
    enum: __type(name: $name) {
      values: enumValues {
        name
      }
    }
  }
`
export type EnumComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<EnumQuery, EnumQueryVariables>,
  'query'
> &
  ({ variables: EnumQueryVariables; skip?: boolean } | { skip: boolean })

export const EnumComponent = (props: EnumComponentProps) => (
  <ApolloReactComponents.Query<EnumQuery, EnumQueryVariables> query={EnumDocument} {...props} />
)

export type EnumProps<TChildProps = {}> = ApolloReactHoc.DataProps<EnumQuery, EnumQueryVariables> &
  TChildProps
export function withEnum<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    EnumQuery,
    EnumQueryVariables,
    EnumProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, EnumQuery, EnumQueryVariables, EnumProps<TChildProps>>(
    EnumDocument,
    {
      alias: 'enum',
      ...operationOptions
    }
  )
}

/**
 * __useEnumQuery__
 *
 * To run a query within a React component, call `useEnumQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnumQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEnumQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<EnumQuery, EnumQueryVariables>
) {
  return ApolloReactHooks.useQuery<EnumQuery, EnumQueryVariables>(EnumDocument, baseOptions)
}
export function useEnumLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EnumQuery, EnumQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<EnumQuery, EnumQueryVariables>(EnumDocument, baseOptions)
}
export type EnumQueryHookResult = ReturnType<typeof useEnumQuery>
export type EnumLazyQueryHookResult = ReturnType<typeof useEnumLazyQuery>
export type EnumQueryResult = ApolloReactCommon.QueryResult<EnumQuery, EnumQueryVariables>
export const ActivitiesDocument = gql`
  query activities {
    activities @client {
      ...activity
    }
  }
  ${ActivityFragmentDoc}
`
export type ActivitiesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<ActivitiesQuery, ActivitiesQueryVariables>,
  'query'
>

export const ActivitiesComponent = (props: ActivitiesComponentProps) => (
  <ApolloReactComponents.Query<ActivitiesQuery, ActivitiesQueryVariables>
    query={ActivitiesDocument}
    {...props}
  />
)

export type ActivitiesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ActivitiesQuery,
  ActivitiesQueryVariables
> &
  TChildProps
export function withActivities<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ActivitiesQuery,
    ActivitiesQueryVariables,
    ActivitiesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ActivitiesQuery,
    ActivitiesQueryVariables,
    ActivitiesProps<TChildProps>
  >(ActivitiesDocument, {
    alias: 'activities',
    ...operationOptions
  })
}

/**
 * __useActivitiesQuery__
 *
 * To run a query within a React component, call `useActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useActivitiesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ActivitiesQuery, ActivitiesQueryVariables>
) {
  return ApolloReactHooks.useQuery<ActivitiesQuery, ActivitiesQueryVariables>(
    ActivitiesDocument,
    baseOptions
  )
}
export function useActivitiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActivitiesQuery, ActivitiesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<ActivitiesQuery, ActivitiesQueryVariables>(
    ActivitiesDocument,
    baseOptions
  )
}
export type ActivitiesQueryHookResult = ReturnType<typeof useActivitiesQuery>
export type ActivitiesLazyQueryHookResult = ReturnType<typeof useActivitiesLazyQuery>
export type ActivitiesQueryResult = ApolloReactCommon.QueryResult<
  ActivitiesQuery,
  ActivitiesQueryVariables
>
export const AuditsDocument = gql`
  query audits(
    $filterInput: AuditEntryFilterInput
    $userContext: UserContextInput
    $first: Int
    $after: String
    $before: String
  ) {
    audits(
      where: $filterInput
      userContext: $userContext
      first: $first
      after: $after
      before: $before
    ) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        startCursor
      }
      nodes {
        ...auditLog
      }
    }
  }
  ${AuditLogFragmentDoc}
`
export type AuditsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<AuditsQuery, AuditsQueryVariables>,
  'query'
>

export const AuditsComponent = (props: AuditsComponentProps) => (
  <ApolloReactComponents.Query<AuditsQuery, AuditsQueryVariables>
    query={AuditsDocument}
    {...props}
  />
)

export type AuditsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  AuditsQuery,
  AuditsQueryVariables
> &
  TChildProps
export function withAudits<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    AuditsQuery,
    AuditsQueryVariables,
    AuditsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    AuditsQuery,
    AuditsQueryVariables,
    AuditsProps<TChildProps>
  >(AuditsDocument, {
    alias: 'audits',
    ...operationOptions
  })
}

/**
 * __useAuditsQuery__
 *
 * To run a query within a React component, call `useAuditsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuditsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuditsQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      userContext: // value for 'userContext'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useAuditsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<AuditsQuery, AuditsQueryVariables>
) {
  return ApolloReactHooks.useQuery<AuditsQuery, AuditsQueryVariables>(AuditsDocument, baseOptions)
}
export function useAuditsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AuditsQuery, AuditsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<AuditsQuery, AuditsQueryVariables>(
    AuditsDocument,
    baseOptions
  )
}
export type AuditsQueryHookResult = ReturnType<typeof useAuditsQuery>
export type AuditsLazyQueryHookResult = ReturnType<typeof useAuditsLazyQuery>
export type AuditsQueryResult = ApolloReactCommon.QueryResult<AuditsQuery, AuditsQueryVariables>
export const BudgetHoldersDocument = gql`
  query budgetHolders($filterInput: BudgetHolderFilter, $userContext: UserContextInput) {
    budgetHolders(where: $filterInput, userContext: $userContext) {
      nodes {
        ...budgetHolder
      }
    }
  }
  ${BudgetHolderFragmentDoc}
`
export type BudgetHoldersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<BudgetHoldersQuery, BudgetHoldersQueryVariables>,
  'query'
>

export const BudgetHoldersComponent = (props: BudgetHoldersComponentProps) => (
  <ApolloReactComponents.Query<BudgetHoldersQuery, BudgetHoldersQueryVariables>
    query={BudgetHoldersDocument}
    {...props}
  />
)

export type BudgetHoldersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  BudgetHoldersQuery,
  BudgetHoldersQueryVariables
> &
  TChildProps
export function withBudgetHolders<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    BudgetHoldersQuery,
    BudgetHoldersQueryVariables,
    BudgetHoldersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    BudgetHoldersQuery,
    BudgetHoldersQueryVariables,
    BudgetHoldersProps<TChildProps>
  >(BudgetHoldersDocument, {
    alias: 'budgetHolders',
    ...operationOptions
  })
}

/**
 * __useBudgetHoldersQuery__
 *
 * To run a query within a React component, call `useBudgetHoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBudgetHoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBudgetHoldersQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useBudgetHoldersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<BudgetHoldersQuery, BudgetHoldersQueryVariables>
) {
  return ApolloReactHooks.useQuery<BudgetHoldersQuery, BudgetHoldersQueryVariables>(
    BudgetHoldersDocument,
    baseOptions
  )
}
export function useBudgetHoldersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BudgetHoldersQuery,
    BudgetHoldersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<BudgetHoldersQuery, BudgetHoldersQueryVariables>(
    BudgetHoldersDocument,
    baseOptions
  )
}
export type BudgetHoldersQueryHookResult = ReturnType<typeof useBudgetHoldersQuery>
export type BudgetHoldersLazyQueryHookResult = ReturnType<typeof useBudgetHoldersLazyQuery>
export type BudgetHoldersQueryResult = ApolloReactCommon.QueryResult<
  BudgetHoldersQuery,
  BudgetHoldersQueryVariables
>
export const BudgetHoldersPaginatedDocument = gql`
  query budgetHoldersPaginated(
    $filterInput: BudgetHolderFilter
    $userContext: UserContextInput
    $first: Int
    $after: String
  ) {
    budgetHolders(where: $filterInput, userContext: $userContext, first: $first, after: $after) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ...budgetHolder
      }
    }
  }
  ${BudgetHolderFragmentDoc}
`
export type BudgetHoldersPaginatedComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    BudgetHoldersPaginatedQuery,
    BudgetHoldersPaginatedQueryVariables
  >,
  'query'
>

export const BudgetHoldersPaginatedComponent = (props: BudgetHoldersPaginatedComponentProps) => (
  <ApolloReactComponents.Query<BudgetHoldersPaginatedQuery, BudgetHoldersPaginatedQueryVariables>
    query={BudgetHoldersPaginatedDocument}
    {...props}
  />
)

export type BudgetHoldersPaginatedProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  BudgetHoldersPaginatedQuery,
  BudgetHoldersPaginatedQueryVariables
> &
  TChildProps
export function withBudgetHoldersPaginated<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    BudgetHoldersPaginatedQuery,
    BudgetHoldersPaginatedQueryVariables,
    BudgetHoldersPaginatedProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    BudgetHoldersPaginatedQuery,
    BudgetHoldersPaginatedQueryVariables,
    BudgetHoldersPaginatedProps<TChildProps>
  >(BudgetHoldersPaginatedDocument, {
    alias: 'budgetHoldersPaginated',
    ...operationOptions
  })
}

/**
 * __useBudgetHoldersPaginatedQuery__
 *
 * To run a query within a React component, call `useBudgetHoldersPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useBudgetHoldersPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBudgetHoldersPaginatedQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      userContext: // value for 'userContext'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useBudgetHoldersPaginatedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    BudgetHoldersPaginatedQuery,
    BudgetHoldersPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    BudgetHoldersPaginatedQuery,
    BudgetHoldersPaginatedQueryVariables
  >(BudgetHoldersPaginatedDocument, baseOptions)
}
export function useBudgetHoldersPaginatedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BudgetHoldersPaginatedQuery,
    BudgetHoldersPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    BudgetHoldersPaginatedQuery,
    BudgetHoldersPaginatedQueryVariables
  >(BudgetHoldersPaginatedDocument, baseOptions)
}
export type BudgetHoldersPaginatedQueryHookResult = ReturnType<
  typeof useBudgetHoldersPaginatedQuery
>
export type BudgetHoldersPaginatedLazyQueryHookResult = ReturnType<
  typeof useBudgetHoldersPaginatedLazyQuery
>
export type BudgetHoldersPaginatedQueryResult = ApolloReactCommon.QueryResult<
  BudgetHoldersPaginatedQuery,
  BudgetHoldersPaginatedQueryVariables
>
export const BudgetHolderByIdDocument = gql`
  query budgetHolderById($id: Int!, $userContext: UserContextInput) {
    budgetHolderById(id: $id, userContext: $userContext) {
      ...budgetHolder
    }
  }
  ${BudgetHolderFragmentDoc}
`
export type BudgetHolderByIdComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    BudgetHolderByIdQuery,
    BudgetHolderByIdQueryVariables
  >,
  'query'
> &
  ({ variables: BudgetHolderByIdQueryVariables; skip?: boolean } | { skip: boolean })

export const BudgetHolderByIdComponent = (props: BudgetHolderByIdComponentProps) => (
  <ApolloReactComponents.Query<BudgetHolderByIdQuery, BudgetHolderByIdQueryVariables>
    query={BudgetHolderByIdDocument}
    {...props}
  />
)

export type BudgetHolderByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  BudgetHolderByIdQuery,
  BudgetHolderByIdQueryVariables
> &
  TChildProps
export function withBudgetHolderById<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    BudgetHolderByIdQuery,
    BudgetHolderByIdQueryVariables,
    BudgetHolderByIdProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    BudgetHolderByIdQuery,
    BudgetHolderByIdQueryVariables,
    BudgetHolderByIdProps<TChildProps>
  >(BudgetHolderByIdDocument, {
    alias: 'budgetHolderById',
    ...operationOptions
  })
}

/**
 * __useBudgetHolderByIdQuery__
 *
 * To run a query within a React component, call `useBudgetHolderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBudgetHolderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBudgetHolderByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useBudgetHolderByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    BudgetHolderByIdQuery,
    BudgetHolderByIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<BudgetHolderByIdQuery, BudgetHolderByIdQueryVariables>(
    BudgetHolderByIdDocument,
    baseOptions
  )
}
export function useBudgetHolderByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    BudgetHolderByIdQuery,
    BudgetHolderByIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<BudgetHolderByIdQuery, BudgetHolderByIdQueryVariables>(
    BudgetHolderByIdDocument,
    baseOptions
  )
}
export type BudgetHolderByIdQueryHookResult = ReturnType<typeof useBudgetHolderByIdQuery>
export type BudgetHolderByIdLazyQueryHookResult = ReturnType<typeof useBudgetHolderByIdLazyQuery>
export type BudgetHolderByIdQueryResult = ApolloReactCommon.QueryResult<
  BudgetHolderByIdQuery,
  BudgetHolderByIdQueryVariables
>
export const CarriersDocument = gql`
  query carriers($filterInput: CarrierFilter, $userContext: UserContextInput) {
    carriers(where: $filterInput, userContext: $userContext) {
      nodes {
        ...carrier
        accounts {
          ...carrierAccount
        }
        services {
          ...carrierService
        }
      }
    }
  }
  ${CarrierFragmentDoc}
  ${CarrierAccountFragmentDoc}
  ${CarrierServiceFragmentDoc}
`
export type CarriersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CarriersQuery, CarriersQueryVariables>,
  'query'
>

export const CarriersComponent = (props: CarriersComponentProps) => (
  <ApolloReactComponents.Query<CarriersQuery, CarriersQueryVariables>
    query={CarriersDocument}
    {...props}
  />
)

export type CarriersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CarriersQuery,
  CarriersQueryVariables
> &
  TChildProps
export function withCarriers<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CarriersQuery,
    CarriersQueryVariables,
    CarriersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CarriersQuery,
    CarriersQueryVariables,
    CarriersProps<TChildProps>
  >(CarriersDocument, {
    alias: 'carriers',
    ...operationOptions
  })
}

/**
 * __useCarriersQuery__
 *
 * To run a query within a React component, call `useCarriersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarriersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarriersQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useCarriersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CarriersQuery, CarriersQueryVariables>
) {
  return ApolloReactHooks.useQuery<CarriersQuery, CarriersQueryVariables>(
    CarriersDocument,
    baseOptions
  )
}
export function useCarriersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CarriersQuery, CarriersQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CarriersQuery, CarriersQueryVariables>(
    CarriersDocument,
    baseOptions
  )
}
export type CarriersQueryHookResult = ReturnType<typeof useCarriersQuery>
export type CarriersLazyQueryHookResult = ReturnType<typeof useCarriersLazyQuery>
export type CarriersQueryResult = ApolloReactCommon.QueryResult<
  CarriersQuery,
  CarriersQueryVariables
>
export const CarrierByIdDocument = gql`
  query carrierById($id: Int!, $userContext: UserContextInput) {
    carrierById(id: $id, userContext: $userContext) {
      ...carrier
      accounts {
        ...carrierAccount
      }
      services {
        ...carrierService
      }
    }
  }
  ${CarrierFragmentDoc}
  ${CarrierAccountFragmentDoc}
  ${CarrierServiceFragmentDoc}
`
export type CarrierByIdComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CarrierByIdQuery, CarrierByIdQueryVariables>,
  'query'
> &
  ({ variables: CarrierByIdQueryVariables; skip?: boolean } | { skip: boolean })

export const CarrierByIdComponent = (props: CarrierByIdComponentProps) => (
  <ApolloReactComponents.Query<CarrierByIdQuery, CarrierByIdQueryVariables>
    query={CarrierByIdDocument}
    {...props}
  />
)

export type CarrierByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CarrierByIdQuery,
  CarrierByIdQueryVariables
> &
  TChildProps
export function withCarrierById<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CarrierByIdQuery,
    CarrierByIdQueryVariables,
    CarrierByIdProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CarrierByIdQuery,
    CarrierByIdQueryVariables,
    CarrierByIdProps<TChildProps>
  >(CarrierByIdDocument, {
    alias: 'carrierById',
    ...operationOptions
  })
}

/**
 * __useCarrierByIdQuery__
 *
 * To run a query within a React component, call `useCarrierByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarrierByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarrierByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useCarrierByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CarrierByIdQuery, CarrierByIdQueryVariables>
) {
  return ApolloReactHooks.useQuery<CarrierByIdQuery, CarrierByIdQueryVariables>(
    CarrierByIdDocument,
    baseOptions
  )
}
export function useCarrierByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CarrierByIdQuery, CarrierByIdQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CarrierByIdQuery, CarrierByIdQueryVariables>(
    CarrierByIdDocument,
    baseOptions
  )
}
export type CarrierByIdQueryHookResult = ReturnType<typeof useCarrierByIdQuery>
export type CarrierByIdLazyQueryHookResult = ReturnType<typeof useCarrierByIdLazyQuery>
export type CarrierByIdQueryResult = ApolloReactCommon.QueryResult<
  CarrierByIdQuery,
  CarrierByIdQueryVariables
>
export const CarriersPaginaterDocument = gql`
  query carriersPaginater(
    $filterInput: CarrierFilter
    $userContext: UserContextInput
    $first: Int
    $after: String
  ) {
    carriers(where: $filterInput, userContext: $userContext, first: $first, after: $after) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {
        id
        code
        name
      }
    }
  }
`
export type CarriersPaginaterComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CarriersPaginaterQuery,
    CarriersPaginaterQueryVariables
  >,
  'query'
>

export const CarriersPaginaterComponent = (props: CarriersPaginaterComponentProps) => (
  <ApolloReactComponents.Query<CarriersPaginaterQuery, CarriersPaginaterQueryVariables>
    query={CarriersPaginaterDocument}
    {...props}
  />
)

export type CarriersPaginaterProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CarriersPaginaterQuery,
  CarriersPaginaterQueryVariables
> &
  TChildProps
export function withCarriersPaginater<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CarriersPaginaterQuery,
    CarriersPaginaterQueryVariables,
    CarriersPaginaterProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CarriersPaginaterQuery,
    CarriersPaginaterQueryVariables,
    CarriersPaginaterProps<TChildProps>
  >(CarriersPaginaterDocument, {
    alias: 'carriersPaginater',
    ...operationOptions
  })
}

/**
 * __useCarriersPaginaterQuery__
 *
 * To run a query within a React component, call `useCarriersPaginaterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarriersPaginaterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarriersPaginaterQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      userContext: // value for 'userContext'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useCarriersPaginaterQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CarriersPaginaterQuery,
    CarriersPaginaterQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<CarriersPaginaterQuery, CarriersPaginaterQueryVariables>(
    CarriersPaginaterDocument,
    baseOptions
  )
}
export function useCarriersPaginaterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CarriersPaginaterQuery,
    CarriersPaginaterQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<CarriersPaginaterQuery, CarriersPaginaterQueryVariables>(
    CarriersPaginaterDocument,
    baseOptions
  )
}
export type CarriersPaginaterQueryHookResult = ReturnType<typeof useCarriersPaginaterQuery>
export type CarriersPaginaterLazyQueryHookResult = ReturnType<typeof useCarriersPaginaterLazyQuery>
export type CarriersPaginaterQueryResult = ApolloReactCommon.QueryResult<
  CarriersPaginaterQuery,
  CarriersPaginaterQueryVariables
>
export const CarrierModulesDocument = gql`
  query carrierModules {
    carrierModules
  }
`
export type CarrierModulesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CarrierModulesQuery, CarrierModulesQueryVariables>,
  'query'
>

export const CarrierModulesComponent = (props: CarrierModulesComponentProps) => (
  <ApolloReactComponents.Query<CarrierModulesQuery, CarrierModulesQueryVariables>
    query={CarrierModulesDocument}
    {...props}
  />
)

export type CarrierModulesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CarrierModulesQuery,
  CarrierModulesQueryVariables
> &
  TChildProps
export function withCarrierModules<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CarrierModulesQuery,
    CarrierModulesQueryVariables,
    CarrierModulesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CarrierModulesQuery,
    CarrierModulesQueryVariables,
    CarrierModulesProps<TChildProps>
  >(CarrierModulesDocument, {
    alias: 'carrierModules',
    ...operationOptions
  })
}

/**
 * __useCarrierModulesQuery__
 *
 * To run a query within a React component, call `useCarrierModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarrierModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarrierModulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCarrierModulesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CarrierModulesQuery, CarrierModulesQueryVariables>
) {
  return ApolloReactHooks.useQuery<CarrierModulesQuery, CarrierModulesQueryVariables>(
    CarrierModulesDocument,
    baseOptions
  )
}
export function useCarrierModulesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CarrierModulesQuery,
    CarrierModulesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<CarrierModulesQuery, CarrierModulesQueryVariables>(
    CarrierModulesDocument,
    baseOptions
  )
}
export type CarrierModulesQueryHookResult = ReturnType<typeof useCarrierModulesQuery>
export type CarrierModulesLazyQueryHookResult = ReturnType<typeof useCarrierModulesLazyQuery>
export type CarrierModulesQueryResult = ApolloReactCommon.QueryResult<
  CarrierModulesQuery,
  CarrierModulesQueryVariables
>
export const ConfigurationForModuleDocument = gql`
  query configurationForModule($module: String, $userContext: UserContextInput) {
    configurationForModule(module: $module, userContext: $userContext) {
      label
      overrideRequired
      position
      settingName
      type
    }
  }
`
export type ConfigurationForModuleComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ConfigurationForModuleQuery,
    ConfigurationForModuleQueryVariables
  >,
  'query'
>

export const ConfigurationForModuleComponent = (props: ConfigurationForModuleComponentProps) => (
  <ApolloReactComponents.Query<ConfigurationForModuleQuery, ConfigurationForModuleQueryVariables>
    query={ConfigurationForModuleDocument}
    {...props}
  />
)

export type ConfigurationForModuleProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ConfigurationForModuleQuery,
  ConfigurationForModuleQueryVariables
> &
  TChildProps
export function withConfigurationForModule<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ConfigurationForModuleQuery,
    ConfigurationForModuleQueryVariables,
    ConfigurationForModuleProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ConfigurationForModuleQuery,
    ConfigurationForModuleQueryVariables,
    ConfigurationForModuleProps<TChildProps>
  >(ConfigurationForModuleDocument, {
    alias: 'configurationForModule',
    ...operationOptions
  })
}

/**
 * __useConfigurationForModuleQuery__
 *
 * To run a query within a React component, call `useConfigurationForModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigurationForModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigurationForModuleQuery({
 *   variables: {
 *      module: // value for 'module'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useConfigurationForModuleQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ConfigurationForModuleQuery,
    ConfigurationForModuleQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    ConfigurationForModuleQuery,
    ConfigurationForModuleQueryVariables
  >(ConfigurationForModuleDocument, baseOptions)
}
export function useConfigurationForModuleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ConfigurationForModuleQuery,
    ConfigurationForModuleQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    ConfigurationForModuleQuery,
    ConfigurationForModuleQueryVariables
  >(ConfigurationForModuleDocument, baseOptions)
}
export type ConfigurationForModuleQueryHookResult = ReturnType<
  typeof useConfigurationForModuleQuery
>
export type ConfigurationForModuleLazyQueryHookResult = ReturnType<
  typeof useConfigurationForModuleLazyQuery
>
export type ConfigurationForModuleQueryResult = ApolloReactCommon.QueryResult<
  ConfigurationForModuleQuery,
  ConfigurationForModuleQueryVariables
>
export const CodesDocument = gql`
  query codes($filterInput: UDCodeFilter, $userContext: UserContextInput) {
    codes(where: $filterInput, userContext: $userContext) {
      nodes {
        ...code
      }
    }
  }
  ${CodeFragmentDoc}
`
export type CodesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CodesQuery, CodesQueryVariables>,
  'query'
>

export const CodesComponent = (props: CodesComponentProps) => (
  <ApolloReactComponents.Query<CodesQuery, CodesQueryVariables> query={CodesDocument} {...props} />
)

export type CodesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CodesQuery,
  CodesQueryVariables
> &
  TChildProps
export function withCodes<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CodesQuery,
    CodesQueryVariables,
    CodesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, CodesQuery, CodesQueryVariables, CodesProps<TChildProps>>(
    CodesDocument,
    {
      alias: 'codes',
      ...operationOptions
    }
  )
}

/**
 * __useCodesQuery__
 *
 * To run a query within a React component, call `useCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCodesQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useCodesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CodesQuery, CodesQueryVariables>
) {
  return ApolloReactHooks.useQuery<CodesQuery, CodesQueryVariables>(CodesDocument, baseOptions)
}
export function useCodesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CodesQuery, CodesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CodesQuery, CodesQueryVariables>(CodesDocument, baseOptions)
}
export type CodesQueryHookResult = ReturnType<typeof useCodesQuery>
export type CodesLazyQueryHookResult = ReturnType<typeof useCodesLazyQuery>
export type CodesQueryResult = ApolloReactCommon.QueryResult<CodesQuery, CodesQueryVariables>
export const CodeForTypeDocument = gql`
  query codeForType($codeType: String) {
    codesForType(codeType: $codeType) {
      nodes {
        ...code
      }
    }
  }
  ${CodeFragmentDoc}
`
export type CodeForTypeComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CodeForTypeQuery, CodeForTypeQueryVariables>,
  'query'
>

export const CodeForTypeComponent = (props: CodeForTypeComponentProps) => (
  <ApolloReactComponents.Query<CodeForTypeQuery, CodeForTypeQueryVariables>
    query={CodeForTypeDocument}
    {...props}
  />
)

export type CodeForTypeProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CodeForTypeQuery,
  CodeForTypeQueryVariables
> &
  TChildProps
export function withCodeForType<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CodeForTypeQuery,
    CodeForTypeQueryVariables,
    CodeForTypeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CodeForTypeQuery,
    CodeForTypeQueryVariables,
    CodeForTypeProps<TChildProps>
  >(CodeForTypeDocument, {
    alias: 'codeForType',
    ...operationOptions
  })
}

/**
 * __useCodeForTypeQuery__
 *
 * To run a query within a React component, call `useCodeForTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCodeForTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCodeForTypeQuery({
 *   variables: {
 *      codeType: // value for 'codeType'
 *   },
 * });
 */
export function useCodeForTypeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CodeForTypeQuery, CodeForTypeQueryVariables>
) {
  return ApolloReactHooks.useQuery<CodeForTypeQuery, CodeForTypeQueryVariables>(
    CodeForTypeDocument,
    baseOptions
  )
}
export function useCodeForTypeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CodeForTypeQuery, CodeForTypeQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CodeForTypeQuery, CodeForTypeQueryVariables>(
    CodeForTypeDocument,
    baseOptions
  )
}
export type CodeForTypeQueryHookResult = ReturnType<typeof useCodeForTypeQuery>
export type CodeForTypeLazyQueryHookResult = ReturnType<typeof useCodeForTypeLazyQuery>
export type CodeForTypeQueryResult = ApolloReactCommon.QueryResult<
  CodeForTypeQuery,
  CodeForTypeQueryVariables
>
export const CodesPaginatedDocument = gql`
  query codesPaginated(
    $filterInput: UDCodeFilter
    $userContext: UserContextInput
    $first: Int
    $after: String
    $before: String
  ) {
    codes(
      where: $filterInput
      userContext: $userContext
      first: $first
      after: $after
      before: $before
    ) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        startCursor
      }
      nodes {
        ...code
      }
    }
  }
  ${CodeFragmentDoc}
`
export type CodesPaginatedComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CodesPaginatedQuery, CodesPaginatedQueryVariables>,
  'query'
>

export const CodesPaginatedComponent = (props: CodesPaginatedComponentProps) => (
  <ApolloReactComponents.Query<CodesPaginatedQuery, CodesPaginatedQueryVariables>
    query={CodesPaginatedDocument}
    {...props}
  />
)

export type CodesPaginatedProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CodesPaginatedQuery,
  CodesPaginatedQueryVariables
> &
  TChildProps
export function withCodesPaginated<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CodesPaginatedQuery,
    CodesPaginatedQueryVariables,
    CodesPaginatedProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CodesPaginatedQuery,
    CodesPaginatedQueryVariables,
    CodesPaginatedProps<TChildProps>
  >(CodesPaginatedDocument, {
    alias: 'codesPaginated',
    ...operationOptions
  })
}

/**
 * __useCodesPaginatedQuery__
 *
 * To run a query within a React component, call `useCodesPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCodesPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCodesPaginatedQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      userContext: // value for 'userContext'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useCodesPaginatedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CodesPaginatedQuery, CodesPaginatedQueryVariables>
) {
  return ApolloReactHooks.useQuery<CodesPaginatedQuery, CodesPaginatedQueryVariables>(
    CodesPaginatedDocument,
    baseOptions
  )
}
export function useCodesPaginatedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CodesPaginatedQuery,
    CodesPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<CodesPaginatedQuery, CodesPaginatedQueryVariables>(
    CodesPaginatedDocument,
    baseOptions
  )
}
export type CodesPaginatedQueryHookResult = ReturnType<typeof useCodesPaginatedQuery>
export type CodesPaginatedLazyQueryHookResult = ReturnType<typeof useCodesPaginatedLazyQuery>
export type CodesPaginatedQueryResult = ApolloReactCommon.QueryResult<
  CodesPaginatedQuery,
  CodesPaginatedQueryVariables
>
export const CodeForTypePaginatedDocument = gql`
  query codeForTypePaginated(
    $codeType: String
    $userContext: UserContextInput
    $first: Int
    $after: String
    $before: String
  ) {
    codesForType(
      codeType: $codeType
      userContext: $userContext
      first: $first
      after: $after
      before: $before
    ) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        startCursor
      }
      nodes {
        ...code
      }
    }
  }
  ${CodeFragmentDoc}
`
export type CodeForTypePaginatedComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CodeForTypePaginatedQuery,
    CodeForTypePaginatedQueryVariables
  >,
  'query'
>

export const CodeForTypePaginatedComponent = (props: CodeForTypePaginatedComponentProps) => (
  <ApolloReactComponents.Query<CodeForTypePaginatedQuery, CodeForTypePaginatedQueryVariables>
    query={CodeForTypePaginatedDocument}
    {...props}
  />
)

export type CodeForTypePaginatedProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CodeForTypePaginatedQuery,
  CodeForTypePaginatedQueryVariables
> &
  TChildProps
export function withCodeForTypePaginated<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CodeForTypePaginatedQuery,
    CodeForTypePaginatedQueryVariables,
    CodeForTypePaginatedProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CodeForTypePaginatedQuery,
    CodeForTypePaginatedQueryVariables,
    CodeForTypePaginatedProps<TChildProps>
  >(CodeForTypePaginatedDocument, {
    alias: 'codeForTypePaginated',
    ...operationOptions
  })
}

/**
 * __useCodeForTypePaginatedQuery__
 *
 * To run a query within a React component, call `useCodeForTypePaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCodeForTypePaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCodeForTypePaginatedQuery({
 *   variables: {
 *      codeType: // value for 'codeType'
 *      userContext: // value for 'userContext'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useCodeForTypePaginatedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CodeForTypePaginatedQuery,
    CodeForTypePaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<CodeForTypePaginatedQuery, CodeForTypePaginatedQueryVariables>(
    CodeForTypePaginatedDocument,
    baseOptions
  )
}
export function useCodeForTypePaginatedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CodeForTypePaginatedQuery,
    CodeForTypePaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    CodeForTypePaginatedQuery,
    CodeForTypePaginatedQueryVariables
  >(CodeForTypePaginatedDocument, baseOptions)
}
export type CodeForTypePaginatedQueryHookResult = ReturnType<typeof useCodeForTypePaginatedQuery>
export type CodeForTypePaginatedLazyQueryHookResult = ReturnType<
  typeof useCodeForTypePaginatedLazyQuery
>
export type CodeForTypePaginatedQueryResult = ApolloReactCommon.QueryResult<
  CodeForTypePaginatedQuery,
  CodeForTypePaginatedQueryVariables
>
export const CodeDefinitionsDocument = gql`
  query codeDefinitions {
    codeDefinitions {
      nodes {
        ...codeDefinition
      }
    }
  }
  ${CodeDefinitionFragmentDoc}
`
export type CodeDefinitionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CodeDefinitionsQuery, CodeDefinitionsQueryVariables>,
  'query'
>

export const CodeDefinitionsComponent = (props: CodeDefinitionsComponentProps) => (
  <ApolloReactComponents.Query<CodeDefinitionsQuery, CodeDefinitionsQueryVariables>
    query={CodeDefinitionsDocument}
    {...props}
  />
)

export type CodeDefinitionsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CodeDefinitionsQuery,
  CodeDefinitionsQueryVariables
> &
  TChildProps
export function withCodeDefinitions<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CodeDefinitionsQuery,
    CodeDefinitionsQueryVariables,
    CodeDefinitionsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CodeDefinitionsQuery,
    CodeDefinitionsQueryVariables,
    CodeDefinitionsProps<TChildProps>
  >(CodeDefinitionsDocument, {
    alias: 'codeDefinitions',
    ...operationOptions
  })
}

/**
 * __useCodeDefinitionsQuery__
 *
 * To run a query within a React component, call `useCodeDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCodeDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCodeDefinitionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCodeDefinitionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CodeDefinitionsQuery,
    CodeDefinitionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<CodeDefinitionsQuery, CodeDefinitionsQueryVariables>(
    CodeDefinitionsDocument,
    baseOptions
  )
}
export function useCodeDefinitionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CodeDefinitionsQuery,
    CodeDefinitionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<CodeDefinitionsQuery, CodeDefinitionsQueryVariables>(
    CodeDefinitionsDocument,
    baseOptions
  )
}
export type CodeDefinitionsQueryHookResult = ReturnType<typeof useCodeDefinitionsQuery>
export type CodeDefinitionsLazyQueryHookResult = ReturnType<typeof useCodeDefinitionsLazyQuery>
export type CodeDefinitionsQueryResult = ApolloReactCommon.QueryResult<
  CodeDefinitionsQuery,
  CodeDefinitionsQueryVariables
>
export const DestinationsDocument = gql`
  query destinations($filterInput: DestinationFilter, $userContext: UserContextInput) {
    destinations(where: $filterInput, userContext: $userContext) {
      nodes {
        ...destination
        address {
          ...address
        }
        budgets {
          ...budget
        }
        contact {
          ...contact
        }
        routing {
          ...routing
        }
      }
    }
  }
  ${DestinationFragmentDoc}
  ${AddressFragmentDoc}
  ${BudgetFragmentDoc}
  ${ContactFragmentDoc}
  ${RoutingFragmentDoc}
`
export type DestinationsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<DestinationsQuery, DestinationsQueryVariables>,
  'query'
>

export const DestinationsComponent = (props: DestinationsComponentProps) => (
  <ApolloReactComponents.Query<DestinationsQuery, DestinationsQueryVariables>
    query={DestinationsDocument}
    {...props}
  />
)

export type DestinationsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  DestinationsQuery,
  DestinationsQueryVariables
> &
  TChildProps
export function withDestinations<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DestinationsQuery,
    DestinationsQueryVariables,
    DestinationsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    DestinationsQuery,
    DestinationsQueryVariables,
    DestinationsProps<TChildProps>
  >(DestinationsDocument, {
    alias: 'destinations',
    ...operationOptions
  })
}

/**
 * __useDestinationsQuery__
 *
 * To run a query within a React component, call `useDestinationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDestinationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDestinationsQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useDestinationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<DestinationsQuery, DestinationsQueryVariables>
) {
  return ApolloReactHooks.useQuery<DestinationsQuery, DestinationsQueryVariables>(
    DestinationsDocument,
    baseOptions
  )
}
export function useDestinationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DestinationsQuery, DestinationsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<DestinationsQuery, DestinationsQueryVariables>(
    DestinationsDocument,
    baseOptions
  )
}
export type DestinationsQueryHookResult = ReturnType<typeof useDestinationsQuery>
export type DestinationsLazyQueryHookResult = ReturnType<typeof useDestinationsLazyQuery>
export type DestinationsQueryResult = ApolloReactCommon.QueryResult<
  DestinationsQuery,
  DestinationsQueryVariables
>
export const DestinationsPaginatedDocument = gql`
  query destinationsPaginated(
    $filterInput: DestinationFilter
    $userContext: UserContextInput
    $first: Int
    $after: String
  ) {
    destinations(where: $filterInput, userContext: $userContext, first: $first, after: $after) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      nodes {
        id
        code
        name
      }
    }
  }
`
export type DestinationsPaginatedComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    DestinationsPaginatedQuery,
    DestinationsPaginatedQueryVariables
  >,
  'query'
>

export const DestinationsPaginatedComponent = (props: DestinationsPaginatedComponentProps) => (
  <ApolloReactComponents.Query<DestinationsPaginatedQuery, DestinationsPaginatedQueryVariables>
    query={DestinationsPaginatedDocument}
    {...props}
  />
)

export type DestinationsPaginatedProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  DestinationsPaginatedQuery,
  DestinationsPaginatedQueryVariables
> &
  TChildProps
export function withDestinationsPaginated<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DestinationsPaginatedQuery,
    DestinationsPaginatedQueryVariables,
    DestinationsPaginatedProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    DestinationsPaginatedQuery,
    DestinationsPaginatedQueryVariables,
    DestinationsPaginatedProps<TChildProps>
  >(DestinationsPaginatedDocument, {
    alias: 'destinationsPaginated',
    ...operationOptions
  })
}

/**
 * __useDestinationsPaginatedQuery__
 *
 * To run a query within a React component, call `useDestinationsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useDestinationsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDestinationsPaginatedQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      userContext: // value for 'userContext'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useDestinationsPaginatedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DestinationsPaginatedQuery,
    DestinationsPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<DestinationsPaginatedQuery, DestinationsPaginatedQueryVariables>(
    DestinationsPaginatedDocument,
    baseOptions
  )
}
export function useDestinationsPaginatedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DestinationsPaginatedQuery,
    DestinationsPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    DestinationsPaginatedQuery,
    DestinationsPaginatedQueryVariables
  >(DestinationsPaginatedDocument, baseOptions)
}
export type DestinationsPaginatedQueryHookResult = ReturnType<typeof useDestinationsPaginatedQuery>
export type DestinationsPaginatedLazyQueryHookResult = ReturnType<
  typeof useDestinationsPaginatedLazyQuery
>
export type DestinationsPaginatedQueryResult = ApolloReactCommon.QueryResult<
  DestinationsPaginatedQuery,
  DestinationsPaginatedQueryVariables
>
export const DestinationByIdDocument = gql`
  query destinationById($id: Int!, $userContext: UserContextInput) {
    destinationById(id: $id, userContext: $userContext) {
      ...destination
      address {
        ...address
      }
      budgets {
        ...budget
      }
      contact {
        ...contact
      }
      routing {
        ...routing
      }
    }
  }
  ${DestinationFragmentDoc}
  ${AddressFragmentDoc}
  ${BudgetFragmentDoc}
  ${ContactFragmentDoc}
  ${RoutingFragmentDoc}
`
export type DestinationByIdComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<DestinationByIdQuery, DestinationByIdQueryVariables>,
  'query'
> &
  ({ variables: DestinationByIdQueryVariables; skip?: boolean } | { skip: boolean })

export const DestinationByIdComponent = (props: DestinationByIdComponentProps) => (
  <ApolloReactComponents.Query<DestinationByIdQuery, DestinationByIdQueryVariables>
    query={DestinationByIdDocument}
    {...props}
  />
)

export type DestinationByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  DestinationByIdQuery,
  DestinationByIdQueryVariables
> &
  TChildProps
export function withDestinationById<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DestinationByIdQuery,
    DestinationByIdQueryVariables,
    DestinationByIdProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    DestinationByIdQuery,
    DestinationByIdQueryVariables,
    DestinationByIdProps<TChildProps>
  >(DestinationByIdDocument, {
    alias: 'destinationById',
    ...operationOptions
  })
}

/**
 * __useDestinationByIdQuery__
 *
 * To run a query within a React component, call `useDestinationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDestinationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDestinationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useDestinationByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DestinationByIdQuery,
    DestinationByIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<DestinationByIdQuery, DestinationByIdQueryVariables>(
    DestinationByIdDocument,
    baseOptions
  )
}
export function useDestinationByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DestinationByIdQuery,
    DestinationByIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<DestinationByIdQuery, DestinationByIdQueryVariables>(
    DestinationByIdDocument,
    baseOptions
  )
}
export type DestinationByIdQueryHookResult = ReturnType<typeof useDestinationByIdQuery>
export type DestinationByIdLazyQueryHookResult = ReturnType<typeof useDestinationByIdLazyQuery>
export type DestinationByIdQueryResult = ApolloReactCommon.QueryResult<
  DestinationByIdQuery,
  DestinationByIdQueryVariables
>
export const DestinationCodesDocument = gql`
  query destinationCodes {
    destinationCodes @client {
      ...destinationCode
    }
  }
  ${DestinationCodeFragmentDoc}
`
export type DestinationCodesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    DestinationCodesQuery,
    DestinationCodesQueryVariables
  >,
  'query'
>

export const DestinationCodesComponent = (props: DestinationCodesComponentProps) => (
  <ApolloReactComponents.Query<DestinationCodesQuery, DestinationCodesQueryVariables>
    query={DestinationCodesDocument}
    {...props}
  />
)

export type DestinationCodesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  DestinationCodesQuery,
  DestinationCodesQueryVariables
> &
  TChildProps
export function withDestinationCodes<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    DestinationCodesQuery,
    DestinationCodesQueryVariables,
    DestinationCodesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    DestinationCodesQuery,
    DestinationCodesQueryVariables,
    DestinationCodesProps<TChildProps>
  >(DestinationCodesDocument, {
    alias: 'destinationCodes',
    ...operationOptions
  })
}

/**
 * __useDestinationCodesQuery__
 *
 * To run a query within a React component, call `useDestinationCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDestinationCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDestinationCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDestinationCodesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DestinationCodesQuery,
    DestinationCodesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<DestinationCodesQuery, DestinationCodesQueryVariables>(
    DestinationCodesDocument,
    baseOptions
  )
}
export function useDestinationCodesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DestinationCodesQuery,
    DestinationCodesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<DestinationCodesQuery, DestinationCodesQueryVariables>(
    DestinationCodesDocument,
    baseOptions
  )
}
export type DestinationCodesQueryHookResult = ReturnType<typeof useDestinationCodesQuery>
export type DestinationCodesLazyQueryHookResult = ReturnType<typeof useDestinationCodesLazyQuery>
export type DestinationCodesQueryResult = ApolloReactCommon.QueryResult<
  DestinationCodesQuery,
  DestinationCodesQueryVariables
>
export const HandlingTypesDocument = gql`
  query handlingTypes($filterInput: HandlingTypeFilter, $userContext: UserContextInput) {
    handlingTypes(where: $filterInput, userContext: $userContext) {
      nodes {
        ...handlingType
        storageTypes {
          storageType {
            ...storageType
          }
        }
      }
    }
  }
  ${HandlingTypeFragmentDoc}
  ${StorageTypeFragmentDoc}
`
export type HandlingTypesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<HandlingTypesQuery, HandlingTypesQueryVariables>,
  'query'
>

export const HandlingTypesComponent = (props: HandlingTypesComponentProps) => (
  <ApolloReactComponents.Query<HandlingTypesQuery, HandlingTypesQueryVariables>
    query={HandlingTypesDocument}
    {...props}
  />
)

export type HandlingTypesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  HandlingTypesQuery,
  HandlingTypesQueryVariables
> &
  TChildProps
export function withHandlingTypes<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    HandlingTypesQuery,
    HandlingTypesQueryVariables,
    HandlingTypesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    HandlingTypesQuery,
    HandlingTypesQueryVariables,
    HandlingTypesProps<TChildProps>
  >(HandlingTypesDocument, {
    alias: 'handlingTypes',
    ...operationOptions
  })
}

/**
 * __useHandlingTypesQuery__
 *
 * To run a query within a React component, call `useHandlingTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHandlingTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHandlingTypesQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useHandlingTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<HandlingTypesQuery, HandlingTypesQueryVariables>
) {
  return ApolloReactHooks.useQuery<HandlingTypesQuery, HandlingTypesQueryVariables>(
    HandlingTypesDocument,
    baseOptions
  )
}
export function useHandlingTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    HandlingTypesQuery,
    HandlingTypesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<HandlingTypesQuery, HandlingTypesQueryVariables>(
    HandlingTypesDocument,
    baseOptions
  )
}
export type HandlingTypesQueryHookResult = ReturnType<typeof useHandlingTypesQuery>
export type HandlingTypesLazyQueryHookResult = ReturnType<typeof useHandlingTypesLazyQuery>
export type HandlingTypesQueryResult = ApolloReactCommon.QueryResult<
  HandlingTypesQuery,
  HandlingTypesQueryVariables
>
export const SelectHandlingTypesDocument = gql`
  query selectHandlingTypes($userContext: UserContextInput) {
    handlingTypes(userContext: $userContext) {
      nodes {
        ...handlingType
      }
    }
  }
  ${HandlingTypeFragmentDoc}
`
export type SelectHandlingTypesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SelectHandlingTypesQuery,
    SelectHandlingTypesQueryVariables
  >,
  'query'
>

export const SelectHandlingTypesComponent = (props: SelectHandlingTypesComponentProps) => (
  <ApolloReactComponents.Query<SelectHandlingTypesQuery, SelectHandlingTypesQueryVariables>
    query={SelectHandlingTypesDocument}
    {...props}
  />
)

export type SelectHandlingTypesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  SelectHandlingTypesQuery,
  SelectHandlingTypesQueryVariables
> &
  TChildProps
export function withSelectHandlingTypes<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SelectHandlingTypesQuery,
    SelectHandlingTypesQueryVariables,
    SelectHandlingTypesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SelectHandlingTypesQuery,
    SelectHandlingTypesQueryVariables,
    SelectHandlingTypesProps<TChildProps>
  >(SelectHandlingTypesDocument, {
    alias: 'selectHandlingTypes',
    ...operationOptions
  })
}

/**
 * __useSelectHandlingTypesQuery__
 *
 * To run a query within a React component, call `useSelectHandlingTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectHandlingTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectHandlingTypesQuery({
 *   variables: {
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useSelectHandlingTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SelectHandlingTypesQuery,
    SelectHandlingTypesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<SelectHandlingTypesQuery, SelectHandlingTypesQueryVariables>(
    SelectHandlingTypesDocument,
    baseOptions
  )
}
export function useSelectHandlingTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SelectHandlingTypesQuery,
    SelectHandlingTypesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<SelectHandlingTypesQuery, SelectHandlingTypesQueryVariables>(
    SelectHandlingTypesDocument,
    baseOptions
  )
}
export type SelectHandlingTypesQueryHookResult = ReturnType<typeof useSelectHandlingTypesQuery>
export type SelectHandlingTypesLazyQueryHookResult = ReturnType<
  typeof useSelectHandlingTypesLazyQuery
>
export type SelectHandlingTypesQueryResult = ApolloReactCommon.QueryResult<
  SelectHandlingTypesQuery,
  SelectHandlingTypesQueryVariables
>
export const HandlingTypesPaginatedDocument = gql`
  query handlingTypesPaginated(
    $filterInput: HandlingTypeFilter
    $first: Int
    $after: String
    $before: String
  ) {
    handlingTypes(where: $filterInput, first: $first, after: $after, before: $before) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      nodes {
        ...handlingType
        storageTypes {
          storageType {
            ...storageType
          }
        }
      }
    }
  }
  ${HandlingTypeFragmentDoc}
  ${StorageTypeFragmentDoc}
`
export type HandlingTypesPaginatedComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    HandlingTypesPaginatedQuery,
    HandlingTypesPaginatedQueryVariables
  >,
  'query'
>

export const HandlingTypesPaginatedComponent = (props: HandlingTypesPaginatedComponentProps) => (
  <ApolloReactComponents.Query<HandlingTypesPaginatedQuery, HandlingTypesPaginatedQueryVariables>
    query={HandlingTypesPaginatedDocument}
    {...props}
  />
)

export type HandlingTypesPaginatedProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  HandlingTypesPaginatedQuery,
  HandlingTypesPaginatedQueryVariables
> &
  TChildProps
export function withHandlingTypesPaginated<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    HandlingTypesPaginatedQuery,
    HandlingTypesPaginatedQueryVariables,
    HandlingTypesPaginatedProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    HandlingTypesPaginatedQuery,
    HandlingTypesPaginatedQueryVariables,
    HandlingTypesPaginatedProps<TChildProps>
  >(HandlingTypesPaginatedDocument, {
    alias: 'handlingTypesPaginated',
    ...operationOptions
  })
}

/**
 * __useHandlingTypesPaginatedQuery__
 *
 * To run a query within a React component, call `useHandlingTypesPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useHandlingTypesPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHandlingTypesPaginatedQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useHandlingTypesPaginatedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    HandlingTypesPaginatedQuery,
    HandlingTypesPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    HandlingTypesPaginatedQuery,
    HandlingTypesPaginatedQueryVariables
  >(HandlingTypesPaginatedDocument, baseOptions)
}
export function useHandlingTypesPaginatedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    HandlingTypesPaginatedQuery,
    HandlingTypesPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    HandlingTypesPaginatedQuery,
    HandlingTypesPaginatedQueryVariables
  >(HandlingTypesPaginatedDocument, baseOptions)
}
export type HandlingTypesPaginatedQueryHookResult = ReturnType<
  typeof useHandlingTypesPaginatedQuery
>
export type HandlingTypesPaginatedLazyQueryHookResult = ReturnType<
  typeof useHandlingTypesPaginatedLazyQuery
>
export type HandlingTypesPaginatedQueryResult = ApolloReactCommon.QueryResult<
  HandlingTypesPaginatedQuery,
  HandlingTypesPaginatedQueryVariables
>
export const HandlingTypeByIdDocument = gql`
  query handlingTypeById($id: Int!) {
    handlingTypeById(id: $id) {
      ...handlingType
      storageTypes {
        storageType {
          ...storageType
        }
      }
    }
  }
  ${HandlingTypeFragmentDoc}
  ${StorageTypeFragmentDoc}
`
export type HandlingTypeByIdComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    HandlingTypeByIdQuery,
    HandlingTypeByIdQueryVariables
  >,
  'query'
> &
  ({ variables: HandlingTypeByIdQueryVariables; skip?: boolean } | { skip: boolean })

export const HandlingTypeByIdComponent = (props: HandlingTypeByIdComponentProps) => (
  <ApolloReactComponents.Query<HandlingTypeByIdQuery, HandlingTypeByIdQueryVariables>
    query={HandlingTypeByIdDocument}
    {...props}
  />
)

export type HandlingTypeByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  HandlingTypeByIdQuery,
  HandlingTypeByIdQueryVariables
> &
  TChildProps
export function withHandlingTypeById<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    HandlingTypeByIdQuery,
    HandlingTypeByIdQueryVariables,
    HandlingTypeByIdProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    HandlingTypeByIdQuery,
    HandlingTypeByIdQueryVariables,
    HandlingTypeByIdProps<TChildProps>
  >(HandlingTypeByIdDocument, {
    alias: 'handlingTypeById',
    ...operationOptions
  })
}

/**
 * __useHandlingTypeByIdQuery__
 *
 * To run a query within a React component, call `useHandlingTypeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useHandlingTypeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHandlingTypeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHandlingTypeByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    HandlingTypeByIdQuery,
    HandlingTypeByIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<HandlingTypeByIdQuery, HandlingTypeByIdQueryVariables>(
    HandlingTypeByIdDocument,
    baseOptions
  )
}
export function useHandlingTypeByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    HandlingTypeByIdQuery,
    HandlingTypeByIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<HandlingTypeByIdQuery, HandlingTypeByIdQueryVariables>(
    HandlingTypeByIdDocument,
    baseOptions
  )
}
export type HandlingTypeByIdQueryHookResult = ReturnType<typeof useHandlingTypeByIdQuery>
export type HandlingTypeByIdLazyQueryHookResult = ReturnType<typeof useHandlingTypeByIdLazyQuery>
export type HandlingTypeByIdQueryResult = ApolloReactCommon.QueryResult<
  HandlingTypeByIdQuery,
  HandlingTypeByIdQueryVariables
>
export const LocationsDocument = gql`
  query locations($filterInput: LocationFilter, $userContext: UserContextInput) {
    locations(where: $filterInput, userContext: $userContext) {
      nodes {
        ...location
      }
    }
  }
  ${LocationFragmentDoc}
`
export type LocationsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<LocationsQuery, LocationsQueryVariables>,
  'query'
>

export const LocationsComponent = (props: LocationsComponentProps) => (
  <ApolloReactComponents.Query<LocationsQuery, LocationsQueryVariables>
    query={LocationsDocument}
    {...props}
  />
)

export type LocationsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  LocationsQuery,
  LocationsQueryVariables
> &
  TChildProps
export function withLocations<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LocationsQuery,
    LocationsQueryVariables,
    LocationsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    LocationsQuery,
    LocationsQueryVariables,
    LocationsProps<TChildProps>
  >(LocationsDocument, {
    alias: 'locations',
    ...operationOptions
  })
}

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useLocationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LocationsQuery, LocationsQueryVariables>
) {
  return ApolloReactHooks.useQuery<LocationsQuery, LocationsQueryVariables>(
    LocationsDocument,
    baseOptions
  )
}
export function useLocationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationsQuery, LocationsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<LocationsQuery, LocationsQueryVariables>(
    LocationsDocument,
    baseOptions
  )
}
export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>
export type LocationsLazyQueryHookResult = ReturnType<typeof useLocationsLazyQuery>
export type LocationsQueryResult = ApolloReactCommon.QueryResult<
  LocationsQuery,
  LocationsQueryVariables
>
export const LocationByIdDocument = gql`
  query locationById($id: Int!, $userContext: UserContextInput) {
    locationById(id: $id, userContext: $userContext) {
      ...location
      storageType {
        ...storageType
      }
    }
  }
  ${LocationFragmentDoc}
  ${StorageTypeFragmentDoc}
`
export type LocationByIdComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<LocationByIdQuery, LocationByIdQueryVariables>,
  'query'
> &
  ({ variables: LocationByIdQueryVariables; skip?: boolean } | { skip: boolean })

export const LocationByIdComponent = (props: LocationByIdComponentProps) => (
  <ApolloReactComponents.Query<LocationByIdQuery, LocationByIdQueryVariables>
    query={LocationByIdDocument}
    {...props}
  />
)

export type LocationByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  LocationByIdQuery,
  LocationByIdQueryVariables
> &
  TChildProps
export function withLocationById<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LocationByIdQuery,
    LocationByIdQueryVariables,
    LocationByIdProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    LocationByIdQuery,
    LocationByIdQueryVariables,
    LocationByIdProps<TChildProps>
  >(LocationByIdDocument, {
    alias: 'locationById',
    ...operationOptions
  })
}

/**
 * __useLocationByIdQuery__
 *
 * To run a query within a React component, call `useLocationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useLocationByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<LocationByIdQuery, LocationByIdQueryVariables>
) {
  return ApolloReactHooks.useQuery<LocationByIdQuery, LocationByIdQueryVariables>(
    LocationByIdDocument,
    baseOptions
  )
}
export function useLocationByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LocationByIdQuery, LocationByIdQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<LocationByIdQuery, LocationByIdQueryVariables>(
    LocationByIdDocument,
    baseOptions
  )
}
export type LocationByIdQueryHookResult = ReturnType<typeof useLocationByIdQuery>
export type LocationByIdLazyQueryHookResult = ReturnType<typeof useLocationByIdLazyQuery>
export type LocationByIdQueryResult = ApolloReactCommon.QueryResult<
  LocationByIdQuery,
  LocationByIdQueryVariables
>
export const LocationProcessTypesDocument = gql`
  query locationProcessTypes {
    locationProcessTypes {
      displayName
      description
      value
    }
  }
`
export type LocationProcessTypesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    LocationProcessTypesQuery,
    LocationProcessTypesQueryVariables
  >,
  'query'
>

export const LocationProcessTypesComponent = (props: LocationProcessTypesComponentProps) => (
  <ApolloReactComponents.Query<LocationProcessTypesQuery, LocationProcessTypesQueryVariables>
    query={LocationProcessTypesDocument}
    {...props}
  />
)

export type LocationProcessTypesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  LocationProcessTypesQuery,
  LocationProcessTypesQueryVariables
> &
  TChildProps
export function withLocationProcessTypes<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LocationProcessTypesQuery,
    LocationProcessTypesQueryVariables,
    LocationProcessTypesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    LocationProcessTypesQuery,
    LocationProcessTypesQueryVariables,
    LocationProcessTypesProps<TChildProps>
  >(LocationProcessTypesDocument, {
    alias: 'locationProcessTypes',
    ...operationOptions
  })
}

/**
 * __useLocationProcessTypesQuery__
 *
 * To run a query within a React component, call `useLocationProcessTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationProcessTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationProcessTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationProcessTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LocationProcessTypesQuery,
    LocationProcessTypesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<LocationProcessTypesQuery, LocationProcessTypesQueryVariables>(
    LocationProcessTypesDocument,
    baseOptions
  )
}
export function useLocationProcessTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LocationProcessTypesQuery,
    LocationProcessTypesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    LocationProcessTypesQuery,
    LocationProcessTypesQueryVariables
  >(LocationProcessTypesDocument, baseOptions)
}
export type LocationProcessTypesQueryHookResult = ReturnType<typeof useLocationProcessTypesQuery>
export type LocationProcessTypesLazyQueryHookResult = ReturnType<
  typeof useLocationProcessTypesLazyQuery
>
export type LocationProcessTypesQueryResult = ApolloReactCommon.QueryResult<
  LocationProcessTypesQuery,
  LocationProcessTypesQueryVariables
>
export const LocationPhysicalTypesDocument = gql`
  query locationPhysicalTypes {
    locationPhysicalTypes {
      displayName
      description
      value
    }
  }
`
export type LocationPhysicalTypesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    LocationPhysicalTypesQuery,
    LocationPhysicalTypesQueryVariables
  >,
  'query'
>

export const LocationPhysicalTypesComponent = (props: LocationPhysicalTypesComponentProps) => (
  <ApolloReactComponents.Query<LocationPhysicalTypesQuery, LocationPhysicalTypesQueryVariables>
    query={LocationPhysicalTypesDocument}
    {...props}
  />
)

export type LocationPhysicalTypesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  LocationPhysicalTypesQuery,
  LocationPhysicalTypesQueryVariables
> &
  TChildProps
export function withLocationPhysicalTypes<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LocationPhysicalTypesQuery,
    LocationPhysicalTypesQueryVariables,
    LocationPhysicalTypesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    LocationPhysicalTypesQuery,
    LocationPhysicalTypesQueryVariables,
    LocationPhysicalTypesProps<TChildProps>
  >(LocationPhysicalTypesDocument, {
    alias: 'locationPhysicalTypes',
    ...operationOptions
  })
}

/**
 * __useLocationPhysicalTypesQuery__
 *
 * To run a query within a React component, call `useLocationPhysicalTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationPhysicalTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationPhysicalTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useLocationPhysicalTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LocationPhysicalTypesQuery,
    LocationPhysicalTypesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<LocationPhysicalTypesQuery, LocationPhysicalTypesQueryVariables>(
    LocationPhysicalTypesDocument,
    baseOptions
  )
}
export function useLocationPhysicalTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LocationPhysicalTypesQuery,
    LocationPhysicalTypesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    LocationPhysicalTypesQuery,
    LocationPhysicalTypesQueryVariables
  >(LocationPhysicalTypesDocument, baseOptions)
}
export type LocationPhysicalTypesQueryHookResult = ReturnType<typeof useLocationPhysicalTypesQuery>
export type LocationPhysicalTypesLazyQueryHookResult = ReturnType<
  typeof useLocationPhysicalTypesLazyQuery
>
export type LocationPhysicalTypesQueryResult = ApolloReactCommon.QueryResult<
  LocationPhysicalTypesQuery,
  LocationPhysicalTypesQueryVariables
>
export const SelectLocationsDocument = gql`
  query selectLocations($filterInput: LocationFilter, $userContext: UserContextInput) {
    locations(where: $filterInput, userContext: $userContext) {
      nodes {
        id
        code
      }
    }
  }
`
export type SelectLocationsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<SelectLocationsQuery, SelectLocationsQueryVariables>,
  'query'
>

export const SelectLocationsComponent = (props: SelectLocationsComponentProps) => (
  <ApolloReactComponents.Query<SelectLocationsQuery, SelectLocationsQueryVariables>
    query={SelectLocationsDocument}
    {...props}
  />
)

export type SelectLocationsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  SelectLocationsQuery,
  SelectLocationsQueryVariables
> &
  TChildProps
export function withSelectLocations<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SelectLocationsQuery,
    SelectLocationsQueryVariables,
    SelectLocationsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SelectLocationsQuery,
    SelectLocationsQueryVariables,
    SelectLocationsProps<TChildProps>
  >(SelectLocationsDocument, {
    alias: 'selectLocations',
    ...operationOptions
  })
}

/**
 * __useSelectLocationsQuery__
 *
 * To run a query within a React component, call `useSelectLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectLocationsQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useSelectLocationsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SelectLocationsQuery,
    SelectLocationsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<SelectLocationsQuery, SelectLocationsQueryVariables>(
    SelectLocationsDocument,
    baseOptions
  )
}
export function useSelectLocationsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SelectLocationsQuery,
    SelectLocationsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<SelectLocationsQuery, SelectLocationsQueryVariables>(
    SelectLocationsDocument,
    baseOptions
  )
}
export type SelectLocationsQueryHookResult = ReturnType<typeof useSelectLocationsQuery>
export type SelectLocationsLazyQueryHookResult = ReturnType<typeof useSelectLocationsLazyQuery>
export type SelectLocationsQueryResult = ApolloReactCommon.QueryResult<
  SelectLocationsQuery,
  SelectLocationsQueryVariables
>
export const LocationsPaginatedDocument = gql`
  query locationsPaginated(
    $filterInput: LocationFilter
    $first: Int
    $after: String
    $before: String
    $userContext: UserContextInput
  ) {
    locations(
      where: $filterInput
      first: $first
      after: $after
      before: $before
      userContext: $userContext
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      nodes {
        id
        aisle
        code
        zone
        storageType {
          ...storageType
        }
      }
    }
  }
  ${StorageTypeFragmentDoc}
`
export type LocationsPaginatedComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    LocationsPaginatedQuery,
    LocationsPaginatedQueryVariables
  >,
  'query'
>

export const LocationsPaginatedComponent = (props: LocationsPaginatedComponentProps) => (
  <ApolloReactComponents.Query<LocationsPaginatedQuery, LocationsPaginatedQueryVariables>
    query={LocationsPaginatedDocument}
    {...props}
  />
)

export type LocationsPaginatedProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  LocationsPaginatedQuery,
  LocationsPaginatedQueryVariables
> &
  TChildProps
export function withLocationsPaginated<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LocationsPaginatedQuery,
    LocationsPaginatedQueryVariables,
    LocationsPaginatedProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    LocationsPaginatedQuery,
    LocationsPaginatedQueryVariables,
    LocationsPaginatedProps<TChildProps>
  >(LocationsPaginatedDocument, {
    alias: 'locationsPaginated',
    ...operationOptions
  })
}

/**
 * __useLocationsPaginatedQuery__
 *
 * To run a query within a React component, call `useLocationsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsPaginatedQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useLocationsPaginatedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LocationsPaginatedQuery,
    LocationsPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<LocationsPaginatedQuery, LocationsPaginatedQueryVariables>(
    LocationsPaginatedDocument,
    baseOptions
  )
}
export function useLocationsPaginatedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LocationsPaginatedQuery,
    LocationsPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<LocationsPaginatedQuery, LocationsPaginatedQueryVariables>(
    LocationsPaginatedDocument,
    baseOptions
  )
}
export type LocationsPaginatedQueryHookResult = ReturnType<typeof useLocationsPaginatedQuery>
export type LocationsPaginatedLazyQueryHookResult = ReturnType<
  typeof useLocationsPaginatedLazyQuery
>
export type LocationsPaginatedQueryResult = ApolloReactCommon.QueryResult<
  LocationsPaginatedQuery,
  LocationsPaginatedQueryVariables
>
export const MaxWeeklySpendsDocument = gql`
  query maxWeeklySpends {
    maxWeeklySpends @client {
      ...maxWeeklySpend
    }
  }
  ${MaxWeeklySpendFragmentDoc}
`
export type MaxWeeklySpendsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<MaxWeeklySpendsQuery, MaxWeeklySpendsQueryVariables>,
  'query'
>

export const MaxWeeklySpendsComponent = (props: MaxWeeklySpendsComponentProps) => (
  <ApolloReactComponents.Query<MaxWeeklySpendsQuery, MaxWeeklySpendsQueryVariables>
    query={MaxWeeklySpendsDocument}
    {...props}
  />
)

export type MaxWeeklySpendsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  MaxWeeklySpendsQuery,
  MaxWeeklySpendsQueryVariables
> &
  TChildProps
export function withMaxWeeklySpends<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MaxWeeklySpendsQuery,
    MaxWeeklySpendsQueryVariables,
    MaxWeeklySpendsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MaxWeeklySpendsQuery,
    MaxWeeklySpendsQueryVariables,
    MaxWeeklySpendsProps<TChildProps>
  >(MaxWeeklySpendsDocument, {
    alias: 'maxWeeklySpends',
    ...operationOptions
  })
}

/**
 * __useMaxWeeklySpendsQuery__
 *
 * To run a query within a React component, call `useMaxWeeklySpendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaxWeeklySpendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaxWeeklySpendsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMaxWeeklySpendsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MaxWeeklySpendsQuery,
    MaxWeeklySpendsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<MaxWeeklySpendsQuery, MaxWeeklySpendsQueryVariables>(
    MaxWeeklySpendsDocument,
    baseOptions
  )
}
export function useMaxWeeklySpendsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MaxWeeklySpendsQuery,
    MaxWeeklySpendsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<MaxWeeklySpendsQuery, MaxWeeklySpendsQueryVariables>(
    MaxWeeklySpendsDocument,
    baseOptions
  )
}
export type MaxWeeklySpendsQueryHookResult = ReturnType<typeof useMaxWeeklySpendsQuery>
export type MaxWeeklySpendsLazyQueryHookResult = ReturnType<typeof useMaxWeeklySpendsLazyQuery>
export type MaxWeeklySpendsQueryResult = ApolloReactCommon.QueryResult<
  MaxWeeklySpendsQuery,
  MaxWeeklySpendsQueryVariables
>
export const ConfirmMethodsDocument = gql`
  query confirmMethods {
    pickConfirmMethods
    postPickConfirmMethods
    packingMethods
    despatchMethods
    closeLoadMethods
  }
`
export type ConfirmMethodsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<ConfirmMethodsQuery, ConfirmMethodsQueryVariables>,
  'query'
>

export const ConfirmMethodsComponent = (props: ConfirmMethodsComponentProps) => (
  <ApolloReactComponents.Query<ConfirmMethodsQuery, ConfirmMethodsQueryVariables>
    query={ConfirmMethodsDocument}
    {...props}
  />
)

export type ConfirmMethodsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ConfirmMethodsQuery,
  ConfirmMethodsQueryVariables
> &
  TChildProps
export function withConfirmMethods<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ConfirmMethodsQuery,
    ConfirmMethodsQueryVariables,
    ConfirmMethodsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ConfirmMethodsQuery,
    ConfirmMethodsQueryVariables,
    ConfirmMethodsProps<TChildProps>
  >(ConfirmMethodsDocument, {
    alias: 'confirmMethods',
    ...operationOptions
  })
}

/**
 * __useConfirmMethodsQuery__
 *
 * To run a query within a React component, call `useConfirmMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfirmMethodsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ConfirmMethodsQuery, ConfirmMethodsQueryVariables>
) {
  return ApolloReactHooks.useQuery<ConfirmMethodsQuery, ConfirmMethodsQueryVariables>(
    ConfirmMethodsDocument,
    baseOptions
  )
}
export function useConfirmMethodsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ConfirmMethodsQuery,
    ConfirmMethodsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ConfirmMethodsQuery, ConfirmMethodsQueryVariables>(
    ConfirmMethodsDocument,
    baseOptions
  )
}
export type ConfirmMethodsQueryHookResult = ReturnType<typeof useConfirmMethodsQuery>
export type ConfirmMethodsLazyQueryHookResult = ReturnType<typeof useConfirmMethodsLazyQuery>
export type ConfirmMethodsQueryResult = ApolloReactCommon.QueryResult<
  ConfirmMethodsQuery,
  ConfirmMethodsQueryVariables
>
export const PickCreationmethodsDocument = gql`
  query pickCreationmethods {
    prePickCreationMethods
    pickCreationMethods
    postPickCreationMethods
    pickOutputMethods
  }
`
export type PickCreationmethodsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PickCreationmethodsQuery,
    PickCreationmethodsQueryVariables
  >,
  'query'
>

export const PickCreationmethodsComponent = (props: PickCreationmethodsComponentProps) => (
  <ApolloReactComponents.Query<PickCreationmethodsQuery, PickCreationmethodsQueryVariables>
    query={PickCreationmethodsDocument}
    {...props}
  />
)

export type PickCreationmethodsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  PickCreationmethodsQuery,
  PickCreationmethodsQueryVariables
> &
  TChildProps
export function withPickCreationmethods<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PickCreationmethodsQuery,
    PickCreationmethodsQueryVariables,
    PickCreationmethodsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PickCreationmethodsQuery,
    PickCreationmethodsQueryVariables,
    PickCreationmethodsProps<TChildProps>
  >(PickCreationmethodsDocument, {
    alias: 'pickCreationmethods',
    ...operationOptions
  })
}

/**
 * __usePickCreationmethodsQuery__
 *
 * To run a query within a React component, call `usePickCreationmethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickCreationmethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickCreationmethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePickCreationmethodsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PickCreationmethodsQuery,
    PickCreationmethodsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<PickCreationmethodsQuery, PickCreationmethodsQueryVariables>(
    PickCreationmethodsDocument,
    baseOptions
  )
}
export function usePickCreationmethodsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PickCreationmethodsQuery,
    PickCreationmethodsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<PickCreationmethodsQuery, PickCreationmethodsQueryVariables>(
    PickCreationmethodsDocument,
    baseOptions
  )
}
export type PickCreationmethodsQueryHookResult = ReturnType<typeof usePickCreationmethodsQuery>
export type PickCreationmethodsLazyQueryHookResult = ReturnType<
  typeof usePickCreationmethodsLazyQuery
>
export type PickCreationmethodsQueryResult = ApolloReactCommon.QueryResult<
  PickCreationmethodsQuery,
  PickCreationmethodsQueryVariables
>
export const MaterialHandlingEquipmentsDocument = gql`
  query materialHandlingEquipments(
    $filterInput: MaterialHandlingEquipmentFilter
    $userContext: UserContextInput
  ) {
    materialHandlingEquipment(where: $filterInput, userContext: $userContext) {
      nodes {
        ...mhe
      }
    }
  }
  ${MheFragmentDoc}
`
export type MaterialHandlingEquipmentsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MaterialHandlingEquipmentsQuery,
    MaterialHandlingEquipmentsQueryVariables
  >,
  'query'
>

export const MaterialHandlingEquipmentsComponent = (
  props: MaterialHandlingEquipmentsComponentProps
) => (
  <ApolloReactComponents.Query<
    MaterialHandlingEquipmentsQuery,
    MaterialHandlingEquipmentsQueryVariables
  >
    query={MaterialHandlingEquipmentsDocument}
    {...props}
  />
)

export type MaterialHandlingEquipmentsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  MaterialHandlingEquipmentsQuery,
  MaterialHandlingEquipmentsQueryVariables
> &
  TChildProps
export function withMaterialHandlingEquipments<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MaterialHandlingEquipmentsQuery,
    MaterialHandlingEquipmentsQueryVariables,
    MaterialHandlingEquipmentsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MaterialHandlingEquipmentsQuery,
    MaterialHandlingEquipmentsQueryVariables,
    MaterialHandlingEquipmentsProps<TChildProps>
  >(MaterialHandlingEquipmentsDocument, {
    alias: 'materialHandlingEquipments',
    ...operationOptions
  })
}

/**
 * __useMaterialHandlingEquipmentsQuery__
 *
 * To run a query within a React component, call `useMaterialHandlingEquipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaterialHandlingEquipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaterialHandlingEquipmentsQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useMaterialHandlingEquipmentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MaterialHandlingEquipmentsQuery,
    MaterialHandlingEquipmentsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    MaterialHandlingEquipmentsQuery,
    MaterialHandlingEquipmentsQueryVariables
  >(MaterialHandlingEquipmentsDocument, baseOptions)
}
export function useMaterialHandlingEquipmentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MaterialHandlingEquipmentsQuery,
    MaterialHandlingEquipmentsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    MaterialHandlingEquipmentsQuery,
    MaterialHandlingEquipmentsQueryVariables
  >(MaterialHandlingEquipmentsDocument, baseOptions)
}
export type MaterialHandlingEquipmentsQueryHookResult = ReturnType<
  typeof useMaterialHandlingEquipmentsQuery
>
export type MaterialHandlingEquipmentsLazyQueryHookResult = ReturnType<
  typeof useMaterialHandlingEquipmentsLazyQuery
>
export type MaterialHandlingEquipmentsQueryResult = ApolloReactCommon.QueryResult<
  MaterialHandlingEquipmentsQuery,
  MaterialHandlingEquipmentsQueryVariables
>
export const MaterialHandlingEquipmentsPaginatedDocument = gql`
  query materialHandlingEquipmentsPaginated(
    $filterInput: MaterialHandlingEquipmentFilter
    $userContext: UserContextInput
    $first: Int
    $after: String
  ) {
    materialHandlingEquipment(
      where: $filterInput
      userContext: $userContext
      first: $first
      after: $after
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      nodes {
        id
        code
        description
        class
      }
    }
  }
`
export type MaterialHandlingEquipmentsPaginatedComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MaterialHandlingEquipmentsPaginatedQuery,
    MaterialHandlingEquipmentsPaginatedQueryVariables
  >,
  'query'
>

export const MaterialHandlingEquipmentsPaginatedComponent = (
  props: MaterialHandlingEquipmentsPaginatedComponentProps
) => (
  <ApolloReactComponents.Query<
    MaterialHandlingEquipmentsPaginatedQuery,
    MaterialHandlingEquipmentsPaginatedQueryVariables
  >
    query={MaterialHandlingEquipmentsPaginatedDocument}
    {...props}
  />
)

export type MaterialHandlingEquipmentsPaginatedProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  MaterialHandlingEquipmentsPaginatedQuery,
  MaterialHandlingEquipmentsPaginatedQueryVariables
> &
  TChildProps
export function withMaterialHandlingEquipmentsPaginated<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MaterialHandlingEquipmentsPaginatedQuery,
    MaterialHandlingEquipmentsPaginatedQueryVariables,
    MaterialHandlingEquipmentsPaginatedProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MaterialHandlingEquipmentsPaginatedQuery,
    MaterialHandlingEquipmentsPaginatedQueryVariables,
    MaterialHandlingEquipmentsPaginatedProps<TChildProps>
  >(MaterialHandlingEquipmentsPaginatedDocument, {
    alias: 'materialHandlingEquipmentsPaginated',
    ...operationOptions
  })
}

/**
 * __useMaterialHandlingEquipmentsPaginatedQuery__
 *
 * To run a query within a React component, call `useMaterialHandlingEquipmentsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaterialHandlingEquipmentsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaterialHandlingEquipmentsPaginatedQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      userContext: // value for 'userContext'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useMaterialHandlingEquipmentsPaginatedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MaterialHandlingEquipmentsPaginatedQuery,
    MaterialHandlingEquipmentsPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    MaterialHandlingEquipmentsPaginatedQuery,
    MaterialHandlingEquipmentsPaginatedQueryVariables
  >(MaterialHandlingEquipmentsPaginatedDocument, baseOptions)
}
export function useMaterialHandlingEquipmentsPaginatedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MaterialHandlingEquipmentsPaginatedQuery,
    MaterialHandlingEquipmentsPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    MaterialHandlingEquipmentsPaginatedQuery,
    MaterialHandlingEquipmentsPaginatedQueryVariables
  >(MaterialHandlingEquipmentsPaginatedDocument, baseOptions)
}
export type MaterialHandlingEquipmentsPaginatedQueryHookResult = ReturnType<
  typeof useMaterialHandlingEquipmentsPaginatedQuery
>
export type MaterialHandlingEquipmentsPaginatedLazyQueryHookResult = ReturnType<
  typeof useMaterialHandlingEquipmentsPaginatedLazyQuery
>
export type MaterialHandlingEquipmentsPaginatedQueryResult = ApolloReactCommon.QueryResult<
  MaterialHandlingEquipmentsPaginatedQuery,
  MaterialHandlingEquipmentsPaginatedQueryVariables
>
export const MaterialHandlingEquipmentByIdDocument = gql`
  query materialHandlingEquipmentById($id: Int!, $userContext: UserContextInput) {
    materialHandlingEquipmentById(id: $id, userContext: $userContext) {
      ...mhe
    }
  }
  ${MheFragmentDoc}
`
export type MaterialHandlingEquipmentByIdComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    MaterialHandlingEquipmentByIdQuery,
    MaterialHandlingEquipmentByIdQueryVariables
  >,
  'query'
> &
  ({ variables: MaterialHandlingEquipmentByIdQueryVariables; skip?: boolean } | { skip: boolean })

export const MaterialHandlingEquipmentByIdComponent = (
  props: MaterialHandlingEquipmentByIdComponentProps
) => (
  <ApolloReactComponents.Query<
    MaterialHandlingEquipmentByIdQuery,
    MaterialHandlingEquipmentByIdQueryVariables
  >
    query={MaterialHandlingEquipmentByIdDocument}
    {...props}
  />
)

export type MaterialHandlingEquipmentByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  MaterialHandlingEquipmentByIdQuery,
  MaterialHandlingEquipmentByIdQueryVariables
> &
  TChildProps
export function withMaterialHandlingEquipmentById<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    MaterialHandlingEquipmentByIdQuery,
    MaterialHandlingEquipmentByIdQueryVariables,
    MaterialHandlingEquipmentByIdProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    MaterialHandlingEquipmentByIdQuery,
    MaterialHandlingEquipmentByIdQueryVariables,
    MaterialHandlingEquipmentByIdProps<TChildProps>
  >(MaterialHandlingEquipmentByIdDocument, {
    alias: 'materialHandlingEquipmentById',
    ...operationOptions
  })
}

/**
 * __useMaterialHandlingEquipmentByIdQuery__
 *
 * To run a query within a React component, call `useMaterialHandlingEquipmentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaterialHandlingEquipmentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaterialHandlingEquipmentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useMaterialHandlingEquipmentByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MaterialHandlingEquipmentByIdQuery,
    MaterialHandlingEquipmentByIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    MaterialHandlingEquipmentByIdQuery,
    MaterialHandlingEquipmentByIdQueryVariables
  >(MaterialHandlingEquipmentByIdDocument, baseOptions)
}
export function useMaterialHandlingEquipmentByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MaterialHandlingEquipmentByIdQuery,
    MaterialHandlingEquipmentByIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    MaterialHandlingEquipmentByIdQuery,
    MaterialHandlingEquipmentByIdQueryVariables
  >(MaterialHandlingEquipmentByIdDocument, baseOptions)
}
export type MaterialHandlingEquipmentByIdQueryHookResult = ReturnType<
  typeof useMaterialHandlingEquipmentByIdQuery
>
export type MaterialHandlingEquipmentByIdLazyQueryHookResult = ReturnType<
  typeof useMaterialHandlingEquipmentByIdLazyQuery
>
export type MaterialHandlingEquipmentByIdQueryResult = ApolloReactCommon.QueryResult<
  MaterialHandlingEquipmentByIdQuery,
  MaterialHandlingEquipmentByIdQueryVariables
>
export const OrderEntryDocument = gql`
  query orderEntry($code: String!) {
    orderEntry(code: $code) @client {
      ...orderEntry
      destinationCode {
        ...destinationCode
      }
      budgetHolder {
        ...budgetHolder
      }
      orderType {
        ...orderType
      }
    }
  }
  ${OrderEntryFragmentDoc}
  ${DestinationCodeFragmentDoc}
  ${BudgetHolderFragmentDoc}
  ${OrderTypeFragmentDoc}
`
export type OrderEntryComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<OrderEntryQuery, OrderEntryQueryVariables>,
  'query'
> &
  ({ variables: OrderEntryQueryVariables; skip?: boolean } | { skip: boolean })

export const OrderEntryComponent = (props: OrderEntryComponentProps) => (
  <ApolloReactComponents.Query<OrderEntryQuery, OrderEntryQueryVariables>
    query={OrderEntryDocument}
    {...props}
  />
)

export type OrderEntryProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  OrderEntryQuery,
  OrderEntryQueryVariables
> &
  TChildProps
export function withOrderEntry<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OrderEntryQuery,
    OrderEntryQueryVariables,
    OrderEntryProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    OrderEntryQuery,
    OrderEntryQueryVariables,
    OrderEntryProps<TChildProps>
  >(OrderEntryDocument, {
    alias: 'orderEntry',
    ...operationOptions
  })
}

/**
 * __useOrderEntryQuery__
 *
 * To run a query within a React component, call `useOrderEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderEntryQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useOrderEntryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<OrderEntryQuery, OrderEntryQueryVariables>
) {
  return ApolloReactHooks.useQuery<OrderEntryQuery, OrderEntryQueryVariables>(
    OrderEntryDocument,
    baseOptions
  )
}
export function useOrderEntryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderEntryQuery, OrderEntryQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<OrderEntryQuery, OrderEntryQueryVariables>(
    OrderEntryDocument,
    baseOptions
  )
}
export type OrderEntryQueryHookResult = ReturnType<typeof useOrderEntryQuery>
export type OrderEntryLazyQueryHookResult = ReturnType<typeof useOrderEntryLazyQuery>
export type OrderEntryQueryResult = ApolloReactCommon.QueryResult<
  OrderEntryQuery,
  OrderEntryQueryVariables
>
export const OrderEntriesDocument = gql`
  query orderEntries($filterInput: OrderFilter) {
    orderEntries(where: $filterInput) @client {
      ...orderEntry
      destinationCode {
        ...destinationCode
      }
      budgetHolder {
        ...budgetHolder
      }
      orderType {
        ...orderType
      }
    }
  }
  ${OrderEntryFragmentDoc}
  ${DestinationCodeFragmentDoc}
  ${BudgetHolderFragmentDoc}
  ${OrderTypeFragmentDoc}
`
export type OrderEntriesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<OrderEntriesQuery, OrderEntriesQueryVariables>,
  'query'
>

export const OrderEntriesComponent = (props: OrderEntriesComponentProps) => (
  <ApolloReactComponents.Query<OrderEntriesQuery, OrderEntriesQueryVariables>
    query={OrderEntriesDocument}
    {...props}
  />
)

export type OrderEntriesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  OrderEntriesQuery,
  OrderEntriesQueryVariables
> &
  TChildProps
export function withOrderEntries<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OrderEntriesQuery,
    OrderEntriesQueryVariables,
    OrderEntriesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    OrderEntriesQuery,
    OrderEntriesQueryVariables,
    OrderEntriesProps<TChildProps>
  >(OrderEntriesDocument, {
    alias: 'orderEntries',
    ...operationOptions
  })
}

/**
 * __useOrderEntriesQuery__
 *
 * To run a query within a React component, call `useOrderEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderEntriesQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *   },
 * });
 */
export function useOrderEntriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<OrderEntriesQuery, OrderEntriesQueryVariables>
) {
  return ApolloReactHooks.useQuery<OrderEntriesQuery, OrderEntriesQueryVariables>(
    OrderEntriesDocument,
    baseOptions
  )
}
export function useOrderEntriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderEntriesQuery, OrderEntriesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<OrderEntriesQuery, OrderEntriesQueryVariables>(
    OrderEntriesDocument,
    baseOptions
  )
}
export type OrderEntriesQueryHookResult = ReturnType<typeof useOrderEntriesQuery>
export type OrderEntriesLazyQueryHookResult = ReturnType<typeof useOrderEntriesLazyQuery>
export type OrderEntriesQueryResult = ApolloReactCommon.QueryResult<
  OrderEntriesQuery,
  OrderEntriesQueryVariables
>
export const OrderTypesDocument = gql`
  query orderTypes {
    orderTypes @client {
      ...orderType
    }
  }
  ${OrderTypeFragmentDoc}
`
export type OrderTypesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<OrderTypesQuery, OrderTypesQueryVariables>,
  'query'
>

export const OrderTypesComponent = (props: OrderTypesComponentProps) => (
  <ApolloReactComponents.Query<OrderTypesQuery, OrderTypesQueryVariables>
    query={OrderTypesDocument}
    {...props}
  />
)

export type OrderTypesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  OrderTypesQuery,
  OrderTypesQueryVariables
> &
  TChildProps
export function withOrderTypes<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    OrderTypesQuery,
    OrderTypesQueryVariables,
    OrderTypesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    OrderTypesQuery,
    OrderTypesQueryVariables,
    OrderTypesProps<TChildProps>
  >(OrderTypesDocument, {
    alias: 'orderTypes',
    ...operationOptions
  })
}

/**
 * __useOrderTypesQuery__
 *
 * To run a query within a React component, call `useOrderTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrderTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<OrderTypesQuery, OrderTypesQueryVariables>
) {
  return ApolloReactHooks.useQuery<OrderTypesQuery, OrderTypesQueryVariables>(
    OrderTypesDocument,
    baseOptions
  )
}
export function useOrderTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrderTypesQuery, OrderTypesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<OrderTypesQuery, OrderTypesQueryVariables>(
    OrderTypesDocument,
    baseOptions
  )
}
export type OrderTypesQueryHookResult = ReturnType<typeof useOrderTypesQuery>
export type OrderTypesLazyQueryHookResult = ReturnType<typeof useOrderTypesLazyQuery>
export type OrderTypesQueryResult = ApolloReactCommon.QueryResult<
  OrderTypesQuery,
  OrderTypesQueryVariables
>
export const PoFormatsDocument = gql`
  query poFormats {
    poFormats @client {
      ...poFormat
    }
  }
  ${PoFormatFragmentDoc}
`
export type PoFormatsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<PoFormatsQuery, PoFormatsQueryVariables>,
  'query'
>

export const PoFormatsComponent = (props: PoFormatsComponentProps) => (
  <ApolloReactComponents.Query<PoFormatsQuery, PoFormatsQueryVariables>
    query={PoFormatsDocument}
    {...props}
  />
)

export type PoFormatsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  PoFormatsQuery,
  PoFormatsQueryVariables
> &
  TChildProps
export function withPoFormats<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PoFormatsQuery,
    PoFormatsQueryVariables,
    PoFormatsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PoFormatsQuery,
    PoFormatsQueryVariables,
    PoFormatsProps<TChildProps>
  >(PoFormatsDocument, {
    alias: 'poFormats',
    ...operationOptions
  })
}

/**
 * __usePoFormatsQuery__
 *
 * To run a query within a React component, call `usePoFormatsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePoFormatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePoFormatsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePoFormatsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PoFormatsQuery, PoFormatsQueryVariables>
) {
  return ApolloReactHooks.useQuery<PoFormatsQuery, PoFormatsQueryVariables>(
    PoFormatsDocument,
    baseOptions
  )
}
export function usePoFormatsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PoFormatsQuery, PoFormatsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<PoFormatsQuery, PoFormatsQueryVariables>(
    PoFormatsDocument,
    baseOptions
  )
}
export type PoFormatsQueryHookResult = ReturnType<typeof usePoFormatsQuery>
export type PoFormatsLazyQueryHookResult = ReturnType<typeof usePoFormatsLazyQuery>
export type PoFormatsQueryResult = ApolloReactCommon.QueryResult<
  PoFormatsQuery,
  PoFormatsQueryVariables
>
export const PoTransmissionMethodsDocument = gql`
  query poTransmissionMethods {
    poTransmissionMethods @client {
      ...poTransmissionMethod
    }
  }
  ${PoTransmissionMethodFragmentDoc}
`
export type PoTransmissionMethodsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PoTransmissionMethodsQuery,
    PoTransmissionMethodsQueryVariables
  >,
  'query'
>

export const PoTransmissionMethodsComponent = (props: PoTransmissionMethodsComponentProps) => (
  <ApolloReactComponents.Query<PoTransmissionMethodsQuery, PoTransmissionMethodsQueryVariables>
    query={PoTransmissionMethodsDocument}
    {...props}
  />
)

export type PoTransmissionMethodsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  PoTransmissionMethodsQuery,
  PoTransmissionMethodsQueryVariables
> &
  TChildProps
export function withPoTransmissionMethods<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    PoTransmissionMethodsQuery,
    PoTransmissionMethodsQueryVariables,
    PoTransmissionMethodsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    PoTransmissionMethodsQuery,
    PoTransmissionMethodsQueryVariables,
    PoTransmissionMethodsProps<TChildProps>
  >(PoTransmissionMethodsDocument, {
    alias: 'poTransmissionMethods',
    ...operationOptions
  })
}

/**
 * __usePoTransmissionMethodsQuery__
 *
 * To run a query within a React component, call `usePoTransmissionMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePoTransmissionMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePoTransmissionMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePoTransmissionMethodsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PoTransmissionMethodsQuery,
    PoTransmissionMethodsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<PoTransmissionMethodsQuery, PoTransmissionMethodsQueryVariables>(
    PoTransmissionMethodsDocument,
    baseOptions
  )
}
export function usePoTransmissionMethodsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PoTransmissionMethodsQuery,
    PoTransmissionMethodsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    PoTransmissionMethodsQuery,
    PoTransmissionMethodsQueryVariables
  >(PoTransmissionMethodsDocument, baseOptions)
}
export type PoTransmissionMethodsQueryHookResult = ReturnType<typeof usePoTransmissionMethodsQuery>
export type PoTransmissionMethodsLazyQueryHookResult = ReturnType<
  typeof usePoTransmissionMethodsLazyQuery
>
export type PoTransmissionMethodsQueryResult = ApolloReactCommon.QueryResult<
  PoTransmissionMethodsQuery,
  PoTransmissionMethodsQueryVariables
>
export const ProductsDocument = gql`
  query products(
    $filterInput: ProductFilter
    $userContext: UserContextInput
    $includeLinked: Boolean!
  ) {
    product(where: $filterInput, context: $userContext, includeLinked: $includeLinked) {
      nodes {
        ...product
        pickfaces {
          ...pickface
        }
        licencePlates {
          ...licencePlate
          ...licencePlate
        }
        licencePlates {
          ...licencePlate
        }
        documents {
          ...document
        }
      }
    }
  }
  ${ProductFragmentDoc}
  ${PickfaceFragmentDoc}
  ${LicencePlateFragmentDoc}
  ${DocumentFragmentDoc}
`
export type ProductsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<ProductsQuery, ProductsQueryVariables>,
  'query'
> &
  ({ variables: ProductsQueryVariables; skip?: boolean } | { skip: boolean })

export const ProductsComponent = (props: ProductsComponentProps) => (
  <ApolloReactComponents.Query<ProductsQuery, ProductsQueryVariables>
    query={ProductsDocument}
    {...props}
  />
)

export type ProductsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ProductsQuery,
  ProductsQueryVariables
> &
  TChildProps
export function withProducts<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ProductsQuery,
    ProductsQueryVariables,
    ProductsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ProductsQuery,
    ProductsQueryVariables,
    ProductsProps<TChildProps>
  >(ProductsDocument, {
    alias: 'products',
    ...operationOptions
  })
}

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      userContext: // value for 'userContext'
 *      includeLinked: // value for 'includeLinked'
 *   },
 * });
 */
export function useProductsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ProductsQuery, ProductsQueryVariables>
) {
  return ApolloReactHooks.useQuery<ProductsQuery, ProductsQueryVariables>(
    ProductsDocument,
    baseOptions
  )
}
export function useProductsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<ProductsQuery, ProductsQueryVariables>(
    ProductsDocument,
    baseOptions
  )
}
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>
export type ProductsQueryResult = ApolloReactCommon.QueryResult<
  ProductsQuery,
  ProductsQueryVariables
>
export const SelectProductsDocument = gql`
  query selectProducts(
    $filterInput: ProductFilter
    $userContext: UserContextInput
    $includeLinked: Boolean!
  ) {
    product(where: $filterInput, context: $userContext, includeLinked: $includeLinked) {
      nodes {
        id
        code
        description
      }
    }
  }
`
export type SelectProductsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<SelectProductsQuery, SelectProductsQueryVariables>,
  'query'
> &
  ({ variables: SelectProductsQueryVariables; skip?: boolean } | { skip: boolean })

export const SelectProductsComponent = (props: SelectProductsComponentProps) => (
  <ApolloReactComponents.Query<SelectProductsQuery, SelectProductsQueryVariables>
    query={SelectProductsDocument}
    {...props}
  />
)

export type SelectProductsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  SelectProductsQuery,
  SelectProductsQueryVariables
> &
  TChildProps
export function withSelectProducts<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SelectProductsQuery,
    SelectProductsQueryVariables,
    SelectProductsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SelectProductsQuery,
    SelectProductsQueryVariables,
    SelectProductsProps<TChildProps>
  >(SelectProductsDocument, {
    alias: 'selectProducts',
    ...operationOptions
  })
}

/**
 * __useSelectProductsQuery__
 *
 * To run a query within a React component, call `useSelectProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectProductsQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      userContext: // value for 'userContext'
 *      includeLinked: // value for 'includeLinked'
 *   },
 * });
 */
export function useSelectProductsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SelectProductsQuery, SelectProductsQueryVariables>
) {
  return ApolloReactHooks.useQuery<SelectProductsQuery, SelectProductsQueryVariables>(
    SelectProductsDocument,
    baseOptions
  )
}
export function useSelectProductsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SelectProductsQuery,
    SelectProductsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<SelectProductsQuery, SelectProductsQueryVariables>(
    SelectProductsDocument,
    baseOptions
  )
}
export type SelectProductsQueryHookResult = ReturnType<typeof useSelectProductsQuery>
export type SelectProductsLazyQueryHookResult = ReturnType<typeof useSelectProductsLazyQuery>
export type SelectProductsQueryResult = ApolloReactCommon.QueryResult<
  SelectProductsQuery,
  SelectProductsQueryVariables
>
export const ProductsPaginatedDocument = gql`
  query productsPaginated(
    $filterInput: ProductFilter
    $userContext: UserContextInput
    $includeLinked: Boolean!
    $first: Int
    $after: String
  ) {
    product(
      where: $filterInput
      context: $userContext
      includeLinked: $includeLinked
      first: $first
      after: $after
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        id
        code
        description
      }
    }
  }
`
export type ProductsPaginatedComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ProductsPaginatedQuery,
    ProductsPaginatedQueryVariables
  >,
  'query'
> &
  ({ variables: ProductsPaginatedQueryVariables; skip?: boolean } | { skip: boolean })

export const ProductsPaginatedComponent = (props: ProductsPaginatedComponentProps) => (
  <ApolloReactComponents.Query<ProductsPaginatedQuery, ProductsPaginatedQueryVariables>
    query={ProductsPaginatedDocument}
    {...props}
  />
)

export type ProductsPaginatedProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ProductsPaginatedQuery,
  ProductsPaginatedQueryVariables
> &
  TChildProps
export function withProductsPaginated<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ProductsPaginatedQuery,
    ProductsPaginatedQueryVariables,
    ProductsPaginatedProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ProductsPaginatedQuery,
    ProductsPaginatedQueryVariables,
    ProductsPaginatedProps<TChildProps>
  >(ProductsPaginatedDocument, {
    alias: 'productsPaginated',
    ...operationOptions
  })
}

/**
 * __useProductsPaginatedQuery__
 *
 * To run a query within a React component, call `useProductsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsPaginatedQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      userContext: // value for 'userContext'
 *      includeLinked: // value for 'includeLinked'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useProductsPaginatedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    ProductsPaginatedQuery,
    ProductsPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<ProductsPaginatedQuery, ProductsPaginatedQueryVariables>(
    ProductsPaginatedDocument,
    baseOptions
  )
}
export function useProductsPaginatedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ProductsPaginatedQuery,
    ProductsPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<ProductsPaginatedQuery, ProductsPaginatedQueryVariables>(
    ProductsPaginatedDocument,
    baseOptions
  )
}
export type ProductsPaginatedQueryHookResult = ReturnType<typeof useProductsPaginatedQuery>
export type ProductsPaginatedLazyQueryHookResult = ReturnType<typeof useProductsPaginatedLazyQuery>
export type ProductsPaginatedQueryResult = ApolloReactCommon.QueryResult<
  ProductsPaginatedQuery,
  ProductsPaginatedQueryVariables
>
export const ProductByIdDocument = gql`
  query productById($id: Int!, $userContext: UserContextInput) {
    productById(id: $id, context: $userContext) {
      ...product
      pickfaces {
        ...pickface
      }
      licencePlates {
        ...licencePlate
        ...licencePlate
      }
      licencePlates {
        ...licencePlate
      }
      documents {
        ...document
      }
    }
  }
  ${ProductFragmentDoc}
  ${PickfaceFragmentDoc}
  ${LicencePlateFragmentDoc}
  ${DocumentFragmentDoc}
`
export type ProductByIdComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<ProductByIdQuery, ProductByIdQueryVariables>,
  'query'
> &
  ({ variables: ProductByIdQueryVariables; skip?: boolean } | { skip: boolean })

export const ProductByIdComponent = (props: ProductByIdComponentProps) => (
  <ApolloReactComponents.Query<ProductByIdQuery, ProductByIdQueryVariables>
    query={ProductByIdDocument}
    {...props}
  />
)

export type ProductByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ProductByIdQuery,
  ProductByIdQueryVariables
> &
  TChildProps
export function withProductById<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ProductByIdQuery,
    ProductByIdQueryVariables,
    ProductByIdProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ProductByIdQuery,
    ProductByIdQueryVariables,
    ProductByIdProps<TChildProps>
  >(ProductByIdDocument, {
    alias: 'productById',
    ...operationOptions
  })
}

/**
 * __useProductByIdQuery__
 *
 * To run a query within a React component, call `useProductByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useProductByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ProductByIdQuery, ProductByIdQueryVariables>
) {
  return ApolloReactHooks.useQuery<ProductByIdQuery, ProductByIdQueryVariables>(
    ProductByIdDocument,
    baseOptions
  )
}
export function useProductByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProductByIdQuery, ProductByIdQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<ProductByIdQuery, ProductByIdQueryVariables>(
    ProductByIdDocument,
    baseOptions
  )
}
export type ProductByIdQueryHookResult = ReturnType<typeof useProductByIdQuery>
export type ProductByIdLazyQueryHookResult = ReturnType<typeof useProductByIdLazyQuery>
export type ProductByIdQueryResult = ApolloReactCommon.QueryResult<
  ProductByIdQuery,
  ProductByIdQueryVariables
>
export const CultureDocument = gql`
  query culture($resourceSet: String!) {
    resources {
      allLocaleIds(resourceSet: $resourceSet) {
        name
        displayName
        nativeName
        englishName
      }
    }
  }
`
export type CultureComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CultureQuery, CultureQueryVariables>,
  'query'
> &
  ({ variables: CultureQueryVariables; skip?: boolean } | { skip: boolean })

export const CultureComponent = (props: CultureComponentProps) => (
  <ApolloReactComponents.Query<CultureQuery, CultureQueryVariables>
    query={CultureDocument}
    {...props}
  />
)

export type CultureProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CultureQuery,
  CultureQueryVariables
> &
  TChildProps
export function withCulture<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CultureQuery,
    CultureQueryVariables,
    CultureProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CultureQuery,
    CultureQueryVariables,
    CultureProps<TChildProps>
  >(CultureDocument, {
    alias: 'culture',
    ...operationOptions
  })
}

/**
 * __useCultureQuery__
 *
 * To run a query within a React component, call `useCultureQuery` and pass it any options that fit your needs.
 * When your component renders, `useCultureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCultureQuery({
 *   variables: {
 *      resourceSet: // value for 'resourceSet'
 *   },
 * });
 */
export function useCultureQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CultureQuery, CultureQueryVariables>
) {
  return ApolloReactHooks.useQuery<CultureQuery, CultureQueryVariables>(
    CultureDocument,
    baseOptions
  )
}
export function useCultureLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CultureQuery, CultureQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CultureQuery, CultureQueryVariables>(
    CultureDocument,
    baseOptions
  )
}
export type CultureQueryHookResult = ReturnType<typeof useCultureQuery>
export type CultureLazyQueryHookResult = ReturnType<typeof useCultureLazyQuery>
export type CultureQueryResult = ApolloReactCommon.QueryResult<CultureQuery, CultureQueryVariables>
export const CultureByNameDocument = gql`
  query cultureByName($filterInput: CultureInfoFilterInput) {
    resources {
      cultures(where: $filterInput) {
        nodes {
          LCID
          name
          displayName
          nativeName
          englishName
        }
      }
    }
  }
`
export type CultureByNameComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CultureByNameQuery, CultureByNameQueryVariables>,
  'query'
>

export const CultureByNameComponent = (props: CultureByNameComponentProps) => (
  <ApolloReactComponents.Query<CultureByNameQuery, CultureByNameQueryVariables>
    query={CultureByNameDocument}
    {...props}
  />
)

export type CultureByNameProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CultureByNameQuery,
  CultureByNameQueryVariables
> &
  TChildProps
export function withCultureByName<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CultureByNameQuery,
    CultureByNameQueryVariables,
    CultureByNameProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CultureByNameQuery,
    CultureByNameQueryVariables,
    CultureByNameProps<TChildProps>
  >(CultureByNameDocument, {
    alias: 'cultureByName',
    ...operationOptions
  })
}

/**
 * __useCultureByNameQuery__
 *
 * To run a query within a React component, call `useCultureByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useCultureByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCultureByNameQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *   },
 * });
 */
export function useCultureByNameQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CultureByNameQuery, CultureByNameQueryVariables>
) {
  return ApolloReactHooks.useQuery<CultureByNameQuery, CultureByNameQueryVariables>(
    CultureByNameDocument,
    baseOptions
  )
}
export function useCultureByNameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CultureByNameQuery,
    CultureByNameQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<CultureByNameQuery, CultureByNameQueryVariables>(
    CultureByNameDocument,
    baseOptions
  )
}
export type CultureByNameQueryHookResult = ReturnType<typeof useCultureByNameQuery>
export type CultureByNameLazyQueryHookResult = ReturnType<typeof useCultureByNameLazyQuery>
export type CultureByNameQueryResult = ApolloReactCommon.QueryResult<
  CultureByNameQuery,
  CultureByNameQueryVariables
>
export const StockEnquiryDocument = gql`
  query stockEnquiry(
    $filterInput: StockFilterInput
    $userContext: UserContextInput
    $first: Int
    $after: String
    $before: String
  ) {
    stock {
      stock(
        where: $filterInput
        userContext: $userContext
        first: $first
        after: $after
        before: $before
      ) {
        totalCount
        pageInfo {
          hasNextPage
          startCursor
          endCursor
          startCursor
        }
        nodes {
          ...stock
        }
      }
    }
  }
  ${StockFragmentDoc}
`
export type StockEnquiryComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<StockEnquiryQuery, StockEnquiryQueryVariables>,
  'query'
>

export const StockEnquiryComponent = (props: StockEnquiryComponentProps) => (
  <ApolloReactComponents.Query<StockEnquiryQuery, StockEnquiryQueryVariables>
    query={StockEnquiryDocument}
    {...props}
  />
)

export type StockEnquiryProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  StockEnquiryQuery,
  StockEnquiryQueryVariables
> &
  TChildProps
export function withStockEnquiry<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    StockEnquiryQuery,
    StockEnquiryQueryVariables,
    StockEnquiryProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    StockEnquiryQuery,
    StockEnquiryQueryVariables,
    StockEnquiryProps<TChildProps>
  >(StockEnquiryDocument, {
    alias: 'stockEnquiry',
    ...operationOptions
  })
}

/**
 * __useStockEnquiryQuery__
 *
 * To run a query within a React component, call `useStockEnquiryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockEnquiryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockEnquiryQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      userContext: // value for 'userContext'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useStockEnquiryQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<StockEnquiryQuery, StockEnquiryQueryVariables>
) {
  return ApolloReactHooks.useQuery<StockEnquiryQuery, StockEnquiryQueryVariables>(
    StockEnquiryDocument,
    baseOptions
  )
}
export function useStockEnquiryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StockEnquiryQuery, StockEnquiryQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<StockEnquiryQuery, StockEnquiryQueryVariables>(
    StockEnquiryDocument,
    baseOptions
  )
}
export type StockEnquiryQueryHookResult = ReturnType<typeof useStockEnquiryQuery>
export type StockEnquiryLazyQueryHookResult = ReturnType<typeof useStockEnquiryLazyQuery>
export type StockEnquiryQueryResult = ApolloReactCommon.QueryResult<
  StockEnquiryQuery,
  StockEnquiryQueryVariables
>
export const StockOwnersDocument = gql`
  query stockOwners($filterInput: StockOwnerFilter) {
    stockOwners(where: $filterInput) {
      nodes {
        ...stockOwner
        linkedStockOwners {
          child {
            id
            code
          }
        }
        address {
          ...address
        }
        categories {
          ...category
        }
        contact {
          ...contact
        }
        productStatuses {
          ...productStatuses
        }
        orderStatuses {
          ...orderStatus
        }
        pickMethods {
          ...pickMethod
        }
        confirmMethods {
          ...pickConfirmMethod
        }
      }
    }
  }
  ${StockOwnerFragmentDoc}
  ${AddressFragmentDoc}
  ${CategoryFragmentDoc}
  ${ContactFragmentDoc}
  ${ProductStatusesFragmentDoc}
  ${OrderStatusFragmentDoc}
  ${PickMethodFragmentDoc}
  ${PickConfirmMethodFragmentDoc}
`
export type StockOwnersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<StockOwnersQuery, StockOwnersQueryVariables>,
  'query'
>

export const StockOwnersComponent = (props: StockOwnersComponentProps) => (
  <ApolloReactComponents.Query<StockOwnersQuery, StockOwnersQueryVariables>
    query={StockOwnersDocument}
    {...props}
  />
)

export type StockOwnersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  StockOwnersQuery,
  StockOwnersQueryVariables
> &
  TChildProps
export function withStockOwners<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    StockOwnersQuery,
    StockOwnersQueryVariables,
    StockOwnersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    StockOwnersQuery,
    StockOwnersQueryVariables,
    StockOwnersProps<TChildProps>
  >(StockOwnersDocument, {
    alias: 'stockOwners',
    ...operationOptions
  })
}

/**
 * __useStockOwnersQuery__
 *
 * To run a query within a React component, call `useStockOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockOwnersQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *   },
 * });
 */
export function useStockOwnersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<StockOwnersQuery, StockOwnersQueryVariables>
) {
  return ApolloReactHooks.useQuery<StockOwnersQuery, StockOwnersQueryVariables>(
    StockOwnersDocument,
    baseOptions
  )
}
export function useStockOwnersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StockOwnersQuery, StockOwnersQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<StockOwnersQuery, StockOwnersQueryVariables>(
    StockOwnersDocument,
    baseOptions
  )
}
export type StockOwnersQueryHookResult = ReturnType<typeof useStockOwnersQuery>
export type StockOwnersLazyQueryHookResult = ReturnType<typeof useStockOwnersLazyQuery>
export type StockOwnersQueryResult = ApolloReactCommon.QueryResult<
  StockOwnersQuery,
  StockOwnersQueryVariables
>
export const StockOwnerCategoriesDocument = gql`
  query stockOwnerCategories($id: Int!) {
    stockOwnerById(id: $id) {
      categories {
        code
        description
      }
    }
  }
`
export type StockOwnerCategoriesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    StockOwnerCategoriesQuery,
    StockOwnerCategoriesQueryVariables
  >,
  'query'
> &
  ({ variables: StockOwnerCategoriesQueryVariables; skip?: boolean } | { skip: boolean })

export const StockOwnerCategoriesComponent = (props: StockOwnerCategoriesComponentProps) => (
  <ApolloReactComponents.Query<StockOwnerCategoriesQuery, StockOwnerCategoriesQueryVariables>
    query={StockOwnerCategoriesDocument}
    {...props}
  />
)

export type StockOwnerCategoriesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  StockOwnerCategoriesQuery,
  StockOwnerCategoriesQueryVariables
> &
  TChildProps
export function withStockOwnerCategories<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    StockOwnerCategoriesQuery,
    StockOwnerCategoriesQueryVariables,
    StockOwnerCategoriesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    StockOwnerCategoriesQuery,
    StockOwnerCategoriesQueryVariables,
    StockOwnerCategoriesProps<TChildProps>
  >(StockOwnerCategoriesDocument, {
    alias: 'stockOwnerCategories',
    ...operationOptions
  })
}

/**
 * __useStockOwnerCategoriesQuery__
 *
 * To run a query within a React component, call `useStockOwnerCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockOwnerCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockOwnerCategoriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStockOwnerCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StockOwnerCategoriesQuery,
    StockOwnerCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<StockOwnerCategoriesQuery, StockOwnerCategoriesQueryVariables>(
    StockOwnerCategoriesDocument,
    baseOptions
  )
}
export function useStockOwnerCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StockOwnerCategoriesQuery,
    StockOwnerCategoriesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    StockOwnerCategoriesQuery,
    StockOwnerCategoriesQueryVariables
  >(StockOwnerCategoriesDocument, baseOptions)
}
export type StockOwnerCategoriesQueryHookResult = ReturnType<typeof useStockOwnerCategoriesQuery>
export type StockOwnerCategoriesLazyQueryHookResult = ReturnType<
  typeof useStockOwnerCategoriesLazyQuery
>
export type StockOwnerCategoriesQueryResult = ApolloReactCommon.QueryResult<
  StockOwnerCategoriesQuery,
  StockOwnerCategoriesQueryVariables
>
export const StockOwnerProductStatusesDocument = gql`
  query stockOwnerProductStatuses($id: Int!) {
    stockOwnerById(id: $id) {
      productStatuses {
        ...productStatuses
      }
    }
  }
  ${ProductStatusesFragmentDoc}
`
export type StockOwnerProductStatusesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    StockOwnerProductStatusesQuery,
    StockOwnerProductStatusesQueryVariables
  >,
  'query'
> &
  ({ variables: StockOwnerProductStatusesQueryVariables; skip?: boolean } | { skip: boolean })

export const StockOwnerProductStatusesComponent = (
  props: StockOwnerProductStatusesComponentProps
) => (
  <ApolloReactComponents.Query<
    StockOwnerProductStatusesQuery,
    StockOwnerProductStatusesQueryVariables
  >
    query={StockOwnerProductStatusesDocument}
    {...props}
  />
)

export type StockOwnerProductStatusesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  StockOwnerProductStatusesQuery,
  StockOwnerProductStatusesQueryVariables
> &
  TChildProps
export function withStockOwnerProductStatuses<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    StockOwnerProductStatusesQuery,
    StockOwnerProductStatusesQueryVariables,
    StockOwnerProductStatusesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    StockOwnerProductStatusesQuery,
    StockOwnerProductStatusesQueryVariables,
    StockOwnerProductStatusesProps<TChildProps>
  >(StockOwnerProductStatusesDocument, {
    alias: 'stockOwnerProductStatuses',
    ...operationOptions
  })
}

/**
 * __useStockOwnerProductStatusesQuery__
 *
 * To run a query within a React component, call `useStockOwnerProductStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockOwnerProductStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockOwnerProductStatusesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStockOwnerProductStatusesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StockOwnerProductStatusesQuery,
    StockOwnerProductStatusesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    StockOwnerProductStatusesQuery,
    StockOwnerProductStatusesQueryVariables
  >(StockOwnerProductStatusesDocument, baseOptions)
}
export function useStockOwnerProductStatusesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StockOwnerProductStatusesQuery,
    StockOwnerProductStatusesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    StockOwnerProductStatusesQuery,
    StockOwnerProductStatusesQueryVariables
  >(StockOwnerProductStatusesDocument, baseOptions)
}
export type StockOwnerProductStatusesQueryHookResult = ReturnType<
  typeof useStockOwnerProductStatusesQuery
>
export type StockOwnerProductStatusesLazyQueryHookResult = ReturnType<
  typeof useStockOwnerProductStatusesLazyQuery
>
export type StockOwnerProductStatusesQueryResult = ApolloReactCommon.QueryResult<
  StockOwnerProductStatusesQuery,
  StockOwnerProductStatusesQueryVariables
>
export const StockOwnerPickMethodsDocument = gql`
  query stockOwnerPickMethods($id: Int!) {
    stockOwnerById(id: $id) {
      pickMethods {
        id
        name
      }
    }
  }
`
export type StockOwnerPickMethodsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    StockOwnerPickMethodsQuery,
    StockOwnerPickMethodsQueryVariables
  >,
  'query'
> &
  ({ variables: StockOwnerPickMethodsQueryVariables; skip?: boolean } | { skip: boolean })

export const StockOwnerPickMethodsComponent = (props: StockOwnerPickMethodsComponentProps) => (
  <ApolloReactComponents.Query<StockOwnerPickMethodsQuery, StockOwnerPickMethodsQueryVariables>
    query={StockOwnerPickMethodsDocument}
    {...props}
  />
)

export type StockOwnerPickMethodsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  StockOwnerPickMethodsQuery,
  StockOwnerPickMethodsQueryVariables
> &
  TChildProps
export function withStockOwnerPickMethods<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    StockOwnerPickMethodsQuery,
    StockOwnerPickMethodsQueryVariables,
    StockOwnerPickMethodsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    StockOwnerPickMethodsQuery,
    StockOwnerPickMethodsQueryVariables,
    StockOwnerPickMethodsProps<TChildProps>
  >(StockOwnerPickMethodsDocument, {
    alias: 'stockOwnerPickMethods',
    ...operationOptions
  })
}

/**
 * __useStockOwnerPickMethodsQuery__
 *
 * To run a query within a React component, call `useStockOwnerPickMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockOwnerPickMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockOwnerPickMethodsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStockOwnerPickMethodsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StockOwnerPickMethodsQuery,
    StockOwnerPickMethodsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<StockOwnerPickMethodsQuery, StockOwnerPickMethodsQueryVariables>(
    StockOwnerPickMethodsDocument,
    baseOptions
  )
}
export function useStockOwnerPickMethodsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StockOwnerPickMethodsQuery,
    StockOwnerPickMethodsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    StockOwnerPickMethodsQuery,
    StockOwnerPickMethodsQueryVariables
  >(StockOwnerPickMethodsDocument, baseOptions)
}
export type StockOwnerPickMethodsQueryHookResult = ReturnType<typeof useStockOwnerPickMethodsQuery>
export type StockOwnerPickMethodsLazyQueryHookResult = ReturnType<
  typeof useStockOwnerPickMethodsLazyQuery
>
export type StockOwnerPickMethodsQueryResult = ApolloReactCommon.QueryResult<
  StockOwnerPickMethodsQuery,
  StockOwnerPickMethodsQueryVariables
>
export const LinkedStockOwnersDocument = gql`
  query linkedStockOwners {
    stockOwners {
      nodes {
        id
        code
      }
    }
  }
`
export type LinkedStockOwnersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    LinkedStockOwnersQuery,
    LinkedStockOwnersQueryVariables
  >,
  'query'
>

export const LinkedStockOwnersComponent = (props: LinkedStockOwnersComponentProps) => (
  <ApolloReactComponents.Query<LinkedStockOwnersQuery, LinkedStockOwnersQueryVariables>
    query={LinkedStockOwnersDocument}
    {...props}
  />
)

export type LinkedStockOwnersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  LinkedStockOwnersQuery,
  LinkedStockOwnersQueryVariables
> &
  TChildProps
export function withLinkedStockOwners<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    LinkedStockOwnersQuery,
    LinkedStockOwnersQueryVariables,
    LinkedStockOwnersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    LinkedStockOwnersQuery,
    LinkedStockOwnersQueryVariables,
    LinkedStockOwnersProps<TChildProps>
  >(LinkedStockOwnersDocument, {
    alias: 'linkedStockOwners',
    ...operationOptions
  })
}

/**
 * __useLinkedStockOwnersQuery__
 *
 * To run a query within a React component, call `useLinkedStockOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkedStockOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkedStockOwnersQuery({
 *   variables: {
 *   },
 * });
 */
export function useLinkedStockOwnersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LinkedStockOwnersQuery,
    LinkedStockOwnersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<LinkedStockOwnersQuery, LinkedStockOwnersQueryVariables>(
    LinkedStockOwnersDocument,
    baseOptions
  )
}
export function useLinkedStockOwnersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LinkedStockOwnersQuery,
    LinkedStockOwnersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<LinkedStockOwnersQuery, LinkedStockOwnersQueryVariables>(
    LinkedStockOwnersDocument,
    baseOptions
  )
}
export type LinkedStockOwnersQueryHookResult = ReturnType<typeof useLinkedStockOwnersQuery>
export type LinkedStockOwnersLazyQueryHookResult = ReturnType<typeof useLinkedStockOwnersLazyQuery>
export type LinkedStockOwnersQueryResult = ApolloReactCommon.QueryResult<
  LinkedStockOwnersQuery,
  LinkedStockOwnersQueryVariables
>
export const SelectStockOwnersDocument = gql`
  query selectStockOwners {
    stockOwners {
      nodes {
        id
        code
        name
      }
    }
  }
`
export type SelectStockOwnersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SelectStockOwnersQuery,
    SelectStockOwnersQueryVariables
  >,
  'query'
>

export const SelectStockOwnersComponent = (props: SelectStockOwnersComponentProps) => (
  <ApolloReactComponents.Query<SelectStockOwnersQuery, SelectStockOwnersQueryVariables>
    query={SelectStockOwnersDocument}
    {...props}
  />
)

export type SelectStockOwnersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  SelectStockOwnersQuery,
  SelectStockOwnersQueryVariables
> &
  TChildProps
export function withSelectStockOwners<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SelectStockOwnersQuery,
    SelectStockOwnersQueryVariables,
    SelectStockOwnersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SelectStockOwnersQuery,
    SelectStockOwnersQueryVariables,
    SelectStockOwnersProps<TChildProps>
  >(SelectStockOwnersDocument, {
    alias: 'selectStockOwners',
    ...operationOptions
  })
}

/**
 * __useSelectStockOwnersQuery__
 *
 * To run a query within a React component, call `useSelectStockOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectStockOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectStockOwnersQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectStockOwnersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SelectStockOwnersQuery,
    SelectStockOwnersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<SelectStockOwnersQuery, SelectStockOwnersQueryVariables>(
    SelectStockOwnersDocument,
    baseOptions
  )
}
export function useSelectStockOwnersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SelectStockOwnersQuery,
    SelectStockOwnersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<SelectStockOwnersQuery, SelectStockOwnersQueryVariables>(
    SelectStockOwnersDocument,
    baseOptions
  )
}
export type SelectStockOwnersQueryHookResult = ReturnType<typeof useSelectStockOwnersQuery>
export type SelectStockOwnersLazyQueryHookResult = ReturnType<typeof useSelectStockOwnersLazyQuery>
export type SelectStockOwnersQueryResult = ApolloReactCommon.QueryResult<
  SelectStockOwnersQuery,
  SelectStockOwnersQueryVariables
>
export const StockOwnerPaginatedDocument = gql`
  query stockOwnerPaginated($filterInput: StockOwnerFilter, $first: Int, $after: String) {
    stockOwners(where: $filterInput, first: $first, after: $after) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      nodes {
        id
        code
        name
        status
      }
    }
  }
`
export type StockOwnerPaginatedComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    StockOwnerPaginatedQuery,
    StockOwnerPaginatedQueryVariables
  >,
  'query'
>

export const StockOwnerPaginatedComponent = (props: StockOwnerPaginatedComponentProps) => (
  <ApolloReactComponents.Query<StockOwnerPaginatedQuery, StockOwnerPaginatedQueryVariables>
    query={StockOwnerPaginatedDocument}
    {...props}
  />
)

export type StockOwnerPaginatedProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  StockOwnerPaginatedQuery,
  StockOwnerPaginatedQueryVariables
> &
  TChildProps
export function withStockOwnerPaginated<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    StockOwnerPaginatedQuery,
    StockOwnerPaginatedQueryVariables,
    StockOwnerPaginatedProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    StockOwnerPaginatedQuery,
    StockOwnerPaginatedQueryVariables,
    StockOwnerPaginatedProps<TChildProps>
  >(StockOwnerPaginatedDocument, {
    alias: 'stockOwnerPaginated',
    ...operationOptions
  })
}

/**
 * __useStockOwnerPaginatedQuery__
 *
 * To run a query within a React component, call `useStockOwnerPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockOwnerPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockOwnerPaginatedQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useStockOwnerPaginatedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StockOwnerPaginatedQuery,
    StockOwnerPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<StockOwnerPaginatedQuery, StockOwnerPaginatedQueryVariables>(
    StockOwnerPaginatedDocument,
    baseOptions
  )
}
export function useStockOwnerPaginatedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StockOwnerPaginatedQuery,
    StockOwnerPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<StockOwnerPaginatedQuery, StockOwnerPaginatedQueryVariables>(
    StockOwnerPaginatedDocument,
    baseOptions
  )
}
export type StockOwnerPaginatedQueryHookResult = ReturnType<typeof useStockOwnerPaginatedQuery>
export type StockOwnerPaginatedLazyQueryHookResult = ReturnType<
  typeof useStockOwnerPaginatedLazyQuery
>
export type StockOwnerPaginatedQueryResult = ApolloReactCommon.QueryResult<
  StockOwnerPaginatedQuery,
  StockOwnerPaginatedQueryVariables
>
export const StockOwnerByIdDocument = gql`
  query stockOwnerById($id: Int!) {
    stockOwnerById(id: $id) {
      ...stockOwner
      linkedStockOwners {
        child {
          id
          code
        }
      }
      address {
        ...address
      }
      categories {
        ...category
      }
      contact {
        ...contact
      }
      productStatuses {
        ...productStatuses
      }
      orderStatuses {
        ...orderStatus
      }
      pickMethods {
        ...pickMethod
      }
      confirmMethods {
        ...pickConfirmMethod
      }
    }
  }
  ${StockOwnerFragmentDoc}
  ${AddressFragmentDoc}
  ${CategoryFragmentDoc}
  ${ContactFragmentDoc}
  ${ProductStatusesFragmentDoc}
  ${OrderStatusFragmentDoc}
  ${PickMethodFragmentDoc}
  ${PickConfirmMethodFragmentDoc}
`
export type StockOwnerByIdComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<StockOwnerByIdQuery, StockOwnerByIdQueryVariables>,
  'query'
> &
  ({ variables: StockOwnerByIdQueryVariables; skip?: boolean } | { skip: boolean })

export const StockOwnerByIdComponent = (props: StockOwnerByIdComponentProps) => (
  <ApolloReactComponents.Query<StockOwnerByIdQuery, StockOwnerByIdQueryVariables>
    query={StockOwnerByIdDocument}
    {...props}
  />
)

export type StockOwnerByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  StockOwnerByIdQuery,
  StockOwnerByIdQueryVariables
> &
  TChildProps
export function withStockOwnerById<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    StockOwnerByIdQuery,
    StockOwnerByIdQueryVariables,
    StockOwnerByIdProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    StockOwnerByIdQuery,
    StockOwnerByIdQueryVariables,
    StockOwnerByIdProps<TChildProps>
  >(StockOwnerByIdDocument, {
    alias: 'stockOwnerById',
    ...operationOptions
  })
}

/**
 * __useStockOwnerByIdQuery__
 *
 * To run a query within a React component, call `useStockOwnerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockOwnerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockOwnerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStockOwnerByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<StockOwnerByIdQuery, StockOwnerByIdQueryVariables>
) {
  return ApolloReactHooks.useQuery<StockOwnerByIdQuery, StockOwnerByIdQueryVariables>(
    StockOwnerByIdDocument,
    baseOptions
  )
}
export function useStockOwnerByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StockOwnerByIdQuery,
    StockOwnerByIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<StockOwnerByIdQuery, StockOwnerByIdQueryVariables>(
    StockOwnerByIdDocument,
    baseOptions
  )
}
export type StockOwnerByIdQueryHookResult = ReturnType<typeof useStockOwnerByIdQuery>
export type StockOwnerByIdLazyQueryHookResult = ReturnType<typeof useStockOwnerByIdLazyQuery>
export type StockOwnerByIdQueryResult = ApolloReactCommon.QueryResult<
  StockOwnerByIdQuery,
  StockOwnerByIdQueryVariables
>
export const StorageTypesDocument = gql`
  query storageTypes {
    storageTypes {
      nodes {
        ...storageType
        MHEs {
          materialHandlingEquipment {
            id
            code
          }
        }
      }
    }
  }
  ${StorageTypeFragmentDoc}
`
export type StorageTypesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<StorageTypesQuery, StorageTypesQueryVariables>,
  'query'
>

export const StorageTypesComponent = (props: StorageTypesComponentProps) => (
  <ApolloReactComponents.Query<StorageTypesQuery, StorageTypesQueryVariables>
    query={StorageTypesDocument}
    {...props}
  />
)

export type StorageTypesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  StorageTypesQuery,
  StorageTypesQueryVariables
> &
  TChildProps
export function withStorageTypes<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    StorageTypesQuery,
    StorageTypesQueryVariables,
    StorageTypesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    StorageTypesQuery,
    StorageTypesQueryVariables,
    StorageTypesProps<TChildProps>
  >(StorageTypesDocument, {
    alias: 'storageTypes',
    ...operationOptions
  })
}

/**
 * __useStorageTypesQuery__
 *
 * To run a query within a React component, call `useStorageTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useStorageTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<StorageTypesQuery, StorageTypesQueryVariables>
) {
  return ApolloReactHooks.useQuery<StorageTypesQuery, StorageTypesQueryVariables>(
    StorageTypesDocument,
    baseOptions
  )
}
export function useStorageTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StorageTypesQuery, StorageTypesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<StorageTypesQuery, StorageTypesQueryVariables>(
    StorageTypesDocument,
    baseOptions
  )
}
export type StorageTypesQueryHookResult = ReturnType<typeof useStorageTypesQuery>
export type StorageTypesLazyQueryHookResult = ReturnType<typeof useStorageTypesLazyQuery>
export type StorageTypesQueryResult = ApolloReactCommon.QueryResult<
  StorageTypesQuery,
  StorageTypesQueryVariables
>
export const StorageTypesPaginatedDocument = gql`
  query storageTypesPaginated($first: Int, $after: String) {
    storageTypes(first: $first, after: $after) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
      nodes {
        ...storageType
        MHEs {
          materialHandlingEquipment {
            id
            code
          }
        }
      }
    }
  }
  ${StorageTypeFragmentDoc}
`
export type StorageTypesPaginatedComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    StorageTypesPaginatedQuery,
    StorageTypesPaginatedQueryVariables
  >,
  'query'
>

export const StorageTypesPaginatedComponent = (props: StorageTypesPaginatedComponentProps) => (
  <ApolloReactComponents.Query<StorageTypesPaginatedQuery, StorageTypesPaginatedQueryVariables>
    query={StorageTypesPaginatedDocument}
    {...props}
  />
)

export type StorageTypesPaginatedProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  StorageTypesPaginatedQuery,
  StorageTypesPaginatedQueryVariables
> &
  TChildProps
export function withStorageTypesPaginated<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    StorageTypesPaginatedQuery,
    StorageTypesPaginatedQueryVariables,
    StorageTypesPaginatedProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    StorageTypesPaginatedQuery,
    StorageTypesPaginatedQueryVariables,
    StorageTypesPaginatedProps<TChildProps>
  >(StorageTypesPaginatedDocument, {
    alias: 'storageTypesPaginated',
    ...operationOptions
  })
}

/**
 * __useStorageTypesPaginatedQuery__
 *
 * To run a query within a React component, call `useStorageTypesPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageTypesPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageTypesPaginatedQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useStorageTypesPaginatedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    StorageTypesPaginatedQuery,
    StorageTypesPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<StorageTypesPaginatedQuery, StorageTypesPaginatedQueryVariables>(
    StorageTypesPaginatedDocument,
    baseOptions
  )
}
export function useStorageTypesPaginatedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StorageTypesPaginatedQuery,
    StorageTypesPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    StorageTypesPaginatedQuery,
    StorageTypesPaginatedQueryVariables
  >(StorageTypesPaginatedDocument, baseOptions)
}
export type StorageTypesPaginatedQueryHookResult = ReturnType<typeof useStorageTypesPaginatedQuery>
export type StorageTypesPaginatedLazyQueryHookResult = ReturnType<
  typeof useStorageTypesPaginatedLazyQuery
>
export type StorageTypesPaginatedQueryResult = ApolloReactCommon.QueryResult<
  StorageTypesPaginatedQuery,
  StorageTypesPaginatedQueryVariables
>
export const SuppliersDocument = gql`
  query suppliers($filterInput: SupplierFilter) {
    suppliers(where: $filterInput) {
      nodes {
        ...supplier
        address {
          ...address
        }
      }
    }
  }
  ${SupplierFragmentDoc}
  ${AddressFragmentDoc}
`
export type SuppliersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<SuppliersQuery, SuppliersQueryVariables>,
  'query'
>

export const SuppliersComponent = (props: SuppliersComponentProps) => (
  <ApolloReactComponents.Query<SuppliersQuery, SuppliersQueryVariables>
    query={SuppliersDocument}
    {...props}
  />
)

export type SuppliersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  SuppliersQuery,
  SuppliersQueryVariables
> &
  TChildProps
export function withSuppliers<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SuppliersQuery,
    SuppliersQueryVariables,
    SuppliersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SuppliersQuery,
    SuppliersQueryVariables,
    SuppliersProps<TChildProps>
  >(SuppliersDocument, {
    alias: 'suppliers',
    ...operationOptions
  })
}

/**
 * __useSuppliersQuery__
 *
 * To run a query within a React component, call `useSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuppliersQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *   },
 * });
 */
export function useSuppliersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SuppliersQuery, SuppliersQueryVariables>
) {
  return ApolloReactHooks.useQuery<SuppliersQuery, SuppliersQueryVariables>(
    SuppliersDocument,
    baseOptions
  )
}
export function useSuppliersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SuppliersQuery, SuppliersQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<SuppliersQuery, SuppliersQueryVariables>(
    SuppliersDocument,
    baseOptions
  )
}
export type SuppliersQueryHookResult = ReturnType<typeof useSuppliersQuery>
export type SuppliersLazyQueryHookResult = ReturnType<typeof useSuppliersLazyQuery>
export type SuppliersQueryResult = ApolloReactCommon.QueryResult<
  SuppliersQuery,
  SuppliersQueryVariables
>
export const SelectSuppliersDocument = gql`
  query selectSuppliers($filterInput: SupplierFilter) {
    suppliers(where: $filterInput) {
      nodes {
        id
        code
        name
      }
    }
  }
`
export type SelectSuppliersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<SelectSuppliersQuery, SelectSuppliersQueryVariables>,
  'query'
>

export const SelectSuppliersComponent = (props: SelectSuppliersComponentProps) => (
  <ApolloReactComponents.Query<SelectSuppliersQuery, SelectSuppliersQueryVariables>
    query={SelectSuppliersDocument}
    {...props}
  />
)

export type SelectSuppliersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  SelectSuppliersQuery,
  SelectSuppliersQueryVariables
> &
  TChildProps
export function withSelectSuppliers<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SelectSuppliersQuery,
    SelectSuppliersQueryVariables,
    SelectSuppliersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SelectSuppliersQuery,
    SelectSuppliersQueryVariables,
    SelectSuppliersProps<TChildProps>
  >(SelectSuppliersDocument, {
    alias: 'selectSuppliers',
    ...operationOptions
  })
}

/**
 * __useSelectSuppliersQuery__
 *
 * To run a query within a React component, call `useSelectSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectSuppliersQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *   },
 * });
 */
export function useSelectSuppliersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SelectSuppliersQuery,
    SelectSuppliersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<SelectSuppliersQuery, SelectSuppliersQueryVariables>(
    SelectSuppliersDocument,
    baseOptions
  )
}
export function useSelectSuppliersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SelectSuppliersQuery,
    SelectSuppliersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<SelectSuppliersQuery, SelectSuppliersQueryVariables>(
    SelectSuppliersDocument,
    baseOptions
  )
}
export type SelectSuppliersQueryHookResult = ReturnType<typeof useSelectSuppliersQuery>
export type SelectSuppliersLazyQueryHookResult = ReturnType<typeof useSelectSuppliersLazyQuery>
export type SelectSuppliersQueryResult = ApolloReactCommon.QueryResult<
  SelectSuppliersQuery,
  SelectSuppliersQueryVariables
>
export const SupplierByIdDocument = gql`
  query supplierById($id: Int!, $userContext: UserContextInput) {
    supplierById(id: $id, userContext: $userContext) {
      ...supplier
      address {
        ...address
      }
    }
  }
  ${SupplierFragmentDoc}
  ${AddressFragmentDoc}
`
export type SupplierByIdComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<SupplierByIdQuery, SupplierByIdQueryVariables>,
  'query'
> &
  ({ variables: SupplierByIdQueryVariables; skip?: boolean } | { skip: boolean })

export const SupplierByIdComponent = (props: SupplierByIdComponentProps) => (
  <ApolloReactComponents.Query<SupplierByIdQuery, SupplierByIdQueryVariables>
    query={SupplierByIdDocument}
    {...props}
  />
)

export type SupplierByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  SupplierByIdQuery,
  SupplierByIdQueryVariables
> &
  TChildProps
export function withSupplierById<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SupplierByIdQuery,
    SupplierByIdQueryVariables,
    SupplierByIdProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SupplierByIdQuery,
    SupplierByIdQueryVariables,
    SupplierByIdProps<TChildProps>
  >(SupplierByIdDocument, {
    alias: 'supplierById',
    ...operationOptions
  })
}

/**
 * __useSupplierByIdQuery__
 *
 * To run a query within a React component, call `useSupplierByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplierByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplierByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useSupplierByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<SupplierByIdQuery, SupplierByIdQueryVariables>
) {
  return ApolloReactHooks.useQuery<SupplierByIdQuery, SupplierByIdQueryVariables>(
    SupplierByIdDocument,
    baseOptions
  )
}
export function useSupplierByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SupplierByIdQuery, SupplierByIdQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<SupplierByIdQuery, SupplierByIdQueryVariables>(
    SupplierByIdDocument,
    baseOptions
  )
}
export type SupplierByIdQueryHookResult = ReturnType<typeof useSupplierByIdQuery>
export type SupplierByIdLazyQueryHookResult = ReturnType<typeof useSupplierByIdLazyQuery>
export type SupplierByIdQueryResult = ApolloReactCommon.QueryResult<
  SupplierByIdQuery,
  SupplierByIdQueryVariables
>
export const SuppliersPaginatedDocument = gql`
  query suppliersPaginated(
    $filterInput: SupplierFilter
    $first: Int
    $after: String
    $before: String
    $userContext: UserContextInput
  ) {
    suppliers(
      where: $filterInput
      first: $first
      after: $after
      before: $before
      userContext: $userContext
    ) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        id
        code
        name
      }
    }
  }
`
export type SuppliersPaginatedComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SuppliersPaginatedQuery,
    SuppliersPaginatedQueryVariables
  >,
  'query'
>

export const SuppliersPaginatedComponent = (props: SuppliersPaginatedComponentProps) => (
  <ApolloReactComponents.Query<SuppliersPaginatedQuery, SuppliersPaginatedQueryVariables>
    query={SuppliersPaginatedDocument}
    {...props}
  />
)

export type SuppliersPaginatedProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  SuppliersPaginatedQuery,
  SuppliersPaginatedQueryVariables
> &
  TChildProps
export function withSuppliersPaginated<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SuppliersPaginatedQuery,
    SuppliersPaginatedQueryVariables,
    SuppliersPaginatedProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SuppliersPaginatedQuery,
    SuppliersPaginatedQueryVariables,
    SuppliersPaginatedProps<TChildProps>
  >(SuppliersPaginatedDocument, {
    alias: 'suppliersPaginated',
    ...operationOptions
  })
}

/**
 * __useSuppliersPaginatedQuery__
 *
 * To run a query within a React component, call `useSuppliersPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuppliersPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuppliersPaginatedQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useSuppliersPaginatedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SuppliersPaginatedQuery,
    SuppliersPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<SuppliersPaginatedQuery, SuppliersPaginatedQueryVariables>(
    SuppliersPaginatedDocument,
    baseOptions
  )
}
export function useSuppliersPaginatedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SuppliersPaginatedQuery,
    SuppliersPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<SuppliersPaginatedQuery, SuppliersPaginatedQueryVariables>(
    SuppliersPaginatedDocument,
    baseOptions
  )
}
export type SuppliersPaginatedQueryHookResult = ReturnType<typeof useSuppliersPaginatedQuery>
export type SuppliersPaginatedLazyQueryHookResult = ReturnType<
  typeof useSuppliersPaginatedLazyQuery
>
export type SuppliersPaginatedQueryResult = ApolloReactCommon.QueryResult<
  SuppliersPaginatedQuery,
  SuppliersPaginatedQueryVariables
>
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      __typename
      ... on WMSUser {
        userName
        forename
        surname
      }
      ... on DatabaseError {
        DBErrorId: id
        message
      }
      ... on ValidationError {
        field
        ValidationErrorId: id
        messages
      }
    }
  }
`
export type CurrentUserComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<CurrentUserQuery, CurrentUserQueryVariables>,
  'query'
>

export const CurrentUserComponent = (props: CurrentUserComponentProps) => (
  <ApolloReactComponents.Query<CurrentUserQuery, CurrentUserQueryVariables>
    query={CurrentUserDocument}
    {...props}
  />
)

export type CurrentUserProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  CurrentUserQuery,
  CurrentUserQueryVariables
> &
  TChildProps
export function withCurrentUser<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    CurrentUserQuery,
    CurrentUserQueryVariables,
    CurrentUserProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    CurrentUserQuery,
    CurrentUserQueryVariables,
    CurrentUserProps<TChildProps>
  >(CurrentUserDocument, {
    alias: 'currentUser',
    ...operationOptions
  })
}

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>
) {
  return ApolloReactHooks.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    baseOptions
  )
}
export function useCurrentUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    baseOptions
  )
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>
export type CurrentUserQueryResult = ApolloReactCommon.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>
export const UsersDocument = gql`
  query users($filterInput: WMSUserFilterInput) {
    users(where: $filterInput) {
      nodes {
        __typename
        ... on WMSUser {
          ...user
        }
      }
    }
  }
  ${UserFragmentDoc}
`
export type UsersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<UsersQuery, UsersQueryVariables>,
  'query'
>

export const UsersComponent = (props: UsersComponentProps) => (
  <ApolloReactComponents.Query<UsersQuery, UsersQueryVariables> query={UsersDocument} {...props} />
)

export type UsersProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  UsersQuery,
  UsersQueryVariables
> &
  TChildProps
export function withUsers<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UsersQuery,
    UsersQueryVariables,
    UsersProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<TProps, UsersQuery, UsersQueryVariables, UsersProps<TChildProps>>(
    UsersDocument,
    {
      alias: 'users',
      ...operationOptions
    }
  )
}

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *   },
 * });
 */
export function useUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UsersQuery, UsersQueryVariables>
) {
  return ApolloReactHooks.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions)
}
export function useUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions)
}
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>
export type UsersQueryResult = ApolloReactCommon.QueryResult<UsersQuery, UsersQueryVariables>
export const UserByIdDocument = gql`
  query userById($id: String) {
    userById(id: $id) {
      __typename
      ... on WMSUser {
        ...user
        userPermissions {
          ...userPermission
        }
      }
      ... on DatabaseError {
        DBErrorId: id
        message
      }
      ... on ValidationError {
        field
        ValidationErrorId: id
        messages
      }
    }
  }
  ${UserFragmentDoc}
  ${UserPermissionFragmentDoc}
`
export type UserByIdComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<UserByIdQuery, UserByIdQueryVariables>,
  'query'
>

export const UserByIdComponent = (props: UserByIdComponentProps) => (
  <ApolloReactComponents.Query<UserByIdQuery, UserByIdQueryVariables>
    query={UserByIdDocument}
    {...props}
  />
)

export type UserByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  UserByIdQuery,
  UserByIdQueryVariables
> &
  TChildProps
export function withUserById<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserByIdQuery,
    UserByIdQueryVariables,
    UserByIdProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserByIdQuery,
    UserByIdQueryVariables,
    UserByIdProps<TChildProps>
  >(UserByIdDocument, {
    alias: 'userById',
    ...operationOptions
  })
}

/**
 * __useUserByIdQuery__
 *
 * To run a query within a React component, call `useUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UserByIdQuery, UserByIdQueryVariables>
) {
  return ApolloReactHooks.useQuery<UserByIdQuery, UserByIdQueryVariables>(
    UserByIdDocument,
    baseOptions
  )
}
export function useUserByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserByIdQuery, UserByIdQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<UserByIdQuery, UserByIdQueryVariables>(
    UserByIdDocument,
    baseOptions
  )
}
export type UserByIdQueryHookResult = ReturnType<typeof useUserByIdQuery>
export type UserByIdLazyQueryHookResult = ReturnType<typeof useUserByIdLazyQuery>
export type UserByIdQueryResult = ApolloReactCommon.QueryResult<
  UserByIdQuery,
  UserByIdQueryVariables
>
export const UserByUsernameDocument = gql`
  query userByUsername($username: String) {
    userByUsername(username: $username) {
      __typename
      ... on WMSUser {
        ...user
      }
    }
  }
  ${UserFragmentDoc}
`
export type UserByUsernameComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<UserByUsernameQuery, UserByUsernameQueryVariables>,
  'query'
>

export const UserByUsernameComponent = (props: UserByUsernameComponentProps) => (
  <ApolloReactComponents.Query<UserByUsernameQuery, UserByUsernameQueryVariables>
    query={UserByUsernameDocument}
    {...props}
  />
)

export type UserByUsernameProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  UserByUsernameQuery,
  UserByUsernameQueryVariables
> &
  TChildProps
export function withUserByUsername<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserByUsernameQuery,
    UserByUsernameQueryVariables,
    UserByUsernameProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserByUsernameQuery,
    UserByUsernameQueryVariables,
    UserByUsernameProps<TChildProps>
  >(UserByUsernameDocument, {
    alias: 'userByUsername',
    ...operationOptions
  })
}

/**
 * __useUserByUsernameQuery__
 *
 * To run a query within a React component, call `useUserByUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUserByUsernameQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UserByUsernameQuery, UserByUsernameQueryVariables>
) {
  return ApolloReactHooks.useQuery<UserByUsernameQuery, UserByUsernameQueryVariables>(
    UserByUsernameDocument,
    baseOptions
  )
}
export function useUserByUsernameLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserByUsernameQuery,
    UserByUsernameQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<UserByUsernameQuery, UserByUsernameQueryVariables>(
    UserByUsernameDocument,
    baseOptions
  )
}
export type UserByUsernameQueryHookResult = ReturnType<typeof useUserByUsernameQuery>
export type UserByUsernameLazyQueryHookResult = ReturnType<typeof useUserByUsernameLazyQuery>
export type UserByUsernameQueryResult = ApolloReactCommon.QueryResult<
  UserByUsernameQuery,
  UserByUsernameQueryVariables
>
export const UsersPaginatedDocument = gql`
  query usersPaginated(
    $filterInput: WMSUserFilterInput
    $first: Int
    $after: String
    $before: String
  ) {
    users(where: $filterInput, first: $first, after: $after, before: $before) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        ... on WMSUser {
          id
          forename
          surname
          userName
        }
      }
    }
  }
`
export type UsersPaginatedComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<UsersPaginatedQuery, UsersPaginatedQueryVariables>,
  'query'
>

export const UsersPaginatedComponent = (props: UsersPaginatedComponentProps) => (
  <ApolloReactComponents.Query<UsersPaginatedQuery, UsersPaginatedQueryVariables>
    query={UsersPaginatedDocument}
    {...props}
  />
)

export type UsersPaginatedProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  UsersPaginatedQuery,
  UsersPaginatedQueryVariables
> &
  TChildProps
export function withUsersPaginated<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UsersPaginatedQuery,
    UsersPaginatedQueryVariables,
    UsersPaginatedProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UsersPaginatedQuery,
    UsersPaginatedQueryVariables,
    UsersPaginatedProps<TChildProps>
  >(UsersPaginatedDocument, {
    alias: 'usersPaginated',
    ...operationOptions
  })
}

/**
 * __useUsersPaginatedQuery__
 *
 * To run a query within a React component, call `useUsersPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersPaginatedQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useUsersPaginatedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UsersPaginatedQuery, UsersPaginatedQueryVariables>
) {
  return ApolloReactHooks.useQuery<UsersPaginatedQuery, UsersPaginatedQueryVariables>(
    UsersPaginatedDocument,
    baseOptions
  )
}
export function useUsersPaginatedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UsersPaginatedQuery,
    UsersPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<UsersPaginatedQuery, UsersPaginatedQueryVariables>(
    UsersPaginatedDocument,
    baseOptions
  )
}
export type UsersPaginatedQueryHookResult = ReturnType<typeof useUsersPaginatedQuery>
export type UsersPaginatedLazyQueryHookResult = ReturnType<typeof useUsersPaginatedLazyQuery>
export type UsersPaginatedQueryResult = ApolloReactCommon.QueryResult<
  UsersPaginatedQuery,
  UsersPaginatedQueryVariables
>
export const SetPasswordForUserDocument = gql`
  mutation setPasswordForUser($id: String, $newPassword: String) {
    setPasswordForUser(id: $id, newPassword: $newPassword) {
      ... on WMSUser {
        userName
      }
      ... on DatabaseError {
        id
        message
      }
      ... on ValidationError {
        id
        messages
      }
    }
  }
`
export type SetPasswordForUserMutationFn = ApolloReactCommon.MutationFunction<
  SetPasswordForUserMutation,
  SetPasswordForUserMutationVariables
>
export type SetPasswordForUserComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SetPasswordForUserMutation,
    SetPasswordForUserMutationVariables
  >,
  'mutation'
>

export const SetPasswordForUserComponent = (props: SetPasswordForUserComponentProps) => (
  <ApolloReactComponents.Mutation<SetPasswordForUserMutation, SetPasswordForUserMutationVariables>
    mutation={SetPasswordForUserDocument}
    {...props}
  />
)

export type SetPasswordForUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<
  SetPasswordForUserMutation,
  SetPasswordForUserMutationVariables
> &
  TChildProps
export function withSetPasswordForUser<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SetPasswordForUserMutation,
    SetPasswordForUserMutationVariables,
    SetPasswordForUserProps<TChildProps>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    SetPasswordForUserMutation,
    SetPasswordForUserMutationVariables,
    SetPasswordForUserProps<TChildProps>
  >(SetPasswordForUserDocument, {
    alias: 'setPasswordForUser',
    ...operationOptions
  })
}

/**
 * __useSetPasswordForUserMutation__
 *
 * To run a mutation, you first call `useSetPasswordForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPasswordForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPasswordForUserMutation, { data, loading, error }] = useSetPasswordForUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useSetPasswordForUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetPasswordForUserMutation,
    SetPasswordForUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SetPasswordForUserMutation,
    SetPasswordForUserMutationVariables
  >(SetPasswordForUserDocument, baseOptions)
}
export type SetPasswordForUserMutationHookResult = ReturnType<typeof useSetPasswordForUserMutation>
export type SetPasswordForUserMutationResult = ApolloReactCommon.MutationResult<
  SetPasswordForUserMutation
>
export type SetPasswordForUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetPasswordForUserMutation,
  SetPasswordForUserMutationVariables
>
export const UserPermissionsDocument = gql`
  query userPermissions($filterInput: UserPermissionFilter) {
    userPermissions(where: $filterInput) {
      nodes {
        ...userPermission
      }
    }
  }
  ${UserPermissionFragmentDoc}
`
export type UserPermissionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<UserPermissionsQuery, UserPermissionsQueryVariables>,
  'query'
>

export const UserPermissionsComponent = (props: UserPermissionsComponentProps) => (
  <ApolloReactComponents.Query<UserPermissionsQuery, UserPermissionsQueryVariables>
    query={UserPermissionsDocument}
    {...props}
  />
)

export type UserPermissionsProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  UserPermissionsQuery,
  UserPermissionsQueryVariables
> &
  TChildProps
export function withUserPermissions<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserPermissionsQuery,
    UserPermissionsQueryVariables,
    UserPermissionsProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserPermissionsQuery,
    UserPermissionsQueryVariables,
    UserPermissionsProps<TChildProps>
  >(UserPermissionsDocument, {
    alias: 'userPermissions',
    ...operationOptions
  })
}

/**
 * __useUserPermissionsQuery__
 *
 * To run a query within a React component, call `useUserPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPermissionsQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *   },
 * });
 */
export function useUserPermissionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    UserPermissionsQuery,
    UserPermissionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<UserPermissionsQuery, UserPermissionsQueryVariables>(
    UserPermissionsDocument,
    baseOptions
  )
}
export function useUserPermissionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserPermissionsQuery,
    UserPermissionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<UserPermissionsQuery, UserPermissionsQueryVariables>(
    UserPermissionsDocument,
    baseOptions
  )
}
export type UserPermissionsQueryHookResult = ReturnType<typeof useUserPermissionsQuery>
export type UserPermissionsLazyQueryHookResult = ReturnType<typeof useUserPermissionsLazyQuery>
export type UserPermissionsQueryResult = ApolloReactCommon.QueryResult<
  UserPermissionsQuery,
  UserPermissionsQueryVariables
>
export const UserPermissionDocument = gql`
  query userPermission($id: Int!) {
    userPermission(id: $id) {
      ...userPermission
    }
  }
  ${UserPermissionFragmentDoc}
`
export type UserPermissionComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<UserPermissionQuery, UserPermissionQueryVariables>,
  'query'
> &
  ({ variables: UserPermissionQueryVariables; skip?: boolean } | { skip: boolean })

export const UserPermissionComponent = (props: UserPermissionComponentProps) => (
  <ApolloReactComponents.Query<UserPermissionQuery, UserPermissionQueryVariables>
    query={UserPermissionDocument}
    {...props}
  />
)

export type UserPermissionProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  UserPermissionQuery,
  UserPermissionQueryVariables
> &
  TChildProps
export function withUserPermission<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    UserPermissionQuery,
    UserPermissionQueryVariables,
    UserPermissionProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    UserPermissionQuery,
    UserPermissionQueryVariables,
    UserPermissionProps<TChildProps>
  >(UserPermissionDocument, {
    alias: 'userPermission',
    ...operationOptions
  })
}

/**
 * __useUserPermissionQuery__
 *
 * To run a query within a React component, call `useUserPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPermissionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserPermissionQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<UserPermissionQuery, UserPermissionQueryVariables>
) {
  return ApolloReactHooks.useQuery<UserPermissionQuery, UserPermissionQueryVariables>(
    UserPermissionDocument,
    baseOptions
  )
}
export function useUserPermissionLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    UserPermissionQuery,
    UserPermissionQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<UserPermissionQuery, UserPermissionQueryVariables>(
    UserPermissionDocument,
    baseOptions
  )
}
export type UserPermissionQueryHookResult = ReturnType<typeof useUserPermissionQuery>
export type UserPermissionLazyQueryHookResult = ReturnType<typeof useUserPermissionLazyQuery>
export type UserPermissionQueryResult = ApolloReactCommon.QueryResult<
  UserPermissionQuery,
  UserPermissionQueryVariables
>
export const WarehouseByIdDocument = gql`
  query warehouseById($id: Int!) {
    warehouseById(id: $id) {
      ...warehouse
      address {
        ...address
      }
    }
  }
  ${WarehouseFragmentDoc}
  ${AddressFragmentDoc}
`
export type WarehouseByIdComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<WarehouseByIdQuery, WarehouseByIdQueryVariables>,
  'query'
> &
  ({ variables: WarehouseByIdQueryVariables; skip?: boolean } | { skip: boolean })

export const WarehouseByIdComponent = (props: WarehouseByIdComponentProps) => (
  <ApolloReactComponents.Query<WarehouseByIdQuery, WarehouseByIdQueryVariables>
    query={WarehouseByIdDocument}
    {...props}
  />
)

export type WarehouseByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  WarehouseByIdQuery,
  WarehouseByIdQueryVariables
> &
  TChildProps
export function withWarehouseById<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    WarehouseByIdQuery,
    WarehouseByIdQueryVariables,
    WarehouseByIdProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    WarehouseByIdQuery,
    WarehouseByIdQueryVariables,
    WarehouseByIdProps<TChildProps>
  >(WarehouseByIdDocument, {
    alias: 'warehouseById',
    ...operationOptions
  })
}

/**
 * __useWarehouseByIdQuery__
 *
 * To run a query within a React component, call `useWarehouseByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWarehouseByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<WarehouseByIdQuery, WarehouseByIdQueryVariables>
) {
  return ApolloReactHooks.useQuery<WarehouseByIdQuery, WarehouseByIdQueryVariables>(
    WarehouseByIdDocument,
    baseOptions
  )
}
export function useWarehouseByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    WarehouseByIdQuery,
    WarehouseByIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<WarehouseByIdQuery, WarehouseByIdQueryVariables>(
    WarehouseByIdDocument,
    baseOptions
  )
}
export type WarehouseByIdQueryHookResult = ReturnType<typeof useWarehouseByIdQuery>
export type WarehouseByIdLazyQueryHookResult = ReturnType<typeof useWarehouseByIdLazyQuery>
export type WarehouseByIdQueryResult = ApolloReactCommon.QueryResult<
  WarehouseByIdQuery,
  WarehouseByIdQueryVariables
>
export const WarehouseByCodeDocument = gql`
  query warehouseByCode($code: String!) {
    warehouseByCode(code: $code) {
      ...warehouse
      address {
        ...address
      }
    }
  }
  ${WarehouseFragmentDoc}
  ${AddressFragmentDoc}
`
export type WarehouseByCodeComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<WarehouseByCodeQuery, WarehouseByCodeQueryVariables>,
  'query'
> &
  ({ variables: WarehouseByCodeQueryVariables; skip?: boolean } | { skip: boolean })

export const WarehouseByCodeComponent = (props: WarehouseByCodeComponentProps) => (
  <ApolloReactComponents.Query<WarehouseByCodeQuery, WarehouseByCodeQueryVariables>
    query={WarehouseByCodeDocument}
    {...props}
  />
)

export type WarehouseByCodeProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  WarehouseByCodeQuery,
  WarehouseByCodeQueryVariables
> &
  TChildProps
export function withWarehouseByCode<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    WarehouseByCodeQuery,
    WarehouseByCodeQueryVariables,
    WarehouseByCodeProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    WarehouseByCodeQuery,
    WarehouseByCodeQueryVariables,
    WarehouseByCodeProps<TChildProps>
  >(WarehouseByCodeDocument, {
    alias: 'warehouseByCode',
    ...operationOptions
  })
}

/**
 * __useWarehouseByCodeQuery__
 *
 * To run a query within a React component, call `useWarehouseByCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseByCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseByCodeQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useWarehouseByCodeQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WarehouseByCodeQuery,
    WarehouseByCodeQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<WarehouseByCodeQuery, WarehouseByCodeQueryVariables>(
    WarehouseByCodeDocument,
    baseOptions
  )
}
export function useWarehouseByCodeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    WarehouseByCodeQuery,
    WarehouseByCodeQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<WarehouseByCodeQuery, WarehouseByCodeQueryVariables>(
    WarehouseByCodeDocument,
    baseOptions
  )
}
export type WarehouseByCodeQueryHookResult = ReturnType<typeof useWarehouseByCodeQuery>
export type WarehouseByCodeLazyQueryHookResult = ReturnType<typeof useWarehouseByCodeLazyQuery>
export type WarehouseByCodeQueryResult = ApolloReactCommon.QueryResult<
  WarehouseByCodeQuery,
  WarehouseByCodeQueryVariables
>
export const WarehousesDocument = gql`
  query warehouses($filterInput: WarehouseFilter) {
    warehouses(where: $filterInput, first: 10) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      nodes {
        ...warehouse
        address {
          ...address
        }
      }
    }
  }
  ${WarehouseFragmentDoc}
  ${AddressFragmentDoc}
`
export type WarehousesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<WarehousesQuery, WarehousesQueryVariables>,
  'query'
>

export const WarehousesComponent = (props: WarehousesComponentProps) => (
  <ApolloReactComponents.Query<WarehousesQuery, WarehousesQueryVariables>
    query={WarehousesDocument}
    {...props}
  />
)

export type WarehousesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  WarehousesQuery,
  WarehousesQueryVariables
> &
  TChildProps
export function withWarehouses<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    WarehousesQuery,
    WarehousesQueryVariables,
    WarehousesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    WarehousesQuery,
    WarehousesQueryVariables,
    WarehousesProps<TChildProps>
  >(WarehousesDocument, {
    alias: 'warehouses',
    ...operationOptions
  })
}

/**
 * __useWarehousesQuery__
 *
 * To run a query within a React component, call `useWarehousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehousesQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *   },
 * });
 */
export function useWarehousesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<WarehousesQuery, WarehousesQueryVariables>
) {
  return ApolloReactHooks.useQuery<WarehousesQuery, WarehousesQueryVariables>(
    WarehousesDocument,
    baseOptions
  )
}
export function useWarehousesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WarehousesQuery, WarehousesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<WarehousesQuery, WarehousesQueryVariables>(
    WarehousesDocument,
    baseOptions
  )
}
export type WarehousesQueryHookResult = ReturnType<typeof useWarehousesQuery>
export type WarehousesLazyQueryHookResult = ReturnType<typeof useWarehousesLazyQuery>
export type WarehousesQueryResult = ApolloReactCommon.QueryResult<
  WarehousesQuery,
  WarehousesQueryVariables
>
export const WarehousesPaginatedDocument = gql`
  query warehousesPaginated(
    $filterInput: WarehouseFilter
    $first: Int
    $after: String
    $before: String
  ) {
    warehouses(where: $filterInput, first: $first, after: $after, before: $before) {
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      nodes {
        id
        code
        warehouseName
        companyName
      }
    }
  }
`
export type WarehousesPaginatedComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    WarehousesPaginatedQuery,
    WarehousesPaginatedQueryVariables
  >,
  'query'
>

export const WarehousesPaginatedComponent = (props: WarehousesPaginatedComponentProps) => (
  <ApolloReactComponents.Query<WarehousesPaginatedQuery, WarehousesPaginatedQueryVariables>
    query={WarehousesPaginatedDocument}
    {...props}
  />
)

export type WarehousesPaginatedProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  WarehousesPaginatedQuery,
  WarehousesPaginatedQueryVariables
> &
  TChildProps
export function withWarehousesPaginated<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    WarehousesPaginatedQuery,
    WarehousesPaginatedQueryVariables,
    WarehousesPaginatedProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    WarehousesPaginatedQuery,
    WarehousesPaginatedQueryVariables,
    WarehousesPaginatedProps<TChildProps>
  >(WarehousesPaginatedDocument, {
    alias: 'warehousesPaginated',
    ...operationOptions
  })
}

/**
 * __useWarehousesPaginatedQuery__
 *
 * To run a query within a React component, call `useWarehousesPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehousesPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehousesPaginatedQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useWarehousesPaginatedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WarehousesPaginatedQuery,
    WarehousesPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<WarehousesPaginatedQuery, WarehousesPaginatedQueryVariables>(
    WarehousesPaginatedDocument,
    baseOptions
  )
}
export function useWarehousesPaginatedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    WarehousesPaginatedQuery,
    WarehousesPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<WarehousesPaginatedQuery, WarehousesPaginatedQueryVariables>(
    WarehousesPaginatedDocument,
    baseOptions
  )
}
export type WarehousesPaginatedQueryHookResult = ReturnType<typeof useWarehousesPaginatedQuery>
export type WarehousesPaginatedLazyQueryHookResult = ReturnType<
  typeof useWarehousesPaginatedLazyQuery
>
export type WarehousesPaginatedQueryResult = ApolloReactCommon.QueryResult<
  WarehousesPaginatedQuery,
  WarehousesPaginatedQueryVariables
>
export const SelectWarehousesDocument = gql`
  query selectWarehouses {
    warehouses {
      nodes {
        id
        code
        warehouseName
      }
    }
  }
`
export type SelectWarehousesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SelectWarehousesQuery,
    SelectWarehousesQueryVariables
  >,
  'query'
>

export const SelectWarehousesComponent = (props: SelectWarehousesComponentProps) => (
  <ApolloReactComponents.Query<SelectWarehousesQuery, SelectWarehousesQueryVariables>
    query={SelectWarehousesDocument}
    {...props}
  />
)

export type SelectWarehousesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  SelectWarehousesQuery,
  SelectWarehousesQueryVariables
> &
  TChildProps
export function withSelectWarehouses<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SelectWarehousesQuery,
    SelectWarehousesQueryVariables,
    SelectWarehousesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    SelectWarehousesQuery,
    SelectWarehousesQueryVariables,
    SelectWarehousesProps<TChildProps>
  >(SelectWarehousesDocument, {
    alias: 'selectWarehouses',
    ...operationOptions
  })
}

/**
 * __useSelectWarehousesQuery__
 *
 * To run a query within a React component, call `useSelectWarehousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectWarehousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectWarehousesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectWarehousesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SelectWarehousesQuery,
    SelectWarehousesQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<SelectWarehousesQuery, SelectWarehousesQueryVariables>(
    SelectWarehousesDocument,
    baseOptions
  )
}
export function useSelectWarehousesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SelectWarehousesQuery,
    SelectWarehousesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<SelectWarehousesQuery, SelectWarehousesQueryVariables>(
    SelectWarehousesDocument,
    baseOptions
  )
}
export type SelectWarehousesQueryHookResult = ReturnType<typeof useSelectWarehousesQuery>
export type SelectWarehousesLazyQueryHookResult = ReturnType<typeof useSelectWarehousesLazyQuery>
export type SelectWarehousesQueryResult = ApolloReactCommon.QueryResult<
  SelectWarehousesQuery,
  SelectWarehousesQueryVariables
>
export const WarehouseZonesDocument = gql`
  query warehouseZones($filterInput: WarehouseZoneFilter, $userContext: UserContextInput) {
    warehouseZones(where: $filterInput, userContext: $userContext) {
      nodes {
        ...warehouseZone
        zoneType
      }
    }
  }
  ${WarehouseZoneFragmentDoc}
`
export type WarehouseZonesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<WarehouseZonesQuery, WarehouseZonesQueryVariables>,
  'query'
>

export const WarehouseZonesComponent = (props: WarehouseZonesComponentProps) => (
  <ApolloReactComponents.Query<WarehouseZonesQuery, WarehouseZonesQueryVariables>
    query={WarehouseZonesDocument}
    {...props}
  />
)

export type WarehouseZonesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  WarehouseZonesQuery,
  WarehouseZonesQueryVariables
> &
  TChildProps
export function withWarehouseZones<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    WarehouseZonesQuery,
    WarehouseZonesQueryVariables,
    WarehouseZonesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    WarehouseZonesQuery,
    WarehouseZonesQueryVariables,
    WarehouseZonesProps<TChildProps>
  >(WarehouseZonesDocument, {
    alias: 'warehouseZones',
    ...operationOptions
  })
}

/**
 * __useWarehouseZonesQuery__
 *
 * To run a query within a React component, call `useWarehouseZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseZonesQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useWarehouseZonesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<WarehouseZonesQuery, WarehouseZonesQueryVariables>
) {
  return ApolloReactHooks.useQuery<WarehouseZonesQuery, WarehouseZonesQueryVariables>(
    WarehouseZonesDocument,
    baseOptions
  )
}
export function useWarehouseZonesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    WarehouseZonesQuery,
    WarehouseZonesQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<WarehouseZonesQuery, WarehouseZonesQueryVariables>(
    WarehouseZonesDocument,
    baseOptions
  )
}
export type WarehouseZonesQueryHookResult = ReturnType<typeof useWarehouseZonesQuery>
export type WarehouseZonesLazyQueryHookResult = ReturnType<typeof useWarehouseZonesLazyQuery>
export type WarehouseZonesQueryResult = ApolloReactCommon.QueryResult<
  WarehouseZonesQuery,
  WarehouseZonesQueryVariables
>
export const WarehouseZonesPaginatedDocument = gql`
  query warehouseZonesPaginated(
    $filterInput: WarehouseZoneFilter
    $userContext: UserContextInput
    $first: Int
    $after: String
    $before: String
  ) {
    warehouseZones(
      where: $filterInput
      userContext: $userContext
      first: $first
      after: $after
      before: $before
    ) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        startCursor
      }
      nodes {
        ...warehouseZone
        zoneType
      }
    }
  }
  ${WarehouseZoneFragmentDoc}
`
export type WarehouseZonesPaginatedComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    WarehouseZonesPaginatedQuery,
    WarehouseZonesPaginatedQueryVariables
  >,
  'query'
>

export const WarehouseZonesPaginatedComponent = (props: WarehouseZonesPaginatedComponentProps) => (
  <ApolloReactComponents.Query<WarehouseZonesPaginatedQuery, WarehouseZonesPaginatedQueryVariables>
    query={WarehouseZonesPaginatedDocument}
    {...props}
  />
)

export type WarehouseZonesPaginatedProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  WarehouseZonesPaginatedQuery,
  WarehouseZonesPaginatedQueryVariables
> &
  TChildProps
export function withWarehouseZonesPaginated<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    WarehouseZonesPaginatedQuery,
    WarehouseZonesPaginatedQueryVariables,
    WarehouseZonesPaginatedProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    WarehouseZonesPaginatedQuery,
    WarehouseZonesPaginatedQueryVariables,
    WarehouseZonesPaginatedProps<TChildProps>
  >(WarehouseZonesPaginatedDocument, {
    alias: 'warehouseZonesPaginated',
    ...operationOptions
  })
}

/**
 * __useWarehouseZonesPaginatedQuery__
 *
 * To run a query within a React component, call `useWarehouseZonesPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseZonesPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseZonesPaginatedQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *      userContext: // value for 'userContext'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useWarehouseZonesPaginatedQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WarehouseZonesPaginatedQuery,
    WarehouseZonesPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    WarehouseZonesPaginatedQuery,
    WarehouseZonesPaginatedQueryVariables
  >(WarehouseZonesPaginatedDocument, baseOptions)
}
export function useWarehouseZonesPaginatedLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    WarehouseZonesPaginatedQuery,
    WarehouseZonesPaginatedQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    WarehouseZonesPaginatedQuery,
    WarehouseZonesPaginatedQueryVariables
  >(WarehouseZonesPaginatedDocument, baseOptions)
}
export type WarehouseZonesPaginatedQueryHookResult = ReturnType<
  typeof useWarehouseZonesPaginatedQuery
>
export type WarehouseZonesPaginatedLazyQueryHookResult = ReturnType<
  typeof useWarehouseZonesPaginatedLazyQuery
>
export type WarehouseZonesPaginatedQueryResult = ApolloReactCommon.QueryResult<
  WarehouseZonesPaginatedQuery,
  WarehouseZonesPaginatedQueryVariables
>
export const WarehouseZoneByIdDocument = gql`
  query warehouseZoneById($id: Int!, $userContext: UserContextInput) {
    warehouseZone(id: $id, userContext: $userContext) {
      ...warehouseZone
      zoneType
    }
  }
  ${WarehouseZoneFragmentDoc}
`
export type WarehouseZoneByIdComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    WarehouseZoneByIdQuery,
    WarehouseZoneByIdQueryVariables
  >,
  'query'
> &
  ({ variables: WarehouseZoneByIdQueryVariables; skip?: boolean } | { skip: boolean })

export const WarehouseZoneByIdComponent = (props: WarehouseZoneByIdComponentProps) => (
  <ApolloReactComponents.Query<WarehouseZoneByIdQuery, WarehouseZoneByIdQueryVariables>
    query={WarehouseZoneByIdDocument}
    {...props}
  />
)

export type WarehouseZoneByIdProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  WarehouseZoneByIdQuery,
  WarehouseZoneByIdQueryVariables
> &
  TChildProps
export function withWarehouseZoneById<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    WarehouseZoneByIdQuery,
    WarehouseZoneByIdQueryVariables,
    WarehouseZoneByIdProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    WarehouseZoneByIdQuery,
    WarehouseZoneByIdQueryVariables,
    WarehouseZoneByIdProps<TChildProps>
  >(WarehouseZoneByIdDocument, {
    alias: 'warehouseZoneById',
    ...operationOptions
  })
}

/**
 * __useWarehouseZoneByIdQuery__
 *
 * To run a query within a React component, call `useWarehouseZoneByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseZoneByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseZoneByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userContext: // value for 'userContext'
 *   },
 * });
 */
export function useWarehouseZoneByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    WarehouseZoneByIdQuery,
    WarehouseZoneByIdQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<WarehouseZoneByIdQuery, WarehouseZoneByIdQueryVariables>(
    WarehouseZoneByIdDocument,
    baseOptions
  )
}
export function useWarehouseZoneByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    WarehouseZoneByIdQuery,
    WarehouseZoneByIdQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<WarehouseZoneByIdQuery, WarehouseZoneByIdQueryVariables>(
    WarehouseZoneByIdDocument,
    baseOptions
  )
}
export type WarehouseZoneByIdQueryHookResult = ReturnType<typeof useWarehouseZoneByIdQuery>
export type WarehouseZoneByIdLazyQueryHookResult = ReturnType<typeof useWarehouseZoneByIdLazyQuery>
export type WarehouseZoneByIdQueryResult = ApolloReactCommon.QueryResult<
  WarehouseZoneByIdQuery,
  WarehouseZoneByIdQueryVariables
>
export const ZoneTypesDocument = gql`
  query zoneTypes {
    zoneTypes @client {
      ...zoneType
    }
  }
  ${ZoneTypeFragmentDoc}
`
export type ZoneTypesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<ZoneTypesQuery, ZoneTypesQueryVariables>,
  'query'
>

export const ZoneTypesComponent = (props: ZoneTypesComponentProps) => (
  <ApolloReactComponents.Query<ZoneTypesQuery, ZoneTypesQueryVariables>
    query={ZoneTypesDocument}
    {...props}
  />
)

export type ZoneTypesProps<TChildProps = {}> = ApolloReactHoc.DataProps<
  ZoneTypesQuery,
  ZoneTypesQueryVariables
> &
  TChildProps
export function withZoneTypes<TProps, TChildProps = {}>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    ZoneTypesQuery,
    ZoneTypesQueryVariables,
    ZoneTypesProps<TChildProps>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    ZoneTypesQuery,
    ZoneTypesQueryVariables,
    ZoneTypesProps<TChildProps>
  >(ZoneTypesDocument, {
    alias: 'zoneTypes',
    ...operationOptions
  })
}

/**
 * __useZoneTypesQuery__
 *
 * To run a query within a React component, call `useZoneTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoneTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoneTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useZoneTypesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ZoneTypesQuery, ZoneTypesQueryVariables>
) {
  return ApolloReactHooks.useQuery<ZoneTypesQuery, ZoneTypesQueryVariables>(
    ZoneTypesDocument,
    baseOptions
  )
}
export function useZoneTypesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ZoneTypesQuery, ZoneTypesQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<ZoneTypesQuery, ZoneTypesQueryVariables>(
    ZoneTypesDocument,
    baseOptions
  )
}
export type ZoneTypesQueryHookResult = ReturnType<typeof useZoneTypesQuery>
export type ZoneTypesLazyQueryHookResult = ReturnType<typeof useZoneTypesLazyQuery>
export type ZoneTypesQueryResult = ApolloReactCommon.QueryResult<
  ZoneTypesQuery,
  ZoneTypesQueryVariables
>
