import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  NormalizedCacheObject
} from 'apollo-cache-inmemory'
import { persistCache } from 'apollo-cache-persist'
import { PersistedData, PersistentStorage } from 'apollo-cache-persist/types'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'
import { IDENTITY_SERVER, USER_CONTEXT_STORAGE_KEY } from './constants'
import {
  activities,
  carrierLeadTimes,
  carriers,
  currencies,
  destinationCodes,
  handlingTypes,
  maxWeeklySpends,
  orderEntries,
  orderTypes,
  pickListTypes,
  poFormats,
  poTransmissionMethods,
  routes,
  services,
  suppliers,
  udcs,
  users,
  warehouses,
  zones,
  zoneTypes
} from './data'
import introspectionQueryResultData from './fragmentTypes.json'
import { getIdentityServerToken } from './utils/getIdentityServerToken'

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
})

const cache = new InMemoryCache({ fragmentMatcher })

const httpLink = createHttpLink({
  uri: IDENTITY_SERVER.graphQLEndpoint
})

const middlewareLink = new ApolloLink((operation, forward) => {
  const getUserContext = () => {
    const context = localStorage.getItem(USER_CONTEXT_STORAGE_KEY)

    return context ? JSON.parse(context) : null
  }

  const userContext = getUserContext()

  operation.variables = {
    ...operation.variables,
    userContext
  }

  return forward(operation)
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from (local/session) storage if it exists
  const token = getIdentityServerToken()
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const link = ApolloLink.from([middlewareLink, authLink, httpLink])

export const client = new ApolloClient({
  link,
  cache
})

export const persistedClient = async () => {
  await persistCache({
    cache,
    storage: window.localStorage as PersistentStorage<PersistedData<NormalizedCacheObject>>
  })

  return client
}

cache.writeData({
  data: {
    activities,
    carrierLeadTimes,
    carriers,
    currencies,
    destinationCodes,
    handlingTypes,
    maxWeeklySpends,
    orderEntries,
    orderTypes,
    pickListTypes,
    poFormats,
    poTransmissionMethods,
    routes,
    services,
    suppliers,
    udcs,
    users,
    warehouses,
    zones,
    zoneTypes
  }
})
