import styled from '@emotion/styled'
import {
  color,
  ColorProps,
  justifyContent,
  left,
  LeftProps,
  space,
  SpaceProps
} from 'styled-system'
import { theme } from '../../theme'

type HeaderContProps = LeftProps &
  SpaceProps &
  ColorProps & {
    color?: string
    open?: boolean
  } & {
    justifyContent: string
  }

export const HeaderCont = styled.div<HeaderContProps>`
  top: 0;
  right: 0;
  height: 60px;
  z-index: 1300;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: row;
  box-shadow: ${theme.boxShadow.thin};
  box-sizing: border-box;
  border-bottom-width: 1px;
  justify-content: space-between;
  left: ${(props) => (props.open ? '250px' : '60px')};
  @media screen and (max-width: 40em) {
    left: 0;
  }
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  ${justifyContent}
  ${left}
  ${space};
  ${color};
`

export const MobileHeaderCont = styled.div<HeaderContProps>`
  top: 60px;
  right: 0;
  height: 60px;
  z-index: 1;
  display: flex;
  position: fixed;
  align-items: center;
  flex-direction: row;
  box-sizing: border-box;
  border-bottom-width: 1px;
  justify-content: space-between;
  left: ${(props) => (props.open ? '250px' : '60px')};
  @media screen and (max-width: 40em) {
    left: 0;
  }
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  ${justifyContent}
  ${left}
  ${space};
  ${color};
`
