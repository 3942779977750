import { ApolloProvider } from '@apollo/react-hooks'
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  ChakraProvider,
  CloseButton
} from '@chakra-ui/react'
import { NormalizedCacheObject } from 'apollo-cache-inmemory'
import ApolloClient from 'apollo-client'
import React from 'react'
import 'react-dates/initialize'
import * as ReactDOM from 'react-dom'
import { client, persistedClient } from './apollo'
import { FallbackErrorMessage } from './components'
import { BUGSNAG_API_KEY } from './constants'
import { AppProvider } from './contexts'
import './i18n'
import Navigation from './navigation'
import { register, unregister } from './serviceWorker'
import { theme } from './theme'

const bugsnagClient = bugsnag(BUGSNAG_API_KEY)
bugsnagClient.use(bugsnagReact, React)
const ErrorBoundary = bugsnagClient.getPlugin('react')

const renderApp = (client: ApolloClient<NormalizedCacheObject>) =>
  ReactDOM.render(
    <ErrorBoundary FallbackComponent={FallbackErrorMessage}>
      <ApolloProvider client={client}>
        <AppProvider>
          <ChakraProvider theme={theme}>
            <Navigation />
          </ChakraProvider>
        </AppProvider>
      </ApolloProvider>
    </ErrorBoundary>,
    document.getElementById('root') as HTMLElement
  )

persistedClient()
  .then((client) => renderApp(client))
  .catch(() => renderApp(client))

if (process.env.REACT_APP_UNREGISTER_SERVICE_WORKER) {
  unregister()
} else {
  register({
    onUpdate: () =>
      function RegisterWorker() {
        return (
          <Alert status="info">
            <AlertIcon />
            <AlertTitle mr={2}>A new version is available!</AlertTitle>
            <AlertDescription>Please refresh your browser to get it.</AlertDescription>
            <CloseButton position="absolute" right="8px" top="8px" />
          </Alert>
        )
      }
  })
}

if (process.env.NODE_ENV === 'development') {
  // const whyDidYouRender = require('@welldone-software/why-did-you-render')
  // whyDidYouRender(React, {
  //   trackAllPureComponents: true,
  //   hotReloadBufferMs: 1500
  // })
}
