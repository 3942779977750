import React, { useEffect } from 'react'
import { Column, Row as IRow } from 'react-table'
import { FixedSizeList } from 'react-window'
import { useTableContext } from '../../contexts/TableProvider.context'
import Table from './index'

type CreateTableProps = {
  data: any
  columns: Column<{
    id: string | number
    code: string
  }>[]
  loadNextPage: (startIndex: number, endIndex: number) => void
  hasMore: boolean
  isNextPageLoading: boolean
  toggleSelection: boolean
  listRef?: React.RefObject<FixedSizeList>
  onRowSelect?: any
  setDeletable?: any
  setDeselectRowIds?: any
  deletable?: any[]
  useSingleRowSelect?: boolean
  isSelectionDisabled?: boolean
  setDeletableIds?: boolean
  disableSelectAtIndex?: number
  onRowDoubleClick?: (
    row: IRow<{
      id: string | number
      code: string
    }>
  ) => void
  useDoubleClick?: boolean
}

const CreateTable: React.FC<CreateTableProps> = React.memo(
  ({
    data,
    columns,
    setDeletable,
    deletable = [],
    setDeselectRowIds,
    loadNextPage,
    hasMore,
    isNextPageLoading,
    listRef,
    toggleSelection,
    onRowDoubleClick,
    onRowSelect,
    useSingleRowSelect,
    isSelectionDisabled,
    setDeletableIds,
    disableSelectAtIndex,
    useDoubleClick
  }) => {
    const { clearAll } = useTableContext()
    useEffect(
      () => clearAll(),
      // eslint-disable-next-line
      []
    )

    const handleRowSelect = (rows: IRow<{ id: string | number; code: string }>[]) => {
      setDeselectRowIds && setDeselectRowIds(rows.map((row) => row.id))
      setDeletableIds && setDeletable && setDeletable(rows.map((row) => row.original.id))
      !setDeletableIds &&
        setDeletable &&
        setDeletable([...deletable, ...rows.map((row) => row.original)])
      onRowSelect && onRowSelect(rows)
    }
    return (
      <Table
        useCheckboxSelection
        useInfiniteScroll
        useSingleRowSelect={useSingleRowSelect}
        isSelectionDisabled={isSelectionDisabled}
        listRef={listRef}
        columns={columns}
        data={data}
        onRowSelect={handleRowSelect}
        loadNextPage={loadNextPage}
        hasMore={hasMore}
        isNextPageLoading={isNextPageLoading}
        toggleSelection={toggleSelection}
        onRowDoubleClick={onRowDoubleClick}
        disableSelectAtIndex={disableSelectAtIndex}
        useDoubleClick={useDoubleClick}
      />
    )
  }
)

CreateTable.defaultProps = {
  data: [],
  columns: []
}

export default CreateTable
