import { FastField, Field } from 'formik'
import React from 'react'
import { ConnectedFormGroup } from '..'

const Address: React.FC<{ label: string; fieldsActive?: boolean }> = ({ label, fieldsActive }) => {
  return (
    <React.Fragment>
      <Field
        isRequired
        disabled={!fieldsActive}
        name="address.street1"
        type="text"
        label={label}
        placeholder="Street 1"
        mb={1}
        component={ConnectedFormGroup}
      />
      <FastField
        name="address.street2"
        type="text"
        placeholder="Street 2"
        mb={1}
        component={ConnectedFormGroup}
      />
      <FastField
        name="address.city"
        type="text"
        placeholder="City/Town"
        mb={1}
        component={ConnectedFormGroup}
      />
      <FastField
        name="address.county"
        type="text"
        placeholder="County"
        mb={1}
        component={ConnectedFormGroup}
      />
      <FastField
        name="address.country"
        type="text"
        placeholder="Country"
        mb={1}
        component={ConnectedFormGroup}
      />
      <FastField
        name="address.postCode"
        type="text"
        placeholder="Postal Code"
        mb={1}
        component={ConnectedFormGroup}
      />
    </React.Fragment>
  )
}

export default Address
