import { OIDC_USER } from '../constants'

/**
 * Gets JWT of authenticatedUser from either sessionStorage or localStorage
 */
export const getIdentityServerToken = () => {
  const sessionUser = sessionStorage.getItem(OIDC_USER)
  const identityServerUser = sessionUser

  return identityServerUser ? JSON.parse(identityServerUser).access_token : null
}
