import { Button, Flex, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import * as React from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'

type DropdownItem = { title: string; onClick?: () => void }

type DropdownMenuProps = {
  menuItems: DropdownItem[]
  title: string
  bg?: string
  icon?: React.ReactElement
  color?: string
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({ menuItems, title, icon, bg, color }) => {
  return (
    <Menu>
      {({ isOpen }) => (
        <React.Fragment>
          <MenuButton
            as={Button}
            rightIcon={isOpen ? <ChevronUp size="1rem" /> : <ChevronDown size="1rem" />}
            bg={bg}
            color={color}
          >
            <Flex>{icon ? icon : title}</Flex>
          </MenuButton>
          <MenuList paddingY={1}>
            {menuItems.map(({ title, onClick }) => (
              <MenuItem key={title} onClick={onClick} minHeight="32px" paddingX={4}>
                {title}
              </MenuItem>
            ))}
          </MenuList>
        </React.Fragment>
      )}
    </Menu>
  )
}

export default DropdownMenu
