import { SetStateAction, useEffect, useRef, useState } from 'react'

const usePersistState = (
  key: string,
  storageType?: 'localStorage' | 'sessionStorage'
): [any, React.Dispatch<SetStateAction<any>>] => {
  const STORAGE_TYPE = storageType || 'localStorage'
  const storedData = window[STORAGE_TYPE].getItem(key)

  const mounted = useRef<boolean>()
  const [data, setData] = useState(storedData ? JSON.parse(storedData) : {})

  const persist = (dataToStore: any) => {
    window[STORAGE_TYPE].setItem(key, JSON.stringify(dataToStore))
  }

  useEffect(() => {
    if (!mounted.current) {
      const storedData = window[STORAGE_TYPE].getItem(key)
      mounted.current = true
      storedData && setData(JSON.parse(storedData))
    } else {
      const storedData = window[STORAGE_TYPE].getItem(key)
      const jsonData = storedData ? JSON.parse(storedData) : {}
      const combined = { ...jsonData, ...data }

      persist(combined)
    }
    // Only care when to fire again if the user changes the data
    // eslint-disable-next-line
  }, [data])

  return [data, setData]
}

export default usePersistState
