import styled from '@emotion/styled'
import {
  color,
  ColorProps,
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  textAlign,
  TextAlignProps
} from 'styled-system'

export type LabelProps = SpaceProps &
  LayoutProps &
  ColorProps &
  TextAlignProps &
  FontSizeProps &
  FontWeightProps & { color?: string; hideLabelOverflow?: boolean }

export const Label = styled.label<LabelProps>`
  ${layout};
  ${space};
  ${color};
  ${fontSize};
  ${textAlign};
  ${fontWeight};
  ${(props) =>
    props.hideLabelOverflow
      ? {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      : {}}
`

Label.defaultProps = {
  ml: '8px',
  mb: '4px',
  fontSize: 'md',
  textAlign: 'left',
  color: 'text.dark'
}
