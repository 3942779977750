import React, { lazy } from 'react'

type Factory = () => Promise<{
  default: React.ComponentType<any>
}>

export type LoadableComponentType = React.LazyExoticComponent<React.ComponentType<any>> & {
  // expose preload function to allow for a lazy component to be preloaded
  preload: Function
}

const Loadable = (factory: Factory) => {
  const Component = lazy(factory) as LoadableComponentType
  Component.preload = factory

  return Component
}

export default Loadable
