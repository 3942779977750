import { IToast } from '@chakra-ui/react'

export const APP_NAME = 'MACS'

export const COGNITO_USER_STORAGE_KEY = 'WMSAccessToken'

export const USER_CONTEXT_STORAGE_KEY = 'WMSUserContext'

export const CLIENT_HOST = process.env.REACT_APP_CLIENT_HOST

//Renewal Time Constants
export const CHECK_TOKEN = 60000
export const RENEW_TOKEN = 600

// Development
export const IS_PROD = process.env.NODE_ENV === 'production'

export const OIDC_USER = IS_PROD
  ? 'oidc.user:https://identity.macs.sovtech.org/:js'
  : 'oidc.user:http://localhost:5002/:js'

export const IDENTITY_SERVER = {
  stsAuthority: IS_PROD ? 'https://identity.macs.sovtech.org/' : 'http://localhost:5002/',
  clientId: 'js',
  clientRoot: IS_PROD ? `https://${window.location.hostname}/` : 'http://localhost:3000/',
  clientScope: 'openid profile web_api',
  apiRoot: 'https://demo.identityserver.io/api/',
  graphQLEndpoint: IS_PROD
    ? 'https://graphql.macs.sovtech.org/graphql'
    : 'http://localhost:5000/graphql'
}

// Bugsnag
export const BUGSNAG_API_KEY = process.env.REACT_APP_BUGSNAG_API_KEY || ''

/**
 * Password regex for alphanumeric password with special characters
 * ^                  The password string will start this way
 * (?=.*[a-z])        The string must contain at least 1 lowercase alphabetical character
 * (?=.*[A-Z])        The string must contain at least 1 uppercase alphabetical character
 * (?=.*[0-9])        The string must contain at least 1 numeric character
 * @see https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/
 */
export const PASSWORD_REGEX = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])')

export const PASSWORD_REGEX_MESSAGE =
  'Password must contain at least 1 lowercase letter, 1 uppercase letter, 1' +
  ' number and 1 special character'

export const SUCCESS_TOAST: IToast = {
  duration: 6000,
  isClosable: true,
  title: 'Success!',
  status: 'success',
  position: 'bottom-right'
}

export const ERROR_TOAST: IToast = {
  duration: 6000,
  title: 'Oops!',
  status: 'error',
  isClosable: true,
  position: 'bottom-right'
}

export const DATE_FORMAT: string = 'DD/MM/YYYY'
export const DATE_TIME_FORMAT: string = 'DD/MM/YYYY HH:mm:ss'

export const PHONE_NUMBER_REGEX = new RegExp('^([0]{1})?([1-9]{1}[0-9]{8})$')

export const COUNTRY_CODE = '+27'
