import { Flex } from '@chakra-ui/react'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { H1, H4 } from '../../components/Typography'

const PageNotFound: FC = () => (
  <Flex height="100vh" justifyContent="center" alignItems="center" flexDirection="column">
    <H1>404 not found</H1>
    <H4>The page is missing</H4>
    <Link to="/">Back to saftey</Link>
  </Flex>
)

export default PageNotFound
