import { FormikHelpers } from 'formik'
import Maybe from 'graphql/tsutils/Maybe'
import * as React from 'react'
import { useState } from 'react'
import { LabelKeyObject } from 'react-csv/components/CommonPropTypes'
import { Column } from 'react-table'
import { SelectObject } from '../components/FormElements/ConnectedSelect'
import { AuditEntryFilterInput, StockFilterInput } from '../generated/graphql'

type ISidePanel = {
  key: string | { parent: string; child: string }
  header: string
  value: Maybe<string>
  isHeader?: boolean
}[]

type IDataExport = {
  exportData: any
  exportMaxSize: number
  exportCurrentSize: number
  exportError: null
}

type IPage = Maybe<'stock' | 'audit'>

export type IExport = {
  pdf: { header: string[]; data: any[][] }
  csv: { header: string[] | LabelKeyObject[] | undefined; data: any[] }
  excel: { header: string[]; data: any[][] }
}

export type ExportForm = {
  destination: SelectObject
  saveAsName: string
  saveTo: string
  exportFormat: SelectObject
  exportFields: SelectObject[]
} & FormikHelpers<any>

type AdvancedDataContextType = {
  page: IPage
  createPage: (page: IPage) => void
  data: any[]
  createData: (data: any[]) => void
  columns: Column<{
    id: string | number
    code: string
  }>[]
  createColumns: (
    columns: Column<{
      id: string | number
      code: string
    }>[]
  ) => void
  sidePanel: ISidePanel
  createSidePanel: (sidePanel: ISidePanel) => void
  exports: IExport
  createExports: (exports: IExport) => void
  tablePanelWidth: string
  createTablePanelWidth: (width: string) => void
  selectedRow: any
  createSelectedRow: (row: any) => void
  exportForm: ExportForm
  createExportForm: (form: ExportForm) => void
  exportObject: IDataExport
  createExportObject: (exportObject: IDataExport) => void
  filter: Maybe<AuditEntryFilterInput | StockFilterInput>
  createFilter: (
    filter: Maybe<AuditEntryFilterInput | StockFilterInput>
  ) => Promise<Maybe<AuditEntryFilterInput | StockFilterInput>>
}

export const AdvancedDataContext = React.createContext<AdvancedDataContextType>({
  page: null,
  createPage: () => null,
  data: [],
  createData: () => null,
  columns: [{ Header: 'PlaceHolder', accessor: 'placeHolder' }],
  createColumns: () => null,
  sidePanel: [],
  createSidePanel: () => null,
  exports: {
    csv: { data: [], header: [] },
    pdf: { data: [], header: [] },
    excel: { data: [], header: [] }
  },
  createExports: () => null,
  tablePanelWidth: '100%',
  createTablePanelWidth: () => null,
  selectedRow: {},
  createSelectedRow: () => null,
  exportForm: {} as ExportForm,
  createExportForm: () => null,
  exportObject: {
    exportData: [] as any,
    exportMaxSize: 0,
    exportCurrentSize: 0,
    exportError: null
  },
  createExportObject: () => null,
  filter: {},
  createFilter: async () => null
})

export const useAdvancedDataContext = () => React.useContext(AdvancedDataContext)

const AdvancedDataProvider: React.FC<{}> = ({ children }) => {
  const [page, setPage] = useState<IPage>(null)
  const [data, setData] = useState<any[]>([])
  const [columns, setColumns] = useState<
    Column<{
      id: string | number
      code: string
    }>[]
  >([{ Header: 'PlaceHolder', accessor: 'placeHolder' }])
  const [sidePanel, setSidePanel] = useState<ISidePanel>([])
  const [exports, setExports] = useState<IExport>({
    csv: { data: [], header: [] },
    pdf: { data: [], header: [] },
    excel: { data: [], header: [] }
  })
  const [tablePanelWidth, setTablePanelWidth] = useState<string>('100%')
  const [selectedRow, setSelectedRow] = useState<any>([])
  const [exportForm, setExportForm] = useState<ExportForm>({} as ExportForm)
  const [exportObject, setExportObject] = useState({
    exportData: [] as any,
    exportMaxSize: 0,
    exportCurrentSize: 0,
    exportError: null
  })
  const [filter, setFilter] = useState<Maybe<AuditEntryFilterInput>>()

  function createData(data: any[]) {
    setData(data)
  }

  function createColumns(
    columns: Column<{
      id: string | number
      code: string
    }>[]
  ) {
    setColumns(columns)
  }

  function createSidePanel(sidePanel: ISidePanel) {
    setSidePanel(sidePanel)
  }

  function createExports(exports: IExport) {
    setExports(exports)
  }

  function createTablePanelWidth(width: string) {
    setTablePanelWidth(width)
  }

  function createSelectedRow(row: string) {
    setSelectedRow(row)
  }

  function createExportForm(form: ExportForm) {
    setExportForm(form)
  }

  function createExportObject(exportObject: IDataExport) {
    setExportObject(exportObject)
  }

  const createFilter = (filter: Maybe<AuditEntryFilterInput>) => {
    return new Promise<Maybe<AuditEntryFilterInput>>((resolve) => {
      setFilter(filter)
      resolve(filter)
    })
  }

  const createPage = (page: IPage) => {
    setPage(page)
  }

  return (
    <AdvancedDataContext.Provider
      value={{
        page,
        createPage,
        data,
        createData,
        columns,
        createColumns,
        sidePanel,
        createSidePanel,
        exports,
        createExports,
        tablePanelWidth,
        createTablePanelWidth,
        selectedRow,
        createSelectedRow,
        exportForm,
        createExportForm,
        exportObject,
        createExportObject,
        filter,
        createFilter
      }}
    >
      {children}
    </AdvancedDataContext.Provider>
  )
}

export default AdvancedDataProvider
