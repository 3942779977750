import * as React from 'react'
import { UserPermission } from '../generated/graphql'

type UserContextType = {
  newPermissions: UserPermission[]
  createNewPermissions: (permission: UserPermission) => void
  deleteNewPermissions: (stockOwnerId: number, warehouseId: number) => void
  updatedPermissions: UserPermission[]
  createUpdatedPermissions: (permission: UserPermission) => void
  deletablePermissions: UserPermission[]
  deletePermissions: (permission: UserPermission[]) => void
  clearPermissions: () => void
}

export const UserContext = React.createContext<UserContextType>({
  newPermissions: [],
  createNewPermissions: () => null,
  deleteNewPermissions: () => null,
  updatedPermissions: [],
  createUpdatedPermissions: () => null,
  deletablePermissions: [],
  deletePermissions: () => null,
  clearPermissions: () => null
})

export const useUserContext = () => React.useContext(UserContext)

const UserProvider: React.FC<{}> = ({ children }) => {
  const [updatedPermissions, setUpdatedPermissions] = React.useState<UserPermission[]>([])
  const [newPermissions, setNewPermissions] = React.useState<UserPermission[]>([])
  const [deletablePermissions, setDeletablePermissions] = React.useState<UserPermission[]>([])

  function createNewPermissions(status: UserPermission) {
    setNewPermissions([...newPermissions, status])
  }

  function deleteNewPermissions(stockOwnerId: number, warehouseId: number) {
    const filteredNewPermissions = newPermissions.filter(
      (newPermission) =>
        newPermission.stockOwnerId !== stockOwnerId && newPermission.warehouseId !== warehouseId
    )
    setNewPermissions(filteredNewPermissions)
  }

  function createUpdatedPermissions(status: UserPermission) {
    setUpdatedPermissions([...updatedPermissions, status])
  }

  function deletePermissions(Permissions: UserPermission[]) {
    setDeletablePermissions(Permissions)
  }

  function clearPermissions() {
    setNewPermissions([])
    setUpdatedPermissions([])
    setDeletablePermissions([])
  }

  return (
    <UserContext.Provider
      value={{
        updatedPermissions,
        newPermissions,
        deletablePermissions,
        createNewPermissions,
        deleteNewPermissions,
        createUpdatedPermissions,
        deletePermissions,
        clearPermissions
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
