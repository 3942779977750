import { Box, Collapse, Divider, Flex, List, ListItem, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { ChevronDown, ChevronUp, Circle } from 'react-feather'
import { ColorProps } from 'styled-system'
import { NavItems } from '.'
import Text from '../../components/Typography/Text'
import { AppContext } from '../../contexts/ApplicationProvider.context'
import usePersistState from '../../hooks/persistState'
import { NavItemWrapper, StyledLink, StyledLinkContainer } from '../SideBar/styles'

export type TSideBarItem = {
  hovercolor: string
  accentcolor: string
  color?: string
} & ColorProps &
  NavItems

const SideBarItem: React.FC<TSideBarItem> = ({
  title,
  to,
  icon,
  items = [],
  level,
  bg,
  color,
  hovercolor,
  accentcolor
}) => {
  const isExpandable = items
  const [open, setOpen] = usePersistState('sideBarOpen')
  const { drawerOpen } = React.useContext(AppContext)

  const handleClick = () => {
    setOpen({ [title]: !open[title] })
  }

  const margin = () => {
    switch (level) {
      case 1:
        return '8px'
      case 2:
        return '64px'
      case 3:
        return '80px'
      case 4:
        return '96px'
      default:
        return '8px'
    }
  }

  const NavItem = (
    <React.Fragment>
      {!drawerOpen && !icon && <Circle size={20} color="white" className="sidebar-menu-icon" />}
      {icon}
      {drawerOpen && (
        <Text cursor="pointer" ml={margin()} color="white">
          {title}
        </Text>
      )}
    </React.Fragment>
  )

  const DropdownNavItem = (
    <React.Fragment>
      {icon}
      {drawerOpen && (
        <Flex flex={1} width="25px">
          <Text cursor="pointer" ml={margin()} color="white" minWidth="130px">
            {title}
          </Text>
          {isExpandable && !open[title] && (
            <Box margin="auto">
              <ChevronDown size={15} />
            </Box>
          )}
          {isExpandable && open[title] && (
            <Box margin="auto">
              <ChevronUp size={15} />
            </Box>
          )}
        </Flex>
      )}
    </React.Fragment>
  )

  const SidebarItemRoot = (
    <ListItem onClick={!to ? handleClick : () => null} width="100%">
      {to && (
        <StyledLink
          to={to}
          activeClassName="active"
          exact
          bg={bg}
          color={color}
          hovercolor={hovercolor}
          accentcolor={accentcolor}
          className={`${drawerOpen ? 'nav-link' : 'nav-link-text-hidden'}`}
        >
          {drawerOpen && (
            <NavItemWrapper className="sidebar-nav-item-wrapper" open={drawerOpen}>
              {NavItem}
            </NavItemWrapper>
          )}
          {!drawerOpen && (
            <Tooltip hasArrow aria-label={title} label={title} placement="right">
              <NavItemWrapper className="sidebar-nav-item-wrapper" open={drawerOpen}>
                {NavItem}
              </NavItemWrapper>
            </Tooltip>
          )}
        </StyledLink>
      )}
      {!to && (
        <StyledLinkContainer
          px={4}
          padding={0}
          className={`${drawerOpen ? 'nav-link' : 'nav-link-text-hidden'}`}
        >
          {drawerOpen && (
            <NavItemWrapper className={`nav-link sidebar-nav-item-wrapper`} open={drawerOpen}>
              {DropdownNavItem}
            </NavItemWrapper>
          )}
          {!drawerOpen && (
            <Tooltip hasArrow aria-label={title} label={title} placement="right" shouldWrapChildren>
              <NavItemWrapper
                className={`'nav-link-text-hidden sidebar-nav-item-wrapper`}
                open={drawerOpen}
              >
                {DropdownNavItem}
              </NavItemWrapper>
            </Tooltip>
          )}
        </StyledLinkContainer>
      )}
    </ListItem>
  )
  const SideBarItemChildren =
    isExpandable.length > 0 ? (
      <Collapse in={open[title] || false} startingHeight={open[title] ? 'auto' : 0}>
        <List>
          {items.map((item, index) => (
            <SideBarItem
              key={index}
              bg={bg}
              color={color}
              hovercolor={hovercolor}
              accentcolor={accentcolor}
              {...item}
            />
          ))}
          {level === 1 && <Divider />}
        </List>
      </Collapse>
    ) : null

  return (
    <React.Fragment>
      {SidebarItemRoot}
      {SideBarItemChildren}
    </React.Fragment>
  )
}

export default SideBarItem
