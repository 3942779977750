import { Button, Flex, Text } from '@chakra-ui/react'
import * as React from 'react'
import { CardFooter, ModalWrap } from '..'

type ConfirmationModalProps = {
  onClose: (() => void) | undefined
  isOpen: boolean | undefined
  isLoading: boolean
  actionButtonText: string
  actionButtonVariant: string
  onSubmit: () => void
  message: string
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  onClose,
  isOpen,
  isLoading,
  actionButtonText,
  actionButtonVariant,
  onSubmit,
  message
}) => {
  return (
    <ModalWrap
      title="Confirmation"
      onClose={() => {
        onClose && onClose()
      }}
      isOpen={isOpen || false}
    >
      <Flex flexDirection="row" position="relative" p={2}>
        <Text textAlign="center">{message}</Text>
      </Flex>
      <CardFooter flexDirection="row" flex={1}>
        <Flex justifyContent="flex-end" flex={1}>
          <Button mr={2} variant="outline" type="submit" onClick={onClose}>
            Cancel
          </Button>
          <Button isLoading={isLoading} variantColor={actionButtonVariant} onClick={onSubmit}>
            {actionButtonText}
          </Button>
        </Flex>
      </CardFooter>
    </ModalWrap>
  )
}

export default ConfirmationModal
