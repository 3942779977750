import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import React, { useEffect } from 'react'
import { ChevronLeft, ChevronRight } from 'react-feather'

type ArrowProps = {
  disabled: boolean
  onClick: (e: React.MouseEvent) => void
}

type CustomTabsProps = {
  data: {
    label: string
    accessor: string
    content: JSX.Element
  }[]
  tabIndex: number
  updateTabIndex: (index: number) => void
  fieldsActive: boolean
  sliderSettings?: {
    initial?: number
    slidesPerView?: number
    spacing?: number
    mode?: 'snap' | 'free' | 'free-snap' | undefined
  }
  disabledTab?: {
    isDisabled: boolean
    accessor: string
  }
}

const SLIDES_PER_VIEW = 6

const CustomTabs: React.FC<CustomTabsProps> = ({
  data,
  tabIndex,
  updateTabIndex,
  fieldsActive,
  disabledTab,
  sliderSettings = { initial: 0, slidesPerView: SLIDES_PER_VIEW, spacing: 0, mode: 'free-snap' }
}) => {
  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [sliderRef, slider] = useKeenSlider({
    initial: sliderSettings.initial,
    slidesPerView: sliderSettings.slidesPerView,
    spacing: sliderSettings.spacing,
    mode: sliderSettings.mode,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    }
  })

  //If fieldsActive is false reset the slider to initial position
  useEffect(() => {
    if (!fieldsActive) {
      slider?.moveToSlide(0)
    }
    // eslint-disable-next-line
  }, [fieldsActive])
  return (
    <Tabs
      className="navigation-wrapper"
      isFitted
      isLazy
      variant="enclosed"
      index={tabIndex}
      onChange={(index: number) => {
        updateTabIndex(index)
      }}
    >
      <Flex>
        <Box marginY="auto" marginRight={1} {...(currentSlide === 0 ? { display: 'none' } : {})}>
          <ArrowLeft
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation()
              slider.prev()
            }}
            disabled={currentSlide === 0}
          />
        </Box>
        <TabList ref={sliderRef} className="keen-slider" flex={1} paddingY="0.5rem">
          {data.map((tab: any, index: number) => (
            <Tab
              css={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
              className={`keen-slider__slide number-slide${index}`}
              _selected={{
                color: fieldsActive ? 'white' : '',
                bg: fieldsActive ? 'solid.tab' : ''
              }}
              _disabled={{
                color: 'grey'
              }}
              key={index}
              isDisabled={
                !fieldsActive || (disabledTab?.isDisabled && tab.accessor === disabledTab.accessor)
              }
              minWidth="130px"
            >
              {tab.label}
            </Tab>
          ))}
        </TabList>
        <Box
          marginY="auto"
          marginLeft={1}
          {...(currentSlide + SLIDES_PER_VIEW === slider?.details().size
            ? { display: 'none' }
            : {})}
          {...(slider?.details().size < SLIDES_PER_VIEW ? { display: 'none' } : {})}
          {...(!fieldsActive ? { display: 'none' } : {})}
        >
          <ArrowRight
            onClick={(e: React.MouseEvent) => {
              if (fieldsActive) {
                e.stopPropagation()
                slider.next()
              }
            }}
            disabled={currentSlide + SLIDES_PER_VIEW === slider?.details().size - 1}
          />
        </Box>
      </Flex>
      {React.useMemo(
        () => (
          <TabPanels>
            {data.map((tab: any, index: number) => (
              <TabPanel key={index} paddingX={0} pt={3}>
                {tab.content}
              </TabPanel>
            ))}
          </TabPanels>
        ),
        // eslint-disable-next-line
        [fieldsActive, tabIndex]
      )}
    </Tabs>
  )
}

const ArrowLeft = ({ disabled, onClick }: ArrowProps) => {
  const disabeldClass = disabled ? ' arrow--disabled' : ''
  return <ChevronLeft onClick={onClick} className={'arrow arrow--right' + disabeldClass} />
}

function ArrowRight({ disabled, onClick }: ArrowProps) {
  const disabeldClass = disabled ? ' arrow--disabled' : ''
  return <ChevronRight onClick={onClick} className={'arrow arrow--right' + disabeldClass} />
}

export default CustomTabs
