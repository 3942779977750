import moment from 'moment'
import React, { FC, Suspense } from 'react'
import { Circle, Home } from 'react-feather'
import { Route, RouteProps, Switch } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'
import { FillLoader, SideBar } from '../components'
import { P } from '../components/Typography'
import PageNotFound from '../containers/PageNotFound'
import { useAuthentication } from '../hooks'
import { theme } from '../theme'
import { PRIVATE_ROUTES, PUBLIC_ROUTES } from './routes'

interface RouteType extends RouteProps {
  component: any
  title: string
}

const NAV_ITEMS = [
  {
    to: '/',
    title: 'Home',
    icon: <Home size={20} color="white" className="sidebar-menu-icon" />,
    level: 1
  },
  {
    title: 'Administration',
    icon: <Circle size={20} color="white" className="sidebar-menu-icon" />,
    level: 1,
    items: [
      {
        to: '/user-setup',
        title: 'User Setup',
        level: 2
      },
      {
        to: '/warehouse-setup',
        title: 'Warehouse Setup',
        level: 2
      },
      {
        to: '/stock-owner-setup',
        title: 'Stock Owner Setup',
        level: 2
      },
      {
        to: '/udc-setup',
        title: 'Code Setup',
        level: 2
      },
      {
        to: '/mhe-setup',
        title: 'MHE Setup',
        level: 2
      },
      {
        to: '/carrier-setup',
        title: 'Carrier Setup',
        level: 2
      }
    ]
  },
  {
    title: 'Setup',
    icon: <Circle size={20} color="white" className="sidebar-menu-icon" />,
    level: 1,
    items: [
      {
        to: '/location-setup',
        title: 'Location Setup',
        level: 2
      },
      {
        to: '/supplier-setup',
        title: 'Supplier Setup',
        level: 2
      },
      {
        to: '/storage-type-setup',
        title: 'Storage Type Setup',
        level: 2
      },
      {
        to: '/handling-type-setup',
        title: 'Handling Type Setup',
        level: 2
      },
      {
        to: '/zone-setup',
        title: 'Zone Setup',
        level: 2
      },

      {
        to: '/product-setup',
        title: 'Product Setup',
        level: 2
      },
      {
        to: '/destination-setup',
        title: 'Destination Setup',
        level: 2
      },
      {
        to: '/order-entry',
        title: 'Order Entry',
        level: 2
      },
      {
        to: '/budget-holder',
        title: 'Budget Holder',
        level: 2
      }
    ]
  },
  {
    title: 'Purchasing',
    icon: <Circle size={20} color="white" className="sidebar-menu-icon" />,
    level: 1,
    items: [
      {
        to: '/coming-soon',
        title: 'Purchase Order Enty',
        level: 2
      }
    ]
  },
  {
    title: 'Sales',
    icon: <Circle size={20} color="white" className="sidebar-menu-icon" />,
    level: 1,
    items: [
      {
        to: '/coming-soon',
        title: 'Sales Order Enty',
        level: 2
      }
    ]
  },
  {
    title: 'Receiving',
    icon: <Circle size={20} color="white" className="sidebar-menu-icon" />,
    level: 1,
    items: [
      {
        to: '/coming-soon',
        title: 'Booking Setup',
        level: 2
      },
      {
        to: '/coming-soon',
        title: 'Good Reciept',
        level: 2
      },
      {
        to: '/coming-soon',
        title: 'Put Away',
        level: 2
      }
    ]
  },
  {
    title: 'Picking',
    icon: <Circle size={20} color="white" className="sidebar-menu-icon" />,
    level: 1,
    items: [
      {
        to: '/coming-soon',
        title: 'Pick Creation',
        level: 2
      },
      {
        to: '/coming-soon',
        title: 'Pick Confirm',
        level: 2
      }
    ]
  },
  {
    title: 'Loading',
    icon: <Circle size={20} color="white" className="sidebar-menu-icon" />,
    level: 1,
    items: [
      {
        to: '/coming-soon',
        title: 'Create Load',
        level: 2
      },
      {
        to: '/coming-soon',
        title: 'Despatch Order',
        level: 2
      }
    ]
  },
  {
    title: 'Stock Managment',
    icon: <Circle size={20} color="white" className="sidebar-menu-icon" />,
    level: 1,
    items: [
      {
        to: '/coming-soon',
        title: 'Stock Adjustment',
        level: 2
      },
      {
        to: '/stock-enquiry',
        title: 'Stock Enquiry',
        level: 2
      },
      {
        to: '/coming-soon',
        title: 'Status Change',
        level: 2
      },
      {
        title: 'Movments',
        level: 2,
        items: [
          {
            to: '/coming-soon',
            title: 'Create Movment',
            level: 3
          },
          {
            to: '/coming-soon',
            title: 'Confirm Movment',
            level: 3
          },
          {
            to: '/coming-soon',
            title: 'Ad Hoc Movment',
            level: 3
          }
        ]
      }
    ]
  },
  {
    to: '/coming-soon',
    title: 'Reporting',
    icon: <Circle size={20} color="white" className="sidebar-menu-icon" />,
    level: 1
  },
  {
    to: '/coming-soon',
    title: 'Dashboards',
    icon: <Circle size={20} color="white" className="sidebar-menu-icon" />,
    level: 1
  },
  {
    to: '/audit-log',
    title: 'Audit Log',
    icon: <Circle size={20} color="white" className="sidebar-menu-icon" />,
    level: 1
  }
]

const Redirect: FC<{ redirectFn: () => void }> = ({ redirectFn }) => {
  redirectFn()
  return null
}

const PrivateRoute = ({ component: Component, ...rest }: RouteType) => {
  const { authenticating, isAuthenticated, signInRedirect } = useAuthentication()

  if (authenticating) {
    return <FillLoader bg="gray.50" />
  }

  if (isAuthenticated) {
    return (
      <Route {...rest}>
        <SideBar
          mode="push"
          color="white"
          bg="gray.800"
          navItems={NAV_ITEMS}
          hovercolor={theme.colors.gray[500]}
          accentcolor={theme.colors.gray[500]}
        >
          <React.Suspense fallback={<FillLoader bg="gray.50" />}>
            <Component />
          </React.Suspense>
        </SideBar>
      </Route>
    )
  }

  return <Redirect redirectFn={signInRedirect} />
}

const PublicRoute = ({ component: Component, ...rest }: RouteType) => (
  <Route {...rest}>
    <Component />
    <P textAlign="center" width="100%" paddingY={2} fontSize="sm" marginTop="-48px">
      Copyright &copy; {moment().year()} MACS Software
    </P>
  </Route>
)

const Navigation = () => (
  <Router>
    <Suspense fallback={<FillLoader />}>
      <Switch>
        {PUBLIC_ROUTES.map((route) => {
          return <PublicRoute key={route.path} {...route} />
        })}
        {PRIVATE_ROUTES.map((route) => {
          return <PrivateRoute key={route.path} {...route} />
        })}
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </Suspense>
  </Router>
)

export default Navigation
