import { Box, Flex, useDisclosure } from '@chakra-ui/react'
import { Field, FieldConfig, useFormikContext } from 'formik'
import React, { FC, useEffect } from 'react'
import { Row } from 'react-table'
import {
  HandlingTypeFilter,
  LocationFilter,
  MaterialHandlingEquipmentFilter,
  OrderFilter,
  StorageTypeFilter,
  SupplierFilter,
  WarehouseFilter,
  WarehouseZoneFilter,
  WmsUserFilterInput
} from '../../../generated/graphql'
import { theme } from '../../../theme'
import AdvancedSearchModal from '../../AdvancedSearchModal'
import DropdownButton from '../../DropdownButton'
import ConnectedFormGroup from '../ConnectedFormGroup'

type PrimaryFieldProps = FieldConfig<any> & {
  addNewText?: string
  label: string
  placeholder?: string
  isDisabled?: boolean
  isNormalCase?: boolean
  onAdd?: Function
  onEnter?: () => void
  onF2?: () => void
  onAdvanced?: () => void
  onSelect?: (row: Row<any>) => void
  showButton?: boolean
  advancedSearch?: {
    columns?: any
    data?: any
    filters?: {
      [key: string]: any
      name:
        | keyof HandlingTypeFilter
        | keyof LocationFilter
        | keyof OrderFilter
        | keyof SupplierFilter
        | keyof WarehouseFilter
        | keyof WarehouseZoneFilter
        | keyof MaterialHandlingEquipmentFilter
        | keyof StorageTypeFilter
        | keyof WmsUserFilterInput
    }[]
    objectFilters?: WmsUserFilterInput
    initialFilter?: string
    query: any
    queryResultKey?: string
    queryVariables?: object
    size?: number
  }
}

const advancedSearchButtonRef = React.createRef<HTMLDivElement>()

const PrimaryField: FC<PrimaryFieldProps> = ({
  addNewText,
  advancedSearch: advancedSearchProps,
  isNormalCase,
  onAdd,
  onAdvanced,
  onEnter,
  onF2,
  onSelect,
  showButton = true,
  validate,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { status, values, setStatus } = useFormikContext<any>()

  const handleKeyDown = ({ keyCode }: React.KeyboardEvent<HTMLElement>) => {
    if (keyCode === 13) {
      onEnter && values?.[props.name].length > 0 && onEnter()
    } else if (keyCode === 113) {
      onOpen()
      onF2 && onF2()
    }
  }

  useEffect(() => {
    if (status === 'advancedSearch') {
      if (advancedSearchButtonRef && advancedSearchButtonRef.current) {
        advancedSearchButtonRef.current.click()
      }
      setStatus(undefined)
    }
    //eslint-disable-next-line
  }, [status, setStatus])

  return (
    <Flex>
      <Field
        {...props}
        inputLeftAddonText={'F2'}
        inputLeftBackground={theme.colors.primary.base}
        onKeyDown={handleKeyDown}
        component={ConnectedFormGroup}
        maxWidth="400px"
        width="100%"
        mask={/^[a-zA-Z0-9]*$/}
        validate={validate}
        {...(!isNormalCase && { prepare: (value: string) => value.toUpperCase() })}
      />
      {showButton && (
        <Box paddingTop="25px" marginLeft={1}>
          <DropdownButton
            isDisabled={props.isDisabled || Boolean(values?.[props.name].length < 2)}
            onClick={() => onAdd && onAdd()}
            title={addNewText || 'Add New'}
          />
        </Box>
      )}
      <Box ref={advancedSearchButtonRef} display="none" onClick={onOpen} />
      {isOpen && (
        <AdvancedSearchModal
          onClose={onClose}
          isOpen={isOpen}
          onSelect={(row) => onSelect && onSelect(row)}
          isLoading={false}
          {...advancedSearchProps}
        />
      )}
    </Flex>
  )
}

export default PrimaryField
