import { Flex } from '@chakra-ui/react'
import * as React from 'react'
import { useMediaQuery } from 'react-responsive'
import { RouteComponentProps } from 'react-router'
import withBreadcrumbs, { BreadcrumbsProps } from 'react-router-breadcrumbs-hoc'
import { PRIVATE_ROUTES } from '../../navigation/routes'
import { H5 } from '../Typography'

const Breadcrumbs: React.FC<RouteComponentProps & { breadcrumbs: BreadcrumbsProps<{}>[] }> = ({
  breadcrumbs
}) => {
  const slicedBreadcrumbs = breadcrumbs.slice(1)
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })

  return (
    <Flex
      flexDirection="row"
      marginX={isTabletOrMobile ? 'auto' : 'none'}
      marginBottom={isTabletOrMobile ? 1 : 0}
    >
      {slicedBreadcrumbs.map(({ breadcrumb, match }, index) => {
        const ml = index === 0 ? 0 : 2
        return (
          <H5 key={match.url} fontSize={20} ml={ml} mr={2} mb={0}>
            {breadcrumb}
          </H5>
        )
      })}
    </Flex>
  )
}

export default withBreadcrumbs<{}>(PRIVATE_ROUTES)(Breadcrumbs)
