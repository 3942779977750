type SelectProps = {
  data: { [key: string]: string | number }[]
}

export const getSelectOptions = (response: SelectProps) => {
  return response.data.map((data) => {
    const keys = Object.keys(data)
    const value = keys[0]
    const label = keys[1]

    return { label: data[label], value: data[value].toString() }
  })
}
