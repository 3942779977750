import styled from '@emotion/styled'
import { TextTransformProperty } from 'csstype'
import {
  color,
  fontSize,
  fontWeight,
  layout,
  letterSpacing,
  lineHeight,
  space,
  textAlign,
  width
} from 'styled-system'
import { theme } from '../../theme'
import { StyledSystemProps } from '../../typings/StyledSystemProps'

type Props = StyledSystemProps & {
  pointer?: boolean
  textTransform?: TextTransformProperty
  color?: string
  letterSpacing?: string
  lineHeight?: string
}

const P = styled.p<Props>`
  ${layout};
  ${space};
  ${color};
  ${width};
  ${fontSize};
  ${textAlign};
  ${fontWeight};
  ${letterSpacing};
  ${lineHeight};
  text-transform: ${(props: Props) => props.textTransform || 'none'};
  &:hover {
    cursor: ${(props: Props) => (props.pointer ? 'pointer' : 'default')};
  }
`

export default P

P.defaultProps = {
  fontSize: 'md',
  color: theme.colors.text.default
}
