import { Flex, FlexProps } from '@chakra-ui/react'
import * as React from 'react'

type CardFooterProps = FlexProps

const CardFooter: React.FC<CardFooterProps> = ({ children, ...rest }) => {
  return (
    <Flex overflowX="hidden" overflowY="hidden" {...rest}>
      {children}
    </Flex>
  )
}

export default CardFooter

CardFooter.defaultProps = {
  borderTopWidth: 1,
  p: 2,
  bg: 'white',
  roundedBottomLeft: 4,
  roundedBottomRight: 4,
  flexDirection: 'column'
}
