import styled from '@emotion/styled'
import * as React from 'react'
import { Grid, GridProps } from 'react-flexbox-grid'
import { color, ColorProps, space, SpaceProps } from 'styled-system'

export type StyledContainerProps = GridProps &
  SpaceProps &
  ColorProps & {
    color?: string
    height?: string
  }

const StyledContainer = styled(Grid)`
  ${space};
  ${color};
`

const CustomContainer: React.FC<StyledContainerProps> = ({ children, height, ...rest }) => {
  return (
    <StyledContainer style={{ width: '100%', padding: 0, height }} {...rest}>
      {children}
    </StyledContainer>
  )
}

export default CustomContainer
