import * as React from 'react'
import { TComponent, TLicencePlate, TPickFace, TSupplier } from '../containers/ProductSetup'
import { TDocuments } from '../containers/ProductSetup/index'

type TBackenSupplier = { supplierId: number; warehouseId: number }
type TBackenBOM = { childId: number; qty: number }

type ProductSetupContextType = {
  tabIndex: number
  updateTabIndex: (index: number) => void

  newSuppliers: TBackenSupplier[]
  createNewSupplier: (supplier: TSupplier) => void
  deleteNewSupplier: (supplierId: number, warehouseId: number) => void
  updatedSuppliers: TBackenSupplier[]
  createUpdatedSupplier: (supplier: TSupplier) => void
  deletableSuppliers: TSupplier[]
  deleteSupplier: (suppliers: TSupplier[]) => void

  newBOMs: TBackenBOM[]
  createNewBOM: (BOM: TComponent) => void
  deleteNewBOM: (childId: number) => void
  updatedBOMs: TBackenBOM[]
  createUpdatedBOM: (component: TComponent) => void
  deletableBOMs: TComponent[]
  deleteBOM: (components: TComponent[]) => void

  newWarehouses: TPickFace[]
  createNewWarehouse: (warehouse: TPickFace) => void
  deleteNewWarehouse: (warehouse: string, location?: string, pickZone?: string) => void
  updatedWarehouses: TPickFace[]
  createUpdatedWarehouse: (warehouse: TPickFace) => void
  deletableWarehouses: TPickFace[]
  deleteWarehouse: (warehouse: TPickFace[]) => void

  newLicencePlates: TLicencePlate[]
  createNewLicencePlate: (LP: TLicencePlate) => void
  deleteNewLicencePlate: (handType: string) => void
  updatedLicencePlates: TLicencePlate[]
  createUpdatedLicencePlate: (LP: TLicencePlate) => void
  deletableLicencePlates: TLicencePlate[]
  deleteLicencePlate: (LPs: TLicencePlate[]) => void

  newDocuments: TDocuments[]
  createNewDocument: (document: TDocuments[]) => void
  deleteNewDocument: (
    fileName: string,
    uploaded: string,
    uploadedBy: string | null | undefined
  ) => void
  updatedDocuments: TDocuments[]
  createUpdatedDocument: (document: TDocuments) => void
  deletableDocuments: TDocuments[]
  deleteDocument: (documents: TDocuments[]) => void

  clearLicencePlate: () => void
  clearSupplier: () => void
  clearBOM: () => void
  clearWarehouse: () => void
  clearDocument: () => void
}

export const ProductSetupContext = React.createContext<ProductSetupContextType>({
  tabIndex: 0,
  updateTabIndex: () => null,

  newSuppliers: [],
  createNewSupplier: () => null,
  deleteNewSupplier: () => null,
  updatedSuppliers: [],
  createUpdatedSupplier: () => null,
  deletableSuppliers: [],
  deleteSupplier: () => null,
  clearSupplier: () => null,

  newBOMs: [],
  createNewBOM: () => null,
  deleteNewBOM: () => null,
  updatedBOMs: [],
  createUpdatedBOM: () => null,
  deletableBOMs: [],
  deleteBOM: () => null,
  clearBOM: () => null,

  newWarehouses: [],
  createNewWarehouse: () => null,
  deleteNewWarehouse: () => null,
  updatedWarehouses: [],
  createUpdatedWarehouse: () => null,
  deletableWarehouses: [],
  deleteWarehouse: () => null,
  clearWarehouse: () => null,

  newLicencePlates: [],
  createNewLicencePlate: () => null,
  deleteNewLicencePlate: () => null,
  updatedLicencePlates: [],
  createUpdatedLicencePlate: () => null,
  deletableLicencePlates: [],
  deleteLicencePlate: () => null,
  clearLicencePlate: () => null,

  newDocuments: [],
  createNewDocument: () => null,
  deleteNewDocument: () => null,
  updatedDocuments: [],
  createUpdatedDocument: () => null,
  deletableDocuments: [],
  deleteDocument: () => null,
  clearDocument: () => null
})

export const useProductSetupContext = () => React.useContext(ProductSetupContext)

const ProducSetupProvider: React.FC<{}> = ({ children }) => {
  const [updatedSuppliers, setUpdatedSuppliers] = React.useState<TBackenSupplier[]>([])
  const [newSuppliers, setNewSuppliers] = React.useState<TBackenSupplier[]>([])
  const [deletableSuppliers, setDeletableSuppliers] = React.useState<TSupplier[]>([])

  const [updatedBOMs, setUpdatedBOMs] = React.useState<TBackenBOM[]>([])
  const [newBOMs, setNewBOMs] = React.useState<TBackenBOM[]>([])
  const [deletableBOMs, setDeletableBOMs] = React.useState<TComponent[]>([])

  const [newWarehouses, setNewWarehouses] = React.useState<TPickFace[]>([])
  const [updatedWarehouses, setUpdatedWarehouses] = React.useState<TPickFace[]>([])
  const [deletableWarehouses, setDeletableWarehouses] = React.useState<TPickFace[]>([])

  const [newLicencePlates, setNewLicencePlates] = React.useState<TLicencePlate[]>([])
  const [updatedLicencePlates, setUpdatedLicencePlates] = React.useState<TLicencePlate[]>([])
  const [deletableLicencePlates, setDeletableLicencePlates] = React.useState<TLicencePlate[]>([])

  const [newDocuments, setNewDocuments] = React.useState<TDocuments[]>([])
  const [updatedDocuments, setUpdatedDocuments] = React.useState<TDocuments[]>([])
  const [deletableDocuments, setDeletableDocuments] = React.useState<TDocuments[]>([])

  const [tabIndex, setTabIndex] = React.useState<number>(0)

  function updateTabIndex(index: number) {
    setTabIndex(index)
  }

  function createNewSupplier(supplier: TSupplier) {
    const { supplierId, warehouseId, active } = supplier
    const formattedSupplier = {
      active,
      supplierId,
      warehouseId
    }
    setNewSuppliers([...newSuppliers, formattedSupplier])
  }

  function deleteNewSupplier(supplierId: number, warehouseId: number) {
    const filteredNewSupplier = newSuppliers.filter(
      (newSupplier) =>
        newSupplier.supplierId !== supplierId && newSupplier.warehouseId !== warehouseId
    )
    setNewSuppliers(filteredNewSupplier)
  }

  function createUpdatedSupplier(supplier: TSupplier) {
    const { supplierId, warehouseId, active, id } = supplier
    const formattedSupplier = {
      id,
      supplierId,
      warehouseId,
      active
    }

    setUpdatedSuppliers([...updatedSuppliers, formattedSupplier])
  }

  function deleteSupplier(supplier: TSupplier[]) {
    setDeletableSuppliers(supplier)
  }

  function clearSupplier() {
    setUpdatedSuppliers([])
    setNewSuppliers([])
    setDeletableSuppliers([])
  }

  function createNewBOM(component: TComponent) {
    const { childId, qty } = component
    const formattedComponent = {
      childId,
      qty
    }
    setNewBOMs([...newBOMs, formattedComponent])
  }

  function deleteNewBOM(childId: number) {
    const filteredNewBOM = newBOMs.filter((newBOM) => newBOM.childId !== childId)
    setNewBOMs(filteredNewBOM)
  }

  function createUpdatedBOM(component: TComponent) {
    const { childId, qty, id } = component
    const formattedComponent = {
      id,
      childId,
      qty
    }

    setUpdatedBOMs([...updatedBOMs, formattedComponent])
  }

  function deleteBOM(component: TComponent[]) {
    setDeletableBOMs(component)
  }

  function clearBOM() {
    setUpdatedBOMs([])
    setNewBOMs([])
    setDeletableSuppliers([])
  }

  function createNewWarehouse(warehouse: TPickFace) {
    const { id, ...rest } = warehouse
    setNewWarehouses([...newWarehouses, rest])
  }

  function deleteNewWarehouse(warehouse: string, location?: string, pickZone?: string) {
    const filteredNewWarehouse = newWarehouses.filter(
      (newWarehouse) =>
        (newWarehouse.warehouse !== warehouse && location && newWarehouse.location !== location) ||
        (pickZone && newWarehouse.pickZone !== pickZone)
    )
    setNewWarehouses(filteredNewWarehouse)
  }

  function createUpdatedWarehouse(warehouse: TPickFace) {
    setUpdatedWarehouses([...updatedWarehouses, warehouse])
  }

  function deleteWarehouse(warehouse: TPickFace[]) {
    setDeletableWarehouses(warehouse)
  }

  function clearWarehouse() {
    setUpdatedWarehouses([])
    setNewWarehouses([])
    setDeletableWarehouses([])
  }

  function createNewLicencePlate(LP: TLicencePlate) {
    const { id, ...rest } = LP
    setNewLicencePlates([...newLicencePlates, rest])
  }
  function deleteNewLicencePlate(handType: string) {
    const filteredNewLP = newLicencePlates.filter((newLP) => newLP.handType !== handType)
    setNewLicencePlates(filteredNewLP)
  }

  function createUpdatedLicencePlate(LP: TLicencePlate) {
    setUpdatedLicencePlates([...updatedLicencePlates, LP])
  }

  function deleteLicencePlate(LP: TLicencePlate[]) {
    setDeletableLicencePlates(LP)
  }

  function clearLicencePlate() {
    setUpdatedLicencePlates([])
    setNewLicencePlates([])
    setDeletableLicencePlates([])
  }

  function createNewDocument(document: TDocuments[]) {
    const documentValues = document.map(({ id, ...keepAttrs }) => keepAttrs)
    setNewDocuments([...newDocuments, ...documentValues])
  }
  function deleteNewDocument(
    fileName: string,
    uploaded: string,
    uploadedBy: string | null | undefined
  ) {
    const filteredNewDocument = newDocuments.filter(
      (newDoc) =>
        newDoc.name !== fileName && newDoc.uploaded !== uploaded && newDoc.uploadBy !== uploadedBy
    )
    setNewDocuments(filteredNewDocument)
  }

  function createUpdatedDocument(document: TDocuments) {
    setUpdatedDocuments([...updatedDocuments, document])
  }

  function deleteDocument(document: TDocuments[]) {
    setDeletableDocuments(document)
  }

  function clearDocument() {
    setUpdatedDocuments([])
    setNewDocuments([])
    setDeletableDocuments([])
  }

  return (
    <ProductSetupContext.Provider
      value={{
        tabIndex,
        updateTabIndex,

        newSuppliers,
        createNewSupplier,
        deleteNewSupplier,
        updatedSuppliers,
        createUpdatedSupplier,
        deletableSuppliers,
        deleteSupplier,
        clearSupplier,

        newBOMs,
        createNewBOM,
        deleteNewBOM,
        updatedBOMs,
        createUpdatedBOM,
        deletableBOMs,
        deleteBOM,
        clearBOM,

        newWarehouses,
        createNewWarehouse,
        deleteNewWarehouse,
        updatedWarehouses,
        createUpdatedWarehouse,
        deletableWarehouses,
        deleteWarehouse,
        clearWarehouse,

        newLicencePlates,
        createNewLicencePlate,
        deleteNewLicencePlate,
        updatedLicencePlates,
        createUpdatedLicencePlate,
        deletableLicencePlates,
        deleteLicencePlate,
        clearLicencePlate,

        newDocuments,
        createNewDocument,
        deleteNewDocument,
        updatedDocuments,
        createUpdatedDocument,
        deletableDocuments,
        deleteDocument,
        clearDocument
      }}
    >
      {children}
    </ProductSetupContext.Provider>
  )
}

export default ProducSetupProvider
