import { Avatar, Box, Flex } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import * as React from 'react'
import { useState } from 'react'
import { Home, Users } from 'react-feather'
import { useMediaQuery } from 'react-responsive'
import { RouteComponentProps, withRouter } from 'react-router'
import { ColorProps } from 'styled-system'
import { ConnectedSelect, DropdownMenu } from '..'
import { USER_CONTEXT_STORAGE_KEY } from '../../constants'
import { useAppContext } from '../../contexts/ApplicationProvider.context'
import {
  useSelectStockOwnersLazyQuery,
  useSelectStockOwnersQuery,
  useSelectWarehousesLazyQuery,
  useSelectWarehousesQuery
} from '../../generated/graphql'
import { useAuthentication, useLocalStorage } from '../../hooks'
import { theme } from '../../theme'
import { SelectObject } from '../FormElements/ConnectedSelect'
import HamburgerIcon from '../SideBar/icon'
import { H5 } from '../Typography'
import ChangePasswordModal from './changePasswordModal'
import { HeaderCont, MobileHeaderCont } from './styles'

const INITIAL_VALUES = {
  productLocation: [],
  stockOwner: []
}

type HeaderProps = RouteComponentProps &
  ColorProps & {
    color?: string
    size?: number
    id?: string
    open?: boolean
    getLoggedInUser?: () => { name?: string; id: string }
  }

/**
 * @render react
 * @name Header component
 * @description Main Header Component
 */

const Header: React.FC<HeaderProps> = ({ ...rest }) => {
  const [openChangePassword, setOpenChangePassword] = useState(false)

  const { drawerOpen, toggleDrawer, createResetTableData } = useAppContext()
  const { cognitoUser, signOut } = useAuthentication()

  const [
    selectWarehousesQuery,
    { data: selectWarehouseData, loading: selectWarehouseLoading }
  ] = useSelectWarehousesLazyQuery({
    fetchPolicy: 'network-only'
  })

  const { data: warehousesData } = useSelectWarehousesQuery({ fetchPolicy: 'network-only' })

  const warehouses =
    warehousesData?.warehouses?.nodes?.map((node) => ({
      label: node?.warehouseName,
      value: node?.id
    })) || []

  const [
    selectStockOwnersQuery,
    { data: selectStockOwnersData, loading: selectStockOwnerLoading }
  ] = useSelectStockOwnersLazyQuery({
    fetchPolicy: 'network-only'
  })

  const { data: stockOwnersData } = useSelectStockOwnersQuery({ fetchPolicy: 'network-only' })
  const stockOwners =
    stockOwnersData?.stockOwners?.nodes?.map((node) => ({
      label: node?.name,
      value: node?.id
    })) || []
  const [initialUserContext, setInitialUserContext] = useLocalStorage('WMSInitialUserContext', {
    productLocation: warehouses[0],
    stockOwner: stockOwners[0]
  })

  const stockOwnerId = () => {
    try {
      //@ts-ignore - Object is possibly 'null' or 'undefined'
      return stockOwnersData?.stockOwners?.nodes[0]?.id
    } catch (error) {
      return 0
    }
  }

  const warehouseId = () => {
    try {
      //@ts-ignore - Object is possibly 'null' or 'undefined'
      return warehousesData?.warehouses?.nodes[0]?.id
    } catch (error) {
      return 0
    }
  }

  const [userContext, setUserContext] = useLocalStorage(USER_CONTEXT_STORAGE_KEY, {
    stockOwnerId: stockOwnerId(),
    warehouseId: warehouseId()
  })

  const initialValues = {
    ...INITIAL_VALUES,
    ...initialUserContext
  }

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })
  const isTablet = useMediaQuery({ query: '(max-width: 50em)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 1366px)' })
  const showHeaderContent = isTabletOrMobile ? !drawerOpen : true
  const handleResize = () => {
    window.innerWidth < 1366 ? toggleDrawer(false) : toggleDrawer(true)
  }

  /* 
    Initial Value for local storage does not write data to the actual local storage
    These 2 useEffects check to see if the Id in local storage is undefined - meaning its the first time someone has logged in
    If true then it writes the first Warehouse and Stock Owner to the local storage so it can be used by the rest of the application
    If false then it keeps the currently selected value inside of local storage
  */
  React.useEffect(() => {
    if (userContext.warehouseId === undefined && warehousesData?.warehouses?.nodes) {
      setUserContext({
        ...userContext,
        warehouseId: warehousesData?.warehouses?.nodes[0]?.id
      })
    }

    initialUserContext.productLocation === undefined &&
      setInitialUserContext({ ...initialUserContext, productLocation: warehouses[0] })
    //only want to update when data changes
    //eslint-disable-next-line
  }, [warehousesData])
  React.useEffect(() => {
    if (userContext.stockOwnerId === undefined && stockOwnersData?.stockOwners?.nodes) {
      setUserContext({
        ...userContext,
        stockOwnerId: stockOwnersData?.stockOwners?.nodes[0]?.id
      })
    }

    initialUserContext.stockOwner === undefined &&
      setInitialUserContext({ ...initialUserContext, stockOwner: stockOwners[0] })
    //only want to update when data changes
    //eslint-disable-next-line
  }, [stockOwnersData])

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }

    //vs throws error if dependency is added
    //eslint-disable-next-line
  }, [])

  const renderForm = () => {
    return (
      <Formik enableReinitialize initialValues={initialValues} onSubmit={() => {}}>
        <Form style={{ width: '100%' }}>
          <Flex flexDirection="row" justifyContent="center" alignItems="center" flex={1}>
            <Box padding={1}>
              <Home size={20} />
            </Box>
            <Field
              name="productLocation"
              placeholder="Warehouse"
              mb={0}
              mr={2}
              minWidth="auto"
              isLoading={selectWarehouseLoading}
              onMenuOpen={() => selectWarehousesQuery()}
              onSelect={(value: number, option: SelectObject) => {
                setUserContext({ ...userContext, warehouseId: value })
                setInitialUserContext({ ...initialUserContext, productLocation: [option] })
                createResetTableData(true)
              }}
              options={
                selectWarehouseData?.warehouses?.nodes?.map((node) => ({
                  label: node?.warehouseName,
                  value: node?.id
                })) || []
              }
              component={ConnectedSelect}
            />
            <Box padding={1}>
              <Users size={20} />
            </Box>
            <Field
              name="stockOwner"
              placeholder="Select Stock Owner"
              mb={0}
              mr={1}
              minWidth="auto"
              isLoading={selectStockOwnerLoading}
              onMenuOpen={() => selectStockOwnersQuery()}
              onSelect={(value: number, option: SelectObject) => {
                setUserContext({ ...userContext, stockOwnerId: value })
                setInitialUserContext({ ...initialUserContext, stockOwner: [option] })
                createResetTableData(true)
              }}
              options={
                selectStockOwnersData?.stockOwners?.nodes?.map((node) => ({
                  label: node?.name,
                  value: node?.id
                })) || []
              }
              component={ConnectedSelect}
            />
          </Flex>
        </Form>
      </Formik>
    )
  }

  const renderName = () => {
    if (cognitoUser?.user.firstname !== '' && cognitoUser?.user.surname !== '') {
      return `${cognitoUser?.user.firstname} ${cognitoUser?.user.surname}`
    } else if (cognitoUser?.user.userName !== '') {
      return cognitoUser?.user.userName
    } else {
      return ''
    }
  }

  return (
    <React.Fragment>
      <HeaderCont px={4} {...rest} justifyContent={isTabletOrMobile ? 'flex-end' : 'space-between'}>
        <Flex flexDirection="row" flex={isDesktop ? 1 : 0}>
          <Flex flexDirection="row" justifyContent="center" alignItems="center">
            <HamburgerIcon
              ml={0}
              open={false}
              onClick={() => {
                toggleDrawer()
              }}
              bg={drawerOpen ? 'gray.400' : 'gray.800'}
            />
          </Flex>
        </Flex>
        {showHeaderContent && (
          <Flex alignItems="center" flexDirection="row" flex={2} justifyContent="space-evenly">
            <Flex
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              flex={2}
              marginLeft={2}
            >
              {!isTabletOrMobile && renderForm()}
            </Flex>
            <Flex flexDirection="row" justifyContent="center" alignItems="center">
              <DropdownMenu
                menuItems={[
                  { title: 'Change Password', onClick: () => setOpenChangePassword(true) },
                  {
                    title: 'Logout',
                    onClick: () => {
                      signOut()
                    }
                  }
                ]}
                title={cognitoUser?.user.userName || ''}
                bg={'solid.white'}
                icon={
                  <React.Fragment>
                    <Avatar
                      bg="background.base"
                      size="sm"
                      my="auto"
                      ml={-3}
                      name={cognitoUser?.user.firstname || cognitoUser?.user.userName || ''}
                      src="../"
                    />
                    {!isTablet && (
                      <Box
                        height="28px"
                        borderLeft="1px solid"
                        borderColor={theme.colors.background.base}
                        ml={1}
                      />
                    )}
                    {!isTablet && (
                      <H5 mb={0} ml={1} fontSize="md">
                        {renderName()}
                      </H5>
                    )}
                  </React.Fragment>
                }
              />
              <ChangePasswordModal
                open={openChangePassword}
                onClose={() => setOpenChangePassword(false)}
              />
            </Flex>
          </Flex>
        )}
      </HeaderCont>
      {isTabletOrMobile && (
        <MobileHeaderCont px={4} {...rest} justifyContent="space-evenly">
          <Flex flex={1}>{renderForm()}</Flex>
        </MobileHeaderCont>
      )}
    </React.Fragment>
  )
}

export default withRouter(Header)

Header.defaultProps = {
  bg: 'background.surface'
}
