import { BoxProps, Flex } from '@chakra-ui/react'
import React from 'react'
import { theme } from '../../theme'

export type CardProps = BoxProps & {
  onClick?: () => void
  color?: string
  floating?: boolean
  ref?: ((instance: any) => void) | React.MutableRefObject<any> | null
}

const Card: React.FC<CardProps> = React.forwardRef<any, CardProps>(
  ({ onClick, children, floating, ...rest }, ref) => {
    return (
      <Flex
        ref={ref}
        onClick={onClick}
        {...rest}
        style={(floating && { boxShadow: theme.boxShadow.thick }) || {}}
      >
        {children}
      </Flex>
    )
  }
)

Card.defaultProps = {
  bg: 'background.surface',
  width: 'auto',
  rounded: 'lg',
  borderWidth: '1px',
  onClick: () => false,
  flexDirection: 'column',
  floating: true
}

export default Card
