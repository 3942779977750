import { lazy } from 'react'
import { RouteProps } from 'react-router'
import ComingSoon from '../containers/ComingSoon/index'
// Public
const Authenticate = lazy(() => import('../containers/Authenticate'))
// Private
const Home = lazy(() => import('../containers/Home'))
const StorageTypeSetup = lazy(() => import('../containers/StorageTypeSetup'))
const UserSetup = lazy(() => import('../containers/UserSetup'))
const WarehouseSetup = lazy(() => import('../containers/WarehouseSetup'))
const OrderEntry = lazy(() => import('../containers/OrderEntry'))
const ZoneSetup = lazy(() => import('../containers/ZoneSetup'))
const HandlingTypeSetup = lazy(() => import('../containers/HandlingTypeSetup'))
const UDCSetup = lazy(() => import('../containers/UDCSetup'))
const SupplierSetup = lazy(() => import('../containers/SupplierSetup'))
const LocationSetup = lazy(() => import('../containers/LocationSetup'))
const StockOwnerSetup = lazy(() => import('../containers/StockOwnerSetup'))
const ProductSetup = lazy(() => import('../containers/ProductSetup'))
const MHESetup = lazy(() => import('../containers/MHE'))
const DestinationSetup = lazy(() => import('../containers/DestinationSetup'))
const BudgetHolder = lazy(() => import('../containers/BudgetHolder/index'))
const StockEnquiry = lazy(() => import('../containers/StockEnquiry'))
const CarrierSetup = lazy(() => import('../containers/CarrierSetup'))
const AuditLog = lazy(() => import('../containers/AuditLog'))

export interface PrivateRouteObject extends RouteProps {
  exact: boolean
  path: string
  breadcrumb: string
  component: any
  title: string
}

const PRIVATE_ROUTES: PrivateRouteObject[] = [
  {
    exact: true,
    path: '/',
    breadcrumb: 'Home',
    component: Home,
    title: 'Home'
  },
  {
    exact: true,
    path: '/storage-type-setup',
    breadcrumb: 'Storage Type Setup',
    component: StorageTypeSetup,
    title: 'Storage Type Setup'
  },
  {
    exact: true,
    path: '/user-setup',
    breadcrumb: 'User Setup',
    component: UserSetup,
    title: 'User Setup'
  },
  {
    exact: true,
    path: '/warehouse-setup',
    breadcrumb: 'Warehouse Setup',
    component: WarehouseSetup,
    title: 'Warehouse Setup'
  },
  {
    exact: true,
    path: '/order-entry',
    breadcrumb: 'Order Entry',
    component: OrderEntry,
    title: 'Order Entry'
  },
  {
    exact: true,
    path: '/zone-setup',
    breadcrumb: 'Zone Setup',
    component: ZoneSetup,
    title: 'Zone Setup'
  },
  {
    exact: true,
    path: '/handling-type-setup',
    breadcrumb: 'Handling Type Setup',
    component: HandlingTypeSetup,
    title: 'Handling Type Setup'
  },
  {
    exact: true,
    path: '/UDC-setup',
    breadcrumb: 'Code Setup',
    component: UDCSetup,
    title: 'Code Setup'
  },
  {
    exact: true,
    path: '/supplier-setup',
    breadcrumb: 'Supplier Setup',
    component: SupplierSetup,
    title: 'Supplier Setup'
  },
  {
    exact: true,
    path: '/location-setup',
    breadcrumb: 'Location Setup',
    component: LocationSetup,
    title: 'Location Setup'
  },
  {
    exact: true,
    path: '/stock-owner-setup',
    breadcrumb: 'Stock Owner Setup',
    component: StockOwnerSetup,
    title: 'Stock Owner Setup'
  },
  {
    exact: true,
    path: '/product-setup',
    breadcrumb: 'Product Setup',
    component: ProductSetup,
    title: 'Product Setup'
  },
  {
    exact: true,
    path: '/mhe-setup',
    breadcrumb: 'MHE Setup',
    component: MHESetup,
    title: 'MHE Setup'
  },
  {
    exact: true,
    path: '/destination-setup',
    breadcrumb: 'Destination Setup',
    component: DestinationSetup,
    title: 'Destination Setup'
  },
  {
    exact: true,
    path: '/budget-holder',
    breadcrumb: 'Budget Holder',
    component: BudgetHolder,
    title: 'Budget Holder'
  },
  {
    exact: true,
    path: '/stock-enquiry',
    breadcrumb: 'Stock Enquiry',
    component: StockEnquiry,
    title: 'Stock Enquiry'
  },
  {
    exact: true,
    path: '/carrier-setup',
    breadcrumb: 'Carrier Setup',
    component: CarrierSetup,
    title: 'Carrier Setup'
  },
  {
    exact: true,
    path: '/audit-log',
    breadcrumb: 'Audit Log',
    component: AuditLog,
    title: 'Audit Log'
  },
  {
    exact: true,
    path: '/coming-soon',
    breadcrumb: 'Coming Soon',
    component: ComingSoon,
    title: 'Coming Soon'
  }
]

const PUBLIC_ROUTES = [
  {
    exact: true,
    path: '/authenticate',
    component: Authenticate,
    title: 'Authenticate'
  }
]

export { PUBLIC_ROUTES, PRIVATE_ROUTES }
