import { Box, Button, Checkbox, Flex, IconButton, Input, Spinner, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
  ChevronUp
} from 'react-feather'
import { useMediaQuery } from 'react-responsive'
import {
  Cell,
  Column,
  ColumnInstance,
  HeaderGroup,
  Hooks,
  Row,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable
} from 'react-table'
import { AutoSizer } from 'react-virtualized'
import { FixedSizeList } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { useTableContext } from '../../contexts/TableProvider.context'
import { theme } from '../../theme'
import { logger } from '../../utils'
import Card from '../Card'
import BottomSection from '../Card/CardFooter'
import TopSection from '../Card/CardHeader/index'
import { StyledTable, TableCell, TableHead, TableIconButton, TableRow } from './styles'

// Use declaration merging to extend types https://github.com/tannerlinsley/react-table/commit/7ab63858391ebb2ff621fa71411157df19d916ba
declare module 'react-table' {
  export interface TableOptions<D extends object>
    extends UsePaginationOptions<D>,
      UseFiltersOptions<D> {}

  export interface TableInstance<D extends object = {}> extends UsePaginationInstanceProps<D> {}

  export interface TableState<D extends object = {}> extends UsePaginationState<D> {
    selectedRowIds: object
  }

  export interface ColumnInstance<D extends object = {}> extends UseSortByColumnProps<D> {
    flex?: number | string
  }
}

type TableProps<D extends object = {}> = {
  data: any
  pageSize?: number
  initialPageIndex?: number
  tableHeading?: React.ReactNode
  columns: Column<D>[]
  onPageChange?: (pageIndex: number) => void
  onRowClick?: (row: Row<D>) => void
  onRowDoubleClick?: (row: Row<D>) => void
  onRowSelect?: (rows: Row<D>[]) => void
  updateMyData?: Function
  skipPageReset?: boolean
  editable?: boolean
  pageNeighbours?: number
  useCheckboxSelection?: boolean
  useNumberedPagination?: boolean
  useSingleRowSelect?: boolean
  useInfiniteScroll?: boolean
  fillTable?: boolean
  loadNextPage?: (startIndex: number, endIndex: number) => any
  hasMore?: boolean
  isNextPageLoading?: boolean
  totalCount?: number
  listRef?: React.RefObject<FixedSizeList>
  toggleSelection?: any
  isSelectionDisabled?: boolean
  disableSelectAtIndex?: number
  useDoubleClick?: boolean
}

type EditableCellProps<D extends object = {}> = {
  column: ColumnInstance<D>
  row: Row<D>
  cell: Cell<D>
  updateMyData: Function
}

// Create an editable cell renderer
const EditableCell = ({
  cell: { value: initialValue },
  row: { index },
  column: { id },
  updateMyData // This is a custom function that we supplied to our table instance
}: EditableCellProps) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData && updateMyData(index, id, value)
  }

  // If the initialValue is changed externall, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return <input value={value} onChange={onChange} onBlur={onBlur} />
}

const IndeterminateCheckbox = React.forwardRef(
  // @ts-ignore - Property 'checked' and 'indeterminate' do not exist on type '{ children?: ReactNode; }'
  ({ checked, indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      // @ts-ignore - Property 'current' does not exist on type '((instance: unknown) => void) | MutableRefObject<unknown>'
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <Checkbox
        // @ts-ignore - Type 'MutableRefObject<unknown>' is not assignable to type 'RefObject<HTMLInputElement>'
        ref={resolvedRef}
        isChecked={checked}
        isIndeterminate={indeterminate}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        {...rest}
      />
    )
  }
)

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from: number, to: number, step = 1) => {
  let i = from
  const range = []

  while (i <= to) {
    range.push(i)
    i += step
  }

  return range
}

const LEFT_PAGE = 'LEFT'
const RIGHT_PAGE = 'RIGHT'

/**
 * @render react
 * @name Table component
 * @description Not available
 */

const Table = <D extends {}>({
  columns,
  data,
  tableHeading,
  pageSize: initialPageSize,
  onPageChange,
  onRowClick,
  onRowDoubleClick,
  onRowSelect,
  updateMyData,
  editable,
  pageNeighbours: numOfPageNeighbours,
  useCheckboxSelection,
  useNumberedPagination,
  useSingleRowSelect,
  useInfiniteScroll,
  skipPageReset,
  initialPageIndex,
  fillTable,
  loadNextPage,
  hasMore,
  isNextPageLoading,
  listRef,
  toggleSelection,
  isSelectionDisabled,
  disableSelectAtIndex,
  useDoubleClick
}: TableProps<D>) => {
  const [prevRow, setPrevRow] = useState<any>()

  const tableColumns = React.useMemo(() => columns, [columns])

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })

  const {
    rows,
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    // @ts-ignore - Property 'selectedFlatRows' does not exist on type 'TableInstance<D>'
    selectedFlatRows,
    //@ts-ignore - Property 'toggleAllRowsSelected' does not exist on type 'TableInstance<D>'
    toggleAllRowsSelected,
    state: { pageIndex, pageSize }
  } = useTable<D>(
    {
      //@ts-ignore - Object literal may only specify known properties
      autoResetSelectedRows: false,
      columns: tableColumns,
      data,
      initialState: {
        pageIndex: initialPageIndex,
        pageSize: initialPageSize,
        selectedRowIds: {}
      },
      ...(editable && {
        defaultColumn: {
          Cell: EditableCell
        },
        // use the skipPageReset option to disable page resetting temporarily
        autoResetPage: !skipPageReset,
        // updateMyData isn't part of the API, but
        // anything we put into these options will
        // automatically be available on the instance.
        // That way we can call this function from our
        // cell renderer!
        // @ts-ignore
        updateMyData
      })
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks: Hooks<D>) => {
      hooks.visibleColumns.push((columns) =>
        useCheckboxSelection && !isSelectionDisabled
          ? [
              // Let's make a column for selection
              {
                id: 'selection',
                // The header can use the table's getToggleAllRowsSelectedProps method
                // to render a checkbox
                Header: ({ getToggleAllRowsSelectedProps, toggleAllRowsSelected, rows }: any) =>
                  useSingleRowSelect ? null : (
                    <IndeterminateCheckbox
                      {...getToggleAllRowsSelectedProps()}
                      marginX={-1}
                      marginY={-2}
                      padding={2}
                      onClick={(e: React.ChangeEvent) => {
                        e.preventDefault()
                        if (disableSelectAtIndex !== undefined) {
                          rows.forEach((row: Row<D>) => {
                            if (row.index > 0) {
                              //@ts-ignore - Property 'toggleRowSelected' does not exist on type 'Row<D>' - it does
                              row.toggleRowSelected()
                            }
                          })
                        } else {
                          toggleAllRowsSelected()
                        }
                      }}
                    />
                  ),
                // The cell can use the individual row's getToggleRowSelectedProps method
                // to render a checkbox
                Cell: ({ row }) => {
                  if (row.index === disableSelectAtIndex) {
                    return null
                  }
                  return (
                    <IndeterminateCheckbox
                      //@ts-ignore - Property 'id' does not exist on type 'D'
                      id={`checkbox-${row.original.id || row.id}`}
                      // @ts-ignore
                      {...row.getToggleRowSelectedProps()}
                      margin={-1}
                      padding={2}
                      onClick={(e: React.ChangeEvent) => {
                        logger(row)
                        // @ts-ignore Property 'getToggleRowSelectedProps' does not exist on type 'Row<D>' - it does
                        !useSingleRowSelect && row.getToggleRowSelectedProps()
                      }}
                    />
                  )
                },
                flex: 0,
                minWidth: 12,
                width: 12
              },
              ...columns
            ]
          : [...columns]
      )
    }
  )

  const { toggleSelection: contextToggle, createToggleSelection } = useTableContext()

  useEffect(() => {
    onRowSelect && onRowSelect(selectedFlatRows)
    // eslint-disable-next-line
  }, [selectedFlatRows])

  useEffect(() => {
    onPageChange && onPageChange(pageIndex)
  }, [onPageChange, pageIndex])

  const pageNeighbours =
    typeof numOfPageNeighbours === 'number' ? Math.max(0, Math.min(numOfPageNeighbours, 2)) : 0

  /**
   * Let's say we have 10 pages and we set pageNeighbours to 2
   * Given that the current page is 6
   * The pagination control will look like the following:
   *
   * (1) < {4 5} [6] {7 8} > (10)
   *
   * (x) => terminal pages: first and last page(always visible)
   * [x] => represents current page
   * {...x} => represents page neighbours
   */
  const fetchPageNumbers = () => {
    const totalPages = pageCount
    const currentPage = pageIndex + 1

    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3
    const totalBlocks = totalNumbers + 2

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours)
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours)

      let pages: (number | string)[] = range(startPage, endPage)
      logger({ endPage, pages, startPage })

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2
      const hasRightSpill = totalPages - endPage > 1
      const spillOffset = totalNumbers - (pages.length + 1)

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1)
          pages = [LEFT_PAGE, ...extraPages, ...pages]
          break
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset)
          pages = [...pages, ...extraPages, RIGHT_PAGE]
          break
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE]
          break
        }
      }

      return [1, ...pages, totalPages]
    }

    return range(1, totalPages)
  }
  const CustomTableRow = page.map(
    (row: Row<D>, key: any) =>
      // @ts-ignore
      prepareRow(row) || (
        <TableRow
          onDoubleClick={() => {
            logger('DOUBLE')
            onRowDoubleClick && onRowDoubleClick(row)
          }}
          onClick={(e: any) => {
            useSingleRowSelect && toggleAllRowsSelected(false)
            if (e.shiftKey && prevRow) {
              if (prevRow.index < row.index) {
                for (let i = prevRow.index; i <= row.index; i++) {
                  //@ts-ignore
                  prevRow.index !== i && rows[i].toggleRowSelected()
                  //@ts-ignore
                  // row.getToggleRowSelectedProps().onChange(row[i])
                }
                // or the opposite
              } else {
                for (let i = row.index; i <= prevRow.index; i++) {
                  //@ts-ignore
                  row.index !== i && rows[i].toggleRowSelected()
                  //@ts-ignore
                  // row.getToggleRowSelectedProps().onChange(row[i])
                }
              }
            }
            setPrevRow(row)

            //@ts-ignore
            row.getToggleRowSelectedProps().onChange(e)
            onRowClick && onRowClick(row)
          }}
          // key={key}
          flexDirection="row"
          {...row.getRowProps()}
          style={{
            // @ts-ignore - Property 'isSelected' does not exist on type 'Row<D>'
            background: row.isSelected ? theme.colors.primary.muted : 'transparent'
          }}
        >
          {row.cells.map((cell) => {
            return (
              <TableCell
                key={cell.row.index}
                justifyContent="flex-start"
                padding={1}
                flex={cell.column.flex}
                maxWidth={cell.column.maxWidth}
                minWidth={cell.column.minWidth}
                width={cell.column.width}
                {...cell.getCellProps()}
              >
                {cell.render('Cell')}
              </TableCell>
            )
          })}
        </TableRow>
      )
  )

  const itemCount = hasMore ? data.length + 1 : data.length
  const loadMoreItems = loadNextPage ? (isNextPageLoading ? () => {} : loadNextPage) : () => {}
  const isItemLoaded = (index: number) => !hasMore || index < data.length
  const MemoRow = React.useMemo(() => TableRow, [])
  const MemoCell = React.useMemo(() => TableCell, [])

  const RenderRow = ({ index, style }: any) => {
    const rowRef = React.useRef(null)
    const row = rows[index] || {}
    let timeout: number | null = null

    useEffect(() => {
      if (Object.keys(row).length > 0 && contextToggle) {
        //@ts-ignore - Property 'toggleRowSelected' does not exist on type 'Row<D>'
        toggleAllRowsSelected(false)
        createToggleSelection(false)
      }
      // eslint-disable-next-line
    }, [])

    const onClick = (e: React.MouseEvent<any, MouseEvent>) => {
      if (useDoubleClick) {
        if (timeout === null) {
          timeout = window.setTimeout(() => {
            timeout = null
            setPrevRow(row)
            if (!isSelectionDisabled) {
              if (row.index !== disableSelectAtIndex) {
                useSingleRowSelect && toggleAllRowsSelected(false)
                //@ts-ignore - Property 'toggleRowSelected' does not exist on type 'Row<D>'
                row.toggleRowSelected()
                onRowClick && onRowClick(row)
              }
            }
          }, 300)
        }
      } else {
        setPrevRow(row)
        if (!isSelectionDisabled) {
          if (row.index !== disableSelectAtIndex) {
            useSingleRowSelect && toggleAllRowsSelected(false)
            //@ts-ignore - Property 'toggleRowSelected' does not exist on type 'Row<D>'
            row.toggleRowSelected()
            onRowClick && onRowClick(row)
          }
        }
      }
    }

    const onDoubleClick = (e: React.MouseEvent<any, MouseEvent>) => {
      e.preventDefault()
      timeout && window.clearTimeout(timeout)
      timeout = null
      logger('Double')
      onRowDoubleClick && onRowDoubleClick(row)
    }

    if (!isItemLoaded(index)) {
      return (
        <TableRow style={style} justifyContent="center" ref={rowRef}>
          <TableCell justifyContent="center">
            <Spinner size="sm" />
          </TableCell>
        </TableRow>
      )
    } else {
      prepareRow(row)
      return (
        <MemoRow
          ref={rowRef}
          onDoubleClick={(e: any) => {
            onDoubleClick(e)
          }}
          onClick={(e: any) => {
            onClick(e)
          }}
          style={{
            // @ts-ignore - Property 'isSelected' does not exist on type 'Row<D>'
            background: row.isSelected ? theme.colors.primary.muted : 'transparent'
          }}
          {...row.getRowProps({
            style: {
              ...style,
              // @ts-ignore - Property 'isSelected' does not exist on type 'Row<D>'
              background: row.isSelected ? theme.colors.primary.muted : 'transparent'
            }
          })}
          className="tr"
        >
          {row.cells.map((cell: Cell<D>) => {
            return (
              <MemoCell
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden'
                }}
                key={cell.row.index}
                justifyContent="flex-start"
                padding={1}
                flex={cell.column.flex}
                maxWidth={cell.column.maxWidth}
                minWidth={cell.column.minWidth}
                width={cell.column.width}
                {...cell.getCellProps()}
              >
                {cell.render('Cell')}
              </MemoCell>
            )
          })}
        </MemoRow>
      )
    }
  }
  const headerRef = React.useRef<HTMLDivElement>(null)
  return (
    <Card
      flexDirection="column"
      flex={1}
      maxWidth="100%"
      width="100%"
      height="100%"
      zIndex={0}
      floating={false}
    >
      {!!tableHeading && <TopSection>{tableHeading}</TopSection>}
      <StyledTable {...getTableProps()}>
        <TableHead
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 0
          }}
        >
          {headerGroups.map((headerGroup: HeaderGroup<D>) => (
            <Flex
              ref={headerRef}
              key={headerGroup.id}
              flex={1}
              flexDirection="row"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <TableCell
                  px={1}
                  py={2}
                  key={column.id}
                  bg={theme.colors.background.muted}
                  {...column.getHeaderProps()}
                  justifyContent="space-between"
                  {...column.getSortByToggleProps()}
                  flex={column.flex}
                  width={column.width}
                  maxWidth={column.maxWidth}
                  minWidth={column.minWidth}
                >
                  <Box fontWeight="bold">{column.render('Header')}</Box>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <ChevronDown size={20} />
                    ) : (
                      <ChevronUp size={20} />
                    )
                  ) : (
                    ''
                  )}
                </TableCell>
              ))}
            </Flex>
          ))}
        </TableHead>
        {itemCount === 0 && (
          <TableRow style={{ justifyContent: 'center', height: '100%' }}>
            <TableCell justifyContent="center">
              {!isNextPageLoading && !hasMore ? 'No Data' : <Spinner size="sm" />}
            </TableCell>
          </TableRow>
        )}
        {!useInfiniteScroll && <Flex flexDirection="column">{CustomTableRow}</Flex>}
        {useInfiniteScroll && itemCount > 0 && (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            itemCount={itemCount}
            loadMoreItems={loadMoreItems}
          >
            {({ onItemsRendered, ref }) => (
              <div style={{ height: '100%', width: '100%' }}>
                <AutoSizer>
                  {({ width, height }) => (
                    <FixedSizeList
                      ref={(list) => {
                        if (ref) {
                          //@ts-ignore - This expression is not callable
                          ref(list)
                        }
                        if (listRef) {
                          //@ts-ignore - Type 'Ref<any>' is not assignable to type 'RefObject<FixedSizeList> | undefined'
                          listRef = ref
                        }
                      }}
                      className="List"
                      height={height}
                      itemCount={itemCount}
                      itemSize={50}
                      onItemsRendered={onItemsRendered}
                      width={headerRef.current?.clientWidth || width}
                    >
                      {RenderRow}
                    </FixedSizeList>
                  )}
                </AutoSizer>
              </div>
            )}
          </InfiniteLoader>
        )}
        {fillTable &&
          [...new Array(pageSize - page.length)].map((_, index: number) => (
            <TableRow key={'placeholder' + index} minHeight={52} />
          ))}
      </StyledTable>
      {!useNumberedPagination && !useInfiniteScroll && (
        <BottomSection justifyContent="space-between" flexDirection="row">
          <Flex flexDirection="row">
            <TableIconButton
              mr={1}
              onClick={() => gotoPage(0)}
              isDisabled={!canPreviousPage}
              icon={<ChevronsLeft size={20} />}
            />
            <TableIconButton
              mr={1}
              isDisabled={!canPreviousPage}
              onClick={() => previousPage()}
              icon={<ChevronLeft size={20} />}
            />
          </Flex>
          <Flex justifyContent="center" alignItems="center">
            <Text mr={2}>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length || 1}
              </strong>{' '}
            </Text>
            {!isTabletOrMobile && (
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value))
                }}
              >
                {[5, 10, 20, 30, 40, 50, 1000].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            )}
          </Flex>
          <Flex flexDirection="row">
            <TableIconButton
              ml={1}
              isDisabled={!canNextPage}
              onClick={() => nextPage()}
              icon={<ChevronRight size={20} />}
            />
            <TableIconButton
              ml={1}
              onClick={() => gotoPage(pageCount ? pageCount - 1 : 1)}
              isDisabled={!canNextPage}
              icon={<ChevronsRight size={20} />}
            />
          </Flex>
        </BottomSection>
      )}
      {useNumberedPagination && !useInfiniteScroll && (
        <BottomSection flexDirection="row" justifyContent="space-between" width="100%">
          <Flex alignItems="center">
            <Text mr={2}>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length || 1}
              </strong>{' '}
            </Text>
            {!isTabletOrMobile && (
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value))
                }}
              >
                {[5, 10, 20, 30, 40, 50, 1000].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            )}
          </Flex>
          <Flex justifyContent="flex-end">
            <Input
              onKeyDown={(event: any) => {
                if (event.keyCode === 13) {
                  gotoPage(event.target.value - 1)
                  event.target.value = ''
                }
              }}
              marginRight={4}
              paddingX={12}
              placeholder="Go to page"
            />
            {fetchPageNumbers().map((page, index) => {
              if (page === LEFT_PAGE)
                return (
                  <IconButton
                    aria-label="Go to previous page"
                    key={index}
                    mr={1}
                    variant="ghost"
                    isDisabled={!canPreviousPage}
                    onClick={() => previousPage()}
                    icon={<ChevronLeft size={20} />}
                  />
                )

              if (page === RIGHT_PAGE)
                return (
                  <IconButton
                    aria-label="Go to next page"
                    key={index}
                    mr={1}
                    variant="ghost"
                    isDisabled={!canNextPage}
                    onClick={() => nextPage()}
                    icon={<ChevronRight size={20} />}
                  />
                )

              return (
                <Button
                  {...(pageIndex + 1 === page && {
                    bg: 'primary.base',
                    color: 'solid.white',
                    style: { pointerEvents: 'none' }
                  })}
                  key={index}
                  variant="outline"
                  mr={1}
                  paddingX={2}
                  // @ts-ignore
                  onClick={() => gotoPage(page - 1)}
                >
                  {page}
                </Button>
              )
            })}
          </Flex>
        </BottomSection>
      )}
    </Card>
  )
}

export default Table

Table.defaultProps = {
  pageNeighbours: 5,
  pageSize: 10,
  initialPageIndex: 0
}
