import { Box, BoxProps, Button, Flex } from '@chakra-ui/react'
import { Field, Form, Formik } from 'formik'
import { isEmpty } from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { Row as IRow } from 'react-table'
import { CardFooter, Col, Container, ModalWrap, Row } from '..'
import { useTableContext } from '../../contexts/TableProvider.context'
import { WmsUserFilterInput } from '../../generated/graphql'
import { stripSelectValues } from '../../utils'
import CreateTable from '../Table/table'

type AdvancedSearchModalProps = {
  columns?: any
  data?: any
  filters?: any
  objectFilters?: WmsUserFilterInput
  initialFilter?: string
  query?: any
  queryVariables?: object
  onClose: (() => void) | undefined
  isOpen: boolean | undefined
  isLoading: boolean
  onSelect: (row: IRow<{}>) => void
  size?: number
}

const AdvancedSearchModal: React.FC<AdvancedSearchModalProps> = ({
  columns,
  filters,
  objectFilters,
  initialFilter,
  onClose,
  onSelect,
  isOpen,
  query,
  queryVariables,
  size = 648
}) => {
  const [selectedRow, setSelectedRow] = useState<any>({})
  const defaultFilter = filters?.[0] || { name: 'code_starts_with' }
  const [formFilterValues, setFormFilterValues] = useState({})

  const {
    endCursor,
    data: contextData,
    hasMore,
    isNextPageLoading,
    toggleSelection,
    createIsNextPageLoaded,
    clearAll
  } = useTableContext()

  const handleClose = () => {
    onClose && onClose()
    clearAll()
  }
  useEffect(() => {
    clearAll()
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    let filterInput = {}
    if (objectFilters) {
      filterInput = objectFilters
    } else {
      filterInput = filters?.reduce((obj: object, item: { name: string; initialValue: string }) => {
        return {
          ...obj,
          [item['name']]: item['initialValue']
        }
      }, {})
    }
    query && query({ variables: { filterInput, first: 20, ...queryVariables } })
    // eslint-disable-next-line
  }, [initialFilter, query])

  const loadNextPage = (...args: any) => {
    let filterInput = {}
    if (objectFilters) {
      filterInput = objectFilters
    } else {
      filterInput = filters?.reduce((obj: object, item: { name: string; initialValue: string }) => {
        return {
          ...obj,
          [item['name']]: item['initialValue']
        }
      }, {})
    }
    createIsNextPageLoaded(true)
    query &&
      endCursor !== '' &&
      query({
        variables: {
          filterInput: { ...filterInput, ...formFilterValues },
          first: 20,
          after: endCursor,
          ...queryVariables
        }
      })
  }
  return (
    <ModalWrap title="Advanced Search" onClose={handleClose} isOpen={isOpen || false}>
      <Box p={2} className="modal-box">
        <Filters
          marginBottom={2}
          filters={filters}
          useCustomId={!!objectFilters}
          onSearch={(values: any) => {
            clearAll()
            let filterInput = {}
            if (objectFilters) {
              const filterObject = Object.entries(values)
                .map(([key, value]) => {
                  const filterInput = {
                    [key]: {
                      //@ts-ignore - A computed property name must be of type 'string', 'number', 'symbol', or 'any'
                      [Object.keys(objectFilters[key])]: value
                    }
                  }
                  return filterInput
                })
                .reduce(
                  (obj, item) => ({
                    ...obj,
                    ...item
                  }),
                  {}
                )
              filterInput = filterObject
            } else {
              filterInput = Object.entries(values)
                .map(([key, value]) => {
                  if (!isEmpty(value) || typeof value === 'number') {
                    // Variable to store the parent and nested key prefix
                    // @example
                    // Given `storageTypes_some: { storageTypeId_in: [1] }`...
                    // ... the parent and nested key are `storageType`
                    let idInPrefix

                    // Handle nested filtering
                    // TODO: Possible refactor
                    // @example: storageTypes_some: { storageTypeId_in: [1] }
                    if (key.endsWith('s_some')) {
                      idInPrefix = key.split('s_some')?.[0]
                    }

                    return {
                      [key]: idInPrefix ? { [idInPrefix + 'Id_in']: [value] } : value
                    }
                  }

                  return {}
                })
                .reduce(
                  (obj, item) => ({
                    ...obj,
                    ...item
                  }),
                  {}
                )
            }
            const input = !isEmpty(filterInput) ? filterInput : { [defaultFilter.name]: '' }
            setFormFilterValues(input)
            query({ variables: { filterInput: input, first: 20, ...queryVariables } })
            setSelectedRow({})
          }}
        />
        <Box height="400px">
          <CreateTable
            data={contextData}
            columns={columns}
            hasMore={hasMore}
            isNextPageLoading={isNextPageLoading}
            loadNextPage={loadNextPage}
            toggleSelection={toggleSelection}
            useSingleRowSelect
            useDoubleClick
            onRowSelect={(rows: any) => {
              if (rows.length > 0) {
                setSelectedRow(rows[0])
              }
            }}
            onRowDoubleClick={(row) => {
              //@ts-ignore
              onSelect && onSelect(row)
              onClose && onClose()
            }}
          />
        </Box>
      </Box>
      <CardFooter flexDirection="row" flex={1}>
        <Flex justifyContent="flex-end" flex={1}>
          <Button mr={2} variant="outline" type="submit" onClick={onClose}>
            Cancel
          </Button>
          <Button
            isDisabled={!selectedRow.id}
            bg="primary.base"
            color="solid.white"
            onClick={() => {
              onSelect && onSelect(selectedRow)
              onClose && onClose()
            }}
          >
            Select
          </Button>
        </Flex>
      </CardFooter>
    </ModalWrap>
  )
}

AdvancedSearchModal.defaultProps = {
  columns: [],
  data: [],
  size: 648
}

export default AdvancedSearchModal

type FiltersProps = BoxProps & {
  filters?: any[]
  onSearch: (...values: string[]) => void
  useCustomId: boolean
}

const Filters: FC<FiltersProps> = ({ onSearch, filters, useCustomId, ...rest }) => {
  const initialValues =
    filters?.reduce((obj, item) => {
      return {
        ...obj,
        [item['name']]: item['initialValue']
      }
    }, {}) || ({} as any)

  const renderFilters = () => {
    return (
      filters?.map(({ component, ...props }) => {
        if (component) {
          const id = useCustomId ? `${props.name}-filter` : props.name
          return (
            <Col key={props.name} xs={12} sm={6} md={4}>
              <Field component={component} id={id} {...props} />
            </Col>
          )
        }
        return null
      }) || null
    )
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        Object.keys(values).map((key) => {
          if (values[key] && typeof values[key] === 'string') {
            return (values[key] = values[key].trim())
          }
          return values[key]
        })
        const copyOfValues = Object.assign({}, values)
        // @ts-ignore - Argument of type 'object' is not assignable to parameter of type 'string'
        onSearch(stripSelectValues(copyOfValues))
      }}
    >
      {({ handleSubmit }) => (
        <Form>
          <Container>
            <Row>{renderFilters()}</Row>
          </Container>
          <Flex justifyContent="flex-end" width="100%" {...rest}>
            <Button onClick={() => handleSubmit()} marginBottom={2} marginLeft={1}>
              Search
            </Button>
          </Flex>
        </Form>
      )}
    </Formik>
  )
}
