import { Flex, Input, InputGroup, InputLeftAddon, InputProps, Text } from '@chakra-ui/react'
import { ErrorMessage, FieldProps } from 'formik'
import * as React from 'react'
import { Label, LabelProps } from '../styles'

export type ConnectedTelInputProps = LabelProps &
  FieldProps &
  InputProps & {
    label?: string
    code: string
  }

const ConnectedTelInput: React.FC<ConnectedTelInputProps> = ({
  type,
  label,
  field,
  placeholder,
  code,
  ...rest
}) => (
  <Flex flexDirection="column" width="100%" mr={rest.mr} ml={rest.ml} mt={rest.mt} mb={rest.mb}>
    {label && <Label htmlFor={field.name}>{label}</Label>}
    <InputGroup>
      {!!code && <InputLeftAddon>{code}</InputLeftAddon>}
      <Input
        {...field}
        type="tel"
        id={field.name}
        focusBorderColor="brand.500"
        placeholder={placeholder || ''}
        {...rest}
      />
    </InputGroup>
    <ErrorMessage name={field.name}>
      {(msg) => (
        <Text mt="4px" ml="8px" color="intent.error">
          {msg}
        </Text>
      )}
    </ErrorMessage>
  </Flex>
)

export default ConnectedTelInput

ConnectedTelInput.defaultProps = {
  mb: 2,
  fontWeight: 'lighter'
}
