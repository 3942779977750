const images = {
  underConstruction: require('../assets/images/under-construction.svg'),
  warning: require('../assets/images/warning.svg'),
  empty: require('../assets/images/empty.svg'),
  facebook: require('../assets/images/facebook.svg'),
  appLogo: require('../assets/images/logo.png'),
  macsLogo: require('../assets/images/MACS_logo.svg'),
  macsLogoSmall: require('../assets/images/MACS_logo_small.svg'),
  csv: require('../assets/images/csv.svg'),
  pdf: require('../assets/images/pdf.svg'),
  doc: require('../assets/images/doc.svg'),
  xls: require('../assets/images/xls.svg'),
  general: require('../assets/images/documents.svg')
}

export default images
