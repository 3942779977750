import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          'Welcome to React': 'Welcome to React and react-i18next'
        },
        oidcClient: {
          'Zone Code': 'Zone Code',
          'Zone Description': 'Zone Description',
          'Zone Type': 'Zone Type'
        }
      }
    },
    debug: true,
    saveMissing: true,
    fallbackNS: 'translation',
    interpolation: {
      escapeValue: false
    }
  })

export default i18n
