import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  InputProps,
  InputRightAddon,
  Text
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { ErrorMessage, FieldInputProps, FieldProps } from 'formik'
import * as React from 'react'
import { isFirefox } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { IMaskInput } from 'react-imask'
import { theme } from '../../../theme'
import { Label, LabelProps } from '../styles'

const StyledInput = styled(Input)<InputProps>`
  border: ${theme.colors.border.default};
  margin-bottom: 0;
  max-width: ${(props) => props.maxWidth};
`

const MaskedInput = styled(IMaskInput, {
  shouldForwardProp: (prop) => {
    return prop === 'maxWidth' ? false : true
  }
})<InputProps>`
  border: ${theme.colors.border.default};
  margin-bottom: 0;
  padding-left: 12px;
  padding-right: 12px;

  align-items: center;
  appearance: none;
  background-color: #fff;
  border-color: inherit;
  border-radius: 0.25rem;
  border: 1px solid #d9d9d9;
  display: flex;
  font-size: 1rem;
  height: 2.5rem;
  outline: none;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  transition: all 0.2s;
  width: 100%;
  max-height: 35px;
  max-width: ${(props) => props.maxWidth};
  &:focus {
    z-index: 1;
    border-color: #3182ce;
    box-shadow: 0 0 0 1px #3182ce;
  }

  &:disabled {
    opacity: 0.4;
  }
`

export type ConnectedFormGroupProps = LabelProps &
  FieldProps &
  InputProps & {
    label?: string
    labelMinWidth?: string
    labelMarginRight?: string
    updateOnBlur?: string
    inputLeftAddonText?: string
    inputLeftBackground?: string
    inputRightAddonText?: string
    mask?: any
    prepare?: Function
    noBottomMargin?: boolean
    isRequired?: boolean
    disabled?: boolean
  }

const ConnectedFormLabel: React.FC<{
  field: FieldInputProps<any>
  labelMinWidth: any
  labelMarginRight: any
  label: string
}> = React.memo(
  ({ field, labelMinWidth, labelMarginRight, label }) => {
    const { t } = useTranslation('translation')

    return (
      <Label htmlFor={field.name} minWidth={labelMinWidth} marginRight={labelMarginRight}>
        {t(label)}
      </Label>
    )
  },
  () => true
)

const ConnectedFormGroup: React.FC<ConnectedFormGroupProps> = ({
  type,
  label,
  field,
  placeholder,
  flexDirection,
  labelMinWidth,
  labelMarginRight,
  updateOnBlur,
  form,
  defaultValue,
  inputLeftAddonText,
  inputLeftBackground,
  inputRightAddonText,
  mask,
  prepare,
  noBottomMargin,
  isRequired,
  disabled,
  ...rest
}) => {
  const renderInput = () => {
    if (mask) {
      //@ts-ignore - Property 'initialValue' does not exist
      const { isDisabled, maxWidth, initialValue, ...maskedRest } = rest
      return (
        <MaskedInput
          disabled={rest.isDisabled}
          type={type}
          id={field.name}
          name={field.name}
          placeholder={placeholder || label}
          mask={mask}
          prepare={prepare}
          onAccept={(value: string) => form.setFieldValue(field.name, value)}
          value={field.value}
          onBlur={(e: React.FocusEvent<any>) => field.onBlur(e)}
          {...maskedRest}
          maxWidth={isFirefox && inputRightAddonText ? 'calc(100% - 36px)' : 'auto'}
        />
      )
    }

    /* 
      NOTE: 
      updateOnBlur is needed as field and rest cause the input to not respond when onChange and onBlur are provided
      Formik provides FastField which does so this is an optimization to improve typing speed on larege forms
    */
    if (updateOnBlur) {
      return (
        <StyledInput
          type={type}
          id={field.name}
          placeholder={placeholder || label}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => e.preventDefault()}
          onBlur={({ currentTarget }: React.ChangeEvent<HTMLInputElement>) =>
            form.setFieldValue(field.name, currentTarget.value)
          }
          defaultValue={defaultValue}
          paddingX={12}
        />
      )
    }
    return (
      <StyledInput
        {...field}
        type={type}
        id={field.name}
        placeholder={placeholder || label}
        paddingX={12}
        {...rest}
        maxWidth={isFirefox && inputRightAddonText ? 'calc(100% - 36px)' : 'auto'}
      />
    )
  }
  return (
    <Flex
      flexDirection={flexDirection || 'column'}
      width="100%"
      mr={rest.mr}
      ml={rest.ml}
      mt={rest.mt}
      mb={rest.mb}
    >
      {label && (
        <ConnectedFormLabel
          field={field}
          labelMinWidth={labelMinWidth}
          labelMarginRight={labelMarginRight}
          label={label}
        />
      )}
      <InputGroup>
        <InputLeftAddon
          children={
            <React.Fragment>
              {isRequired && !disabled && (
                <Box width="1px" height="100%" background={theme.colors.background.required}></Box>
              )}
              {!isRequired && !disabled && <Box width="1px" height="100%"></Box>}
            </React.Fragment>
          }
          bg="white"
          border="none"
          padding="4px"
        />

        {inputLeftAddonText && (
          <InputLeftAddon
            paddingX={1}
            children={inputLeftAddonText}
            background={inputLeftBackground}
            color={inputLeftBackground ? 'white' : 'black'}
          />
        )}

        {renderInput()}
        {inputRightAddonText && <InputRightAddon paddingX={1} children={inputRightAddonText} />}
      </InputGroup>
      <ErrorMessage name={field.name}>
        {(msg) => (
          <Text mt="4px" ml="8px" color="intent.error">
            {msg}
          </Text>
        )}
      </ErrorMessage>
    </Flex>
  )
}

export default ConnectedFormGroup

ConnectedFormGroup.defaultProps = {
  mb: 2,
  type: 'text'
}
