import { List } from '@chakra-ui/react'
import React, { useEffect, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import { ColorProps } from 'styled-system'
import { AppContext } from '../../contexts/ApplicationProvider.context'
import usePersistState from '../../hooks/persistState'
import images from '../../theme/images'
import Header from '../Header'
import SideBarItem from './sideBarItem'
import { Logo, LogoCont, RenderWrapper, SideBarWrapper } from './styles'

export type Mode = 'cover' | 'push'

export type NavItems = {
  title: string
  icon?: React.ReactNode
  to?: string
  items?: NavItems[]
  level?: number
}

type SideBarProps = ColorProps & {
  mode: Mode
  color: string
  hovercolor: string
  accentcolor: string
  navItems: NavItems[]
}

type SideBarLinkProps = ColorProps & {
  to: string
  open: boolean
  title: string
  hovercolor: string
  accentcolor: string
  color?: string
  icon?: React.ReactNode
}

const MemoSideBarItem = React.memo(SideBarItem)

const SideBar: React.FC<SideBarProps> = ({
  children,
  mode,
  navItems,
  color,
  bg,
  accentcolor,
  hovercolor
}) => {
  const { drawerOpen } = React.useContext(AppContext)

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })

  const [sideBarPosition, setSideBarPosition] = usePersistState('sideBarPosition')
  const listRef = useRef<HTMLUListElement>(null)
  useEffect(() => {
    if (listRef) {
      if (listRef.current) {
        listRef.current.scrollTo({
          top: sideBarPosition.position
        })
      }
    }
    // eslint-disable-next-line
  }, [listRef])

  return (
    <React.Fragment>
      <SideBarWrapper
        bg={bg}
        color={color}
        open={drawerOpen}
        mode={isTabletOrMobile ? 'cover' : mode}
      >
        <LogoCont borderBottomColor="gray.700" open={drawerOpen}>
          <Logo src={drawerOpen ? images.macsLogo : images.macsLogoSmall} width="60%" />
        </LogoCont>
        <List
          width="100%"
          height={'100vh'}
          overflow="scroll"
          ref={listRef}
          onScroll={(e: React.UIEvent<HTMLElement>) => {
            setSideBarPosition({ position: e.currentTarget.scrollTop })
          }}
        >
          {navItems.map((item, index) => (
            <MemoSideBarItem
              key={index}
              bg={bg}
              color={color}
              hovercolor={hovercolor}
              accentcolor={accentcolor}
              {...item}
            />
          ))}
        </List>
      </SideBarWrapper>
      <RenderWrapper
        pl={isTabletOrMobile ? 0 : mode === 'cover' ? '60px' : drawerOpen ? '250px' : '60px'}
        mode={mode}
      >
        <Header open={drawerOpen} />
        {children}
      </RenderWrapper>
    </React.Fragment>
  )
}

export default SideBar

SideBar.defaultProps = {
  mode: 'push',
  navItems: []
}
