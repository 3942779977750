import { Box, Button, ButtonGroup } from '@chakra-ui/react'
import React, { FC } from 'react'
import { StyledSystemProps } from '../../typings/StyledSystemProps'

type DropdownButtonProps = StyledSystemProps & {
  isDisabled?: boolean
  title: string
  onClick: (event: React.MouseEvent<any, MouseEvent>) => void
}

const DropdownButton: FC<DropdownButtonProps> = ({ isDisabled, title, onClick, ...rest }) => {
  return (
    // @ts-ignore - borderTopWidth incompatible
    <Box {...rest}>
      <ButtonGroup spacing={-1}>
        <Button isDisabled={isDisabled} bg="primary.base" color="text.onPrimary" onClick={onClick}>
          {title}
        </Button>
      </ButtonGroup>
    </Box>
  )
}

export default DropdownButton
