import { Checkbox, Flex } from '@chakra-ui/react'
import { FieldInputProps, useFormikContext } from 'formik'
import React from 'react'

const ConnectedCheckbox: React.FC<{
  field: FieldInputProps<any>
  label: string
  useFieldValue?: boolean
  disabled?: boolean
  mx?: number
}> = ({ field, label, useFieldValue, disabled, mx }) => {
  const { values, setFieldValue } = useFormikContext<any>()

  const fieldValue = () => {
    if (typeof field.value === 'string') {
      return field.value === 'true' ? true : false
    }
    return field.value
  }
  return (
    <Flex marginX={mx}>
      <Checkbox
        onKeyDown={({ keyCode }: React.KeyboardEvent<HTMLInputElement>) => {
          if (keyCode === 13) {
            useFieldValue
              ? setFieldValue(field.name, !field.value)
              : setFieldValue(field.name, !values[field.name])
          }
        }}
        isChecked={useFieldValue ? fieldValue() : values[field.name]}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setFieldValue(field.name, event.target.checked)
        }
      >
        <span
          style={{
            color: disabled ? 'grey' : 'black'
          }}
        >
          {label}
        </span>
      </Checkbox>
    </Flex>
  )
}

ConnectedCheckbox.defaultProps = {
  mx: 0
}

export default ConnectedCheckbox
