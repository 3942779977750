export const stockOwnersData = {
  stockOwners: {
    nodes: [...new Array(5)].map((_: any, index: number) => ({
      id: ++index,
      code: 'SO ' + index,
      name: 'Stock Owner ' + index,
      __typename: 'StockOwner'
    }))
  }
}
