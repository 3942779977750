import { extendTheme } from '@chakra-ui/react'

const colors = {
  background: {
    base: '#E9E9E9',
    muted: '#FAFAFA',
    surface: '#FFFFFF',
    required: '#F85F04'
  },
  border: {
    default: '1px solid #D9D9D9'
  },
  intent: {
    success: '#38A169',
    error: '#E53E3E',
    warning: '#DD6B34',
    info: '#3182CE'
  },
  text: {
    default: '#262626',
    gray: '#585858',
    muted: '#A7AFB9',
    link: '#178FFF',
    onPrimary: '#FFFFFF'
  },
  primary: {
    base: '#63BFB4',
    muted: '#DBF0EE'
  },
  solid: {
    black: '#262626',
    muted: '#F5F5F5',
    white: '#FFFFFF',
    tab: '#1890FF'
  },
  sidebar: {
    base: '#000B16',
    subMenu: {
      base: '#001A34'
    }
  },
  advancedTable: {
    filter: '#F0F0F0',
    tableHeader: '#E1E1E1',
    sidePanel: '#E1E1E1',
    valueText: '#707070'
  },
  progressBar: {
    primary: { 500: '#63BFB4' }
  }
}

const makeTheme = extendTheme({
  styles: {
    global: (props) => ({
      ' html,body': {
        backgroundColor: props.theme.colors.background.base,
        fontSize: '14px'
      },
      button: {
        fontWeight: 'normal !important',
        '&:disabled': {
          opacity: 0.4
        }
      },
      fieldset: {
        display: 'contents',
        width: '100%'
      },
      'fieldset&:disabled': {
        '.react-select__control,input,textarea': {
          backgroundColor: props.theme.colors.background.muted,
          '&:hover': {
            backgroundColor: props.theme.colors.background.muted
          }
        }
      },
      'input,textarea': {
        '&:disabled': {
          backgroundColor: `${props.theme.colors.background.muted} !important`,
          opacity: '0.4',
          '&:hover': {
            backgroundColor: props.theme.colors.background.muted
          }
        }
      }
    })
  },
  fonts: {
    heading: 'PingFang SC, sans-serif',
    body: 'PingFang SC, sans-serif'
  },
  colors: {
    ...colors
  },
  inputHeight: '32px',
  boxShadow: {
    thin: '0 1px 4px 0 #CCD0D2',
    thick: '0 8px 10px 0 #C7CDD4'
  },
  space: [0, 8, 16, 24, 32, 40, 48, 56, 64],
  transition: 'all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)',
  gridGutter: 1 // rem - taken from Chakra UI space scale https://chakra-ui.com/theme#spacing
})

const theme = makeTheme

export { theme }
