import { Box, IconButton, Modal, ModalContent, ModalOverlay, ModalProps } from '@chakra-ui/react'
import * as React from 'react'
import { X } from 'react-feather'
import { useMediaQuery } from 'react-responsive'
import Card from '../Card'
import CardHeader from '../Card/CardHeader'
import { H5 } from '../Typography'

type ModalWrapProps = ModalProps & {
  title: string
  showCloseButton?: boolean
}

const ModalWrap: React.FC<ModalWrapProps> = ({
  children,
  title,
  onClose,
  isOpen,
  size,
  showCloseButton = true,
  ...rest
}) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 40em)' })
  return (
    <Modal
      size={size || '6xl'}
      isCentered
      preserveScrollBarGap
      scrollBehavior={isTabletOrMobile ? 'inside' : 'outside'}
      {...rest}
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent
        shadow="none"
        bg="transparent"
        rounded="md"
        position="initial"
        overflow="visible"
      >
        <Card
          overflow={isTabletOrMobile ? 'auto' : 'inherit'}
          id="main-modal-container"
          rounded="md"
          m={1}
          maxHeight="90vh"
        >
          <CardHeader alignItems="center" flexDirection="row" justifyContent="space-between">
            <H5 mb={0}>{title}</H5>
            {showCloseButton && (
              <IconButton
                onClick={onClose}
                size="sm"
                aria-label="Close Modal"
                icon={<X />}
                variant="ghost"
              />
            )}
          </CardHeader>
          <Box overflow="visible">{children}</Box>
        </Card>
      </ModalContent>
    </Modal>
  )
}

export default ModalWrap

ModalWrap.defaultProps = {
  title: 'Modal Heading'
}
